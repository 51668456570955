.page-blog.page-blog-author{
    .header-title{
        min-width: fit-content;
    }
    .img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .blog-author{
        margin: 0;
        margin-bottom: 6rem;
        padding-right: 4rem;

        .author-content-box{    
            .generic.text * {
                border: none;
                font-size: 1.5rem !important;
                font-weight: normal !important;
            }

            .title{
                padding-bottom: 1rem;
                color: $colorCorporative;
            }
            .company{
                padding-bottom: 1rem;
                color: $colorCorporative;
            }
            .subtitle{               
                padding-bottom: 2rem;
                font-weight: 600;
                font-size: 1.5rem;
            }
            &.imgTrue{
                width: 60%;
                padding-left: 2rem;
            }
        }  
        .box-content{
            display: flex;
            .photo-author{
                width: 40%;                          
            }
        }      
    }

    .title-last-blog-box{
        width: 100%;
        margin-bottom: 4rem;
        padding-right: 4rem;

        .title-last-blog{
            font-size: 2.5rem;
            color: $colorCorporative;
        }
        hr{

        }
    }

    .blog-entry-container{    

        & > .blog-entry:first-of-type {             

            width: 100%;
            margin-bottom: 4rem;
            padding-right: 4rem;

            a{
                width: 100%;
            }
            .aux-item{
                width: 40%;
            }
            .aux-img .wrap-img{
                padding-top: 63%;
            }
            .aux-img  .wrap-img .img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                -o-object-fit: cover;
                object-fit: cover;
                box-shadow: none;
            }

            .block-text {
                width: 60% !important;
                padding-left: 2rem;
              
            }

            .block-title {
                width: 80%;
            }

            .desc {
                margin-top: 1rem;
            }

            p {
                width: 80%;
            }

            .author {
                margin-top: 5rem;
            }

            .blog-content .block-text hr {
                display: block !important;
            }

            .badge-blog {
                display: block;
                position: absolute;
                top: 1.3rem;
                right: 0 !important;
                width: 80px;
                border-width: 1.6rem;
                border-color: white white white transparent;
                border-style: solid;
                
                p {
                    position: absolute;
                    width: 60px;
                    top: 0%;
                    right: -40%;
                    transform: translateY(-50%);
                }
            }
            .img-link:active{
                .img{
                    box-shadow: 0px 0px $colorCorporative;
                    transition: box-shadow .2s linear;
                }
                .wrap-img::before{
                    content: '';
                    position: absolute;
                    width: 80%;
                    height: 100%;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    background-color: $colorCorporative;

                }
            }
        }
    }
}

@media (max-width: $md-width) {
    .page-blog.page-blog-author{
        .header-title{
            min-width: fit-content;
        }
    
        .blog-author{
            margin: 0;
            padding: 0;
            padding-bottom: 4rem;
    
            .author-content-box{            
    
                .generic.text * {
                    border: none;
                    font-size: 1.5rem !important;
                }
    
                .company{                   
                    font-size: 1.7rem;
                }
                .subtitle{               
                    padding-bottom: 2rem;
                    font-weight: 600;
                    font-size: 1.5rem;
                }
                &.imgTrue{
                    width: 100%;
                    padding-left: 0;
                    
                }
            }  
            .box-content{
                flex-direction: column;
                
                .photo-author{
                    width: 100%;   
                    padding-bottom: 1.5rem;                       
                }
            }      
        }
    
        .title-last-blog-box{
            width: 100%;
            margin-bottom: 2.5rem;
            padding-right: 0;
    
            .title-last-blog{            
                font-size: 1.7rem;
            }          
        }
    
        .blog-entry-container{    
    
            & > .blog-entry:first-of-type {             
    
                width: 100%;
                margin-bottom: 4rem;
                padding-right: 4rem;
    
                a{
                    width: 100%;
                }
                .aux-item{
                    width: 40%;
                }
                .aux-img .wrap-img{
                    padding-top: 63%;
                }
                .aux-img  .wrap-img .img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    -o-object-fit: cover;
                    object-fit: cover;
                    box-shadow: none;
                }
    
                .block-text {
                    width: 60% !important;
                    padding-left: 2rem;
                  
                }
    
                .block-title {
                    width: 80%;
                }
    
                .desc {
                    margin-top: 1rem;
                }
    
                p {
                    width: 80%;
                }
    
                .author {
                    margin-top: 5rem;
                }
    
                .blog-content .block-text hr {
                    display: block !important;
                }
    
                .badge-blog {
                    display: block;
                    position: absolute;
                    top: 1.3rem;
                    right: 0 !important;
                    width: 80px;
                    border-width: 1.6rem;
                    border-color: white white white transparent;
                    border-style: solid;
                    
                    p {
                        position: absolute;
                        width: 60px;
                        top: 0%;
                        right: -40%;
                        transform: translateY(-50%);
                    }
                }
                .img-link:active{
                    .img{
                        box-shadow: 0px 0px $colorCorporative;
                        transition: box-shadow .2s linear;
                    }
                    .wrap-img::before{
                        content: '';
                        position: absolute;
                        width: 80%;
                        height: 100%;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        background-color: $colorCorporative;
    
                    }
                }
            }
        }
    }
}