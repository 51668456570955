// FAMA BASE
.sidebar-categories{ 
    top: 11.8rem;
    padding-top: calc(7.3rem - 2.7rem);

    @media (min-width: 991px) {
    
    //@include media-breakpoint-up(md) { // Desktop
        display: flex;
        flex-direction: column;

        .item {
            border-left: 1px solid #d6dcde;
            position: relative;
            &:before, &:after {
                content: '';
                position: absolute;
                left: 0;
                top: rem(18px);
                transform: translate(-50%, -50%);
                border-radius: 100%;
                transition: all $t-normal $easeOutCubic;
            }
            &:before {
                width: 8px;
                height: 8px;
                background-color: #d6dcde;
                z-index: 2;
            }
            &:after {
                width: 0;
                height: 0;
                background-color: #fff;
                border: 1px solid $c-fama;
                z-index: 1;
            }
            &.is-active {
                color: $c-fama;
                text-transform: uppercase;
                &:before {
                    border: 1px solid #fff;
                    background-color: $c-fama;
                }
                &:after {
                    width: 12px;
                    height: 12px;
                }
            }
            &.has-sublist {
                position: relative;
                display: flex;
                align-items: center;
                padding-right: rem(30px);
                .arrow-dropdown {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: rem(30px);
                    height: rem(30px);
                    border-radius: 100%;
                    cursor: pointer;
                    transition: background-color $t-fast $easeOutCubic;
                    &:hover {
                        &:before {
                            opacity: 1;
                            height: 100%;
                            width: 100%;
                            transition: opacity $t-fast linear 0s, width $t-normal $easeOutCubic 0s, height $t-normal $easeOutCubic 0s;
                        }
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        height: 0;
                        width: 0;
                        border-radius: 100%;
                        transform-origin: center;
                        transform: translate(-50%, -50%);
                        opacity: 0;
                        background-color: #fff;
                        transition: opacity $t-fast linear 0s, width $t-normal $easeOutCubic 0s, height $t-normal $easeOutCubic 0s;
                    }
                    &:after {
                        content: '';
                        display: inline-block;
                        position: relative;
                        //top: calc(8px);
                        left: 50%;
                        transform: translate(-50%, -50%);
                        transform-origin: center;
                        width: 0;
                        height: 5px;
                        border-top: 4px solid $c-gray2;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        transition: transform $t-fast linear 0.2s;
                    }
                }
            }
            .item-inner {
                padding: rem(10px) 0 rem(10px) rem(25px);
                display: block;
                cursor: pointer;
                font-size: rem(15px);
                font-weight: normal;
                font-family: 'Montserrat-SemiBold';
                color: $gray-dark;
                position: relative;
                transition: all 0.2s linear;
                &:hover {
                    color: lighten($c-fama, 5%);
                }
            }

            &.isSelected {
                .item-inner {
                    color: $c-fama;
                    text-transform: uppercase;
                }
            }

            &.isOpen {
                &.has-sublist {
                    .arrow-dropdown {
                        &:before {
                            opacity: 1;
                            height: 100%;
                            width: 100%;
                            transform: translate(-50%, -50%);
                        }
                        &:after {
                            transform: scaleY(-1) translate(-50%, 50%);
                        }
                    }
                }
                &+.subitem-list {
                    transition: height $t-fast $easeOutCubic 0s;
                    .subitem {
                        opacity: 1;
                        transform: translateX(0);
                        $delay: 0.1s;
                        @for $i from 1 through 10 {
                            &:nth-child(#{$i}) {
                                transition: opacity $t-fast linear calc((#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc((#{$i} * #{$delay}));
                            }
                        }

                        .inner-mediaombiente.inner-mediaombiente{
                            &.isSelected{
                                color: #1197a5;
                            }

                        }
                    }
                }
            }
        }

        .subitem-list {
            border-left: 1px dashed #d6dcde;
            height: 0;
            overflow: hidden;
            transition: height $t-fast $easeOutCubic 0.2s;
            .subitem {
                opacity: 0;
                transform: translateX(#{rem(-25px)});
                transition: opacity $t-fast linear 0s, transform 0s linear 0.2s;
                &.is-active {
                    .subitem-inner {
                        color: $c-fama;
                        text-transform: uppercase;
                        &:before {
                            opacity: 0;
                            background-image: url('/css/assets/arrow-grey.svg');
                        }
                        &:after {
                            opacity: 1;
                            background-image: url('/css/assets/arrow-blue.svg');
                        }
                    }
                }
                .subitem-inner {
                    text-transform: uppercase;
                    padding: rem(10px) 0 rem(10px) rem(50px);
                    display: block;
                    cursor: pointer;
                    font-size: rem(13px);
                    font-weight: normal;
                    font-family: 'Montserrat-SemiBold';
                    color: $gray-dark;
                    position: relative;
                    transition: all 0.2s linear;
                    &:before, &:after {
                        content: '';
                        position: absolute;
                        left: rem(25px);
                        top: rem(13px);
                        height: 7px;
                        width: 10px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        transition: all $t-fast linear;
                    }
                    &:before {
                        opacity: 1;
                        background-image: url('/css/assets/arrow-grey.svg');
                    }
                    &:after {
                        opacity: 0;
                        background-image: url('/css/assets/arrow-blue.svg');
                    }
                    &:hover {
                        color: lighten($c-fama, 5%);
                        &:before {
                            opacity: 0;
                            background-image: url('/css/assets/arrow-grey.svg');
                        }
                        &:after {
                            opacity: 1;
                            background-image: url('/css/assets/arrow-blue.svg');
                        }
                    }
                    img {
                        display: none;
                    }
                }
            }
        }
    }
 
    @media (max-width: 991px) {
    //@include media-breakpoint-down(md) { // Mobile
        position: relative;
        display: flex;
        align-items: flex-start;       
        overflow-x: auto;
        padding-left: $lateral-space-sm;
        flex-direction: row;

        .item{
            padding: 25px 13px 9px;
            border-bottom: 2px solid transparent;
            white-space: nowrap;
            margin-bottom: 0;
            transition: margin $t-fast $easeOutCubic 0.2s;
            border-left: none;

            &::before{
                content: none;
            }

            .item-inner {
                font-size: 15px;
                font-weight: normal;
                color: $gray-dark;
            }

            &.is-active{
                border-bottom: 2px solid $c-fama;
            }

            &.isOpen {
                margin-bottom: rem(220px);
                transition: margin $t-fast $easeOutCubic 0s;
                .item-inner {
                    &:after {
                        display: none;
                    }
                }
                &+.subitem-list {
                    display: flex;
                    align-items: flex-start;
                    padding-top: rem(40px);
                    height: rem(220px) !important;
                    transition: height $t-fast $easeOutCubic 0s;
                    &:before {
                        content: '';
                        position: fixed;
                        left: $lateral-space-sm;
                        bottom: 0;
                        height: 1px;
                        width: calc(100% - (#{$lateral-space-sm} * 2));
                        background-color: $c-gray2;
                    }
                    .subitem {
                        .subitem-inner {
                            opacity: 1;
                            display: block;
                            transform: translateX(0);
                        }
                        $delay: 0.1s;
                        @for $i from 1 through 10 {
                            &:nth-child(#{$i}) {
                                .subitem-inner {
                                    transition: opacity $t-fast linear calc((#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc((#{$i} * #{$delay}));
                                }
                            }
                        }
                    }
                }
            }
        }
        .subitem-list {
            display: flex;
            position: fixed;
            width: 100%;
            left: 0;
            padding: 0 $lateral-space-sm;
            top: rem(51px);
            overflow: auto;
            transition: height $t-fast $easeOutCubic 0.2s;
            .subitem {
                margin-right: rem(15px);
                display: flex;
                align-items: center;
                &:last-child {
                    .subitem-inner {
                        padding-right: $lateral-space-sm;
                    }
                }
                .subitem-inner {
                    display: none;
                    padding: 0 rem(5px);
                    line-height: 1;
                    color: $gray-dark;
                    opacity: 0;
                    transform: translateX(#{rem(-25px)});
                    transition: opacity $t-fast linear 0s, transform 0s linear 0.2s;
                    img {
                        height: rem(125px);
                        width: rem(125px);
                        margin-bottom: rem(6px);
                        margin-left: rem(-5px);
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
        }
        .subitem-list::-webkit-scrollbar {
            display: none;
        }
    }
}

.sidebar-categories::-webkit-scrollbar {
    display: none;
}

//FAMA NEW(2021)

.app-list_2021{
    .sidebar-categories{   
        padding-top: 0 !important;
        position: unset !important;
        top: 0 !important;

        @media (min-width: 992px){
            
        //@include media-breakpoint-up(md) { // Desktop
            .item {          
                border-left: 2px solid lighten(#8fc3c9a5, 10%);
            
                &:before, &:after {               
                    top: 50%;              
                    transform: translateY(-50%) translateX(-58%);                
                }
                &:before {
                    width: 1rem;
                    height: 1rem;
                    background-color: #e1f4f7;
                    border: 2px solid #1197a5;
                    z-index: 1;
                }
                &:after {           
                    content: none;   
                    background-color: transparent;              
                }
                &.is-active {                    
                    &:before {
                        border: none;
                        background-color: #1197a5 !important;
                    }                    
                    .item-inner{
                        color: #1197a5;
                        font-family: 'Montserrat-Bold';
                    }
                }
                .item-inner {                
                    padding: 1.1rem 0 1.1rem 2.5rem;
                    display: block;
                    cursor: pointer;
                    font-size: 1.7rem;
                    font-weight: normal;
                    font-family: 'Montserrat-SemiBold';
                    color: #575252;
                    text-transform: initial;
                    position: relative;
                    transition: all 0.2s linear;

                    h1{
                        //font-weight: 900;
                        font-size: inherit;
                        text-transform: none;
                        line-height: inherit;
                        margin-bottom: 0;
                        font-weight: normal;
                        font-family: 'Montserrat-Bold';
                    }
                    
                    &:hover {
                        color: lighten(#1197a5, 5%);
                    }
                }
                &.has-sublist {                
                    padding-right:3rem;
                    .arrow-dropdown {                    
                        //top: 1.5rem;                    
                        width: 3rem;
                        height: 3rem;  

                        /*
                        &::after{
                            top: 8px;
                        }
                        */
                    }
                    .item-inner {                
                        padding: 1rem 0 1rem 2.5rem;                
                        font-size: 1.7rem;       
                        text-transform: initial;

                        &:hover {
                            color: lighten(#1197a5, 5%);
                        }
                    }

                    &.isSelected {
                        .item-inner {
                            color: #1197a5;
                        }
                    }

                    &.isOpen {
                        &+.subitem-list {
                            height: auto;   
                        }
                    }
                    &.isEntry{
                        &:before {              
                            background-color: #cee4e7;      
                        }
                    }
                }
            }
            .subitem-list {         
                border-left: 2px dashed lighten(#8fc3c9a5, 10%);                
                .subitem {              
                    transform: translateX(-2.5rem);                    
                    &.is-active {
                        .subitem-inner {
                            color: #1197a5;   
                            font-family: "Montserrat-Bold";
                        }
                    }
                    .subitem-inner {
                        text-transform: inherit;
                        padding: 1.1rem 0 1.1rem 4.5rem;                 
                        font-size: 1.5rem;   
                        color: #575252;
                        font-weight: normal;
                        font-family: "Montserrat-SemiBold";

                        &:before, &:after {                      
                            left: 2.5rem;                           
                            top: 50%;
                            transform: translateY(-50%);                
                        }                    
                        &:hover {
                            color: lighten(#1197a5, 5%);                      
                        }  
                        h1{
                            //font-weight: 900;
                            font-size: inherit;
                            text-transform: none;
                            line-height: inherit;
                            margin-bottom: 0;
                            font-weight: normal;
                            font-family: "Montserrat-Bold";
                        }                      
                    }
                }
            }            
        }
       
        @media (max-width: 991px) {
        //@include media-breakpoint-down(lg) { // Mobile     
            .item{            
                border-left: none;

                &.isOpen {
                    margin-bottom: 22rem;   
                    &+.subitem-list {
                        padding-top: 4rem;
                        height: 22rem !important;                    
                        padding-left: 1.5rem;
                        padding-right: 1.5rem;

                        .subitem {
                            .subitem-inner {                        
                                font-size: 1.3rem;
                            }                   
                        }
                    }
                }
                &::before{
                    content: none;
                }
            }
            .subitem-list {       
                top: 5.1rem;    
                margin-right: 1.5rem;       

                .subitem-inner {                
                    padding: 0 .5rem;                  
                    transform: translateX(-2.5rem);                 
                    img {
                        height: 12.5rem;
                        width: 12.5rem;
                        margin-bottom: .6rem;
                        margin-left: -.5rem;                     
                    }
                }
            }
        }    
        @media (min-width: 769px) and (max-width: 991px){
            .item{     
                padding: 25px 13px 2px;
            }
        }
    }
}

.company_2021{
    @include media-breakpoint-up(md) { // Desktop
        .module-list-container{
            height: calc(100% + 15.6rem);

        }
    }
    .text.generic img, .text.generic .img{
        display: block;
    }
    .sidebar-categories{      
        position: -moz-sticky;
        position: -o-sticky;
        position: -ms-sticky;
        position: sticky;
   
    
        @include media-breakpoint-up(md) { // Desktop
            display: flex;
            flex-direction: column;
    
            .item {               
                border-left: 2px solid lighten(#8fc3c9a5, 10%);
                position: relative;
                &:first-of-type{
                    position: relative;
                    border-width: 2px;
                    border-style: solid;
                    border-image: linear-gradient(to top, rgba(177, 213, 218, 0.6470588235) 50%, transparent 50%) 1;
                    border-bottom: none;
                    border-right: none;
                    border-top: none;
                }

                &:last-of-type{
                    position: relative;
                    border-width: 2px;
                    border-style: solid;
                    border-image: linear-gradient(to top, transparent 50%, rgba(177, 213, 218, 0.6470588235) 50%) 1;
                    border-bottom: none;
                    border-top: none;
                    border-right: none;
                }
                &:before, &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;                 
                    transform: translateY(-50%) translateX(-58%);  
                    border-radius: 100%;
                    transition: all $t-normal $easeOutCubic;
                }
                &:before {
                    width: 1rem;
                    height: 1rem;
                    background-color: #e1f4f7;
                    border: 2px solid #1197a5;
                    z-index: 1;
                }
                &:after {           
                    content: none;   
                    background-color: transparent;              
                }
                &.is-active {
                    color: #1197a5;
                    text-transform: uppercase;
                    .item-inner{
                        color: #1197a5;
                        font-family: "Montserrat-SemiBold";
                    }
                    &:before {
                        border: none;
                        background-color: #1197a5 !important;
                    }  
                    h1{
                        font-size: inherit;
                        font-family: "Montserrat-SemiBold";
                        font-weight: normal;
                        margin: 0;
                    }                 
                }
                &.has-sublist {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding-right:3rem;

                    .arrow-dropdown {
                        display: none;
                           &:before, &:after {
                            content: none;
                        }
                    }
                                      
                }
                .item-inner {                
                    padding: 1.1rem 0 1.1rem 2.1rem;
                    display: block;
                    cursor: pointer;
                    font-size: 1.7rem;
                    font-weight: normal;
                    font-family: 'Montserrat-SemiBold';
                    color: #575252;
                    text-transform: initial;
                    position: relative;
                    transition: all 0.2s linear;

                    &:hover {
                        color: lighten(#1197a5, 5%);
                    }
                    h1{
                        font-size: inherit;
                        font-weight: inherit;
                        font-family: inherit;
                        margin: 0;
                    }
                }
    
                &.isSelected {
                    .item-inner {
                        color: #1197a5;
                        text-transform: none;
                    }
                }
    
                &.isOpen {
                   
                    border-image: none;
                    &+.subitem-list {
                        height: auto;
                        transition: height $t-fast $easeOutCubic 0s;
                        .subitem {
                            opacity: 1;
                            transform: translateX(0);
                            $delay: 0.1s;
                            @for $i from 1 through 10 {
                                &:nth-child(#{$i}) {
                                    transition: opacity $t-fast linear calc((#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc((#{$i} * #{$delay}));
                                }
                            }
                        }
                    }
                }
                &.isEntry{
                    &:before {              
                        background-color: #cee4e7;      
                    }
                }
            }
    
            .subitem-list {            
                border-left: 2px dashed lighten(#8fc3c9a5, 10%);
                height: 0;
                overflow: hidden;
                transition: height $t-fast $easeOutCubic 0.2s;
                .subitem {
                    opacity: 0;
                    transform: translateX(-2.5rem);
                    transition: opacity $t-fast linear 0s, transform 0s linear 0.2s;
                    &.is-active {
                        .subitem-inner {
                            color: #1197a5;
                            text-transform: none;     
                            
                            h1{
                                font-size: inherit;
                                font-family: 'Montserrat-SemiBold';
                                //font-family: inherit;
                                font-weight: normal;
                                margin: 0;
                            }
                        }
                    }
                    .subitem-inner {
                        text-transform: inherit;
                        padding: 1.1rem 0 1.1rem 2.1rem;
                        display: block;
                        cursor: pointer;
                        font-size: 1.5rem;
                        font-weight: normal;
                        font-family: 'Montserrat-Medium';
                        color: #575252;
                        position: relative;
                        transition: all 0.2s linear;
                        &:before, &:after {
                            content: none;
                        }
                       
                        &:hover {
                            color: lighten(#1197a5, 5%);
                            &:before, &:after {
                                content: none;
                            }                       
                        }
                        img {
                            display: none;
                        }
                    }
                }
            }
        }
    
    }
   
}