.curriculum-faqs_2021{
    padding-top: 5rem;
    padding-bottom: 10.5rem;

    .nav.faqs-list {
        .result{           
            border-top: 1px solid $colorCorporative; 
            overflow: hidden;          

            .title_curriculumFaq{
                  font-size: 2.5rem;
                  font-family: 'Montserrat-Bold';
                  line-height: 3.1rem;   
                  color: $colorCorporative;
                  position: relative;
                  width: 100%;
                  height: 7rem;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                  &::before{
                    content: "+";
                    font-size: 3rem;              
                    position: absolute;
                    color: inherit;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%) rotate(90deg);
                    height: 3.1rem;
                    transition: .2s all linear;                   
                  }
            }
            .wrap-content{
                display: flex;       
                flex-wrap: wrap;                             
                max-height: 0;
                height: 0;
                opacity: 0;
                transition: .1s .2s opacity linear, .4s max-height linear;

                .column-left, .column-right{
                    width: 50%;
                    padding-right: 7%;
                    img.mvl{
                        display: none;
                    }
                  
                }
                .wrap-collage{
                    width: 100%;
                    padding-bottom: 2rem;

                    .part-up{
                        align-items: flex-end;
                        padding-bottom: 7rem;

                        .text.generic{
                            width: calc(30% + 5rem);
                            padding-right: 5rem;
                            align-self: flex-start;
                        }
                        .aux-part-photo{
                            width: calc(70% - 3rem);
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            transform: translateY(5rem);

                            .wrap-photos_1{
                                width: 100%;
                                height: auto;
                                max-height: 30rem;
                                overflow: hidden;
                                align-self: center;                             

                                .img{
                                    width: auto;
                                    height: 100%;
                                    object-fit: contain;
                                    padding-right: 2rem;
                                    max-height: 30rem;
                                    
                                    &:first-of-type{
                                        padding-top: 2rem;
                                    }
                                }
                            }                        
                               
                        }
                    }
                    .part-center{
                        .wrap-photos_2{
                            width: 100%;
                            .part-left{     
                                width: 50%;

                                .aux{
                                    width: 100%;
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: flex-end;
                                    align-items: baseline;

                                    .img{
                                        width: auto;
                                        height: 19rem;
                                        object-fit: cover;
                                        padding-right: 2rem;
                                        padding-bottom: 2rem;

                                        &:nth-child(1), &:nth-child(2){
                                            max-width: 50%;
                                        }
                                        &:nth-child(1){
                                            margin-top: -9rem;
                                            height: 27rem;
                                        }
                                    }
                                }
                            }
                            .part-right{      
                                width: 50%;                      
                                .img{
                                    height: 38rem;
                                    width: auto;
                                    object-fit: contain;
                                    padding-bottom: 2rem;
                                }
                            }
                        }
                    }
                    &.mvl{
                        display: none;
                    }

                }
            }

            &.isOpen{
                .title_curriculumFaq{     
                    &::before{
                        content: "-";
                        font-size: 3rem;
                        position: absolute;
                        color: inherit;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%) rotate(0deg);
                        height: 3.1rem;
                        transition: .2s all linear;
                    }
                }
                .wrap-content{
                    height: auto;
                    max-height: 9999px;
                    padding-top: 3rem;     
                    padding-bottom: 6rem;
                    opacity:1;
                    transition: .1s .2s opacity linear, .4s max-height linear;
                }

            }
            &:last-of-type{
                border-bottom: 1px solid $colorCorporative; 
            }
        
        }
      
    }
    .text.generic{
        a, a *{
            color: $colorCorporative !important;
            background-color: transparent;
        }
        h3, h3 *{
            font-size: 2.1rem !important;
            color: $colorCorporative !important;
            font-family: 'Montserrat-Bold' !important;
            text-transform: uppercase;
            margin: 0;
        }
        h3{
            padding-top: 1.5rem;    
            padding-bottom: 1.5rem;        
        }
        h4, h4 *{
            font-size: 1.7rem !important;
            font-family: 'Montserrat-Regular' !important;
            text-transform: uppercase;
            color: $colorCorporative !important;
        }
        h5, h5 *{
            font-family: 'Montserrat-Medium' !important;
            text-transform: uppercase;
            color: $colorCorporative !important;
            line-height: 3rem !important;
            font-size: 1.7rem !important;
            letter-spacing: 1.5px !important;      
        }
        h5{
            width: calc(50% + 5rem);
            margin: 0 auto;
            margin-right: 0;          
           

            &:before{
                content: "";
                background-image: url('/images/path-logo2.svg');
                width: 50%;
                height: calc(100% - 3rem);
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                top: -1.5rem;
                left: 0;
            }
        }

        .wrap-medioambiente-work{
            display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;

            .wrap-btnDefault{
                margin: 0 auto;
                margin-right: 0;
                margin-top: 3rem;
                min-width: calc(50% + 5rem);

                .btnDefault{
                    text-align: center;
                    background-color: $colorCorporative;
                    color: #ffffff !important;
                    padding: 1.4rem 4rem;
                    position: relative;
                    font-size: 1.9rem !important;
                    font-family: "Montserrat-Bold" !important;
                    letter-spacing: 0.3rem !important;
                    line-height: 1.7rem !important;
                    text-transform: uppercase;
                    transform: translateX(-0.3rem) translateY(-0.3rem);
                    transition: 0.3s transform linear;
                    z-index: 2;

                    &:active, &:hover{                   
                        transform: translateX(0) translateY(0);
                        transition: .3s all linear;   
                    }
                }               
            }

        }
        img{
            display: block;
            width: 100% !important;
            height: auto;          
            margin-bottom: 3rem;            
            object-fit: contain;         

            &.large{
                width: 150% !important;
                padding-top: 5rem;
            }
        }
    }

}

@media (min-width: 1200px){
    .curriculum-faqs_2021 .text.generic img.large{
        padding-top: 8rem;
    } 
}
@media (min-width: 1350px){
    .curriculum-faqs_2021 .text.generic img.large{
        padding-top: 10rem;
    } 
}

@media screen and (max-width: 1100px){
    .curriculum-faqs_2021 .nav.faqs-list .result .wrap-content{
        .wrap-collage{
            width: 100%;
            padding-bottom: 2rem;
            
            .part-up{
                align-items: flex-end;
                padding-bottom: 11rem;

                .text.generic{
                    width: calc(30% + 5rem);
                    padding-right: 5rem;
                    align-self: flex-start;
                }
                .aux-part-photo{
                    width: calc(70% - 5rem);
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    transform: translateY(9rem);

                    .wrap-photos_1{
                        width: 100%;
                        height: auto;
                        max-height: 30rem;
                        overflow: hidden;
                        align-self: center;                        

                        .img{
                            width: auto;
                            height: 100%;
                            object-fit: contain;
                            padding-right: 2rem;
                            max-height: 30rem;
                            
                            &:first-of-type{
                                padding-top: 2rem;
                                width: 60%;
                                align-self: flex-end;
                            }
                            &:last-of-type{
                                width: 40%;
                                padding-right: 0;
                            }
                        }
                    }                        
                       
                }
            }
            .part-center{
                .wrap-photos_2{
                    width: 100%;
                    .part-left{     
                        width: 50%;

                        .aux{
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-end;
                            align-items: baseline;

                            .img{
                                width: auto;
                                height: 19rem;
                                object-fit: cover;
                                padding-right: 2rem;
                                padding-bottom: 2rem;

                                &:nth-child(1), &:nth-child(2){
                                    max-width: 50%;
                                }
                                &:nth-child(1){
                                    margin-top: -9rem;
                                    height: 27rem;
                                }
                            }
                        }
                    }
                    .part-right{      
                        width: 50%;                      
                        .img{
                            height: 38rem;
                            width: auto;
                            object-fit: contain;
                            padding-bottom: 2rem;
                        }
                    }
                }
            }

        }
    }
}

@media (min-width: 769px) and (max-width: 992px){
    .curriculum-faqs_2021 .nav.faqs-list .result .wrap-content{
        .wrap-collage{
            width: 100%;
            padding-bottom: 2rem;
            
            .part-up{            
                flex-wrap: wrap;
                padding-bottom: 0;

                .text.generic{
                    width: 100%;
                    padding-right: 0;
                }
                .aux-part-photo{
                    width: 100%;
                    transform: none;
                    margin: 0 auto;
                    margin-right: 0;
                    padding-bottom: 2rem;
                    padding-top: 3rem;

                    .wrap-photos_1{
                        width: 100%;
                        height: auto;
                        max-height: 30rem;
                        overflow: hidden;
                        align-self: center;
                        //justify-content: center;

                        .img{
                            width: auto;
                            height: 100%;
                            object-fit: contain;
                            padding-right: 2rem;
                            max-height: 30rem;
                            
                            &:first-of-type{
                                padding-top: 2rem;
                                width: 60%;
                                align-self: flex-end;
                                max-height: unset;
                            }
                            &:last-of-type{
                                width: 40%;
                                padding-right: 0;
                                margin: 0 auto;
                                margin-left: 0;
                                object-position: left;
                            }
                        }
                    }                        
                       
                }
            }
            .part-center{
                .wrap-photos_2{
                    width: 100%;
                    .part-left{     
                        width: 50%;

                        .aux{
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-end;
                            align-items: baseline;

                            .img{
                                width: auto;
                                height: 19rem;
                                object-fit: cover;
                                padding-right: 2rem;
                                padding-bottom: 2rem;

                                &:nth-child(1), &:nth-child(2){
                                    max-width: 50%;
                                }
                                &:nth-child(1){
                                    margin-top: 0;
                                    height: 19rem;
                                }
                            }
                        }
                    }
                    .part-right{      
                        width: 50%;                      
                        .img{
                            height: 38rem;
                            width: auto;
                            object-fit: contain;
                            padding-bottom: 2rem;
                        }
                    }
                }
            }

        }
    }
}

@media screen and (max-width: $sm-width){
    .curriculum-faqs_2021{
        padding-top: 4rem;
        padding-bottom: 8.5rem;
    
        .nav.faqs-list {
            .result{            
                border-top: 1px solid $colorCorporative; 
                overflow: hidden;          
    
                .title_curriculumFaq{
                    font-size: 1.9rem;
                    font-family: "Montserrat-Bold";
                    line-height: 2.7rem;
                    color: $colorCorporative;
                    position: relative;
                    width: 100%;
                    min-height: 5rem;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    height: auto;
                    padding: 1.5rem 0;
                    padding-right: 2rem;
    
                      &::before{
                        content: "+";
                        font-size: 2.5rem;
                        position: absolute;
                        color: inherit;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%) rotate(90deg);
                        height: 3.1rem;
                        transition: .2s all linear;
                      }
                }
                .wrap-content{                  
                    flex-direction: column;       
               
                    .column-left, .column-right{
                        width: 100%;
                        padding-right: 0;
                        img.mvl{
                            display: block;
                        }
                        img.desc{
                            display: none;                            
                        }
                    }
                    .column-right{
                        padding-top: 3rem;
                    }
                    .wrap-collage{
                        &.desc{
                            display: none;
                        }
                        &.mvl{
                            display: flex;
                            flex-direction: column;

                            .item:nth-child(even){
                                width: auto;
                                max-width: 80%;
                                margin: 0 auto;
                                margin-right: 0;
                                max-height: 24.5rem;
                               
                                .img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                    max-height: 24.5rem;
                                    padding-bottom: 1.5rem;                                   
                                    object-position: right;
                                }
                            }
                            .item:nth-child(odd){
                                width: auto;
                                max-width: 80%;
                                margin: 0 auto;
                                margin-left: 0;
                                max-height: 24.5rem;
                                
                                .img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                    max-height: 24.5rem;
                                    padding-bottom: 1.5rem;                                   
                                    object-position: left;
                                }
                            }
                            .item:nth-child(1){
                                order: 6;
                                margin: 0 auto;
                                margin-right: 0;
                                .img{
                                    object-position: right;
                                }
                            }
                            .item:nth-child(2){
                                order: 5;
                                margin: 0 auto;
                                margin-left: 0;
                                .img{
                                    object-position: left;
                                }
                            }


                            // slider(no se usa)

                            &.slider-workWithUs{
                                height: 50vh;

                                .item .wrap-img{
                                    padding-top: unset;                        
                                    margin-bottom: 0;
                                    display: flex;
                                    justify-content: center;                              
                                    align-items: center;
                                    height: 100%;

                                    .img{                                     
                                        position: relative;
                                        width: 100%;
                                        height: 100%;
                                        object-fit: contain;
                                        max-height: 50vh;
                                    }
                                }
                                .slick-list{
                                    padding: 0 !important;
                                }
                                .slick-arrow{
                                    z-index: 1;
                                }
                        
                                .slick-prev	{
                                   
                                    left: 1rem;
                                    display: none;
                                    width: 4rem;
                                    height: 4rem;
                                    opacity: 1;
                                    
                                    &:before{
                                        content: '';
                                        background-image: url('/images/left.svg');
                                        width: 100%;
                                        height: 100%;
                                        position: absolute;
                                        background-repeat: no-repeat;
                                        background-position: center;
                                        background-size: contain;
                                        top: 0;
                                        left: 0;
                                    }
                        
                                }
                                .slick-next	{
                                    
                                    right: 1rem;
                                    display: none;
                                    width: 4rem;
                                    height: 4rem;
                                    opacity: 1;
                        
                                    &:before{
                                        content: '';
                                        background-image: url('/images/right.svg');
                                        width: 100%;
                                        height: 100%;
                                        position: absolute;
                                        background-repeat: no-repeat;
                                        background-position: center;
                                        background-size: contain;
                                        top: 0;
                                        left: 0;
                                    }
                                }
                        
                            }
                        }                      
                        
                    }
                    
                }  
                &.isOpen{
                    .wrap-content{  
                       padding: 2rem 0;     
                       padding-bottom: 4rem;  
                    }  
                  
                    .title_curriculumFaq{     
                        &::before{
                            content: "-";
                            font-size: 2.5rem;
                            position: absolute;
                            color: inherit;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%) rotate(0deg);
                            height: 3.1rem;
                            transition: .2s all linear;
                        }
                    }    
                        
                }
          
            }
          
        }
        .text.generic{
            h3, h3 *{
                font-size: 1.7rem !important;            
            }
            h3{
                padding-top: 1rem;        
                padding-bottom: 1.5rem;   
            }
            h4, h4 *{
                font-size: 1.5rem !important;            
            }
            img{               
                margin-bottom: 0;
                &.large{
                    width: 150% !important;
                    padding-top: 5rem;
                }
                &.large.desc{
                    display: none;
                }
            }
            h5, h5 *{
                font-family: 'Montserrat-Medium' !important;
                text-transform: uppercase;
                color: $colorCorporative !important;
                line-height: 3rem !important;
                font-size: 1.7rem !important;
                letter-spacing: 1.5px !important;      
            }
            h5{
                width: 75%;            
                margin: 0 auto;     
    
                &:before{
                    content: "";
                    background-image: url('/images/path-logo2.svg');
                    width: 18rem;
                    height: 18rem;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: -2rem;
                    left: 0;
                    z-index: 1;
                }
            }

            .wrap-medioambiente-work{              
                padding-top: 7rem;
    
                .wrap-btnDefault{                    
                    min-width:75%;
                    margin: 0 auto;
                    margin-top: 3rem;
    
                    .btnDefault{
                        text-align: center;
                        background-color: $colorCorporative;
                        color: #ffffff !important;
                        padding: 1.4rem 4rem;
                        position: relative;
                        font-size: 1.7rem !important;
                        font-family: "Montserrat-Bold" !important;
                        letter-spacing: 2.68px !important;
                        line-height: 1.7rem !important;
                        text-transform: uppercase;
                        transform: translateX(-0.3rem) translateY(-0.3rem);
                        transition: 0.3s transform linear;
                        z-index: 2;
    
                        &:active, &:hover{                   
                            transform: translateX(0) translateY(0);
                            transition: .3s all linear;   
                        }
                    }               
                }
    
            }
        }
    
    }
        
}

