.container.home-concurso{
    overflow: visible;
    padding-bottom: 13.8rem;
    
    .section-concurso-wrap{
        .wrap-text{
            display: flex;
            justify-content: center;
            .text{
                color: transparent;
                -webkit-text-stroke: 1px $colorCorporative;
                stroke: 2px grey;
                font-size: 20rem;
                margin: 0 auto;
                padding: 0 2.3rem;                    
                font-family: 'Montserrat-SemiBold';                
                z-index: 2;
            }        
        }
        .slider-concurso{            
            .slick-slider.slider-home-concurso{    
                height: auto;           
                max-height: 40vh; 
                position: relative;    
                .slick-list{
                    height: 100%;
                    cursor: grab;                                
                    &.dragging, &.draggable:active:hover {
                        cursor: grabbing;
                    }
                    .slick-track{
                        height: 100%;
                        .slick-slide{
                            height: 100%;  
                            position: relative;  
                         
                            .item{
                                position: relative;
                                height: auto;
                                display: flex !important;                 
                                margin-left: 3.5rem;
                                margin-right: 0;                               
                                width: auto;                      
                                transition: .1s all linear ;
                                .img{                                       
                                    width: 100%;
                                    height: 100%;                                    
                                    object-fit: contain;
                                    max-height: 40vh;
                                    min-height: 40vh;
                                } 
                            }   
                        }                       
                    }
                } 
                .slick-next, .slick-prev{
                    opacity: 0;                   
                    transition: .3s all ease;                                  
                }
         
                &>.slick-next{                                     
                    width: calc((100% - 47vw - (3.5rem * 3)) / 2);
                    right: 0;
                    height: 100%;
                    &:before{
                        content: '';
                        background-image: url('/images/right.svg');
                        width: 4rem;
                        height: 4rem;
                        position: absolute;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        top: 50%;
                        right: 2.5rem;
                        transform: translateY(-50%);
                    }
        
                }
                &>.slick-prev{                    
                    width: calc((100% - 47vw - (3.5rem * 3)) / 2);
                    left: 0;
                    height: 100%;
                    &:before{
                        content: '';
                        background-image: url('/images/left.svg');
                        width: 4rem;
                        height: 4rem;
                        position: absolute;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 2.5rem;
                    }
        
                }
                 &:hover{
                    .slick-next, .slick-prev{
                        opacity: 1;
                        transition: .3s all ease;
                    }
                } 
            }
        }
    }
    .info-concurso{
        font-family: "Montserrat-Regular";
        font-size: 2.1rem;
        line-height: 3.1rem;
        color: $gray;
        width: 34%;
        max-width: 42.7rem;
        text-align: center;
        margin: 0 auto;
        padding-bottom: 3.5rem;

    }
}
@media (min-width: calc(#{$md-width} + 1px)) and (max-width: 1300px) {
    .container.home-concurso{  
        .section-concurso-wrap{
            .wrap-text{     
                .text{            
                    stroke: 2px grey;             
                    margin: 0 auto;
                    padding: 0 .3rem; 
                    width: auto;
                    overflow: hidden;
                    font-size: 15.5vw;
                }        
            }   
        }
    }
}
@media (max-width: $md-width) {
    .container.home-concurso{  
        .section-concurso-wrap{
            .wrap-text{     
                .text{            
                    stroke: 2px grey;                   
                    margin: 0 auto;
                    padding: 0 .3rem; 
                    width: auto;
                    overflow: hidden;
                    font-size: 15.6vw;
                }        
            }   
        }
    }
}

@media (max-width: $sm-width) {
    .container.home-concurso{  
        padding-top: 3rem;      
        padding-bottom: 8.4rem;   
        .carousel-box{
            position: relative;
            height: 150rem;
            .js-carousel-box-wrapper{               
                position: -moz-sticky;
                position: -o-sticky;
                position: -ms-sticky;
                position: sticky;
                position: -webkit-sticky;
                bottom: unset;
                top: 7vh;
            }
        }     
        .section-concurso-wrap{
            &.wrap-animate{    
                .animate{
                    opacity: 0;
                    transform: translateY(-7rem);     
                    transition: 0.8s all linear;       
                    &.isVisible{
                        opacity: 1;
                        transform: translateY(2.3rem);     
                        transition: 0.8s all linear;                   
                    } 
                }              
            }
            .slider-concurso{            
                .slick-slider.slider-home-concurso{ 
                    max-height: 18rem; 
                    position: relative;    
                    .slick-list{                       
                        .slick-track{                           
                            .slick-slide{                              
                                .item{                                               
                                    margin-left: 1.4rem;
                                    margin-right: 0;                                   
                                    width: auto;
                                    .img{                                       
                                        width: 100%;
                                        height: 100%;                                       
                                        object-fit: contain;
                                        max-height: 18rem;
                                        min-height: 18rem;
                                    } 
                                }   
                            }                        
                        }
                    } 
                    .slick-next, .slick-prev{
                        width: calc((100% - 75vw - 1.4rem) / 2);
                        z-index: 1;            
                        height: 100%;
                        opacity: 1;        
                    }
                    .slick-next{
                        right: 0;                        
                        &::before{
                            left: unset;
                            right: 1.5rem; 
                        }                   
                    }
                    .slick-prev{
                        left: 0;
                        &::before{
                            left: 1.5rem; 
                        }
                    }
                }
            }
        }
        .info-concurso{
            font-family: "Montserrat-Regular";
            font-size: 1.7rem;
            line-height: 2.5rem;
            color: $gray;
            width: 100%;
            max-width: 100rem;
            text-align: center;
            margin: 0 auto;
            padding: 0 1.5rem;
            padding-bottom: 5rem;
    
        }
    }
}