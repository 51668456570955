.section-empresa.sofas.sofas-rscTop{
    &.section-rsc{
        .wrap-content{
            .wrap-title{
                width: 100%;

                .title, .subtitle{
                    width: 100%;
                    padding-right: 0;
                }
            }
            .wrap-up{
                width: 100%;
                padding-bottom: 4.3rem;
                .part-left{
                    width: 60%;
                    padding-right: 2.5rem;
                }
                .part-right{
                    width: 40%;
                    padding-left: 2.5rem;
                }
            }
            .wrap-center{
                width: 100%;
                padding-bottom: 10.6rem;
                .part-left{
                    width: 40%;
                    padding-right: 2.5rem;
                }
                .part-right{
                    width: 60%;
                    padding-left: 2.5rem;
                    &.only{
                        width: 100%;
                        padding-left: 0;
                    }
                }
            }

        }
    }
}

//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {
    .section-empresa.sofas.sofas-rscTop{
        &.section-rsc{
            .wrap-content{
                .generic.text, .generic.text p, .generic{
                    color: inherit;
                    font-size: 1.7rem;
                    line-height: 2.5rem;
                }
                .wrap-title{
                    width: 100%;    
                    .title, .subtitle{
                        width: 100%;
                        padding-right: 0;
                    }
                }
                .wrap-up{
                    width: 100%;
                    padding-bottom: 4.3rem;
                    flex-direction: column;
                    .part-left{
                        width: 100%;
                        padding-right: 0;
                    }
                    .part-right{
                        width: 100%;
                        padding-left: 0;
                        .wrap-img{
                            margin-top: 4rem;
                        }
                    }
                }
                .wrap-center{
                    width: 100%;
                    padding-bottom: 6.2rem;
                    flex-direction: column;
                    .part-left{
                        width: inherit;
                        order: 2;
                        padding-top: 4rem;
                        padding: 0;
                        padding-right: 0;
                    }
                    .part-right{
                        width: inherit;
                        order: 1;
                        padding: 0;
                        padding-left: 0;
                        padding-bottom: 4rem;
                    }
                }
    
            }
        }
    }
}