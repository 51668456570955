//Schema
// In this case, is a variant of real corporative colour.
//$colorCorporative: #00A5B8;
$colorCorporative2: #c03d8b;
$colorCorporativeGrey: #575252;

$colorWhite: #FFF;
$colorGray: #999;
$colorBlack: #333333;
$colorBlack2: #666;

//Principals colours
$colorPrimary: $colorCorporative;
$colorDanger: #FF007F;
$colorSuccess: #2ECC71;
$colorInfo: $colorCorporative;
$colorWarning: gold;
$colorMuted: $colorBlack;

$colorMicrorelatos: #ff8037;


//Default
$colorLinkNormal: $colorBlack2;
$colorLinkHover: $colorGray;
$colorLinkActive: $colorCorporative;
$colorText: $colorMuted;
$colorText2: $colorGray;
$backgroundTextNormal: $colorWhite;
$btn-default-color: $colorBlack;
$btn-primary-bg: $colorWhite;

//Header schema
$colorHeaderLinkNormal: $colorGray;
$colorHeaderLinkHover: $colorBlack2;
$colorHeaderLinkActive: $colorCorporative;
$backgroundColorNavbar: $colorWhite;

//Inverse
$colorLinkNormalInverse: $colorWhite;
$colorLinkHoverInverse: $colorWhite;
$colorLinkActiveInverse: $colorCorporative;
$backgroundColorNavbarInverse: transparent;
$backgroundBody: #DCDCDC;

//Menu Detail Product
$colorMenuItemText: $colorMuted;
$colorMenuItemTextHover: $colorWhite;
$colorMenuItemTextActive: $colorWhite;
$colorMenuItemBackground: $colorWhite;
$colorMenuItemBackgroundHover: $colorCorporative;

//Extra BG colour
$bgRed: #ED0000;
$bgBlue: $colorCorporative;

//OLD COLORS
$blue: #3a80be; //modified
$indigo: #6574cd;
$purple: #9561e2;
$pink: #c61083;
$red: #e3342f;
$orange: #ff8c41; //modified
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$white: #ffffff;

/////////////////////
// SOCIAL COLOURS
// by https://designpieces.com/2012/12/social-media-colours-hex-and-rgb/
// Last update 13/3/2017
///////////////////////////
$colorRSS: #ff6600;
$colorFacebook: #3b5998;
$colorTwitter: #55acee;
$colorYoutube: #cd201f; //https://www.youtube.com/yt/brand/color.html
$colorGoogle: #dd4b39;
$colorInstagram:#e95950;
$colorInstagram2:#125688;
$colorWhatsApp: #4dc247;
$colorLinkedin: #007bb5;
$colorPinterest: #cb2027;
$colorVine: #00bf8f;
$colorSnapchat: #fffc00;
$colorQuora: #fffc00;
$colorDropbox: #007ee5;
$colorFlickr: #ff0084;
$colorTumblr: #32506d;
$colorVK: #45668e;
$colorVimeo: #aad450;
$colorFoursquare: #0072b1;
$colorSpotify: #00e461;
$colorGithub: #000;
$colorBehance: #1769ff;



//FAMA_new_2021
//$colorCorporative:#1197a5; 
$colorCorporative:#1197a5; 
$colorCorporative_famaliving: #c70087;

$c-fama: $colorCorporative;
$gray: #575252;
$gray-ligth:#f3f3f3; 
$line-gray: rgba(#3f6574, 0.5);
$pink:#c95a9c;

$err: #e95950;

$bg-grey: #f3f6f7;