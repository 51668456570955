.section-empresa.sofas.sofas-international{
    &.section-one-paragraphs-left-img{
        .wrap-content{
            .part-up{
                width: 100%;
                .wrap-title{
                    width: 55%;
                }
            }
            .part-content{
                .part-left{
                    width: 55%;
                    .aux-slider{                       
                        width: 100%;
                        overflow: hidden;
                        .wrap-slider{
                            width: calc(100% + 3.5rem);
                        }
                        .aux-box{
                            margin-bottom: 3rem;
                        }
                    }
                    .slider-international.slick-slider{
                        .slick-list{
                            padding: 0 !important;
                            cursor: grab;                                
                            &.dragging, &.draggable:active:hover {
                                cursor: grabbing;
                            }
                            .slick-slide{                            
                                .item{
                                    position: relative;
                                    padding-top: 62.5%;                                   
                                    .img{
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }
                        }
                    }
                }
                .part-right{
                    width: 45%;
                    padding-left: 3.5rem;
                }
            }
        }
    }
}

//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {
    .section-empresa.sofas.sofas-international{
        &.section-one-paragraphs-left-img{
            .wrap-content{
                .generic.text{
                    font-size: 1.7rem;
                    line-height: 2.5rem;
                    color: #000;
                }
                .part-up{
                    width: 100%;
                    .wrap-title{
                        width: 100%;
                        .title{
                            font-size: 8.5vw;
                            padding-bottom: .5rem;
                        }
                    }
                }
                .part-content{
                    flex-direction: column;
                    .part-left{
                        width: 100%;
                        .aux-slider{                         
                            padding-bottom: 1.7rem;
                            .wrap-slider{
                                width: calc(100% + 1rem);
                            }
                            .aux-box{
                                margin-bottom: 3.5rem;                                
                            }
                        }
                        .slider-international.slick-slider{
                            .slick-list{
                                padding: 0 !important;
                                .slick-slide{                               
                                    .item{
                                        position: relative;
                                        padding-top: 62.5%;   
                                        margin-right: 1rem;                                     
                                        .img{
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .part-right{
                        width: 100%;
                        padding-left: 0;
                    }
                }
            }
        }
    }
}