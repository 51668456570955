.app-detail_2021{

.modules-menu
{
    margin-bottom: 14.5rem;

    @include media-breakpoint-down(sm)
    {
        margin-bottom: 6.1rem;
    }
}
.modules-normal
{
    margin-bottom: 0;

    .module-full
    {
        margin-bottom: 7rem;

        @include media-breakpoint-down(sm)
        {
            margin-bottom: 6.1rem;
        }
    }

    .module-title
    {
        font-size: $f-size-big-title_2021;
        font-weight: bold;

        @include media-breakpoint-down(sm)
        {
            font-size: 1.7rem;


        }
    }

    @include media-breakpoint-down(sm)
    {
        margin-bottom: 2rem;
        .deco-line-both
        {
            padding-left: 2rem;
        }
    }

}
.module
{
    position: relative;

    &[id^="mod-simulacion-2d"] .module-image{
        margin-left: 0 !important;
        img{
            max-width: 100%;
        }
    }

    .module-header
    {
        position: relative;

        &:before
        {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            border-style: solid;
            border-color: $c-gray2;
            border-width: 0;
            z-index: 2;
        }
    }
    .module-wrap {
        max-width: unset !important;
        width: 100% !important;

        .module-title{
            font-size: 3rem;
        }
    }

    .popup-360 {
        position: fixed;
        z-index: 1000;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.9);
        visibility: hidden;
        opacity: 0;
        transition: opacity $t-fast linear 0.4s, visibility 0s linear 0.4s;
        &.isVisible {
            opacity: 1;
            visibility: visible;
            transition: opacity $t-fast linear 0s, visibility 0s linear 0s;
            .container-360 {
                opacity: 1;
                visibility: visible;
                transform: translate(-50%, -50%);
                transition: opacity $t-fast linear 0.2s, visibility 0s linear 0.2s, transform $t-normal $easeOutCubic 0.2s;
            }
        }
        .container-360 {
            position: absolute;
            display: flex;
            flex-direction: column;
            background-color: #fff;
            width: 128rem;
            height: 70rem;
            max-width: 90%;
            max-height: 90%;
            top: 47%;
            left: 50%;
            opacity: 0;
            visibility: hidden;
            transform: translate(-50%, calc(-50% - 5rem));
            transition: opacity $t-fast linear 0s, visibility 0s linear 0.4s, transform $t-normal $easeOutCubic 0s;
            .header-360 {
                height: 6rem;
                display: flex;
                align-items: center;
                background-color: $gray-ligth;
                .title-360 {
                    flex-grow: 1;
                    font-size: 2.7rem;
                    display: flex;
                    align-items: baseline;
                    padding: 0 2.5rem;
                    color: $colorCorporative;
                    &:before {
                        content: '';
                        width: 9.5rem;
                        height: 3rem;
                        margin-right: 2rem;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        background-image: url('/css/assets/fama-logo.png');
                    }
                }
                .close-360 {
                    display: flex;
                    align-items: center;
                    padding-left: 1rem;
                    width: 14rem;
                    height: 100%;
                    min-width: 14rem;
                    font-weight: 500;
                    font-size: 1.5rem;
                    text-transform: uppercase;
                    color: rgba($gray-dark, 0.5);
                    cursor: pointer;
                    position: relative;
                    transition: all $t-fast linear;
                    &:before {
                        content: '';
                        position: relative;
                        margin-right: 1.5rem;
                        width: 1.7rem;
                        min-width: 1.7rem;
                        height: 1.7rem;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        background-image: url('/css/assets/x-air.svg');
                    }
                    &:hover {
                        background-color: rgba(0,0,0,0.05);
                    }
                }
            }
            .body-360 {
                position: relative;
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                iframe {
                    width: 100%;
                    height: 100%;
                    border: 0 !important;
                    outline: 0 !important;
                }
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 2rem;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 8.2rem;
                    height: 5rem;
                    pointer-events: none;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    background-image: url('/css/assets/360spin.svg');
                }
            }
        }
    }
    &.isActive, &.is-open {
        .slider2.isInitialized .slick-slide .item.image-loaded{
            position: absolute;
            height: 100%;
        }
    }
    .noSlider3 .item{
        position: relative;
        
        .wrap-info{
            position: absolute;
            height: fit-content !important;
            bottom: 0;
            color: #fff;
            backdrop-filter: blur(5px);
            background-color: hsla(0,0,0,.5);
            padding: 1rem;
            max-width: 70%;
            margin: 0;
            font-size: 1.3rem;

            @include media-breakpoint-down(sm){
                display: none;
            }
        }
    }


    &.isActive, &.is-open {
        .module-header .module-title,
        .module-next,
        .module-tabs-nav .item,
        .module-tab.isActive a img,
        .module-tab.isActive .tab-title, .module-tab.isActive .tab-text,
        .module-stat .module-stat-title, .module-stat .module-stat-text, .module-stat .module-stat-icon, .module-stat .module-stat-image,
        .slider2.isInitialized .slick-slide .item.image-loaded,
        .slider2-thumb.isInitialized .slick-slide .item.image-loaded,
        .noSlider3 .item >*,
        .module-accordion .card,
        .module-products .item, .module-products .item .title, .module-products .item .text, .module-products .item .more-info,
        .module-documents .module-documents-item .icon, .module-documents .module-documents-item .title, .module-documents .module-documents-item .text,
        .images-grid .image-grid-item img
        {
            opacity: 1;
            transform: translate(0, 0);

        }
        .module-header {
            &:before {
                width: 41rem;
                transition: width $t-fast $easeOutCubic 0.6s;
            }
            .module-title {
                transition: opacity $t-fast linear 0.2s, transform $t-normal $easeOutCubic 0.2s;
            }
        }
        .module-next {
            transition: opacity $t-fast linear 0.8s, transform $t-normal $easeOutCubic 0.8s;
        }
        .module-tabs-nav {
            .item {
                transition: opacity $t-fast linear 1.4s, transform $t-normal $easeOutCubic 1.4s, color $t-fast linear 0s, background-color $t-fast linear 0s;
                $delay: 0.1s;
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), color $t-fast linear 0s, background-color $t-fast linear 0s;
                    }
                }
            }
        }
        .module-tab {
            &.isActive {
                a {
                    img {
                        transition: opacity $t-fast $easeOutCubic 1.4s, transform $t-normal $easeOutCubic 1.4s;
                    }
                    $delay: 0.1s;
                    @for $i from 1 through 10 {
                        &:nth-child(#{$i}) img {
                            transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay}));
                        }
                    }
                }
                .tab-title {
                    transition: opacity $t-fast linear 0.6s, transform $t-normal $easeOutCubic 0.6s;
                }
                .tab-text {
                    transition: opacity $t-fast linear 0.7s, transform $t-normal $easeOutCubic 0.7s;
                }
            }
        }
        .module-stat {
            .module-stat-image {
                transition: opacity $t-fast $easeOutCubic 1.3s, transform $t-normal $easeOutCubic 1.3s;
            }
            .module-stat-title {
                transition: opacity $t-fast $easeOutCubic 1.4s, transform $t-normal $easeOutCubic 1.4s;
            }
            .module-stat-text {
                transition: opacity $t-fast $easeOutCubic 1.5s, transform $t-normal $easeOutCubic 1.5s;
            }
            .module-stat-icon {
                transition: opacity $t-fast $easeOutCubic 1.6s, transform $t-normal $easeOutCubic 1.6s;
            }
            $delay: 0.1s;
            @for $i from 1 through 10 {
                &:nth-child(#{$i}) {
                    .module-stat-image {
                        transition: opacity $t-fast $easeOutCubic calc(0.3s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.3s + (#{$i} * #{$delay}));
                    }
                    .module-stat-title {
                        transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay}));
                    }
                    .module-stat-text {
                        transition: opacity $t-fast $easeOutCubic calc(0.5s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.5s + (#{$i} * #{$delay}));
                    }
                    .module-stat-icon {
                        transition: opacity $t-fast $easeOutCubic calc(0.6s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.6s + (#{$i} * #{$delay}));
                    }
                }
            }
        }
        .slider2 {
            .slick-slide {
                .item {
                    transition: opacity $t-fast $easeOutCubic 0.4s, transform $t-normal $easeOutCubic 0.4s;
                }
            }
        }
        .slider2-thumb {
            .slick-slide {
                .item {
                    transition: opacity $t-fast $easeOutCubic 1.4s, transform $t-normal $easeOutCubic 1.4s;
                }
                $delay: 0.1s;
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) .item {
                        transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay}));
                    }
                }
            }
        }

        .noSlider3 {
            .item {
                >* {
                    transition: opacity $t-fast $easeOutCubic 1.4s, transform $t-normal $easeOutCubic 1.4s;
                }
                $delay: 0.1s;
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) >* {
                        transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay}));
                    }
                }
            }
        }
        .module-accordion
        {
            .card
            {
                transition: opacity $t-fast $easeOutCubic 1.4s, transform $t-normal $easeOutCubic 1.4s;
                $delay: 0.1s;
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay}));
                    }
                }
            }
        }
        .module-products {
            .col-12.col-md-6 {
                .item {
                    transition: opacity $t-fast $easeOutCubic 1.2s, transform $t-normal $easeOutCubic 1.2s, background-color $t-fast linear 0s;
                    .title {
                        transition: opacity $t-fast $easeOutCubic 1.4s, transform $t-normal $easeOutCubic 1.4s;
                    }
                    .text {
                        transition: opacity $t-fast $easeOutCubic 1.5s, transform $t-normal $easeOutCubic 1.5s;
                    }
                    .more-info {
                        transition: opacity $t-fast $easeOutCubic 1.6s, transform $t-normal $easeOutCubic 1.6s;
                    }
                }
                $delay: 0.1s;
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) .item {
                        transition: opacity $t-fast $easeOutCubic calc(0.2s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.2s + (#{$i} * #{$delay})), background-color $t-fast linear 0s;
                        .title {
                            transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay}));
                        }
                        .text {
                            transition: opacity $t-fast $easeOutCubic calc(0.5s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.5s + (#{$i} * #{$delay}));
                        }
                        .more-info {
                            transition: opacity $t-fast $easeOutCubic calc(0.6s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.6s + (#{$i} * #{$delay}));
                        }
                    }
                }
            }
        }
        .module-documents {
            .col-12.col-md-5 {
                .module-documents-item {
                    .icon
                    {
                        transition: opacity $t-fast $easeOutCubic 1.4s, transform $t-normal $easeOutCubic 1.4s;
                    }
                    .title
                    {
                        transition: opacity $t-fast $easeOutCubic 1.5s, transform $t-normal $easeOutCubic 1.5s;
                    }
                    .text
                    {
                        transition: opacity $t-fast $easeOutCubic 1.6s, transform $t-normal $easeOutCubic 1.6s;
                    }
                }
                $delay: 0.1s;
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) .module-documents-item {
                        .icon
                        {
                            transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay}));
                        }
                        .title
                        {
                            transition: opacity $t-fast $easeOutCubic calc(0.5s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.5s + (#{$i} * #{$delay}));
                        }
                        .text
                        {
                            transition: opacity $t-fast $easeOutCubic calc(0.6s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.6s + (#{$i} * #{$delay}));
                        }
                    }
                }
            }
        }
        .images-grid {
            .image-grid-item {
                img {
                     transition: opacity $t-fast $easeOutCubic 1.2s, transform $t-normal $easeOutCubic 1.2s;
                }
                $delay: 0.1s;
                @for $i from 1 through 6 {
                    &:nth-child(#{$i}) img{
                        transition: opacity $t-fast $easeOutCubic calc(0.6s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.6s + (#{$i} * #{$delay}));
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm)
    {
        display: block !important;
        padding-left: 0;
        padding-right: 0;

        .presentation-container {
            padding-bottom: 13rem !important;
            .presentation-price-container {
                position: absolute;
                bottom: 0;
                left: 0;
                padding-left: 2rem;
                padding-right: 2rem;
                height: 10.5rem;
                width: 100%;
                display: flex;
                align-items: center;
                &.presentation-price-container-gb-region-selector {
                    height: auto;
                }
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    height: 1px;
                    width: calc(100% - 4rem);
                    background-color: $c-gray2;
                }
                .module-text {
                    display: flex;
                    align-items: flex-end;
                    width: 100%;
                    strong {
                        line-height: 1;
                    }
                    >*:last-child {
                        margin-left: 2rem;
                    }
                }
            
            }
            .col-auto{
                padding: 0;
            }
        }

        .module-body
        {
            display: none;
            padding-left: 2rem;
            padding-right: 2rem;

            .module-wrap
            {
                padding-bottom: 2rem;
                padding-top: 2rem;

            }
            overflow: hidden;
            transition: max-height 0.4s ease-in-out;
        }

        .module-image {
            margin: 0 !important;
            width: 100%;

            img {
                width: 100%;
            }
        }

        &:nth-child(2n) .module-header
        {
            background-color: rgba($blue, 0.1);
        }

        &:nth-child(2n + 1) .module-header
        {
            background-color: rgba($blue, 0.05);
        }

        .module-header
        {
            min-height: 7.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 2rem;

            .module-title
            {
                font-family: 'Montserrat-Regular';
                font-weight: bold;
                font-size: 1.7rem;
                color: $gray-dark;
                text-decoration: none;
                margin: 0;
            }

            &:after
            {
                content: '';
                background-image: url('/img/accordion/m-down.png');
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                right: 4.5rem;
                transform: rotate(0);
                display: block;
                width: 1.3rem;
                height: 1.3rem;
            }
        }

        .module-video
        {
            max-width: 100%;

            iframe
            {
                max-width: 100% !important;
            }
        }
        .module-documents {
            .col-12.col-md-5{
                padding: 0;
                .module-documents-item {
                    margin-bottom: 1rem;
                    .content{
                        padding: 1rem;
                        min-height: 2rem;
                    }
                    
                }
            }
        }
        &.is-open
        {
            .module-header
            {
                background-color: white;

                &:before
                {
                    right: 0;
                    left: 2rem;
                    width: calc(100% - 4rem);

                    height: 1px;
                    border-bottom-width: 1px;
                }

                &:after
                {
                    transform: rotate(180deg);
                }



                .module-title
                {
                    color: $colorCorporative;
                    text-decoration: none;
                }
            }

            .module-body
            {
                pointer-events: all;
            }
        }

        .module-next
        {
            display: none;
        }

        .module-video
        {
            margin-bottom: 2rem;
        }

        .module-tabs
        {
            overflow: hidden;
            max-width: calc(100% + (2rem * 2));
            width: calc(100% + (2rem * 2));
            margin-left: -2rem;
            padding: 0 2rem;

            .tab-title,
            .tab-text
            {
                padding-left: 0px;
            }

            .tab-title
            {
                font-size: $f-size-small_2021;
                font-family: Arvo, Arial, Tahoma;
                font-weight: bold;
                color: $colorCorporative;
                text-transform: uppercase;
                margin-bottom: 2.2rem;
                line-height: 1.2;
            }
        }

        .module-tabs-nav
        {
            display: flex;
            align-items: center;
            max-width: 100vw;
            width: 100vw;
            margin: 0;
            margin-left: -2rem;
            padding: 0 2rem;
            flex-wrap: nowrap;
            overflow-x: auto;

            > .item
            {
                background-color: rgba($gray-dark, 0.1);

                border-radius: 1.1rem;
                padding: .5rem 1rem;
                margin-right: 2.3rem;

                margin-bottom: 2rem;

                font-size: $f-size-small_2021;
                font-weight: 800;
                color: rgba($gray-dark, 0.5);

                text-transform: uppercase;

                cursor: pointer;

                transition: all ease-in-out $t-fast;

                &:hover
                {
                    color: $gray-dark;
                    background-color: rgba($gray-dark, 0.3);
                }

                &.is-active,
                &.is-active:hover
                {
                    background-color: $gray-dark;
                    color: $white;
                }
            }
        }

        .special-row
        {
            margin-left: -15.2rem;

            .autoimg
            {
                max-width: 100%;
                margin-bottom: 4.8rem;
                margin-right: -10rem;
            }
        }

        .special-image
        {
            width: 100%;
            margin-bottom: 1.2rem;

            img
            {
                max-width: 100%;
                height: auto;
            }
        }

        .eins
        {
           text-align: center;
           padding-top: 4rem;
           padding-bottom: 5rem;
        }

        &#fotos-de-clientes
        {
            .button-dark
             {
                 margin-bottom: 4rem;
                 display: inline-block;
             }
        }

        .module-gallery
        {
            margin-top: 2.5rem;
            .slider2
            {
                height: 40vh;
                .slick-list, .slick-track, .slick-slide > div, .item {
                    height: 100%;
                }
                .slick-prev, .slick-next {
                    top: unset;
                    bottom: 100%;
                    left: unset;
                    right: unset;
                    height: 3rem;
                    width: 3rem;
                    transform: none;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 1.5rem;
                        height: 2rem;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
                .slick-prev {
                    right: 3rem;
                    opacity: 1;
                    top: -4.5rem;
                    &:before {
                        background-image: url('/css/assets/slider2-left.svg');
                    }
                }
                .slick-next {
                    opacity: 1;
                    right: 0;
                    top: -4.5rem;
                    &:before {
                        background-image: url('/css/assets/slider2-right.svg');
                    }
                }
            }
            .noSlider3 {
                display: flex;
                overflow: auto;
                width: 100vw;
                margin-left: -2rem;
                .item {
                    margin-right: 1.5rem;
                    > * {
                        height: 15rem;
                    }
                    > iframe {
                        width: 25rem;
                    }
                }
            }
            .gallery-info,
            .gallery-title
            {
                color: $colorCorporative_famaliving;
                font-size: $f-size-small_2021;
                font-weight: 500;
                margin-bottom: 1.5rem;
                width: 80%;
            }
            .gallery-info
            {
                margin-right: 2.5rem;
                display: block;
            }

            .control-fullscreen-clientphotos {
                //display: none;
                display: block;
                position: absolute;
            }
        }

        .module-stat
        {
            position: relative;
            margin-bottom: 2.5rem;
            float: left;
            width: 100%;
            &-image
            {
                position: absolute;
                left: 0;
                top: 0;
                float: left;
                margin-right: 1.5rem;
                max-width: 8.5rem;
                height: 7.5rem;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: left top;
                }
                &+.module-stat-title, &+.module-stat-title+.module-stat-text {
                    padding-left: 9rem;
                }
            }

            &-icon
            {
                margin-bottom: .7rem;
                opacity: 0;
                transform: translateY(5rem);
                height: 3.6rem;
                img {
                    height: 100%;
                    max-width: 100%;
                    object-position: left bottom;
                    object-fit: contain;
                }
            }
            &-title
            {
                font-size: $f-size-small_2021;
                font-weight: bold;
                margin-bottom: .8rem;
                text-transform: uppercase;
            }
        }

        .module-text-subtitle
        {
            font-size: $f-size-small_2021;
            font-weight: bold;
            margin-bottom: .8rem;
            text-transform: uppercase;
        }

        .iframe-360-container {
            position: relative;
            margin-left: -2rem;
            width: 100vw !important;
            padding-top: 60%;
            .iframe-360 {
                position: absolute;
                top: 0;
                left: 0;
                height: 100% !important;
                width: 100% !important;
            }
        }
    }

    @include media-breakpoint-up(md)
    {
        .presentation-container {
            >.module-row {
                *:first-child {
                    flex-basis: 0 !important;
                    flex-grow: 1 !important;
                }
                .presentation-price-container {
                    width: 20rem;
                    min-width: 20rem;
                    flex-grow: 0 !important;
                    * {
                        line-height: 1.4 !important;
                    }
                }
                .col-auto{
                    padding-left: 0;
                }

            }
        }

        .module-wrap
        {
            padding-left: 9.8rem;
            padding-right: 2rem;
            position: relative;
            z-index: 2;
        }

        .iframe-360 {
            border: 5px solid transparent !important;
            outline: 1px solid $c-gray2;
            margin-left: calc(-9.6rem + 2.5rem);
            height: 47rem;
            margin-top: 5rem;
            background-color: #fff;
            //width: calc(100vw - 38.5rem - #{$lateral-space} - 5rem) !important;
            width: calc(100% + 2rem) !important;
            @media (min-width: $special-container-max-width)
            {
                //width: calc(100vw - ((100vw - #{$special-container-max-width}) / 2) - 38.5rem - #{$lateral-space});
                width: calc(100% + 2rem) !important;
            }
        }

        .module-header
        {
            position: relative;

            &:before
            {
                width: 0;
                height: 1px;
                border-bottom-width: 1px;
            }
            .module-title
            {
                font-family: Arvo, Arial, Tahoma;
                font-size: $f-size-title_2021;
                padding-top: 7.1rem;
                padding-bottom: 2.3rem;
                color: $colorCorporative;
                opacity: 0;
                transform: translateY(5rem);
            }
        }

        .module-body
        {
            padding-top: 2.5rem;
        }

        .module-text, .tab-text
        {
            margin-bottom: 2.2rem;
            line-height: 1.5;
            *
            {
                line-height: 1.5;
            }
        }

        .module-video
        {
            margin-left: 0;
        }

        .module-row
        {
            margin-bottom: 2.2rem;
        }

        .module-next
        {
            display: flex;
            margin-top: 8rem;
            margin-bottom: 5.5rem;
            justify-content: flex-end;
            align-items: center;
            opacity: 0;
            transform: translateY(5rem);
            a
            {

                font-weight: bold;
                font-size: $f-size-small_2021;
                color: $gray-dark;
                transition: color $t-fast ease-in-out;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                &:after {
                    content: '';
                    width: 1.7rem;
                    height: 1.1rem;
                    margin-left: 2.5rem;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    background-image: url('/css/assets/right-arrow.png');
                }

                &:hover
                {
                    color: $colorCorporative;
                }
            }
        }

        .module-tabs-nav
        {
            display: flex;
            align-items: center;

            > .item
            {
                background-color: rgba($gray-dark, 0.1);

                border-radius: 1.1rem;
                padding: .5rem 1rem;
                margin-right: 2.3rem;

                margin-bottom: 2.2rem;

                font-size: $f-size-small_2021;
                font-weight: 800;
                color: rgba($gray-dark, 0.5);

                text-transform: uppercase;

                cursor: pointer;
                white-space: nowrap;
                opacity: 0;
                transform: translateX(5rem);
                transition: all $t-fast linear;

                &:hover
                {
                    color: $gray-dark;
                    background-color: rgba($gray-dark, 0.3);
                }

                &.is-active,
                &.is-active:hover
                {
                    background-color: $gray-dark;
                    color: $white;
                }
            }
        }

        .module-tabs
        {
            .tab-title,
            .tab-text
            {
                padding-left: 4rem;
                opacity: 0;
                transform: translateY(5rem);
            }
            .tab-title
            {
                font-size: $f-size-small_2021;
                font-family: Arvo, Arial, Tahoma;
                font-weight: bold;
                color: $colorCorporative;
                text-transform: uppercase;
                margin-bottom: 2.2rem;
            }
            .module-tab {
                padding-top: 4rem;
                a {
                    img {
                        opacity: 0;
                        transform: translateY(5rem);
                    }
                }
            }
        }

        .special-row
        {
            margin-left: -15.2rem;

            .autoimg
            {
                margin-bottom: 4.8rem;
            }
        }

        .special-image
        {
            width: 22.7rem;
            margin-bottom: 1.2rem;

            img
            {
                max-width: 100%;
                height: auto;
            }
        }

        .module-text-image
        {
            margin-top: 3rem;
            margin-bottom: 7rem;
        }

        .module-gallery
        {
            .gallery-info,
            .gallery-title
            {
                color: $colorCorporative_famaliving;
                font-size: $f-size-small_2021;
                font-weight: 500;
                margin-bottom: 5px;
            }
            .gallery-info
            {
                margin-right: 2.5rem;
            }

            .slider2,
            .slider2-thumb
            {
                margin-left: 0;
                width: 100%;
            }
            .slider2 {
                .item {
                    opacity: 0;
                    transform: translateY(5rem);
                }
            }

            .slider2-thumb {
                .item {
                    opacity: 0;
                    transform: translateX(5rem);
                }
            }

            .slider2-container {
                position: relative;
                .btn-control {
                    position: absolute;
                    top: 2.5rem;
                    right: 2.5rem;
                    z-index: 2;
                    cursor: pointer;
                }
            }

            .slider2
            {
                .slick-prev, .slick-next {
                    height: 100%;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 1.5rem;
                        height: 1.5rem;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                    }
                }
                .slick-prev:before {
                    background-image: url('/css/assets/slider2-left.svg');
                }
                .slick-next:before {
                    background-image: url('/css/assets/slider2-right.svg');
                }
            }
        }

        .noSlider3 {
            display: flex;
            overflow: auto;
            margin-left: 0;
            width: calc(100vw - 39rem - #{$lateral-space} - 9.6rem);
            @media (min-width: $special-container-max-width) {
                width: calc(100vw - #{$lateral-space} - ((100vw - #{$special-container-max-width}) / 2) - 39rem - 9.6rem);
            }
            .item {
                margin-right: 1.5rem;
                > * {
                    opacity: 0;
                    transform: translateX(5rem);
                    height: 28.5rem;
                    margin-right: 1.5rem;
                }
                > iframe {
                    width: 50rem;
                }
            }
        }

        .module-stat
        {
            margin-bottom: 2.5rem;
            float: left;
            width: 100%;
            &-image
            {
                float: left;
                margin-right: 1.5rem;
                opacity: 0;
                transform: translateY(5rem);
                img {
                    height: 100%;
                }
            }

            &-icon
            {
                margin-bottom: .7rem;
                opacity: 0;
                transform: translateY(5rem);
                height: 3.6rem;
                img {
                    height: 100%;
                    max-width: 100%;
                    object-position: left bottom;
                    object-fit: contain;
                }
            }

            &-title
            {
                font-size: $f-size-small_2021;
                font-weight: bold;
                margin-bottom: .8rem;
                text-transform: uppercase;
                opacity: 0;
                transform: translateY(5rem);
            }
            &-text
            {
                opacity: 0;
                transform: translateY(5rem);
                line-height: 1.33;
            }
        }

        .module-text-subtitle
        {
            font-size: $f-size-small_2021;
            font-weight: bold;
            margin-bottom: .8rem;
            text-transform: uppercase;
        }
    }
    @media (min-width: $special-container-max-width)
    {
        .iframe-360 {
            width: calc(100vw - ((100vw - #{$special-container-max-width}) / 2) - 39rem - #{$lateral-space} - 5rem) !important;
        }
    }
}

.images-grid {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    flex-grow: 1;
    width: calc(100% + 13rem);
    //margin-left: -115rem;
    margin-left: 0;

    .images-grid-container {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
    }
    .image-grid-item {
        position: relative;
        height: 11.5rem;
        width: calc((100% / 4) - 1.5rem);
        min-width: calc((100% / 4) - 1.5rem);
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
        @include media-breakpoint-up(sm)
        {
            &:nth-child(5n+2),
            &:nth-child(5n+4) {

            }
            &:nth-child(5n+3),
            &:nth-child(5n) {
                margin-top: 13rem;
                margin-left: calc((100% / 4) * -1);
            }
            &:nth-child(5n+1) {
                height: 24.5rem;
                margin-top: 0;
                margin-left: 0;
            }
        }
        img {
            position: relative;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            object-position: center;
            opacity: 0;
            transform: translateX(5rem);
        }
    }
    @include media-breakpoint-down(sm)
    {
        width: calc(100% + 4rem + 1.5rem);
        margin-left: -2rem;
        .image-grid-item {
            width: calc((100% / 3) - 1.5rem);
            min-width: calc((100% / 3) - 1.5rem);
            &:nth-child(3n) {
                margin-top: 13rem;
                margin-left: calc((100% / 3) * -1);
            }
            &:nth-child(3n+1) {
                height: 24.5rem;
                margin-top: 0;
                margin-left: 0;
            }
        }
    }
}

.module-documents {
    .row {
        flex-wrap: wrap;
        &:before{
            content: none;
        }
    }
}

.module-documents-item
{
    display: flex;
    align-items: flex-start;
    color: $gray-dark;
    margin-bottom: 2.8rem;
    .icon, .title, .text
    {
        opacity: 0;
        transform: translateY(5rem);
    }
    .icon
    {
        margin-right: 1rem;
    }
    .title
    {
        font-size: $f-size-normal-body;
        font-weight: 500;
        //line-height: 0.87px;
        text-decoration: underline;
        margin-bottom: 1rem;
        margin-top: .7rem;
    }
    .text
    {
        font-size: $f-size-very-small_2021;
        font-weight: 500;
        line-height: 1.18;
        line-height: normal;
    }
}

.module-accordion
{
    .card
    {
        border: none;
        border-bottom: 1px solid $c-gray2;
        opacity: 0;
        transform: translateY(5rem);
        &:last-child {
            border-bottom: 0;
        }
    }
    .card-header
    {
        font-size: $f-size-medium;
        font-weight: bold;
        padding: 4rem 5.5rem 3.4rem 9rem;
        cursor: pointer;
        position: relative;
        border: 0;

        &:before
        {
            content: url('/cssiw5/img/accordion/help.png');
            position: absolute;
            left: 3.2rem;
            top: 50%;
            transform: translateY(-50%);
            display: block;
        }

        &:after
        {
            content: url('/cssiw5/img/accordion/plus.png');
            display: block;
            padding: 0 5px;
            border-radius: 100%;
            border: 1px solid $gray-dark;
            position: absolute;
            right: 3.2rem;
            top: 50%;
            transform-origin: center top;
            transform: rotate(45deg) translateY(-50%);
            transition: transform $t-normal $easeOutCubic;
        }

        &.collapsed:after
        {
            transform: rotate(360deg) translateY(-50%);
        }
        &+.collapse {
            &.show {
                .card-body {
                    opacity: 1;
                    transform: translateY(0);
                    transition: opacity $t-fast linear 0.4s, transform $t-normal linear 0.4s;
                }
            }
            .card-body {
                opacity: 0;
                transform: translateY(5rem);
                transition: opacity $t-fast linear 0s, transform 0s linear 0.4s;
            }
        }
    }

    .card-body
    {
        padding: 0 5.5rem 4rem 9rem;
        position: relative;
        font-weight: 500;

        &:before
        {
            content: url('/cssiw5/img/accordion/help-1.png');
            position: absolute;
            left: 3.2rem;
            top: 0;

            display: block;
        }
    }

    @include media-breakpoint-down(sm)
    {
        .card-header
        {
            padding-left: 3.2rem;
            padding-right: 3.2rem;
            font-size: 1.7rem;
            line-height: 1.3;

            &:before
            {
                left: 0;
            }

            &:after
            {
                right: 0;
                line-height: 1;
                font-size: $f-size-medium;
            }
        }
        .card-body
        {
            padding-left: 3.2rem;
            padding-right: 0;
            line-height: 1.2;

            &:before
            {
                left: 0;
            }

        }
    }

    @include media-breakpoint-up(md)
    {
        margin-left: -9rem;
        margin-right: -2rem;
    }
}

.module-products
{
    .item
    {
        opacity: 0;
        transform: translateY(5rem);
        .title, .text, .more-info
        {
            opacity: 0;
            transform: translateY(5rem);
        }
        &:hover
        {
            background-color: rgba($gray-dark, 0.1);
        }
    }
    &.mode-combination
    {
        width: calc(100% + 4.5rem + 1rem);
        margin-left: calc((4.5rem + .5rem) / 2 * -1);
        >.row {
            flex-wrap: wrap;
            >* {
                margin-bottom: 4.5rem;
            }
        }
        .item
        {
            height: 100%;
            padding: 1rem;
            //margin: 0 0 4rem;
            color: $gray-dark;
            transition: background-color $t-fast ease-in;
            display: block;

            .image
            {
                height: 20.1rem;
                margin-bottom: .9rem;
            }
            .image img
            {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .title
            {
                font-size: 2.1rem;
                margin-bottom: 1rem;
                opacity: 0;
                transform: translateY(5rem);
            }

            .text
            {
                font-size: $f-size-small_2021;
                font-family: Arvo, Arial, Tahoma;
                opacity: 0;
                transform: translateY(5rem);
            }
        }
    }

    &.mode-related
    {
        margin: 5.2rem auto 0 auto;
        position: relative;
        display: inline-block;
        left: 50%;
        max-width: 100vw;
        overflow: auto;
        //overflow-y: hidden;
        transform: translateX(-50%);


        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        
        &::-webkit-scrollbar:horizontal {
            height: 4px;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 1px solid $colorCorporative;
            background-color: $colorCorporative;
        }
        
        &::-webkit-scrollbar-track { 
            background-color: rgba($colorCorporative, 0.32); 
            border-radius: 8px; 
        }
        &::-webkit-scrollbar-track-piece  {
            background-color: rgba($colorCorporative, 0.32); 
            border-radius: 8px; 
        }
        
        &::-webkit-scrollbar-thumb:vertical {
            border-radius: 8px;
            border: 1px solid $colorCorporative;
            background-color: $colorCorporative;
        }
        /* Thin Scrollbar for firefox */
        &{
            scrollbar-color: $colorCorporative rgba($colorCorporative, 0.32);
            scrollbar-width: thin;                
        }

        .module-products-container {
            white-space: nowrap;
            text-align: center;
            display: inline-flex;

            // Fix problem with scrollbars in desktop chrome.
            //overflow: hidden;
            @include media-breakpoint-down(sm){
                overflow-x: auto;
            }
           
            .item-wrapper {
                //width: 36rem;
                //min-width: 36rem;
                width: calc(100% / 3);
                min-width: calc(100% / 3);
                max-width: 36rem;
                display: inline-block;
                white-space: normal;
                text-align: left;
                margin: 0 0 4rem;
            }
        }
        .item
        {
            height: 100%;
            padding: calc(1rem + 25.7rem + .9rem) 2rem 2.5rem 2rem;
            color: $gray-dark;
            transition: background-color $t-fast ease-in;
            display: flex;
            flex-direction: column;
            transform: translate(5rem, 0);
            &.isVisible {
                opacity: 1;
                transform: translate(0, 0) !important;
                transition: opacity $t-fast linear 0.2s, transform $t-normal $easeOutCubic 0.2s, background-color $t-fast linear 0s;
                .title, .text, .more-info
                {
                    opacity: 1;
                    transform: translate(0, 0) !important;
                }
                .title
                {
                    transition: opacity $t-fast linear 0.4s, transform $t-normal $easeOutCubic 0.4s;
                }
                .text
                {
                    transition: opacity $t-fast linear 0.5s, transform $t-normal $easeOutCubic 0.5s;
                }
                .more-info
                {
                    transition: opacity $t-fast linear 0.6s, transform $t-normal $easeOutCubic 0.6s;
                }
            }
            .image
            {
                height: 25.7rem;
                width: calc(100% - 4rem);
                position: absolute;
                top: 1rem;
                left: 2rem;
            }
            .image img
            {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .title
            {
                font-size: 2.1rem;
                margin-bottom: 1rem;
            }

            .text
            {
                font-size: 1.5rem;
                font-family: Arvo, Arial, Tahoma;
                margin-bottom: 2.3rem;
            }

            .more-info
            {
                font-size: $f-size-small_2021;
                font-weight: bold;
                height: 1.5rem;
                width: calc(100% - 4rem);
                position: absolute;
                bottom: 1rem;
                left: 2rem;
            }
        }
    }
    @include media-breakpoint-down(sm)
    {
        &.mode-combination
        {
            width: 100%;
            margin-left: 0;
            >.row {
                flex-wrap: nowrap;
                overflow-x: auto;
                width: 100vw;
                margin-left: -2rem !important;

                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 7px;
                }
                
                &::-webkit-scrollbar:horizontal {
                    height: 4px;
                }
                
                &::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                    border: 1px solid $colorCorporative;
                    background-color: $colorCorporative;
                }
                
                &::-webkit-scrollbar-track { 
                    background-color: rgba($colorCorporative, 0.32); 
                    border-radius: 8px; 
                }
                &::-webkit-scrollbar-track-piece  {
                    background-color: rgba($colorCorporative, 0.32); 
                    border-radius: 8px; 
                }
                
                &::-webkit-scrollbar-thumb:vertical {
                    border-radius: 8px;
                    border: 1px solid $colorCorporative;
                    background-color: $colorCorporative;
                }
                /* Thin Scrollbar for firefox */
                &{
                    scrollbar-color: $colorCorporative rgba($colorCorporative, 0.32);
                    scrollbar-width: thin;                
                }

                >* {
                    max-width: 60%;
                }
            }
        }
        &.mode-related {
            margin-top: 3rem;
            overflow: visible;

            &:before {
                content: '';
                position: absolute;
                z-index: -1;
                left: 50%;
                bottom: 0;
                width: 100vw;
                height: calc(100% + 11rem);
                transform: translateX(-50%);
                background-color: $gray-ligth;
                pointer-events: none;
            }
        
            .module-products-container {
                display: flex;
                .item-wrapper {
                    width: 27.5rem;
                    min-width: 27.5rem;
                    max-width: 75%;
                    .item {
                        //height: calc(100% - 4rem);
                        position: relative;
                        padding-bottom: 2rem;
                        .image {
                            height: 20rem;
                        }
                        .more-info {
                            position: absolute;
                            bottom: 1rem;
                            left: 2rem;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 500px) {
        &.mode-combination
        {
            >.row {
                flex-wrap: nowrap;
                overflow-x: auto;
                width: 100vw;
                margin-left: -2rem !important;
                >* {
                    max-width: 85%;
                }
            }
        }
    }
}

.module-valoraciones
{
    .info
    {
        font-size: $f-size-small_2021;
        font-weight: 500;
        white-space: nowrap;
    }
    .module-header
    {
        margin-bottom: 2.1rem;

        .info
        {
            margin-left: 2.5rem;
        }

        .stars
        {          
            & > div{
                height: 1.5rem;
                width: 1.5rem;
            }
        }
    }
    .module-body
    {
        .lateral
        {
            //width: 320px;
            width: 37.5rem;
            border-right: 1px solid $gray-ligth;

            .lateral-header .info
            {
                opacity: 0.5;
            }

            .lateral-header
            {
                margin-bottom: 3.6rem;
                padding-bottom: 3.5rem;
                position: relative;

                &:after
                {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    width: 8rem;
                    left: 0;
                    height: .5rem;
                    background-color: $colorCorporative;
                }
            }

            .lateral-body
            {
                width: 28.6rem;
            }

            .th,
            .tr
            {
                display: flex;
                //justify-content: space-between;
                align-items: center;
                padding: 1.8rem 0 1.9rem;
                position: relative;
                > .td:first-child
                {
                    flex: 0 0 7.8rem;
                    width: 7.8rem;
                    margin-right: 4.3rem;
                }
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 1px;
                    width: 80%;
                    background-color: $gray-ligth;
                }
            }

            .th
            {
                font-size: $f-size-very-small_2021;
                font-weight: bold;
                text-transform: uppercase;
            }

            .tr
            {
                cursor: pointer;
                &.isSelected {
                    &:before {
                        background-color: $gray-ligth;
                    }
                }
                &.empty {
                    pointer-events: none;
                    &:before {
                        display: none;
                    }
                }
                &:before {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    right: 0;
                    top: 0;
                    pointer-events: none;
                    height: 100%;
                    width: 100vw;
                    transition: background-color $t-fast linear;
                }
                .td
                {
                    display: flex;
                    align-items: center;
                    
                }
                &:hover {
                    &:before {
                        background-color: $gray-ligth;
                    }
                }
            }

            .progress
            {
                width: 10rem;
                min-width: 10rem;
                max-width: 10rem;
                margin-right: 2rem;
                height: .5rem;
                margin-bottom: 0;
            }
        }

        .tr .list-rates
        {
            flex: 1;
        }

        .rate
        {
            padding-top: 3rem;
            padding-bottom: 2.3rem;
            border-bottom: 1px solid $gray-ligth;

            &:last-child
            {
                border: none;
            }

            .rate-header
            {
                display: flex;
                margin-bottom: 2rem;

                .photo
                {
                    width: 4.4rem;
                    min-width: 4.4rem;
                    height: 4.4rem;
                    border-radius: 100%;
                    overflow: hidden;
                    margin-right: 2.5rem;
                    font-size: 2.1rem;
                    font-weight: 300;
                    color: white;
                    background-color: $colorCorporative;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img
                    {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .rate-order-verified
                {
                    font-weight: bold;
                    font-size: $f-size-very-small_2021;
                    color: $blue;
                    margin-left: 2.5rem;
                }

                .name
                {
                    font-weight: 500;
                    margin-bottom: .7rem;
                    font-size: 1.7rem;
                }

                .stars
                {
                    margin-bottom: 1.6rem;
                    & > div{
                        height: 1.5rem;
                        width: 1.5rem;
                    }
                }

                .date
                {
                    font-weight: 500;
                    font-size: $f-size-small_2021;
                    opacity: 0.5;
                }
            }

            .rate-comment
            {
                font-size: $f-size-small_2021;
                font-weight: 500;
                line-height: 1.54;
            }
        }
    }
    .desktop-valoration-opinions {
        .list-rates {
            position: relative;
            display: none !important;
            min-height: 38.2rem;
            //max-height: 382px;
            overflow: hidden;
            &.seeMore {
                max-height: unset;
                .list-rates-see-more {
                    display: none;
                }
            }
            &.isVisible {
                display: block !important;
            }
            .list-rates-see-more {
                position: absolute;
                z-index: 1;
                width: 100%;
                bottom: 0;
                left: 0;
                height: 5rem;
                background: rgba(255,255,255,0);
                background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 63%);
                background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(63%, rgba(255,255,255,1)));
                background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 63%);
                background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 63%);
                background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 63%);
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 63%);
                .text-see-more {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    color: $pink;
                    font-size: 1.5rem;
                    font-weight: 500;
                    justify-self: flex-start;
                    text-decoration: underline;
                    cursor: pointer;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm)
    {
        margin-bottom: 5rem !important;
        padding: 0 1rem;
        .module-body .container
        {
            padding-left: 0;
            padding-right: 0;
        }

        .module-header
        {
            .module-title
            {
                font-size: 1.7rem;
            }

            &:after
            {
                left: 2rem;
            }

            .container {
                padding: 0;
            }
        }

        .module-body .lateral
        {
            width: 100%;
            border: none;

            .lateral-header
            {
                padding-left: 0;
                padding-right: 0;
            }

            .lateral-body
            {
                width: 100%;

                .tr,
                .th
                {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }


            .list-rates
            {
                padding-left: 2rem;
                padding-right: 2rem;
            }

            .tr,
            .th
            {
                position: relative;
                &:after {
                    //left: -2rem;
                    //width: 100vw;
                    //display: none;
                    left: 0;
                    width: 100%;

                }
            }

            .tr
            {
                position: relative;

                &:before
                {
                    content: url('/cssiw5/img/accordion/m-down.png');
                    position: absolute;
                    right: -2rem;
                    top: 50%;
                    width: 1.2rem;
                    height: 1.2rem;
                    transform: translateY(-50%) rotate(270deg);
                    display: block;
                    background-color: transparent !important;
                }

                &:not(.collapsed) {
                    position: fixed;
                    z-index: 1001;
                    left: 0;
                    top: 0;
                    width: 100vw;
                    height: 50px;
                    background-color: #fff;
                    padding: 0 2rem;
                    &:after {
                        left: 1rem;
                        width: calc(100vw - #{2rem});
                    }
                    &:before {
                        right: 2rem;
                        content: url('/css/assets/close.svg');
                    }
                }
            }

            .list-rates {
                position: relative;
                padding: 0;
                bottom: 50vh;
                transition: bottom $t-normal $easeOutCubic 0s, height 0s linear 0s;
                .rate .rate-header .rate-order-verified {
                    display: block;
                    line-height: 2;
                    margin-left: 0;
                }
                &.collapsing {
                    transition: bottom $t-normal $easeOutCubic 0s, height 0s linear 0s;
                }
                &.show, &.collapsing {
                    position: fixed;
                    z-index: 1000;
                    left: 0;
                    bottom: 0;
                    height: calc(100vh - 50px);
                    width: 100%;
                    overflow: auto;
                    background-color: #fff;
                    padding: 0 2rem !important;
                }
            }
        }
        .desktop-valoration-opinions {
            display: none !important;
        }
    }
}

.module-related-products {
    .module-header{
        width: 100%;
        transform: none;
       
        width: calc(100% + 10rem);
        transform: translateX(-5rem);
    }

    @include media-breakpoint-down(sm)
    {
        .module-header {
            padding-left: 1rem;
            position: relative;
            z-index: 1;
            width: 100%;
            padding: 1.5rem;
            transform: translateX(0);
        }
    }
    @include media-breakpoint-down(md){
        .module-header {
            padding-left: 1rem;
            position: relative;
            z-index: 1;
            width: 100%;
            padding: 1.5rem;
            transform: translateX(0);

        }
    }
}

.module-form
{
    .row {
        flex-wrap: wrap;
    }
    .module-header
    {
        margin-bottom: 4.9rem;
    }
    .module-body
    {
        padding-bottom: 2.5rem;

        @include media-breakpoint-up(md)
        {
            padding-left: 9.7rem;
            padding-right: 8.6rem;
        }

        .container
        {
            border-bottom: 1px solid $gray-ligth;
        }

        .form-contact-product{
            .form-group{
                label {
                    white-space: normal;
                    pointer-events: auto;
                }
            }
        }

        label
        {
            font-size: $f-size-small_2021;
            font-weight: 500;
            color: rgba($gray-dark, 0.5);
            margin-bottom: 3px;
            padding: 0;
        }

        .form-check
        {
            position: relative;
            padding-left: 0;
            input
            {
                visibility: hidden;
                position: absolute;
            }
            label
            {
                margin-bottom: 0;
                position: absolute;
                padding-left: 2.8rem;
                cursor: pointer;

                &:before
                {
                    content: '';
                    border: 1px solid rgba($gray-dark, 0.35);
                    height:20px;
                    width: 2rem;
                    position: absolute;
                    left: 0;
                    display: block;
                    top: 50%;
                    transform: translateY(-50%);;
                }

                &:after
                {
                    content: '';
                    border: 0 solid $gray-dark;
                    position: absolute;
                    left: .5rem;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: border-width $t-fast ease-in-out;
                }
            }

            input:checked
            {
                & + label:after
                {
                    border-width: .5rem;
                }
            }
        }

        input[type=text],
        input[type=email],
        input[type=number],
        textarea
        {
            background-color: rgba($gray-dark, 0.05);
            border: transparent 1px solid;
            border-bottom: 1px solid rgba($gray-dark, 0.35);
            padding: 1.4rem 1rem;
            line-height: 1;
            color: $gray-dark;
            font-size: $f-size-small_2021;
            height: 3rem;
        }

        select
        {

            line-height: 1;
            color: $gray-dark;
            font-size: $f-size-small_2021;
            border: transparent 1px solid;
            background-color: rgba($gray-dark, 0.05);
            border-bottom: 1px solid rgba($gray-dark, 0.35);
            height: 3rem;
            padding-left: 1rem;
        }

        .form-group
        {
            margin-bottom: 3.4rem;
        }

        textarea
        {
            height: 11.3rem;
            resize: none;
        }

        button.button-dark
        {
            margin-bottom: 5.2rem;
        }
    }


    @include media-breakpoint-down(sm)
    {
        margin-bottom: 0 !important;
        .module-header
        {
            margin-bottom: -4rem;
            background: white;
            padding: 2rem 0;
            color: $colorCorporative;
            .container{
                padding: 0;
                .module-title {
                    display: inline-block;
                    background-color: #fff;
                    padding: 0 1rem;
                }
            }
        }
        .module-body
        {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 0;
            .container
            {
                padding: 2rem;
                border: 1px solid $gray-ligth;
                .col-12 {
                    padding: 0;
                }
                .form-group {
                    margin-bottom: 2.5rem;
                }
            }

            button.button-dark
            {
                margin-top: 4.7rem;
                margin-bottom: 0;
            }
        }
    }
}
}

