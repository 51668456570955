$fontStack1: "Montserrat", Arial, sans-serif;
//$colorCorporative: #00A5B8;
//$colorCorporative2: #c03d8b;

//$colorGray: #999;
//$colorBlack: #333;

@media (max-width: 400px) {
    .famaliving .topbar {
        padding-left: 20px;
        padding-right: 20px;
    }

    .famaliving .topbar .btn-search{
        margin-left: .5rem;
        position: unset;
        right: unset;
        top: unset;
    }

    .famaliving .topbar .logo img{
        height: 2.5rem !important;
    }
}

.famaliving{
    .topbar{
        @include media-breakpoint-down(sm){
            padding-left: 2rem !important;
            padding-right: 2rem !important;  

            .btn-search{
                margin-left: 0.5rem !important;
            }
        } 
    }
}

.famaliving.bg-gray-catalogue{
    &.bg-gray.bg-gray-catalogue{
        background-color: #DCDCDC !important;
    }
    .app-list.app-list_2021{
        padding-top: 6rem;

        nav.topbar{
            height: 6rem;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            background-color: white;
            padding: 0;
            padding-left: 2.6rem;
            padding-right: 3.8rem;
            z-index: 5;
            margin-top: 0; 

            @include media-breakpoint-down(sm){
                padding-left: 2rem;
                padding-right: 2rem;  

                .btn-search{
                    margin-left: 0.5rem;
                }
            }     
          
        
            @include media-breakpoint-up(md){
                padding-left: 3.2rem;
                padding-right: 4rem;                
            }
                
            .alb-shadow{
                &, &:after, &:before{
                    content: '';
                    display: block;
                    height: 8rem;
                    position: absolute;
                    top: 0;
                    z-index: -1;
                }
        
                &{
                    background: url('/img/topbar/mob-middle.png') repeat-x transparent top center;
                    right: 18.7rem;
                    left: 18.7rem;
                    pointer-events: none;
                }
        
        
                &:after{
                    background: url('/img/topbar/mob-left.png') repeat-x transparent top left;
                    width: 18.7rem;
                    left: -18.7rem;
                }
        
                &:before{
                    background: url('/img/topbar/mob-right.png') repeat-x transparent top right;
                    width: 18.7rem;
                    right: -18.7rem;
                }
        
        
                @include media-breakpoint-up(lg){
                    &{
                        background: url('/img/topbar/middle.png') repeat-x transparent top center;
                        right: 64rem;
                        left: 64rem;
                    }
        
        
                    &:after{
                        background: url('/img/topbar/left.png') repeat-x transparent top left;
                        width: 64rem;
                        left: -64rem;
                    }
        
                    &:before{
                        background: url('/img/topbar/right.png') repeat-x transparent top right;
                        width: 64rem;
                        right: -64rem;
                    }
                }
            }
        
            .ham{
                cursor: pointer;
                margin-right: 3.2rem;
                &+.hide-menu+.logo {
                    height: unset;
                }
            }
        
            .logo{
               
                img {
                    height: 3.5875rem;
                }
            }
        
            .menu.menu-horizontal {
                display: none;        
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                transition: padding $t-fast ease-in;
                margin: unset;
        
        
                @include media-breakpoint-up(lg){
                    display: flex;
                    padding-left: 1.6rem;
                }
        
                @include media-breakpoint-up(xl){
                    padding-left: 10.125em;//162px;
                }
        
                & > .item {
                    padding-left: 2rem;
                    padding-right: 2rem;
                    line-height: normal;
                    font-weight: bold;
                    font-size: 1.0875rem;
                    text-transform: uppercase;
        
                    @media (min-width: 1280px) {
                        font-size: 1.3125rem;
                        padding-left: 24px;
                        padding-right: 24px;
                    }
        
                    @media (min-width: 1500px) {
                        font-size: 1.3375rem;
                        padding-left: 28px;
                        padding-right: 28px;
                    }
        
                    @media (min-width: 2000px) {
                        font-size: 1.6125rem;
                        padding-left: 32px;
                        padding-right: 32px;
                    }
        
                    a{
                        position: relative;
                        color: $gray-dark;
                        text-transform: uppercase;
                    }
        
                    &.is-active
                    {
                        a{
                            color: $c-famaliving;
        
                            &:before
                            {
                                background-color: $c-famaliving;
                            }       
                            h1{
                                font-weight: inherit;
                                font-size: initial;
                                font-family: inherit;
                                margin: 0;
                            }
                        }
                        
                    }
        
        
                    a:before{
                        content: '';
                        position: absolute;
                        height: 2px;
                        background-color: $gray-dark;
                        transform: scaleX(0);
                        right: 0;
                        bottom: -6px;
                        left: 0;
                        transition: transform $t-fast ease-in-out;
                    }
        
                    a:hover:before{
                        transform: scaleX(1);
                    }
                }
        
            }
     
        
            .btn-search{
                width: 1.4rem;
                height: 100%;
                margin-left: 2em;
                cursor: pointer;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;                   
                }
             

                
                @include media-breakpoint-down(md){                    
                    margin-top: 0;
                    margin-left: 0rem;
                    top: 0;
                }
        
                @include media-breakpoint-up(xl){
                    margin-left: 3em;
                }
        
                /*@include media-breakpoint-down(lg){
                    margin-left: -1em;                  
                }*/
            }
           
        
            .menu-vertical{
                width: 26.4rem;
                height: 100vh;
                position: absolute;
                top: 0;
                left: 0;
                background-color: $c-famaliving;
                overflow-y: auto;
                transform: translateX(-26.4rem);
                padding-bottom: unset;
                margin-top: 0;
                padding-top: 0;         
                margin: 0;
                
                > ul .item.lang a {
                    padding-right: 5px;
                    & + a {
                        padding-left: 5px;
                    }
                }
        
                .logo{
                     padding: 1em 2em 1em;
                 }
        
                & > ul
                {
                    list-style: none;
                    margin-left: 0;
                    padding-left: 0;
        
                    .item a{
                        display: block;
                        color: white;
                        background-color: transparent;                      
                        padding-top: 1.2em;
                        padding-bottom: 1.2em;                     
        
                        font-size: 0.9375em;
                        font-weight: bold;
        
                        transition: background-color $t-fast ease-in-out;
                        text-transform: uppercase;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
        
        
                        &:hover
                        {
                            background-color: rgba(black, 0.05);
                            text-decoration: none;
                        }
                    }
                    .item.is-open{
                        background-color: darken($gray, 20%);
                        a{
                            color: $gray-dark;
                        }
                    }
                    .item.top-separator{
                        border-top: 1px dashed white;
                    }
        
                   li.item > ul{
                        //display: none;
                        list-style: none;
                        padding: 0;
                        background-color: $gray;
        
                        > li.item a{
        
                            color: $gray-dark;
                            padding-top: 1.2rem;
                            padding-bottom: 1.2rem;
                            padding-left: 2rem;
                            padding-right: 2rem;
                            font-size: $f-size-small;
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            text-transform: capitalize;
        
                            &:before{
                                content: '\27A4';
                                margin-right: 1.5rem;
                                font-size: rem(8px);
                                display: block;
                                transition: $t-fast transform ease-in-out;
                            }
        
                            &:hover{
                                background-color: rgba(black, 0.05);
                                text-decoration: none;
        
                                &:before
                                {
                                    transform: translateX(0.5rem);
                                }
                            }
                        }
                    }
                }
            }
        
            .ham {
                display: flex;
                align-items: center;
                height: 50px;
        
                div {
                    position: relative;
                }
        
                div,
                div:before,
                div:after{
                    height: 1px;
                    background-color: $c-famaliving;     
                    position: relative;
                    width: 25px;
                    content: '';
                    transition: all 0.3s ease-in-out;
                }
        
                div:before,
                div:after{
                    position: absolute;
                    left: 0;
                }
        
                div:before{
                    top: -6px;
                }
                div:after{
                    bottom: -6px;
                }
        
                &:hover{
                    div,
                    div:before,
                    div:after{
                        background-color: $gray-dark;
                    }
                }
            }
        
            .hide-menu{
                position: absolute;
                left: 3.8rem;
                opacity: 0;
                z-index: -1;
                transition: 0.4s ease-in-out all;
                font-size: 0.5625em;
                text-transform: uppercase;
                width: 5rem;
                line-height: 1.5;
                color: $gray-dark;
            }
        
            .if-open-hidden{
                opacity: 1;
                transition: 0.4s ease-in-out all;
                h1{
                    font-size: inherit;
                    font-family: inherit;
                    font-weight: inherit;
                    margin: 0;
                }
            }
        
            transition: transform 0.3s ease-in-out;
            transform: translateX(0);
        
            &.is-open{
                transform: translateX(26.4rem);      
                margin: 0;

                @include media-breakpoint-down(md)
                {
                    margin: 0;
                    .btn-search {
                        background-color: transparent;
                    }
                }
        
                .ham{
                    div{
                        &:before{
                            transform-origin: left center;
                            transform: rotate(-40deg) scaleX(0.5);
                            top: 0;
                        }
                        &:after{
                            transform-origin: left center;
                            transform: rotate(40deg) scaleX(0.5);
                            bottom: 0;
                        }
                        &,
                        &:before,
                        &:after {
                            background-color: $gray-dark;
                        }
                    }
                }
                .hide-menu{
                    opacity: 1;
                    z-index: 1;
                    transition: 0.4s 0.8s ease-in-out all;
                    cursor: pointer;
                }
        
                .if-open-hidden{
                    opacity: 0;
                }
            }
            @include media-breakpoint-down(sm)
            {
                /*padding: 0;
                >.row {
                    >* {
                        padding: 0;
                    }
                }*/
                 .row {
                    margin: 0 -15px!important;
                }
            }
        }       

    }

    .footer{
        font-size: 1.4rem;
        &.footer_famaliving{    
            .newsletter{
                color: #c03d8b;
            }
            .divhelp .divhelp-top{  
                flex-direction: column;
                align-items: flex-start;  

                .help{
                    padding-right: 0;
                    padding-bottom: 0.5rem;
                  }
            }
            @media (max-width: 767px) {
                .hidden-xs {
                  display: none !important;
                }
            }
              @media (min-width: 768px) and (max-width: 991px) {
                .hidden-sm {
                  display: none !important;
                }
            }
        }
        .row{
            margin-left: -15px !important;
            margin-right: -15px !important;
            flex-wrap: wrap;
        }

        .btn.btn-primary{
            color: #fff;
            background-color: #00A5B8;
            border-color: #008e9f;
            font-family: "Montserrat", Arial, sans-serif;
            font-weight: 500;
            border-radius: 0;
            display: inline-block;
            margin-bottom: 0;
            font-weight: normal;
            text-align: center;
            vertical-align: middle;
            touch-action: manipulation;
            cursor: pointer;
            background-image: none;
            border: 1px solid transparent;
            white-space: nowrap;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 1.42857;
            border-radius: 0;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &:hover{
                color: #fff;
                background-color: #007785;
                border-color: #005761;
            }
        }

    }

    .footer {

        background:#EEEEEE;
        margin-top: 15px;
        color: #888888;
        padding: 20px 0 0px 0;

        h1,
        .footer-title	{
            font-size: 16px;
            color: #888888;
            font-family: $fontStack1;
            margin-bottom: 30px;
        }

        h2,
        .footer-subtitle {
            margin-bottom: 0;
            font-size: 14px;
            font-family: $fontStack1;
            color: #888888;
            margin-top: 0;
            text-transform:uppercase;

            &.has-children
            {
                margin-bottom: 2px;
                //padding-left: 10px;
            }

            a
            {
                color: #888888;
                
                &:hover
                {
                    color:#666;
                }
            }
        }

        .footer-title	{
            margin-bottom: 2px;
            padding-left: 10px;
        }

        ul	{
            list-style:none;
            padding-left: 0px;
            /*text-decoration: underline;*/
            font-family: $fontStack1;
            font-size: 14px;
            //color: #888888;		
            

            &.has-children		{
                padding-left: 10px;
                padding-bottom: 5px;
                padding-top: 5px;
                margin-bottom: 15px;
                border-left: 1px solid #ddd;
            }

        

            li a{
                /*text-decoration: underline;*/
                line-height: 120%;
                color: $colorGray;
                -webkit-transition: color 200ms ease-out;
                transition: color 200ms ease-out;

                &.brand-image img{
                    max-width: 200px;
                    height: auto;
                    margin: 10px 0;
                    opacity: 1;				
                    transition: opacity;
                }

                &:hover{
                    color: $colorBlack;
                    text-decoration: underline; 

                    &.brand-image img
                    {
                        opacity: 0.7;
                    }
                }

            }
        }

        .newsletter {
            font-size: 25px;
            color: $colorCorporative;
            @extend %famalivingTextColor;
            text-transform: none;
            display: block;
        }

        .subs {
            font-size: 14px;
            margin-top: 0px;
            text-transform: uppercase;
            margin-bottom: 10px;
            
        }

        .fa-file-text-o {
            font-size: 40px;
            font-weight: lighter;
        }

        input {
            width: 100%;
            height:35px;
            padding-left: 13px;
            text-decoration: underline;
            background-color: #fff;
            border: 1px solid #000;

            &:focus,
            &:focus-visible{
                border: 2px solid #000;
            }
        }

        button 	{
            background: #888888;
            margin-top: 5px;
            width: 100%;
            color:white;
            height: 35px;
            text-align: left;
            font-weight: bold;
            border: none;
            padding-left:10px;
            display: block;
            
            &:hover	{
                background-color: $colorCorporative;
            }
        }

        .field	{
            position: relative;
            font-size: 12px;
            color: #AAA;
            overflow: hidden;

            label		{
                position: absolute;
                top: 50%;
                left: 10px;
                margin-top: -10px;
                //background: #FFF;
                padding: 3px 3px 3px;
                transition: 0.3s ease-in all;
                text-decoration: underline;
                cursor: text;
            }
            input:focus + label,
            input.filled + label{
                /*margin-top: -50px;*/
                left: -100%;
            }
        }
        .btn-mailsuscription	{
            transition: 0.3s ease-in all;
            &:hover{
                background-color: #333;
            }
        }

        .help 	{
            font-size: 14px;
            display: inline;
            padding-right: 20px;
        }

        .help2 	{		
            font-size: 12;
            text-decoration: underline;
        }

        .mail 	{
            font-size: 18px;
            text-decoration: underline;
        }

        .divhelp	{
            margin-top: 30px;

            .divhelp-top		{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 20px;

                .btn			{
                    flex: 1 0 0;
                }
            }
        }

        .copy 	{
            float: left;
            text-decoration: underline;
        }

        .idioma-social {
            //border-top: #CCC 2px solid;
        }

        .sociallinks 	{
            display: flex;
            //font-size: 47px;
            font-size: 35px;
            margin-bottom: 1rem;

            //border-top: #CCC 3px solid;
            text-align: right;
            margin-right: -3px;

            li 		{
                padding-left: 5px;
                padding-right: 0;
                margin: 0;
            }
        }

        #languages {
            list-style-type: none;
            margin: 0;
            padding: 0;
            //position: fixed;
            //top: 0;
            //right: 10px;
            line-height: 20px;
            z-index: 9999;
            padding: 5px;
            background: #FFF;
            margin-top: 7px;
            margin-bottom: 7px;

            img		{
                margin-right: 5px;
            }

            a, .a		{
                cursor: pointer;
                text-decoration: none!important;

                .arrow
                {
                display: inline-block;
                padding-left: 6px;
                -webkit-transition: transform 300ms ease-in;
                transition: transform 300ms ease-in;
                -webkit-transform: rotateX(180deg);
                transform: rotateX(180deg);
                vertical-align:middle;
                }

                &.collapsed .arrow
                {
                
                -webkit-transform: rotateX(0);
                transform: rotateX(0);
                }    
            }
        }
    }

    .footermenu {
        float: right;
        display: flex !important;

        li 
        {
            padding-left: 15px;
        }
    }

    .footer-last {
        margin:20px 0px;
    }

    @media only screen and (max-width : $screen-sm-max) {
        .footer	{ 
            .help {
                font-size: 25px;
                text-align: center;
                text-transform: uppercase;
                margin-top: 5px;
            }

            .footer .help2 {
                text-align: center;
                font-size: 20px;
            }

            .footer .mail {
                font-size: 25px;
                text-align: center;
            }

            .copy {
                float: none !important;
                padding-bottom: 15px;
            }

            .divhelp		{
                .divhelp-top			{
                    display: block;

                    .help				{
                        padding-right: 0;
                        padding-bottom: 10px;
                        display: block;
                        text-align: left;
                    }
                }
            }

        }

        
        .footermenu 	{
            //display: none !important;
            display: block!important;
            float: none;
            li		{
                float: none;
                padding: 5px 0;
                display: block;
            }
        }
    }

    //**/
    @media (max-width: $screen-sm-max){
        .footer	{
            //text-align: center;
            padding-bottom: 10px;
            margin-top: 0;

            .footer-section		{
                margin-bottom: 50px;
            }
            .footer-section h2.has-children		{
                border-bottom: 1px solid #ddd;
                padding-bottom: 5px;
                margin-bottom: 10px;
            }

            .footer-section ul.has-children		{
                border-left: 0;
                margin-bottom: 30px;

                li			{
                    margin-bottom: 15px;

                    .brand-image img
                    {
                        opacity: 1;
                    }
                }
            }

            & > .container		{
                padding-top: 40px;
                
                & > .row
                {
                    margin: 0;
                }
            }

            .help,
            .mail		{
                font-size: 13px;
            }
            .mail		{
                margin-bottom: 20px;
            }
            .help2		{
                font-size: 10px;
                line-height: 130%;
            }
            .sociallinks		{
                font-size: 30px;
                text-align: left;
                
                float: none!important;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;

                img			{
                    height: 26px;
                }

                & > li			{
                    display: inline;
                    padding: 0;
                    margin: 0 -2px;
                }
            }
            #languages		{
                margin-top: 12px;
                float: none!important;
                margin-bottom: 15px;
                //width: 80px;
                width: 100%;
                text-align: left;

                li a,  li .a  			{
                    margin-top: 5px;
                    margin-bottom: 5px;
                    display: block;
                    
                    .arrow				{
                        float: right;
                    }
                }
            }
            
            .divhelp		{
                //padding: 0 50px;
                margin-top: 0;
                padding-top: 30px;
                border-top: 1px solid #ddd;

                .divhelp-top .btn
                {
                    width: 100%;
                }
            }
            .copy		{
                font-size: 12px;
                padding-top: 30px;
                border-top: 1px solid #ddd;
                margin-top: 30px;
            }
        }
    }






    /******************************** MODAL FINDER *************************************/
    .modal-finder
{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(white, 0.95);
    z-index: 10;
    overflow-y: auto;
    color: $gray-dark;




    .wrapp
    {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        width: 80%;
        margin: auto;
        padding-top: 8rem;
        padding-left: 0;
        padding-right: 0;

        label
        {
            font-size: 3.088rem;
            font-family: Arvo, Arial, Tahoma;
            text-transform: uppercase;
            display: flex;
            align-items: center;

            img
            {
                margin-right: 0.5rem;
            }
        }
        input
        {
            font-size: $f-size-big-title;
            border: none;
            outline: none;
            box-shadow: none;
            background-color: transparent;
            width: 100%;
            display: block;
            font-weight: 500;
            margin-left: 2rem;
            margin-bottom: 4rem;
        }

        .close
        {
            font-size: 4rem;
            position: absolute;
            top: 6.5rem;
            right: 0;
            display: block !important;
        }
        .non-results{
            font-size: 1.5rem;
        }

        .results
        {
          
            .results-title
            {
                font-family: Arvo, Arial, Tahoma;
                font-size: $f-size-medium;
                font-weight: bold;
                text-transform: uppercase;
            }

            .item
            {
                .image,
                img.image
                {
                    width: 90px;
                    height: 60px;
                    object-fit: cover;
                    margin-right: 1.5rem;
                }

                font-size: 25px;
                padding: 15px 0;
                color: #3a80be;

            }
        }

    }
}


 /********************************END MODAL FINDER *************************************/


 /******************************** popup_cookies *************************************/
 #popup_cookies {
	position: fixed;
	z-index: 1000;
	bottom: 0;
	margin: 0px auto;
	left: 0px;
	right: 0px;
	text-align: center;
	display: none;

    .content{
        font-size: 1.4rem;
    }

	.buttons
	{
		padding-bottom: 15px;   
      
	}
	.buttons a
	{
		padding: 10px;
        font-size: 1.5rem;

		-webkit-transition: color 0.3s ease-in;
		transition: all 0.3s ease-in;
		margin: 0 5px;
        background-color: transparent;

		&.aceptar
		{
			color: $colorCorporative;          
            border: 1px solid #008e9f;

            &:hover{			     
                background-color: $colorCorporative;       
		    }
		}
		&.rechazar
		{
			color: #FF007F;          
            border: 1px solid #e60072;
            
            &:hover{
			    color: #fff;     
                background-color:  #FF007F;       
		    }
		}

		&:hover
		{
            color: #fff;           
		}
	}

	@media screen AND (max-width: $screen-sm-max)
	{
		.content
		{
			font-size: 12px;
			padding: 15px 15px 10px;
		}
		.buttons
		{
			padding-bottom: 10px;
		}
		.buttons a
		{
			//padding: 4px 10px;
            padding: 8px 10px;
			font-size: 12px;
		}


	}

	@media screen AND (min-width: $screen-md-min)
	{
		min-height: 125px;
	}

	background-color: #cacaca;
}
#popup_cookies.mostrar {
	display: block;
}

/********************************END popup_cookies *************************************/
}