.filter-categories-news {
    display: flex;
    align-self: flex-end;
    //width: 85%;
    width: 100%;
    justify-content: space-between;
    //flex-wrap: wrap;
    flex-wrap: nowrap;
    transform: translateY(-1.5rem);

    .wrap-categorysNews {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    li {
        //margin-left: 5rem;
        margin-left: 0;
        padding-left: 2rem;

        a {
            font-size: 1.5rem;
            color: black;
            font-weight: normal;
            font-family: "Montserrat-SemiBold";
            transition: 0.2s all ease;
        }

        .active {
            color: $colorCorporative;
        }
        &:hover {
            a {
                color: $colorCorporative;
                transition: 0.2s all ease;
            }
        }
    }

    .header-line-news {
        opacity: 1;
        border-top: 1px solid rgba($colorCorporative, 0.8);
        margin-bottom: 0.5rem;
        align-self: flex-end;
        width: 100%;
        max-width: 40rem;
    }
}

.container.news {
    background-color: #f3f6f7;
    padding-left: 5rem;
    padding-right: 5rem;

    .text.generic,
    .text.generic * {
        font-size: 1.5rem !important;
        line-height: 2.1rem !important;
        font-family: "Montserrat-Regular" !important;
        letter-spacing: 0 !important;
        p {
            color: #000;
            padding-bottom: 2.5rem;
            &:last-of-type {
                padding-bottom: 0;
            }
            &:empty {
                display: none;
            }
        }
        br {
            display: none;
        }
        b {
            font-family: "Montserrat-Bold" !important;
        }
        i {
            font-family: "Montserrat-Italic" !important;
        }
    }
    .limited-wrap {
        margin: 0 auto;
        width: 100%;
        max-width: 990px;
    }
    &.page-news {
        .limited-wrap {
            margin: 0 auto;
            width: 100%;
            max-width: 1280px;
            padding-top: 15.2rem;

            header {
                display: flex;
                padding-bottom: 6.5rem;
                justify-content: space-between;
            }
        }
    }
}

.section-news-title {
    margin-block-end: 0;
    align-self: flex-end;

    a {
        font-family: "Montserrat-SemiBold";
        font-size: 3.5rem;
        font-weight: normal;
        color: $colorCorporative;
    }

    .title-underline-news {
        border-top: 5px solid $colorCorporative;
        width: 3.5rem;
        margin: 0;
        margin-top: 1rem;
    }
}

.newsContainer {  
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .first-item-news {
        width: 45%;
        background-color: white;

        .content-news {
            justify-content: space-between;
            margin: 2rem 3rem;

            .info {
                display: flex;
                align-items: center;

                .text {
                    font-weight: normal;
                    font-family: "Montserrat-Regular";
                    line-height: 1.9rem;
                    font-size: 1.3rem;
                    padding-bottom: 0.5rem;
                }

                .date-info {
                    font-size: 1.3rem;
                    color: $colorCorporative;
                    font-family: "Montserrat-Italic";
                }

                .news-dot {
                    margin: 0rem 1.1rem;
                    font-size: 7px;
                    align-self: center;
                    margin-bottom: 2px;
                    color: $colorCorporative;
                }
            }
        }

        .details-news {
            margin: 0 3rem 3rem 3rem;
            display: block;
            font-size: 1.5rem;
            font-weight: bold;
            color: black;

            .title {
                font-family: "Montserrat-SemiBold";
                font-size: 1.5rem;
                line-height: 2.1rem;
                font-weight: normal;
            }
            .desc-news {
                margin-top: 1rem;
                font-weight: normal;
            }
        }
        .aux-img {
            width: 100%;
            .wrap-img {
                position: relative;
                padding-top: 78%;
                transform: translateY(0);
                transition: transform 0.5s;
                overflow: hidden;
                .img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    transform: scale(1);
                    transition: transform 0.5s;
                }
            }
        }
        &:hover {
            .wrap-img {
                transform: translateY(-1rem);
                transition: transform 0.5s;
                overflow: hidden;

                .img {
                    transform: scale(1.3);
                    transition: transform 0.5s;
                }
            }
        }
    }

    .right-four {
        width: 55%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        & > div:nth-of-type(3),
        & > div:nth-of-type(4) {
            margin-bottom: 0;
        }

        .wrap-item {
            width: 50%;
            padding-left: 3.6rem;
            margin-bottom: 1.5rem;
            .aux-img {
                width: 100%;
                .wrap-img {
                    position: relative;
                    padding-top: 60%;
                    transform: translateY(0);
                    transition: transform 0.5s;
                    overflow: hidden;
                    .img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        transform: scale(1);
                        transition: transform 0.5s;
                    }
                }
            }
            &:hover {
                .wrap-img {
                    transform: translateY(-1rem);
                    transition: transform 0.5s;

                    .img {
                        transform: scale(1.3);
                        transition: transform 0.5s;
                    }
                }
            }
        }
        .left-item-news {
            width: 100%;
            height: 100%;
            background-color: white;
            .info .text {
                font-family: "Montserrat-Regular";
                font-weight: normal;
                line-height: 1.7rem;
                font-size: 1.3rem;
                padding-bottom: 0.5rem;
            }
            .content-news {
                justify-content: space-between;
                padding: 1.5rem;

                .text {
                    font-family: "Montserrat-Regular";
                    font-weight: normal;
                    line-height: 1.7rem;
                    font-size: 1.3rem;
                    padding-bottom: 0.5rem;
                }

                .date-info {
                    font-size: 1.3rem;
                    color: $colorCorporative;
                    font-family: "Montserrat-Italic";
                }
            }

            .new-title {
                margin: 0 1.5rem 1.5rem 1.5rem;
                display: block;
                color: black;
                font-family: "Montserrat-SemiBold";
                font-size: 1.3rem;
                line-height: 1.9rem;
                font-weight: normal;
                color: #000;
            }
        }
    }
    .wrap-rest-items {
        width: 100%;
        .aux-wrap {
            width: calc(100% + 3.5rem);
            display: flex;
            flex-wrap: wrap;

            .info .text {
                font-family: "Montserrat-Regular";
                font-weight: normal;
                line-height: 1.7rem;
                font-size: 1.3rem;
                padding-bottom: 0.5rem;
            }
        }

        .rest-items {
            width: 100%;
            padding-right: 3.5rem;
            width: calc(100% / 3);
            margin-top: 7.5rem;
            display: flex;
            flex-direction: column;

            .wrap-img {
                position: relative;
                padding-top: 63%;
                transform: translateY(0);
                transition: transform 0.5s;
                overflow: hidden;
                .img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: scale(1);
                    transition: transform 0.5s;
                }
            }

            .news-content {
                justify-content: space-between;
                margin-top: 2rem;
                margin-bottom: 1rem;

                .date-info {
                    font-size: 1.3rem;
                    color: $colorCorporative;
                    font-family: "Montserrat-Italic";
                }
            }

            .new-title {
                font-family: "Montserrat-SemiBold";
                font-size: 1.5rem;
                line-height: 2.1rem;
                font-weight: normal;
                color: #000;
            }
            &:hover {
                .wrap-img {
                    transform: translateY(-1rem);
                    transition: transform 0.5s;

                    .img {
                        transform: scale(1.3);
                        transition: transform 0.5s;
                    }
                }
            }
        }
    }
    .news-spacer-hr {
        width: 100%;
        margin-top: 7.5rem;
    }
}

.hiddenMobile {
    display: block;
}

.hiddenDesktop {
    display: none;
}

// Single news page

.mock-img {
    width: 100%;
    height: 60rem;
    background-color: lightseagreen;
    margin: 16.5rem auto 0rem auto;    
}
.linkBack {
    position: relative;
    top: -3.5rem;
    padding-left: 2.5rem;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #00a5b8;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 1.5rem;
        height: 100%;
        background-image: url("/images/arrow-right-green.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(180deg) scale(1);
        transition: 0.2s transform ease;
        top: 0;
    }

    &:hover {
        &::before {
            transform: rotate(180deg) scale(1.3);
            transition: 0.2s transform ease;
        }
    }
}

.list-image {
    height: 173px;
}

.other-images {
    height: 300px;
    background-size: 100% 100%;
    display: block;
}

.news-article {
    width: 100%;
}

.new-article-text {
    .new-news-header {
        width: 71%;
        margin: auto;
        padding: 2rem 5rem;
        background-color: #f3f6f7;
        margin-top: -10rem;     

        h1 {
            font-size: 4rem;
            color: #575252;
            font-weight: bold;
            text-align: center;
            margin-bottom: 3rem;
            font-family: "Montserrat-Bold";
        }

        .date {
            font-size: 1.7rem;
            color: $colorCorporative;
            font-style: oblique;
            text-align: center;
            margin: auto;
            opacity: 1;
            width: 45%;
            padding: 1rem 0rem;
            border-top: 1px solid $colorCorporative;
            border-bottom: 1px solid $colorCorporative;
            font-family: "Montserrat-Italic";
        }

        .news-category {
            font-size: 1.5rem;
            letter-spacing: 0.5rem;
            text-transform: uppercase;
            text-align: center;
            margin: 2rem 0rem;
        }
    }

    .text {
        width: 100%;
        margin: auto;
        font-size: 1.9rem;
        line-height: 2.9rem;
        margin-top: 5rem;
      
        > * {
            line-height: 2.1rem !important;
            font-size: 1.5rem !important;
            color: #000 !important;
            font-family: "Montserrat-Regular" !important;
            font-weight: normal !important;
            background-color: transparent !important;
            padding-bottom: 2rem;
        }
    }
    .text.generic {
        a {
            color: $colorCorporative !important;
            &:hover {
                filter: brightness(0.8);
            }
        }
    }
    .wrapper {
        margin-top: 8rem;

        .pasadorPost{
            .img-responsive{
                height: 26.5rem;
                left: 0;
                object-fit: contain;
                position: relative;
                top: 0;
            }
        }
   
         //********************slick-slider(remove)********************//
        .wrap-slider-new {
            &.slick-slider {
                width: calc(((((100vw - 11.7rem) - 100%) / 2) + 5rem + 100%));
                .slick-list {
                    width: 100%;
                    padding: 0 !important;
                    .slick-track {
                        margin: 0;
                    }
                }
            }
            .item-img {              
                margin-right: 1.7rem;

                .img {
                    width: 100%;
                    padding-top: 64%;
                    position: relative;

                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        top: 0;
                        left: 0;
                    }
                }
            }

            .slick-arrow {
                z-index: 1;
            }

            .slick-prev {
                left: 1.5rem;
                display: none;
                width: 4rem;
                height: 4rem;

                &:before {
                    content: "";
                    background-image: url("/images/left.svg");
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: 0;
                    left: 0;
                }
            }
            .slick-next {
                right: 1.5rem;
                display: none;
                width: 4rem;
                height: 4rem;

                &:before {
                    content: "";
                    background-image: url("/images/right.svg");
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: 0;
                    left: 0;
                }
            }
            &:hover {
                .slick-prev,
                .slick-next {
                    display: block;
                }
            }
            &:hover {
                .slick-prev,
                .slick-next {
                    opacity: 1;
                }
            }
        }
        /**
          *  Progress Bar
          */
        .wrap-progress {
            width: 100%;
            max-width: 1280px;
            padding-top: 2.8rem;
            .progress {
                display: block;
                width: 100%;
                height: 5px;
                border-radius: 8px;
                overflow: hidden;

                background-color: rgba($colorCorporative, 0.5);
                background-image: linear-gradient(to right, $colorCorporative, $colorCorporative);
                background-repeat: no-repeat;
                background-size: 0 100%;

                transition: background-size 0.4s ease-in-out;
            }
            .sr-only {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                border: 0;
            }
            &.notSee {
                opacity: 0;
            }
        }

        /**
        *  END Progress Bar
        */


        //********************fin slick-slider(remove)********************//




         //********************swiper-slider(add)********************//
      
         .pasadorPost{
            width: 100%;
            position: relative;
            background-color: transparent;    
            margin-right: 0.5rem;
            min-height: unset;      
            margin-bottom: 0;
    
            .swiper-wrapper{
                .swiper-slide{
                    padding-right: 1.5rem;                 
                    width: auto !important;

                }
            }
       
            .control {
                width: 4rem;
                height: 4rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: transparent;
                color: transparent;
                border: none;
                font-size: unset;
                opacity: .75;
                transition: .2s all ease-in;
                z-index: 1;
                &:hover{
                    opacity: 1;
                }
                &.swiper-button-disabled{
                    transition: .2s all ease-in;
                    opacity: 0;
                }
               
                &.control-prev{              
                    width: 4rem;
                    height: 4rem;
                    left: 1rem;
                   
                    &:before{
                        content: "";
                        background-image: url('/images/right.svg');
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        top: 0;
                        left: 0;
                        transform: rotate(180deg);
                    }
                   
                }
                &.control-next{            
                    width: 4rem;
                    height: 4rem;
                    right: 1rem;
                    
                    &:before{
                        content: "";
                        background-image: url('/images/right.svg');
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        top: 0;
                        left: 0;
                    }
                }
            }
           
        }

          

         //********************fin swiper-slider(add)********************//
    }
    
}
 // PHOTO Full screen

 .news-article .mygallerybox{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: -1;
    opacity: 0;

    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;
  
    &.preopen,
    &.open{
        z-index: 1040;	
    }

    &.open {
        opacity: 1;
    }

    & .swiper-container{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    

    .slide-img{          
        max-width: 90%;
        max-height: 90%;
        position: absolute;
        top: 0;
        left:0;
        right:0;
        bottom: 0;
        margin: auto;


        @media (max-width: $md-width) {
            max-width: 100%;
            max-height: 100%;
        }          
       
    }

    .btn-close {    
        position: absolute;
        top: 5rem;
        right: 5rem;
        padding: 0;
        background-color: transparent;          
        -webkit-transition: 0.3s ease-in all;
        transition: 0.3s ease-in all;
        z-index: 1040;
        width: 3rem;
        height: 3rem;
        opacity: .75;
        &:hover{
            background-color: transparent;
        }

        &::before{
            content: "";
            position: absolute;
            background-image: url('/images/close-x.svg');
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;          
        }
        
        &:hover{
            opacity: 1;            
        }
    }

    .swiper-button-prev,
    .swiper-button-next {            
        width: 4rem;
        height: 4rem;   
        background-image: none;
    }
    .swiper-button-prev{
        left: 15px;          

        &:before{
            content: '';
            position: absolute;
            width: 4rem;
            height: 4rem;
            background-image: url('/images/right.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            transform: rotate(180deg);
        }
    }
    .swiper-button-next {
        right: 15px;

        &:before{
            content: '';
            position: absolute;
            width: 4rem;
            height: 4rem;
            background-image: url('/images/right.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

}

.item-news {
    display: inline-block;
    margin-right: 2rem;
    user-select: none;
    position: relative;

    background: #c95a9c;
    width: calc(100% / 3);   

    .wrap-img {
        width: 100%;
        padding-top: 64%;
        position: relative;
        overflow: hidden;
        .img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            object-position: center;
        }
    }
}

.new-news-socials {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10rem auto;  

    .socials {
        display: flex;

        a {
            margin-right: 2rem;
            font-size: 2.5rem;
            color: #666;

            &:hover i {
                color: $colorCorporative;
            }

            i {
                transition: 0.5s;
            }
        }
    }

    .heart {
        font-size: 2.5rem;
        margin-bottom: 5px;
        margin-left: 0.75rem;
    }

    .heart--red {
        color: #c95a9c;
    }
}

.new-new-spacer {
    width: 100%;
    margin: auto;
    border-color: $colorCorporative;
    opacity: 1;
    margin-bottom: 5rem;   
}

.news .section-title {
    width: 100%;
    margin: 0 auto;
    margin-left: 0;

    h2 {
        font-size: 2.1rem;
        color: $colorCorporative;     
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 5.25px;
        font-family: "Montserrat-SemiBold";      
    }

    hr {
        width: 3.5rem;
        border-top: 4px solid $colorCorporative;
        margin: 2rem auto 7rem auto;
    }
}

.last-3-news {
    width: calc(100% + 2rem);
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 10rem;   
    .last-3-new {
        //width: calc(100% / 3);
        //padding-right: 2rem;
        padding-right: 0;
        width: calc(33.33333% - 1.5rem);
        background-color: $colorWhite;
        padding-bottom: 3rem;

        .wrap-img {
            position: relative;
            padding-top: 64%;
            transform: translateY(0);
            transition: transform 0.5s;
            overflow: hidden;

            .img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                background-color: $colorCorporative;
                transform: scale(1);
                transition: 0.5s transform linear;
            }
        }
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            //margin-top: 1rem;
            background-color: $colorWhite;
            padding: 0 1rem;
            padding-top: 1rem;

            span {
                font-size: 1.3rem;
            }

            .heart {
                color: #c95a9c;
            }
        }

        .date {
            font-size: 1.3rem;
            color: $colorCorporative;
            opacity: 1;
            display: block;
            //margin-bottom: 2rem;
            font-family: "Montserrat-Italic";
            padding: 0 1rem;
            background-color: $colorWhite;
            padding-bottom: 2rem;
        }

        .title {
            font-size: 1.5rem;
            font-family: "Montserrat-SemiBold";
            color: #000;
            padding: 0 1rem;
            //padding-bottom: 3rem;
        }
        &:hover {
            .wrap-img {
                transform: translateY(-1rem);
                transition: transform 0.5s;
                .img {
                    transform: scale(1.3);
                    transition: 0.5s transform linear;
                }
            }
        }
    }
}

.newsletter-section {
    display: flex;
    width: 100%; 
    padding-bottom: 10rem;
    padding-top: 4rem;

    .news-form {
        width: 65%;
        margin-bottom: 10rem;
        max-width: 38.2rem;

        .privacy label {
            margin-left: 0;
        }

        .wrap-btnDefault {
            margin: 0 auto;
            margin-top: 4rem;
            display: flex;
            margin-right: 0;
        }

        h2 {
            font-size: 4rem;
            color: $colorCorporative;
            font-weight: bold;
            margin-bottom: 3rem;
        }

        .email-input {
            label {
                width: 6.5rem;
                font-size: 1.3rem;
                font-weight: 600;
                display: block;
                background-color: #f3f6f7;
                padding: 0.2rem 1rem;
                transform: translate(20px, 15px);
            }

            input {
                border: 1px solid #575252;
                width: 100%;
                padding: 1rem 2rem;
            }
        }

        .privacy {
            display: flex;
            margin-top: 1.5rem;

            label {
                order: 1;             
                font-size: 1.1rem;
            }
        }

        .submit-btn {
            background-color: $colorCorporative;
            color: white;
            text-transform: uppercase;
            font-size: 1.5rem;
            letter-spacing: 3px;
            font-weight: bold;
            padding: 1.5rem 5rem;
            margin-top: 3rem;        
            box-shadow: 4px 4px 0px 0px #f3f6f7, 4px 4px 0px 1px $colorCorporative;
            transition: 0.5s;
            margin: 0 auto;
            margin-right: 0;

            &:hover {
                box-shadow: none;
            }
        }
    }
    .wrap-sliding-img {
        //width: 55%;
        max-width: 88rem;
        width: calc(45% + (((100vw - 1.7rem) - 100%) / 2));
        position: absolute;
        right: 0;     
        overflow: hidden;
    }
    .sliding-img {
        width: 100%;
        padding-top: 58%;
        position: relative;      
        background-size: 200%;
        background-image: linear-gradient(
            90deg,
            rgba(2, 0, 36, 0) 50%,
            rgb(17, 151, 165) 50%
        );
        background-position: 0;
        transition: background-position 0.2s ease-in;

        .sliding-img2 {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
            top: 0;
            left: 0;

            background-size: 200%, 100% 100%;
            background-image: linear-gradient(
                    90deg,
                    rgba(2, 0, 36, 0) 50%,
                    rgba(2, 0, 36, 0) 50%
                ),
                url(/images/img-footer-newsletter.webp);
            background-position: 0, 100rem 0;
            background-repeat: no-repeat;
            transition: background-position 0.2s 0.5s ease-in;
        }
        @media (max-width: $md-width) {
            background-position: right, 0 0 !important;
            .sliding-img2 {
                background-position: right, 0 0 !important;
            }
        }
    }

    .js-right {
        background-position: right, 0 0 !important;
        .sliding-img2 {
            background-position: right, 0 0 !important;
            transition: background-position 0.2s 0.5s ease-in;
        }
    }
}
@media (min-width: #{$md-width + 1px}) and (max-width: 1342px) {
    .filter-categories-news {
        display: flex;
        align-self: flex-end;   
        width: 100%;
        justify-content: space-between;        
        flex-wrap: nowrap;
        transform: translateY(-1.5rem);

        .wrap-categorysNews {
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        li {
            padding-left: 2rem;
        }

        .header-line-news {
            width: 15%;
            max-width: 30rem;
        }
    }
}

@media (max-width: $md-width) {
    .container.news {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 11.2rem;
    }

    .container.news.page-news {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 10rem;

        .limited-wrap {
            padding-top: 0;
            max-width: 100%;
            header {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                flex-wrap: wrap;
                padding-bottom: 4rem;
            }
        }
    }

    .list-image {
        height: 20rem;
    }

    .hiddenMobile {
        display: none;
    }

    .hiddenDesktop {
        display: block;
    }

    .closed {
        height: 50px;
    }

    .opened {
        height: unset;
    }

    .filter-categories-news {
        display: flex;
        align-self: unset;
        width: 100%;
        flex-wrap: wrap;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        justify-content: center;
        overflow: hidden;
        font-family: "Montserrat-Medium";

        .wrap-categorysNews {
            transform: translateX(-1rem);
        }

        button {
            width: 100%;
            font-size: 1.5rem;
            text-transform: uppercase;
            color: $colorCorporative;
            padding: 1.5rem;
            margin-bottom: 4rem;
            letter-spacing: 5px;
        }

        li {           
            margin: 0rem 0rem 3rem 2rem;
            padding-left: 0;

            a {
                font-size: 1.5rem;
                color: black;
                font-weight: normal;
                font-family: "Montserrat-Regular";
            }

            .active {
                color: $colorCorporative;
            }
        }

        .header-line-news {
            opacity: 1;
            border-top: 1px solid $colorCorporative;
            margin-bottom: 0.5rem;
            align-self: flex-end;
            width: 100%;
            max-width: 100%;
        }
    }

    .section-news-title {
        margin-block-end: 0;
        align-self: flex-end;
        width: 100%;
        margin-bottom: 3rem;
        font-size: 2.5rem;

        a {
            font-size: 2.5rem;
            font-weight: normal;
            font-family: "Montserrat-SemiBold";
            color: $colorCorporative;
        }

        .title-underline-news {
            border-top: 2px solid $colorCorporative;
            width: 3.5rem;
            margin: 0;
        }
    }

    .newsContainer {     
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        .first-item-news {
            width: 100%;
            background-color: transparent;
            .aux-img {
                width: 100%;               
            }
            .wrap-content {
                padding-right: 3rem;
                padding-left: 3rem;
            }
            .content-news {
                justify-content: space-between;
                margin: 2rem 0;

                .info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .text {
                        font-size: 1.3rem;
                    }

                    .date-info {
                        font-size: 1.3rem;
                        color: $colorCorporative;
                        font-family: "Montserrat-Italic";
                    }

                    .news-dot {
                        display: none;
                    }
                }
            }

            .details-news {
                margin: 2rem 0;
                display: block;
                font-size: 1.5rem;
                font-weight: bold;
                color: black;

                .desc-news {
                    margin-top: 1rem;
                    font-weight: normal;
                }
            }
        }

        .right-four {
            width: 100%;
            display: flex;
            justify-content: unset;
            flex-wrap: nowrap;
            overflow: scroll;
            padding-left: 1.4rem;
            margin-top: 3rem;

            &::-webkit-scrollbar {
                display: none;
            }

            & > .wrap-item {
                width: calc(100% - 4.5rem);
                background-color: white;
                flex-shrink: 0;
                margin-right: 1.4rem;
                padding-left: 0;
                .content-news {
                    justify-content: space-between;
                    padding: 1.5rem;

                    .text {
                        font-size: 1.3rem;
                    }

                    .date-info {
                        font-size: 1.3rem;
                        color: $colorCorporative;
                    }
                }

                .new-title {
                    font-size: 1.5rem;
                    margin: 0 1.5rem 1.5rem 1.5rem;
                    display: block;
                    font-weight: bold;
                    color: black;
                }

                .desc-news {
                    margin-top: 2rem;
                    font-weight: normal;
                }
            }

            & > div:nth-of-type(1),
            & > div:nth-of-type(2) {
                margin-bottom: 0;
            }

            & > div:nth-of-type(3),
            & > div:nth-of-type(4) {
                margin-top: 0;
            }
        }
        .wrap-rest-items {
            width: 100%;
            .aux-wrap {
                width: 100%;
            }
            .rest-items {
                margin-top: 6rem;
                justify-content: center;
                margin-left: 0;
                width: 100%;
                padding-right: 3rem;
                padding-left: 3rem;

                .news-content {
                    justify-content: space-between;
                    margin-top: 2rem;
                    margin-bottom: 1rem;

                    .date-info {
                        font-size: 1.3rem;
                        color: $colorCorporative;
                    }
                }

                .new-title {
                    font-family: "Montserrat-SemiBold";
                    font-size: 1.3rem;
                    line-height: 1.9rem;
                    font-weight: normal;
                    color: #000;
                }
                .wrap-img {
                    position: relative;
                    padding-top: 60%;

                    .img.other-images {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .news-spacer-hr {
            width: 100%;
            margin: 6rem 3rem 0rem 3rem;
        }
    }

    // Single news page

    .mock-img {
        height: 20.1rem;
        margin: 0;
    }
    .linkBack {
        top: -2rem;
        font-size: 1rem;
    }
    .news-article {
        width: 100%;
    }

    .new-article-text {
        .new-news-header {
            padding: 1rem 1.5rem;          
            margin-top: -4rem;
            margin-bottom: 2.5rem;

            h1 {
                font-size: 2.1rem;
                font-family: "Montserrat-SemiBold";
                line-height: 2.7rem;
            }

            .date {
                font-size: 1.3rem;
                opacity: 1;
                width: 80%;
                padding: 1rem 0rem;
            }

            .news-category {
                font-size: 1.3rem;
                letter-spacing: 0.2rem;
                margin: 0rem 0rem 2rem 0;
            }
        }

        .text {
            width: 100%;
            margin: auto;
            font-size: 1.5rem;
            line-height: 2.5rem;
            transform: translateY(0rem);
        }
        .text.generic {
            a {
                color: $colorCorporative !important;
                &:hover {
                    filter: brightness(0.8);
                }
            }
        }
        .wrapper {
            margin-top: 4.5rem;

            .pasadorPost{
                .img-responsive{
                    height: 100%;
                    max-height: 20rem;
                    object-fit: contain;
                    width: auto;
                }
            }
        }
    }
 

    .item-news {
        width: calc(100% - 3rem);
    }

    .new-news-socials {
        margin-top: 7rem;
        margin-bottom: 4.5rem;

        .likes-count {
            font-size: 1.9rem;
        }
    }

    .news .section-title {
        width: 100%;

        h2 {
            font-size: 1.7rem;
            letter-spacing: 4.25px;
        }

        hr {
            margin: 2rem auto 3rem auto;
        }
    }

    .last-3-news {
        transform: translateX(-1.5rem);
        width: calc(100% + 3rem);
        flex-wrap: nowrap;
        padding-bottom: 6rem;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        .last-3-new {
            width: calc(100% - 6rem);
            flex-shrink: 0;
            margin-right: 1.5rem;
            padding-right: 0;

            &:hover {
                .wrap-img {
                    transform: translateY(0);

                    .img {
                        transform: scale(1);
                    }
                }
            }

            &:first-of-type {
                margin-left: 1.5rem;
            }
        }
    }

    .newsletter-section {
        display: flex;
        flex-direction: column;
        padding-bottom: 10rem;
        padding-top: 0rem;
        width: 100%;
        margin-left: 0;
        max-width: 100%;

        .news-form {
            width: 100%;
            max-width: 100%;
            padding-right: 0;
            margin-bottom: 8rem;

            .wrap-btnDefault {
                min-width: unset;
                align-self: flex-end;
            }

            h2 {
                margin-bottom: 1rem;
            }

            .email-input {
                input {
                    padding: 1.5rem 2rem;
                    font-size: 1.5rem;
                }
            }

            .submit-btn {
                float: right;
                font-size: 1.7rem;
                padding: 1.5rem 3rem;
                margin-left: 0;
            }
        }

        .wrap-sliding-img {
            position: relative;
            width: calc(100% + 3rem);
            max-width: calc(100% + 3rem);
            overflow: unset;
        }
    }
}

.newsWrapper:hover {
    .wrap-img {
        transform: translateY(-1rem);
        transition: transform 0.5s;

        .img {
            transform: scale(1.3);
            transition: transform 0.5s;
        }
    }
}

@media (max-width: $md-width) {
    .container.news {
        .section-title {
            width: 100%;
            margin: 0 auto;
            margin-left: 0;
            font-size: 1.7rem;
            text-transform: none;
            padding: 0;
            background-color: transparent;
            margin-bottom: 0;
            display: block;
        }
        .wrapper{
            .aux-slider{
				padding-right: 0;
				width: calc(100% + 3rem);
				transform: translateX(-1.5rem);
				.pasadorPost .swiper-wrapper .swiper-slide{
					padding-right: 0.8rem;				
				}
                .swiper-wrapper{
                    display: flex;
                    align-items: center;

                    .swiper-slide{                       
                        .img-responsive{
                            height: 100%;
                            width: auto;
                            object-fit: contain;
                            max-height: 20rem;      
                        }
                    }
                }
			}
        }
    }
}
