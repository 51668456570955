//NO USA pare dublicada en sass/components
@import "../solutions/settings";

/*** START OLD ***/
.header-detail
{
    position: relative;
    left: 50%;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    margin-top: -$topbar-height;
    z-index: 2;
    transform: translateX(-50%);

    .slider
    {
        max-height: 100vh;
        max-width: 100vw;
        height: $header-detail-height-m;
        width: 100vw;
        overflow: hidden;

        .item
        {
            height: $header-detail-height-m;
            width: 100vw!important;

            img
            {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    @include media-breakpoint-up(md){
        &,
        & .slider,
        & .slider .item
        {
            height: $header-detail-height;

        }       
    }

    .slick-dots
    {
        position: absolute;
        bottom: rem(20px);
        left: rem(70px);
        right: rem(70px);
        text-align: center;
        bottom: rem(86px);
        right: 0;
        width: calc(100vw - #{rem(385px)} - #{$lateral-space});
        left: auto;
        height: rem(50px);
        text-align: left;
        margin: auto;
        white-space: nowrap;
        &:before
        {
            content: '';
            position: absolute;
            top: rem(11px);
            right: 0;
            height: 2px;
            width: 100%;
            background-color: rgba(white, 0.15);
            transition: width $t-normal $easeOutCubic 0s;
        }
        li
        {
            position: relative;
            margin: 0 rem(23px);
            height: 0;
            width: 0;
            transform: translateX(0);
            transition: transform $t-normal $easeOutCubic 0.2s;
            &:first-child
            {
                margin-left: rem(47px);
            }
            $delay: 0.05s;
            @for $i from 1 through 15 {
                &:nth-child(#{$i}) {
                    transition: transform $t-normal $easeOutCubic calc(0.2s + (#{$i} * #{$delay}));
                }
            }
            &:before {
                position: absolute;
                content: "\2022";
                top: calc(50% - 1px);
                left: 50%;
                transform: translate(-50%, -50%);
                font-family: "slick";
                font-size: 0;
                color: rgba(white, 0.15);
                width: 6px;
                height: 6px;
                background-color: rgba(white, 0.15);
                border-radius: 100%;
            }
            button {
                margin-top: -10px;
                margin-left: -10px;
                position: absolute;
                width: 20px;
                height: 20px;
                clip: rect(0px, 20px, 20px, 10px);
                &:before {
                    content: '' !important;
                }
            }
            &.slick-active {
                color: rgba(white, 1);
                &:before {
                    color: rgba(white, 1);
                    background-color: rgba(white, 1);
                }
                button {
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    animation-timing-function: linear;
                    animation-duration: 0.01s;
                    animation-delay: 1.5s;
                    animation-name: close-wrapper;
                    &:before, &:after {
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        animation-timing-function: linear;
                        position: absolute;
                        content: '' !important;
                        top: 0;
                        left: 1px;
                        width: 18px;
                        height: 18px;
                        border: 1px solid #fff;
                        border-radius: 100%;
                        clip: rect(0px, 10px, 20px, 0px);
                    }
                    &:before {
                        animation-duration: 3s;
                        animation-name: left-spin;
                    }
                    &:after {
                        animation-duration: 1.5s;
                        animation-name: right-spin;
                    }
                }
            }
        }

        @media (min-width: $special-container-max-width)
        {
            width: calc(100vw - ((100vw - #{$special-container-max-width}) / 2) - #{rem(385px)} - #{$lateral-space});
        }
    }

    .control-fullscreen,
    .control-next,
    .control-prev,
    .control-readmore
    {
        cursor: pointer;
        z-index: 2;
        color: white;
        font-size: rem(11px);
        text-transform: uppercase;
        font-weight: bold;
    }

    .control-fullscreen
    {
        position: absolute;
        top: rem(30px);
        right: rem(22px);

        @include media-breakpoint-up(md){
            right: rem(52px);
            top: calc(#{rem(46px)} + #{rem(60px)}); // + h cabecera
        }
    }

    .control-readmore
    {
        position: absolute;
        right: rem(52px);
        bottom: rem(48px);
    }
    .control-group-prev-next
    {
        position: absolute;
        bottom: rem(8px);
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        padding: 0 3px;



        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div
        {
            margin: 0 3px;
        }

        @include media-breakpoint-up(md)
        {
            bottom: rem(48px);
            width: auto;
            padding: 0;
            justify-content: inherit;
        }
    }

    &.is-fullscreen
    {
        .control-readmore
        {
            display: none !important;
        }
        .slick-dots
        {
            width: auto;
            left: 0;
            text-align: center;

            li:first-child
            {
                margin-left: rem(23px);
            }
        }
        .control-group-prev-next
        {
            margin-right: auto;
            margin-left: auto;
            left: 50%;
            right: auto;
        }
    }
    @include media-breakpoint-down(sm)
    {
        padding-top: $topbar-height-sm;
        margin-top: 0;
        height: 60vh;
        .control-fullscreen {
            top: rem(25px);
        }
        .slider {
            height: 100%;
            margin-bottom: 0;
            .slick-list, .slick-track, .slick-slide, .slick-slide > div, .item {
                height: 100%;
            }
            .slick-dots {
                width: calc(100% - #{rem(100px)});
                height: unset;
                bottom: rem(30px);
                left: 50%;
                transform: translate(-50%, 50%);
                text-align: center;
                &:before {
                    display: none;
                }
                li {
                    margin: 0;
                    margin-right: rem(20px);
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.module-valoraciones {
    padding-top: rem(25px);
}

.slider-handler {
    width: rem(385px);
    padding: 0 !important;
    @include media-breakpoint-down(sm)
    {
        width: 100vw;
        min-width: 100vw;
        max-width: 100vw;
        padding: 0 !important;
        left: 50%;
        transform: translateX(-50%);
    }
}

.slider-container, 
.slider-container.col-12 {
    position: relative;
    width: calc(100% - #{rem(385px)});
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        height: 100%;
        width: calc(100vw - #{rem(325px)});
        background-color: $gray;
    }
    .module-tabs-nav {
        width: 100vw;
        max-width: calc(100vw - #{rem(325px)} - #{rem(98px)});
        overflow-x: auto;
        padding-left: rem(98px);
        margin-left: rem(-98px);

        @include media-breakpoint-down(sm){
            max-width: 100%;
            margin-left: 0;
            padding-left: 0;
        }

        >.item {
            &:last-child {
                margin-right: rem(56px);
            }
        }
    }
    @media (min-width: $special-container-max-width)
    {
        .module-tabs-nav {
            max-width: calc(100vw - ((100vw - #{$special-container-max-width}) / 2) - #{rem(325px)} - #{rem(98px)});
        }
    }
    @include media-breakpoint-down(sm){
        &, 
        &.col-12{
            width: 100%;
            overflow: hidden;
            width: 100vw;
            min-width: 100vw;
            max-width: 100vw;
            padding: 0;
            left: 50%;
            transform: translateX(-50%);
            &:before {
                display: none;
            }
        }
    }
}

@keyframes right-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes left-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes close-wrapper {
  to {
    clip: rect(auto, auto, auto, auto);
  }
}

.ballon-top,
.ballon-bottom {
    position: absolute;
    height: rem(442px);
    width: rem(442px);
    background-color: $white;
    border-radius: 100%;
    z-index: -1;
    transition: transform $t-fast $easeOutCubic;
}
.ballon-top
{
    top: rem(-121px);
    left: rem(78px);
}

.ballon-bottom
{
    top: rem(455px);
    right: rem(-60px);
}

.atugusto-img {
    width: calc(100vw - #{rem(385px)} - #{$lateral-space} - #{rem(96px)});

    img {
        width: 100%;
    }

    @include media-breakpoint-up($special-container-max-width)
    {
        width: calc(100vw - ((100vw - #{$special-container-max-width}) / 2) - #{rem(385px)} - #{$lateral-space} - #{rem(96px)});
    }
    @include media-breakpoint-down(sm)
    {
        width: calc(100vw - #{rem(20px)});
    }
}

#tipologias-de-sofa {
    .module-tab {
        margin-left: calc(-#{rem(96px)} - #{rem(25px)});
        width: calc(100% + #{rem(96px)} + #{rem(25px)});
    }
}

#caracteristicas {
    .module-body {
        .module-wrap {
            >.row {
                flex-wrap: wrap;
            }
        }
    }
}

.simulation-button-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    @include media-breakpoint-down(sm)
    {

    }
}

.select-gb-region-wrapper,
.select-gb-region-wrapper-xs {
    text-align: center;
    h3 {
        margin-bottom: 15px;
    }
    input {
        margin-right: 5px;
    }
    button {
        margin-top: 15px;
    }
}

@media(max-width: $screen-sm-max) {
    .flex-max-sm-column {
        flex-direction: column;
    }
    .order-first-xs {
        order: -1;
    }
    .select-gb-region-wrapper-xs {
        margin-left: 0 !important;
        margin-bottom: 15px !important;
    }
}

/*** END OLD ***/



//FAMA NEW
.app-detail_2021{
    font-size: 1.5rem;
    line-height: 2.1rem;

    .loves-num{
        font-size: 1.2rem;
    }
    &.full{
        max-width: 100%;
        margin: 0 auto;
        padding: 7rem 5rem; 
        .module-form_2021{
            max-width: 1400px;
            margin: 0 auto;        

            .section-title{            
                .title{
                    font-size: 3.5rem;
                    text-transform: none;
                    font-family: "Montserrat-SemiBold";
                    font-weight: normal;
                    line-height: 4.3rem;
                    text-align: left;
                }
                .title-underline{
                    width: 3.5rem;
                    border-bottom: 4px solid;
                    background: $colorCorporative;
                    margin-top: 0.5rem;
                    margin-bottom: 5.4rem;
                }
            }

            @media (max-width: $md-width) {      
                .section-title{
                    padding-left: 0;
                    .title{
                        font-size: 2.5rem;
                        line-height: 3.3rem;
                    }
                    .title-underline{
                        width: 3.5rem;
                        border-bottom: 2px solid;
                        margin-bottom: 5.4rem;
                    }
        
                }
            }
        }
    }


    .special-container>div{
        display: flex;

        @media (max-width: $sm-width) {       
            flex-direction: column;
        }
    }
    .module{
        .module-next a{
            font-size: 1.5rem;
        }
    }

    .header-detail{   
        .slider{       
            height: 100vh;    
            .item{
                display: inline-block;
                margin-right: 0;
                height: 60vh !important;              
            }
        }

        @include media-breakpoint-up(md){
            &,
            & .slider,
            & .slider .item{
                height: $header-detail-height !important;
            }
        }

        .slick-dots{     
            /*  
            bottom: 2rem;
            left: 7rem;
            right: 7rem;       
            bottom: 8.6rem;      
            right: 1rem;
            width: calc(100vw - 36rem - 6.5rem);     
            height: 5rem;
            */

            @media (max-width: $md-width) {       
                width: calc(100vw - 40.5rem);     
            }
            &:before{           
                top: 1.3rem;           
            }
            li{           
                margin: 0 2.3rem;         
                &:first-child{
                    margin-left: 4.7rem;
                }   
                button {
                    margin-top: -1rem;
                    margin-left: -1rem;               
                    width: 2rem;
                    height: 2rem;
                    clip: rect(0, 2rem, 2rem, 1rem);            
                }
                &.slick-active {              
                    button {                  
                        &:before, &:after {         
                            clip: rect(0px, 1rem, 2rem, 0px);
                        }                
                    }
                }
            }

            @media (min-width: $special-container-max-width){        
                width: calc(100vw - ((100vw - 140rem) / 2) - 39rem - 6rem);      
                transform: translateX(-1rem);
            }
        }

        .control-fullscreen,
        .control-next,
        .control-prev,
        .control-readmore{       
            font-size: 1.1rem;    
        }

        .control-fullscreen{
            position: absolute;
            right: 5rem;
            top: 14rem;

            @media (max-width: 991px) {
                right: 1.5rem;
                top: calc(1.5rem + 7rem); // + h cabecera
                top: 10.2rem;
            }
        }

        .control-readmore{       
            right: 5.2rem;
            bottom: 4.8rem;
        }
        .control-group-prev-next{      
            bottom: 4.8rem;          
            transform: translateX(-17rem);    

            @media (max-width: 1400px) {
                bottom: 4.8rem;          
                transform: translate(-50%, 0);              
            }

            @media (max-width: $sm-width) {     
                bottom: .5rem;  
                width: calc(100vw - 3rem);           
                justify-content: space-between;  
            }                
        }

        &.is-fullscreen{   
            .slick-dots{       
                li:first-child{
                    margin-left: 3.2rem;
                }
            }
            .control-group-prev-next{           
                transform: translateX(50%, 0);
            }
        }
        @include media-breakpoint-down(sm){     
            padding-top: 0;  
            .control-fullscreen {
                top: 10.2rem;
            }
            .slider {
                width: 100%;
                min-width: 100%;
                max-width: 100%;    

                .slick-dots {
                    width: calc(100% - 6.25rem);               
                    bottom: 3rem;             
            
                    li {                  
                        margin-right: 1.1rem;   
                    }
                }
            }
        }
    }

    .module-valoraciones {
        padding-top: 2.5rem;
    }

    .slider-handler {
        width: 39rem;
     
        @include media-breakpoint-down(sm){  
            left: 0;
            transform: unset;
            margin-left: -1.5rem;
        }
    }

    .slider-container {      
        width: calc(100% - 39rem);
        &:before {          
            width: calc(100vw - 32.5rem);
            background-color: #f3f6f7;
        }
        .module-tabs-nav {          
            max-width: calc(100vw - 32.5rem - 9.8rem);           
            padding-left: 9.8rem;
            margin-left: -9.8rem;
            >.item {
                &:last-child {
                    margin-right: 5.6rem;
                }
            }
        }
        @media (min-width: $special-container-max-width){
            .module-tabs-nav {
                max-width: calc(100vw - ((100vw - #{$special-container-max-width}) / 2) - 32.5rem - 9.8rem);
            }
        }
        @include media-breakpoint-down(sm){                   
            left: 0;
            transform: unset;
            margin-left: -1.5rem;        
        }
    }

    .ballon-top,
    .ballon-bottom {     
        height: 44.2rem;
        width: 44.2rem;    
    }
    .ballon-top{
        top: -12.1rem;
        left: 7.8rem;
    }

    .ballon-bottom{
        top: 45.5rem;
        right: -6rem;
    }

    .atugusto-img {
        width: calc(100vw - 39rem - #{$lateral-space} - 9.6rem);     

        @include media-breakpoint-up($special-container-max-width){
            width: calc(100vw - ((100vw - #{$special-container-max-width}) / 2) - 39rem - #{$lateral-space} - 9.6rem);
        }
        @include media-breakpoint-down(sm){
            width: calc(100vw - 2rem);
        }
    }

    #tipologias-de-sofa {
        .module-tab {
            margin-left: calc(-9.6rem - 2.5rem);
            width: calc(100% + 9.6rem + 2.5rem);
        }
    }

    @media(max-width: $screen-sm-max) {
        .flex-max-sm-column {
            flex-direction: column;
        }
        .order-first-xs {
            order: -1;
        }
        .select-gb-region-wrapper-xs {
            margin-left: 0 !important;
            margin-bottom: 15px !important;
        }
    }

    .form.form-contact-product{       
            padding-top: 2rem;
            width: calc(100% + 5rem);
            //padding-right: 31%;
            overflow: hidden;
            position: relative;

            max-width: 1280px;
            margin: 0 auto;

            .input-box{
                position: relative;        
                padding-right: 2.5rem !important;
                padding-bottom: 2.5rem !important;
            }
            %item-box {
                display: block;
                input, textarea, select{         
                    & +.label {        
                        position: absolute;    
                        top: 0;
                        font-size: 1.5rem !important;
                        left: 1.5rem !important;
                        font-family: 'Montserrat-Medium' !important;
                        color: #575252 !important;
                        background-color: #f3f6f7 !important;                      
                        padding: 0 1.1rem;
                        pointer-events: none;
                        transform: translateY(-50%) !important;    
                        transform-origin: left;
                        white-space: nowrap;
                        transition: none;
                        font-weight: normal !important;
                        

                    }
                    &:valid,  &:focus {            
                        & +.label {                   
                            transform: translateY(-50%);                  
                        }
                    }
                    &:focus {
                        border: 1px solid #575252; 
                    }     
            
                }   
            
                input, select, textarea {
                    -webkit-appearance: none;
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 4.5rem;
                    border-radius: 0 !important;
                    border: 1px solid #575252;	
                    color: #575252;
                    font-family: 'Montserrat-Medium';
                    font-size: 1.5rem;
                    outline: 0;
                    padding: 0 1rem;
                    transition: all 0.2s linear;
                    text-decoration: none;
                    background-color: inherit;
            
                    &.error {
                        border-color: $red !important;
                    }	      	
            
                }
                select {
                    line-height: normal;
                    font-size: 1.7rem;
                }
                textarea {
                    padding: 1rem;
                    min-width: 100%;
                    max-width: 100%;
                    min-height: 15rem;
                }
            }
        
        
            .input-box {
                @extend %item-box;
            }
            
            .privacy{
                /*
                position: relative;
                width: 100%;
                padding-left: 0;

                input[type="checkbox"]{
                    width: auto;
                    visibility: hidden;
                }
              
                .labelContainer{
                    top: 0;
                    width: 100%;
                    position: relative;
                    left: 0;
                    margin-left: 0;
                    display: flex;
                    padding-top: 1rem;
                    align-items: center;
            
                    .textContainer{
                        color: #575252;
                        font-size: 1.3rem;
                        width: calc(100% - 2.2rem);
                        font-family: 'Montserrat-Medium';
                        font-weight: normal;
            
                        .link{
                            text-decoration: underline;
                            color: $colorCorporative;
                            font-family: 'Montserrat-Medium';
                            font-weight: normal;
                        }
                    }
                }
           
                input.error +.labelContainer:before {
                    border: 0.1rem solid #e3342f;        
                }
             
                input+.labelContainer:before {
                    content: '';
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    min-width: 1.7rem;
                    height: 1.7rem;
                    top: 0;
                    border: 0.1rem solid $colorCorporative;
                    border-radius: 0 !important;
                    background-color: transparent;
                    transition: background-color 0.2s linear 0.1s;
                    
                }
                input+ .labelContainer:after {
                    content: '';
                    z-index: 1;
                    min-width: 1.1rem;
                    height: 0.6rem;
                    top: 50%;
                    left: 0.3rem;
                    border: 0.2rem solid $colorCorporative;
                    border-top: 0;
                    border-right: 0;
                    transform: rotate(-45deg) scale(0);
                    transform-origin: left top;
                    transition: all 0.2s ease 0s;
                }
                input[type="checkbox"]:checked + .labelContainer:before {
                    background-color: transparent;
                    transition: background-color 0.2s linear 0s;
                }
                input[type="checkbox"]:checked + .labelContainer:after {
                    transform: translateY(-20%) rotate(-45deg) scale(1);
                    transition: all 0.2s ease 0.1s;
                }
                */
                &.error{
                    input+.labelContainer:before {
                        border: 0.2rem solid $err !important;
                    }
                }
                .err{
                    width: 100%;
                    font-style: normal;
                    color: $err;
                }
            }
            .buttons{
                width: 100%;
                .wrap-btnDefault{
                    margin: 0 auto;
                    margin-right: 2.5rem;
                }
            }
            &::before{
                content: "";
                background-image: url(/images/Captura_detail.PNG);
                background-repeat: no-repeat;
                width: 29%;
                height: 100%;
               
                background-size: contain;
                background-position-x: right;
            }

            &.isSent{
                &::before{
                    content: none;
                }
            }
        }
  
        @media (max-width: $md-width) {
            .form.form-contact-product{
                display: block;
                width: 100%;  
                padding-right: 0;

                .input-box{
                    padding-right: 0 !important;
                }
                .buttons{
                    .wrap-btnDefault{
                        min-width: unset;
                        margin: 0 auto;
                        margin-right: 0;
    
                        .btnDefault{
                            font-size: 1.5rem;
                            line-height: 1.7rem;
                            padding: 1.5rem 4.3rem
                        }
                    }
                }
              
                .privacy{
                    margin-bottom: 2rem;
                    padding: 0;
                    width: 100%;

                    .labelContainer{
                        .textContainer{
                            font-size: 1.1rem;
                            line-height: 1.8rem;
                        }
                    }
                    /*
                    input+.labelContainer:before {				
                        top: .2rem;		
                    }
                    input+ .labelContainer:after {				
                        top: 1.9rem;			
                    }
                    */
                }
                &::before{
                    content: none;                  
                }
            }	
        }

}

.loves-num{
    font-size: large;
    font-style: bold;
}

.fullscreen-icon{
    size: 7.5rem;
}