.page-famalovers.container-content{
    .section-empresa.sofas.sofas-philosophy.section-two-columns-philosophy{
        margin: 0 auto;
        padding-top: 5rem;
        .text.generic-famalovers, .text.generic-famalovers *{
            font-size: 1.5rem !important;
            line-height: 2.1rem !important;
            font-family: "Montserrat-Regular" !important;
            letter-spacing: 0 !important;
            p{
                color: #000;
                padding-bottom: 2.5rem;
                &:last-of-type{
                    padding-bottom: 0;
                }
                &:empty{
                    display: none;
                }
            }
            br{
                display: none;
            }
            b{
                font-family: 'Montserrat-Bold' !important;
            }
            i{
                font-family: 'Montserrat-Italic' !important;
            }
        }
        
        .wrap-content{
            .title{                
                font-family: "Montserrat-SemiBold";
                font-size: 4.1rem;
                line-height: 5.5rem;
                letter-spacing: 0;
                color: #575252;
                padding-bottom: 0.7rem;
            }
            .subtitle{
                width: 100%;                
                font-family: "MONTSERRAT-MEDIUM";
                font-size: 1.7rem;
                color: $colorCorporative;
                text-transform: uppercase;
                line-height: 3rem;
                letter-spacing: 1.5px;
                flex-grow: 1;
                padding-bottom: 2.5rem;
            }
            .up {
                .part-up {
                    justify-content: space-between;
                    .part-right{     
                        position: relative;      
                        width: 45%;   
                        padding-top: 3.2rem;    
                        .wrap-img{
                            width: 100%;
                            &::before{
                                content: none;
                            }     
                                         
                        }
                    } 
                    .part-left{
                        position: relative;
                        width: 48%;
                        .wrap-img.mvl{
                            display: none;
                        }
                    }
                }
                .wrap-img{
                    position: relative;
                    padding-top: 66%;
                    margin-bottom: 4.2rem;
                    .img{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        top: 0;
                        left: 0;
                    }
                }
                .slider-famalovers{
                  
                    .item .wrap-img{
                        padding-top: unset;
                        margin-bottom: 0;
                        .img{
                            position: relative;
                        }
                    }
                    .slick-list{
                        padding: 0 !important;
                        cursor: grab;                                
                        &.dragging, &.draggable:active:hover {
                            cursor: grabbing;
                        }
                    }
                    .slick-arrow{
                        z-index: 1;
                    }
            
                    .slick-prev	{
                       
                        left: 1rem;
                        display: none;
                        width: 4rem;
                        height: 4rem;
                        opacity: 1;
                        
                        &:before{
                            content: '';
                            background-image: url('/images/left.svg');
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            top: 0;
                            left: 0;
                        }
            
                    }
                    .slick-next	{
                        
                        right: 1rem;
                        display: none;
                        width: 4rem;
                        height: 4rem;
                        opacity: 1;
            
                        &:before{
                            content: '';
                            background-image: url('/images/right.svg');
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            top: 0;
                            left: 0;
                        }
                    }
            
                }
                            
              
            }
        }
    }
    // PART OF LINE VERTICAL
    &.partDown{
        padding-top: 0;
        .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks{
            margin: 0 auto;
            .wrap-content{
                margin-bottom: 14rem;
            }
            .wrap-content>.wrap-title{
                width: 30%;
            }
            .title{
                text-align: left;
                font-family: 'Montserrat-Medium';
                font-size: 1.7rem;
                line-height: 2.3rem;
                color: $colorCorporative;
            }
            .wrap-content .wrap-list{
                .item.right{
                    &:first-of-type{
                        padding-top: 0;
                        .item-content{
                            padding-top: 0;
                            .wrap-img{
                                margin-top: -20%;
                            }
                            &::before{    
                                content: none;
                            }
                        }
                    } 
                    .item-content{
                        position: relative;
                        padding-top: 0;
                        .wrap-title{
                            width: 100%;
                            max-width: 27.6rem;

                            .title{
                                font-family: 'Montserrat-Bold';
                                font-size: 1.7rem;
                                line-height: 2.3rem;
                                margin-bottom: 1rem;
                                &::after{
                                    content: none;
                                }
                            }
                        }
                        .wrap-link-famalovers{
                            width: 100%;
                            max-width: 27.6rem;
                        }
                        &::before{    
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 2.9rem;
                            height: 2.9rem;
                            border-radius: 50%;
                            background-color: $colorCorporative;
                            transform: translateX(-50%);
                            z-index: 1;
                        }
                        .wrap-img{
                            width: 27.6rem;         
                            margin-bottom: 2.5rem;     
                            overflow: hidden;          
                            .img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                transition: .3s all ease-in;
                                transform: scale(1);
                            } 
                            &:hover{
                                .img{
                                    transition: .3s all ease-in;
                                    transform: scale(1.08);
                                }
                              
                            }
                        }

                    }
                }
          
                .item.left{                   
                    .item-content{
                        position: relative;
                        overflow: visible;
                        padding-top: 0;
                        padding-left: calc(50% - 27.6rem - 9.6rem);
                        .wrap-img{
                            width: 27.6rem;
                            margin-bottom: 2.5rem;
                            overflow: hidden;
                            .img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                transition: .3s all ease-in;
                                transform: scale(1);                           
                            }                         
                            &:hover{
                                .img{
                                    transition: .3s all ease-in;
                                    transform: scale(1.08);
                                }
                              
                            }
                        }
                        .wrap-title{
                            .title{                            
                                background-color: transparent;   
                                font-family: 'Montserrat-Bold';
                                font-size: 1.7rem;
                                line-height: 2.3rem;
                                margin-bottom: 1rem;  
                                padding-right: 0;                          
                            }
                        }
                        &::before{    
                            content: "";
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 2.9rem;
                            height: 2.9rem;
                            border-radius: 50%;
                            background-color: $colorCorporative;
                            transform: translateX(50%);
                            z-index: 1;
                        }
                    }
                }
                .item:last-of-type{
                    .item-content{
                        border: none;
                    }
                }
                &::after{
                    content: none;
                }
           
                .wrap-link-famalovers{                   
                    .link{
                        font-size: 1.7rem;
                        font-family: 'Montserrat-Regular';
                        color: #575252;
            
                        --border-color: #575252;
                        --border-width: 1px;
                        --bottom-distance: 0px; /* you can increase this */    
                        
                        display: inline;
                        background-image: linear-gradient(var(--border-color), var(--border-color));         
                        background-size: 100% var(--border-width);
                        background-repeat: no-repeat;
                        background-position-y: bottom;
                        transition: background-size 0.3s;                     
                    }    
                    &:hover{
                        .link{
                            background-size: 0% var(--border-width);
                        }  
                    }           
                }
            } 
        }
    }
}

//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {

    .page-famalovers.container-content{
        .section-empresa.sofas.sofas-philosophy.section-two-columns-philosophy{           
            padding-top: 0;
            
            .wrap-content{
                .title{ 
                    font-size: 3.5rem;
                    line-height: 4.5rem;                  
                    padding-bottom: 1rem;
                }
             
                .up {
                    margin-bottom: 3.5rem;
                    .part-up {
                        flex-direction: column;
                        justify-content: unset;
                        padding-bottom: 0;

                        .part-right{           
                            width: 100%;   
                            padding-top: 0; 
                            position: relative;   
                            .wrap-img{
                                width: 100%;
                                &::before{
                                    content: none;
                                }
                                &.desc{
                                    display: none;
                                }
                            }
                        } 
                        .part-left{
                            width: 100%;
                            .title{
                                padding-top: 0;
                            }
                            .wrap-img.mvl{
                                display: block;
                            }
                        }
                    }
                    .wrap-img{
                        position: relative;
                        padding-top: 66%;
                        margin-bottom: 4.2rem;
                        .img{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            top: 0;
                            left: 0;
                        }
                    }
                    .slider-famalovers{
                        margin-bottom: 2.5rem;
                        margin-top: 4rem;
                    }
                
                  
                }
            }
        }
        // PART OF LINE VERTICAL
        &.partDown{
            padding-top: 0;
            .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks{
                margin: 0 auto;
                .wrap-content{
                    margin-bottom: 6rem;
                }
                .wrap-content>.wrap-title{
                    width: 100%;
                    padding-bottom: 4rem;
                    .title{
                        width: 100%;
                    }
                }
           
               
                .wrap-content .wrap-list{
                    .item.right{
                        border-left: 1px solid #8fc3c9;
                        padding-right: 0;
                       
                        &:first-of-type{                           
                            .item-content{                                
                                .wrap-img{
                                    margin-top:0;
                                } 
                                                 
                            }
                        } 
                        .item-content{     
                            padding-right: 0;
                            padding-left: 4rem;                      
                            .wrap-title{
                                max-width: 100%;    
                                .title{                                  
                                    margin-bottom: 1rem;                                  
                                }
                            }
                            .wrap-link-famalovers{                              
                                max-width: 100%;
                            }
                            &::before{
                                content: none;
                            }
                            .wrap-img{
                                width: 100%;
                                max-width: 27.6rem;        
                                margin-bottom: 1.5rem;     
                                overflow: hidden;          
                                .img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    transition: .3s all ease-in;
                                    transform: scale(1);
                                } 
                                &:hover{
                                    .img{
                                        transition: .3s all ease-in;
                                        transform: scale(1.08);
                                    }
                                  
                                }
                            }
    
                        }
                        &::before{    
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 2.9rem;
                            height: 2.9rem;
                            border-radius: 50%;
                            background-color: $colorCorporative;
                            transform: translateX(-50%);
                            z-index: 1;
                        }
                    }
              
                    .item.left{   
                        border-left: 1px solid #8fc3c9;    

                        .item-content{                           
                            padding-right: 0;
                            padding-left: 4rem;
                            .wrap-img{
                                width: 100%;
                                max-width: 27.6rem;
                                margin-bottom: 1.5rem;
                                overflow: hidden;
                                .img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    transition: .3s all ease-in;
                                    transform: scale(1);                           
                                }                         
                                &:hover{
                                    .img{
                                        transition: .3s all ease-in;
                                        transform: scale(1.08);
                                    }
                                  
                                }
                            }
                            .wrap-title{
                                .title{                            
                                    background-color: transparent;   
                                    font-family: 'Montserrat-Bold';
                                    font-size: 1.7rem;
                                    line-height: 2.3rem;
                                    margin-bottom: 1rem;  
                                    padding-right: 0;                          
                                }
                            }
                            &::before{    
                                content: none;
                            }
                   
                        }
                        &::before{    
                            content: "";
                            position: absolute;
                            top: 0;
                            right: unset;
                            left: 0;
                            width: 2.9rem;
                            height: 2.9rem;
                            border-radius: 50%;
                            background-color: $colorCorporative;
                            transform: translateX(-50%);
                            z-index: 1;
                        }
                    }
                    .item{
                        display: block;
                        width: calc(100% - 1.5rem);
                        position: relative;
                        margin-left: 1.5rem;
                        margin-bottom: 0;
                        padding-bottom: 6.4rem;
                        .item-content{
                            padding-left: 3.5rem !important;
                            padding-right: 2rem !important;
                            width: auto;                            
                        }
                    }
                    .item:last-of-type{
                        border: none !important;                       
                    }                  
               
                    .wrap-link-famalovers{                   
                        .link{
                            font-size: 1.7rem;
                            font-family: 'Montserrat-Regular';
                            color: #575252;
                
                            --border-color: #575252;
                            --border-width: 1px;
                            --bottom-distance: 0px; /* you can increase this */    
                            
                            display: inline;
                            background-image: linear-gradient(var(--border-color), var(--border-color));         
                            background-size: 100% var(--border-width);
                            background-repeat: no-repeat;
                            background-position-y: bottom;
                            transition: background-size 0.3s;                     
                        }    
                        &:hover{
                            .link{
                                background-size: 0% var(--border-width);
                            }  
                        }           
                    }
                } 
            }
        }
    }

}