@media (max-width: 1024px) and (min-width:768px) {
	.text-box-gray {
		right:7px;
	}
}

.container.home-items{
	margin-bottom: 0;
	padding-top: 0;
	background-image: linear-gradient(#ffffff 10%, #effbff);

	.section-menu-wrap{
		//padding: 14.9rem 5rem;
		padding: 13.9rem 5rem;

		.section-menu{
			display: flex;
			justify-content: space-between;
			position: relative;
		}
	}
	@media (max-width: $screen-sm-max){
		padding-left: 0;
		padding-right: 0;
	}


}


.home{
	.block{
		margin-left: 12px;
		margin-right: 12px;
		.photo:not(.photo-natural){
			img{
				transform: none;
			}
			&:hover img{
				transform: none;
			}
		}
	}
}

.video-widget{
	position: relative;
	height: 12vw;

	@media screen AND (max-width: $screen-sm-max){
		height: 56vw!important;
	}

	.video{

		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.grid-a-item.col-md-6{
	.video-widget{
		height: 26vw;
	}
}

.grid-a-item.col-md-9{
	.video-widget{
		height: 40vw;
	}
}

.grid-a-item.col-md-12{
	.video-widget{
		height: 54vw;
	}
}

 .video-vimeo {
    width: 100%;
    height: 0;
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    padding-top: 56vw;   
    overflow: hidden;
    margin-left: 0;
    @media screen AND (max-width: $screen-sm-max){
    	height: 100%;
    	width: auto;
    }
}

.video-vimeo iframe{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}


.paddingImgSmall {
	padding: 0px 5px 0px 5px;
}
/********/

/*MENU*/

.listpadding {
	width: 29.4%;
	max-width: 37.6rem;
	background: transparent;
	padding: 3rem 0;
	border-top: 2px solid $backgroundBody;
	border-bottom: 2px solid $backgroundBody;
}

.list-title {
	color: white;
	font-size:23px;
	font-family:'Arvo';
	margin-top:0px;
	padding:15px 0px 0px 20px;
	font-weight:bold;
	text-transform: uppercase;
}

.menu-list {
	list-style: none;
	padding-left: 0px;
}

.list-item {
	color: $gray;
	font-size: 2.5rem;
	opacity: .8;
	font-family: 'Montserrat-SemiBold';
    line-height: 120%;
    height: auto;
    padding: 13px 26px;
	transition: .2s all easy;
	&:hover{
		opacity: 1;
		transition: .2s opacity easy;
	}

	.linkMov{
		display: none;
	}

}

.list-text {
	padding-left: 10px;
	padding-top: 7px;
	margin-bottom: 0px;
}

.listpadding .list-item{
	color:$gray;
	transition: .3s all ease;
    p {
        color:$gray;
        transition: .3s all ease;
    }
	.home-list-text{
        cursor: pointer;
		position: relative;
	}
	&.isActive{
		color: $colorCorporative;
		transition: .3s all ease;
        p {
            color: $colorCorporative;
            transition: .3s all ease;
        }
		.notLinkDesk .home-list-text::before{
			content: "";
			width: 1rem;
			height: 1rem;
			border-radius: 50%;
			background-color: $colorCorporative;
			top: 50%;
			transform: translateY(-50%) translateX(-20px);
			position: absolute;
			transition: .3s all ease;
		}
	}
	.notLinkDesk .home-list-text::before{
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		background-color: transparent;
		top: 50%;
		transform: translateY(-50%) translateX(-20px);
		position: absolute;
		transition: .3s all ease;
	}
}


.listpadding p{
	margin: 0;
}
/******/

@media only screen and (max-width: $md-width){
	.container.home-items .section-menu-wrap{
		padding: 0 3.5rem;

		.listpadding {
			width: 100%;
			max-width: 100%;
			background-color: transparent;
			padding: 7.2rem 0;
			border-top: none;
			border-bottom: none;
			.notLinkDesk{
				display: none;
			}

			.linkMov{
				display: block;
				text-align: center;
			}
		}
		.listpadding a {
			color:#575252;
			text-align: center;
			.home-list-text{
				position: relative;
				font-size: 2.5rem;

			}
			&:active{
				color: $colorCorporative;
			}	
		}
	}


}


/*Concursos*/
.item-concursos
{
	@media (max-width: $screen-xs-max){
		margin: 0;
	}
}
.item-concursos-title {
	font-size: 25px;
	font-family: $fontStack1;
	color: white;
	text-align: center;
	margin-top: 0px;
	margin-bottom: 0px;
	text-transform: uppercase;
	font-weight:bold;
}

.item-concursos-subtitle
{
	font-size: 23px;
	font-family: 'Arvo';
	color: white;
	padding-left: 10px;
	text-align:left;
	font-wegiht: bold;

	@media (min-width: 768px) AND (max-width: 1280px)
	{
		font-size: 20px;
	}

	@media screen AND (max-width: $screen-sm-max)
	{
		text-align: center;
		padding: 0 30px;
	}

}

@media screen AND (max-width: $screen-sm-max)
{
	.item-concursos
	{
		text-align: center;
	}
}

.item-concursos .desc {
	font-size: 18px;
	font-family: 'Roboto';
	color: white;
	padding-left: 10px;
	text-align:left;

	@media (min-width: 768px) AND (max-width: 1280px)
	{
		font-size: 16px;
	}
}

.item-concursos-img {
	margin: 0 auto;
	padding: 85px 85px 35px 85px;
}

.item-concursos--orange {
	text-align:center;
}

.item-concursos-orange-img {
    padding: 35px 55px 35px 55px;
}

.item-concursos .down {
	padding-left: 10px;
	color:white;
	text-align: left;
}
/******/

/*News*/
.item-news-title {
	font-size: 23px;
	font-family: 'Arvo';
	padding: 5px 10px;
	font-weight:bold;
	margin-bottom: 5px;
	line-height: 130%;
}

.item-news-subtitle {
	font-size: 18px;
	font-family: $fontStack1;
	color: #84c652;
	padding: 5px 10px;
	margin-bottom: 40px;
	margin-top:0px;
	padding-bottom: 25px;
	line-height: 130%;
}
/******/

/*Twitter*/
@media screen AND (min-width: $screen-md-min){
	.tw_block > .tweets
	{

		max-height: 500px;
		overflow: auto;
	}
}
.tw_block > .title {
	text-align: center;
	color:white;
	background: #333333;
	margin: 0px;
	padding: 20px 0px 10px 0px;
	position: relative;

	a{
		color:white;
	}

	&:after
	{
	    content: "";
	    position: absolute;
	    bottom: -19px;
	    left: 0;
	    right: 0;
	    height: 20px;
	    background: url(img/efecto-twiter.png) repeat-x;
	}
}

.tw {
	font-size: 14px;
	font-family: 'Roboto';
    padding: 20px 30px 15px 30px;
    word-wrap: break-word;
}

.tw .title {
	font-weight:bold;
	margin-bottom:0px;
}

.tw .user{
	color: #00b0b3;
	margin-bottom:0px;
	font-size: 15px;
}

.tw .hash {
	color: #ff8e7d;
    //margin-bottom: 5px;
    margin-bottom: 0;

}

.tw .date {
	font-size: 12px;
	color: #999999;
}
/******/

/*Blog*/
.item-blog {
	font-family: 'Roboto';
}
.item-blog-title {
	font-family: 'Arvo';
	font-size: 23px;
	padding-left: 15px;
	font-weight:bold;
}

.icon-archive {
	float: right;
	padding-right: 15px;
	color: #AAAAAA;
}

.blog-post {
	padding: 0px 15px 0px 15px;
	margin: 10px 10px 10px 10px;
	border: 1px solid #CCCCCC;
	position: relative;

	@media screen AND (min-width: $screen-sm-min) AND (max-width: $screen-sm-max)
	{
		padding-top: 10px;
		padding-bottom: 10px;
	}

	@media screen AND (min-width: $screen-md-min) AND (max-width: 1399px)
	{
		text-align: center;
	}
}

.blog-post h1,
.blog-post .blog-post-title {
	margin-top: 25px;
	font-size: 16px;
	color: #a47a6c;
    font-weight: bold;
    padding: 0px 15px;
    margin-bottom: 0px;
    line-height: 1.3;

    @media screen AND (min-width: $screen-sm-min) AND (max-width: $screen-sm-max)
    {
    	margin-bottom: 10px;
    	margint-top: 10px;
    }
}

.blog-post .photo-valign
{
	@media screen AND (min-width: $screen-md-min)
	{
		margin-bottom: 10px;
	}

}

.blog-post p {
	font-size: 14px;
	margin-bottom: 25px;
    font-weight: bold;
    padding: 0px 15px;
}

.blog-post span {
	font-size: 14px;
	color:#CCCCCC;
}

.blog-post .more {
	position: absolute;
	top: 4px;
	right: 3px;
	background: #84B851;
	padding: 1px 7px;
	border-radius: 3px;
	color: white;
	font-weight: bold;
	border: none;
}
.item-blog,
.item-eventos
{
	padding-bottom: 65px;
}

.item-blog .pag,
.item-eventos .pag{
	//display: flex;
	padding-top: 15px;
	//height: 40px;
	overflow: hidden;
	position: absolute;
    width: 100%;
    bottom: 0;
    padding: 0 10px 13px;
    left: 0;
    right: 0;
}

.item-blog .pag .next,
.item-eventos .pag .next {
    float: right;
    padding: 10px;
    background-color: $colorPrimary;

    border-radius: 4px 0 0 4px;
    vertical-align: middle;
    display: flex;
    color: white;
    position: relative;
    margin-right: 30px;

    &:after
    {
	    position: absolute;
		right: -19px;
		left: auto;
		top: 0;
		width: 0;
		height: 0;
		border-top: 19px solid transparent;
		border-bottom: 20px solid transparent;
		border-left: 20px solid $colorPrimary;

		.famaliving &
		{
			border-left-color: $colorCorporative2;
		}
		content: "";
    }
    &:hover
    {
    	opacity: 0.9;
    }
}

.item-blog .pag .previous,
.item-eventos .pag .previous
 {
	padding-left: 15px;
	float: left;
    padding: 10px;
    border-radius: 0 4px 4px 0;
    vertical-align: middle;
    display:  flex;
    position:relative;
    margin-left: 30px;
    background-color: $colorPrimary;

    color: $colorWhite;

    &:after
    {
	    position: absolute;
		left: -19px;
		right: auto;
		top: 0;
		width: 0;
		height: 0;
		border-top: 19px solid transparent;
		border-bottom: 20px solid transparent;
		border-right: 20px solid $colorPrimary;

		.famaliving &
		{
			border-right-color: $colorCorporative2;
		}
		content: "";
    }

    &:hover
    {
    	opacity: 0.9;
    }
}

.item-blog .pag .next,
.item-eventos .pag .next {
    float: right;
    padding: 10px;
    background-color: $colorPrimary;

    border-radius: 4px 0 0 4px;
    vertical-align: middle;
    display: flex;
    color: white;
    position: relative;
    margin-right: 30px;

    &:after
    {
	    position: absolute;
		right: -19px;
		left: auto;
		top: 0;
		width: 0;
		height: 0;
		border-top: 19px solid transparent;
		border-bottom: 20px solid transparent;
		border-left: 20px solid $colorPrimary;

		.famaliving &
		{
			border-left-color: $colorCorporative2;
		}
		content: "";
    }
}

/** Estilo originariamente para movil, ahora para pc tambien **/

.item-blog-title
{
	background-color: $colorPrimary;	
	color: $colorWhite;
	padding-top: 18px;
	height: auto;
	overflow: hidden;
	position: relative;
	margin-bottom: 38px;
	margin-top: 0;

	.title-wrap
	{
		float: left;
		padding-bottom: 13px;
	}

	.title img
	{
		height: 35px;
	}

	.subtitle
	{
		font-size: 18px;
		font-weight: lighter;
		font-family: $fontStack1;
		text-align: right;
	}

	.title-icon
	{
		font-size: 70px;
		color: $colorCorporative;		
		position: absolute;
		top: -36px;
		right: -16px;
		border-radius: 100%;
		background-color: #008493;
		background-color: rgba(0, 0, 0, 0.2);
		padding: 40px;
	}
}

.blog-post
{
	overflow: hidden;
	margin: 0;
	padding: 0 10px;

	@media screen AND (min-width: 1400px)
	{
		padding-bottom: 30px;
	}

	.photo-valign
	{

		height: 87px;
		width: 87px;
		display: block;
		margin-left: auto;
		margin-right: auto;

		@media screen AND (min-width: 1400px)
		{
			//float: left;
			margin-right: 15px;
			margin-left: 0;
			display: inline-block;
			vertical-align: middle;
		}

		img
		{
			filter: grayscale(100%);
			height: 87px;
		}
	}

	h1,
	.blog-post-title
	{
		margin-top: 11px;
		margin-bottom: 15px;
		font-size: 17px;		
		position: relative;
		overflow: hidden;
		font-family: $fontStack2;
		color: #333;
		padding-left: 0;

		@media screen AND (min-width: $screen-sm-min)
		{
			padding: 0;
		}

		@media screen AND (min-width: $screen-md-min) AND (max-width: 1399px)
		{
			margin-bottom: 10px;
		}

		@media screen AND (min-width: 1400px)
		{
			text-align: left;
			margin-top: 0;
			vertical-align: middle;
			display: inline-block;
			width: 60%;
			width: calc(90% - 100px);
		}


		&:after
		{
			@media screen AND (min-width: $screen-sm-min){
				display: none;
			}
			content: "";
			position: absolute;
			width: 35px;
			height: 20px;
			top: 0;
			right: 0;
			background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 25%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.75) 25%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.75) 25%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */

		}
	}

	@media screen AND (min-width: 1400px)
	{
		p
		{
			text-align: left;
			clear: both;
			padding-left: 0;

			span
			{
				margin-right: 5px;
			}
		}
	}

	.name-author
	{
		float: left;
		font-size: 17px;

		@media screen AND (min-width: 1400px)
		{
			text-align: left;
		}

		span
		{
			display: block;
			color: $colorCorporative;
			.famaliving &
			{
				color: $colorCorporative2;
			}
		}
	}
}

.blog-post .photo-author
{
	width: 87px;
	height: 87px;
	border-radius: 100%;
	overflow: hidden;
	float: left;
	display: block;

	img
	{
		height: 87px;
		width: auto;
	    margin-left: -5px;
		filter: grayscale(1);
	}

	&:hover
	{
		filter: none;
	}
}


.item-blog
{
	padding-left: 0;
	padding-right: 0;
	//padding-bottom: 20px;
	padding-top: 0;
}

.blog-post
{
	border: none;
}
/******/

/*Carousel Sofas*/

.myCarousel {
	//background: #009AB7;
	background: $colorPrimary;
	//@extend %famalivingBackgroundColor;
	min-height: 332px;
	position: relative;
	height: 100%;
}

.myCarousel  .item
{
	overflow: hidden;
}
.carousel .item
{
	overflow: hidden;
}

.myCarousel .cartitle {
	font-family: 'Arvo';
	font-size: 35px;
	color: white;
	margin-top: 5px;
}

.myCarousel .carsubtitle {
	font-family: 'Roboto';
	font-size: 18px;
	margin-top: 5px;
}

.myCarousel .cardesc {
	font-family: $fontStack1;
	font-size: 14px;
	color: white;
	padding-bottom: 25px;
}

.myCarousel .car-img img
{
	@include object-fit(cover);
}

.myCarousel .carousel-controls
{
	position: absolute;
	bottom: 15px;
	width: 100px;
	left: 40px;
	font-size: 30px;
	height: 30px;
	.carousel-control
	{
		height: 25px;
		width: 25px;
		border-radius: 100%;
		background-color: #333;
		text-align: center;
		color: $colorWhite;
		opacity: 0.5;
		line-height: 25px;

		&:hover{
			background-color: #DEDEDE;
		}

		&.left
		{
		}
		&.right
		{
			left: 30px;
		}
	}
}

.car-text {
    padding: 10px 12px 0px 12px;
}

.car-img {
    padding: 4px 3px 0px 0px;
    img
    {
    	width: 100%;
    }

}
/*******/

/*Events*/
.home-events-title {
	font-family: 'Arvo';
	font-size: 23px;
    padding: 1px 0px 10px 10px;
	margin-top: 5px;
	float: left;
	margin-bottom: 0px;
	font-weight:bold;
}

.home-events-subtitle {
	float: right;
	font-family: 'Roboto';
	font-size: 16px;
	color: #AAAAAA;
	margin-top: 10px;
	padding-right: 15px;
	margin-bottom: 0px;
}

.home-events-date {
    float: right;
    font-size: 16px;
    color: #AAAAAA;
    padding-right: 15px;
    margin-top: 10px;

}

.home-event {
	position: relative;
}


.home-event .ev_desc {
	font-family: $fontStack1;
	font-size: 14px;
	padding: 15px 10px 5px 10px;
	margin-bottom: 0px;
}

.home-event .ev_ubic, .home-event .ev_date {
	font-family: 'Roboto';
	font-size: 14px;
	margin-bottom: 0px;
	padding: 5px 0px 15px 35px;
}

.home-event .join
{
	position: absolute;
	bottom: 15px;
	right: 5px;
	background: #84B851;
	padding: 1px 7px;
	border-radius: 3px;
	color: white;
	font-weight: bold;
	border: none;
}
/*******/

/*Carousel Productos*/
.secCarousel {
	background: white;
}

.secCarousel .cartitle {
	font-family: 'Arvo';
	font-size: 35px;
	color: black;
	padding-top: 10px;
}

.secCarousel .carsubtitle {
	font-family: $fontStack1;
	font-size: 18px;
	color: #84C552;
}

.secCarousel .cardesc {
	font-family: $fontStack1;
	font-size: 14px;
	color: black;

	@media (max-width: $screen-md-max)
	{
		padding-right: 50px;
	}
}

.secCarousel .carousel-indicators {
	left: 81%;
	bottom: 70px;
	width: 44px;
	margin:0px;
}

.secCarousel .carousel-indicators li {
	background-color: #DFDFDF;
}

.secCarousel .carousel-indicators .active {
    background-color: white;
    border: 2px solid #F08960;
}

.secCarousel .carousel-controls {
	position: absolute;
	bottom: 96px;
	width: 133px;	
	left: 81%;
	right: auto;
	margin-left: -45px;
}

.secCarousel .carousel-controls i {
	color: black;
}

.secCarousel .divdown {
	position: absolute;
	top: 450px;
	right: 0px;
}

.secCarousel .down .down-wrapper
{
	padding: 10px 15px;
	.likes-text
	{
		top: 5px;
	}


}


/************/


/*******/
@media (max-width: $screen-xs-max)
{
	#myCarousel
	{
		min-height: 0;
		padding-bottom: 20px;
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.item-concursos-subtitle
	{
		padding-right: 45px;
	}
	.listpadding,
	.famaliving .listpadding
	{
		background-color: #EEE;

		body.famaliving &
		{
			background-color: #EEE!important;

		}
		.list-title
		{
			color: #333;
			background-color: $colorWhite;
			font-family: $fontStack1;
			font-weight: 500;
			margin: 0;
			padding-bottom: 10px;
		}

		a
		{
			color: #333;
			font-size: 20px;
		}

		.dashedline--white
		{
			border-color: $colorCorporative;

			.famaliving &
			{
				border-color: $colorCorporative2;
			}
		}
	}

	.xs-special .item-news-title
	{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding-left: 22px;
		padding-right: 62px;
		background-color: rgba($colorWhite, 0.85);
		padding-top: 13px;
		padding-bottom: 13px;
		margin-bottom: 0;
	}

	.item-news-subtitle
	{
		color: $colorCorporative;

		.famaliving &
		{
			color: $colorCorporative2;
		}
		font-size: 22px;
		margin-bottom: 0;

	}

	.item-news-title
	{
		font-size: 25px;
	}

	.xs-normal .down
	{
		position: absolute;	
		top: auto;
		bottom: 0;
	}


	.block.big
	{
		.title
		{
			font-size: 25px;
		}

		.subtitle
		{
			color: $colorPrimary;

			.famaliving &
			{
				color: $colorCorporative2;
			}

		}

		&.big .down
		{
			top: 217px;
		}

		.text
		{			
			padding-bottom: 10px;
			position: absolute;
			top: auto;
			bottom: 0;
			right: 0;
			left: 0;
			padding-left: 22px;
			padding-right: 22px;
			background-color: rgba($colorWhite,0.6);
			min-height: 0;
			max-width: 100%;
		}
	}


	.home
	{
		.block,
		.block.big
		{
			border: 3px double #DCDCDC;
			border-top: none;

			height: auto;
			margin-bottom: 0;

			&.item-concursos
			{				
				margin: 0;
			}
		}

		.blog-post {
			.photo-valign {
				float: none;
				display: inline-block;
				vertical-align: middle;
			}
			.blog-post-title {
				white-space: normal;
				width: calc(100% - 107px);
				margin-top: 0px;
				padding-right: 15px;
				display: inline-block;
				vertical-align: middle;
			}
			.link-authors {
				display: inline-block;
				p {
					padding-left: 0px;
				}
			}
			.item-concursos-subtitle {
				margin-top: 0px;
			}
		}

	}



	.item-concursos-title
	{
		font-size: 25px;
		font-family: $fontStack2;
		margin-bottom: 32px;
	}

	.tw_block {
		& > .title
		{
			text-align: left;
			background-color: $colorCorporative;

			.famaliving &
			{
				background-color: $colorCorporative2;
			}
			overflow: hidden;
			font-size: 36px;
			padding-left: 18px;		
			font-weight: bold;
		}
		.title-icon
		{
			font-size: 76px;
			color: $colorCorporative;
			.famaliving &
			{
				color: $colorCorporative2;
			}
			position: absolute;
			top: -30px;
			right: -16px;
			border-radius: 100%;
			background-color: #008493;
			background-color: rgba(0, 0, 0, 0.2);
			padding: 30px;
		}
	}
	.tw
	{
		padding-left: 18px;

		.user
		{
			font-size: 20px;
			margin-bottom: 16px;

			.icon
			{
				color: $colorWhite;
				background-color: #999;
				border-radius: 100%;
				padding: 5px;
				font-size: 17px;
				text-align: center;
				margin-right: 10px;
			}
		}
		.title
		{
			font-size: 17px;
			line-height: 118%;
			margin-bottom: 10px;
		}
		.hash
		{
			font-size: 17px;
			line-height: 145%;
			margin-bottom: 0;
		}
		.date
		{
			font-size: 17px;
			line-height: 120%;
		}
	}

	.item-blog-title
	{
		background-color: $colorPrimary;

		.famaliving &
		{
			background-color: $colorCorporative2;
		}

		color: $colorWhite;
		padding-top: 18px;
		height: auto;
		overflow: hidden;
		position: relative;
		margin-bottom: 38px;

		.title-wrap
		{
			float: left;
			padding-bottom: 13px;
		}

		.title img
		{
			height: 35px;
		}

		.subtitle
		{
			font-size: 18px;
			font-weight: lighter;
			font-family: $fontStack1;
			text-align: right;
		}

		.title-icon
		{
			font-size: 70px;
			color: $colorCorporative;

			.famaliving &
			{
				color: $colorCorporative2;
			}

			position: absolute;
			top: -36px;
			right: -16px;
			border-radius: 100%;
			background-color: #008493;
			background-color: rgba(0, 0, 0, 0.2);
			padding: 40px;
		}
	}

	.blog-post
	{
		overflow: hidden;
		margin: 0;
		padding: 0 10px;

		.photo-valign
		{
			float: left;
			height: 87px;
			width: 87px;
			margin-right: 15px;

			img
			{
				filter: grayscale(100%);
				height: 87px;
			}
		}

		h1,
		.blog-post-title
		{
			margin-top: 11px;
			margin-bottom: 15px;
			font-size: 17px;
			white-space: nowrap;
			position: relative;
			overflow: hidden;
			font-family: $fontStack2;
			color: #333;
			padding-left: 0;


			&:after
			{
				content: "";
				position: absolute;
				width: 35px;
				height: 20px;
				top: 0;
				right: 0;
				background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 25%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.75) 25%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.75) 25%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */

			}
		}

		.name-author
		{
			float: left;
			font-size: 17px;
			span
			{
				display: block;
				color: $colorCorporative;
				.famaliving &
				{
					color: $colorCorporative2;
				}
			}
		}
	}

	.blog-post .photo-author
	{
		width: 87px;
		height: 87px;
		border-radius: 100%;
		overflow: hidden;
		float: left;
		display: block;

		img
		{
			height: 87px;
			width: auto;
		    margin-left: -5px;
			filter: grayscale(1);
		}

		&:hover
		{
			filter: none;
		}
	}


	.item-blog
	{
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 20px;
		padding-top: 0;
	}

	.blog-post
	{
		border: none;
	}
}

@media (max-width: $screen-sm-max)
{
	.secCarousel
	{
		.carousel-indicators
		{
			left: 25%;
			bottom: 5px;
			width: 50%;
			margin: 0px;
		}
		.carousel-controls
		{
			bottom: 10px;
			width: 100%;
			left: 0%;
			height: 25px;
			margin-left: 0px;
		}
		.carousel-indicators li {
			padding: 8px;
			margin-left: 5px;
			margin-right: 5px;
		}
	}
	.myCarousel {
		.carousel-controls {
			bottom: -2px;
			width: 100%;
			left: 43%;
		}
	}
}

@media (min-width: $screen-md-min) AND (max-width: $screen-md-max)
{
	.secCarousel .cardesc
	{
		padding-right: 50px;
	}

	.item-blog .pag .next,
	.item-eventos .pag .next
	{
		margin-right: 15px;
		padding-left: 5px;
		padding-right: 5px;
	}
	.item-blog .pag .previous,
	.item-eventos .pag .previous
	{
		margin-left: 15px;
		padding-left: 5px;
		padding-right: 5px;
	}

	.down
	{
		width: 100%;
	}

	.home .likes-text
	{
		float: right;
	}
}

.block.block-text
{
	&.has-photo
	{
		min-height: 300px;
	}
	@media screen AND (min-width: $screen-lg-min)
	{
		& .photo.col-lg-6,
		&.resized .photo.col-lg-6
		{
			position: absolute;
			top: 0;
			bottom: 0;
			max-height: none;
			height: auto;

			&.pright
			{
				right: 0;
			}

			img
			{
				height: 100%;
				min-height: 0;
				max-height: none;
			}
		}
	}
}
.container.slider-home{
	@media screen AND (orientation: portrait) AND (max-width: $screen-sm-max){
		&.hasvideo{
			height: auto;
		}
	}

	.listpadding{
		position: absolute;
		bottom: 5vh;
		right: 100px;
		z-index: 1;
	}
}
.slider-home .volume{
	position: absolute;
	top: 50px;
	left: 50px;
	color: $colorWhite;
	text-shadow: 0 0 3px $colorBlack, 0 0 3px transparent, 0 0 3px transparent;
	font-size: 30px;
	z-index: 1;
	cursor: pointer;

	&:hover
	{
		text-shadow: 0 0 3px $colorBlack, 0 0 30px $colorWhite, 0 0 60px $colorWhite;
	}

	& .volume-icon.muted
	{
		display: none;
	}
	& .volume-icon.actived
	{
		display: block;
	}
	&.muted .volume-icon.muted
	{
		display: block;
	}
	&.muted .volume-icon.actived
	{
		display: none;
	}


}
@media (max-width: $screen-sm-max){
	.block-text	{
		.item-news-title{
			position: static;
		}
		.desc{
			padding-left: 22px;
			padding-right: 22px;
		}
	}

	// slider principal home
	.container.slider-home	{
		padding: 0;
		margin: 0;
		height: 100vh;
		overflow: hidden;
		position: relative;
        margin-top: 0;
		margin-bottom: 0;		

		.aux-item{
			.wrap-img{    
				height: 100%;
				width: 100%;
				position: relative;
			}
		}

		.slick-list{
			height: 100%;
			.slick-track{
				height: 100%;
			}
		}
		.slick-slide{
			position: relative;
			height: 100%;
			overflow: hidden;
			width: 100vw;
			.aux-text-item.mvl{
				z-index: 2;
				display: flex;
				flex-direction: column;
			}
			.wrap-color{
				display: none;
			}
			.section-title{
				font-size: 1.5rem;
				line-height: 2.2rem;
				letter-spacing: 5px;
				color: #fff;
				padding-bottom: 1rem;
				text-align: left;

			}
			.pasador-title{
				color: $colorWhite;
				font-weight: normal;
				padding-top: 1rem;
				padding-bottom: 1rem;
				border-bottom: 1px solid #fff;
				border-top: 1px solid #fff;
				font-family: 'Montserrat-ExtraBold';
				font-size: 6rem;
			}
			.pasador-text{
				font-size: 6rem;
				width: 67.5%;
				max-width: 25.3rem;
				color: #FFF;
				font-weight: normal;
				font-size: 18px;
				position: absolute;
				bottom: 11.5rem;
				left: 3.4rem;
				top: unset;
				background-color: transparent;
				padding: 0;

			}

            .slider-image,
			video{
				width: 100%;
				height: 100%;
				position: relative;
				@include object-fit(cover);
				filter: brightness(80%);
			}

		}
		.slick-prev,
		.slick-next{
			background-image: none!important;
			width: auto;
			height: auto;
			z-index: 1;

			.icon{
				font-size: 40px;
				color: $colorWhite;
				-webkit-transition: 300ms color ease-in;
				transition: 300ms color ease-in;
				text-shadow: 0px 0px 10px rgba(0,0,0,0.7);
			}

			&:hover{
				color: $colorPrimary;

				.famaliving &{
					color: $colorCorporative2;
				}
			}
		}

		.slick-prev{
			left: 5px;
		}
		.slick-next{
			right: 5px;
		}

	}
}
@media (min-width: $screen-md-min) {
	.newimg	{
		height: 100%!important;
	}

	.container.home-items{
		padding-top: 0;
	}
	.container.slider-home	{
		padding: 0;
		margin: 0;	
		height: 100vh;
		overflow: hidden;
		position: relative;
	    margin-top: 0;

		.slick-list{
			height: 100%;
			.slick-track{
				height: 100%;
			}
		}
		.slick-slide{
			position: relative;
			height: 100%;
			overflow: hidden;			
			.aux-text-item.mvl{
				display: none;
				z-index: 2;

			}
			.wrap-color{				
				min-width: 21vw;
				width: 21%;
				max-width: 21%;

				&::before{
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					background-color: inherit;
				}

			}
			.section-title{
				letter-spacing: .5rem;
				color: #fff;
				font-family: 'Montserrat-Regular';
				line-height: 2.2;
				font-size: 1.5rem;
				text-align: left;
				text-transform: uppercase;
				margin: 0;
			}
			.pasador-title{
				color: $colorWhite;
				padding-top: 2rem;
				padding-bottom: 2rem;
				border-bottom: 1px solid #fff;
				border-top: 1px solid #fff;
				font-family: 'Montserrat-ExtraBold';				
				font-size: 7.5vw;
			}
			.aux-text-item{
				top: 40%;
				width: 40%;
				position: absolute;
				margin: 0 auto;			
				left: 8%;
				z-index: 3;
				@media (min-width: $sm-width) AND (max-width: 1400px){
					padding: 0 5rem;
					.pasador-text{
						.pasador-title{
							font-size: 8vw;
						}
					}
				}

			}
			.pasador-text{
				color: $colorWhite;
				font-family: $fontStack1;
				font-weight: normal;
				position: relative;				
				background-color: transparent;
				padding: 0;
				padding-left: 0;
			}

			.aux-item{
				width: 100%;
				display: flex;
				position: relative;
				justify-content: flex-end;

				.wrap-img{    
					height: 100%;
					width: 100%;
					position: relative;
				}
			}


			.slider-image,
			video{
				width: 100%;
				height: 100%;
				position: relative;
				object-fit: contain;
				@include object-fit(cover);
				filter: brightness(80%);
			}
			.hotspot-marker.box-hotspot{
				z-index: 10;
				cursor: pointer;
				&:hover{
					.hotspot-marker-img + .wrap-hotspot-info{
						display: block;
						opacity: 1;
						visibility: visible;
						transition: .5s .2s opacity ease;

					}
					.hotspot-marker-img{

						opacity: 0;
						transition: .2s opacity ease;

						&.isHover{
							opacity: 1;
							transition: .2s opacity ease;
						}

					}

				}

				.hotspot-marker-img{

					&.isHover{
						opacity: 0;
						transition: .2s opacity ease;
					}
				}
				.hotspot-marker-img + .wrap-hotspot-info{				
					display: none;
					visibility: hidden;
					opacity: 0;
					transition: .5s .2s opacity ease;

					.hotspot-content{
						display: flex;
    					flex-direction: column;
						padding: 1.4rem;
						width: 180px;
						height: auto;

						.title{
							font-size: 1.5rem;
							letter-spacing: 1.3px;
							color: inherit;
							font-family: 'Montserrat-Regular';
						}
						.text{
							color: inherit;
							padding-bottom: 2.7rem;
							font-family: 'Montserrat-Bold';
							text-transform: uppercase;
						}
						.link{
							color: inherit;
							width: 100%;
							text-align: end;
							font-size: 1.1rem;
							position: relative;
							padding-right: 2rem;

							&::after{
								content: '';
								background-image: url('/images/arrow-white-right.svg');
								background-repeat: no-repeat;
								width: 2rem;
								height: 100%;
								position: absolute;
								right: -.5rem;
								top: 0.1rem;

							}

						}
					}
					.aux-wrap{
						position: relative;
						height: 100%;
						width: 100%;
						color: #fff;
						background-color: rgba(255, 255, 255, 0.1);
						backdrop-filter: blur(5px);
					}
				}
			}

		}
		.swiper-button-prev,
		.swiper-button-next	{
			background-image: none!important;
			width: auto;
			height: auto;

			.icon{
				font-size: 40px;
				color: $colorWhite;
				-webkit-transition: 300ms color ease-in;
				transition: 300ms color ease-in;
			}

			&:hover	{
				color: $colorPrimary;
			}
		}

		.slick-arrow{
			z-index: 1;
		}

		.slick-prev	{
			transform: translateX(5rem);
    		left: 0;
			display: none;
			width: 4rem;
			height: 4rem;

			&:before{
				content: '';
				background-image: url('/images/left.svg');
				width: 100%;
				height: 100%;
				position: absolute;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				top: 0;
				left: 0;
			}

		}
		.slick-next	{
			transform: translateX(-5rem);
    		right: 0;
			display: none;
			width: 4rem;
			height: 4rem;

			&:before{
				content: '';
				background-image: url('/images/right.svg');
				width: 100%;
				height: 100%;
				position: absolute;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				top: 0;
				left: 0;
			}
		}
		&:hover{

			.slick-prev, .slick-next{
				display: block;
			}

		}
		&:hover
		{
			.slick-prev, .slick-next{
				opacity: 1;
			}
		}

	}

	.carousel-inner > .item > img, .carousel-inner > .item > a > img{
		height: 100%;
	}
}

.aux-wrap-seccion-slider-home{
	width: 100%;
    background-color: #3f6574;
	.wrap-seccion-slider-home{
		width: 100%;
		margin: 0 auto;		
	}
}
.seccion-slider-home{
	position: relative;
    padding-top: 100vh;
    width: 100%;
	.container.slider-home{
		top: 0;
		position: absolute;
		left: 0;
		height: 100%;
		.slick-list{
			padding: 0 !important;
		}
	}
}

.seccion-slider-home .scrollbar-home{
	position: absolute;
	bottom: 3.9rem;
}

.container.home-concurso .scrollbar-home{
	position: relative;
	padding-top: 9rem;
	padding-bottom: 5.5rem;
	@media (max-width: $sm-width) {
		padding-top: 3.1rem;
		padding-bottom: 2.8rem;
	}
}

@media (max-width: $md-width) {
	.hotspot-marker-img, .hotspot-marker-img + .wrap-hotspot-info{
		display: none !important;
		visibility: hidden !important;
	}
}
