.section-empresa.sofas.sofas-medioambiente{
    &.section-two-paragraph-enviroment{
        .wrap-content{
            .wrap-up-first{
                position: relative;
                width: 100%;
                .wrap-title{
                    width: 100%;
                    .title{
                        padding-right: 0;     
                        position: relative;
                        padding-bottom: 6rem;                        
                    }
                }  
                .wrap-img.mvl{
                    display: none !important;
                }      
            }
            .part-left, .part-right{
                width: 50%;
            }
            .part-right{
                padding-left: 2.8rem;
            }
            .part-up{
                width: 100%;
                margin-bottom: 6rem;
                .part-left{
                    display: flex;
                    flex-direction: column;
                    .wrap-img{                       
                        width: 100%;
                        flex-grow: 1;
                        .img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
                .part-right{
                    align-items: flex-end;
                    display: flex;
                    position: relative;
                    flex-direction: column;
                    .wrap-img{
                        position: relative;
                        width: 60%;
                        padding-top: 70%;                        
                        margin: 0 auto;                        
                        margin-top: -4rem;
                        flex-grow: 1;
                        .img{
                            position: absolute;
                            top: 0;
                            left: 0;
                            margin: 0 auto;                            
                            object-fit: contain;
                        }
                    }
                    .wrap-text{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;                    
                    }               
                }
            }       
        }
    }
}
.section-empresa.sofas.sofas-medioambiente{
    &.section-two-paragraph-enviroment{
        .wrap-content{
            .part-down{
                width: 100%;
                overflow: hidden;
                .part-text{
                    display: flex;
                    width: calc(100% + 6.5rem);
                    padding-bottom: 3.5rem;
                    .text{
                        width: calc(100% / 3);                        
                        padding-right: 6.5rem;                        
                    }
                }
                .part-text-full{
                    width: calc(100% - 6.5rem);
                    .link{
                        color: $colorCorporative;
                        text-decoration: underline;
                        opacity: 1;
                        transition: .2s all ease;

                        &:hover{
                            opacity: .8;
                            transition: .2s all ease;
                        }
                    }
                }
                .part-img{
                    width: calc((100% + 6.5rem) - ((100% + 6.5rem) / 3) );
                    padding-bottom: 8.5rem;
                    padding-top: 5rem;                
                    padding-right: 6.5rem;
                    .wrap-img{
                        width: 100%;
                        height: auto;
                        .img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
    &.second{
        padding-top: 0;
    }
}

//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {
    .section-empresa.sofas.sofas-medioambiente{
        &.section-two-paragraph-enviroment{
            padding-top: 0 !important;
            .wrap-content{
                .generic.text, .generic.text p, .generic{
                    color: inherit;
                    font-size: 1.7rem;
                    line-height: 2.5rem;
                }
                .part-left, .part-right{
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }
                .wrap-up-first{
                    .wrap-title{
                        width: 100%;
                        .title{                           
                            padding-bottom: 2rem;          
                            padding-top: 10rem;              
                        }
                    } 
                    .wrap-img.mvl{
                        right: 0;
                        position: absolute;
                        display: block !important;
                        .img{
                            width: 15.3rem;
                            width: 15.5rem;
                        }
                    }  
                }
                .part-up{
                    flex-direction: column;
                    margin-bottom: 0;
                    .part-right{
                        .wrap-img.desk{
                            display: none !important;
                        } 
                    }
                    .part-left{
                        .wrap-img{
                            padding-top: 1rem;
                            padding-bottom: 3rem;
                        }
                    }
                }
            }
        }
    }

    .section-empresa.sofas.sofas-medioambiente{
        &.section-two-paragraph-enviroment{
            .wrap-content{
                .part-down{   
                    .part-text{                        
                        flex-direction: column;
                        width: 100%;
                        padding-bottom: 0;
                        .text{
                            width: 100%;                          
                            padding-right: 0;   
                            padding-bottom: 3.7rem;
                            padding-left: calc(4.7rem - 1.5rem);     
                            position: relative;
                            &::before{
                                content: '';
                                position: absolute;
                                width: 1rem;
                                height: 1rem;
                                border-radius: 50%;
                                transform: translateY(0.7rem);
                                left: 0;
                                background-color: $colorCorporative;

                            }               
                        }
                    }
                    .part-text-full{
                        width: 100%;                  
                    }
                    .part-img{
                        width: 100%;
                        padding-bottom: 4.6rem;
                        padding-top: 4.6rem;                     
                        padding-right: 0;                      
                    }
                }
            }
        }      
    }
}