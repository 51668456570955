.container.home-videos{
    .videos-section{
        display: flex;
            flex-direction: column;        
            padding: 5rem;
            padding-bottom: 16rem;           
            background-size: cover;
            background-repeat: no-repeat;    
        .videos-section-list{
            width: 100%;
            margin: 0 auto;
            max-width: 1280px;
        }
    }
    .wrap-info-video{
        position: relative;         
        transform: translateY(4.3rem) translateX(3rem);
        justify-content: flex-end;
        display: flex;
        z-index: 1;

        .text{
            font-family: 'Montserrat-SemiBold';
            font-size: 10.1rem;
            color: #fff;
        }
    }
    .slider-videos-home{
        width: 100%;
        margin: 0 auto;
        max-width: 1280px;
        .slick-list{
            padding: 0 !important;
            cursor: grab;                                
            &.dragging, &.draggable:active:hover {
                cursor: grabbing;
            }
            .slick-track{              
                .slick-slide.videos-section-item{                    
                    .video-wrapper{
                        padding-top: 56%;
                        width: 100%;
                        position: relative;                               
                                                     
                        .video-container{
                            left: 0;
                            object-fit: cover;
                            width: 100%;
                            position: absolute;
                            top: 0;
                            height: 100%;
                            img{
                                z-index: -1;
                                position: relative;
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                                opacity: 1;
                                transition: .3s all ease;
                                &.isSee{
                                    z-index: 1;
                                    opacity: 1;
                                    transition: .3s all ease;
                                    
                                }
                            }
                            iframe{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                    .aux-play{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 9;
                    }
                    .playPause{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: calc((100% - ((2.5rem * 1.5) + 3.9rem)) / 2);
                        left: 50%;                      
                        transform: translateY(-50%) translateX(-50%);                        
                        z-index: 10;
                        opacity: 1;
                        transition: 0.2s all ease;              
                        
                        &::before{
                            position: absolute;
                            content: '';
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                            opacity: .7;                         
                            transition: 0.4s transform ease-in;                          
                            width: 6.8rem;
                            height: 6.8rem;   
                            top: 50%;
                            left: 50%;                          
                            transform: translateY(-50%) translateX(-50%) scale(1); 
                            z-index: 2;
                        }
                        &:hover{
                            &::before{                               
                                opacity: 1;
                                transform: translateY(-50%) translateX(-50%) scale(1.2);
                                transition: 0.4s transform ease-in;
                            }
                        }
                        &.play{
                            display: flex;
                            &::before{                                                   
                                background-image: url('/images/btn-play.svg');             
                            }
                            &.isPlayd{
                                display: none;
                            }
                        }
                        &.pause{
                            display: flex;
                            &::before{                                                   
                                background-image: url('/images/btn-pause.svg');             
                            }
                            &.isStoped{
                                display: none;
                            }
                        }
                        &.mouseLeave{
                            opacity: 0;
                            transition: 0.2s all ease;
                        }
                    }
                }
            }
        }
        .slick-dots{
            z-index: 2;           
            width: auto;
            color: #fff;
            align-self: flex-start;
            font-family: 'Montserrat-Regular';
            font-size: 2.5rem;
            letter-spacing: 3.85px;
            display: flex;                   
            bottom: 2.2rem;
            flex-wrap: wrap; 
            display: flex;
            max-width: 50%;
        
            li{
                width: 7.2rem;
                margin: 0;
                display: flex;
                
                a{
                    color: #fff;
                    opacity: .4;
                    transition: .3s opacity linear;
                    text-align: start;
                }
                
                &.slick-active{
                    a{
                        opacity: 1;
                        transition: .3s opacity linear;
                    }
                    
                }
            }
        }
        .slick-prev, .slick-next{
            opacity: 0;
            top: calc((100% - ((2.5rem * 1.5) + 3.9rem)) / 2);
        }
        .slick-prev	{
			transform: translateX(2rem) translateY(-50%);
    		left: 0;
			display: none;
			width: 4rem;
			height: 4rem;
			
			&:before{
				content: '';
				background-image: url('/images/left.svg');
				width: 100%;
				height: 100%;
				position: absolute;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				top: 0;
				left: 0;
			}

		}
		.slick-next	{
			transform: translateX(-2rem) translateY(-50%);
    		right: 0;
			display: none;
			width: 4rem;
			height: 4rem;

			&:before{
				content: '';
				background-image: url('/images/right.svg');
				width: 100%;
				height: 100%;
				position: absolute;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				top: 0;
				left: 0;
			}
		}
		&:hover{		
			.slick-prev, .slick-next{
				opacity: 1;
                transition: .2 opacity ease;
			}
		}
    }

/**
 *  Progress Bar
 */
 .wrap-progress{
    width: 100%;
    margin: 0 auto;
    max-width: 1280px;
    position: relative;
    height: calc(2.5rem * 1.5);
    margin-top: 2.3rem;

    display: flex;       
    justify-content: flex-end;
 }
.progress {
    display: block;   
    height: 3px;
    border-radius: 0;
    overflow: hidden;
    align-self: center ;    
    background-color:rgba(#fff, .5);
    background-image: linear-gradient(to right, #fff, #fff);
    background-repeat: no-repeat;
    background-size: 0 100%;     
    transition: background-size .4s ease-in-out;
  }
/**
 *  END Progress Bar
 */
    .wrap-btnDefault{
        margin-top: -2rem;
    }

}

@media (max-width: $sm-width) {
    .container.home-videos{
        padding-top: 0;
        .videos-section{              
            padding: 1.5rem;
            padding-bottom: 12.4rem;
            padding-top: 4rem;                  
            .videos-section-list{         
                max-width: 100%;
            }
        }
        .wrap-info-video{         
            transform: translateY(2.5rem) translateX(0);
           
            .text{              
                font-size: 6rem;            
                transform: translateY(2.2rem); 
            }
        }
        .slider-videos-home{          
            max-width: 100%;        
            .slick-list{
                .slick-track{
                    .slick-slide.videos-section-item{
                        .playPause{                           
                            top: calc((100% - (2.3rem + 1.9rem * 1.5) - (1.9rem * 1.5) ) / 2);
                            &::before{
                                width: 3.8rem;
                                height: 3.8rem;
                            }
                        }
                    }
                }
            }    
            .slick-dots{
                max-width: 100%;
                width: 100%; 
                font-size: 1.9rem;
                letter-spacing: 2.92px;    
                bottom: 4rem;
                
            }
        }
    
    
    
    /**
     *  Progress Bar
     */
     .wrap-progress{      
        max-width: 100%;
        position: relative;
        height: calc(1.9rem * 1.5);
        margin-top: calc(2.3rem + 1.9rem * 1.5);
        padding-left: 0;     
    }
    .progress {     
        width: 100% !important; 
      }
    /**
     *  END Progress Bar
     */
    
        .wrap-btnDefault{
            margin-top: -2rem;
        }    
    }
}