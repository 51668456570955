* {
	box-sizing: border-box;
	outline: 0;
    background: transparent none repeat scroll 0 0;
    border: 0 none;
    font-size: 100%;
    margin: 0;
    outline: 0 none;
    padding: 0;
    text-decoration: none;
    vertical-align: baseline;
    font-display: block;
}

html {
    margin: 0;
	font-size: 10px;
 }

body {
	font-family: 'Montserrat-Regular';
	color: $black;
	font-size: 2rem;
	line-height: 1.2;
	margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    .container{
        flex-grow: 1;
        position: relative;   
        height: 100%;
    }
    &.stopSrcoll{
        overflow-y: hidden !important;
    }    
}
a{
    text-decoration: none;
}
ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
}
button {
    outline: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
}

