.container.home-empresa.desk{
    width: 100%;   
    background-color: rgba(17, 151, 165, 0.15);
    padding: 5rem;
    padding-top: 1;
    padding-top: 11.8rem;
    margin: 0 auto;
    .wrap-empresa.desk{
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        .wrap-title{
            padding-bottom: 7.4rem;
            .title{
                color: $colorCorporative;
                font-family: 'Montserrat-SemiBold';
                font-size: 8rem;        
            }
        }
     
        .empresa-content{ 
            .text{                
                overflow: hidden;
                height: 0;
                visibility: hidden;
                &.notEmpty{
                    height: auto;
                    visibility: visible;
                }
            }
            .title{     
                padding: 3.8rem;                
                position: relative;  
                overflow: hidden;              
              
                .title-info{                
                    color: #fff;      
                    text-transform: uppercase;       
                    font-family: 'Montserrat-Bold';
                    font-size: 2.5rem;
                    letter-spacing: 3.33px;
                    a{
                        color: #fff;
                    }
                }
            }          
            .img{           
               img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
               }
            }      
            .aux-text{
                display: none;
                opacity: 1; 
                transition: .4s .5s opacity linear;
                &.isVisible{
                    opacity: 1;
                    transition: .4s .5s opacity linear;
                }
            }   
            .wrap-info{       
                position: relative;          
                width: 100%;
                &.isVisible{
                    position: relative;                                                   
                    bottom: 0;                     
                    height: auto;                           
                    transition: .1s .3s all linear;   
                }
                .text{
                    font-size: 1.5rem;
                    line-height: 1.9rem;
                    font-family: 'Montserrat-Regular';
                    width: 100%;
                    color: #fff;
                    letter-spacing: 0;
                    padding-bottom: 2rem;
                    padding-top: 1.8rem;
                    text-transform: none;      
                }
                .link{
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 1.3rem;
                    line-height: 1.9rem;
                    position: relative;
                    &::after{
                        content: "";
                        background-image: url('/images/arrow-white-right.svg');
                        position: absolute;                        
                        width: 1.6rem;
                        height: 1.3rem; 
                        background-position: center;
                        background-repeat: no-repeat;       
                        transform: translateX(1rem) scale(1);
                        bottom: 0;     
                        transition: .1s transform;            
                    }   
                    &:hover{
                        &::after{                              
                            transform: translateX(1rem) scale(1.1);
                            transition: .1s transform;
                                          
                        } 
                    }                 
                }
            }
   
            .part-superup{
                margin-bottom: .8rem;               
                overflow: hidden;               
                height: auto;
                min-height: 40rem;
                max-height: 40rem;
                background-color: #126d77;
     
                .title{    
                    position: relative;
                    width: 50%;      
                    z-index: 2;            
                }
                .img{         
                    position: relative;  
                    width: 50%;               
                    z-index: 1;
                    img{
                        width: 100%;
                        transform: translateX(0);
                        transition: .5s all linear;
                    }
                }
               
                &:hover{            
                    .img img{
                        transform: translateX(100%);
                        transition: .3s all linear;
                    }
                    .aux-text{                       
                        max-height: 9999px;
                        opacity: 1;
                        transition: max-height 1s, opacity .2s linear;                                     
                    }
                }
                &.down{
                    background-color: #3d8289;
                    .title{
                        background-color: #3d8289;
                    }
                }
     
                .aux-text{                   
                    display: flex;
                    height: auto;
                    opacity: 0;
                    max-height: 0;
                    transition: max-height 0.5s, opacity .3s .1s linear;
                    width: 100%;       
                }              
            }

            .part-up{
                margin-bottom: .8rem;
                .part-left{                   
                    width: calc(41% - 0.8rem);
                    margin-right: 0.8rem;
                    background-color: #6b9195;
                    overflow: hidden;
                  
                    .aux-text{                     
                        display: flex;
                        height: auto;
                        opacity: 0;
                        max-height: 0;
                        transition: max-height 0.5s, opacity .3s .1s linear;
                        width: 100%;       
                    }
             
                    .img{
                        position: relative;
                        padding-top: 58%;
                        width: 100%;                      
                        transform: translateY(0);
                        transition: .5s all linear;
                        img{
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            object-fit: cover;
                            top: 0;
                            left: 0;
                        }                                                                   
                    }  
                    &:hover{
                        .img{
                            transform: translateY(-100%);                         
                            transition: .2s all linear;
                        }   
                        .aux-text{                        
                            max-height: 9999px;
                            opacity: 1;
                            transition: max-height 1s, opacity .2s linear;                                        
                        }                 
                    }               
                }

                .part-right{
                    width: 59%;                

                    .part-left-up{
                        height: 42.7rem;
                        overflow: hidden;
                        margin-bottom: .8rem;
                        background-color: $colorCorporative;
                        .title{
                            background-color: $colorCorporative;
                            width: 58%;
                            transition: .3s all linear;
                        }
                        .aux-text{                   
                            display: flex;
                            height: auto;
                            opacity: 0;
                            max-height: 0;
                            transition: max-height 0.5s, opacity .3s .1s linear;
                            width: 100%;     
                        }
                        .img{
                            position: relative;
                            width: 42%;
                            padding-top: 60%;                        
                            transform: translateX(0);
                            transition: .5s all linear;
                            img{
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                top: 0;
                                left: 0;
                            }
                        }
                        &:hover{                        
                            .img{                           
                                transform: translateX(100%);
                                transition: .2s all linear;                             
                            }
                            .aux-text{                        
                                max-height: 9999px;
                                opacity: 1;
                                transition: max-height 1s, opacity .2s linear;                                        
                            }        
                        }
                    }

                    .part-left-down{                  
                        overflow: hidden;
                        height: 25.2rem;
                        .title{
                            background-color: #669ba0;
                            width: 58%;
                            transition: .3s all linear;
                        }
                        .aux-text{                   
                            display: flex;
                            height: auto;
                            opacity: 0;
                            max-height: 0;
                            transition: max-height 0.5s, opacity .3s .1s linear;
                            width: 100%;     
                        }
                        .img{
                            position: relative;
                            width: 45%;
                            padding-top: 30%;
                            transition: .5s .7s all linear;
                            img{
                                top: 0;
                                left: 0;
                                position: absolute;
                            }
                        }
                        &:hover{
                            .title{
                                width: 100%;
                                transition: .5s .7s all linear;
                            }
                            .img{
                                width: 0;
                                transition: .3s all linear;
                            }
                            .aux-text{                        
                                max-height: 9999px;
                                opacity: 1;
                                transition: max-height .8s, opacity .5s .3s linear;                                        
                            }  
                        }
                    }
                   
                }
            }

            .part-center{                
                margin-bottom: .8rem;
                .part-left{
                    width: 57%;
                    position: relative;
                    height: calc(26rem + 12.5rem);                   

                    .title{
                        width: 53%;
                        position: relative;
                        background-color: #177f8a;
                        transition: .3s all linear;           
                        .title-info{
                            width: 100%;
                        }            
                    }
                    .aux-text{                     
                        display: flex;
                        height: auto;
                        opacity: 0;
                        max-height: 0;
                        transition: max-height 0.5s, opacity .3s .1s linear;
                        width: 100%;       
                    }
                    .img{
                        position: relative;
                        width: 47%;
                        padding-top: 50%;
                        transition: .5s .7s all linear;
                        img{
                            position: absolute;
                            top: 0;
                            right: 0;
                        }

                    }
                    &:hover{
                        .title{
                            width: 100%;
                            transition: .5s .7s all linear;
                            .title-info{
                                width: 50%;                                
                            }
                        }
                        .img{
                            width: 0;
                            transition: .3s all linear;
                        }
                        .aux-text{                        
                            max-height: 9999px;
                            opacity: 1;
                            transition: max-height .8s, opacity .5s .3s linear;                                           
                        } 
                    }
                }

                .part-right{
                    width: calc(43% - .8rem);
                    margin-left: .8rem;
                    background-color: #00454c;
                    overflow: hidden;
                    height: calc(26rem + 12.5rem);
                    position: relative;

                    .title{
                        padding-top: 5.4rem;                     
                        width: 100%;
                        height: auto;
                        background-color: #00454c;
                        position: relative;
                        transition: .8s all linear;
                        .wrap-info{         
                            &.isVisible{
                                height: auto;                               
                                .text{
                                    width: 100%;
                                    transition: .3s .5s width linear;
                                }    
                            }
                        }
                    }
                    .aux-text{                     
                        display: flex;
                        height: auto;
                        opacity: 0;
                        max-height: 0;            
                        width: 100%;       
                        transition: max-height .3s linear, opacity linear; 
                    }
                    .img{
                        width: 100%;
                        position: relative;
                        padding-top: 48%;                   
                        transform: translateY(0);
                        transition: .5s all linear;
                        img{
                            position: absolute;
                            top: 0;
                            left: 0;
                        }                  

                    }
                    &:hover{
                        .title{
                            height: 100%;       
                            position: absolute;
                            transition:  .7s all linear;
                        }
                        .img{      
                            transform: translateY(-100%);                      
                            transition: .2s all linear;
                        }
                        .aux-text{                        
                            max-height: 9999px;
                            opacity: 1;
                            transition: max-height 1s, opacity .5s 0.3s linear;                                      
                        }   
                     
                    }
                }
            }
     
        }
    } 
}
.container.home-empresa.mvl{ display: none; }



//    -  -
// {0 º  º 0}
//     ``
//   (____)

// movil (slider horizontal)
@media (max-width: $md-width) {
    .container.home-empresa.desk{
       display: none;      
    }
    .container.home-empresa.mvl{ 
        padding-top: 0;
        .aux-wrap{
            padding-bottom: 10.2rem;
            padding-top: 5.1rem;
        }  
        display: block;   
        width: 100%;   
        background-color: rgba(17, 151, 165, 0.15);

        .wrap-text{       
            white-space: nowrap;
            padding-left: 1.5rem;

            .text{
                color: $colorCorporative;       
                font-family: 'Montserrat-SemiBold';           
                font-size: 3.5rem;
                line-height: 6.3rem;
                padding-bottom: 3rem;  
            }     
        }

        .carousel-box{
            position: relative;     
            width: calc(100% - 1.5rem);           
            display: flex;
            justify-content: flex-end;

            .carousel-box-wrapper{              
                position: -moz-sticky;
                position: -o-sticky;
                position: -ms-sticky;
                position: sticky;     
                position: -webkit-sticky;   
                top: 6.5rem;
                width: calc(100% - 1.5rem);     
                overflow: visible;
            }    
        
            .carousel-wrapper{
                width: 100vw;      
                overflow: hidden;

                .carousel-container{
                    display: flex;
                    flex-wrap: nowrap;      
                    .slick-list{
                        height: unset;
                    }      

                    .carousel-item{        
                        height: 100%;            
                        width: 30.5rem;
                        min-width: 30.5rem;
                        margin: 0;
                        margin-left: 1.4rem;
                        display: flex;
                        flex-direction: column;
                        background-color: #126d77;                
                    
                        .item{     
                            padding-top: 66%;
                            position: relative;          
                            margin-right: 0;
                            margin-left: 0;

                            .img{                                       
                                width: 100%;
                                height: 100%;
                                object-fit: cover;                 
                                top: 0;
                                position: absolute;
                                left: 0;
                            } 
                        }
                        .item-info{
                            color: #fff;                          
                            padding: 1.2rem;
                            padding-top: 2.8rem;
                            padding-right: 4rem;

                            .title{
                                text-transform: uppercase;
                                font-family: 'Montserrat-Bold';
                                font-size: 1.9rem;
                                line-height: 2.6rem;
                                margin-bottom: 2rem;
                                padding-left: 1rem;
                                letter-spacing: 2.53px;
                            }
                            .text{
                                color: #fff;
                                font-family: 'Montserrat-Regular';
                                font-size: 1.5rem;
                                line-height: 2.2rem;
                            }
                        }
                        .link{
                            line-height: 1.9rem;
                            font-size: 1.3rem;
                            font-family: 'Montserrat-Medium';
                            padding-bottom: 2.6rem;
                            padding-top: 2.6rem;
                            padding-left: 1.5rem;
                            padding-right: 3.5rem;
                            color: #fff;
                            letter-spacing: 0;
                            position: relative;
                            text-transform: uppercase;
                            &::after{
                                content: "";
                                background-image: url('/images/arrow-white-right.svg');
                                position: absolute;                        
                                width: 1.6rem;                             
                                height: 1.3rem;
                                background-position: center;
                                background-repeat: no-repeat;       
                                transform: translateX(1rem) translateY(.3rem);                                            
                            } 
                            &:active, &:focus{
                                transform: scale(1.1) translateX(1.5rem);
                                transition: .3s transform ease;
                            }                         
                        }            
                                
                        &:first-of-type{
                            margin-left: 0;
                        }
                        &:last-of-type{
                            margin-right: 3rem;
                        }
                    }
                    .carousel-item:nth-child(1){
                        background-color: #126d77;                   
                    }
                    .carousel-item:nth-child(2), &:nth-child(4){
                        background-color: #6b9195;
                    }
                    .carousel-item:nth-child(3){
                        background-color: $colorCorporative;
                    }       
                    .carousel-item:nth-child(5){
                        background-color: #177f8a;                
                    }
                    .carousel-item:nth-child(6){
                        background-color: #00454c;                   
                    }
                    .carousel-item:nth-child(7){
                        background-color: #3d8289;                   
                    }
                }           
            }
        }
    }
}



