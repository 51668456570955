.container-content {
    width: 100%;   
    background-color: #e1f4f7;
    padding-top: calc(6.5rem + 3.7rem);
    padding-left: 5rem;
    padding-right: 5rem;

    &.bg-white {
        background-color: #fff;
    }

    .wrap-module-list {
        padding-top: 2.7rem;
        margin-bottom: 15.6rem;      
        display: flex;
        flex-wrap: nowrap;      
        position: relative;

        .module-list-wrapper {
            width: 100%;         
        }

        .module-list-lateral {          
            width: 18vw;        
            z-index: 2;
            padding-bottom: rem(50px);
            justify-content: flex-end;
            display: flex;
            .module-list-lateral-inner {
                position: -moz-sticky;
                position: -o-sticky;
                position: -ms-sticky;
                position: sticky;
                position: -webkit-sticky;
                top: calc(#{$topbar-height} + #{rem(45px)});
                max-width: 100%;
                .item-inner {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            @media (min-width: calc(#{$extra-width} + 1px)) {
                width: 25vw;
            }
        }

        .module-list-container {
            padding-left: 3rem;        
            width: calc(100% - 18vw);
            min-width: calc(100% - 18vw);
            flex-grow: 1;

            @media (min-width: calc(#{$extra-width} + 1px)) {
                width: calc(100% - 25vw);
                min-width: calc(100% - 25vw);
            }

            .section-empresa {            
                margin-top: $topSection;
                .subtitle {
                    width: 67%;
                    max-width: 60.5rem;
                    font-family: "MONTSERRAT-MEDIUM";
                    font-size: 1.7rem;
                    color: $colorCorporative;
                    text-transform: uppercase;
                    line-height: 3rem;
                    letter-spacing: 1.5px;                 
                    flex-grow: 1;
                    padding-bottom: 2rem;
                }
                .title {
                    padding-right: 7.3rem;
                    font-family: "Montserrat-SemiBold";
                    font-size: 4.1rem;
                    line-height: 5.5rem;
                    letter-spacing: 0;
                    color: #575252;
                    padding-bottom: 0.7rem;
                }
                .part-right,
                .part-left {
                    width: 50%;
                }
                &.section-text-sin-img {
                    .wrap-btnDefault {
                        align-self: flex-start;
                    }
                }
                &.section-points-menu-horizont {
                    .aux-warp {
                        .line {
                            width: 100%;
                            height: 2px;
                            border-top: 2px solid $colorCorporative;
                            transition: 0.3s all ease;
                        }
                        &:hover {
                            .link-point {
                                transition: 0.3s all ease;
                                .point-item .item {
                                    color: #8fc3c9;
                                }
                                .link {
                                    opacity: 0.4;
                                }
                                &::before {
                                    background-color: #8fc3c9;
                                }
                                &:hover {
                                    .point-item .item {
                                        color: $colorCorporative;
                                        transition: 0.3s all ease;
                                    }
                                    .link {
                                        opacity: 1;
                                        transition: 0.3s all ease;
                                    }
                                    &::before {
                                        background-color: $colorCorporative;
                                        transition: 0.3s all ease;
                                    }
                                }
                            }
                            .line {                            
                                border-color: #8fc3c9;
                                transition: 0.3s all ease;
                            }
                        }
                    }
                    .wrap-points-menu {
                        justify-content: space-between;

                        .link-point {
                            position: relative;
                            text-align: center;
                            font-size: 1.7rem;
                            letter-spacing: 0;
                            line-height: 2.3rem;
                            font-family: "Montserrat-Regular";
                            padding-top: 4.8rem;
                            transition: 0.3s all ease;
                            &::before {
                                content: "";
                                position: absolute;
                                width: 2.5rem;
                                height: 2.5rem;
                                border-radius: 50%;
                                background-color: $colorCorporative;
                                top: 0;
                                transform: translateY(-50%) translateX(-50%);
                                transition: 0.3s all ease;
                            }
                            &:first-of-type {
                                text-align: left;
                                &:before {
                                    transform: translateY(-50%) translateX(0);
                                }
                            }
                            &:last-of-type {
                                text-align: right;

                                &:before {
                                    transform: translateY(-50%)
                                        translateX(-100%);
                                }
                            }
                            .point-item {
                                .item {
                                    color: $colorCorporative;
                                    font-family: "Montserrat-Bold";
                                    transition: 0.3s all ease;
                                }
                                .link {
                                    color: #575252;
                                    text-decoration: underline;
                                    transition: 0.3s all ease;
                                    span {
                                        text-transform: lowercase;
                                    }
                                }
                            }
                        }
                    }
                }

                .videos-section-item {
                    width: 100%;
                    position: relative;
                    margin-bottom: 5rem;
                    .video-wrapper {
                        padding-top: 56%;
                        width: 100%;
                        position: relative;
                        .video-container {
                            left: 0;
                            object-fit: cover;
                            width: 100%;
                            position: absolute;
                            top: 0;
                            height: 100%;
                            img {
                                z-index: -1;
                                position: relative;
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                                opacity: 1;
                                transition: 0.3s all ease;
                                &.isSee {
                                    z-index: 1;
                                    opacity: 1;
                                    transition: 0.3s all ease;
                                }
                            }
                            iframe {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                    .aux-play {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 9;
                    }
                    .playPause.js-play:not(.isPlayd) {
                        &::after {
                            content: "";                           
                            z-index: 1;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background-color: rgba(0, 0, 0, 0.5);
                        }
                    }
                    .playPause {
                        width: 100%;
                        height: 100%;                     
                        position: absolute;                     
                        top: 0;
                        left: 0;
                        z-index: 10;
                        opacity: 1;
                        transition: 0.2s all ease;

                        &::before {
                            position: absolute;
                            content: "";
                            width: 6.8rem;
                            height: 6.8rem;                           
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                            opacity: 0.9;
                            top: 50%;
                            left: 50%;                            
                            transform: translateY(-50%) translateX(-50%)scale(1); 
                            transition: 0.4s transform ease-in;
                            z-index: 2;
                        }

                        &:hover {
                            &::before {
                                opacity: 1;
                                transform: translateY(-50%) translateX(-50%)scale(1.2); 
                                transition: 0.4s transform ease-in;
                            }
                        }
                        &.play {
                            display: flex;
                            &::before {
                                background-image: url("/images/btn-play.svg");
                            }
                            &.isPlayd {
                                display: none;
                            }
                        }
                        &.pause {
                            display: flex;
                            &::before {
                                background-image: url("/images/btn-pause.svg");
                            }
                            &.isStoped {
                                display: none;
                            }
                        }
                        &.mouseLeave {
                            opacity: 0;
                            transition: 0.2s all ease;
                        }
                    }
                }
            }
            //*************** sections of company *********************//
            .section-empresa.section-slider {
                margin-top: $topSection;
                .wrap-text {                                
                    transform: translateX(7rem);
                    margin: 0 auto;                  
                    width: 59rem;

                }
            }

            .section-empresa.section-text-con-img-right {
                .part-left {
                    width: 45%;
                    padding-right: 5.5rem;
                    .title {
                        padding-right: 0;
                    }
                    .wrap-btnDefault {
                        margin-top: 4.4rem;
                        align-self: flex-start;
                    }
                }
                .part-right {
                    width: 55%;
                    .wrap-img {
                        height: 100%;
                        .img {
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
            .section-empresa.section-text-con-img-left {
                .part-left {
                    width: 55%;
                    .wrap-img {
                        height: 100%;
                        .img {
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
                .part-right {
                    width: 45%;
                    padding-left: 5.5rem;
                    .wrap-btnDefault {
                        margin-top: 4.4rem;
                        align-self: flex-start;
                    }
                }
            }

            .section-empresa.section-img-down {
                .wrap-btnDefault.mvl {
                    display: none;
                }
                .part-left {
                    .wrap-img {
                        height: 100%;
                        .img {
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .wrap-btnDefault {
                        margin-top: 4.4rem;
                        align-self: flex-start;
                    }
                }
                .part-right {                    
                    .wrap-btnDefault.mvl {
                        display: none;
                    }
                }
                .part-down {
                    width: 100%;
                    margin-top: 7.7rem;
                    .wrap-img {
                        width: 100%;
                        position: relative;
                        padding-top: 42%;
                        .img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }
            .section-empresa.section-only-img {
                position: relative;
                width: calc(100% + 5rem);              
                margin-top: 10rem;
                .aux-warp {
                    .wrap-img {
                        width: 100%;
                        position: relative;
                        height: 37.5rem;
                        .aux-img {
                            width: 60rem;
                            margin: 0 auto;
                            height: 100%;
                            .img-content {
                                width: calc(60rem + (100% - 60rem) / 2);
                                position: absolute;
                                height: 100%;
                                top: 0;
                                .img {
                                    height: 100%;
                                    width: 100%;
                                    background-color: $colorCorporative;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .section-empresa.section-2img-2parrafos {
                .wrap-content.up {
                    .part-left {
                        height: 100%;
                        width: 50%;
                        padding-right: 4.8rem;
                        .subtitle {
                            padding-bottom: 3.3rem;
                        }
                        .wrap-text {
                            padding-bottom: 10.8rem;
                        }
                        .part-right-mvl {
                            display: none !important;
                        }
                    }

                    .part-right {
                        height: 100%;
                        width: 50%;
                        margin-top: calc(5.5rem / 2);
                        align-self: flex-end;
                        .wrap-img {
                            width: 100%;
                            height: 100%;
                            padding-top: 80%;
                            position: relative;
                            .img {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                object-fit: cover;
                            }
                        }
                        .wrap-btnDefault {
                            margin-top: 4.4rem;
                            align-self: flex-start;
                        }
                    }
                }

                .wrap-content.down {
                    .part-left {
                        width: 65%;
                        height: 100%;
                        margin-top: -6.2rem;
                        .subtitle {
                            padding-bottom: 3.3rem;
                        }
                        .wrap-text {
                            padding-bottom: 10.8rem;
                        }
                        .wrap-img {
                            width: 100%;
                            height: 100%;
                            padding-top: 74%;
                            position: relative;
                            .img {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                object-fit: cover;
                            }
                        }
                    }

                    .part-right {
                        height: 100%;
                        align-self: center;
                        width: 35%;
                        margin-top: 3.5rem;
                        padding-left: 3.5rem;

                        .wrap-btnDefault {
                            margin-top: 3.5rem;
                            align-self: flex-start;
                        }
                    }
                }
            }

            .section-empresa.titulo-subtitulo-btn {
                margin-top: 9.4rem;
                padding-bottom: 20rem;
                .wrap-img.mvl {
                    display: none;
                }
                .aux-box {
                    width: 50%;              
                    padding-top: 9rem;
                    position: relative;
                    .image {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 23.9rem;
                        height: 24.2rem;
                        .img {                           
                            width: 100%;
                            height: 100%;
                            background-position: center;
                            background-size: contain;
                            background-repeat: no-repeat;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }
                .part-left {
                    width: 100%;
                    padding-left: calc(100% / 3);
                    .title {
                        padding-bottom: 2.5rem;
                    }
                    .wrap-btnDefault {
                        margin-top: 3rem;
                        align-self: flex-start;
                    }
                }
                .wrap-img {
                    width: 100%;
                    padding-right: 25%;
                    padding-top: 3rem;
                    .foto-muve,
                    .foto-muve4 {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        padding-top: 117%;
                        transform: translateY(10rem);
                        transition: 0.2s all linear;
                        img {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            object-fit: cover;
                            top: 0;
                            left: 0;
                        }
                    }
                }
                .part-right {
                    position: relative;
                    padding-top: 15rem;
                    align-self: center;
                    .image {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 23.9rem;
                        height: 24.2rem;
                        .img {                          
                            width: 100%;
                            height: 100%;
                            background-position: center;
                            background-size: contain;
                            background-repeat: no-repeat;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }

                    .title {
                        padding-bottom: 2.5rem;
                        padding-right: 0;
                    }
                    .subtitle {
                        width: 100%;
                    }
                    .wrap-btnDefault {
                        margin-top: 3rem;
                        align-self: flex-start;
                    }
                }
            }
            .section-empresa.section-slider {
                .slider-empresa {
                    width: calc(100% + 5rem);
                    margin-bottom: 2.9rem;

                    .slick-list {
                        cursor: grab;
                        &.dragging,
                        &.draggable:active:hover {
                            cursor: grabbing;
                        }
                    }
                    .slick-slide {
                        width: 60rem;
                        max-width: 60rem;
                        .item {
                            position: relative;
                            padding-top: 60%;
                        }
                        .img {
                            width: 100%;

                            top: 0;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            object-fit: cover;
                        }
                    }
                }
            }

            .section-empresa.section-video {
                .videos-section-item {
                    margin-top: -15rem;
                }
            }

            //*********************************************************//
        }
    }
    .section-limited {       
        width: 100%;
        max-width: 1260px;       
        .wrap-text {
            width: 100%;
        }
    }

    .generic {
        color: #000;
        font-size: 1.7rem;
        line-height: 2.5rem;
        font-family: "Montserrat-Regular";
        letter-spacing: 0;
    }
    .generic.text,
    .generic.text p,
    .generic,
    .generic.text * {
        color: #000;
        font-size: 1.7rem !important;
        line-height: 2.5rem !important;
        font-family: "Montserrat-Regular" !important;
        letter-spacing: 0 !important;
    }
    
    .text.generic{
        p {
            padding-bottom: 2.5rem;
            &:last-of-type {
                padding-bottom: 0;
            }
            b, strong {
                font-family: "Montserrat-Bold" !important;
                font-weight: normal;

                a{
                    cursor: pointer;  
                    width: auto;                         
                    font-family: inherit !important;                      
                    text-decoration: none;
                    color: $colorCorporative !important;
                    --border-color: $colorCorporative;
                    --border-width: 1px;
                    --bottom-distance: 0px;
                    display: inline;
                    background-image: linear-gradient(var(--border-color),var(--border-color));
                    background-size: 0 var(--border-width);
                    background-repeat: no-repeat;
                    background-position-y: bottom;
                    transition: background-size .3s;  

                    &:hover{
                        background-size: 100% var(--border-width);
                    }
                }
            }
            * a, a{
                cursor: pointer;  
                width: auto;                         
                font-family: inherit !important;                     
                text-decoration: none;
                color: $colorCorporative !important;
                --border-color: $colorCorporative;
                --border-width: 1px;
                --bottom-distance: 0px;
                display: inline;
                background-image: linear-gradient(var(--border-color),var(--border-color));
                background-size: 0 var(--border-width);
                background-repeat: no-repeat;
                background-position-y: bottom;
                transition: background-size .3s;  

                &:hover{
                    background-size: 100% var(--border-width);
                }
            }
        }
    }
    .text {
        &.isClose {
            overflow: hidden;
            height: calc(2.5rem * 10);
            transition: 2s height linear;
        }

        &.isOpen {
            height: auto;
            transition: 2s height linear;
        }
    }
}

// style general of text
.generic {
    color: #000;
    font-size: 1.7rem;
    line-height: 2.5rem;
    font-family: "Montserrat-Regular";
    letter-spacing: 0;
}
.generic.text,
.generic.text p,
.generic,
.generic.text * {
    color: #000;
    font-size: 1.7rem !important;
    line-height: 2.5rem !important;
    font-family: "Montserrat-Regular" !important;
    letter-spacing: 0 !important;
}
.text.generic {
    p {
        padding-bottom: 2.5rem;
        &:last-of-type {
            padding-bottom: 0;
        }
        b {
            font-family: "Montserrat-Bold" !important;
            font-weight: normal;
        }
    }
    br,
    *:empty {
        display: none;
    }
    .img,
    img {
        display: block;
    }
    * a, a{
        cursor: pointer;       
        width: auto;                         
        font-family: inherit !important;                      
        text-decoration: none;
        color: $colorCorporative !important;
        --border-color: $colorCorporative;
        --border-width: 1px;
        --bottom-distance: 0px;
        display: inline;
        background-image: linear-gradient(var(--border-color),var(--border-color));
        background-size: 0 var(--border-width);
        background-repeat: no-repeat;
        background-position-y: bottom;
        transition: background-size .3s;  

        &:hover{
            background-size: 100% var(--border-width);
        }
    }
}

.btn-seeMore {
    font-size: 1.7rem;
    line-height: 2.3rem;
    letter-spacing: 0.5rem;
    color: $colorCorporative;
    font-family: "Montserrat-Medium";
    margin-top: 3.1rem;
    position: relative;
    &::after {
        content: "";
        background-image: url("/images/arrow-right-green.svg");
        position: absolute;
        width: 1.2rem;
        height: 100%;
        transform: rotate(90deg);
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        margin-left: 1.5rem;
        transition: 0.3s all linear;
    }
    &.js-seeLess:after {
        transform: rotate(-90deg);
        transition: 0.3s transform linear;
    }
    &:hover {
        &::after {
            transform: rotate(90deg) scale(1.2) translateX(0.3rem);
            transition: 0.3s all linear;
        }
    }
}

@media (max-width: 1200px) {
    .container-content {
        .wrap-module-list {
            .module-list-container {
                .section-empresa.titulo-subtitulo-btn {
                    .wrap-content {
                        flex-direction: column;
                    }

                    .part-right {
                        align-self: flex-end;
                    }
                }
            }
        }
    }
}

//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {
    .container-content {
        width: 100%;      
        background-color: #e1f4f7;
        padding-top: calc(6.5rem + 3.7rem);
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        .btn-seeMore {
            font-size: 1.5rem;
            line-height: 2.1rem;
        }

        .wrap-module-list {
            padding-top: 0;
            max-width: 100%;
            margin-bottom: 8rem;

            .module-list-lateral {
                display: none;
            }
            .module-list-container {
                padding-left: 0;
                .section-empresa {
                    max-width: 100%;
                    margin-top: 10.6rem;

                    .title {
                        padding-right: 0;
                        font-size: 3.5rem;
                        line-height: 4.5rem;
                        padding-bottom: 2rem;
                    }
                    .subtitle {
                        width: 100%;                       
                        padding-bottom: 2rem;
                    }

                    .wrap-btnDefault {
                        margin-top: 2rem;
                        align-self: flex-start;
                        min-width: 23.5rem;
                    }
                }
                //*************** sections of company *********************//
                .section-empresa.section-video {
                    padding-top: 0;
                    margin-top: 0;
                    width: 100%;
                    .videos-section-item {
                        margin-top: 0;
                    }
                    .subtitle {
                        padding-left: 1rem;
                        padding-right: 1rem;
                        padding-top: 2.6rem;
                    }
                    img,
                    iframe {
                        width: 100%;
                    }
                }

                .section-empresa.section-text-con-img-right {
                    .wrap-content {
                        flex-direction: column;
                    }
                    .part-left {
                        width: 100%;
                        padding-right: 0;
                        padding-bottom: 4rem;

                        .title {
                            padding-right: 0;
                        }
                        .wrap-btnDefault {
                            margin-top: 4.4rem;
                            align-self: flex-start;
                        }
                    }
                    .part-right {
                        width: 100%;
                        padding-left: 2rem;
                        .wrap-img {
                            height: 100%;
                            .img {
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
                .section-empresa.section-text-con-img-left {
                    .wrap-content {
                        flex-direction: column;
                    }
                    .part-left {
                        width: 100%;
                        order: 2;
                        .wrap-img {
                            height: 100%;
                            .img {
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                    .part-right {
                        width: 100%;
                        padding-left: 5rem;
                        order: 1;
                        padding-bottom: 4rem;

                        .wrap-btnDefault {
                            margin-top: 4.4rem;
                            align-self: flex-start;
                        }
                    }
                }

                .section-empresa.section-img-down {
                    .wrap-content {
                        flex-direction: column;
                        .wrap-btnDefault.mvl {
                            display: flex;
                        }
                    }
                    .part-left {
                        width: 100%;

                        .wrap-img {
                            height: 100%;
                            .img {
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .wrap-btnDefault.desk {
                            display: none;
                            margin-top: 4.4rem;
                            align-self: flex-start;
                        }
                    }
                    .part-right {
                        width: 100%;
                        .wrap-btnDefault.mvl {
                            display: flex;
                            margin-top: 4.4rem;
                            align-self: flex-start;
                        }
                    }
                    .part-down {
                        left: -1.5rem;
                        width: calc(100% + 3rem);
                        position: relative;
                        margin-top: 5.7rem;

                        .wrap-img {
                            width: 100%;
                            position: relative;
                            padding-top: 42%;

                            .img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                }
                .section-empresa.section-only-img {
                    position: relative;
                    width: 100%;
                    margin-top: 4.8rem;

                    .aux-warp {
                        width: calc(100% + 3rem);
                        transform: translateX(-1.5rem);

                        .wrap-img {
                            width: 100%;
                            position: relative;
                            height: 15.8rem;

                            .aux-img {
                                width: 100%;

                                .img-content {
                                    width: 100%;
                                    position: absolute;
                                    height: 100%;
                                    top: 0;

                                    .img {
                                        height: 100%;
                                        width: 100%;
                                        background-color: transparent;
                                        img {
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .section-empresa.section-2img-2parrafos {
                    .wrap-content.up {
                        flex-direction: column;
                        .part-left {
                            width: 100%;
                            padding-bottom: 6rem;
                            padding-right: 0;
                            .subtitle {
                                padding-bottom: 3.3rem;
                            }
                            .wrap-text {
                                padding-bottom: 2.5rem;
                            }
                            .wrap-img {
                                transform: translateX(-1.5rem);
                                max-height: 23rem;
                                max-width: 31.1rem;
                                width: 80%;
                            }
                            .part-right-mvl {
                                display: flex !important;
                                flex-direction: column;
                                .wrap-text {
                                    padding-bottom: 1rem;
                                }
                            }
                        }

                        .part-right {
                            width: 50%;
                            margin-top: 0;
                            align-self: flex-end;
                            .wrap-img {
                                width: 100%;
                                height: 100%;
                                padding-top: 80%;
                                position: relative;
                                .img {
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    object-fit: cover;
                                }
                            }
                            .wrap-btnDefault {
                                margin-top: 4.4rem;
                                align-self: flex-start;
                            }
                        }
                    }
                    .wrap-content.down {
                        flex-direction: column;
                        .part-left {
                            width: 100%;
                            margin-top: -6.2rem;
                            .subtitle {
                                padding-bottom: 3.3rem;
                            }
                            .wrap-text {
                                padding-bottom: 10.8rem;
                            }
                            .wrap-img {
                                width: 80%;
                                height: 100%;
                                padding-top: 54%;
                                position: relative;
                                transform: translateX(-1.5rem);
                                .img {
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    object-fit: cover;
                                }
                            }
                        }

                        .part-right {
                            height: 100%;
                            align-self: center;
                            width: 100%;
                            margin-top: 3.5rem;
                            padding-left: 0;

                            .wrap-btnDefault {
                                margin-top: 3.5rem;
                                align-self: flex-start;
                            }
                            &.desk {
                                display: none !important;
                            }
                        }
                    }
                }
                .section-empresa.section-text-sin-img {
                    .wrap-content {
                        flex-direction: column;
                        .part-left,
                        .part-right {
                            width: 100%;
                        }
                    }
                }

                .section-empresa.titulo-subtitulo-btn {
                    margin-top: 9.4rem;
                    padding-bottom: 20rem;
                    .wrap-img.mvl {
                        display: block;
                        width: 100%;
                        padding-right: 25%;
                        padding-top: 0;
                        .foto-muve4 {
                            width: 100%;
                            height: 100%;
                            position: relative;
                            padding-top: 117%;
                            transition: 0.2s all linear;
                            img {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                object-fit: cover;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                    .aux-box {
                        width: 100%;
                        padding-top: 9rem;
                        position: relative;
                        .image {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 16.7rem;
                            height: 16.9rem;
                            .img {                               
                                width: 100%;
                                height: 100%;
                                background-position: center;
                                background-size: contain;
                                background-repeat: no-repeat;
                                position: absolute;
                                top: -3rem;
                                left: 0;
                            }
                        }
                        .wrap-img.desk {
                            display: none;
                        }
                    }
                    .part-left {
                        width: 100%;
                        padding-left: 0;
                        .title {
                            padding-bottom: 2.5rem;
                        }
                        .wrap-btnDefault {
                            margin-top: 3rem;
                            align-self: flex-start;
                        }
                    }
                    .wrap-img {
                        width: 100%;
                        padding-right: 25%;
                        padding-top: 0;
                        .foto-muve4 {
                            width: 100%;
                            height: 100%;
                            position: relative;
                            padding-top: 117%;
                            img {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                object-fit: cover;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                    .part-right {
                        position: relative;
                        padding-top: 9.5rem;
                        margin-top: 5rem;
                        width: 100%;
                        padding-left: 5.5rem;
                        .image {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 16.7rem;
                            height: 16.9rem;
                            .img {                               
                                width: 100%;
                                height: 100%;
                                background-position: center;
                                background-size: contain;
                                background-repeat: no-repeat;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }
                        .subtitle {
                            width: 100%;
                        }

                        .title {
                            padding-bottom: 2.5rem;
                            padding-right: 0;
                        }
                    }
                }

                .section-empresa.section-slider {
                    margin-top: 3.6rem;
                    .wrap-text {
                        width: 100%;
                        padding-left: 3.5rem;
                        margin: 0 auto;
                        transform: translateX(0);
                    }
                    .slider-empresa {
                        width: calc(100% + 3rem);
                        margin-bottom: 2.9rem;
                        transform: translateX(-1.5rem);
                        .slick-slide {
                            .item {
                                position: relative;
                                padding-top: 60%;
                                margin-right: 1.5rem;
                            }
                            .img {
                                width: 100%;
                                top: 0;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                object-fit: cover;
                            }
                        }
                    }
                }

                //*********************************************************//
                .section-empresa.section-points-menu-horizont {
                    width: calc(100% + 3rem);
                    max-width: calc(100% + 3rem);
                    transform: translateX(-1.5rem);
                    overflow: scroll;
                    overflow-y: hidden;
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                    .line {
                        display: none !important;
                    }
                    .wrap-points-menu {
                        justify-content: space-between;
                        margin-left: 1.5rem;

                        .link-point {
                            position: relative;
                            font-size: 1.7rem;
                            letter-spacing: 0;
                            line-height: 2.3rem;
                            font-family: "Montserrat-Regular";
                            padding-top: 4.8rem;
                            text-align: left;
                            padding-right: 6rem;
                            border-top: 2px solid $colorCorporative;

                            &::before {
                                content: "";
                                position: absolute;
                                width: 1.8rem;
                                height: 1.8rem;
                                border-radius: 50%;
                                background-color: $colorCorporative;
                                top: 0;
                                transform: translateY(-50%) translateX(0);
                            }
                            &:first-of-type {
                                text-align: left;
                                &:before {
                                    transform: translateY(-50%) translateX(0);
                                }
                            }
                            &:last-of-type {
                                text-align: right;
                                margin-right: 1.5rem;
                                padding-right: 0;
                                border-top: none;
                                &:before {
                                    left: 0;
                                    transform: translateY(-50%) translateX(0);
                                }
                                .point-item {
                                    text-align: left;
                                    margin-right: 1.5rem;
                                }
                            }
                            .point-item {
                                .item {
                                    color: $colorCorporative;
                                    font-family: "Montserrat-Bold";
                                }
                                .link {
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }
        }
        .section-limited {
            max-width: 100%;
        }

        .generic {
            color: #575252;
            font-size: 1.5rem;
            line-height: 2.3rem;
            font-family: "Montserrat-Regular";
            letter-spacing: 0;
        }
        .generic.text,
        .generic.text p,
        .generic,
        .generic.text * {
            font-size: 1.5rem !important;
            line-height: 2.3rem !important;

        }
        .text {
            &.isClose {
                overflow: hidden;
                height: calc(1.7 * 2.5rem * 10);
                transition: 2s height linear;
            }

            &.isOpen {
                height: auto;
                transition: 2s height linear;
            }
        }
    }
}
