/*** START OLD ***/
$input-border-radius:  0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;


/*** END OLD ***/


$headerHeight: 64rem;
$fontsizeMenuDesk1: 1.8rem;
$fontsizeMenuDesk2: 1.4rem;

$lineheight: 120%;

//Desktop
// in rem
$containerMaxWidth: 125.6rem;

$containerLateralMargin: 6.6rem;
//In mobile .container haven't laterals margins.

$fontStack1: "Montserrat", Arial, sans-serif;
$fontStack2: 'Arvo', Georgia, "Times New Roman", Times, serif;
$fontStack3: 'Roboto', verdana, arial;

//Section Title
$titleSize: 2rem;
$titleFont: $fontStack1;

//Text normal
$defaultFontSize: 1.4rem;
$f-size-menu-vertical: 1.3rem;

//z-index
$zmodal: 20;
$znormal: 10; 

$addPhotoPadding: 1.2rem;

//LOADER
$loadWidthMobile: 20rem;
$loadHeightMobile: 13.3rem;
$loadWidthDesk: 17.4rem;
$loadHeightDesk: 10.7rem;
$loadPadding: 20rem;

//Container

$sm-width: 768px;
$md-width: 991px;
$lg-width: 1441px;
$extra-width: 1920px;
$special-container-max-width: 1400px;


$grid-breakpoints: (
	xs: 0,
  	sm: 576px,
  	md: 769px,
	mdMax: 991px,
 	lg: 992px,
  	xl: 1200px
);
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1208px,
);

// Typography
$line-height-base: 1;
$font-family-sans-serif: 'Montserrat', sans-serif;
$font-size-base: 1.3rem !default; //10px
//$font-size-base: 0.5rem !default; //10px

$f-size-normal-body: rem(15px);
$f-size-big-title: rem(35px);
$f-size-title: rem(31px);
$f-size-medium: rem(25px);
$f-size-small: rem(13px);
$f-size-very-small: rem(11px);

//_2021
$f-size-normal-body_2021: 1.5rem;
$f-size-big-title_2021: 3.5rem;
$f-size-title_2021: 3.1rem;
$f-size-medium_2021: 2.5rem;
$f-size-small_2021: 1.3rem;
$f-size-very-small_2021: 1.1rem;


//Slick
$slick-dot-size: 8px;
$slick-dot-color: $white;
$slick-dot-color-active: $slick-dot-color;
$slick-opacity-default: 1;
$slick-opacity-not-active: 0.5;

//transitions
$t-fast: 0.2s;
$t-normal: 0.4s;
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);

// Empresa
$topSection: 15rem;


$screen-xlg-min: 1366px;

$screen-fhd-min: 1920px;

$screen-rt-min: 2560px;

$screen-rt15-min: 2880px;

$screen-uhd-min: 3840px;

$screen-4k-min: 4096px;

$screen-8k-min: 8192px;

$screen-lg-max:                     ($screen-xlg-min - 1);
$screen-xlg-max:                 ($screen-fhd-min - 1);
$screen-fhd-max:                ($screen-rt-min - 1);
$screen-rt-max:                ($screen-rt15-min - 1);
$screen-rt15-max:               ($screen-uhd-min - 1);
$screen-uhd-max:                  ($screen-4k-min - 1);
$screen-4k-max:                   ($screen-8k-min - 1);

$container-xlg-desktop:            ((1306px + $grid-gutter-width));
$container-xlg:                    $container-xlg-desktop;
$container-fhd-desktop:           ((1860px + $grid-gutter-width));
$container-fhd:                   $container-fhd-desktop;
$container-rt-desktop:           ((2500px + $grid-gutter-width));
$container-rt:                   $container-rt-desktop;
$container-rt15-desktop:          ((2820px + $grid-gutter-width));
$container-rt15:                  $container-rt15-desktop;
$container-uhd-desktop:             ((3840px + $grid-gutter-width));
$container-uhd:                     $container-uhd-desktop;
$container-4k-desktop:              ((4036px + $grid-gutter-width));
$container-4k:                      $container-4k-desktop;
$container-8k-desktop:              ((8132px + $grid-gutter-width));
$container-8k:                      $container-8k-desktop;


///Components variables
$topbar-height: rem(60px);
$topbar-height-sm: rem(60px);
$lateral-space: rem(50px);
$lateral-space-sm: rem(10px);
$header-detail-height: 100vh;
$header-detail-height-m: calc(100vh - #{$topbar-height});