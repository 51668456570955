.section-empresa.sofas{
    .wrap-quote{
        margin-bottom: 3.2rem;
        .quote{
            font-family: 'Montserrat-SemiBold';
            line-height: 4.1rem;
            font-size: 3.1rem;
            width: 100%;
            position: relative;
            padding-top: 2.6rem;
            padding-bottom: 2.6rem;
            padding-left: 4.5rem;
            padding-right: 4.5rem;
            text-align: center;
            color: #575252;                          

            &::before{                                  
                content: "“";
                font-size: 22.5rem;
                font-family: "Montserrat-Bold";
                position: absolute;
                line-height: .8;
                //transform: translateX(-1rem) translateY(50%);              
                left: 0;
                //height: calc(100% - 5.2rem);
                width: 9.9rem;
                color: $colorCorporative;
                opacity: .1;
                text-align: left;
            }
            &::after{
                content: "„";
                font-size: 22.5rem;
                font-family: 'Montserrat-Bold';
                position: absolute;
                line-height: .8;
                //transform: translateX(-2.5rem) translateY(72%);
                width: 9.9rem;
                color: $colorCorporative;
                opacity: .1;
                right: 0;
                bottom: 0;
                text-align: left;
                //height: calc(100% - 5.2rem);
            }
        }

    }
}

.section-empresa.sofas.sofas-design{
    &.section-two-paragraphs-quote-middle{
        &.sinImg{
            .wrap-content{
                padding-bottom: 0;
            }
        }
        .wrap-content{
            padding-bottom: 15.8rem;
            .part-left{
                .wrap-title{
                    width: 100%;
                    .title{
                        padding-right: 0;
                    }
                    .subtitle{
                        width: 100%;
                    }
                }
                .warp-content-text{
                    .part-left{
                        width: 100%;

                        .wrap-text{
                            margin-bottom: 3.2rem;                           
                        }
                        
                        .aux-slider{
                            &.mvl{
                                display: none;
                            }
                        }

                    }
                }
                .wrap-img.first{
                    width: calc(100% + 3rem);
                    margin-top: .8rem;
                    .img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .part-right{
                padding-left: 6.5rem;
                display: flex;
                flex-direction: column;
   
                .wrap-img.second{
                    width: calc(100% + 9.5rem);
                    transform: translateX(-9.5rem) translateY(10%);
                    .img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    &.js-scrollMuve{
                        .foto-muve3{
                            transform: translateY(30%);
                            transition: .2s all linear;
                        }                            
                    }
                }
            }
            .aux-slider{
                .wrap-slider{
                    width: calc(100% + 3.5rem);

                    .aux-box{
                        padding-bottom: 3.7rem;
                    }
                    .slider-design.slick-slider{
                        .slick-list{
                            padding: 0 !important;
                            cursor: grab;                                
                            &.dragging, &.draggable:active:hover {
                                cursor: grabbing;
                            }
                            .slick-slide{                               
                                .item{
                                    position: relative;
                                    padding-top: 134.5%;                                    
                                    .img{
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }
                        }
                    }
                }
                &.desk{
                    flex-grow: 1;
                }
            }
           
        }
       
    }

}


//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {
    .section-empresa.sofas.sofas-design{
        &.section-two-paragraphs-quote-middle{
            .wrap-content{
                padding-bottom: 0;
                flex-direction: column;
                .generic.text{
                    font-size: 1.7rem;
                    line-height: 2.5rem;
                    color: #000;
                }
                
                .part-left{
                    width: 100%;            
                    .warp-content-text{
                        .part-left{
                            width: 100%;
    
                            .wrap-text{
                                margin-bottom: 3.7rem;                           
                            }                          
                        }               
                    }
                    .wrap-img.first{                             
                        width: 100%;
                        padding-right: 2.5rem;       
                    }
                }
                .part-right{
                    width: 100%;              
                    margin-top: -4rem;
                    padding-left: 4rem;            

                    .wrap-img.second{
                        width: 100%;
                        transform: translateX(0);
                        margin-top: -2rem;
                        .img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                        &.js-scrollMuve{
                            .foto-muve3{
                                transform: translateY(10%) !important;
                                transition: .7s all linear;
                            }                            
                        }
                    }
                }
            
            .aux-slider{                               
                &.mvl{
                    display: block !important;
                    margin-bottom: 4.5rem;
                    .wrap-slider{
                        .aux-box{
                            padding-left: 2rem;
                            padding-right: 1.5rem;
                            padding-bottom: 2.5rem;
                        }
                        width: 100%;    
                        .slick-slide{
                            .slick-list{
                                .slick-slide{
                                    .item{
                                        margin-right: 1rem !important;
                                    }
                                }
                            }
                        }
                    }
                    .slick-slider .slick-list .slick-track .slick-slide .item {
                        margin-right: 1rem !important;
                    }
                }
                &.desk{                       
                    display: none !important;
                }
              
            }
           
        }
    
    }

}
    .section-empresa.sofas{
        .wrap-quote{
            margin-bottom: 8.5rem; 
            .quote{
                font-size: 2.5rem; 
                padding: 2rem 3.5rem;
                &::after,
                &::before{
                    font-size: 15.5rem;
                    //transform: translateX(-1rem) translateY(72%);
                }
            }
        }
    }
}