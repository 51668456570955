.section-empresa.sofas.sofas-philosophy{
    &.section-two-columns-philosophy{
        .wrap-content{
            .up{
                margin-bottom: 6.2rem;
                .part-up{  
                    padding-bottom: 2.5rem;          
                    .part-left{
                        width: 50%;
                        .wrap-title{
                            width: 100%;
                        }
                    }          
                    .part-right{
                        .wrap-img{
                            width: 21.3rem;
                            height: 19.8rem;
                            margin: 0 auto;
                            position: relative;
                            .img{                               
                                width: 100%;
                                height: 100%;
                                background-position: center;
                                background-size: contain;
                                background-repeat: no-repeat;
                                position: absolute;
                                top: 0;
                                left: 0;
                                object-fit: contain;
                            }
                        }
                    }
                }
                .part-down{
                    .wrap-img{
                        padding-top: 39%;
                        position: relative;
                        width: 100%;
                    }
                }
            }
            .center{                
                column-count: 2;
                column-gap: 5rem;
                .img{
                    margin-top: 2.5rem;
                }
            }

        }
    }

    &.section-one-paragraph-title-subtitle-link-img{
        .wrap-content{
            .wrap-img{
                width: 65%;
                .img{
                    width: 100%;
                    object-fit: contain;
                }
            }
        
            .box-muve{
                display: flex;
                justify-content: flex-end;
                margin-top: -10%;               
                transition: .3s all linear;
            }
            .part-green{
                align-self: self-end;
                padding: 5.1rem;
                width: 57%;
                background-color: $colorCorporative;
                color: #fff;              
             
                .subtitle{
                    width: 100%;
                    color: inherit;
                    padding-bottom: 0;
                }
                .text, .text *{
                    color: inherit !important;
                    padding-top: 1.7rem;
                    &.generic{
                        color: inherit;
                    }
                }        
                .wrap-link{
                    color: inherit;
                    padding-top: 4.5rem;
            
                    .link{
                        position: relative;
                        color: inherit;
                        &::before{
                            content: '';            
                            background-image: url('/images/arrow-white-right.svg');
                            width: 2rem;      
                        }  
                        a{
                            color: inherit;
                        } 
                        &:hover{                                    
                            &::before{
                                transform: translateX(3.5rem) scale(1.1);
                                transition: .3s transform linear;
                            }
                        }
                    }
                }
            }
        }
    }   
}

//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {

    .section-empresa.sofas.sofas-philosophy{
        &.section-two-columns-philosophy{
            .wrap-content{
                .generic.text, .generic.text p, .generic{
                    color: inherit;
                    font-size: 1.7rem;
                    line-height: 2.5rem;
                }
                .up{
                    margin-bottom: 6.2rem;
                    .part-up{  
                        padding-bottom: 2.5rem;          
                        .part-left{
                            width: 100%;
                            .wrap-title{
                                width: 100%;
                                .title{
                                    padding-top: 7.5rem;
                                }
                            }
                        }          
                        .part-right{
                            width: 100%;
                            position: absolute;
                            .wrap-img{
                                width: 14.1rem;
                                height: 13.1rem;
                                margin: 0 auto;
                                margin-right: 3.2rem;
                                position: relative;                           
                            }
                        }
                    }
                    .part-down{
                        width: 100vw;
                        left: -1.5rem;
                        position: relative;
                        .wrap-img{
                            padding-top: 50%;                                                 
                            width: 100%;                            
                            position: relative;
                        }
                    }
                }
                .center{                
                    column-count: 1;
                    column-gap: 0;
                    .img{
                        margin-top: 2.5rem;
                        width: 100vw;
                        left: -1.5rem;
                        position: relative;
                    }
                }
    
            }
        }
    
        &.section-one-paragraph-title-subtitle-link-img{
            .wrap-content{
                .generic.text, .generic.text p, .generic{
                    color: inherit;
                    font-size: 1.7rem;
                    line-height: 2.5rem;
                }
                .wrap-img{
                    width: calc(100% - 2.8rem);
                    .img{
                        width: 100%;
                        object-fit: contain;
                    }
                }
                
                .box-muve{
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 1.5rem;
                    transform: translateY(0) !important;                    
                    transition: .3s all linear;
                }
                .part-green{
                    align-self: self-end;
                    padding: 3.2rem 2.2rem;
                    width: calc(100% - 2.8rem);
                    background-color: $colorCorporative;
                    color: #fff;
                                   
                    .subtitle{
                        width: 100%;
                        color: inherit;
                        padding-bottom: 0;
                        font-size: 1.7rem;
                        line-height: 3rem;
                        letter-spacing: 1.5px;
                    }
                    .text{
                        color: inherit !important;
                        padding-top: 1.7rem;
                        &.generic{
                            color: inherit;
                        }
                    }  
                }
            }
        }   
    }
}