.section-empresa.sofas.sofas-rscVerticalBlocks{
    &.section-rsc-vertical-blocks{
        padding-top: 0;
        .wrap-content{
            .wrap-title{
                width: 100%;
                padding-bottom: 5.9rem;
                .title{
                    text-align: center;
                    font-size: 2.5rem;
                    padding-right: 0;
                    line-height: 3rem;
                    letter-spacing: 2.21px;
                    font-family: 'Montserrat-ExtraBold';
                }
            }
            .wrap-list{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                &:before, &:after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: 2.9rem;
                    height: 2.9rem;
                    border-radius: 50%;
                    background-color: $colorCorporative;
                    transform: translateX(-50%);
                    z-index: 1;
                }
                &:after{
                    bottom: 0;
                    top: unset;
                    left: 50%;
                }
                .item{
                    width: 100%;
                    display: flex;
                    .item-content{
                        padding-left: 2rem;
                        padding-top: 4rem;
                        position: relative;
                        width: 50%;

                        .num{
                            font-size: 6.1rem;
                            color: rgba(17, 151, 165, 0.22);
                            font-family: 'Montserrat-SemiBold';
                            letter-spacing: 0;
                            top: 0;
                            position: absolute;
                            left: 0;
                        }
                        .wrap-title{
                            padding-bottom: 0;
                            .title{
                                text-align:left;
                                font-size: 2.5rem;
                                text-transform: uppercase;
                                line-height: 3rem;
                                letter-spacing: 2.21px;
                                font-family: 'Montserrat-Medium';
                                color: $colorCorporative;
                                padding-bottom: 0;
                                width: fit-content;
                                background-color: #e1f4f7;

                            }
                        }
                        .text{
                            padding-bottom: 2.6rem;
                            padding-top: 2.6rem;
                        }

                    }
                    &.left{
                        justify-content: flex-start;
                        .item-content{
                            border-right: 1px solid #8fc3c9;
                            padding-right: 9.6rem;
                            overflow: hidden;
                            .num{
                                right: 7.6rem;
                                z-index: 1;
                            }

                            .aux-line{
                                width: auto;
                                height: 100%;
                                &::after{
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    width: 100%;
                                    background-color: $colorCorporative;
                                    height: 1px;
                                }
                            }
                            .wrap-title{
                                .title{
                                    padding-right: 2.5rem;
                                    position: relative;
                                    z-index: 1;
                                }
                            }

                        }
                        &:first-of-type{
                            .item-content{
                                padding-top: 9rem;
                                .num{
                                    top: 5rem;
                                }
                            }
                        }

                    }
                    &.right{
                        justify-content: flex-end;
                        .item-content{
                            border-left: 1px solid #8fc3c9;
                            transform: translateX(-1px);
                            padding-left: 9.6rem;
                            .num{
                                left: 7.6rem;
                            }
                            .wrap-title{
                                .title{
                                    background-color: unset;
                                    &::after{
                                        content: '';
                                        position: absolute;
                                        top: 50%;
                                        width: 7rem;
                                        background-color: $colorCorporative;
                                        height: 1px;
                                        right: unset;
                                        left: -9.6rem;
                                    }
                                }
                            }
                        }
                        &:first-of-type{
                            .item-content{
                                padding-top: 9rem;
                                .num{
                                    top: 5rem;
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}

//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {
    .section-empresa.sofas.sofas-rscVerticalBlocks{
        &.section-rsc-vertical-blocks{
            padding-top: 0;
            .wrap-content{
                .generic.text, .generic.text p, .generic{
                    color: inherit;
                    font-size: 1.7rem;
                    line-height: 2.5rem;
                }
                .wrap-title{
                    width: 100%;
                    padding-bottom: 0;
                    .title{
                        font-size: 1.7rem;
                        line-height: 3rem;
                        letter-spacing: 1.5px;
                        text-align: left;
                        width: 90%;
                        padding-bottom: 0;
                    }
                }
                .wrap-list{
                    &:before, &:after{
                        content: none;
                    }
                    .item{
                        width: 100%;
                        display: flex;
                        margin-bottom: 5rem;
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                        .item-content{
                            padding-left: 2rem;
                            padding-top: 4rem;
                            position: relative;
                            width: 100%;
                            padding-right: 2.5rem;
                            .num{
                                font-size: 6.1rem;
                                color: rgba(17, 151, 165, 0.22);
                                font-family: 'Montserrat-SemiBold';
                                letter-spacing: 0;
                                top: 0;
                                position: absolute;
                                left: 0;
                            }
                            .wrap-title{
                                padding-bottom: 0;
                                .title{
                                    text-align:left;
                                    font-size: 2.5rem;
                                    text-transform: uppercase;
                                    line-height: 3rem;
                                    letter-spacing: 2.21px;
                                    font-family: 'Montserrat-Medium';
                                    color: $colorCorporative;
                                    padding-bottom: 0;
                                    width: 100%;
                                    background-color: #e1f4f7;

                                }
                            }
                            .text{
                                padding-bottom: 2.6rem;
                                padding-top: 2.6rem;
                            }
                            .wrap-link{
                                width: 90%;
                                .link{
                                    &::before{
                                        height: 1.9rem;
                                        bottom: 0;
                                    }
                                }
                            }
                        }
                        &.left{
                            justify-content: flex-start;
                            .item-content{
                                border-right: none;
                                padding-right: 0;
                                overflow: hidden;
                                padding-right: 2.5rem;
                                .num{
                                    right: 7.6rem;
                                    z-index: 2;
                                }

                                .aux-line{
                                    display: none;
                                }
                                .wrap-title{
                                    .title{
                                        padding-right: 0;
                                        position: relative;
                                        z-index: 1;
                                    }
                                }

                            }
                            &:first-of-type{
                                .item-content{
                                    padding-top: 9rem;
                                    .num{
                                        top: 5rem;
                                    }
                                }
                            }
                        }
                        &.right{
                            justify-content: flex-end;
                            .item-content{
                                border-left: none;
                                transform: translateX(0);
                                padding-left: 2rem;
                                padding-right: 2.5rem;
                                .num{
                                    left: 0;
                                }
                                .wrap-title{
                                    .title{
                                        background-color: unset;
                                        &::after{
                                            content: none;
                                        }
                                    }
                                }
                            }
                            &:first-of-type{
                                .item-content{
                                    padding-top: 9rem;
                                    .num{
                                        top: 5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
