.hashtags{
    .hashtags-header{
        font-size: rem(17px);
        margin-bottom: rem(20px);
        font-weight: bold;
    }

    .hashtags-body{
        display: flex;
        flex-wrap: wrap;
        padding-bottom: rem(11px);
    }

    .hashtag{
        background-color: white;
        color: $colorCorporative;
        white-space: nowrap;
        font-size: rem(12px);
        padding: rem(6px) rem(12px);
        border-radius: rem(6px);
        margin-right: rem(8px);
        margin-bottom: rem(8px);
        transition: background-color $t-fast linear;
        &:hover {
            background-color: #f4f4f4;
        }
        &:before{
            content: '#';
        }

        &.is-active{
            background-color: $colorCorporative;
            color: $white;
            pointer-events: none;
        }
    }
}

//FAMA NEW
.app-list_2021{
    .hashtags{
        .hashtags-header{
            font-size: 1.7rem;
            margin-bottom: 2rem;         

            .hashtags-title{
                color: #575252;
            }
        }

        .hashtags-body{           
            padding-bottom: 1.1rem;     
        }

        .hashtag{          
            font-size: 1.2rem;
            padding: .6rem 1.2rem;
            border-radius: .6rem;
            margin-right: .8rem;
            margin-bottom: .8rem;      
        }
    }
}



