.competitions_2021{
    .wrap-list-competitions{
        padding-top: 7rem;
    }
    .limited-wrap{
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 5rem;
    }
    
    .section-title{
        padding-left: 5rem;
        &.final{
            padding: 5rem 0 0 6rem;
            padding-top: 0;
        }
        .title{
            font-size: 3.5rem;
            text-transform: none;
            font-family: "Montserrat-SemiBold";
            font-weight: normal;
            line-height: 4.3rem;
            text-align: left;
        }
        .title-underline{
            width: 3.5rem;
            border-bottom: 4px solid;
            background: $colorCorporative;
            margin-top: 0.5rem;
            margin-bottom: 4rem;
    
        }
        
    }
    .all-competitions{
        .competition-item.comp-photos:not(:first-of-type){               
            background-color: #e8f1f3;
            margin-bottom: 0;
            padding-bottom: 9rem;   
        }
        .competition-item:nth-child(2){
            padding-top: 9rem !important;
        }
    }
    .competition-item{
        width: calc(100% + 10rem);
        transform: translateX(-5rem);
        min-width: 40rem;

        .wrap-item{
            padding: 4.3rem;
            color: #fff;
            position: relative;
            width: calc(100% - 5rem);
            .num{
                position: relative;
                font-family: 'Montserrat-Bold';
                font-size: 9rem;
                color: rgba(255,255,255,0.5);
                line-height: 0.7;
            }
            .wrap-content-item{
                font-family: 'Montserrat-Regular';
                font-size: 2.5rem;
                letter-spacing: 0;
                flex-direction: column;

                .part-left{
                    width: 60%;                  
                    display: flex;
                    flex-direction: column;

                    .wrap-btnDefault{
                        align-self: flex-start;
                    }
                }
                .part-right{
                    width: 40%;
                    flex-direction: column;
                    min-width: 49rem;

                    .wrap-img{
                        width: 100%;                      
                        .img{
                            width: 100%;
                            height: 100%;                           
                            object-fit: contain;
                            object-position: top;
                        }
                    }                    
                }
                .capture-img{
                    font-size: 1.5rem;
                    line-height: 2.3rem;
                    letter-spacing: 0;
                    padding-right: 4.3rem;
                    padding-left: 5rem;
                    padding-top: 1rem;
                    padding-bottom: 3.5rem;

                    &.mvl{
                        display: none;
                    }
                }
                .title{
                    font-family: 'Montserrat-SemiBold';
                    font-size: 4.1rem;
                    letter-spacing: 0;
                    margin-bottom: 3rem;
                }
                .subtitle{
                    font-family: 'Montserrat-Bold';
                    font-size: 2.5rem;
                    letter-spacing: 0;
                }
                .description{
                    font-family: 'Montserrat-Regular';
                    font-size: 2.5rem;
                    letter-spacing: 0;
                    margin-bottom: 3rem;
                }
            }
         
            .wrap-btnDefault{         
                border: 1px solid #fff;    
                min-width: unset; 
                .btnDefault{
                    background-color: #fff;
                    color: $colorCorporative;
                    font-size: 1.5rem;
                    letter-spacing: .3rem;
                    line-height: 1.7rem;
                    padding: 1.4rem 2.5rem;
                    &.mvl{
                        display: none;
                    }                

                }
            }         
        }
        &.comp-microrelatos, &.comp-videos{
            background-color: #e8f1f3;  
        }
        &.comp-videos:first-of-type{
            padding-top: 9rem;
        }
        &.comp-photos{
            margin-bottom: 9.5rem;        

    
            .section-title{
                padding-left: 5rem;
                .title-underline{
                    margin-bottom: 10rem;
                }
            }
            .wrap-item{
                padding: 0 4.3rem 4.3rem 0;
                background-color: $colorCorporative;
                .num{
                    text-align: right;                   
                }
                .wrap-content-item{
                    .title{
                        width: 50%;
                    }
                    .description{
                        padding-right: 10rem;
                    }              
                    .part-down{
                        width: 100%;
                        justify-content: space-between;
                      
                        .wrap-btn{
                            width: 40%;
                            padding-right: 5rem;
                            padding-left: 4.3rem;
                            min-width: 49rem;
                        }
                        .num{
                            width: 60%;
                            align-self: flex-end;
                        }
                    }
                    .part-right{
                        flex-direction: column;
                        .wrap-img{    
                            margin-top: -2.5rem;
                            margin-left: -5rem;              
                        }
                        .wrap-btnDefault{
                            margin-left: 4.3rem;
                            margin-right: 5rem;
                        }
                    }
                    .part-left{
                        padding-top: 4.3rem;        
                        .wrap-btnDefault{
                            align-self: flex-start;                            
                        }                
                    }
                }
            }      
           
        }
        &.comp-videos{
            padding-bottom: 15rem;
            .wrap-item{
                background-color: #6b9195;
                padding-right: 0;
                padding-bottom: 0;               

                .wrap-content-item{
                    .part-up{
                        .part-left{
                            order: 1;    
                            width: 40%; 
                            padding-bottom: 10rem;

                            .title{
                                white-space: nowrap;
                            }
                        }
                        .part-right{
                            width: 50%;
                            order: 2;                           
                            position: absolute;                          
                            top: unset;
                            right: 0;

                            .wrap-img{
                                width: 100%;
                                height: 100%;
                                transform: translate(5rem, 10rem);
                                .img{
                                    width: 100%;
                                    height: 100%;    
                                    object-fit: contain;     
                                    object-position: top;
                                    padding-left: 5rem;
                                }
                            }
                        }
                    }
                    .part-down{
                        position: absolute;
                        right: 4.3rem;
                        top: 4.3rem;
                    }
                    .title{
                        width: 100%;
                    }
                }
                .wrap-btnDefault{
                    .btnDefault{
                        color: #6b9195;
                    }
                }   
            }  
        }

        &.comp-microrelatos{
            padding-bottom: 14rem;
            .wrap-item{
                background-color: #177f8a;
                padding-bottom: 8rem;

                .num{
                    text-align: right;
                }
                .wrap-content-item{
                    .part-up{
                        .part-left{
                            order: 1;
                        }
                        .part-right{
                            order: 2;
                            top: 4.3rem;
                            position: absolute;
                            right: 4.3rem;
                        }
                    }
                    .part-down{
                        position: absolute;
                        right: 4.3rem;
                        bottom: 4.3rem;
                    }
                    .description{
                        width: 80%;
                    }
                }
                .wrap-btnDefault{
                    .btnDefault{
                        color: #177f8a;
                    }
                }  
            }
        }
        &.odd{     
            .wrap-item{
                margin: 0 auto;
                margin-right: 0;
            }            
        }
        &.even{     
            .wrap-item{
                margin: 0 auto;
                margin-left: 0;
            }            
        }
    }

    &.old_2021{
        .limited-wrap{
            width: 100%;
            max-width: 1180px;
            margin: 0 auto;
            padding: 0;
        }
        .old-competitions{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 1.8rem);
            padding-bottom: 13.2rem;

            .wrap-btnDefault{         
                border: 1px solid #fff;    
                min-width: unset; 
                margin: 0 auto;
                

                .btnDefault{
                    background-color: #fff;
                    color: $colorCorporative;
                    font-size: 1.5rem;
                    letter-spacing: .3rem;
                    line-height: 1.7rem;
                    padding: 1.4rem 2.5rem;

                    font-size: 1.3vh;
                    line-height: normal;
                }
            } 
        }
        .competition-item{
            width: calc(100% / 4);
            min-width: unset;
            transform: none;
            padding-right: 1.8rem;
            margin-bottom: 1.8rem;
            position: relative;           

            .wrap{
                width: 100%;
                height: 100%;
                position: relative;
                aspect-ratio: 1/1;
                padding: 3rem;
                display: flex;
                flex-direction: column;
                background-color:#6b9195;

                .title{
                    position: relative;
                    color: #fff;
                    z-index: 1;
                    font-family: 'Montserrat-SemiBold';
                    width: 100%;
                    font-size: 2.3vh;
                    flex-grow: 1;
                    opacity: 1;
                    transition: .2s opacity ease;
                }
                
                .backgroundImg{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                .hoverbg{
                    background-color: rgba(0, 166, 184, 0.49);
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    z-index: -1;
                    transition: .2s all ease;
                }
                &:before{
                    content: '';
                    background-color: rgba(107, 145, 149, 0.5);
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                }

                &:hover{
                    .title{
                        opacity: 0;
                        transition: .2s opacity ease;
                    }
                    .hoverbg{
                        opacity: 1;
                        z-index: 1;
                        transition: .2s all ease;
                        flex-direction: column;                   
                        padding: 1rem;
                        padding-bottom: 3rem;
                        transition: .2s opacity ease;

                        .aux{
                            flex-grow: 1;
                        }
                    }
                }
                &.notFinished{
                    &:hover{
                        .title{
                            opacity: 1;
                        }
                    }
                    .title{
                        font-size: 2.7vh;
                    }
                    background-color: $colorCorporative;
                    &:before{
                        content: none;
                    }
                }
            }
       
        }
    }    
    &.container-content{
        @media(min-width: 768px) and (max-width: 991px){
            overflow-x: hidden;
        }
    }
}

@media (max-width: 1200px){
    .competitions_2021 .competition-item.comp-photos .wrap-item .wrap-content-item .title{
        width: 100%;
    }

}

@media(max-width: $md-width){
    .competitions_2021.old_2021{
        .limited-wrap{
            width: 100%;
            max-width: 1180px;
            margin: 0 auto;
            padding: 0;
        }
        .old-competitions{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 1.8rem);
            padding-bottom: 7rem;

            .wrap-btnDefault{         
              
                .btnDefault{
                    background-color: #fff;
                    color: $colorCorporative;
                    font-size: 1.5rem;
                    letter-spacing: .3rem;
                    line-height: 1.7rem;
                    padding: 1.4rem 2.5rem;                    
                }
            } 
        }
        .competition-item{
            width: calc(100% / 2);
          
            .wrap{
                width: 100%;
                height: 100%;
                position: relative;
                aspect-ratio: 1/1;
                padding: 1.5rem;
              
                .title{  
                    font-size: 2.7rem;                   
                }
      
                .hoverbg{
                    display: none !important;                   
                }       
 
                &.notFinished{                  
                    .title{
                        font-size: 3.1rem;
                    }               
                }
                &:hover{
                    .title{
                        opacity: 1;
                    }
                }
            }
       
        }
    }    
}

@media (max-width: $sm-width){  
    .competitions_2021{
        .wrap-list-competitions{
            padding-top: 0;
        }
        .limited-wrap{
            width: 100%;
            max-width: 100%;           
            padding: 0;
        }
        
        .section-title{
            padding-left: 0;
            &.final{
                padding: 6.4rem 45% 0 0;
                padding-top: 0;
            }
            .title{
                font-size: 2.5rem;               
                line-height: 3.8rem;              
            }
            .title-underline{              
                border-bottom: 2px solid;               
        
            }
            
        }
        .all-competitions{       
            .competition-item:nth-child(2){
                padding-top: 6rem !important;
            }
        }
        .competition-item{
            width: 100%;
            transform: unset;
            min-width: unset;
    
            .wrap-item{
                padding: 3rem 1.5rem;          
                width: 100%;
                .num{                
                    font-size: 11rem;                   
                }
                .wrap-content-item{               
                    font-size: 1.9rem;   
                    flex-direction: column;      
                    .part-left{
                        width: 100%;                  
                        display: flex;
                        flex-direction: column;
    
                        .wrap-btnDefault{
                            align-self: flex-start;
                        }
                    }
                    .part-right{
                        width: 100%;
                        flex-direction: column;
                        min-width: unset;                      
                    }
                    .capture-img{                     
                        padding-right: 0;
                        padding-left: 0;                  
                        padding-bottom: 2.5rem;

                        &.mvl{
                            display: block;
                        }
                        &.desc{
                            display: none;
                        }
                    }
                    .title{                      
                        font-size: 3.1rem;                      
                        margin-bottom: 2.5rem;
                    }
                    .subtitle{
                        font-family: 'Montserrat-Bold';
                        font-size: 2.1rem;
                        letter-spacing: 0;
                    }
                    .description{      
                        width: 95%;        
                        font-size: 1.9rem;                
                        margin-bottom: 2.5rem;
                    }
                } 
                
                .wrap-btnDefault{
                    .btnDefault{
                        padding: 1.4rem;
                        &.mvl{
                            display: block;
                        }
                        &.desc{
                            display: none;
                        }
                    }
                }
                
            }
         
            &.comp-photos{
                margin-bottom: 6.5rem;
                .section-title{
                    padding-left: 0;
                    .title-underline{
                        margin-bottom: 3rem;
                    }
                }
                .wrap-item{
                    padding: 3rem 1.5rem;
                    background-color: $colorCorporative;
                    .num{
                        text-align: right;                   
                    }
                    .wrap-content-item{
                        .title{
                            width: 100%;
                            margin-bottom: 3.5rem;
                        }
                        .description{
                            padding-right: 0;
                        }
                        .part-up{
                            flex-direction: column;
                        }
                        .part-down{
                            width: 100%;
                            justify-content: space-between;
                            flex-direction: column;
                          
                            .wrap-btn{
                                width: 100%;
                                padding-right: 0;
                                padding-left: 0;
                                min-width: unset;  
                                display: flex;        
                                flex-direction: column;
                                .wrap-btnDefault{
                                    align-self: flex-start;
                                    .btnDefault{
                                        padding: 1.5rem 2.5rem;
                                    }
                                }                      
                            }
                            .num{
                                width: 100%;
                                align-self: flex-end;
                                margin-top: 5rem;
                            }
                        }
                        .part-right{
                            order: 2;
                            flex-direction: column;
                            .wrap-img{    
                                margin-top: 0;
                                margin-left: 0;  
                                .img{
                                    padding-bottom: 2.5rem;
                                }            
                            }
                            .wrap-btnDefault{
                                margin-left: 0;
                                margin-right: 0;
                            }
                        }
                        .part-left{
                            order: 1;
                            padding-top: 0;   
                            padding-bottom: 7rem;  

                            .wrap-btnDefault{
                                align-self: flex-start;                            
                            }                
                        }
                    }
                }           
            }
            &.comp-videos{
                width: calc(100% + 3rem);
                transform: translateX(-1.5rem);
                padding: 0 1.5rem;
                padding-bottom: 7rem;

                .wrap-item{
                    background-color: #6b9195;
                    padding-right: 0;
                    padding-bottom: 0;
                    margin-bottom: 0 !important;
                    padding: 3rem 1.5rem;
    
                    .wrap-content-item{
                        .part-up{
                            flex-direction: column;
                            .part-left{
                                order: 1;    
                                width:100%; 
                                padding-bottom: 5rem;
    
                                .title{
                                    white-space: unset;
                                    width: 55%;
                                }
                            }
                            .part-right{
                                width: 100%;
                                order: 2;                           
                                position: relative;                          
                                top: unset;
                                right: 0;
    
                                .wrap-img{
                                    width: 100%;
                                    height: 100%;
                                    transform: unset;                                  
                                    .img{
                                        width: 100%;
                                        height: 100%;    
                                        object-fit: contain;     
                                        object-position: top;
                                        padding-left: 0;
                                        padding-bottom: 5rem;
                                    }
                                }
                            }
                        }
                        .part-down{
                            position: relative;
                            right: 0;
                            top: unset;
                        }
                        .title{
                            width: 100%;
                        }
                    }
                    .wrap-btnDefault{
                        .btnDefault{
                            color: #6b9195;
                            padding: 1.4rem 4.5rem;
                        }
                    }   
                }  
            }
    
            &.comp-microrelatos{           
                width: calc(100% + 3rem);
                transform: translateX(-1.5rem);
                padding: 3rem 1.5rem;
                padding-bottom: 5rem;
                .wrap-item{
                    background-color: #177f8a;    
                    padding-bottom: 3rem;

                    .num{
                        text-align: right;
                    }
                    .wrap-content-item{
                        flex-direction: column;
                        .part-up{
                            flex-direction: column;
                            .part-left{
                                order: 1;
                            }
                            .part-right{
                                order: 2;
                                top: unset;
                                position: relative;
                                right:0;
                            }
                        }
                        .part-down{
                            position: relative;
                            right: 0;
                            bottom: 0;
                            justify-content: flex-start;

                            .num{
                                padding-top: 10rem;
                            }
                        }
                        .description{
                            width: 100%;
                        }
                    }
                    .wrap-btnDefault{
                        .btnDefault{
                            color: #177f8a;
                            padding: 1.4rem 4.5rem;
                        }
                    }  
                }
            }
            &.odd{     
                .wrap-item{
                    margin: 0 auto;
                    margin-right: 0;
                }            
            }
            &.even{     
                .wrap-item{
                    margin: 0 auto;
                    margin-left: 0;
                }            
            }
        }

        &.old_2021{
            .limited-wrap{
                width: 100%;
                max-width: 100%;              
            }
            .old-competitions{                              
                width: 100%;
                padding-bottom: 7rem;    
            }
            .competition-item{
                width: 100%;
                min-width: unset;
                transform: none;
                padding-right: 0;
                margin-bottom: 1.8rem;
                position: relative;           
    
                .wrap{
                    width: 100%;
                    height: 100%;
                    position: relative;
                    aspect-ratio: 3/2;
                    padding: 2.5rem;     
                  
                }
           
            }
        }   
    
    }
}