.container.home-blog{
    padding-bottom: 13rem;
    .box-wall{        
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        height: auto;
        height: 70.9rem;
        padding-top: 6.1rem;
    }
    .section-title {
        color: #fff;
    }
    .line-separation{
        .line{
            background-color: #fff;
        }
    }
    .section-subtitle{
        color: #fff;
        -webkit-text-stroke: unset;
    }
    .container-blog{
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        margin-top: -25rem;

        .box-container-blog{
            .autor{
                margin-top: .4rem;
                font-family: 'Montserrat-Italic';
                color: #575252;
            }

            .wrap-btnDefault{

                .btnDefault{
                    padding: 1.4rem 7rem;
                }
            }
            .content-blog{
                width: 83.5%;
                margin: 0 auto;
                background-color: #fff;
                padding: 4rem 4.2rem;
                padding-bottom: 4.5rem;

                .wrap-items{
                    flex-wrap: nowrap;
                    padding-top: 0;
                    width: calc(100% + 2.2rem) !important;

                    .wrap-item{
                        .wrap-img{
                            padding-top: 64%;
                            position: relative;

                            .img{
                                width: 100%;
                                left: 0;
                                position: absolute;
                                top: 0;
                                object-fit: cover;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

}

@media (max-width: $md-width) {
    .container.home-blog{
        padding-bottom: 13rem;
        padding-top: 0;
        .box-wall{
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
            height: 44.8rem;
            padding-top: 2.5rem;
        }
        .section-title {
            font-size: 6rem;
            color: #fff;
        }
        .line-separation{
            .line{
                background-color: #fff;
            }
        }
        .section-subtitle{
            color: #fff;
            -webkit-text-stroke: unset;
        }
        .container-blog{
            width: 100%;
            max-width: 100%;
            margin: 0 auto;
            margin-top: -15rem;

            .box-container-blog{
                .wrap-btnDefault{
                    .btnDefault{
                        padding: 1.2rem 6rem;
                    }
                }
                .content-blog{
                    width: calc(100% - 3rem);
                    padding: 2.2rem 1.5rem;
                    padding-bottom: 2.6rem;

                    .wrap-items{
                        width: 100% !important;
                        flex-wrap: nowrap;
                        padding-top: 0;
                        overflow: hidden;

                        .wrap-item{
                            width: 0;
                            padding-right: 0;
                            display: none;
                            &.isFirst{
                                display: flex;
                                width: 100%;
                            }
                        }

                    }
                }
            }
        }
    }
}
