

.section-empresa.sofas.sofas-history{       
      &.section-history-timeline{         
        position: relative;    
        padding-top: 0;
        width: calc(100% + 5rem);
        padding-right: 5rem;      
        margin-top: 15rem;      

        .wrap-content-all{
            margin-top: -11rem;
        }
        .wrap-arrows{
            width: 5rem;           
            position: -moz-sticky;
            position: -o-sticky;
            position: -ms-sticky;
            position: sticky;
            position: -webkit-sticky;
            top: 75%;
            z-index: 100;
            transform: translateX(-10rem);
            display: flex;
            flex-direction: column;

            .arrow-up{
                width: 5rem;
                height: 5rem;
                position: relative;
                cursor: pointer;
                margin-bottom: 1rem;

                &:before{
                    content: '';
                    background-image: url('/images/arrow-timeline.svg');
                    position: absolute;
                    width: 100%;
                    height: 100%;          
                }
                &.isActive{
                    &:before{
                        content: '';
                        background-image: url('/images/arrow-timeline-active.svg');
                        position: absolute;
                        width: 100%;
                        height: 100%;                        
                    }
                }
            }
            .arrow-down{
                width: 5rem;
                height: 5rem;
                position: relative;
                cursor: pointer;

                &:before{
                    content: '';
                    background-image: url('/images/arrow-timeline.svg');
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    transform: rotate(180deg);
                }
                &.isActive{
                    &:before{
                        content: '';
                        background-image: url('/images/arrow-timeline-active.svg');
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        transform: rotate(180deg);
                    }
                }
           
            }

        }
        
        &::before{    
            content: "";
            width: calc(100% + 18vw + 8rem);
            position: absolute;
            left: 100%;
            transform: translateX(-100%);
            top: 15.6rem;
            height: 100%;
            background-color: #cee4e7;          
        }
        @media (min-width: calc(#{$extra-width} + 1px)) {
            &::before{    
                content: "";
                width: calc(100% + 25vw + 8rem);
                position: absolute;
                left: 100%;
                transform: translateX(-100%);
                top: 15.6rem;
                height: 100%;
                background-color: #cee4e7;
            }
        }
        .wrap-content{
            position: relative;     
       
            &::before{       
                content: "";
                width: calc(100% + 18vw + 13rem);
                position: absolute;
                left: calc(100% + 5rem);
                transform: translateX(-100%);
                top: 0;
                height: 100%;
                background-color: #cee4e7;
            }
      
            @media (min-width: calc(#{$extra-width} + 1px)) {
                &::before{       
                    content: "";
                    width: calc(100% + 25vw + 13rem);
                    position: absolute;
                    left: calc(100% + 5rem);
                    transform: translateX(-100%);
                    top: 0;
                    height: 100%;
                    background-color: #cee4e7;
                }
            }
            .wrap-timeline{                
                position: relative;
                padding-top: 5rem;
                max-width: 1260px;             
              
                .slider-timeline{                 
                    position: relative;
                    padding-right: 5rem;
                    margin-top: -14.6rem;
                    .slider-timeline-years{
                        .part-up{
                            .wrap-title{
                                .title{
                                    position: relative;              
                                    z-index: 2;
                                    padding-bottom: 1rem;
                                    font-size: 3.5rem;
                                    line-height: 4.5rem;
                                    letter-spacing: 0;
                                    color: $colorCorporative;
                                    padding-right: 0;
                                }
                            }
                        }
                    }            
  
                }
                .section-time{          
                    &.isActive{
                        .year{
                            opacity: 1;
                            transform: translate3d(0, 0, 0);
                            transition: .4s ease 1s;
                        }
                    }
                  
                    .year{
                        font-size: 18rem;
                        font-family: 'Montserrat-ExtraBold';
                        -webkit-text-stroke: 1px #575252;
                        color: transparent;
                        font-weight: 800;            
                    }
                    
                    .time-box{
                    
                        .box-img{
                            position: relative;
                            .wrap-img{
                                margin: 0 auto;
                                width: 50%;                               
                                margin-right: 0;
                                margin-bottom: 3rem;
                                .img{
                                    position: relative;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                    z-index: 2;

                                }
                                .yearEvent{
                                    position: absolute;
                                    top: 0;
                                    right: 0; 
                                    background-color: $colorCorporative;  
                                    color: #fff;           
                                    padding: 0.5rem 4.2rem;    
                                    z-index: 1;                  
                                }
                                .wrap-quote{
                                    position: absolute;
                                    display: block;
                                    left: 10%;
                                    width: 30%;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    color: $colorCorporative;
                                    font-family: 'Montserrat-Italic' !important;                                   
                                }
                                .caption-pendulum{
                                    display: block;
                                    position: absolute;
                                    width: 15%;
                                    height: 1rem;                               
                                    left: 35%;
                                    top: 20%;
                                    transform-origin: right center;
                                    transform: rotate(-30deg);
                                    .ball{
                                        display: block;
                                        position: absolute;
                                        width: 10px;
                                        height: 10px;
                                        top: 0;
                                        left: 0;
                                        border-radius: 10px;
                                        border: 1px solid rgba($colorCorporative, 0.5);
                                    }
                                    .line{
                                        display: block;
                                        position: absolute;
                                        width: calc(100% - 10px);
                                        height: 1px;
                                        top: 5px;
                                        left: 10px;
                                        background-color: rgba($colorCorporative, 0.5);
                                    }
                                }  
                            }
                            .wrap-text{
                                width: 50%;
                                margin: 0 auto;
                                margin-right: 0;
                            }
                        }
                        &.imageRight{
                            .box-aux{
                                display: flex;
                                justify-content: space-between;
                                .box-img{
                                    width: calc(50% - 6.7rem);
                                    display: flex;
                                    flex-direction: column;
                                    height: auto;
                                    .aux-img{
                                        flex-grow: 1;
                                    }
                                    .wrap-img{
                                        width: 100%;
                                        position: relative;
                                        .wrap-quote{
                                            left: unset;
                                            right: -100%;
                                            width: 90%;
                                            top: 100%;
                                            transform: translateX(30%) translateY(10rem);
                                        }
                                        .caption-pendulum{
                                            left: unset;
                                            right: 0;
                                            width: calc(6.7rem + 30%);
                                            top: 95%;
                                            transform: rotate(-135deg);
                                        }
                                    }
                                }
                                .part-up{
                                    width: 50%;
                                    .wrap-title, .part-left{
                                        width: 100%;
                                    }
                                    &.center{
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                    }
                                }
                            }
                            .box-text{
                                width: 50%;
                                padding-top: 3rem;
                            }

                        }
                    }        
                }               
            }
        
            
            .timeline {
                &.nav {    
                    position: -moz-sticky;
                    position: -o-sticky;
                    position: -ms-sticky;
                    position: sticky;
                    position: -webkit-sticky;
                    height: 100%;
                    z-index: 99;                
                    top: 11.8rem;                    
                    transition: top .3s ease-out;
                    padding-right: 5rem;
            
                    ul {
                        list-style: none;
                        list-style-position: none;                      
            
                        li {
                            font-family: 'Montserrat-Regular';                          
                            padding-left: 0;
                            list-style-type: none;
                            color: $colorCorporative;
                            opacity: .7;                           
                            cursor: pointer;
                            transition: all .3s ease-out;                          
                            height: calc((80vh - 11.8rem) / 8);
                            display: flex;
                            align-items: center;
                            
                            &.active {
                                font-family: 'Montserrat-Medium';                           
                                opacity: 1;
                                border-bottom: 1px dotted transparent;                              
                            }
                            
                            &:hover {
                                color: #575252;
                            }
                        }
                    }
                }
            } 
        }
    //     /**
    // *  Progress Bar
    // */
    .wrap-progress{
        width: 2px;      
        height: 100%;
        position: absolute;
        right: 2rem;
        background-color:rgba($colorCorporative, .5);
        background-image: linear-gradient(to right, $colorCorporative, $colorCorporative);
        background-repeat: no-repeat;
        background-size: 0 100%;   
    }
    .progress-timeline {        
        width: 100%;
        transition: background-size .4s ease-in-out;
        .line-inner{
            height: calc((80vh - 11.8rem) / 8);
            transition: .2s background-color linear;

            .line-content{
                height: 0;
                width: 100%;
                background-color: $colorCorporative;
            }

        }
    }
    // /**
    // *  END Progress Bar
    // */        


    }    

}

.section-empresa.sofas.sofas-history.section-history-timeline-mvl{       
    display: none;
}


//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {
    .section-empresa.sofas.sofas-history.section-history-timeline{       
        display: none;
    }

    .section-empresa.sofas.sofas-history.section-history-timeline-mvl{       
        width: 100%;
        display: flex;
        flex-direction: column;       
        .wrap-content-all{
            width: 100%;
            .wrap-content{
                width: 100%;
                .wrap-timeline{
                    width: calc(100% + 3rem);
                    position: relative;
                    left: -1.5rem;
                    max-width: calc(100% + 3rem);
                }
            }
        }
        

        .slider-timeline{   
            background-color: #cee4e7;          
            width: 100%;
            transform: translateY(8rem);          
            position: relative;
            padding-bottom: 7rem;
            padding-top: 5rem;
            
            .slick-arrow{
                z-index: 3;             
            }
    
            .slick-prev	{
                transform: translateX(5rem);
                left: unset;
                width: 6rem;
                height: 6rem;
                opacity: 1;
                top: 0;             
                right: 10rem;
                transform: translateY(-50%);

                &:before{
                    content: '';
                    background-image: url('/images/arrow-timeline-active.svg');
                    width: 5rem;
                    height: 5rem;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: 50%;
                    left: 50%;
                    transform: translateY(-50%) translateX(-50%) rotate(-90deg);
                }
    
            }
            .slick-next	{
                transform: translateX(-5rem);
                right: 0;			
                width: 6rem;
                height: 6rem;
                opacity: 1;   
                top: 0;
                transform: translateY(-50%) translateX(-1.5rem);

                &:before{
                    content: '';
                    background-image: url('/images/arrow-timeline-active.svg');
                    width: 5rem;
                    height: 5rem;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: 50%;
                    left: 50%;
                    transform: translateY(-50%) translateX(-50%) rotate(90deg);
                }
            }
            .slick-list{
                padding: 0 1.5rem;
            }
            .section-time{               
                width: 100%;                    
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                margin: 0;
                margin-right: 0;
                opacity: 1;
                transition: .7s opacity .1s ease;

                &.opacity-0-mvl {
                    opacity: 0;
                    transition: .7s opacity .1s ease;
                }
  
                .slider-timeline-years{
                    .part-up{
                        .wrap-title{
                            .title{
                                position: relative;              
                                z-index: 2;
                                padding-bottom: 1rem;
                                font-size: 2.5rem;
                                line-height: 3.3rem;
                                letter-spacing: 0;
                                color: $colorCorporative;
                                padding-right: 0;
                            }
                        }
                    }
                    .time-box{
                        margin-bottom: 4rem;
                        
                    }
                    .part-left{
                        width: 100%;
                        .text.generic{
                            font-size: 1.5rem !important;
                            line-height: 2.1rem !important;
                        }
                    }
                }  
                .year{
                    font-size: 33vw;
                    font-family: 'Montserrat-ExtraBold';
                    -webkit-text-stroke: 1px #000;                  
                    padding-top: 0;                        
                    color: #cee4e7;
                    -webkit-text-fill-color: #cee4e7;                 
                } 
                .box-img{
                    position: relative;
                    .wrap-img{  
                        width: 100%;                                                           
                        margin-right: 0;
                        margin-bottom: 3rem;                                                           
                        margin-top: 4.5rem;                        
                        padding-left: 0;
                        .img{
                            position: relative;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            z-index: 2;
                            margin-bottom: 2.5rem;

                        }
                        .yearEvent{
                            position: absolute;
                            top: 0;
                            right: 0; 
                            background-color: $colorCorporative;  
                            color: #fff;           
                            padding: 0.5rem 3.3rem;    
                            z-index: 2;    
                            font-size: 21.6px;     
                            transform: translateX(-1rem) translateY(-50%);         
                        }
                        .wrap-quote{                                     
                        display: block; 
                        color: $colorCorporative;
                        font-family: 'Montserrat-Italic' !important;             
                        left: 0;
                        position: relative;
                        width: 100%;
                        top: 0;
                        transform: unset;                                      
                        margin-bottom: 0;                      
                        }
                        .caption-pendulum{
                            display: none;                           
                        }   
                    }
                    .wrap-text{
                        width: 100%;                                
                    }
                }
            }
        }
    }
}

