// animations generics
.wrap-animate{
    .animate{
        opacity: 0;
        transform: translateY(-10rem);
        transition: .8s all linear;   
        &.isVisible{        
            opacity: 1;
            transform: translateY(8rem);
            transition: .8s all linear;   
        }
    }
}

.js-fade-animation{
    opacity: 0;  
    transition: 1s opacity linear;   
    &.isVisible{
        opacity: 1;   
        transition: 1s opacity linear;   
    }
}
.js-fade-img{   
    opacity: 0;  
    transition: .4s opacity linear;   
    img.prim{
        transform: translateY(100%);
        transition: 1.2s .2s all linear;  
    }
    &.isVisible{
        opacity: 1;   
        transition: .2s opacity linear;   
        img.prim{
            transform: translateY(0);
            transition: 1s .2s all linear;  
        }
        &.bgTransparent{
            background-color: transparent !important;   
            transition: .1s all linear;  
        }
    }
}

.js-fade-up-down.img{   
    height: 0 !important;    
    transition: .5s all linear;      
    background-color: #1197a5 !important;
    img.prim{
        height: 0 !important;      
        transition: 1.5s .5s all linear;  
    }
    &.isVisible{
        height: 100% !important;     
        transition: .5s all linear;   
        img.prim{
            height: 100% !important;
            transform: translateY(0);
            transition: 1.5s .5s all linear;  
        }
        &.bgTransparent{
            background-color: transparent !important;   
            transition: .2s all linear;  
        }
    }
}

.section-empresa{
    .js-fade-up-down.img{   
        @media (max-width: $md-width) {
            height: 100% !important;
            transition: none;
            img.prim{
                height: 100% !important;
            }
        }
    }
    .opacity-0 {        
        @media (max-width: $md-width) {
            opacity: 1;
            &.opacity-0-mvl{
                opacity: 0;
            }
        }
    }
}

.opacity-0 {
    opacity: 0;
}

.cssanimation {
	display: inline-block;
    animation-duration: 1.5s;
    animation-delay: .3s;
    animation-fill-mode: both;
    &.delay-2{
        animation-delay: .5s;
    }
    &.delay-3{
        animation-delay: .7s;
    }    
}
.fontSize-0{
    font-size: 0;
}
.cssanimationmvl {
	display: inline-block;
    animation-duration: 1.5s;
    animation-delay: 0;
    animation-fill-mode: both;
}
.cssanimationTranslate {
	display: inline-block;
    animation-duration: 1.4s;
    animation-delay: .5s;
    animation-fill-mode: both;
    &.delay-2{
        animation-delay: .7s;
    }
    &.delay-3{
        animation-delay: .9s;
    }  
}
@media (max-width: $md-width) {
    .section-empresa .cssanimationTranslate {
        display: inline-block;
        animation-duration: 0;
        animation-delay: 0;
        animation-fill-mode: none;
        &.delay-2{
            animation-delay: 0;
        }
        &.delay-3{
            animation-delay: .0;
        }        
    }
}

.cssanimationTranslateLeft {
	display: inline-block;
    animation-duration: .7s;
    animation-delay: .3s;
    animation-fill-mode: both;
    &.delay-2{
        animation-delay: .7s;
    }
    &.delay-3{
        animation-delay: .9s;
    }
    
}
.cssanimationUp {
	display: inline-block;
    animation-duration: 1.5s;
    animation-delay: .5s;
    animation-fill-mode: both;
    &.delay-2{
        animation-delay: .7s;
    }
    &.delay-3{
        animation-delay: 1.9s;
    }
    
}
.fadeInBottomMvl { 
    animation-name: fadeInBottomMvl; 
}

.fadeInBottom { 
    animation-name: fadeInBottom; 
}
.fadeInBottomTranslate { 
    animation-name: fadeInBottomTranslate;   
}

.fadeInRight { 
    animation-name: fadeInRight; 
    @media (max-width: $md-width) {
        animation-name: none;
    }
}
.section-empresa .fadeInBottom {     
    @media (max-width: $md-width) {
        animation-name: none;
    }
}

.section-empresa .fadeInBottom {     
    @media (max-width: $md-width) {
        animation-name: none;
    }
}

.section-empresa .fadeInLeft {     
    @media (max-width: $md-width) {
        animation-name: none;
    }
}

.section-empresa .fadeUp {     
    @media (max-width: $md-width) {
        animation-name: none;
    }
}
.fadeInLeft { 
    animation-name: fadeInLeft; 
}
.fadeUp { 
    animation-name: fadeUp; 
}
// animation movil
@keyframes fadeInBottomMvl {
    from {
        opacity: 0;      
    }
    to { 
        opacity: 1;
    }
}
// end animation movil

@keyframes fadeInBottom {
    from {
        opacity: 0;    
    }
    to { 
        opacity: 1;     
    }
}
@keyframes fadeInBottomTranslate {
    from {
        opacity: 0;
        transform: translateY(5rem);
        //transition: .3s transform linear;
    }
    to { 
        opacity: 1;
        transform: translateY(0); 
        //transition: .7s transform linear;
    }
}

@keyframes fadeInLeft {
    from {
        margin: 0 0 5rem;
        opacity: 0;
        transform: translate3d(40px, 0, 0);
        transition: .2s ease .5s;
    }
    to {    
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1s;
    }
}



@keyframes fadeUp {
    0% {      
        opacity: 0;
        transform: translate3d(0, 0, 0);
        transition: .2s ease .5s;        
    }
    30% {    
        opacity: 1;
        z-index: 1;        
        transform: translate3d(0, -40px, 0);
        transition: .4s ease 1s;
    }
    60% {    
        opacity: 1;
        z-index: 1;        
        transform: translate3d(0, -40px, 0);
        transition: .4s ease 1s;
    }
    100% {    
        opacity: 1;
        z-index: 10;
        transform: translate3d(-15px, -15px, 0);
        transition: .4s ease 1s;
    }
}
// end  animations generics

// title and subtitle of section(home)
.section-title{
    font-family: 'Montserrat-SemiBold';
    font-size: 8rem;
    text-transform: capitalize;
    text-align: center;
    color: #1197a5;
    letter-spacing: 0;
    line-height: 11rem;
}    
.line-separation{
    display: flex;
    justify-content: center;
    .line{
        width: 3.5rem;
        height: .5rem;
        background-color: #1197a5;
        border: none;
    }
}
.section-subtitle{
    text-align: center;
    font-size: 2.1rem;
    letter-spacing: 5.25px;
    color: $gray;
    font-family: 'Montserrat-SemiBold';
    -webkit-text-stroke: 0.5px #1197a5;
    line-height: 7rem;
}

.scrollbar-home{
	justify-content: center;
	display: flex;	
	margin: 0 auto;
	width: 100%;
	height: .7rem;	
	z-index: 1;
	.scrollbar{
		width: 9.8rem;
        height: .7rem;
		display: flex;
		justify-content: space-between;	
		align-items: flex-end;
	}
	.scrollbar-item{
		width: 2.5rem;
		height: 20%;
		background-color: $colorCorporative;	
		&.isActive{
			height: 100%;
		}		
	}
	@media (max-width: $sm-width) {
        height: .5rem;	
        .scrollbar{
            width: 6.8rem;
            height: .5rem;
            .scrollbar-item{
                width: 1.8rem;
            }
        }

	}
}

.slick-slider{  
        height: auto;  
        position: relative;    
        //max-height: ;
        .slick-list{
            height: 100%;
            .slick-track{
                height: 100%;
                .slick-slide{
                    height: 100%;  
                    position: relative; 
                    .item{
                        //width: ;
                        position: relative;
                        height: auto;
                        display: flex !important;                 
                        margin-right: 3.5rem;                                                  
                        transition: .1s all linear ;
                        
                        .img{                                       
                            width: 100%;
                            height: 100%;
                            object-fit: cover;                           
                        } 
                    }   
                }               
            }
        } 
        .slick-next, .slick-prev{
            opacity: 0;
            z-index: 1;    
            transition: .3s all ease;
            &:hover{
                opacity: 1;
                transition: .3s all ease;
            }                
        }
        .slick-next{
            right: 2.5rem;                    
        }
        .slick-prev{
            left: 2.5rem;
        }
      
    @media (max-width: $sm-width) {
        .slick-slider.slider-home-concurso{              
            .slick-list{            
                .slick-track{                  
                    .slick-slide{                       
                        .item{                              
                            margin-left: 1.5rem;                                                  
                            transition: .1s all linear ;                         
                        }   
                    }               
                }
            } 
         
            .slick-next{
                right: .5rem;                    
            }
            .slick-prev{
                left: .5rem;
            }
        } 
    }

}



// MARGINS GENERICS

$space: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 130);
@each $margin in $space {
    .marginBottom#{$margin} {
        margin-bottom: calc(#{$margin} * 0.1rem) !important;
    }
    .marginTop#{$margin} {
        margin-top: calc(#{$margin} * 0.1rem) !important;
    }
    .marginLeft#{$margin} {
        margin-left: calc(#{$margin} * 0.1rem) !important;
    }
    .marginRight#{$margin} {
        margin-right: calc(#{$margin} * 0.1rem) !important;
    }
}

@each $padding in $space {
    .paddingBottom#{$padding} {
        padding-bottom: calc(#{$padding} * 0.1rem) !important;
    }
    .paddingTop#{$padding} {
        padding-top: calc(#{$padding} * 0.1rem) !important;
    }
    .paddingRight#{$padding} {
        padding-right: calc(#{$padding} * 0.1rem) !important;
    }
    .paddingLeft#{$padding} {
        padding-left: calc(#{$padding} * 0.1rem) !important;
    }
}

.pointer{
    cursor: pointer !important;
}
.uppercase{
    text-transform: uppercase;
}

.padding-top-cover{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
}

.home.stopScroll{
    overflow: hidden !important;
}

.detail.stopScroll{
    overflow: hidden !important;
}

.home.stopScrollSearch{
    overflow: hidden !important;
}

.bg-grey{        
    background-color: $bg-grey !important;
}

// h1-page (custom h1)

.h1-container{
    max-width: 1280px;
    margin: 0 auto;   
    width: 100%;

    .h1-page{
        margin: 5rem;
        margin-bottom: 1rem;  
        padding: 0;
        padding-left: 2rem;     
        color: $colorCorporative;
        font-family: 'Montserrat-SemiBold';       
        text-transform: uppercase;
        line-height: 1;
        font-size: 4rem;
        position: relative;

        &::before{
            content: '';
            width: 1px;
            height: 100%;
            background-color: $colorCorporative;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    @media only screen and (max-width: 991px){
        .h1-page {
            margin: 3.5rem;          
            margin-bottom: 0;
            line-height: 1.2;
            font-family: 'Montserrat-SemiBold';
            font-size: 2.5rem;                
        }
    }
}
.bg-gray-catalogue{
    .h1-container{      
        max-width: 1480px;  
        .h1-page{
            margin: 5rem 0;
            margin-bottom: 0;          
        }
        @media only screen and (max-width: 991px){
            .h1-page {
                margin: 3.5rem 0;      
                margin-bottom: 0;                
            }
        }
    }
}

//Toast
.fama-toast{
    font-size: 1.5rem;
}
.hide {
    display: none !important;
}

.hidden-sm{
    &.hidden-xs {
        @media (max-width: $screen-xs-max) {
          display: none;
        }
    } 
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        display: none;
    }
}