// FAMA 2021
.btnPrimary{
    color: $btn-primary-bg;
    &:hover, &:active {
        background-color : $btn-primary-bg;
        color: white;
    } 
}
 /**temporal*/
.buttons{
    position: relative;
}
.buttons .avisoDisabled{
    position: absolute;
    bottom: -5rem;
    font-size: 15px;
    min-width: 25rem;
    font-family: 'Montserrat-Regular';

}
.wrap-btnDefault{
    min-width: 26.6rem;
    width: auto !important;
    position: relative;
    align-self: center;
    display: flex;
    flex-direction: column;  
    background-color: transparent;
    border: 1px solid $colorCorporative;  
    z-index: 1;

     /**temporal*/
    &.disabled{
        opacity: .5;
        position: relative;
        
        .btnDefault:active, .btnDefault:hover{        
            transform: translateX(-0.3rem) translateY(-0.3rem);
        } 
     
    }


    .btnDefault{
        text-align: center;
        background-color: $colorCorporative;
        color: $white;
        padding: 1.4rem 4rem;
        position: relative;
        font-size: 1.9rem;
        font-family: 'Montserrat-Bold';
        letter-spacing: .3rem;
        line-height: 1.7rem;
        text-transform: uppercase;           
        transform: translateX(-.3rem) translateY(-.3rem);
        transition: .3s transform linear; 
        z-index: 2;
    
        &:active, &:hover{        
            transform: translateX(0) translateY(0);
            transition: .3s all linear;   
        } 
       
    } 

    @media (max-width: $sm-width) {
        .btnDefault{          
            padding: 1.4rem 3.5rem;         
            font-size: 1.7rem;
        } 
    }
    &.loading{
        border: 1px solid #DCDCDC;        
        .btnDefault{           
            color: #DCDCDC;
            background-color: #DCDCDC;
		    position: relative;
            &:before {
                content: '';
                position: absolute;
                left: calc(50% - 1.5rem);
                top: calc(50% - 1.5rem);
                width: 3rem;
                height: 3rem;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                background-image: url('/images/loader-blue.svg');
                transform-origin: center;
                animation: loading 0.8s infinite;
            }
        }
    }
   
}
.spinner.text-primary {
    color: #1197a5 !important;
}

@keyframes loading {
    0% 	{ transform: rotate(0deg); }
    12.49%{ transform: rotate(0deg); }
    12.5% { transform: rotate(45deg); }
    25.09% { transform: rotate(45deg); }
    25.1% { transform: rotate(90deg); }
    37.49% { transform: rotate(90deg); }
    37.5% { transform: rotate(135deg); }
    50.09% { transform: rotate(135deg); }
    50.1% { transform: rotate(180deg); }
    62.49% { transform: rotate(180deg); }
    62.5% { transform: rotate(225deg); }
    75.09% { transform: rotate(225deg); }
    75.1% { transform: rotate(270deg); }
    87.49% { transform: rotate(270deg); }
    87.5% { transform: rotate(315deg); }
    99.99% { transform: rotate(315deg); }
    100% 	{ transform: rotate(360deg); }
  }