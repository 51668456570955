.detail_shop{
    >.container.home-logos{
      height: auto;
      padding-top: 0;
    }
    > .container-shops{     
      height: 100%;
      padding: 16.4rem 0 7.7rem 0;
      padding-left: 5rem;
      padding-right: 5rem;
      display: flex;
      flex-direction: column;

        .title.title-map{
          font-size: 4.1rem;
          color: $colorCorporativeGrey;     
          font-family: 'Montserrat-SemiBold';
          padding: 0;
          margin-top: 0;
          line-height: 5.5rem;
          position: relative;
          margin-bottom: 5.4rem;
          margin-left: 5rem;

          &::before{
            content: '';
            background-image: url('/images/here-icon-blue.svg');
            width: 2.4rem;
            height: 100%;
            position: absolute;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            left: -5rem;
            top: 0;
          }        
      }
        // wrap for see image full
        .wrap-full-background{              
          width: 100%;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;
          visibility: hidden;
          opacity: 0;
          background-color: rgba(0,0,0,.8);
          transition: .2s opacity ease;

          .wrap-imgActive{
            width: 100%;
            height: 100%;
            max-width: 800px;            
            padding-left: 5rem;
            padding-right: 5rem;
            margin: 0 auto;
          }
            .imgActive{
              position: relative;
              margin: 0 auto;
              width: 100%;
              transform: translateY(-50%);
              top: 50%;
              object-fit: contain;
              height: 60%;
            }
            .close {
              position: relative;
              width: 4rem;
              height: 4rem;
              right: 2rem;
              top: 2rem;
              &::before{
                content: '';
                position: absolute;
                background-image: url("/images/plus-active.png");
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                transform: rotate(45deg);
              }
            }
            &.isOpen{
              z-index: 101;
              visibility: visible;
              opacity: 1;
              transition: .2s opacity ease;
            }
        }
        // end wrap for see image full
    }
    .shop-map{
      height: 60vh;
      min-height: 57.2rem;
      width: 100%;   
      display: flex;

      @media screen and (max-width: $md-width) {
          flex-direction: column;
      }

    }
    .wrap-info-stores{
      .filter-c{      
        width: 40rem;       
        height: 100%;
        padding-left: 5rem;
        padding-right: 0;
      }

      .map-container-info{
        width: calc(100% - 47rem);
        height: 100%;
        margin-left: 7rem;
        margin-top: 3.7rem;

        font-size: 1.7rem;
        line-height: 2.3rem;
        color: #000;
        font-family: 'Montserrat-Regular';

        .wrap-content-shop{
          width: 100%;
          max-width: 86.5rem;
          .part-down{
            flex-direction: column;
          }
          .part-up{
            .direction {
              .nameStory{
                font-family: Montserrat-Bold;
                text-transform: uppercase;
              }
            }
              @media (min-width: $sm-width) and (max-width: calc(#{$md-width} + 1px)) {
                flex-direction: row !important;
                  .direction, .contact{
                    width: 50% !important;
                  }
              }
          }

          .part-up, .part-down{
            width: 100%;
            flex-wrap: wrap;
            & >div {
              width: calc(100% / 3);
              min-width: 23.5rem;
              padding-right: 1.5rem;
              margin-bottom: 4.5rem;
            }
            .web{
              width: calc(100% - 1.5rem);
              line-height: 25px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .aux{
              flex-grow: 1;
              p, p span, p a{
                font-family: 'Montserrat-Regular';
                font-size: 1.5rem;
                line-height: 2.3rem;
                color: #575252;
                &.title{
                  font-family: 'Montserrat-SemiBold';                
                  color: #00a6b8;
                }
              }
            }
            .wrap-btnDefault{
              min-width: 21.2rem;
              margin-top: 2.8rem;
              align-self: flex-start;
              .btnDefault{
                padding: 1.4rem 1.4rem;
                font-size: 1.5rem;
              }
            }
            .part-redes{
              .red{
                font-family: 'Montserrat-SemiBold';
                font-size: 1.5rem;
                line-height: 2.3rem;
                color: #575252;
                position: relative;
                padding-left: 3rem;
                
                &.facebook{
                  margin-bottom: 1rem;
                }

                &.facebook:after{
                  content: '';
                  position: absolute;
                  background-image: url('/images/map-facebook.svg');
                  width: 20px;
                  height: 20px;
                  background-size: contain;
                  background-position: center;
                  background-repeat: no-repeat;
                  left: 0;
                  top: 0;                  
                }
                &.instagram:after{
                  content: '';
                  position: absolute;
                  background-image: url('/images/map-instagram.svg');
                  width: 20px;
                  height: 20px;
                  background-size: contain;
                  background-position: center;
                  background-repeat: no-repeat;
                  left: 0;
                  top: 0;                  
                }
              }
            }
            .contact{
              .aux{
                order: 1;
              }
              .part-redes{
                order: 2;
                margin-top: 1.5rem;
              }
              .wrap-btnDefault{
                order: 3;
                left: 0.5rem;
                @media (min-width: calc(#{$md-width} + 1px)) and (max-width: 1280px) {
                  left: 0;
                }
              }
            }
            .storyNear{
              height: auto;            
              overflow: auto;
              order: 2;
              column-count: 2;             
              width: calc((100% / 3) * 2);
              min-width: calc(21.2rem * 2 + 3rem);
              padding-right: 0;
              column-gap: 1.5rem;
         
              &::-webkit-scrollbar {
                  -webkit-appearance: none;
                  width: 7px;
              }
              
              &::-webkit-scrollbar:horizontal {
                  height: 4px;
              }
              
              &::-webkit-scrollbar-thumb {
                  border-radius: 8px;
                  border: 1px solid $colorCorporative;
                  background-color: $colorCorporative;
              }
              
              &::-webkit-scrollbar-track { 
                  background-color: rgba(17, 151, 165, 0.32); 
                  border-radius: 8px; 
              }

      
              .store{
                  font-weight: normal;
                  font-family: 'Montserrat-Regular';
                  font-size: 1.3rem;
                  color: #575252;
                  line-height: 2.1rem;
                  text-transform: capitalize;
                  text-decoration: none;
                  display: flex;
                  flex-direction: column;    
                  .storeName{
                    text-transform: uppercase;

                    .linkStore{
                      color: $colorCorporative;
                      font-family: 'Montserrat-Regular';
                      transition: all .2s linear;
                      &:hover{
                        color: #0e7c87;
                        font-family: 'Montserrat-SemiBold';
                        transition: all .2s linear;
                      }
                    }
                  }
               
                  &:last-of-type{
                    .linksStores{
                      margin-bottom: 0,
                    }
                  }
                  .linksStores{
                    margin-bottom: 1rem;
                  
                      a{               
                        font-family: 'Montserrat-SemiBold';       
                        text-decoration: underline;
                      }
                  } 
              }   
                         
            }
            .title{
              font-size: 1.9rem;
              font-family: 'MONTSERRAT-BOLD';
              color: #575252;
              padding-bottom: 1rem;
              line-height: 2.7rem;
              position: -moz-sticky;
              position: -o-sticky;
              position: -ms-sticky;
              position: sticky;     
              position: -webkit-sticky;   
              top: 0;
              background-color: #fff;
              order: 1;
              margin-bottom: 0;
            }
          }   
        }
        .wrap-slider{
            width: 100%;
            margin-top: 5.2rem;
              .slider-shop{
                  width: calc(100% + 5rem);

                    .item-img{
                      width: 30vw;
                      max-width: 50rem;
                      min-width: 37.3rem;
                      margin-right: 1.7rem;

                        .img{
                          width: 100%;
                          padding-top: 56%;
                          position: relative;

                            img{
                              position: absolute;
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              top: 0;
                              left: 0;
                            }
                        }
                    }

                .slick-arrow{
                  z-index: 1;
                }
            
                .slick-prev	{
                  left: 1.5rem;
                  display: none;
                  width: 4rem;
                  height: 4rem;
                  
                  &:before{
                    content: '';
                    background-image: url('/images/left.svg');
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: 0;
                    left: 0;
                  }
            
                }
                .slick-next	{                  
                  right: 1.5rem;
                  display: none;
                  width: 4rem;
                  height: 4rem;
            
                  &:before{
                    content: '';
                    background-image: url('/images/right.svg');
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: 0;
                    left: 0;
                  }
                }
                &:hover{
                
                  .slick-prev, .slick-next{
                    display: block;
                  }
            
                }
                &:hover
                {
                  .slick-prev, .slick-next{
                    opacity: 1;
                  }
                }
            }
        }
        /**
          *  Progress Bar
          */
          .wrap-progress{
            width: 100%;
            max-width: 1280px;
            padding-top: 2.8rem;
            .progress {
              display: block;
              width: 100%;
              height: 5px;
              border-radius: 8px;
              overflow: hidden;
              
              background-color:rgba($colorCorporative, .5);
              background-image: linear-gradient(to right, $colorCorporative, $colorCorporative);
              background-repeat: no-repeat;
              background-size: 0 100%;
              
              transition: background-size .4s ease-in-out;
            }
            .sr-only {
              position: absolute;
              width: 1px;
              height: 1px;
              padding: 0;
              margin: -1px;
              overflow: hidden;
              clip: rect(0,0,0,0);
              border: 0;
            }
        }
   
        /**
        *  END Progress Bar
        */
      }
    }

     
  }
  
  .content.shops {  
      padding: 12px;  
      & > .row {
          margin: 0 -6px;
          @media screen and (min-width: $screen-sm-min) {
          display: flex;
          }
      }
  
      & .col {
        @media screen and (min-width: $screen-sm-min) {
          padding: 0 6px;
        }
        @media screen and (max-width: $screen-sm-max) {
          padding:0;
        }
      }
  }
  
  .shops-filter {

      padding: 0px 0px 0px 0px;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;

      .show-filter{
        cursor: pointer;

        @media screen and (max-width: $screen-sm-max) {
          padding: 0;
          display: block;
          float: right;
          transform-origin: center center;
          transform: rotateX(0);
          -webkit-transition: all 0.5s ease; /* Safari */
          transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
        }        
      }
      .show-filter.open{
        transform: rotateX(180deg);
      }

      .form-block{              
          padding: 0;
          left: 0 !important;

          @media screen and (min-width: $screen-sm-min) {          
            position: relative;           
            left: 0;
            right: 0px!important;           
            bottom: 0!important;           
            top: unset !important;
            display: block!important;
          }
  
          @media screen and (max-width: $screen-xs-max) {
            display: block;
            position: relative;
            left: auto!important;
            right: auto!important;
            bottom: auto!important;
            top: auto!important;
          }
  
          .form-control {
              text-transform: capitalize;
          }
  
          label {
            @media screen and (max-width: $screen-sm-max) {
              margin-top: 0px;
            }
  
            @media screen and (min-width: $screen-sm-min) {
              margin-top: 20px;
            }
          }
  
          .loading-results{
            background-image: url('/css/assets/images/ajax-loader.gif');
            background-repeat: no-repeat;
            background-position: center center;
            pointer-events: none;
          }
          .form-group{
            position: relative;            
          }
          .form-group.buttons {
            position: absolute;          
            bottom: 0;
            width: 100%;
            right: 0px;           
            padding: 0;
            justify-content: flex-end;
            display: flex;
            flex-direction: column;
           
            @media screen and (max-width: $screen-xs-max) {
              margin-top: 0;
              position: relative;
              top: auto;
              right: auto;
              left: auto;
              bottom: auto;
              padding: 0;
            }
  
            @media screen and (min-width: $screen-sm-min) {
              margin-bottom: 0px;
            }
          }
  
          .form-group.buttons button {
            @media screen and (max-width: $screen-lg-max) {
              font-size: 1vw;
            }
            @media screen and (max-width: 761px) {
              font-size: 18px;
            }
            @media screen and (min-width: $screen-xlg-min) {
              font-size: 18px;
            }
              margin-top: 15px;
          }
      }
  
  }

  .shops-filter .select2-container--default .select2-selection--single {
    border: 1px solid #ccc;
  }
  #pais.err +.select2.select2-container{
    .select2-selection--single {
      border: 1px solid red !important;
    }
  }

  #provincia_container_2021.err{
    .select2-container--default .select2-selection--single {
      border: 1px solid red !important;
    }
  }
  
  .map-container #map {
      width: 100%;
      height: 100%;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
  }
  
  .map-container{

    margin-left: 7rem;
    padding-right: 0;
    @media screen and (max-width: $screen-sm-max) {
      height: 100vh;
      overflow: hidden;
    }
  
    @media screen and (min-width: $screen-sm-min) {
      height: 100%;
      overflow: hidden;     
      width: calc(100% - 47rem);
      min-width: unset;
      box-shadow: 0px 0px 20px 0px darkgray;
      border-radius: 10px;     
    }
  }
  .filter-c{
    @media screen and (min-width: $screen-md-min) {
      height: 70%;
      padding-left: 0;
      padding-left: 5rem;
      width: 40rem;  
    }   
  }
  form.form-block{
    position: relative;
}

.form-search-map{
  width: 100%;
  height: 100%;
  position: relative;

    .wrap-btnDefault{
      min-width: unset;
      margin: 0 auto;
      margin-right: 0;
      margin-top: 3.9rem;
    }      
} 

.container-shops{

  .form-search-map{
      input, select{
        border-radius: 0 !important;
      }
   
      .form-group {
        margin-bottom: 3.9rem;

        .form-control{
          border: 1px solid #575252;
          display: block;
          width: 100%;         
          padding: 0.75rem; 
          font-size: 1.7rem; 
          font-weight: normal; 
          line-height: 2.5rem; 
          color: #000;
          background-color: #ffffff;
          background-clip: padding-box; 
          border-radius: 0;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          
        }
        .form-control +.paisLabel{
          margin-top: 0 !important;
          left: 3rem;
          margin: 0;   
          position: absolute;
          top: 0;
          padding: 0 1rem;
          transform: translateY(-50%);
          background-color: #fff;
          font-size: 1.3rem;
          font-family: 'Montserrat-Medium';
          font-weight: normal;
          color: #575252;
          z-index: 1;
        }
    }
  }
  .select2.select2-container.select2-container--default +.paisLabel{
    margin-top: 0 !important;
    left: 3rem;
    margin: 0;   
    position: absolute;
    top: 0;
    padding: 0 1rem;
    transform: translateY(-50%);
    background-color: #fff;
    font-size: 1.3rem;
    font-family: 'Montserrat-Medium';
    font-weight: normal;
    z-index: 1;
    color: #575252;
  }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #575252;
      line-height: 28px;
      position: absolute;
      background-color: #fff;
      padding-right: 1rem;
  }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: #575252 transparent transparent transparent;
      border-style: solid;
      border-width: 7px 7px 0 7px;
      height: 0;
      left: 50%;
      margin-left: -30px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0;
  }
    .shops-filter .select2-container--default .select2-selection--single {
      border: 1px solid #575252;
  }
    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #575252  transparent;
      border-width: 0 7px 7px 7px;
      
  }
    .select2-container .select2-dropdown{
      margin-top: -1px;
      border-color: #000!important;
      border-radius: 0;
      font-size: 1.7rem;
      font-family: 'Montserrat-Regular';
      line-height: 2.3rem;
      letter-spacing: 0;

  }
}

.select2-container--default .select2-results>.select2-results__options{
  font-size: 1.7rem;
  font-family: 'Montserrat-Regular';
  line-height: 2.3rem;
  letter-spacing: 0;
}
.select2-container{
  #select2-pais-results{
    li{
      &:first-of-type{
        //display: none;
        padding-top: 0px;
        padding-bottom: 0;
      }
    }
  }
  ul[id^="select2-id_provincias"]{
    li{
      &:first-of-type{
        //display: none;
      }
    }
  }
}
@media screen and (max-width: $md-width) {
  .detail_shop > .container-shops{    
      padding: 11.2rem 1.5rem;    
      .title.title-map{
        font-size: 2.5rem;        
        margin-bottom: 4rem;
      }
      .form-search-map .form-block{
          font-size: 1.7rem;
          line-height: 2.3rem;
          letter-spacing: 0;      }
      .form-search-map .wrap-btnDefault{
        margin-top: 0;
      }

      .map-container{
        width: calc(100% + 3rem);
        height: 100%;
        min-height: 45vh;
        margin-left: 0;
        box-shadow: 0px 0px 15px 0px darkgrey;
        transform: translateX(-1.5rem);        
      }
      .shop-map {
        height: 90vh;
      }

      .wrap-info-stores{
        .filter-c{
          display: none;
        }
        .map-container-info{
          width: 100%;
          margin-left: 0;
          margin-top: 2.5rem;

          .wrap-content-shop{
            .part-up, .part-down{
                flex-direction: column;
                &>div{
                  margin-top: 4.8rem;
                  width: 100%;
                  padding-right: 0;
                  margin-bottom: 0;
                }
                .storyNear{
                  order: 2;
                  margin-top: 0;
                  min-width: unset;
                }
                .store{
                  order: 1;
                  .storeName{
                    width: 100%;
                  }
                }
              }         
          }
          .wrap-slider{
            display: none;
          }
        }
      }
   }

      .select2-results__option {
        padding: 1rem !important;
        user-select: none;
        -webkit-user-select: none;       
    }

    .form-group.buttons{
        .select2.select2-container{
          margin-bottom: 3.9rem;
        }
    }
  
    .select2-container--default .select2-search--dropdown .select2-search__field{
      height: 5rem;
      padding: 1rem;
      font-size: 1.7rem;
      line-height: 2.3rem;
      letter-spacing: 0;

    }
    .container-shops .select2-container--default .select2-selection--single .select2-selection__rendered{
      font-size: 1.7rem;
      line-height: 2.3rem;
      letter-spacing: 0;
    }
    .select2-container.select2-container--default.select2-container--open{
        left: unset !important;    
        .select2-dropdown {
          left: 1.5rem;
        }
    }
}

.cluster{
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  &>div{
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span{
      align-items: center;
      color: #fff;
      font-family: 'Montserrat-Medium';
      font-size: 1.5rem;

    }
  }
}
@media screen and (min-width: $md-width){
  .shops-filter .form-block .form-group.buttons {
    margin-bottom: 0;
  }
 
}
@media screen and (max-width: $md-width){
    .shops-filter .form-block .form-group.buttons {
      margin-bottom: 3.9rem;
      position: relative;
    }
    .filter-c{
      height: auto;
    }
}

