%text
{
	font-size: 13px;
	line-height: 120%;
}

body.famaliving %famalivingTextColor
{
	color: $colorCorporative2;
}

body.famaliving %famalivingBackgroundColor
{
	background-color: $colorCorporative2!important;
}