.container.home-non-stop{
    .aux-wrap{       
        padding-top: 11rem;
        padding-bottom: 14.5rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    
    .wrap-text{        
        white-space: nowrap;
        .text{
            color: #fff;
            font-size: 10.1rem;
            font-family: 'Montserrat-SemiBold';
            padding-bottom: 5.6rem;
            .light{
                font-family: 'Montserrat-Regular';                       
            }
        }     
    }

    .carousel-box{
        position: relative;    
         width: 100%;
         padding-left: 5rem;
         padding-right: 5rem;

        .carousel-box-wrapper{          
            width: 100%;           
            margin: 0 auto;
            max-width: 1280px; 
        }    
    
        .carousel-wrapper{
            width: 100%;            
            .carousel-container{
                display: flex;
                flex-wrap: nowrap;   

                .slick-list{
                    overflow: visible;
                    width: 100%;
                }

                .carousel-item{            
                    margin: 0;
                    margin-right: 2.2rem;
                    display: flex;
                    flex-direction: column;
                    width: 33rem;
                  
                    .item{ 
                        position: relative;       
                        padding-top: 90%;        
                        margin-bottom: 2rem;     
                        margin-right: 0;                   
                        .img{  
                            position: absolute; 
                            top: 0;
                            left: 0;                                    
                            width: 100%;
                            height: 100%;
                            object-fit: cover;                           
                        } 
                    }
                    .item-info{
                        color: #fff;
                        .title{
                            font-family: 'Montserrat-Bold';
                            font-size: 1.9rem;
                            line-height: 2.6rem;
                            margin-bottom: .7rem;
                        }
                        .text{
                            color: #fff;
                            font-family: 'Montserrat-Regular';
                            font-size: 1.5rem;
                            line-height: 2.2rem;
                        }
                    }                  
                }
                &>.slick-next{
                    opacity: 1;                    
                    width: 4rem;
                    height: 4rem;
                    transform: translateY(-50%);
                    top: 14.7rem;
                    right: calc((100vw - 100%) / -2 + 5rem);
                    &:before{
                        content: '';
                        background-image: url('/images/right.svg');
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        top: 0;
                        left: 0;
                    }        
                }
                &>.slick-prev{
                    opacity: 1;                   
                    width: 4rem;
                    height: 4rem;
                    transform: translateY(-50%);
                    top: 14.7rem;
                    left: calc((100vw - 100%) / -2 + 5rem);
                    &:before{
                        content: '';
                        background-image: url('/images/left.svg');
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        top: 0;
                        left: 0;
                    }        
                }
            }           
        }
    } 
}

@media (max-width: $sm-width) {
.container.home-non-stop{
    padding-top: 0;
    .aux-wrap{
        padding-bottom: 10.2rem;
        padding-top: 5.1rem;
    }  
        .carousel-box{
            position: relative;        
            padding-left: 0;
            padding-right: 0;

            .carousel-wrapper{
                width: 100%;      
                overflow: hidden;

                .carousel-container{
                    display: flex;
                    flex-wrap: nowrap;            

                    .slick-list{
                        padding: 0 1.5rem !important;
                    }

                    .carousel-item{      
                        width: 26.2rem;
                        margin: 0;
                        margin-left: 1.4rem;
                        display: flex;
                        flex-direction: column;
                       
                        .item{                
                            margin-bottom: 2rem;                        
                            .img{                                       
                                width: 100%;
                                height: 100%;
                                object-fit: cover;                             
                            } 
                        }                                        
                    
                        &:first-of-type{
                            margin-left: 0;
                        }
                        &:last-of-type{
                            margin-right: 3rem;
                        }
                    }
                    &>.slick-next{                
                        top: 12.5rem;
                        right: 1.5rem;                        
            
                    }
                    &>.slick-prev{                       
                        top: 12.5rem;
                        left: 1.5rem;              
            
                    }
                }           
            }
        }
        .wrap-text{           
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            .text{
                font-size: 3.5rem;
                line-height: 6.3rem;
                padding-bottom: 3rem;                   
            }             
        }
    }
}

