.section-empresa.sofas.sofas-medioambiente{
    .wrap-content{
        .title-subsection-empresa{
            font-size: 3.5rem;
            font-family: 'Montserrat-SemiBold';
            line-height: 4.5rem;
            color: $colorCorporative;
            letter-spacing: 0;
            padding-bottom: 3.3rem;           
        }
        .subtitle-subsection-empresa{
            font-size: 1.7rem;
            font-family: 'Montserrat-Medium';
            color: $colorCorporative;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            padding-bottom: 3.5rem;
            width: 70%;
            line-height: 3rem;
        }   
        .text.generic{
            i{
                font-family: 'Montserrat-Italic' !important;
            }
        }
    }
    &.textos-medioambiente{
        .section-two-paragraph-enviroment{
            padding-top: 0;
            .wrap-content{
                .title-subsection-empresa{
                    width: calc(100% + 5rem);
                    padding-left: 1rem;
                }
                .part-down .part-img{
                    padding-top: 0;
                    .text.generic{
                        padding-bottom: 2.5rem;
                    }
                    .img{
                        display: block;
                    }
                }
            } 
        }
        .part-header{
            .part-left-header{                  
                padding-right: 5rem;
                padding-bottom: 2.5rem;
                width: fit-content;
            }
            .wrap-img{               
                align-self: baseline;            
                margin-top: 1rem;   
                margin-bottom: 1.5rem;

                .img{                  
                    max-height: 50vh;                   
                    object-fit: contain;
                }    
                &.mvl{
                    display: none;
                }                         
            }
        }
        .wrap-img2{
            width: calc(80% - 2rem);            
            padding: 7rem 0;
        }
        a, .text.generic a{
            color: $colorCorporative !important;
            text-decoration: none !important;
            opacity: 1;
            transition: 0.2s all ease;
            &:hover{
                text-decoration: underline !important;
            }
        }
    }

    &.lista-medioambiente{
        padding-bottom: 11rem;
        .wrap-content{
            .status{
                width: 100%;
                text-align: end;
                text-transform: uppercase;
                font-family: 'Montserrat-SemiBold';
                color: $colorCorporative;
                width: 100%;
                font-size: 1.7rem;
                letter-spacing: 0;
                line-height: 2.1rem;
                padding-bottom: 1.5rem;
                border-bottom: 1px solid $colorCorporative;    
            }
          
            >.wrap-item{
                padding: 3rem 0;             
                border-bottom: 1px solid $colorCorporative; 
                        
                .part-up{
                    justify-content: space-between;                  
                    font-family: 'Montserrat-SemiBold';
                    color: $colorCorporative;
                    width: 100%;
                    font-size: 1.7rem;
                    letter-spacing: 0;
                    line-height: 2.1rem;

                    .wrap-title{                          
                        width: 100%;

                        .aux-title{                         
                            .content-title{
                                &.mvl{
                                    display: none;
                                }
                            }
                        }

                        .num{
                            font-size: 2.5rem;                          
                            letter-spacing: 0;
                            line-height: 1;
                            margin-top: -0.5rem;
                        }
                        .title{
                            padding: 0;
                            padding-left: 1.5rem;                           
                            display: block;  
                            text-transform: uppercase;

                           .content-title{
                                text-transform: uppercase;
                                font-family: 'Montserrat-SemiBold';
                                color: $colorCorporative;
                                width: 100%;
                                font-size: 1.7rem;
                                letter-spacing: 0;
                                line-height: 2.1rem;
                                padding-bottom: 1.5rem;
                           } 
                           .sustainability-content{
                                text-transform: initial;
                           }    
                    
                            .box-media{
                                padding-top: 2.5rem;      
                                justify-content: space-between;
                            }
                            .wrap-media{
                                aspect-ratio: 4/3;
                                width: 48%;
                                                        
                                .img{                                  
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                    object-position: left;
                                }
                            } 
                            .wrap-video{
                                aspect-ratio: 4/3;
                                width: 48%;
                                iframe{
                                    width: 100%;
                                    height: 100%;                        
                                }
                            }
                                       
                        }                      
                    }
                    .sustainability-status{
                        text-transform: uppercase;
                        font-family: 'Montserrat-Regular';                         
                        color: #000;
                        padding-left: 3rem;
                        text-align: right;

                        p{
                            padding-bottom: 1rem !important;
                            &:last-of-type{
                                padding-bottom: 0 !important;
                            }
                        }                       
                    }
                  
                }
        
            }
        }
    }
    &.ourTours-medioambiente{
        position: sticky;
        top: 15rem;      
        padding-top: 0;
        width: calc(100% + 5rem);
        padding-right: 5rem;
        margin-top: 0;

        

            &::before{
                content: "";
                width: calc(100% + 18vw + 13rem);
                position: absolute;
                left: 100%;
                transform: translateX(-100%);             
                top: 15.6rem;
                height: 100%;
                background-color: #cee4e7;      
            }
   
        @media (min-width: calc(#{$extra-width} + 1px)) {
            &::before{    
                content: "";
                width: calc(100% + 25vw + 8rem);
                position: absolute;
                left: 100%;
                transform: translateX(-100%);              
                top: 15.6rem;
                height: 100%;
                background-color: #cee4e7;               
            }
        }

        .wrap-content{
            background-color: #cee4e7;
            &::before{
                content: "";
                width: calc(100% + 18vw + 13rem);
                position: absolute;
                left: calc(100% + 5rem);
                transform: translateX(-100%);
                top: 0;
                height: 100%;
                background-color: #cee4e7;
            }
            @media (min-width: calc(#{$extra-width} + 1px)) {
                &::before{    
                    content: "";
                    width: calc(100% + 25vw + 8rem);
                    position: absolute;
                    left: 100%;
                    transform: translateX(-100%);
                    top: 0;                 
                    height: 100%;
                    background-color: #cee4e7;              
                }
            }
        }
 
        .title-subsection-empresa{
            padding-top: 4.2rem;
        }
        .wrap-slider-ourTours{
            background-color: #cee4e7;       
            position: relative;

            .aux-ourTour{                
                position: relative;
                height: auto;                            
            }
            .slider-ourTours-mvl{
                display: none;
            }
       
            .slider-ourTours, .slider-ourTours-mvl{  
                max-width: 991px;
                margin-bottom: 8rem;             
             
                .title{
                    font-family: 'Montserrat-Medium' !important;
                    font-size: 1.7rem !important;
                    letter-spacing: .2rem !important;
                    color: $colorCorporative !important;
                    padding-right: 0;
                }
   
                &.slick-vertical{
                   
                    .slick-list{
                        cursor: grab;                                
                        &.dragging, &.draggable:active:hover {
                            cursor: grabbing;
                        }
                    }
                    .slick-slide{
                        display: block ;
                        height: auto !important;  
                        opacity: 0;
                        transition: .2s .2s opacity ease ;  

                        .part-right{
                            display: block;
                            font-size: 2.1rem;
                            line-height: 3.1rem;
                            color: $colorCorporative;
                            letter-spacing: 0;
                            font-family: 'Montserrat-Regular';
                            text-transform: initial;

                            .hito{
                                font-family: 'Montserrat-SemiBold';
                                text-transform: uppercase;
                            }
                        }  
                        
                        .wrap-media{   
                            width: 50%;         
                            max-width: 50%;                            
                            height: auto;
                            object-fit: contain;
                            width: 50%;
                            padding-left: 5rem;
                            display: block !important;
                            aspect-ratio: 3/2;

                            .wrap-video{                               
                                
                                iframe{
                                    object-fit: contain;
                                    width: 100%;
                                    height: 100%;
                                    background-color: unset;
                                    min-height: 26.5vh;
                                }
                            }
                        }
                   
                    }
                    .slick-current.slick-active{
                        opacity: 1;  
                        transition: .2s .2s opacity ease ;                   
                    }
                }
              
                .slick-list{
                    padding: 0 !important;
                    transition: all .5s ease;
             
                    .slick-track{              

                        height: auto;
                        border: none;
                   
                        img{                        
                            max-height: 50vh;                             
                            object-fit: contain;                          
                            width: 100%;                          
                            padding-bottom: 2rem;
                            min-height: 22vh;
                            max-height: unset;
                            height: auto;
                        }
                    }
                }
               
                .slick-prev, .slick-next, .next-arrow, .prev-arrow{
                    opacity: 1;   
                    top: -12rem;        
         
                    &.slick-disabled{
                        opacity: 0.5;
                    }       
                }
                .slick-prev, .prev-arrow	{
                    transform: translateX(2rem) translateY(-50%);
                    right: 10rem;
                    left: unset;
                    display: none;
                    width: 4rem;
                    height: 4rem;
                    display: block;
                    position: absolute;  
                    
                    &:before{
                        content: '';
                        background-image: url('/images/arrow-timeline-active.svg');
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        top: 0;
                        left: 0;
                        
                    }
                    .prev{
                        &:before{
                            content: '';
                            background-image: url('/images/arrow-timeline-active.svg');
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            top: 0;
                            left: 0;
                            
                        }
                    }
        
                }

             
                .slick-next, .next-arrow{
                    transform: translateX(-2rem) translateY(-50%);
                    right: 0;  
                    left: unset;                
                    display: none;
                    width: 4rem;
                    height: 4rem;
                    display: block;
                    position: absolute;  
        
                    &:before{
                        content: '';
                        background-image: url('/images/arrow-timeline-active.svg');
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        top: 0;
                        left: 0;
                        transform: rotate(180deg);
                    }

                    .next{
                        &:before{
                            content: '';
                            background-image: url('/images/arrow-timeline-active.svg');
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            top: 0;
                            left: 0;
                            transform: rotate(180deg);
                        }
                    }
                }                      
           
            }

        }

    }
}

.section-empresa.sofas.sofas-medioambiente.politca-ambiental{
    .wrap-content{
        .wrap-list-enviroment{
            max-width: 800px;
            padding-bottom: 8.5rem;
            .list{
                padding-bottom: 2rem;
                padding-top: 2.5rem;
                .item{
                    padding-bottom: 2.4rem;
                    .num{
                        font-size: 3.5rem;
                        font-family: 'Montserrat-SemiBold';
                        color: $colorCorporative;
                        min-width: 7.5rem;
                        letter-spacing: 0;
                    }
                    .info{
                        padding-left: 2rem;
                    }
                }
    
            }
           
        }       
    }


    &.politica-ambiental-slider{
        position: relative;
        padding-top: 0;
        width: calc(100% + 5rem);
        padding-right: 5rem;
        margin-top: 15rem;

        &::before{
            content: "";
            width: calc(100% + 18vw + 8rem);
            position: absolute;
            left: 100%;
            transform: translateX(-100%);
            top: 0;
            height: 100%;
            background-color: #f0feff;          

        }
        @media (min-width: calc(#{$extra-width} + 1px)) {
            &::before{    
                content: "";
                width: calc(100% + 25vw + 8rem);
                position: absolute;
                left: 100%;
                transform: translateX(-100%);
                top: 0;
                height: 100%;
                background-color: #f0feff;              
            }
        }
        .wrap-content{           

            .slider-enviroment{
                max-width: 800px;
                margin-bottom: 8rem;
            }
            .wrap-slider-enviroment{
                background-color: #f0feff;
                padding-top: 8.2rem;
                padding-bottom: 6rem;
                position: relative;
                
                .slider-enviroment.slick-slider{
                    display: flex;
                    flex-direction: column;
                    .slick-list{
                        cursor: grab;                                
                        &.dragging, &.draggable:active:hover {
                            cursor: grabbing;
                        }
                    }
                    .item{
                   
                        .title{
                            font-family: 'Montserrat-Medium' !important;
                            font-size: 2.5rem !important;
                            letter-spacing: .2rem !important;
                            color: $colorCorporative !important;
                            padding-right: 0;
                        }
                        .text-slider{                            
                            &.isClose{
                                height: calc((1.7rem * 1.5) * 6 + 7.5rem) !important;
                                overflow: hidden;
                                transition: 2s height linear;
                            }
                            &.isClose +.btn-seeMore{
                                &::after {
                                    content: "";
                                    background-image: url(/images/arrow-right-green.svg);
                                    position: absolute;
                                    width: 1.2rem;
                                    height: 100%;
                                    transform: rotate(90deg);
                                    background-repeat: no-repeat;
                                    background-position: bottom;
                                    background-size: contain;
                                    margin-left: 1.5rem;
                                    transition: 0.3s all linear;
                                }
                            }
                           
                            &.isOpen{
                                height: auto !important;
                                transition: 2s height linear;                           
                            }
                            &.isOpen +.btn-seeMore{
                                &::after {
                                    content: "";
                                    background-image: url(/images/arrow-right-green.svg);
                                    position: absolute;
                                    width: 1.2rem;
                                    height: 100%;
                                    transform: rotate(-90deg);
                                    background-repeat: no-repeat;
                                    background-position: bottom;
                                    background-size: contain;
                                    margin-left: 1.5rem;
                                    transition: 0.3s all linear;
                                }
                            }

                            h5, h5 *{
                                font-size: 2.1rem !important;
                                line-height: 2.5rem !important;
                                color: $colorCorporative !important;
                                font-family: 'Montserrat-Bold' !important;
                            }
                            b{
                                font-size: 1.7rem !important;
                                line-height: 2.5rem !important;
                                color: #000 !important;
                                font-family: 'Montserrat-Bold' !important;
                            }
                            .container-content .generic.text, .container-content .generic.text p, .container-content .generic, .container-content .generic.text *{                             
                                line-height: 1.5 !important;                               
                            }

                        }
                        .wrap-img{
                            margin-bottom: 4.8rem;
                            position: relative;
                            padding-top: 40%;
                            .img{
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                    .slick-list{
                        padding: 0 !important;
                    }
    
                    ul.slick-dots{
                        z-index: 2;
                        width: 55%;
                        min-width: 55%;
                        max-width: 55%;
                        width: auto;
                        color: #fff;
                        align-self: flex-start;
                        font-family: 'Montserrat-Regular';
                        font-size: 2.5rem;
                        letter-spacing: 3.85px;
                        display: flex;
                        justify-content: space-between;          
                        bottom: -8rem;
                        flex-wrap: wrap;
             
                        display: flex;
                        max-width: unset;
                    
                        li{
                            width: unset;
                            margin: 0;
                            display: flex;
                            height: 2.5rem;

                            
                            a.dot{
                                color: $colorCorporative;
                                opacity: .4;
                                transition: .3s opacity linear;
                                text-align: start;
                            }
                            
                            &.slick-active{
                                a.dot{
                                    opacity: 1;
                                    transition: .3s opacity linear;
                                    font-family: 'Montserrat-Bold';
                                }
                                
                            }
                        }
                    }
                    .slick-prev, .slick-next{
                        opacity: 0;
                        top: 22vh;
                    }
                    .slick-prev	{
                        transform: translateX(2rem) translateY(-50%);
                        left: 0;
                        display: none;
                        width: 4rem;
                        height: 4rem;
                        
                        &:before{
                            content: '';
                            background-image: url('/images/left.svg');
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            top: 0;
                            left: 0;
                        }
            
                    }
                    .slick-next	{
                        transform: translateX(-2rem) translateY(-50%);
                        right: 0;
                        display: none;
                        width: 4rem;
                        height: 4rem;
            
                        &:before{
                            content: '';
                            background-image: url('/images/right.svg');
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            top: 0;
                            left: 0;
                        }
                    }
                    &:hover{
                    
                        .slick-prev, .slick-next{
                            opacity: 1;
                            transition: .2 opacity ease;
                        }
            
                    }
                }
    
                /**
                /**
                    *  Progress Bar
                    */
                    .aux-probress-bar{
                        max-width: 800px;
                        max-width: 800px;
                        width: 100%;
                        position: relative;
                        display: flex;
                        justify-content: flex-end;
                    }
                    .wrap-progress{
                        width: 45%;                          
                        max-width: none;
                        position: absolute;
                        height: 2.5rem;
                        margin-top: -2.2rem;
                        display: flex;   
                        padding-left: 5rem;
                        justify-content: flex-end;
                    }
                    .progress {
                        display: block;
                        width: 100%;
                        height: 2px;
                        border-radius: 0;
                        overflow: hidden;
                        align-self: center ;
                        
                        background-color:rgba($colorCorporative, .5);
                        background-image: linear-gradient(to right, $colorCorporative, $colorCorporative);
                        background-repeat: no-repeat;
                        background-size: 0 100%;   
                    
                        transition: background-size .4s ease-in-out;
                    }
                    /**
                    *  END Progress Bar
                    */
            }
        }
        
    }
}


@media (max-width: $md-width) {

    .section-empresa.sofas.sofas-medioambiente{
        padding-top: 5rem !important;
        .wrap-content{
            .title-subsection-empresa{
                font-size: 2.5rem;               
                line-height: 3.5rem;              
                padding-bottom: 1.5rem;    
                overflow: hidden;
                text-overflow: ellipsis;       
            }
            .subtitle-subsection-empresa{  
                width: 100%;
            }   
        }
        &.textos-medioambiente{
            .section-two-paragraph-enviroment{
                padding-top: 0;
                .wrap-content{
                    .title-subsection-empresa{
                        width: 100%;
                        padding-left: 0;
                    }
                    .part-down .part-img{
                        padding-top: 0;
                        .text.generic{
                            padding-bottom: 2.5rem;
                        }
                        .img{
                            display: block;
                        }
                    }
                    .text.generic{
                        b{
                            font-family: 'Montserrat-Bold' !important;
                        }
                    }
                } 

            }
            .part-header{
                .part-left-header{                        
                    padding-right: 0;
                    padding-bottom: 2.5rem;
                    width: 100%;
                }
                .wrap-img{   
                    margin-top: 0;   
    
                    .img{
                        max-height: 27rem;
                        width: 100%;
                        height: 100%; 
                        object-position: left;
                        margin-bottom: 3.5rem;
                    }   
                    &.mvl{
                        display: block;
                    }   
                    &.desc{
                        display: none;
                    }          
                }
            }
            .wrap-img2{
                width: 100%;
                padding: 3.5rem 0;
            }
            a, .text.generic a{
                color: $colorCorporative !important;
                text-decoration: none !important;
                opacity: 1;
                transition: 0.2s all ease;
                &:hover{
                    text-decoration: underline !important;
                }
            }
        }
    
        &.lista-medioambiente{
            padding-bottom: 11rem;
            .wrap-content{
                .status{
                  display: none;
                }

                .wrap-item[data-current="1"] {
                    border-top: 1px solid $colorCorporative !important;
                    margin-top: 2.5rem;
                }
             
                .wrap-item{
                    padding: 1.5rem 0;             
                    border-bottom: 1px solid $colorCorporative; 
                            
                    .part-up{  
                        flex-direction: column;
    
                        .wrap-title{                          
                            width: 100%;
                            flex-direction: column;
                            .aux-title{
                                display: flex;
                                padding-bottom: 2rem;

                                .content-title{
                                    &.mvl{
                                        display: block;
                                        padding-left: 1.5rem;
                                        text-transform: uppercase;
                                    }
                                }
                            }
    
                            .num{
                                font-size: 2.5rem;                          
                                letter-spacing: 0;
                                line-height: 1;
                                margin-top: -0.5rem;
                            }
                            .title{
                                padding: 0;
                                padding-left: 0;                           
                                display: block;  
                                text-transform: uppercase;
    
                               .content-title{
                                    &.desc{
                                        display: none;
                                    }
                               } 
                               .sustainability-content{
                                    text-transform: initial;
                                    
                               }    
                               .box-media{
                                   padding-top: 0;
                                    flex-direction: column;
                               }
                               .wrap-media{
                                    aspect-ratio: 4/3;
                                    width: 100%;
                                    padding-top: 2.5rem;                               
                                    .img{
                                        max-height: 30rem;
                                        width: 100%;
                                        height: 100%;
                                        object-fit: contain;
                                        object-position: left;
                                    }
                                } 
                                .wrap-video{
                                    aspect-ratio: 4/3;
                                    width: 100%;
                                    iframe{
                                        width: 100%;
                                        height: 100%;                        
                                    }
                                }
                                           
                            }                      
                        }
                        .sustainability-status{                           
                            padding: 0;
                            padding-top: 2rem;
                            align-self: end;
                            max-width: 50%;
                            p{
                                padding-bottom: 1rem !important;
                                &:last-of-type{
                                    padding-bottom: 0 !important;
                                }
                            }                       
                        }
                      
                    }
                             
                }
            }
        }
        &.ourTours-medioambiente{
            position: sticky;
            top: 10rem;          
            padding-top: 0;
            width: 100%;
            padding-right: 0;
            margin-top: 0;
    
            &::before{
                content: "";
                width: calc(100% + 3rem);
                position: absolute;
                left: -1.5rem;
                transform: unset;             
                top: 8rem;
                height: 100%;
                background-color: #cee4e7;     
            }
    
            .wrap-content{
                background-color: #cee4e7;
                &::before{
                    content: "";
                    width: calc(100% + 3rem);
                    position: absolute;
                    left: -1.5rem;
                    transform: unset;
                    top: 0;
                    height: 100%;
                    background-color: #cee4e7;
                }
                .title-subsection-empresa{
                    z-index: 1;
                    padding-top: 0;
                }
            }
        //  slider-ourTours
            .title-subsection-empresa{
                padding-top: 4.2rem;
            }
            .wrap-slider-ourTours{
                background-color: #cee4e7;         
                position: relative;
    
                .aux-ourTour{                
                    position: relative;
                    height: auto;                            
                }
                .slider-ourTours{
                    display: none;
                }
                .slider-ourTours-mvl{
                    display: block;
                }
                .slider-ourTours, .slider-ourTours-mvl{  
                    max-width: 991px;
                    margin-bottom: 0;    
                             
                    
                    .title{
                        font-family: 'Montserrat-Medium' !important;
                        font-size: 1.7rem !important;
                        letter-spacing: .2rem !important;
                        color: $colorCorporative !important;
                        padding-right: 0;
                        line-height: 2.5rem;
                    }
                    .item{
                        padding-bottom: 4rem;
                        opacity: 1;
                        transition: .7s opacity .1s ease;

                        .wrap-content-item{
                            flex-direction: column;
                            
                            .part-right{
                                font-size: 1.9rem;
                                line-height: 3.5rem;    
                                width: 100%;
                                display: block;      
                           
                            }
                            .wrap-media{
                                width: 100%;
                                max-width: 100%;
                                padding-left: 0;
                                iframe{
                                    width: 100%;
                                    height: auto;
                                    margin-top: 2.5rem;
                                }
                            }
                        }
                        img{
                            max-width: 100%;
                            max-height: unset;  
                            height: 100%;
                            object-fit: contain;
                            width: 100%;
                            padding-left: 0;
                            padding-top: 3.5rem;
                        }                

                        &.opacity-0-mvl {
                            opacity: 0;
                            transition: .7s opacity .1s ease;

                        }
                    } 
                }    
            }    
        }
    }
    
    .section-empresa.sofas.sofas-medioambiente.politca-ambiental{
        padding-top: 0;
        .wrap-content{
            .wrap-list-enviroment{
                max-width: 100%;
                padding-bottom: 0;
                .list{
                    padding-bottom: 2rem;
                    .item{
                        flex-direction: column;
                        padding-bottom: 2.4rem;
                        .num{
                            font-size: 2.1rem;                            
                            min-width: unset;
                            letter-spacing: 0;
                            padding-bottom: 1.5rem;
                            padding-top: 1rem;
                        }
                        .info{
                            padding-left: 0;
                        }
                    }
        
                }
            }       
        }
    
    
        &.politica-ambiental-slider{
            position: relative;
            padding-top: 0 !important;
            width: 100%;
            padding-right: 0;
            margin-top: 5rem;
    
            &::before{
                content: "";
                width: calc(100% + 3rem);
                position: absolute;
                left: -1.5rem;
                transform: none;
                top: 0;
                height: 100%;
                background-color: #f0feff;  
            }

            .wrap-content{           
    
                .slider-enviroment{
                    max-width: 100%;
                    margin-bottom: 10rem;
                }
                .wrap-slider-enviroment{                 
                    padding-top: 3.5rem;
                    padding-bottom: 6rem;
                                       
                    .slider-enviroment.slick-slider{
                        display: flex;
                        flex-direction: column;
                  

                        .slick-list{                          
                            width: calc(100% + 1.5rem);          

                        }
                        .item{                           
                            margin-right: 1.5rem;
                        
                            .title{
                                font-family: 'Montserrat-Medium' !important;
                                font-size: 2.5rem !important;
                                letter-spacing: .2rem !important;
                                color: $colorCorporative !important;
                                padding-right: 0;
                            }
                            .text-slider{            
                                h5{
                                    margin: 0;
                                    line-height: 2.3rem !important;
                                }                
                                &.isClose{                                 
                                    height: calc(2.3rem * 8 + 0.5rem) !important;
                                    overflow: hidden;
                                    transition: 2s height linear;
                                    h5{
                                        margin: 0;
                                        line-height: 2.3rem !important;
                                        & +p{
                                            margin-top: 0.5rem;
                                        }
                                    }
                                    & > p:first-of-type:not(:empty){
                                      margin-top: 0.5rem; 
                                    }
                                }
                                &.isClose +.btn-seeMore{
                                    &::after {
                                        content: "";
                                        background-image: url(/images/arrow-right-green.svg);
                                        position: absolute;
                                        width: 1.2rem;
                                        height: 100%;
                                        transform: rotate(90deg);
                                        background-repeat: no-repeat;
                                        background-position: bottom;
                                        background-size: contain;
                                        margin-left: 1.5rem;
                                        transition: 0.3s all linear;
                                    }
                                }
                               
                                &.isOpen{
                                    height: auto !important;
                                    transition: 2s height linear;                           
                                }
                                &.isOpen +.btn-seeMore{
                                    &::after {
                                        content: "";
                                        background-image: url(/images/arrow-right-green.svg);
                                        position: absolute;
                                        width: 1.2rem;
                                        height: 100%;
                                        transform: rotate(-90deg);
                                        background-repeat: no-repeat;
                                        background-position: bottom;
                                        background-size: contain;
                                        margin-left: 1.5rem;
                                        transition: 0.3s all linear;
                                    }
                                }
    
                                h5, h5 *{
                                    font-size: 2.1rem !important;
                                    line-height: 2.5rem !important;
                                    color: $colorCorporative !important;
                                    font-family: 'Montserrat-Bold' !important;
                                }
                                b{
                                    font-size: 1.7rem !important;
                                    line-height: 2.5rem !important;
                                    color: #000 !important;
                                    font-family: 'Montserrat-Bold' !important;
                                }
                                .container-content .generic.text, .container-content .generic.text p, .container-content .generic, .container-content .generic.text *{                             
                                    line-height: 1.5 !important;                               
                                }
    
                            }
                            .wrap-img{
                                margin-bottom: 4.8rem;
                                position: relative;
                                padding-top: 40%;
                                .img{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                        }
                        .slick-list{
                            padding: 0 !important;
                        }
        
                        ul.slick-dots{                           
                            width: 100%;
                            min-width: 100%;
                            max-width: 100%;                            
                            font-size: 1.9rem;
                            letter-spacing: 2.92px;     
                        
                            li{
                                width: unset;
                                margin: 0;
                                display: flex;
                                height: 2.5rem;
    
                                
                                a.dot{
                                    color: $colorCorporative;
                                    opacity: .4;
                                    transition: .3s opacity linear;
                                    text-align: start;
                                }
                                
                                &.slick-active{
                                    a.dot{
                                        opacity: 1;
                                        transition: .3s opacity linear;
                                        font-family: 'Montserrat-Bold';
                                    }
                                    
                                }
                            }
                        }
                        .slick-prev, .slick-next{
                            opacity: 0;
                            top: 22vh;
                        }
                        .slick-prev	{
                            transform: translateX(2rem) translateY(-50%);
                            left: 0;
                            display: none;
                            width: 4rem;
                            height: 4rem;
                            
                            &:before{
                                content: '';
                                background-image: url('/images/left.svg');
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                                top: 0;
                                left: 0;
                            }
                
                        }
                        .slick-next	{
                            transform: translateX(-2rem) translateY(-50%);
                            right: 0;
                            display: none;
                            width: 4rem;
                            height: 4rem;
                
                            &:before{
                                content: '';
                                background-image: url('/images/right.svg');
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                                top: 0;
                                left: 0;
                            }
                        }
                        &:hover{
                        
                            .slick-prev, .slick-next{
                                opacity: 1;
                                transition: .2 opacity ease;
                            }
                
                        }
                    }
        
                    /**
                    /**
                        *  Progress Bar
                        */
                        .aux-probress-bar{
                            max-width: 100%;                     
                            width: 100%;                           
                        }
                        .wrap-progress{
                            width: 100%;
                            //margin: 0 auto;       
                            max-width: 100%;                  
                            margin-top: 0;                    
                            padding-left: 0;                         
                        }                   
                        /**
                        *  END Progress Bar
                        */
                }
            }            
        }
    }
}