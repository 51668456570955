.section-empresa.sofas.sofas-humanTeam{
    &.section-human-team{
        .wrap-content{
            .part-left{
                width: 45%;
            }
            .part-right{
                width: 55%;
            }
            .wrap-up-first{
                position: relative;
                width: 100%;              
                .wrap-title{
                    width: 100%;
                    .title{
                        padding-right: 0;
                    }
                    .subtitle{
                        width: 100%;
                    }
                    .box-title{
                        .wrap-img.mvl{
                            display: none !important;
                        }
                    }
                }  
                .wrap-img{
                    position: relative;        
                    width: 100%;     
                    max-width: 26.3rem;                    
                    margin: 0 auto;
                    flex-grow: 1;
                    max-height: 100%;
                    height: 28.8rem;
                    .img{
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin: 0 auto;                            
                        object-fit: contain;
                    }
                }
            }
          
            .part-up{
                width: 100%;  
                padding-top: 5rem;
                .part-right{                 
                    display: flex;
                    position: relative;
                    flex-direction: column;                  
                    .wrap-img{                       
                        width: 100%;
                        flex-grow: 1;
                        z-index: 1;
                        position: relative;
                        margin-bottom: 2.5rem;
                        .img{
                            width: 100%;
                            width: calc(100% + 2rem);
                            object-fit: cover;
                            top: 2.5rem;
                            left: -2.5rem;
                            position: relative;
                            z-index: 2;
                            transform: translateX(-2rem);
                        }
                        &::before{
                            content: '';
                            width: 100%;
                            height: 80%;
                            background-color: $colorCorporative;
                            position: absolute;  
                            top: 0;
                            left: 0;     
                            z-index: 1;                   

                        }
                    } 
                    .wrap-quote{
                        transform: translateX(-40%);
                        right: 30rem;
                        width: 100%;
                        margin-top: 7rem;
                        .quote{
                            padding-left: 0;
                            padding-right: 0;
                            white-space: nowrap;
                            font-size: 2.45vw;
                            &:before{
                                transform: translateX(-75%) translateY(90%);
                            }
                            &:after{
                                transform: translateX(55%) translateY(120%);
                            }
                            @media (min-width: 1800px) {
                                font-size: 3.1rem;
                            }
                           
                        }
                    }      
                    .part-text{
                        width: 125%;
                        transform: translateX(-30%);
                        .wrap-text{    
                            width: 95%;     
                            margin-top: 1.5rem;
                        }
                        .wrap-link{
                            color: $colorCorporative;
                            padding-top: 4.5rem;
                    
                            .link{
                                position: relative;
                                color: inherit;
                                &::before{
                                    content: '';            
                                    background-image: url('/images/arrow-blue.svg');
                                    width: 2rem;      
                                }  
                                a{
                                    color: inherit;
                                } 
                                &:hover{                                    
                                    &::before{
                                        transform: translateX(3.5rem) scale(1.1);
                                        transition: .3s transform linear;
                                    }
                                }
                            }
                        }
                    }                       
                }
            }

        }
    }
}
//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {
    .section-empresa.sofas.sofas-humanTeam{
        &.section-human-team{
            .wrap-content{
                .generic.text, .generic.text p, .generic{
                    color: inherit;
                    font-size: 1.7rem;
                    line-height: 2.5rem;
                }
                .part-left{
                    width: 100%;
                }
                .part-right{
                    width: 100%;
                }
                .wrap-up-first{
                    position: relative;
                    width: 100%;              
                    .wrap-title{
                        width: 100%;
                        .title{
                            padding-right: 0;
                            padding-top: 6rem;
                        }
                        .subtitle{
                            width: 85%;
                        }
                        .box-title{
                            display: flex;
                            .wrap-img.mvl{
                                display: block !important;
                            }
                        }
                    }  
                    .wrap-img{
                        position: relative;        
                        width: 100%;     
                        max-width: auto;                    
                        margin: 0 auto;                      
                        max-height: 100%;
                        height: auto;
                        .img{
                            right: 0;
                            position: absolute;
                            top: 0;
                            left: 0;
                            margin: 0 auto;                            
                            object-fit: contain;
                            width: 13rem;
                            height: 14.2rem;
                        }
                    }
                    .part-right.desk{
                        display: none;
                    }
                }
              
                .part-up{
                    width: 100%;  
                    padding-top: 5rem;
                    justify-content: flex-end;
                    .part-left{
                        width: 0;
                    }
                    .part-right{           
                        width: 100%;
                        align-self: flex-end;            
                        .wrap-img{                       
                            width: 100%;
                            flex-grow: 1;
                            z-index: 1;
                            position: relative;
                            margin-bottom: 2.5rem;
                            .img{                                
                                width: calc(100% - 1.5rem);
                                object-fit: cover;
                                top: 1.5rem;
                                left: 0;
                                position: relative;
                                z-index: 2;
                                transform: translateX(0);
                            }
                            &::before{
                                content: '';
                                width: calc(100% - 2.5rem);
                                height: 80%;
                                background-color: $colorCorporative;
                                position: absolute;  
                                top: 0;
                                left: 2.5rem;     
                                z-index: 1;                   
    
                            }
                        } 
                        .wrap-quote{
                            margin: 0 auto;
                            width: 60%;
                            transform: translateX(0);
                            right: 0;
                            margin-top: 7rem;
                            .quote{                              
                                line-height: 2.87rem;
                                font-size: 2.17rem;
                                white-space: unset;
                                &:before{
                                    font-size: 17.5rem;
                                    transform: translateX(-60%) translateY(70%);
                                }
                                &:after{
                                    font-size: 17.5rem;
                                    transform: translateX(75%) translateY(65%);
                                }
                                @media (min-width: 1800px) {
                                    font-size: 3.1rem;
                                }
                               
                            }
                        }      
                        .part-text{
                            width: 100%;
                            transform: translateX(0);
                            .wrap-text{    
                                width: 100%;     
                                margin-top: 4rem;
                            }
                            .wrap-link{
                                width: 70%;
                                color: $colorCorporative;
                                padding-top: 4.5rem;
                        
                                .link{
                                    position: relative;
                                    color: inherit;
                                    &::before{
                                        content: '';            
                                        background-image: url('/images/arrow-blue.svg');
                                        width: 2rem; 
                                        height: 50%;
                                        bottom: 0;
                                    }  
                                    a{
                                        color: inherit;
                                    } 
                                    &:hover{                                    
                                        &::before{
                                            transform: translateX(3.5rem) scale(1.1);
                                            transition: .3s transform linear;
                                        }
                                    }
                                }
                            }
                        }                       
                    }
                }    
            }
        }
    }
}