.wrap-container-full{
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    z-index: 10;
    padding-right: 5rem;
    padding-left: 5rem;
}

.container.home-news{
    max-width: 1280px !important;
    margin: 0 auto;
    padding: 8rem;
    padding-bottom: 6rem;    
    padding-right: 0;
    padding-left: 0;

    .section-subtitle{     
        color: $colorCorporative;
        -webkit-text-stroke: 0;
        text-transform: uppercase;
    }
}

.container.home-news, .container.home-blog {   

    .section-title a {
        color: #fff;

        &:hover{
            color: #fff;  
        }
    }
    
    .wrap-items{
        width: calc(100% + 2.2rem);
        flex-wrap: wrap;
        padding-top: 8rem;
        .wrap-item{
            width: calc(100% / 3);
            padding-right: 2.2rem;
            margin-bottom: 4rem;
            .wrap-img{
                overflow: hidden;
                margin-bottom: 1.2rem;
                overflow: hidden;
                transform: translateY(0);
                transition: .5s transform linear;
                padding-top: 63%;
                position: relative;
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: scale(1);
                    transition: .5s transform linear;
                }               
            }
            .wrap-content{
                font-size: 1.3rem;
                .wrap-info{
                    justify-content: space-between;
                    .info{
                        margin-bottom: 1.2rem;
                        .date-info{
                            margin-top: .4rem;
                            font-family: 'Montserrat-Italic';
                            color: $colorCorporative;
                        } 
                        .text{
                            font-size: 1.3rem;
                        }
                    }                    
                      
                    
                }
                .title{
                    font-family: 'Montserrat-SemiBold';
                    font-size: 1.5rem;
                    color: #000;
                    transition: .3s color ease;
                    &:hover{
                        color: rgba($color: #000000, $alpha: 0.7);
                        transition: .3s color ease;
                    }
                }
            }
            &:hover{
                .wrap-img{                   
                    transform: translateY(-1.5rem);
                    transition: .5s transform linear;
                    img{
                        transform: scale(1.3);
                        transition: .5s transform linear;
                    }
                }
            }
        }
    }
} 



@media (max-width: $sm-width) {
    .wrap-container-full{
        width: 100%;
        max-width: 100%;
        background-color: #fff;
        z-index: 10;
        padding-right: 0;
        padding-left: 0;
    }
     .container.home-news{
        max-width: 100% !important;
        padding: 5.4rem 3.4rem;
        padding-bottom: 11.3rem;
        
        .wrap-items{
            width: 100%;
            flex-wrap: wrap;
            padding-top: 3rem;
            .wrap-item{
                width: 100%;
                padding-right: 0;
                margin-bottom: 6rem;    
                display: none !important;
               
            }
            .wrap-item:nth-child(1),  .wrap-item:nth-child(2),  .wrap-item:nth-child(3){
                display: flex !important;
                flex-direction: column;
            }
            
        }
        .section-title{
            font-size: 6rem;
            line-height: 10rem;
        }
        .section-subtitle{
            font-size: 1.7rem;
            letter-spacing: 4.25px;
            line-height: 6.5rem;
            color: $colorCorporative;
            -webkit-text-stroke: 0;
        }
    }
}
