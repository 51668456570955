.loves
{
    color: $colorCorporative;
    font-size: $f-size-normal-body;
    font-family: Arvo, Arial, Tahoma;
    display: flex;
    align-items: center;

    .icon
    {
        width: 25px;
        height: 23px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('/css/assets/heart.png');  
        margin-right: 10px;
        cursor: pointer;

        &.favourite {
            pointer-events: none;
            background-image: url('/css/assets/heart-pink.svg');
        }

        &:hover
        {
            animation: beat .25s infinite alternate;
            transform-origin: center;
        }
    }
}

@keyframes beat{
    to { transform: scale(1.4); }
}



