#work_2021{
    padding: 0;
    padding-top: 15.6rem;
    padding-right: 5rem;
    padding-left: 5rem;
 
    .limited-wrap{
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;

        .section-title{   
            color: $colorCorporative;
            letter-spacing: 0;
            .title{
                font-size: 3.5rem;
                text-transform: none;
                font-family: "Montserrat-SemiBold";
                font-weight: normal;
                line-height: 4.3rem;
                text-align: left;                
            }
            .title-underline{
                width: 3.5rem;
                border-bottom: 4px solid;
                background: $colorCorporative;
                margin-top: 0.5rem;
                margin-bottom: 4rem;
            }         
        }
        .curriculum_2021{
            .content{
                padding: 6rem 6.5rem;
    
                .content-first{
                    width: 100%;
                    justify-content: space-between;
                    .title{
                        font-size: 2.5rem;
                        line-height: normal;
                        font-family: 'Montserrat-SemiBold';
                        color: $colorCorporative;
                        min-height: 5rem;
                    }
                }
            }
            .form{            
                margin-top: 5.9rem;
                display: block !important;
                max-height: 9999px;              
                opacity: 1;
                transition: max-height 1s,  opacity .4s .4s linear;

                .part-dataPerson{ 

                    .wrap-personData{       

                        .addPhoto{                     
                            width: 12%;
                            margin: 0;
                            min-width: 14rem;
                            padding: 0;
                            display: flex;
                            flex-direction: column;

                            .icon{
                                margin-bottom: 0;
                                padding: 0;
                                border: none;
                                position: relative;
                                max-height: 20rem;
                                padding-bottom: 1rem;

                                .preview_img{
                                    max-height: 20rem;
                                    object-position: center;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                            .triggerAdd{
                                color: #575252;
                                font-size: 1.5rem;
                                font-family: 'Montserrat-Medium';
                                margin: 0 auto;
                                text-align: center;
                            }
                          
                        }
                        .bloque-field{
                            width: calc(100% - 12% - 7%);
                            margin-left: 7%;
                            &.mvl{                                   
                                display: none;                                   
                            }
                            .groupfields{                
                                width: calc(100% + 3.2rem);
                                border: none;
                                .legend-title{
                                    width: calc(100% - 3.2rem);
                                    color: #fff;
                                    font-size: 2.5rem;
                                    text-transform: none;
                                    font-family: 'Montserrat-SemiBold';                              
                                    padding: 1.3rem 2.5rem;
                                    margin-bottom: 3.8rem;
                                    &.mvl{
                                        display: none;
                                    }
                                }
                                .wrap-puesto{
                                    display: block;
                                    column-count: 4;
                                }
                            }
                        }
                    
                    }
                    .bloque-field{
                        width: calc(100% + 3.2rem);
                        .legend-title{
                            font-size: 2.5rem;
                            text-transform: none;
                            font-family: 'Montserrat-SemiBold';
                            background-color: #8ccdd4;
                            padding: 1.3rem 2.5rem;
                        
                        }
                    }
                    .groupfields{  
                        position: relative;                   
                        .legend{
                            font-size: 1.5rem;
                            color: #575252;
                            font-family: 'Montserrat-Medium';
                            &.medium{
                                width: fit-content;
                                margin-left: 1.3rem;
                                padding: 0 1rem;
                                background-color: #fff;
                                position: absolute;
                                transform: translateY(-50%);
                               
                            }
                        }  
                        .form-group-special{
                            padding: 2rem 1.5rem;
                            border: 1px solid #575252;     
                            margin-top: 1rem;        
                            padding-left: 1.8rem; 
                            padding-right: 3rem;
                            margin-bottom: 4rem;   

                            .wrap-puesto{
                                display: block;
                                column-count: 4;
                                width: 100%;
                            }
                            .checkbox, .radio{
                                position: unset;
                                display: block;
                                margin-top: 0;
                                margin-bottom: 2rem;

                                input:before{
                                    content: "";
                                    height: 2.4rem;
                                    width: 2.4rem;
                                    border: #575252 1px solid;                                  
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-top: -3px;
                                    font-family: FontAwesome;
                                }
                                input:checked:before {
                                    content: "";                                    
                                    color: $colorCorporative !important;
                                    padding: 0.3rem;
                                }
                                label{
                                    min-height: 2.4rem;
                                    padding-left: 3rem;
                                    margin-bottom: 0;
                                    font-weight: normal;                                  
                                    cursor: pointer;
                                    font-size: 1.5rem;
                                    color: #575252 !important;
                                    font-family: 'Montserrat-Medium';
                                    .check-name{
                                        align-self: center;
                                    }
                                }
                                input[type="checkbox"], input[type="radio"]{
                                    margin-left: -3rem;
                                    position: absolute;
                                }
                            }
                            &.textarea{
                                font-size: 1.5rem;
                                font-family: 'Montserrat-Regular';
                            }
                        }
                    
                    
                    } 
                }

                .input-box{
                    input{
                        height: 5rem;
                    }
                }
                .input-box input + .label,                    
                .input-box textarea + .label, 
                 .select2 + .label, 
                 select + .label{
                    position: absolute;
                    top: 0;
                    font-size: 1.5rem;
                    left: 1.7rem;
                    color: #575252;
                    background-color: #fff;
                    padding: 0 1.1rem;
                    pointer-events: none;
                    transform: translateY(-50%);
                    transform-origin: left;
                    white-space: nowrap;
                    margin: 0;
                    font-weight: normal;
                    transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
                    font-family: 'Montserrat-Medium';
                }
                    
                    .input-box{
                        padding-right: 3.2rem;
                        margin-bottom: 3.8rem;
                        .label{
                            background-color: #fff;
                        }
                    }
                    .textarea{
                        .input-box{
                            margin-bottom: 0;
                        }
                    }
                    .wrap-privacy{                            
                        width: 100%;
                        .privacy{
                            width: 100%;
                            /*
                            .labelContainer{
                                position: relative;
                                padding: 0;
                                .textContainer{
                                    width: calc(100% - 3.2rem);
                                    font-family: 'Montserrat-Medium';
                                    font-weight: normal;                                  
                                    display: flex;
                                    align-items: center;
        
                                    .link{
                                        color: $colorCorporative;
                                        text-decoration: none;
                                        margin-left: .5rem;
                                        //-left: .5rem;
    
                                        --border-color: $colorCorporative;
                                        --border-width: 1px;
                                        --bottom-distance: 0px;
                                        display: inline;
                                        background-image: linear-gradient(var(--border-color),var(--border-color));
                                        background-size: 0 var(--border-width);
                                        background-repeat: no-repeat;
                                        background-position-y: bottom;
                                        transition: background-size .3s;  
    
                                        &:hover{
                                            background-size: 100% var(--border-width);
                                        }
                                    }
                                }
                            }
                            input + .labelContainer:before {
                                content: "";
                                position: relative;
                                display: inline-block;
                                vertical-align: middle;
                                min-width: 2.4rem;
                                height: 2.4rem;
                                top: 0;
                                border: 0.1rem solid #575252;
                                border-radius: 0 !important;
                                background-color: transparent;
                                transition: background-color 0.2s linear 0.1s;
                            }
                            input + .labelContainer:after {
                                content: "";
                                position: absolute;
                                z-index: 1;
                                min-width: 1.6rem;
                                height: 0.7rem;
                                top: 1.5rem;
                                left: 0.4rem;
                                border: 0.2rem solid $colorCorporative;
                                border-top: 0;
                                border-right: 0;
                                transform: rotate(-45deg) scale(0);
                                transform-origin: left top;
                                transition: all 0.2s ease 0s;
                            }
                            input[type=checkbox]:checked + .labelContainer:after {
                                transform: translateY(-20%) rotate(-45deg) scale(1);
                                transition: all 0.2s ease 0.1s;
                            }
                            */
                            &.error_form input + .labelContainer:before {
                                border: 0.2rem solid #e95950 !important;
                            }
                        }
                        .wrap-btnDefault{
                            margin: 0 auto;
                            margin-right: 0;
                            min-width: unset;
                            .btnDefault{
                                font-size: 1.5rem;
                                line-height: 1.7rem;
                                padding: 1.5rem;
                                &.mvl{
                                    display: none;
                                }
                            }                            
                        }
                    }      
                    &.hidden{
                        display: none !important;
                        height: auto;
                        opacity: 0;
                        max-height: 0;
                        transition: max-height 0.5s, opacity 0.3s 0.1s linear;
                    }      
                }
            
        }
           
        .buttons{    
            display: flex;                           
            justify-content: flex-end;

            .wrap-btnDefault{
                min-width: unset;
                .btnDefault{
                    font-size: 1.5rem;
                    line-height: 1.7rem;
                    padding: 1.5rem;
                    &.mvl{
                        display: none;
                    }
                  
                }
            }

        }
        .form{
            .first{
                width: calc(100% + 3.2rem);
                position: relative;
                .groupfields{
                    position: relative;                    
                }
                .legend-title{
                    width: calc(100% - 3.2rem);
                    color: #fff;
                    font-size: 2.5rem;
                    text-transform: none;
                    font-family: "Montserrat-SemiBold";
                    padding: 1.3rem 2.5rem;
                    background-color: #8ccdd4;
                    margin-bottom: 3.8rem;
                }             
            
                .form-group-special{
                    padding: 2rem 1.5rem;
                    border: 1px solid #575252;     
                    margin-top: 1rem;        
                    padding-left: 1.8rem; 
                    padding-right: 3rem;
                    margin-bottom: 4rem;   

                    .wrap-puesto{
                        display: block;
                        column-count: 4;
                        width: 100%;
                        &.col5{
                            column-count: 5;
                        }
                        &.col3{
                            column-count: 3;
                            margin-bottom: 3rem;
                        }                        
                    }
                    .wrap-others{
                        width: calc(100% + 3.2rem);
                        .input-box{
                            margin-bottom: 2rem;
                        }
                        .legend{
                            padding-bottom: 1.5rem;
                        }
                    }
                    .checkbox, .radio{
                        position: unset;
                        display: block;
                        margin-top: 0;
                        margin-bottom: 2rem;

                        input:before{
                            content: "";
                            height: 2.4rem;
                            width: 2.4rem;
                            border: #575252 1px solid;                                  
                            display: inline-block;
                            vertical-align: middle;
                            margin-top: -3px;
                            font-family: FontAwesome;
                            background-color: #fff;
                        }
                        input:checked:before {
                            content: "";                                    
                            color: $colorCorporative !important;
                            padding: 0.3rem;
                            background-color: #fff;
                        }
                        label{
                            min-height: 2.4rem;
                            padding-left: 3rem;
                            margin-bottom: 0;
                            font-weight: normal;                         
                            cursor: pointer;
                            font-size: 1.5rem;
                            color: #575252 !important;
                            font-family: 'Montserrat-Medium';
                            .check-name{
                                align-self: center;
                            }
                        }
                        input[type="checkbox"], input[type="radio"]{
                            margin-left: -3rem;     
                            position: absolute;
                        }
                    }
                    &.textarea{
                        font-size: 1.5rem;
                        font-family: 'Montserrat-Regular';
                    }    
                    &.noBorder{
                        border: none;
                        padding: 0;
                    }  
                    &.mb2{
                        margin-bottom: 2rem;
                    }
                    .radio-box{
                        .radio{
                            margin-right: 4.5rem;
                            margin-bottom: 3rem;
                        }                
                    }    
                 
                }
             
                .wrap-box{
                    width: calc(100% - 3.2rem);
                }
                .legend{
                    font-size: 1.5rem;
                    color: #575252;
                    font-family: 'Montserrat-Medium';
                    &.medium{
                        width: fit-content;
                        margin-left: 1.5rem;
                        padding: 0 1rem;
                        background-color: #fff;
                        position: absolute;
                        transform: translateY(-50%);
                   
                    }
                } 
                .warp-add{
                    position: absolute;
                    left: 75%;
                    bottom: 5rem;
                    margin: 0 auto;
                    z-index: 1;
                    & > div{
                        margin: 0 auto;
                        margin-right: 3.2rem;
                        .icon.addbutton{
                            padding-bottom: 0;
                            img{
                                width: 3rem;
                                height: 3rem;
                                margin-right: 1rem;
                            }
                            a{
                                align-items: center;
                                display: flex;
                                font-size: 1.5rem;
                                color: $colorCorporative;
                                
                            }
                        }
                    }
                    &.mvl{
                        display: none;
                    }
                } 
                .wrap-aux{
                    position: relative;
                }
            }
            fieldset.form-group-special textarea.form-control{
                min-height: 18.7rem;
                border: none !important;
                box-shadow: none !important;
                font-size: 1.4rem;               
                font-weight: normal;
                color: #575252;
            }
            .wrap-selectCarnet{
                width: calc(100% + 3.2rem);
            }
            .uploadCv{
                padding: 1.3rem 2.8rem;
                background-color: #e4eaea;
                font-family: 'Montserrat-Medium';
                font-size: 1.5rem;
                text-transform: uppercase;
                line-height: 2.1rem;
                color: #575252;
                transition: .2s all ease;

                &:hover{
                    color: #e4eaea;
                    background-color: #575252;
                    transition: .2s all ease;
                }
            }
        }
    }
}

    
@media (max-width: $md-width) {
    #work_2021{
        padding: 0;
        padding-top: 9.2rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    
        .limited-wrap{       
            max-width:100%;        
    
            .section-title{                 
                .title{ 
                    font-size: 2.5rem;                   
                    line-height: 3.8rem;
                }
                .title-underline{
                    width: 3.5rem;
                    border-bottom: 2px solid;
                    background: $colorCorporative;
                    margin-top: 0.5rem;
                    margin-bottom: 4rem;
                }               
    
            }   
            .curriculum_2021{
                .content{
                    padding: 1.5rem;
                    padding-top: 4.4rem;
                    padding-bottom: 5.5rem;   
        
                    .content-first{
                        flex-direction: column;
                        .title{
                            font-size: 1.9rem;
                            text-align: center;
                            line-height: 2.5rem;
                        }
                    }
                }
                .form{            
                    margin-top: 3rem;
                    display: block !important;
                    max-height: 9999px;              
                    opacity: 1;
                    transition: max-height 1s,  opacity .2s .3s linear;
    
                    .part-dataPerson{ 
    
                        .wrap-personData{       
                            flex-direction: column;
                            align-items: center;

                            .addPhoto{                     
                                width: 12%;
                                margin: 0;
                                min-width: 14rem;
                                padding: 0;
                                padding-bottom: 3.8rem;
    
                                .icon{                                    
                                    max-height: 15rem;
                                    padding-bottom: 1.7rem;

                                    .preview_img{
                                        max-height: 11.9rem;                                        
                                    }
                                }
                                .triggerAdd{
                                    color: #575252;                            
                                    font-family: "Montserrat-SemiBold";
                                    font-size: 1.28rem;    
                                    font-weight: normal;
                                    margin: 0;
                                }
                              
                            }
                            .bloque-field{
                                width: 100%;
                                margin-left: 0;
                                &.mvl{                                   
                                    display: block;     
                                    padding-bottom: 3.8rem;                              
                                }
                             
                                .groupfields{                
                                    width: 100%;                                  
                                    .legend-title{
                                        width: calc(100% + 3rem);
                                        color: #fff;
                                        transform: translateX(-1.5rem);
                                        font-size: 2.5rem;
                                        text-transform: none;
                                        font-family: 'Montserrat-SemiBold';     
                                        margin-bottom: 3.8rem;
                                        padding: 1.3rem 0;

                                        &.mvl{
                                            margin: 0 auto;
                                            display: block;
                                            
                                        }
                                        &.desc{
                                            display: none;
                                        }
                                    }
                                    .wrap-puesto{
                                        display: block;
                                        column-count: 4;
                                    }
                                }
                            }
                        
                        }
                        .bloque-field{
                            width: 100%;
                            .legend-title{
                                font-size: 2.5rem;
                                text-transform: none;
                                font-family: 'Montserrat-SemiBold';
                                background-color: #8ccdd4;                              
                                margin-bottom: 3.8rem;    
                                padding: 1.3rem 0;       
                                text-align: center;                
                            }
                        }
                        .groupfields{  
                            position: relative;
                          
                            .legend{
                                font-size: 1.5rem;
                                color: #575252;
                                font-family: 'Montserrat-Medium';
                                &.medium{
                                    width: fit-content;
                                    margin-left: 1.5rem;
                                    padding: 0 1rem;
                                    background-color: #fff;
                                    position: absolute;
                                    transform: translateY(-50%);
                                    font-size: 1.3rem;
                                }
                            }  
                            .form-group-special{
                                padding: 2rem 1.5rem;
                                border: 1px solid #575252;     
                                margin-top: 1rem;        
                                padding-left: 1.8rem; 
                                padding-right: 3rem;
                                margin-bottom: 4rem;   
    
                                .wrap-puesto{
                                    display: block;
                                    column-count: 1;                                
                                }
                                .checkbox, .radio{
                                    position: unset;
                                    display: block;
                                    margin-top: 0;
                                    margin-bottom: 2rem;
    
                                    input:before{
                                        content: "";
                                        //height: 2rem;
                                        //width: 2rem;
                                        border: #575252 1px solid;                                  
                                        display: inline-block;
                                        vertical-align: middle;
                                        margin-top: -3px;
                                        font-family: FontAwesome;
                                    }
                                    input:checked:before {
                                        content: "";                                    
                                        color: $colorCorporative !important;
                                        padding: 0.3rem;
                                    }
                                    label{
                                        min-height: 2.4rem;
                                        padding-left: 3rem;
                                        margin-bottom: 0;
                                        font-weight: normal;                                       
                                        cursor: pointer;
                                        font-size: 1.5rem;
                                        color: #575252 !important;
                                        font-family: 'Montserrat-Medium';
                                        .check-name{
                                            align-self: center;
                                        }
                                    }
                                    input[type="checkbox"], input[type="radio"]{
                                        margin-left: -3rem;
                                        position: absolute;
                                    }
                                }
                                &.textarea{
                                    font-size: 1.5rem;
                                    font-family: 'Montserrat-Regular';
                                }
                            }
                        
                        
                        } 
                    }
    
                    .input-box{
                        input{
                            height: 5rem;
                        }
                    }
                    .input-box input + .label,                    
                    .input-box textarea + .label, 
                     .select2 + .label, 
                     select + .label{                      
                        left: 1rem;       
                        font-size: 1.3rem;                
                    }
                        
                        .input-box{
                            padding-right: 0;
                            margin-bottom: 2.4rem;                           
                        }
                        .textarea{
                            .input-box{
                                margin-bottom: 0;
                            }
                        }
                        .wrap-privacy{                            
                            width: 100%;
                            .privacy{
                                width: 100%;
                                /*
                                .labelContainer{
                                    position: relative;
                                    padding: 0;
                                    display: flex;
                                    align-items: flex-start;
                                    .textContainer{
                                        width: calc(100% - 3.2rem);                                                                   
                                        display: block;    
                                    }
                                }
                                input + .labelContainer:before {
                                    content: "";
                                    position: relative;
                                    display: inline-block;
                                    vertical-align: middle;
                                    min-width: 1.2rem;
                                    height: 1.2rem;
                                    top: 0.4rem;
                                    border: 0.1rem solid #575252;
                                    border-radius: 0 !important;
                                    background-color: transparent;
                                    transition: background-color 0.2s linear 0.1s;
                                }
                                input + .labelContainer:after {
                                    content: "";
                                    position: absolute;
                                    z-index: 1;
                                    min-width: 0.8rem;
                                    height: 0.4rem;
                                    top: 1.2rem;
                                    left: 0.2rem;
                                    border: 0.2rem solid $colorCorporative;
                                    border-top: 0;
                                    border-right: 0;
                                    transform: rotate(-45deg) scale(0);
                                    transform-origin: left top;
                                    transition: all 0.2s ease 0s;
                                }
                                input[type=checkbox]:checked + .labelContainer:after {
                                    transform: translateY(-20%) rotate(-45deg) scale(1);
                                    transition: all 0.2s ease 0.1s;
                                }
                                */
                            }
                            .wrap-btnDefault{
                                margin: 0 auto;
                                margin-right: 0;
                                min-width: unset;
                                .btnDefault{
                                    font-size: 1.5rem;
                                    line-height: 1.7rem;
                                    padding: 1.5rem;
                                    &.mvl{
                                        display: block;
                                    }
                                    &.desc{
                                        display: none;
                                    }
                                  
                                }
                                &.send{
                                    .btnDefault.mvl{
                                        padding: 1.5rem 4.3rem;
                                    }                               
                                }
                            }
                        }      
                        &.hidden{
                            display: none !important;
                            height: auto;
                            opacity: 0;
                            max-height: 0;
                            transition: max-height 0.5s, opacity 0.3s 0.1s linear;
                        }      
                    }

                    .buttons{    
                        justify-content: center;
                        min-width: unset;
                        margin-top: 2.7rem;
                  
                        .wrap-btnDefault{                              
            
                            .btnDefault{
                                font-size: 1.5rem;
                                line-height: 1.7rem;
                                padding: 1.5rem;
                                &.mvl{
                                    display: block;
                                }
                                &.desc{
                                    display: none;
                                }
                            }
                            &.send{
                                .btnDefault.mvl{
                                    padding: 1.5rem 4.3rem;
                                }                               
                            }
                        }            
                    }
                    .form{
                        .first{
                            width: 100%;
                            position: relative;
                            margin-bottom: 5.5rem;                         
                            .legend-title{
                                width: calc(100% + 3rem);                             
                                transform: translateX(-1.5rem);
                                color: #fff;
                                font-size: 2.5rem;
                                text-transform: none;
                                font-family: "Montserrat-SemiBold";
                                padding: 1.3rem 0;
                                background-color: #8ccdd4;
                                margin-bottom: 3.8rem;
                                text-align: center;
                            }             
                        
                            .form-group-special{
                                padding: 2rem 1.5rem;
                                border: 1px solid #575252;     
                                margin-top: 1rem;        
                                padding-left: 1.8rem; 
                                padding-right: 3rem;
                                margin-bottom: 4rem;   
            
                                .wrap-puesto{
                                    display: block;
                                    column-count: 1;
                                    width: 100%;
                                    &.col5{
                                        column-count: 1;
                                    }
                                    &.col3{
                                        column-count: 1;
                                        margin-bottom: 3rem;
                                    }    
                                    .checkbox, .radio{
                                        position: unset;
                                        display: block;
                                        margin-top: 0;
                                        margin-bottom: 2rem;
        

                                        input:before{
                                            content: "";
                                            //height: 2rem;
                                            //width: 2rem;                                           
                                        }   
                                    }                 
                                }
                                .wrap-others{
                                    width: 100%;
                                    .input-box{
                                        margin-bottom: 2rem;
                                    }
                                    .legend{
                                        padding-bottom: 1.5rem;
                                    }
                                }
                                .checkbox, .radio{
                                    position: unset;
                                    display: block;
                                    margin-top: 0;
                                    margin-bottom: 2rem;
            
                                    input:before{
                                        content: "";
                                        height: 2.4rem;
                                        width: 2.4rem;
                                        border: #575252 1px solid;                                  
                                        display: inline-block;
                                        vertical-align: middle;
                                        margin-top: -3px;
                                        font-family: FontAwesome;
                                        background-color: #fff;
                                    }
                                    input:checked:before {
                                        content: "";                                    
                                        color: $colorCorporative !important;
                                        padding: 0.3rem;
                                        background-color: #fff;
                                    }
                                    label{
                                        min-height: 2.4rem;
                                        padding-left: 3rem;
                                        margin-bottom: 0;
                                        font-weight: normal;                                        
                                        cursor: pointer;
                                        font-size: 1.5rem;
                                        color: #575252 !important;
                                        font-family: 'Montserrat-Medium';
                                        .check-name{
                                            align-self: center;
                                        }
                                    }
                                    input[type="checkbox"], input[type="radio"]{
                                        margin-left: -3rem;     
                                        position: absolute;
                                    }
                                }
                                &.textarea{
                                    font-size: 1.5rem;
                                    font-family: 'Montserrat-Regular';
                                }    
                                &.noBorder{
                                    border: none;
                                    padding: 0;
                                }  
                                &.mb2{
                                    margin-bottom: 2rem;
                                }
                                &.margin0{
                                    margin-bottom: 0;
                                }
                                .radio-box{
                                    .radio{
                                        margin-right: 4.5rem;
                                        margin-bottom: 3rem;
                                    }                
                                }    
                                &.wrap-cv-file{
                                    padding: .7rem;
                                    margin-bottom: 1rem;
                                    .cv_file{
                                        position: absolute;
                                        opacity: 0;
                                        &.isSee{
                                            opacity: 1;
                                            position: relative;
                                            padding-top: 1rem;
                                            font-size: 1.5rem;
                                            color: #575252;
                                            font-family: 'Montserrat-Medium';
                                        }
                                    }
                                    .uploadCv{
                                        font-size: 1.3rem;
                                        padding: 1.3rem 0;
                                        width: 100%;
                                    }
                                }                             
                            }
                         
                            .wrap-box{
                                width: 100%;
                            }
                            .legend{
                                font-size: 1.5rem;                             
                                &.medium{                                   
                                    margin-left: 1rem; 
                                    font-size: 1.3rem;                                      
                                }
                            } 
                            .warp-add{
                                position: relative;
                                left: unset;
                                bottom: 0;                          
                                z-index: 1;
                                margin-bottom: 5rem;

                                & > div{
                                    margin: 0 auto;
                                    margin-right: 3.2rem;
                                    .icon.addbutton{
                                        padding-bottom: 0;
                                        img{
                                            width: 3rem;
                                            height: 3rem;
                                            margin-right: 1rem;
                                        }
                                        a{
                                            align-items: center;
                                            display: flex;
                                            font-size: 1.5rem;
                                            color: $colorCorporative;
                                            
                                        }
                                    }
                                }
                                &.mvl{
                                    display: block;
                                }
                                &.desc{
                                    display: none;
                                }
                               
                            } 
                            .wrap-aux{
                                position: relative;
                            }                            
                            
                        }
                        fieldset.form-group-special textarea.form-control{
                            min-height: 30rem;
                            border: none !important;
                            box-shadow: none !important;
                        }
                        .wrap-selectCarnet{
                            width: 100%;
                        }
                        .uploadCv{
                            padding: 1.3rem 2.8rem;
                            background-color: #e4eaea;
                            font-family: 'Montserrat-Medium';
                            font-size: 1.5rem;
                            text-transform: uppercase;
                            line-height: 2.1rem;
                            color: #575252;
                            transition: .2s all ease;
            
                            &:hover{
                                color: #e4eaea;
                                background-color: #575252;
                                transition: .2s all ease;
                            }
                        }
                    }
                    
                    .first, .mb0{                        
                        margin-bottom: 0 !important;                        
                    }
                
            }    
        }
    }    
}