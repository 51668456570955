.container-content.faqs{
    .text.generic, .text.generic *{     
        font-size: 1.5rem !important;
        line-height: 2.1rem !important;
        color: $colorCorporativeGrey;     
    }
    .faqs-nav .nav .text.generic a{
        margin: 0;
        padding: 0;
        font-weight: 600;
    }
    .limited-wrap{
        max-width: 1060px;
        width: 100%;
        margin: 0 auto;
    }
    .section-title{        
        padding-top: 5rem;
        width: 100%;
        
        .title{
            font-size: 3.5rem;
            text-transform: none;
            font-family: "Montserrat-SemiBold";
            font-weight: normal;
            line-height: 4.3rem;
            text-align: left;
        }
        .title-underline{
            width: 3.5rem;
            border-bottom: 4px solid;
            background: $colorCorporative;
            margin-top: 0.5rem;
            margin-bottom: 5.4rem;
        }
    }
    .wrap-hashtags{
        .list-hashtags{
            flex-wrap: wrap;           
            &.notEmpty{
                margin-bottom: 5rem;
            }
        }
        
        .hashtag{
            background-color: #fff;
            padding: 1rem 1.3rem;
            margin-right: 1.5rem;
            margin-bottom: 1.5rem;
            border-radius: 3px;
            box-shadow: 0 2px 4px 0 rgb(0 166 184 / 12%);

            .hashtag-link{
                font-family: 'Montserrat-Regular';
                color: $colorCorporative;
                font-size: 1.7rem;
                letter-spacing: 0;
                line-height: 2.5rem;
            }      
            
            &.is-active{
                box-shadow: 0 2px 4px 0 rgb(0 166 184 / 80%);
                .hashtag-link{
                    font-family: 'Montserrat-SemiBold';
                }

            }

        }
    }
    .form{        
        .input-box{
            margin-bottom: 5rem;
            .form-control{
                background-color: inherit;
            }
            input +.label{
                margin: 0;
                font-size: 1.7rem;
                top: 50%;
                transform: translateY(-50%) scale(1);
                transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
            }
            input:focus + .label,  input:valid + .label{
                top: 0;
                transform: translateY(-50%) scale(0.8);
            }
        }
    }
    .faqs-list{
        display: block;
        margin-bottom: 13.5rem;
        border-bottom: 1px solid $colorCorporative;

        .no-result{
            color: $gray;
            padding: 3rem 0;
        }
        .result{  
            border-top: 1px solid $colorCorporative;
            position: relative;         
            .num{
                position: absolute;
                font-size: 1.3rem;
                top: 1rem;
                color: $colorCorporative;
            }
           p.faq_link{
               position: relative;              
               font-family: 'Montserrat-SemiBold';         
               color: #00a6b8;
               font-size: 1.9rem;
               letter-spacing: 0;
               padding: 1.6rem;
               padding-left: 2.6rem;      
               z-index: 1;     
           }
            a.faq_link{
                position: absolute;
                pointer-events: none;
                opacity: 0;
                visibility: hidden;
            } 
           .wrap-content{                  
               padding-bottom: 0;
               max-height: 0;
               overflow: hidden;
               opacity: 0;
               transition: max-height .5s, opacity .3s .1s linear; 
         
               .content{
                    padding: 2.6rem;
               }
               .wrap-media{                  
                    padding-left: 2.6rem;
               
                    iframe{                        
                        &+.aux-slider{
                            margin-top: 2rem;
                        }
                    }
                    .oneImg{
                        width: 50%;

                        &.notOnly{
                            width: 48%;
                        }
                    }
                                  
                    .video {
                        width: 80%;
                        height: auto;
                        aspect-ratio: 16/9;
                        background-color: transparent;
                        object-fit: contain;
                        object-position: left;
                        iframe{
                            width: 100%;
                            height: 100%;
                        }

                        &.notOnly {
                            width: 48%;           
                            iframe{
                                width: 100%;
                                height: 100%;
                            }             
                        }  
                    }
                    .warp-img{                      
                        .img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            max-height: 20vh;
                            min-height: 20vh;
                        }
                    }
                                      
               }
           }
           &:after{          
                content: "+";      
                color: $colorCorporative;               
                font-size: 2.5rem;
                font-family: 'Montserrat-SemiBold';          
                position: absolute;
                right: 2rem;
                top: 1.3rem;
               
                transform: translateX(50%) rotate(180deg);
                transition: all 0.3s;
           }
           &.isOpen{
                &:after{
                    content: "-";  
                    top: .3rem;
                    font-size: 3.5rem;                        
                    transform: translateX(50%)  rotate(0deg);
                    transition: all 0.3s;
                }
                p.faq_link{                
                    font-family: 'Montserrat-Bold';        
                } 
                .hidden{
                    display: block !important;
                    max-height: 9999px;              
                    opacity: 1;
                    transition: max-height 1s, opacity .2s linear;
                   
                }
                .wrap-content{
                    max-height: 9999px;
                    padding-bottom: 11.8rem;
                    overflow: visible;
                    height: auto;
                    opacity: 1;
                    transition: max-height .5s, opacity .3s .1s linear;      
                   }
                
           }
           .hidden{
            display: none !important;
            height: auto;
            opacity: 0;
            max-height: 0;
            transition: max-height .5s, opacity .3s .1s linear;           
            }
        }
    }


    /***********SLIDER CON DETALLE*************/

// PHOTO Full screen
                
.mygallerybox{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: -1;
    opacity: 0;
    visibility: hidden;

    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;
  
    &.preopen,
    &.open{
        z-index: 1040;	
        visibility: visible;
    }

    &.open {
        opacity: 1;
        visibility: visible;
    }

    & .swiper-container{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    

    .slide-img{
      
        max-width: 90%;
        max-height: 90%;
        position: absolute;
        top: 0;
        left:0;
        right:0;
        bottom: 0;
        margin: auto;


        @media (max-width: $md-width) {
            max-width: 100%;
            max-height: 100%;
        }
      
       
    }

    .btn-close {    
        position: absolute;
        top: 5rem;
        right: 5rem;
        padding: 0;
        background-color: transparent;          
        -webkit-transition: 0.3s ease-in all;
        transition: 0.3s ease-in all;
        z-index: 1040;
        width: 3rem;
        height: 3rem;
        opacity: .75;
        &:hover{
            background-color: transparent;
        }

        &::before{
            content: "";
            position: absolute;
            background-image: url('/images/close-x.svg');
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            //transform: rotate(45deg);
        }
        
        &:hover{
            opacity: 1;            
        }
    }

    .swiper-button-prev,
    .swiper-button-next {      
        width: 4rem;
        height: 4rem;
        background-image: none;
    }
    .swiper-button-prev{
        left: 15px;          

        &:before{
            content: '';
            position: absolute;
            width: 4rem;
            height: 4rem;
            background-image: url('/images/right.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            transform: rotate(180deg);
        }
    }
    .swiper-button-next {
        right: 15px;

        &:before{
            content: '';
            position: absolute;
            width: 4rem;
            height: 4rem;
            background-image: url('/images/right.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

    .aux-slider{        
        padding-right: .5rem;

        @media (max-width: $sm-width) {
            padding-right: 0;
            width: calc(100% + 3rem);   
            transform: translateX(-1.5rem);
            .pasadorPost .swiper-wrapper .swiper-slide{
                padding-right: 0.8rem;          
            }
            .swiper-wrapper{
                display: flex;
                align-items: center;

                .swiper-slide{    
                    width: 100%;
                                       
                    .img-responsive{                       
                        height: 100%;
                        width: auto;
                        object-fit: contain;
                        max-height: 20rem;        
                    }
                }
            }
        }
    }
    .pasadorPost{
        width: 100%;
        position: relative;
        background-color: transparent;    
        margin-right: 0.5rem;
        min-height: unset;      
        margin-bottom: 0;

        .img-responsive{
            max-width: 50rem;
            max-height: 50rem;
        }

        &.pasadorPost-res .img-responsive{
            max-width: 50rem;
            max-height: 50rem;
        }

        .swiper-wrapper{
            .swiper-slide{
                padding-right: 1.5rem;               
                width: auto !important;
                align-self: center;
            }
        }
        .control {
            width: 4rem;
            height: 4rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
            color: transparent;
            border: none;
            font-size: unset;
            opacity: .75;
            transition: .2s all ease-in;
            z-index: 1;
            &:hover{
                opacity: 1;
            }
            &.swiper-button-disabled{
                transition: .2s all ease-in;
                opacity: 0;
            }
          
            &.control-prev{              
                width: 4rem;
                height: 4rem;
                left: 1rem;
               
                &:before{
                    content: "";
                    background-image: url('/images/right.svg');
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: 0;
                    left: 0;
                    transform: rotate(180deg);
                }
               
            }
            &.control-next{            
                width: 4rem;
                height: 4rem;
                right: 1rem;
                
                &:before{
                    content: "";
                    background-image: url('/images/right.svg');
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: 0;
                    left: 0;
                }
            }
        }
        @media (max-width: $md-width) {            
            margin-bottom: 4.3rem;
            margin-right: 0;   
        }	       
    }
}

@media (max-width: $md-width) {
    .container-content.faqs{
        .faqs-nav{
            background-color: transparent;
            box-shadow: none;
            border: none;
            border-radius: none;
        }  
    
        .section-title{        
            padding-top: 0;

            .title{
                font-size: 2.5rem;          
                line-height: 3.3rem;            
            }
            .title-underline{              
                border-bottom: 2px solid;
                margin-bottom: 3.4rem;
            }
        }
        .wrap-hashtags{
            .list-hashtags{                      
                &.notEmpty{
                    margin-bottom: 3.5rem;
                }
            }
            
            .hashtag{   
                margin-right: 1rem;          
                .hashtag-link{               
                    font-size: 1.5rem;                   
                    line-height: 2.1rem;
                }       
    
            }
        }
        .form{       
            display: block; 
            .input-box{
                margin-bottom: 5rem;
                .form-control{
                    background-color: inherit;
                }
                input +.label{
                    margin: 0;
                    font-size: 1.7rem;
                    top: 50%;
                    transform: translateY(-50%) scale(1);
                    transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
                }
                input:focus + .label,  input:valid + .label{
                    top: 0;
                    transform: translateY(-50%) scale(0.8);
                }
            }
        }
        .faqs-list{
            display: block;
            margin-bottom: 13.5rem;
            border-bottom: 1px solid $colorCorporative;
    
           .result{
            position: relative;
            border-top: 1px solid $colorCorporative;
            position: relative;         
                .num{
                    position: absolute;
                    font-size: 1.3rem;
                    top: 1rem;
                    color: $colorCorporative;
                }
               p.faq_link{          
                   font-size: 1.5rem;
                   line-height: 2.1rem;                  
                   padding: 1.6rem;
                   padding-left: 2.6rem;  
                   padding-right: 5.4rem; 
                          
               }
                a.faq_link{
                    position: absolute;
                    pointer-events: none;
                    opacity: 0;
                    visibility: hidden;
                } 
               .wrap-content{              
                   .content{
                        padding: 2.6rem;
                   }
                   .wrap-media{
                    align-items: unset;
                    justify-content: unset;

                    flex-direction: column;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;

                        .oneImg{
                            width: 100%;
                            &.notOnly{
                                width: 100%;
                                margin-top: 3rem;
                            }
                        }
                        .slider-instructions, .slider-faqs{  
                            &.notOnly{
                                margin-top: 3rem;
                                width: calc(100% + 1rem);                                
                            }
                            &.only{
                                width: calc(100% + 1rem);
                            }
                        } 
                      
                        .video{
                            width: 100%;
                            height: auto;
                            aspect-ratio: 16/9;
                            background-color: transparent;
                            object-fit: contain;
                            object-position: left;
                            &.notOnly {
                                width: 100%;                        
                            }  
                        }
                        .warp-img{                           
                            .img{
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                max-height: 35vh;
                                min-height: 35vh;
                            }
                        }
                                 
                        .slider-instructions, .slider-faqs{
                            .item{                              
                                .warp-img{                            
                                    width: 100%;
                                    height: 100%;
                                    padding-left: 0;
                                    padding-right: 1rem;                            
                                }
                            }
                            .slick-prev	{
                           
                                left: 1rem;
                                display: none;
                                width: 4rem;
                                height: 4rem;
                                opacity: 1;
                                
                                &:before{
                                    content: '';
                                    background-image: url('/images/left.svg');
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: contain;
                                    top: 0;
                                    left: 0;
                                }
                    
                            }
                            .slick-next	{                                
                                right: 1rem;
                                display: none;
                                width: 4rem;
                                height: 4rem;
                                opacity: 1;
                    
                                &:before{
                                    content: '';
                                    background-image: url('/images/right.svg');
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: contain;
                                    top: 0;
                                    left: -1rem;
                                }
                            }
                        }
                       
                   }
               }
               &:after{          
                    content: "+";                                
                    font-size: 2rem;
                    right: 0;     
                    transform: translateX(0) rotate(180deg);
                    transition: all 0.3s;             
               }
               &.isOpen{
                    &:after{
                        content: "-";                        
                        font-size: 3rem;                        
                        right: 0;     
                        transform: translateX(0) rotate(0);
                        transition: all 0.3s;
                    }   
                    .wrap-content{
                        padding-bottom: 5.8rem;  
                    }          
               } 
    
           }
        }
    
    }
}