//***************************************************************************************************//
// NAV
nav.topbar{
    height: 6.5rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 5rem;
	margin-top: 3.7rem;
    z-index: 101;
    transform: translateX(0);
    transition: all  0.3s ease-in-out;

    //CAPA full black(open menu vertical)
    .full-level-black{
        position: relative;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: -3.7rem;
        left: 0;
        opacity: 0;
        z-index: -1;
        visibility: hidden;
        background-color: rgba(0, 0, 0, 0.5);


        &.isVisible{
            visibility: unset;
            opacity: 1;
            z-index: 1;
            transition: .2s opacity ease;
        }
    }


	.container-nav{
		background-color: $colorCorporative;
		padding: 0 2.2rem;

        position: inherit;
        width: calc(100% - 10rem);
        z-index: 3;

		.container-aux-nav{
			justify-content: space-between;
		}
	}
	.menu-burger{
        min-width: 2.7rem;

		.item-burger{
			align-items: center;
		}
	}
    .logo-movil{
        display: none;
        .logo{
            height: 2.6rem;
            img{
                height: 100%;
            }
        }
    }
	.wrap-items{
		justify-content: space-between;
	}

    .ham  {
        cursor: pointer;
        margin-right: 0;
        &+.hide-menu+.logo {
            height: unset;
        }
	}

    .logo {
        img {
            height: 4rem;
            object-fit: contain;
        }
    }
//***************************************************************************************************//
// MENU HORIZONTAL
    .menu.menu-horizontal {
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        margin: 0 auto;
        transition: padding $t-fast ease-in;
		font-family: 'Montserrat-Regular';
		display: flex;
        padding-left: 0;

        & > .item {
            line-height: normal;
			font-weight: normal;
			font-size: 1.7rem;
			padding-left: 3.2rem;
			padding-right: 3.2rem;

            &.logo-item{
                a:hover{
                    &:before{
                       content: none;
                    }
                }
            }

            a {
                position: relative;
                color: #fff;
				text-transform: none;
            }
            h1{
                font-size: 1.7rem;
                margin-bottom: 0;
                font-family: inherit;
            }
            &.is-active {
                font-family: 'Montserrat-SemiBold';
                a{
                    text-decoration: none;
                    color: #fff;

                    &:before{
                        background-color: #fff;
                    }
                }
            }
            &.no-active{
                opacity: .5;
                &:hover, &:active{
                    opacity: 1;
                }
            }


            a:before {
                content: '';
                position: absolute;
                height: 1px;
                background-color: #fff;
                transform: scaleX(0);
                right: 0;
                bottom: -6px;
                left: 0;
                transition: transform $t-fast ease-in-out;
            }

            a:hover:before {
                transform: scaleX(1);
            }
        }

    }

    .btn-search  {

        margin-left: 2rem;
        cursor: pointer;

        @include media-breakpoint-up(xl){
            margin-left: 3rem;
        }

        @include media-breakpoint-down(lg){
            margin-left: 0;
        }
    }
    .close.noSeeDesk{
        display: none;
    }
// END MENU HORIZONTAL


//***************************************************************************************************//
// MENU VERTICAL
    .menu-vertical {
        width: 37.4rem;
        height: 100vh;
        position: absolute;
        padding-top: 13.2rem;
        z-index: 2;
        top: -3.7rem;
        left: 0;
        background-color: #fff;
        transform: translateX(-37.4rem);
        transition: transform .3s linear;
        padding-bottom: calc(1.3rem + 2rem + 1.8rem);
        .menu-vertical-box{
            height: 100%;
            width: 100%;
            position: relative;
            overflow-y: auto;
        }

        & .menu-vertical-box > ul {
            list-style: none;
            margin-left: 0;
            padding-left: 5rem;
            padding-right: 4.7rem;
            padding-top: .5rem;

            .item a {
                display: block;
                color: $colorCorporative;
                padding-top: .5rem;
                padding-bottom: .5rem;
                padding-left: 0;
                padding-right: 0;
                background-color: transparent;
                border-bottom: 1px solid $line-gray;
                font-size: $f-size-menu-vertical;
                font-weight: normal;
                transition: background-color $t-fast ease-in-out;
                text-transform: none;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                transition: .3s font-weight ease;

                &:hover {
                    background-color: transparent;
                    text-decoration: none;
                    font-weight: 600;
                    transition: .3s font-weight ease;
                }
            }
            .item.is-open{
                background-color: darken($gray, 20%);
                background-color: transparent;
                border-bottom: none;
                &>a{
                    border-bottom: none;
                }
                a{
                    color: #fff;
                    border-bottom: none;
                    li a:last-of-type{
                        border-bottom: 1px solid rgba(#fff, 0.5);
                    }
                }
            }
            .item.top-separator{
                border-top: 1px solid transparent;
            }

           li.item > ul {
                list-style: none;
                padding: 0;
                background-color: transparent;

                > li.item a{
                    color: #fff;
                    padding-top: 1.2rem;
                    padding-bottom: 1.2rem;
                    padding-left: 2rem;
                    padding-right: 2rem;
                    font-size: $f-size-menu-vertical;
                    font-family: 'Montserrat-Regular';
                    display: flex;
                    align-items: center;

                    text-transform: capitalize;

                    &:hover {
                        background-color: rgba(#000, 0.05);
                        text-decoration: none;

                        &:before
                        {
                            transform: translateX(0.5rem);
                        }
                    }
                }
            }
        }
        .menu-categories-productos{
            margin-bottom: 3.4rem;
            &.mvl{
                display: none;
            }
        }
        .menu-general{
            margin-top: 0;
            padding-left: 0;
            padding-right: 0;
            margin-left: 5rem;
            >.item{
                >a{
                    text-transform: uppercase !important;
                }
            }
            .item{
                a, p{
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    font-family: 'Montserrat-SemiBold';
                }
                &.hasChild{
                    >p{
                        text-transform: uppercase;
                        position: relative;
                        padding-left: 1.5rem;
                        display: block;
                        color: #1197a5;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                        padding-right: 0;
                        background-color: transparent;
                        border-bottom: 1px solid rgba(63, 101, 116, 0.5);
                        font-size: 1.3rem;
                        font-weight: normal;
                        transition: background-color 0.2s ease-in-out;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        transition: 0.3s font-weight ease;

                        &.isOpen{
                            &:before{
                                content: "-";
                                font-size: inherit;
                                font-family: inherit;
                                color: inherit;
                                position: absolute;
                                left: -1rem;
                                padding-right: 1.3rem;
                                transform: translateX(50%) rotate(0);
                                transition: all 0.3s;
                            }
                        }
                    }
                    .isOpen +.sublist{
                        max-height: 9999px;
                        transition: 0.3s all;
                    }
                    >p:before{
                        content: "+";
                        font-size: inherit;
                        font-family: inherit;
                        color: inherit;
                        position: absolute;
                        left: -1rem;
                        padding-right: 1.3rem;
                        transform: translateX(50%) rotate(360deg);
                        transition: all 0.3s;
                    }
                    >p +.sublist{
                        overflow: hidden;
                        max-height: 0;
                        transition: 0.3s all;
                        .item{
                            a{
                                padding: 0.5rem 0 0.5rem 1.5rem;
                                font-family: 'Montserrat-SemiBold';
                                color: #1197a5;
                                text-transform: none;
                            }
                        }
                    }

                }
            }
            &:hover{
                .item a{
                    opacity: .6;
                    transition: .3s opacity ease;
                    &:hover{
                        opacity: 1;
                        transition: .3s opacity ease;
                    }
                }
            }
        }

    }
//EMD MENU VERTICAL

//***************************************************************************************************//
//MENU HANBURGUESA
    .ham {
        display: flex;
        align-items: center;
        height: 5rem;

        div {
            position: relative;
        }

        div,
        div:before,
        div:after{
            height: 2px;
            background-color: #fff;
            position: relative;
            width: 2.7rem;
            content: '';
            transition: all 0.3s ease-in-out;
        }

        div:before,
        div:after{
            position: absolute;
			width: 1.9rem;
			left: 0.4rem;
        }

        div:before{
            top: -9px;
        }
        div:after{
            bottom: -9px;
        }

        &:hover{
            div,
            div:before,
            div:after{
                background-color: #fff;
            }
        }
    }

    .hide-menu{
        position: absolute;
        left: 3.8rem;
        opacity: 0;
        z-index: -1;
        transition: 0.4s ease-in-out all;
        font-size: rem(9px);
        text-transform: uppercase;
        width: 5rem;
        line-height: 1.5;
        color: $gray-dark;
    }

    .if-open-hidden{
        opacity: 1;
        transition: 0.4s ease-in-out all;
        h1{
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            margin: 0;
        }
    }
    &.is-open{
        transform: translateX(0);
        .container-nav{
            z-index: 3;
            //position: relative;
        }
        .menu-vertical {
           transform: translateX(0);
           transition: transform .3s linear;

        }

        .ham{
            .item-ham{
                &:before{
                    width: 3.2rem;
                    transform-origin: center;
                    transform: rotate(-45deg);
                    left: -.2rem;
                }
                &:after{
                    width: 3.2rem;
                    transform-origin: center;
                    transform: rotate(45deg);
                    left: -.2rem;
                }

                &:before,
                &:after{
                    background-color: #fff;
                }
                &{
                    background-color: transparent;
                }
            }
        }
        .hide-menu{
            opacity: 1;
            z-index: 1;
            transition: 0.4s 0.8s ease-in-out all;
            cursor: pointer;
        }

        .if-open-hidden{
            opacity: 0;
        }
    }
    @include media-breakpoint-down(sm){
         .row {
            margin: 0 -15px!important;
        }
    }
}
// END MENU HANBURGUESA


//***************************************************************************************************//
// MOVIL-TABLET

@media only screen and (max-width: $md-width){
	nav.topbar{
        height: 5.5rem;
		padding: 0 1.5rem;
		margin-top: 1.5rem;

        .close.noSeeDesk{

            width: 2.2rem;
            background-color: #1197a5;
            height: 5.5rem;
            position: absolute;
            opacity: 1;
            z-index: 2;

            .icon{
                background-color: #1197a5;
                width: 100%;
                height: 100%;
                z-index: 2;

                &:before{
                    content: '';
                    width: 2.2rem;
                    height: 2.2rem;
                    position: absolute;
                    background-image: url('/images/cancel-copy-white.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    top: 2rem;
                    left: unset;
                    right: 0;
                }
            }
        }
        .full-level-black{
            top: -1.5rem;
        }
		.container-nav{
			padding: 0 1.5rem;

            position: fixed;
            width: calc(100% - 3rem);
            z-index: 3;

            .wrap-items{
                width: 2.2rem;
                transition: .1s all ease;
                &.notVisible{
                    visibility: hidden;
                    transition: .1s all ease;
                }
            }
            .menu-burger{
                opacity: 1;
                transition: .2s 0.2s visibility ease, .2s .1s opacity ease;
                &.notVisible{
                    visibility: hidden;
                    opacity: 0;
                    transition: .1s all ease;
                }
            }

		}
		.menu.menu-horizontal {
			display: none;
		}
        .logo-movil{
            display: flex;
        }
        .menu-vertical {
            background-color: $colorCorporative;
            transform: translateX(-100vw);
        }
        &.is-open{

            .ham{
                div{
                    &:before{
                       left: -.5rem;
                    }
                    &:after{
                        left: -.5rem;
                    }
                }
            }

            .btn-search {
                width: 100%;
                background-color: $colorCorporative;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                z-index: 10;
            }

        }
        .menu-vertical {
            position: absolute;
            margin: 1.5rem;
            width: calc(100% - 3rem);
            top: -1.5rem;
            padding-top: 5.5rem;
            padding-bottom: 5.5rem;
            height: calc(100vh - 9rem);
            background-color: $colorCorporative;
            overflow-x: hidden;
            overflow-y: auto;
            padding-right: 1.5rem;
            .menu-vertical-box{
                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                }
                &::-webkit-scrollbar:vertical {
                    width:5px;
                }
                &::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
                    display: none;
                }
                &::-webkit-scrollbar:horizontal {
                    padding-right: .5rem;
                    height: 8px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #fff;
                    border-radius: none !important;
                    border: none;
                }
                &::-webkit-scrollbar-track {
                    background-color: rgba(#fff, 0.5);;
                    border-radius: none !important;
                }
            }
            .menu-vertical-box > ul{
                padding-right: 3.5rem;
                padding-left: 3.5rem;
                .item a{
                    font-family: 'MONTSERRAT-BOLD';
                    color: #fff;
                    text-transform: uppercase;
                    border-bottom: 1px solid rgba(#fff, 0.5);
                }
                .item.is-open{
                    background-color: darken($gray, 20%);
                    background-color: transparent;
                    border-bottom: none;
                    &>a{
                        border-bottom: none;
                    }
                    a{
                        color: #fff;
                        border-bottom: none;
                        li a:last-of-type{
                            border-bottom: 1px solid rgba(#fff, 0.5);
                        }
                    }
                }
                .item.top-separator{
                    border-top: 1px solid transparent;
                }

               li.item > ul {
                    list-style: none;
                    padding: 0;
                    background-color: transparent;

                    > li.item a{
                        color: #fff;
                        padding-top: 1.2rem;
                        padding-bottom: 1.2rem;
                        padding-left: 2rem;
                        padding-right: 2rem;
                        font-size: $f-size-menu-vertical;
                        font-family: 'Montserrat-Regular';
                        display: flex;
                        align-items: center;

                        text-transform: capitalize;

                        &:before  {
                            content: none;
                        }

                        &:hover {
                            background-color: rgba(#000, 0.05);
                            text-decoration: none;

                            &:before
                            {
                                transform: translateX(0.5rem);
                            }
                        }
                    }
                }
                &.menu-general{
                    margin-top: 3rem;
                    .item a{
                        color: #fff !important;
                        font-size: 1.5rem  !important;
                        font-family: 'Montserrat-Regular'  !important;
                    }
                }
            }
            .menu-categories-productos{
                margin-bottom: 0;
                &.desk{
                    display: none;
                }
                &.mvl{
                    display: block;
                    .itemName{
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                    .itemName{
                        color: #fff;
                        font-size: 1.3rem;
                    }
                    .sub-menu {
                        .itemName{
                            line-height: 3rem;
                            font-size: 1.3rem;
                            color: #fff;
                            padding-left: calc(2.6rem + 1.1rem);
                            font-family: 'Montserrat-SemiBold';
                            position: relative;

                            &:before{
                                content: '';
                                width: 4px;
                                height: 4px;
                                background-color: white;
                                position: absolute;
                                left: 25px;
                                top: 0.65rem;
                                border-radius: 50%;
                            }
                        }

                        .sub-sub-menu {
                            .itemName{
                                padding-left:  calc(2.6rem + 1.1rem + 1.1rem);
                                font-family: 'Montserrat-Regular';

                                &:before{
                                    content: none;
                                }
                            }

                        }
                    }
                    .item{
                        >.itemName{
                            line-height: 3.2rem;
                            padding-left: 2.6rem;
                            color: #fff;
                            font-size: 1.3rem;
                            text-transform: uppercase;
                            font-family: 'Montserrat-Bold';
                        }

                    }

                    .item.hasChild{
                        border-bottom: 1px solid rgba(#fff, 0.5);
                        .itemName{
                            position: relative;

                            &::before{
                                content: "+";
                                font-size: inherit;
                                font-family: inherit;
                                color: inherit;
                                position: absolute;
                                left: 0;
                                padding-right: .5rem;
                                transform: translateX(50%) rotate(360deg);
                                transition: all .3s;
                                font-size: 2rem;
                            }
                        }

                        &.isOpen{
                            border-bottom: none;
                            .itemName{
                                position: relative;

                                &::before{
                                    content: "-";
                                    font-size: inherit;
                                    font-family: inherit;
                                    color: inherit;
                                    position: absolute;
                                    left: 0;
                                    padding-right: 1.3rem;
                                    transform: translateX(50%) rotate(0);
                                    transition: all .3s;
                                    font-size: 2rem;
                                }
                            }
                            & +.sub-menu{
                                max-height: 9999px;
                                transition: .3s all;
                                border-bottom: 1px solid rgba(#fff, 0.5);
                                padding-bottom: 1.8rem;
                            }

                        }
                        & +.sub-menu{
                            overflow: hidden;
                            max-height: 0;
                            transition: .3s all;
                        }
                    }
                }
            }
            .menu-general{
                margin-top: 0;
                padding-left: 3.5rem;
                padding-right: 3.5rem;
                margin-right: 0;
                margin-left: 0;
                .item{
                    a{
                        text-transform: uppercase;
                        font-size: 1.5rem;
                    }
                }
                .item.hasChild p{
                    color: #fff;
                    font-family: inherit;
                    font-size: inherit;
                    padding: inherit;
                    padding-left: 1.5rem;
                    border: inherit;
                }
                .item.hasChild{
                    .isOpen.sublist{
                        .item{
                            a{
                                color: #fff !important;
                                font-family: inherit !important;
                                padding: inherit;
                                padding-left: 1.5rem;
                                font-size: inherit;
                                font-family: inherit;
                            }
                        }
                    }
                }
            }
            .languages{
                margin-top: 0;
                position: fixed;
                bottom: 0;
                width: 100%;
                padding-right: 3.5rem;
                .list-languages{
                    &:hover{
                        .lang-item{
                            opacity: 1;

                        }
                    }
                }
                .lang-current{
                    border-bottom: 3px solid #fff;
                }
                .lang-item{
                    margin-right: 1.9rem;
                    opacity: 1;
                    transition: .3s opacity ease;
                    &:last-of-type{
                        margin-right: 0;
                    }
                }
                a, .a{
                    font-family: 'Montserrat-Regular';
                    font-size: 1.5rem;
                    text-transform: uppercase;
                    letter-spacing: 3.42px;
                    color: #fff;
                }
            }
        }
	}
}


//MENU languages
.languages{
    width: 100%;
    justify-content: flex-end;
    display: flex;
    padding-left: 5rem;
    padding-right: 4.7rem;
    position: fixed;
    padding-bottom: 2rem;
    padding-top: 2.3rem;
    bottom: 0;
    .list-languages{
        &:hover{
            .lang-item{
                opacity: .6;
                transition: .3s opacity ease;
                &:hover{
                    opacity: 1;
                    transition: .3s opacity ease;
                }
            }
        }
    }
    .lang-current{
        border-bottom: 3px solid #1197a5;
    }
    .lang-item{
        margin-right: 1.6rem;
        opacity: 1;
        transition: .3s opacity ease;
        &:last-of-type{
            margin-right: 0;
        }
    }
    a, .a{
        font-family: 'Montserrat-Regular';
        font-size: 1.3rem;
        text-transform: uppercase;
        letter-spacing: 3.42px;
        color: #1197a5;
    }

}
// END MENU languages
