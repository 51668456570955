/*** START OLD ***/
.modal-finder
{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(white, 0.95);
    z-index: 10;
    overflow-y: auto;
    color: $gray-dark;

    a{
        color: #666;

        &:hover{
            color: #999;
        }
    }

    .wrapp
    {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;      
        width: 80%;
        margin: auto;
        padding-top: 5rem;

        label
        {
            font-size: 1.93rem;
            font-family: Arvo, Arial, Tahoma;
            text-transform: uppercase;
            display: flex;
            align-items: center;

            img
            {
                margin-right: 0.5rem;
            }
        }
        input
        {
            font-size: $f-size-big-title;
            border: none;
            outline: none;
            box-shadow: none;
            background-color: transparent;
            width: 100%;
            display: block;
            font-weight: 500;
            margin-left: 2rem;
            margin-bottom: 2.5rem;
        }

        .close
        {
            font-size: 2.5rem;
            position: absolute;
            top: 4rem;
            right: 0;
        }

        .results
        {
            .results-title
            {
                font-family: Arvo, Arial, Tahoma;
                font-size: $f-size-medium;
                font-weight: bold;
                text-transform: uppercase;
            }

            .item
            {
                .image,
                img.image
                {
                    width: 90px;
                    height: 60px;
                    object-fit: cover;
                    margin-right: 1.5rem;
                }

                font-size: 25px;

                padding: 15px 0;

            }
        }

    }
}

/*** END OLD ***/

//FAMA NEW

    .app_2021 .modal-finder,
    .modal-finder.modal-finder_2021 {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 1);
        overflow-y: auto;
        color: #324b4e;
        z-index: 100;

        .wrapp {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            margin: auto;
            padding-top: 15rem;
            height: 100%;
            padding-left: 5rem;
            padding-right: 5rem;

            .non-results{
                .wrap-NoResult{
                    position: relative;
                    width: 100%;
                    max-width: 85rem;
                    margin: 0 auto;
                    margin-top: 11.8rem;
                    font-size: 1.5rem;
                    color: #575252;
                    padding: 2.4rem 4.9rem;
                    background-color: #bae6eb;
                }
            }

            .aux-wrap{
                position: relative;
                width: 100%;
                max-width: 85rem;
                margin: 0 auto;
                margin-top: 5.5rem;

                .wrap-search{

                }
                .text-result{
                    color: $colorCorporative;
                    font-size: 2.1rem;
                    margin-bottom: 1.1rem;

                    .title{
                        font-family: 'Montserrat-Bold';
                        font-size: 2.1rem;
                        margin-bottom: 0;
                    }

                    .text-search{
                        font-family: 'Montserrat-Regular';
                        font-size: 2.1rem;
                    }

                }
            }
            .title{
                margin-bottom: 4rem;
                font-size: 4rem;
                font-family: 'Montserrat-ExtraBold';
                color: #1197a5;
                line-height: normal;
                letter-spacing: 0;
            }

            .form{
                .input-box{
                    input{
                        margin-left: 0;
                        border: 1px solid #575252;
                        font-size: 1.4rem;

                        &+.label{
                            text-transform: none;
                            font-size: 1.5rem;
                            margin: 0;
                            font-family: 'Montserrat-Medium';
                            font-weight: normal;
                            background-color: #fff;
                            line-height: 1.5;
                        }
                    }
                }
            }

            .close {
                font-size: unset;
                position: absolute;
                top: 15rem;
                right: 7rem;
                width: 2.2rem;
                height: 2.2rem;

                .icon{
                    width: 2.2rem;
                    height: 2.2rem;

                &:before{
                        content: '';
                        width: 2.2rem;
                        height: 2.2rem;
                        position: absolute;
                        background-image: url('/images/cancel-copy.svg');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        top: 0%;
                        left: 0;
                    }
                }

            }

            .results{
                padding-bottom: 16rem;
                .results-title {
                    font-family: Arvo, Arial, Tahoma;
                    font-size: 1.5625rem;
                    font-weight: bold;
                    text-transform: uppercase;
                }
                .item {
                    font-size: 25px;
                    padding: 15px 0;
                }

                .item .image,
                .item img.image {
                width: 90px;
                height: 60px;
                -o-object-fit: cover;
                object-fit: cover;
                margin-right: 1.5rem;
            }

            .wrap-category{
                    display: flex;
                    flex-wrap: wrap;
                    max-width: 1280px;
                    width: calc(100% + 2rem);
                    margin: 0 auto;
                    margin-top: 5.5rem;

                .category{
                    width: calc(100% - 2rem);
                    height: 4.5rem;
                    background-color: #3f6574;
                    text-transform: uppercase;
                    padding-left: 2rem;

                        .title{
                            font-size: 2.1rem;
                            line-height: 4.5rem;
                            margin-bottom: 0;
                            color: #fff;
                            font-family: 'Montserrat-Medium';
                        }

                        .num{
                            line-height: 4.5rem;
                            color: #fff;
                            font-family: 'Montserrat-Medium';
                            text-transform: lowercase;
                        }
                }
                .item{
                    display: block;
                    width: calc(100% / 4);
                    padding-top: 2.8rem;
                    padding-right: 2rem;

                        .wrap-img{
                            width: 100%;
                            margin-bottom: 1.4rem;
                            .wrap-img-aux{
                                width: 100%;
                                padding-top: 64%;
                                position: relative;
                                overflow: hidden;
                                background-color: #bae6eb;
                                &::before{
                                    content: '';
                                    position: absolute;
                                    background-color: transparent;
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    left: 0;
                                    transition: .3s all ease;

                                }
                                .image{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    transform: scale(1);
                                    transition: .3s transform linear;
                                }
                            }
                        }

                        .item-name{
                            font-size: 2.1rem;
                            color: 1.4rem;
                            /* padding-top: 1.4rem; */
                            color: #1197a5;
                            font-family: 'Montserrat-Regular';
                            line-height: 2.8rem;
                            transition: .3s all ease;
                        }
                        &:hover{
                            .wrap-img-aux::before{
                                content: '';
                                position: absolute;
                                background-color: rgba(17, 151, 165, 0.5);
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                z-index: 1;
                                transition: .3s all ease;

                            }
                            .wrap-img .wrap-img-aux .image{
                                transform: scale(1.1);
                                transition: .3s transform linear;
                            }
                            .item-name{
                                font-family: 'Montserrat-Bold';
                                transition: .3s all ease;
                            }
                        }
                }
            }

            }

        }
    }

    @media (max-width: $md-width) {
        .app_2021 .modal-finder,
        .modal-finder.modal-finder_2021{
            background-color: #1197a5;
            left: 1.5rem;
            top: 7rem;
            right: 1.5rem;
            bottom: 1.5rem;
            padding-right: 1.5rem;
            padding-bottom: 1.5rem;
            padding-top: 4rem;
            overflow-y: hidden;
            overflow-x: hidden;


            .aux-box{
                overflow-y: auto;
                width: 100%;
                height: 100%;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    //right: 1.5rem;
                }
                &::-webkit-scrollbar:vertical {
                    width:5px;
                }
                &::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
                    display: none;
                }
                &::-webkit-scrollbar:horizontal {
                    padding-right: .5rem;
                    height: 8px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #fff;
                    border-radius: none !important;
                    border: none;
                }
                &::-webkit-scrollbar-track {
                    background-color: rgba(#fff, 0.5);;
                    border-radius: none !important;
                }
            }
            .wrapp{
                background-color: #1197a5;
                padding: 1.5rem;
                padding-top: 0;

                .aux-wrap{
                    margin-top: 0;

                    .wrap-search{
                        .title{
                            color: #fff;
                            font-size: 2.5rem;
                            margin-bottom: 2.6rem;
                        }
                        .form{
                            display: block;
                            .input-box{
                                input{
                                    border: 1px solid #fff;
                                    color: #fff;
                                }
                                input +.label{
                                    color: #fff;
                                    background-color: $colorCorporative;
                                }
                            }
                        }
                    }
                    .text-result{
                        //display: none;
                        height: 0;
                        visibility: hidden;
                    }
                }

                .non-results{
                    .wrap-NoResult{
                        margin-top: 0;
                        padding: 2.8rem 2.6rem;
                        text-align: center;
                        font-size: 1.3rem;
                        line-height: 1.9rem;
                        strong{
                            font-weight: normal;
                            font-family: 'Montserrat-Bold';
                        }
                    }
                }
                .close{
                    display: none;
                }
                .results{
                    padding-bottom: 5rem;

                    .wrap-category{
                        margin-top: 1rem;
                        width: calc(100% + 1rem);

                        .category{
                            width: calc(100% - 1rem);
                            height: auto;
                            min-height: 3.7rem;
                            padding: 1rem;
                            flex-wrap: wrap;
                            .title, .num{
                                line-height: normal;
                                font-size: 1.5rem;
                            }

                        }
                        .item{
                            width: calc(100% / 2);
                            padding-right: 1rem;
                            // padding-top: 1.8rem;
                            padding-bottom: 0;

                            img.image{
                                margin-right: 0;
                            }
                            .item-name{
                                color: #fff;
                                font-size: 1.5rem;
                                line-height: 1.9rem;
                            }
                        }
                    }
                }
            }
        }
        .full-level-black-search{
            position: relative;
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: -1;
            visibility: hidden;
            background-color: rgba(0, 0, 0, 0.5);
            &.isVisible{
                visibility: unset;
                opacity: 1;
                z-index: 1;
                transition: 0.2s opacity ease;
            }
        }

    }







