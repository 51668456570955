.iframeAuxContainer {
	position: absolute;
	z-index: 1001;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	.iframeAuxBox {
		position: fixed;
		height: 100%;
		width: 100%;
		transform: translate3d(0,0,0);
		overflow: hidden;
	}
}

.iframeAux {
	position: fixed;
	z-index: 1;
	opacity: 0;
	pointer-events: all;
	visibility: hidden;
	transform-origin: top left;
	transition: opacity 0.4s linear 0s, visibility 0s linear 0.4s, transform 0.6s ease 0s, top 0.6s ease 0s, left 0.6s ease 0s;
	&.isVisible {
		opacity: 1;
		visibility: visible;
		transition: opacity 0s linear 0s, visibility 0s linear 0s, transform 0.6s ease 0s, top 0.6s ease 0s, left 0.6s ease 0s;
	}
}

$delay: 0.6s;
.video-detail-wrapper {
	position: fixed;
	z-index: 1000;
	width: 100vw;
	max-width: 1400px;
	margin: 0 auto;
	height: 100vh;
	top: 0;
	left: 50%;
	padding: 0 35px;
	display: flex;
    flex-direction: column;
	opacity: 0;
	transform: translateX(-50%);
	visibility: hidden;
	transition: opacity 0.4s linear 0s, visibility 0s linear 0.4s;
	&.isVisible {
		opacity: 1;
		visibility: visible;
		transition: opacity 0.6s linear 0s, visibility 0s linear 0s;
	}
	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 50%;
		height: 100%;
		width: 100vw;
		transform: translateX(-50%);
		background-color: $gray-ligth;
	}
	&.isLoaded {
		&+.iframeAuxContainer {
			padding-top: 80px;
			.iframeAux {
				margin-top: -80px;
			}
		}
		.video-detail-header {
			.video-detail-title {
				.video-detail-title-extra {
					opacity: 1;
					transform: translateX(0);
					transition: transform 0.4s ease calc(0s + #{$delay}), opacity 0.2s linear calc(0s + #{$delay});
				}
			}
		}
		.video-prev-next {
			.video-prev {
				&:after {
					height: 100%;
					transition: height 0.6s ease calc(0s + #{$delay});
				}
			}
			.video-prev-container,
			.video-next-container {
				opacity: 1;
				transform: translateX(0);
				transition: opacity 0s linear 0s, transform 0.6s ease calc(0.4s + #{$delay}), opacity 0.4s linear calc(0.4s + #{$delay});
			}
		}
		.video-info-wrapper {
			opacity: 1;
			transform: translateY(0);
			transition: transform 0.6s ease calc(0.2s + #{$delay}), opacity 0.4s linear calc(0.2s + #{$delay});
		}
		.video-docs-wrapper {
			opacity: 1;
			transform: translateY(0);
			transition: transform 0.6s ease calc(0.4s + #{$delay}), opacity 0.4s linear calc(0.4s + #{$delay});
		}
		.video-detail-related-container {
			.video-detail-related-list {
				.video-detail-related-item {
					@for $i from 1 through 4 {
						&:nth-child(#{$i}) {
							transform: translateX(0);
							opacity: 1;
							transition: transform 1s ease calc(0.6s + (0.1s * #{$i}) + #{$delay}), opacity 0.4s linear calc(0.6s + (0.1s * #{$i}) + #{$delay});
						}
					}	
				}
			}
		}
	}
	.pattern {
		display: none;
	}
	.video-detail-header {
		height: 80px;
		min-height: 80px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $c-gray2;
		padding-right: 10px;
		.video-detail-title {
			font-size: 19px;
			font-weight: bold;
			color: $gray-dark;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			.video-detail-title-extra {
				font-size: 15px;
				color: rgba($gray-dark, 0.5);
				text-transform: initial;
				transform: translateX(-50%);
				opacity: 0;
				transition: transform 0s linear 0.2s, opacity 0.2s linear 0s;
				&:before {
					content: '';
					display: inline-block;
					width: 7px;
					height: 10px;
					margin: 0 10px;
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					background-image: url('../images/arrow-grey-simple-right.svg');
				}
			}
		}
		.video-detail-close {
			height: 30px;
			cursor: pointer;
			display: flex;
			align-items: center;
			color: $gray-dark;
			font-size: 11px;
			text-transform: uppercase;
			transition: all 0.2s linear;
			&:hover {
				opacity: 0.5;
			}
			&:after {
				content: '';
				width: 30px;
				height: 30px;
				border-radius: 100%;
				margin-left: 10px;
				border: 1px solid rgba($gray-dark, 0.5);
				background-repeat: no-repeat;
				background-position: center;
				background-size: 50%;
				background-image: url('/images/x-grey.svg');
			}
		}
	}
	.video-detail-body {
		width: calc(100% + 20px);
		padding-top: 37px;
		padding-right: 30px;
		display: flex;
		flex-grow: 1;
		overflow-y: auto;
		overflow-x: hidden;
		.video-body-left {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
		}
		.video-body-right {
			width: 335px;
			min-width: 335px;
			padding-left: 40px;
			padding-right: 40px;
			margin-right: -40px;
		}
	}
	.video-detail-video-container {
		transition: height 0.4s ease 0s;
	}
	.video-detail-related-container {
		margin-top: 30px;
		min-height: 265px;
		.video-detail-related-title {
			display: flex;
			align-items: center;
			font-size: 17px;
			font-weight: bold;
			color: $gray-dark;
			&:after {
				content: '';
				margin-left: 20px;
				height: 1px;
				flex-grow: 1;
				background-color: $c-gray2;
			}
		}
		.video-detail-related-list {
			display: flex;
			flex-wrap: wrap;
			padding: 35px 0 20px 0;
			width: calc(100% + 50px);
			.video-detail-related-item {
				margin-right: 50px;
				padding: 0 15px;
				width: calc(25% - 50px);
				min-width: calc(25% - 50px);
				transform: translateX(50%);
				opacity: 0;
				transition: transform 0s linear 0.2s, opacity 0.2s linear 0s;
				.video-title {
					font-size: 15px;
					font-weight: bold;
					text-transform: uppercase;
					line-height: 1.4;
					color: $gray-dark;
				}
				.video-subtitle {
					font-size: 15px;
					line-height: 1.4;
					color: $gray-dark;
				}
				.video-family {
					margin-top: 10px;
					display: flex;
					align-items: center;
					.video-family-text {
						font-size: 13px;
						color: $gray-dark;
						opacity: 0.5;
						line-height: 1.5;
					}
					.video-family-icon {
						margin-left: 10px;
						width: 10px;
						height: 10px;
						border-radius: 100%;
					}
				}
			}
		}
	}
	.video-wrapper {
		position: relative;
		width: calc(100% + 30px);
		margin-left: -15px;
		margin-bottom: 10px;
		padding-top: 67%;
		cursor: pointer;
		&:hover {
			&.isLoaded {
				img {
					display: none;
				}
				iframe {
					display: block;
				}
			}
			.video-container {
				&:before, &:after {
					display: block;
				}
			}
		}
		&.isLoaded {
			.video-container {
				&:before, &:after {
					display: none !important;
				}
			}
		}
		&:before {
			content: '';
			position: absolute;
			z-index: 1;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		.video-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			&:before {
				content: '';
				position: absolute;
				display: none;
				z-index: 3;
				width: 100%;
				height: 100%;
				background-color: rgba(255,255,255,0.8);
				border-radius: 15px;
			}
			&:after {
				content: '';
				position: absolute;
				display: none;
				z-index: 3;
				top: calc(50% - 15px);
				left: calc(50% - 15px);
				width: 30px;
				height: 30px;
				border-radius: 100%;
				border: 3px solid #ccc;
				border-top: 3px solid $blue;
				animation: spin 0.8s ease infinite;
			}
		}
		img {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: 100%;
			border-radius: 15px;
		}
		iframe {
			display: none;
			border-radius: 15px;
			max-width: 100%;
			max-height: 100%;
		}
		.video-duration {
			position: absolute;
			z-index: 4;
			bottom: 10px;
			right: 10px;
			pointer-events: none;
			background-color: rgba($gray-dark, 0.75);
			height: 23px;
			padding: 0 6px;
			display: flex;
			align-items: center;
			border-radius: 5px;
			color: #fff;
			font-size: 13px;
			font-weight: 500;
		}
	}
	.video-prev-next {
		display: flex;
		align-items: stretch;
		margin-bottom: 60px;
		.video-prev,
		.video-next {
			position: relative;
			width: calc(50% + 15px);
			min-width: calc(50% + 15px);
			padding: 0 15px;
			overflow: hidden;
			.icon {
				transition: transform 0.4s ease;
			}
			.video-wrapper {
				width: 75px;
				padding-top: 43px;
				margin-right: 0;
				margin-left: 0;
			}
			.video-prev-next-header {
				margin-bottom: 5px;
			}
		}
		.video-prev {
			margin-left: -15px;
			text-align: left;
			.icon {
				margin-right: 10px;
			}
			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				height: 0;
				width: 1px;
				background-color: $c-gray2;
				transition: height 0s linear 0.2s;
			}
			&:hover {
				.icon {
					transform: translateX(-10px);
				}
			}
		}
		.video-next {
			margin-right: -15px;
			text-align: right;
			.video-next-container {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
			}
			.video-prev-next-header {
				justify-content: flex-end;
				.icon {
					margin-left: 10px;
				}
			}
			&:hover {
				.icon {
					transform: translateX(10px);
				}
			}
		}
		.video-prev-container,
		.video-next-container {
			cursor: pointer;
			opacity: 0;
			transition: transform 0s linear 0.2s, opacity 0.2s linear 0s;
		}
		.video-prev-container {
			transform: translateX(100%);
		}
		.video-next-container {
			transform: translateX(-100%);
		}
		.video-prev-next-header {
			display: flex;
			align-items: center;
			font-size: 11px;
			line-height: 1.82;
			color: $gray-dark;
			.icon {
				width: 16px;
				height: 9px;
			}
		}
		.video-prev-next-title {
			font-size: 13px;
			font-weight: bold;
			text-transform: uppercase;
			display: -webkit-box;
			line-clamp: 2;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;  
			overflow: hidden;
			max-width: 120px;
		}
	}
	.video-info-wrapper {
		transform: translateY(120px);
		opacity: 0;
		transition: transform 0s linear 0.2s, opacity 0.2s linear 0s;
		.video-info-title {
			font-size: 17px;
			font-weight: bold;
			line-height: 1.2;
			color: $gray-dark;
			text-transform: uppercase;
		}
		.video-info-subtitle {
			margin-top: 5px;
			font-size: 17px;
			line-height: 1.2;
			color: $gray-dark;
		}
		.video-info-more {
			margin-top: 15px;
			display: flex;
			align-items: center;
			.video-info-likes {
				display: flex;
				align-items: center;
				font-size: 13px;
				color: $pink;
				margin-right: 50px;
				cursor: pointer;
				&.liked {
					pointer-events: none;
					&:before {
						background-image: url('../images/heart-pink.svg');
					}
				}
				&:hover {
					&:before {
						animation: beat .25s infinite alternate;
					}
				}
				&:before {
					content: '';
					display: inline-block;
					width: 15px;
					height: 15px;
					margin-right: 10px;
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					background-image: url('../images/heart.svg'); 
				}
			}
			.video-info-family {
				display: flex;
				align-items: center;
				font-size: 13px;
				color: rgba($gray-dark, 0.5);
				img {
					width: 11px;
					height: 11px;
					object-fit: contain;
					margin-right: 10px;
				}
			}
		}
		.video-info-description {
			margin-top: 40px;
			color: $pink;
			font-size: 15px;
			line-height: normal;
			display: -webkit-box;
			line-clamp: 8;
			-webkit-line-clamp: 8;
			-webkit-box-orient: vertical;  
			overflow: hidden;
			&.showAll {
				display: block;
				&+.video-info-description-more {
					.read-more {
						display: none;
					}
					.read-less {
						display: inline-block;
					}
				}
			}
			* { 
				color: $pink !important;
				font-size: 15px !important;
				line-height: normal !important;
				font-family: "Montserrat" !important;
				cursor: default;
				opacity: 1;
				visibility: visible;
			}
		}
		.video-info-description-more {
			margin-top: 10px;
			font-size: 13px;
			color: $blue;
			line-height: 1.54;
			cursor: pointer;
			.read-more,
			.read-less {
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
			.read-more {
				display: inline-block;
			}
			.read-less {
				display: none;
			}
		}
	}
	.video-docs-wrapper {
		padding-top: 75px;
		padding-bottom: 30px;
		transform: translateY(120px);
		opacity: 0;
		transition: transform 0s linear 0.2s, opacity 0.2s linear 0s;
		.video-docs-title {
			font-size: 15px;
			color: $gray-dark;
			text-transform: uppercase;
		}
		.video-docs-list {
			padding-top: 12px;
			.video-docs-item {
				margin-bottom: 10px;
				.video-docs-item-link {
					height: 20px;
					display: flex;
					align-items: center;
					font-size: 13px;
					color: $gray-dark;
					&:hover {
						.text {
							text-decoration: none;
						}
					}
					.text {
						text-decoration: underline;
						padding-bottom: 2px;
					}
					&:before {
						content: '';
						display: inline-block;
						width: 20px;
						height: 20px;
						margin-right: 10px;
						background-repeat: no-repeat;
						background-position: center;
						background-size: contain;
						background-image: url('../images/down-arrow.svg');
					}
				}
				.video-docs-item-text {
					margin-top: 5px;
					padding-left: 30px;
					color: rgba($gray-dark, 0.5);
					font-size: 13px;
					line-height: 1.54;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		padding: 0 $lateral-space-sm;
		overflow-x: hidden;
		overflow-y: auto;
		&.isLoaded {
			&+.iframeAuxContainer {
				padding-top: 0;
				.iframeAux {
					margin-top: 0;
				}
			}
		}
		.video-detail-header {
			padding-right: 0;
			height: 60px;
			min-height: 60px;
			.video-detail-title {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				font-size: 17px;
				.video-detail-title-extra {
					margin-top: 5px;
					&:before {
						display: none;
					}
				}
			}
			.video-detail-close {
				font-size: 0;
			}
		}
		.video-detail-related-container {
			margin-top: 0;
			min-height: unset;
			.video-detail-related-title {
				padding-right: $lateral-space-sm;
			}
			.video-detail-related-list {
				width: 100vw;
				overflow: auto;
				margin-left: -$lateral-space-sm;
				padding-left: $lateral-space-sm;
				padding-top: 15px;
				flex-wrap: nowrap;
				.video-detail-related-item {
					width: 185px;
					min-width: 185px;
					margin-right: 35px;
				}
			}
		}
		.video-detail-body {
			position: absolute;
			width: 100vw;
			left: 50%;
			bottom: 0;
			padding: 0 $lateral-space-sm;
			transform: translateX(-50%);
			flex-direction: column-reverse;
			padding-top: 0;
			overflow-y: auto;
    		overflow-x: hidden;
			.video-body-left {
				.video-detail-video-container {
					position: absolute;
					top: 0;
					width: 100vw;
					margin-left: -$lateral-space-sm;
					transform: translateY(-100%);
				    height: 0 !important;
    				min-height: 0 !important;
				}
			}
			.video-body-right {
				height: unset;
				overflow: unset;
			    width: 100%;
			    min-width: 100%;
			    padding: 0;
			    display: flex;
			    flex-direction: column;
			}
		}
		.video-info-wrapper {
			.video-info-more {
				.video-info-likes {
					&:hover {
						&:before {
							animation: unset;
						}
					}
				}
			}
		}
		.video-prev-next {
			order: 2;
			margin-bottom: 0;
			padding: 20px 20px;
			.video-prev, .video-next {
				margin: 0 !important;
				width: 50%;
				min-width: 50%;
				padding: 0;
				&:hover {
					.icon {
						transform: unset;
					}
				}
			}
			.video-prev {
				padding-right: 15px;
			}
			.video-next {
				padding-left: 15px;
			}
		}
		.video-info-wrapper {
			order: 1;
			margin-top: 15px;
		}
		.video-docs-wrapper {
			order: 3;
			padding-top: 10px;
		}
	}
}