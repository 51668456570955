.corp-text_2021{
    width: 100%;
    padding-bottom: 8rem;

    .limited-wrap{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        padding: 0;
    }

    .section-title{   
        width: 100%;   
        padding-top: 4rem;   
        .title{
            font-size: 3.5rem;
            text-transform: none;
            font-family: "Montserrat-SemiBold";
            font-weight: normal;
            line-height: 4.3rem;
            text-align: left;
        }
        .title-underline{
            width: 3.5rem;
            border-bottom: 4px solid;
            background: $colorCorporative;
            margin-top: 0.5rem;
            margin-bottom: 5.4rem;
        }
    }
    .wrap-list-corp-text{
        .text.generic{
            iframe{
                display: block !important;
                margin-top: 3rem;               
            }
        }
    }
    @media (max-width: $md-width) {   
        .section-title{  
            padding-top: 0;
            margin-top: 0;  
            .title{
                font-size: 2.5rem;          
                line-height: 3.3rem;            
            }
            .title-underline{              
                border-bottom: 2px solid;
                margin-bottom: 3.4rem;
            }
        }
        .wrap-list-corp-text{
            .text.generic{
                iframe{
                    width: 100%;
                }
            }
    
            .description{
                font-size: 1.3rem;
                line-height: 2rem;       
            }    
        }
        
    }
}

