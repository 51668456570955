.new-blog {   
    position: relative;
    background-color: #f3f6f7;
    padding-right: 5rem;
    padding-left: 5rem;
    .limited-wrap{
        margin: 0 auto;
        width: 100%;
        max-width: 1280px;      
        padding-top: 16.4rem;
    }
    .header {
        display: flex;
        align-items: center;
        margin-bottom: 5rem;
        .header-title {           
            .title {
                font-size: 3.5rem;                 
                margin-block-end: 0;
                padding-right: 2rem;
                font-family: 'Montserrat-SemiBold';      
                color: $colorCorporative;       
                background-color: #f3f6f7;
            }

            hr {
                width: 3.5rem;
                border-top: 0.7rem solid $colorCorporative;
                margin: 0;
            }
        }

        .long-line {
            width: 100%;
            border-color: $colorCorporative;
            align-self: flex-end;
        }

    }
    .two-columns-wrap{
        justify-content: space-between;
    }
    .wrap-lastArticles{
        margin-top: 9rem;

       .last-3-news{
        max-width: calc(100rem);
        margin: 0 auto;
       
            .last-3-new{
                width: calc((100% / 3) - 1.5rem);
                padding-right: 0;
                .header, .date{
                    margin-bottom: 0;
                }
                .title{
                    margin-top: 2rem;
                }
                .author{
                    font-size: 1.3rem;
                    color: $colorCorporativeGrey;
                    line-height: 2.1rem;
                    opacity: 1;
                    display: block;                   
                    font-family: "Montserrat-Italic";
                }
            }
       }
       .section-title {
            width: 100%;
            margin: 0 auto;
            margin-left: 0;

            h2{
                font-size: 2.1rem;
                color: $colorCorporative;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: 5.25px;
                font-family: "Montserrat-SemiBold";
            }
            .divider{
                width: 3.5rem;
                border-top: 2px solid $colorCorporative;
                margin: 2rem auto 7rem auto;
            }
        }
    }
    .authors-container {            
        position: -moz-sticky;
        position: -o-sticky;
        position: -ms-sticky;
        position: sticky;
        position: -webkit-sticky;
        right: 5rem;
        top: 16.5rem;
        width: 36.2rem;
        height: 46.6rem;
        z-index: 14;
        padding: 2rem;
        background-color: white;

        .blog-authors {
            width: 100%;
            height: 85%;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }
            
            &::-webkit-scrollbar:horizontal {
                height: 4px;
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                border: 1px solid $colorCorporative;
                background-color: $colorCorporative;
            }
            
            &::-webkit-scrollbar-track { 
                background-color: rgba(17, 151, 165, 0.32); 
                border-radius: 8px; 
            }
            &::-webkit-scrollbar-track-piece  {
                background-color: rgba(17, 151, 165, 0.32); 
                border-radius: 8px; 
            }
            
            &::-webkit-scrollbar-thumb:vertical {
                border-radius: 8px;
                border: 1px solid $colorCorporative;
                background-color: $colorCorporative;
            }
            /* Thin Scrollbar for firefox */
            &{
                scrollbar-color: $colorCorporative rgba(17, 151, 165, 0.32);
                scrollbar-width: thin;                
            }
            .author-row {
                display: flex;
                align-items: flex-end;
                margin-top: 1.5rem;

                .img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                
                & > .a {
                    width: 8.4rem;
                    height: 7.4rem;
                    display: block;
                    border: 1px solid $colorCorporative;
                    font-size: 1.3rem;
                }

                .author-info {
                    margin-left: 1.2rem;
                    width: 80%;

                    a{
                        font-size: 1.3rem;
                        font-family: 'Montserrat-Regular';
                        font-style: normal;
                    }

                    .a {
                        font-size: 1.3rem;

                        .img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .name{
                        color: $colorCorporative;
                        font-style: oblique;
                        font-size: 1.3rem;
                        font-family: 'Montserrat-Regular';
                        transition: .2s color ease;
                        &:hover{
                            color:rgba($colorCorporative, 0.8);
                            transition: .2s color ease;
                        }
                    }

                    .post{
                        color: #575252;            
                        font-style: normal;  
                        transition: .2s color ease; 

                        &:hover{
                            color:rgba(#575252, 0.8);
                            transition: .2s color ease;
                        } 
                    }

                }
            }
        }

        .title {
            height: 5rem;
            width: 100%;
            background-color: $colorCorporative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 2rem;

            h3 {
                font-size: 1.7rem;
                color: white;
                font-weight: 600;
                text-align: center;
            }
        }
        &.postsRelated{           
            height: 44.5rem;
            padding: 1.8rem;
            >.title{
                margin-bottom: 2.8rem;
                text-transform: uppercase;
                h3{
                    font-weight: normal;
                    margin-bottom: 0;
                    font-family: 'Montserrat-SemiBold';
                    line-height: 2.5rem;
                }
            }
            .blog-authors{          
                height: auto;
                max-height: 33rem;
                overflow-y: auto;
            }
            .author-row{
                margin-top: 1.7rem;
                &:first-of-type{
                    margin-top: 0;
                }
                .pic{
                    width: 10.4rem;
                    height: 6.5rem;
                    display: block;
                    border: 1px solid $colorCorporative;
                    border: none;

                    .img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .author-info{
                    padding: 0 1.5rem;
                    margin: 0;
                    font-size: 1.1rem;
                    .name{
                        line-height: 1.9rem;
                        font-family: 'Montserrat-Regular';
                        font-size: 1.3rem;
                    }
                    .titlePost{
                        font-size: 1.3rem;
                        color: #000;
                        //font-family: 'Montserrat-SemiBold';
                        font-family: 'Montserrat-Regular';
                        background-color: transparent;
                        padding-bottom: 4px;
                        border-bottom: 1px solid rgba($colorCorporative, .5);
                        transition: .2s color ease;
                      

                        &:hover{
                            color: rgba(#000000, .8);
                            transition: .2s color ease;
                        }
                    }
                }
            }
        }
    }

    .blog-entry-container {
        width: calc(100% - 36.2rem);  

        .blog-entry {
            width: 100%;         
            margin-bottom: 4rem;
            padding-right: 4rem;

            .blog-content {
                position: relative;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                
                .img {
                    display: block;                   
                    background-color: grey; // BGM del post
                }

                .badge-blog {
                    display: block;
                    position: absolute;
                    top: 1.3rem;
                    right: 0;
                    width: 80px;
                    border-width: 1.6rem;
                    border-color: white white white transparent;
                    border-style: solid;
                    
                    p {
                        position: absolute;
                        width: 60px;
                        top: 0%;
                        right: -40%;
                        transform: translateY(-50%);
                    }
                }

                .block-text {
                    width: 60%;
                    padding-left: 2rem;

                    .block-title {
                        font-size: 1.5rem;
                        color: black;
                        font-weight: 600;
                        margin-top: 1rem;
                    }
        
                    .desc {
                        margin-top: 1rem;
                    }
        
                    p {
                        font-size: 1.5rem;
                    }
        
                    .author {
                        font-size: 1.3rem !important;
                        line-height: 2.1rem !important;
                        color: $colorCorporative;
                        font-style: oblique;
                    }

                    hr {
                        width: 100%;
                        border-color: rgba(17, 151, 165, 0.5);
                        margin-bottom: 0;
                        border-width: .2rem;
                    }                  
                }
                .generic.text, .generic.text p, .generic, .generic.text *{
                    color: #000;
                    font-size: 1.5rem !important;
                    line-height: 2.3rem !important;
                    font-family: "Montserrat-Regular" !important;
                    letter-spacing: 0 !important;
                    .author {
                        font-size: 1.3rem !important;
                        line-height: 2.1rem !important;
                        color: $colorCorporative;
                        font-style: oblique;
                    }
                    hr{
                        display: block !important;
                    }
                    table, table tr{
                        border: none !important;
                        border-bottom: none !important;
                    }
                    td{
                        background-image: none !important;
                    }
                }              
            }
        
            .aux-item{
                width: 40%;
            }
            .aux-img{           
                width: 100%;
                position: relative;
                .wrap-img{
                    position: relative;
                    width: 100%;
                    padding-top: 63%;
                    .img {
                        width: 100%;
                        height: 100%;                       
                        position: absolute;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                        box-shadow: none;
                    }
                }
            }
        }

        & > .blog-entry:first-of-type {
            
            margin-bottom: 8rem;
            padding-right: 0;
            a{
                width: 100%;
            }
            .aux-item{
                width: 100%;
            }
            .aux-img .wrap-img{
                padding-top: 49%;
            }
            .aux-img  .wrap-img .img {
                width: 80%;
                height: 100%;               
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                box-shadow: 15px 15px $colorCorporative;
            }

            .block-text {
                width: 100% !important;
                margin-top: 5rem;
            }

            .block-title {
                width: 80%;
            }

            .desc {
                margin-top: 1rem;
            }

            p {
                width: 80%;
            }

            .author {
                margin-top: 5rem;
            }

            .blog-content .block-text hr {
                display: none !important;
            }

            .badge-blog {
                display: block;
                position: absolute;
                top: 1.3rem;
                right: 20% !important;
                width: 80px;
                border-width: 1.6rem;
                border-color: white white white transparent;
                border-style: solid;
                
                p {
                    position: absolute;
                    width: 60px;
                    top: 0%;
                    right: -40%;
                    transform: translateY(-50%);
                }
            }
            .img-link:active{
                .img{
                    box-shadow: 0px 0px $colorCorporative;
                    transition: box-shadow .2s linear;
                }
                .wrap-img::before{
                    content: '';
                    position: absolute;
                    width: 80%;
                    height: 100%;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    background-color: $colorCorporative;

                }
            }
        }
    }

}


// DETALLE POST
.new-blog{
    .wrap-content-post{
        width: calc(100% - 36.2rem);
        margin-right: 0.5rem;
        .wrap-blog-content{
            display: flex;
            flex-direction: column;
        }
        
        .wrap-share{
            width: 100%;
            justify-content: flex-end;
            display: flex;
            padding-right: 0.5rem;
            margin-top: 1.7rem;
            
            .share{
                padding: 0;
                .icon{
                    padding-left: 1.7rem;
                    i{
                        font-size: 2.2rem;
                    }
                }
                a{
                    transition: .2s color ease;
                }
                a:hover{                 
                    color: $colorCorporative !important;
                    transition: .2s color ease;
                }
            }
        }
        .wrap-author{
            padding-bottom: 0.8rem;
            display: flex;
            border-bottom: 0.3px solid rgba($colorCorporative, .5);
            margin-bottom: 4.5rem;
            margin-right: 0.5rem;
            margin-top: 3.3rem;

            .wrap-aux{
                padding-top: 0.8rem;
                border-top: 0.3px solid rgba($colorCorporative, .5);
                padding-right: 5rem;
            }
            .pic{
                width: 7rem;
                height: 7rem;
                border-radius: 50%;
                margin-right: 1.3rem;
            }
            .author-info{
                font-family: 'Montserrat-Regular';
                font-size: 1.3rem;
                line-height: 2.1rem;
                color: $colorCorporativeGrey;            
                font-weight: normal;
                align-self: center;
                .name{
                    font-family: 'Montserrat-Italic';                 
                    color: $colorCorporative;          
                    font-size: 1.3rem;
                    line-height: 2.1rem;                                  
                    font-weight: normal; 
                    margin-bottom: 0;
                }
            }
        }
        &.noPostRelated{
            width: 100%;
            margin-right: 0;
            .new-text{
                padding-right: 0;
            }
        }
    }
    .content.detail_new.comments{
        .comment{
            background-color: transparent;
            height: auto!important;
            padding: 0;
            padding-bottom: 3rem;
            border-bottom: solid 0.3px rgba(151, 151, 151, 0.5);
            border-radius: 0;

          
            .name{
                font-family: 'Montserrat-Medium';
                font-size: 1.5rem;
                line-height: 2.3rem;
                font-weight: normal;
                color: $colorCorporative;
            }

            .date{
               font-family: 'Montserrat-Medium';
               font-size: 1.1rem;
               line-height: 2.3rem;
               font-weight: normal;
               color: #000 ;
               padding-bottom: 2.6rem;
               opacity: 1;
               
            }

            .text{
               font-family: 'Montserrat-Medium';
               font-size: 1.5rem;
               line-height: 2.1rem;
               font-weight: normal;
               color: $colorCorporativeGrey;
               margin-bottom: 0;
            }
            
        }
    }
    .wrap-comments-content{
        .title-comments{
            line-height: 3.7;
            font-size: 2.9rem;
            font-family: 'Montserrat-Bold';
            color: $colorCorporative;
            font-weight: normal;
            display: none;
            &.isSee{
                display: block;
            }
        }
        .comments{
            .new-comments{
                padding: 0;
                .title{
                    line-height: 3.7;
                    font-size: 2.9rem;
                    font-family: 'Montserrat-Bold';
                    color: $colorCorporative;
                    font-weight: normal;
                    background-color: transparent;
                    padding: 0;
                    margin: 0;
                    text-transform: none;
                }
            }
            .comments-list{
         
                .no-comments{
                    line-height: 3.7;
                    font-size: 1.9rem;
                    font-family: 'Montserrat-Bold';
                    color: $colorCorporative;
                    font-weight: normal;
                }
               
                .coment-item{
                    .comment{
                     background-color: transparent;
                     height: auto!important;
                     padding: 0;
                     padding-bottom: 3rem;
                     border-bottom: solid 0.3px rgba(151, 151, 151, 0.5);
                     border-radius: 0;

                   
                     .name{
                         font-family: 'Montserrat-Medium';
                         font-size: 1.5rem;
                         line-height: 2.3rem;
                         font-weight: normal;
                         color: $colorCorporative;
                     }

                     .date{
                        font-family: 'Montserrat-Medium';
                        font-size: 1.1rem;
                        line-height: 2.3rem;
                        font-weight: normal;
                        color: #000;
                        padding-bottom: 2.6rem;
                        
                     }

                     .text{
                        font-family: 'Montserrat-Medium';
                        font-size: 1.5rem;
                        line-height: 2.1rem;
                        font-weight: normal;
                        color: $colorCorporativeGrey;
                        margin-bottom: 0;
                     }
                     
                    }
                }
            }
            .form{
                .privacy .labelContainer{
                    margin-top: 1rem;
                }                 
              
                .buttons{
                    justify-content: flex-end;
                    //margin-top: 1.5rem;          
                    margin-left: 0 !important;
                    margin-right: 0 !important;     
                    margin-top: 2rem;  
               
                    .wrap-btnDefault {
                        min-width: unset;
                        .btnDefault{                       
                            font-size: 1.5rem;
                            line-height: 1.7rem;
                        }
                    }
                }
                .input-box input + .label{                          
                    top: 0;
                    font-size: 1.3rem;
                    left: 1.7rem;
                    color: #575252;
                    background-color: #fff;
                    padding: 0 1.1rem;
                    pointer-events: none;
                    transform: translateY(-50%);
                    transform-origin: left;
                    white-space: nowrap;
                    margin: 0;
                    transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
                    &.left-1{
                        left: 1rem
                    }           
                  
                }
                .input-box textarea{
                    min-height: 14.7rem;
                }
                .input-box textarea + .label {                  
                    position: absolute;
                    top: 0;
                    font-size: 1.3rem;
                    left: 1.7rem;
                    color: #575252;
                    background-color: #fff;
                    padding: 0 1.1rem;
                    pointer-events: none;
                    transform: translateY(-50%);
                    transform-origin: left;
                    white-space: nowrap;
                    margin: 0;
                    transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
                    &.left-1{
                        left: 1rem
                    }                  
                }
      
                .input-box input:focus,       
                .input-box textarea:focus{
                    box-shadow: none;
                    border: 1px solid #000;
                }

                .input-box input:focus + .label, .input-box input:valid + .label,       
                .input-box textarea:focus + .label, .input-box textarea:valid + .label {
                    top: 0;                 
                    transform: translateY(-50%) scale(1);
                }
            }
        }
     }
    
    .detail_new{
        background-color: transparent;        
        
        .new-text{
            padding: 0;
            padding-right: 7rem;
            .text, .text *{
                line-height: 2.1rem !important;
                font-size: 1.5rem !important;
                color: #000 !important;
                font-family: 'Montserrat-Regular' !important;
                font-weight: normal !important;
                background-color: transparent !important;
                padding: 0;
                b{
                    font-family: 'Montserrat-SemiBold';
                    font-weight: normal !important;
                }
                i{
                    font-family: 'Montserrat-Italic' !important;
                    font-weight: normal !important;
                }
            }

            .text > *{
                padding-bottom: 2.5rem !important;
                &:empty{
                    display: none !important;
                    padding-bottom: 0 !important;
                }              
            }
            .text{
                p:empty{
                    display: none !important;
                    padding-bottom: 0 !important;
                }
            }
        }
        .photo{
            width: 100%;
            height: auto;
            border-radius: 0;
            position: relative;
            margin-bottom: 2.5rem;

            .pull-right{
                position: absolute;
                color: #000;  
                width: 8rem;
                height: 4.4rem; 
                top: 2.1rem;
                right: 0;
                background-image: url('/images/flag.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                display: flex;
                justify-content: center;
                align-items: center;                
                padding-left: 1rem;

                .likes-count{
                    padding-right: 0.5rem;

                }
                .likes-count, .liked{
                    font-size: 1.65rem;
                }
            }
        }
        .new-header{
            .title{
                font-size: 3.1rem;
                font-family: 'Montserrat-SemiBold';
                line-height: 3.9rem;
                color: #575252;
            }
        }
    }
    .aux-slider{        
        padding-right: .5rem;
    }
    .pasadorPost{
        width: 100%;
        position: relative;
        background-color: transparent;    
        margin-right: 0.5rem;
        min-height: unset;      
        margin-bottom: 0;

        .swiper-wrapper{
            .swiper-slide{
                padding-right: 1.5rem;             
                width: auto !important;
                .img-responsive{                     
                    position: relative;
                    top: 0;
                    left: 0;                       
                    object-fit: contain;
                    height: 26.5rem;
                }             
            }
        }
       
        .control {
                width: 4rem;
                height: 4rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: transparent;
                color: transparent;
                border: none;
                font-size: unset;
                opacity: .75;
                transition: .2s all ease-in;
                z-index: 1;
                &:hover{
                    opacity: 1;
                }
                &.swiper-button-disabled{
                    transition: .2s all ease-in;
                    opacity: 0;
                }
          
            &.control-prev{              
                width: 4rem;
                height: 4rem;
                left: 1rem;
               
                &:before{
                    content: "";
                    background-image: url('/images/right.svg');
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: 0;
                    left: 0;
                    transform: rotate(180deg);
                }
               
            }
            &.control-next{            
                width: 4rem;
                height: 4rem;
                right: 1rem;
                
                &:before{
                    content: "";
                    background-image: url('/images/right.svg');
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
    .comments{
        padding-right: 0.5rem;
    }
    
    .wrap-comments-content{
        padding: 5rem;
        padding-top: 2rem;
        background-color: #fff;
        margin-right: 0.5rem;
    }

    // PHOTO Full screen
    .mygallerybox{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: -1;
        opacity: 0;

        -webkit-transition: all ease-in 0.3s;
        transition: all ease-in 0.3s;
      
        &.preopen,
        &.open{
            z-index: 1040;	
        }

        &.open {
            opacity: 1;
        }

        & .swiper-container{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        
        .slide-img{
            max-width: 90%;
            max-height: 90%;
            position: absolute;
            top: 0;
            left:0;
            right:0;
            bottom: 0;
            margin: auto;

            @media (max-width: $md-width) {
                max-width: 100%;
                max-height: 100%;
            }   
        }

        .btn-close {    
            position: absolute;
            top: 5rem;
            right: 5rem;
            padding: 0;
            background-color: transparent;          
            -webkit-transition: 0.3s ease-in all;
            transition: 0.3s ease-in all;
            z-index: 1040;
            width: 3rem;
            height: 3rem;
            opacity: .75;
            &:hover{
                background-color: transparent;
            }

            &::before{
                content: "";
                position: absolute;
                background-image: url('/images/close-x.svg');
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                //transform: rotate(45deg);
            }
            
            &:hover{
                opacity: 1;            
            }
        }

        .swiper-button-prev,
        .swiper-button-next {           
            width: 4rem;
            height: 4rem; 
            background-image: none;         
        }
        .swiper-button-prev{
            left: 15px;          

            &:before{
                content: '';
                position: absolute;
                width: 4rem;
                height: 4rem;
                background-image: url('/images/right.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                transform: rotate(180deg);
            }
        }
        .swiper-button-next {
            right: 15px;

            &:before{
                content: '';
                position: absolute;
                width: 4rem;
                height: 4rem;
                background-image: url('/images/right.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }   
}

@media (max-width: $md-width) {
    .new-blog {     
        padding-top: 9.2rem;      
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        .limited-wrap{
            padding-top: 0;
        }
        .header {
            justify-content: space-between;
            margin-bottom: 0;
            .long-line {
                width: 100%;
            }
            
            .header-title {             
                .title {
                    font-size: 2.5rem;                  
                }

                hr {            
                    border-top: 0.2rem solid $colorCorporative;                   
                }
            }
        }
        .wrap-lastArticles{
            .section-title{
                h2{
                    font-size: 1.7rem;
                    letter-spacing: 4.25px;
                }
                .divider{
                    margin: 2rem auto 3rem auto;
                }
            }
            .last-3-news{
                transform: translateX(-1.5rem);
                width: calc(100% + 3rem);
                flex-wrap: nowrap;
                padding-bottom: 6rem;
                overflow-x: scroll;
        
                .last-3-new{
                    width: calc(100% - 6rem);
                    flex-shrink: 0;
                    margin-right: 1.5rem;
                    padding-right: 0;
                }
            }
        } 
        .authors-container {
            display: none;
        }
        .detail_new{
            margin-left: 0;
            margin-right: 0;

            .new-text{
                padding-right: 0;

                .new-header{
                    padding: 0;
                    margin-bottom: 1.2rem;

                    .title{
                        font-size: 2.1rem;
                        line-height: 2.7rem;
                    }
                }
                .text{
                    padding: 0;
                    p:empty{
                        display: none !important;
                        padding-bottom: 0 !important;
                    }

                }

                .photo {
                    .pull-right{
                        height: 3rem;
                        top: 1rem;
                        width: 6.6rem;
                    }
                }
            }
        }

        .wrap-content-post{
            width: 100%;
            margin-right: 0;
            .wrap-share{
                padding-right: 0;
                margin-top: 0;
            }
            .wrap-author{
                margin-right: 0;
                padding-right: 0;

                .wrap-aux{
                    width: 100%;
                    margin-right: 0;
                    padding-right: 0;
                  
                }
            }
            .wrap-comments-content{
                padding: 1.5rem;
                margin: 0;

                .detail_new.comments{
                    padding-right: 0;

                    .new-comments{
                        .title{
                            line-height: normal;
                            margin-bottom: 3.6rem;
                        }
                        .form-comment{
                            .form{
                                display: block;
                                .input-box input + .label {
                                    left: 1rem;
                                }
                            }
                            .input-box{
                                padding: 0;
                            }
                        }
                    }
                    .comments-list .no-comments {
                        font-size: 1.7rem;
                    }
                }
                .title-comments{
                    line-height: 3.6rem;
                    margin-bottom: 3.2rem;
                    margin-top: 3.2rem;
                }
            }

            .pasadorPost{
                margin-bottom: 4.3rem;
                margin-right: 0;             
            }
        }
    
        .blog-entry-container {
            width: 100%;
            margin-top: 5.5rem;
    
            .blog-entry {
                width: 100%;
                margin-bottom: 4rem;
    
                .blog-content {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-end;                   
              
    
                    .badge-blog {
                        display: block;
                        position: absolute;
                        top: 1.3rem;
                        right: 0;
                        width: 80px;
                        border-width: 1.6rem;
                        border-color: white white white transparent;
                        border-style: solid;
                        
                        p {
                            position: absolute;
                            width: 60px;
                            top: 0%;
                            right: -40%;
                            transform: translateY(-50%);
                        }
                    }
    
                    .block-text {
                        width: 60%;
                        padding-left: 2rem;
    
                        .block-title {
                            font-size: 1.5rem;
                            color: black;
                            font-weight: 600;
                            margin-top: 1rem;
                        }
            
                        .desc {
                            margin-top: 1rem;
                            padding-right: 0;
                            padding-left: 0;

                        }
            
                        p {
                            font-size: 1.5rem;
                        }
            
                        .author {
                            font-size: 1.5rem;
                            color: $colorCorporative;
                            font-style: oblique;
                        }
    
                        hr {
                            width: 100%;
                            border-color: rgba(17, 151, 165, 0.5);
                            margin-bottom: 0;
                        }
                    }
                }
            }
    
            & > .blog-entry:first-of-type {
                
                margin-bottom: 8rem;
    
                .img {
                    background-color: grey; // BGM del post
                    width: calc(100% - 1.5rem) !important;                  
                    box-shadow: 10px 10px $colorCorporative;
                    margin-bottom: 3rem;
                }
    
                .block-text {
                    width: 100% !important;
                    padding: 0 !important;
                }
    
                .block-title {
                    width: 100%;
                }
    
                .desc {
                    margin-top: 1rem;
                }
    
                p {
                    width: 100%;
                }
    
                .author {
                    margin-top: 5rem;
                }
    
                hr {
                    display: none;
                }
    
                .badge-blog {
                    display: block;
                    position: absolute;
                    top: 1.5rem;
                    right: 1.5rem !important;
                    width: 80px;
                    border-width: 1.6rem;
                    border-color: white white white transparent;
                    border-style: solid;
                    
                    p {
                        position: absolute;
                        width: 60px;
                        top: 0%;
                        right: -40%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    
    }
}


.blog-entry-container{
    .badge-blog{
        .likes{
            display: flex;
            justify-content: center;
            right: unset;
            .likes-count{
                padding-right: 0.5rem;
            }
        }
    }
}

@media (max-width: $sm-width) {
    .new-blog {
        
        .header {
            justify-content: space-between;
            .long-line {
                width: 100%;
            }
        }
    
        .aux-slider{
            padding-right: 0;
            width: calc(100% + 3rem);          
            transform: translateX(-1.5rem);
            .pasadorPost .swiper-wrapper .swiper-slide{
                padding-right: 0.8rem;
          
            }
            .swiper-wrapper{
                display: flex;
                align-items: center;

                .swiper-slide{    
                    width: 100%;
                                       
                    .img-responsive{                       
                        height: 100%;
                        width: auto;
                        object-fit: contain;
                        max-height: 20rem;        
                    }
                }
            }
        }

        .wrap-comments-content{
            .comments .new-comments{
                .form .textarea-box input + .label, 
                .form .input-box input + .label, 
                .form .textarea-box textarea + .label, 
                .form .input-box textarea + .label{
                    font-size: 1.7rem;
                }
            }

            .comments .form{
                .privacy{
                    .labelContainer{
                        font-size: 1.7rem;
                        .textContainer {
                            width: calc(100% - 3.2rem);
                        }
                    }
                    /*
                    input + .labelContainer:before {
                        content: "";
                        position: relative;
                        display: inline-block;
                        vertical-align: middle;
                        min-width: 1.5rem;
                        height: 1.5rem;
                        top: 0;
                        border: 0.1rem solid $colorCorporative;
                        border-radius: 0 !important;
                        background-color: transparent;
                        transition: background-color 0.2s linear 0.1s;
                    } 
                    input + .labelContainer:after {
                        content: "";
                        position: absolute;
                        z-index: 1;
                        min-width: 1rem;
                        height: 0.5rem;
                        top: 1.9rem;
                        left: 0.2rem;
                        border: 0.2rem solid $colorCorporative;
                        border-top: 0;
                        border-right: 0;
                        transform: rotate(-45deg) scale(0);
                        transform-origin: left top;
                        transition: all 0.2s ease 0s;
                    }
                    input[type=checkbox]:checked + .labelContainer:after {
                        transform: translateY(-20%) rotate(-45deg) scale(1);
                        transition: all 0.2s ease 0.1s;
                    }
                    input[type=checkbox]:checked + .labelContainer:before {
                        background-color: transparent;
                        transition: background-color 0.2s linear 0s;
                    }
                    */
                }
                .buttons{
                    margin-top: 0;
                    .wrap-btnDefault {
                        min-width: 16.5rem;             
                    }
                }
            }
        }

        .authors-container {
            display: none;
        }
    
        .blog-entry-container {
            width: 100%;
            margin-top: 5rem;
    
            .blog-entry {
                width: 100%;
                margin-bottom: 4rem;
                padding-right: 0;
                a{
                    width: 100%;
                }    
                   
                .aux-item{
                    width: 100%;
                }

                .blog-content {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-end;
                    
                    .img {
                        display: block;
                        background-color: grey; // BGM del post
    
                        &:hover {
                            background-size: 150%;
                            background-position: center;
                        }
                    }
    
                    .badge-blog {
                        display: block;
                        position: absolute;
                        top: 1.3rem;
                        right: 0%;
                        width: 80px;
                        border-width: 1.6rem;
                        border-color: white white white transparent;
                        border-style: solid;
                        
                        p {
                            position: absolute;
                            width: 60px;
                            top: 0%;
                            right: -40%;
                            transform: translateY(-50%);
                            font-size: 1.5rem !important;
                        }
                    }
    
                    .block-text {
                        width: 100%;
                        padding-left: 0;
                        padding-top: 3rem;
    
                        .block-title {
                            font-size: 1.5rem;
                            color: black;
                            font-weight: 600;
                            margin-top: 1rem;
                        }
            
                        .desc {
                            margin-top: 1rem;
                        }
            
                        p {
                            font-size: 1.5rem;
                        }
            
                        .author {
                            font-size: 1.5rem;
                            color: $colorCorporative;
                            font-style: oblique;
                        }
    
                        hr {
                            width: 100%;
                            border-color: rgba(17, 151, 165, 0.5);;
                            margin-bottom: 0;
                        }
                    }
                }
                .generic.text, .generic.text p, .generic, .generic.text *{
                    color: #000;
                    font-size: 1.5rem !important;
                    line-height: 2.3rem !important;
                    font-family: "Montserrat-Regular" !important;
                    letter-spacing: 0 !important;

                    hr{
                        margin-top: 2.5rem;
                    }
                    table, table tr{
                        border: none !important;
                    }

                    td{
                        background-image: none !important;
                    }
                }
    
            }
    
            & > .blog-entry:first-of-type {
                
                margin-bottom: 8rem;
                .aux-img{
                    width: calc(100% - 1.5rem);
                }
                .aux-img .wrap-img{
                    padding-top: 63%;
                }
                .img {
                    background-color: grey; // BGM del post
                    width: 100% !important;                   
                    box-shadow: 12px 12px 0px 6px $colorCorporative;
                    margin-bottom: 3rem;
                }
    
                .block-text {
                    width: 100% !important;
                    padding: 0 !important;
                }
    
                .block-title {
                    width: 100%;
                }
    
                .desc {
                    margin-top: 1rem;
                }
    
                p {
                    width: 100%;
                }
    
                .author {
                    margin-top: 5rem;
                }
    
                hr {
                    display: none;
                }
    
                .badge-blog {
                    display: block;
                    position: absolute;
                    top: 1.3rem;
                    right: 1.5rem !important;
                    width: 80px;
                    border-width: 1.6rem;
                    border-color: white white white transparent;
                    border-style: solid;
                    
                    p {
                        position: absolute;
                        width: 60px;
                        top: 0%;
                        right: -40%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}