.competition.competitionDetail_2021{
    
    .wrap-btnBack{
        max-width: 1180px;
        width: 100%;
        margin: 0 auto;
        padding: 0;
        margin-top: 4.6rem;
        position: relative;       
        align-items: center;
        
        .btnBack{
            background-color: unset;
            border: 0;
            color: $colorCorporative;
            font-size: 1.5rem;
            font-family: 'Montserrat-medium';   
            padding-left: 4rem;        
            display: flex;
            align-items: center;

            &::before{              
                content: "";
                position: absolute;
                left: 0;
                width: 3rem;
                height: 3rem;
                background-image: url(/images/right.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                transform: rotate(180deg) scale(1);
                transition: .2s all ease;
            }   
            &:hover{
                &::before{   
                    width: 3.2rem;
                    height: 3.2rem;
                    transition: .2s all ease;
                    transform: rotate(180deg) scale(1.1);
                }
            }
        }  
    
    }

    .limited-wrap{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        padding: 0;

        .section-title{         
            .title{
                font-size: 3.5rem;
                text-transform: none;
                font-family: "Montserrat-SemiBold";
                font-weight: normal;
                line-height: 4.3rem;
                text-align: left;
            }
            .title-underline{
                width: 3.5rem;
                border-bottom: 4px solid;
                background: $colorCorporative;
                margin-top: 0.5rem;
                margin-bottom: 4rem;
        
            }
            
        }
      
        .form{
            width: 100%;
            display: block !important;
            overflow: visible;                     

            .input-box{
                padding-right: 0;
                margin-bottom: 2.4rem;
                input{
                    height: 4.5rem;
                }
                .label{
                    left: 1rem !important;
                }
            }
            .wrap-privacy{
                width: 100%;                   
            }

            .input-box input + .label,                    
            .input-box textarea + .label, 
            .input-box select + .label,
            .select2 + .label{
                position: absolute;
                top: 0;
                font-size: 1.3rem;
                left: 1.7rem;
                color: #575252;
                background-color: #fff;
                padding: 0 1.1rem;
                pointer-events: none;
                transform: translateY(-50%);
                transform-origin: left;
                white-space: nowrap;
                margin: 0;
                transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
                font-family: 'Montserrat-Medium';
                font-weight: normal;
            }
            .form-group{
                position: relative;       
            }
            .privacy{
                width: 100%;
                margin-top: -1rem;
                .acept{
                    position: absolute;
                }
                .labelContainer{
                    position: relative;
                }
                .textContainer{                              
                    font-family: 'Montserrat-Regular';
                    font-weight: normal;   
                    display: block;                           
                    /*
                    .link{
                        color: $colorCorporative;
                        text-decoration: none;                  
                    }
                    */
                }
                /*
                input +.labelContainer:after{
                    content: "";
                    position: absolute;
                    z-index: 1;
                    min-width: .8rem;
                    height: 0.4rem;
                    top: 2rem;
                    left: 0.2rem;
                    border: 0.2rem solid $colorCorporative;
                    border-top: 0;
                    border-right: 0;
                    transform: rotate(-45deg) scale(0);
                    transform-origin: left top;
                    transition: all 0.2s ease 0s;
                }
                input +.labelContainer:before{
                    content: "";
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    min-width: 1.2rem;
                    height: 1.2rem;
                    top: 0;
                    border: 0.1rem solid #575252;
                    border-radius: 0 !important;
                    background-color: transparent;
                    transition: background-color 0.2s linear 0.1s;
                    margin-top: 0.3rem;
                }
                input[type="checkbox"]:checked + .labelContainer:before {
                    background-color: transparent;
                    transition: background-color 0.2s linear 0s;
                }
                input[type="checkbox"]:checked + .labelContainer:after {
                    transform: translateY(-20%) rotate(-45deg) scale(1);
                    transition: all 0.2s ease 0.1s;
                }
                */
            }
            .wrap-btnDefault{
                min-width: unset;
                margin: 0 auto;
                margin-right: 0;

                .btnDefault{
                    font-size: 1.5rem;
                    line-height: 1.7rem;
                    padding: 1.5rem 4.3rem
                }
            }

            .wrap-rate-stars{
                margin-bottom: 3rem;
                .warranty-subtitle{
                    margin-bottom: 1rem;
                }
                .rate{
                    display: flex;                             
                    position: relative;
                    float: unset;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    height: 3.5rem;                               
                    font-size: unset;
                    color: transparent;
                    &>input{
                        z-index: -1;
                        position: absolute;
                    }
                }
                .rate > label{
                    position: relative;
                    width: 3rem;
                    height: 3rem;
                    margin-left: .5rem;
                    color: transparent;
                }
                .rate:not(:checked) > label:before {
                    content: '';
                    background-image: url('/images/star-grey.svg');
                    width: 3rem;  
                    height: 3rem;                              
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;                               
                  
                }
                .rate > input:hover  ~ label:before {
                    content: '';
                    background-image: url('/images/star-orange.svg');
                    filter: brightness(.9);
                    width: 3rem;  
                    height: 3rem;

                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;                              
                    
                }
                .rate > input:checked  ~ label:before{
                    content: '';
                    background-image: url('/images/star-orange.svg');
                    width: 3rem;  
                    height: 3rem;

                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;                              
                    
                }
            }
        }
        
    }
    .block{
        .content-first{
            width: 100%;
            justify-content: space-between;
            .title-form{
                width: 100%;                
                font-weight: normal;
                font-family: 'Montserrat-SemiBold';
                text-transform: uppercase;
                line-height: 3.3rem;
                font-size: 2.1rem;    
                letter-spacing: 0;
                color: $colorCorporative;
                padding-bottom: 0;
                display: flex;
                align-items: center;
                min-height: 4.9rem;                      
            }
            .buttons{
                display: flex;
                justify-content: flex-end;
                
                .wrap-btnDefault{
                    min-width: unset;
                    .btnDefault{
                        font-size: 1.5rem;
                        line-height: 1.7rem;
                        padding: 1.5rem;
                        // &.mvl{
                        //     display: none;
                        // }
                    }
                }
            }
            &.is-sent{
                flex-direction: column;

                .subtitle{
                    font-size: 2.1rem;
                    font-weight: 600;
                    color: #009aa7;
                    -webkit-margin-after: 0;
                    margin-block-end: 0;
                    margin-bottom: 0;
                    font-family: 'Montserrat-Regular';
                    text-align: center;

                    &:last-of-type{
                        font-weight: 300;
                        color: #333333;
                    }
                }
            }
        }
    }
    .wrap-form-competition{
        padding-top: 8rem;
        padding-bottom: 9.5rem;
        &.finished{
            padding-top: 4rem;
        }
    }
    .wrap-dos-columns{
        .wrap-content-info{
            width: 50%;
            padding: 2rem 5rem 0 5rem;
            .textRules.text.generic, .generic.text *{
                font-size: 1.5rem !important;
                line-height: 2.1rem !important;
                a{
                    color: $colorCorporative !important;
                }
            }
            .more-info{
                a{
                    color: $colorCorporative;
                    font-size: 1.5rem;
                    line-height: 2.1rem;
                    padding: 0;
                    margin: 0;                
                    font-family: "Montserrat-Regular";
                    letter-spacing: 0;
                    font-weight: normal;
                    text-decoration: none;                    
                }
            }
        }
        .part-form{
            width: 50%;
        }
    }
    .block .wrap-valoracion{ 
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 4rem;      

        .subtitle{
            margin-bottom: 2.1rem;
            font-size: 1.9rem;
            font-family: 'Montserrat-Medium';
            color: $colorCorporative;
            line-height: 2.1rem;
            letter-spacing: 0;
        }
        .wrap-rate-stars{
            padding-left: 2.5rem;
        }
    }
   
    .wrap-content-form{
        width: 100%;      
        display: block !important;
        max-height: 9999px;
        opacity: 1;
        transition: max-height 1s, opacity 0.4s 0.4s linear;
        padding-top: 3.9rem;

        &.hidden{
            display: none !important;
            height: auto;
            opacity: 0;
            max-height: 0;
            transition: max-height 0.5s, opacity 0.3s 0.1s linear;
        }


        .upload-progress-bar_2021{  
            width: 100%;
            bottom: 0;
            min-height: 4.5rem;
            height: auto;          
            line-height: normal;
            margin-bottom: 3rem;
            cursor: pointer;
            position: relative;

            .upload-progress-bar-inner{
                border: 1px solid #575252;
                background-color: #fff;
                height: 100%;
                min-height: 4.5rem;

                .upload-progress-bar-txt{
                    color: #fff;
                    font-weight: normal;
                    font-family: 'Montserrat-Medium';
                    line-height: 4.5rem;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    text-align: center;
                    height: 4.5rem;
                    overflow: hidden;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .add-file{
                        color: #575252;
                        font-size: 1.5rem;                       
                        text-transform: uppercase;
                        font-family: 'Montserrat-Medium';
                        font-weight: normal;
                    }
                }
            }
            .uploadErr{             
                height: auto; 
                line-height: normal;
                z-index: 1;
                &.err{
                    padding-bottom: 0;
                }
            }
            &.error{
                .upload-progress-bar-inner{
                    border: 1px solid #e3342f;
                }
            }
        }

       .imgUpLoad{          
            position: relative;          
            max-height: 45vh;
            width: 100%;
            display: block;          
            object-fit: contain;

            &.notSee{
                opacity: 0;
                display: block;                
                padding-bottom: 0;
            }
        }

        .uploadErr{
            color: #e3342f;
            font-size: 1.3rem;
            font-family: 'Montserrat-Regular';
            &.err{
                padding-bottom: 2.5rem;
            }
        }               

        form.form-paticipate{
            .form-header{
                width: 50%;
                padding-bottom: 2rem;
            }           
            .text1{
                font-weight: normal;
                font-family: 'Montserrat-Medium';
                line-height: 2.7rem;
                font-size: 1.9rem;    
                letter-spacing: 0;
                color: $colorCorporative;
            }

        }
    }

    .block{
		margin: 0;
		margin-bottom: 0;
        padding: 6rem 7rem;
		font-family: 'Montserrat-Regualr';
		color: #333 !important;
		height: auto;
		min-height: 0;
		max-height: initial;
		border: none;
        border-radius: 0;
        box-shadow: none;
        justify-content: space-between;
	}

    .wrap-list-concursantes{
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + .9rem);
        padding-bottom: 7rem;

        .section-title{
            width: 100%;
            padding-right: .9rem;
        }

        .concursante{
            width: calc(100% / 4);
            padding-right: .9rem;

            .block-photo{
                position: relative;
                padding-top: 62%;
                margin-bottom: .9rem;

                .photo{
                    position: absolute;
                    top: 0;
                    left: 0;
                    align-items: center;
                    background-color: #d8d8d8;
                    border-radius: 0;
                    border: 1px solid #979797;
                    color: #fff;
                    display: flex;
                    font-size: 1.5rem;
                    font-family: 'Montserrat-Regualr';
                    font-weight: normal;
                    height: 100%;
                    justify-content: center;
                    margin-right: 0;
                    min-width: 100%;
                    overflow: hidden;
                    width: 100%;
                    transition: all 0.4s ease-out;

                    .spinner
                    {
                        position: absolute;
                        top: 50%;
                        left: 50%;                       
                        height: auto;
                        width: auto;                    
                        margin-left: -20px;
                        margin-top: -20px;
                        z-index: 1;
                    }
                    img{
                        height: 100%;
                        object-fit: cover;
                        z-index: 2;

                        &.lazy{
                            opacity: 0;
                        }
                    }
                    & .close-modal{
                        position: absolute;
                        right: 0;
                        top: 0;
                        color: $colorWhite;
                        font-size: 60px;
                        display: none;
                    }
                    &.photo-modal{
                        position: fixed;
                        margin: auto;
                        z-index: 100;
                        top: 124px;
                        left: 20px;
                        right: 20px;
                        bottom: 60px;
                        height: 90%;
                        width: 90%;
                        border: 3px solid $colorWhite;                        
                        img
                        {
                            height: 100%!important;
                        }

                        & .close-modal
                        {
                            display: block;
                        }
                    }

                }
                .hoverbg{
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 100%;
                    background-color: rgba($colorCorporative, 0.45);
                    transition: 0.3s ease-in all;
        
                    .btn-action
                    {
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        background-color: $colorWhite;
                        color: $colorCorporative;
                        padding: 5px 5px 0 5px;
                        &.js-votar{
                            background-color: transparent;
                            color: #fff;
                        }
                    }
                    .btn-action:hover
                    {
                        background-color: $colorCorporative;
                        color: $colorWhite;
                    }
        
                    .btn-action{
                        .fs1{                                 
                            font-size: 1.5rem;
                            vertical-align: bottom;    
                        }
                        .textBtn{
                            font-size: 1.5rem;
                        }
                    } 
        
                    .js-votar
                    {
                        left: 10px;
                        right: auto;
                        .fs1{
                            font-size: 2.5rem;
                            vertical-align: middle;
                        }
                    }
        
                    .js-ampliar_2021
                    {
                        right: 10px;
                        left: auto;
                        bottom: 10px;
                        top: auto;

                        height: auto;
                        padding: 0 0.5rem;
                        padding-top: 0;
                        padding-bottom: 0.3rem;                        
                    }       
                
                }
                .wrap-background{
                    position: fixed;
                    width: 100%;
                    top: 0;
                    left: 0;
                    height: 100vh;
                    background-color: rgba(0, 0, 0, 0.5);
                    z-index: 103;
                    visibility: hidden;                    
                    &.in{
                        visibility: visible;
                        transition: visibility 0.3s ease-in;                     
                    }
                   
                }
                .votar-box{             
                    display: block;
                    height: 0;
                    position: fixed;
                    top: 50%;
                    width: 40%;
                    max-width: 54.2rem;
                    min-width: 54.2rem;

                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: #fff;
                    padding: 7rem 3.2rem 3.2rem 3.2rem;
                    color: #FFF;
                    transition: height 0.3s ease-in;
                    overflow: hidden;
                    z-index: 15;

                    .closeVotar{                     
                        width: 2.5rem;  
                        height: 2.5rem;
                        position: absolute;
                        top: 1.5rem;                    
                        right: 1.5rem;
                        cursor: pointer;

                        &::before{
                            content: '';
                            background-image: url('/images/close-concurso.svg');
                            width: 100%;  
                            height: 100%;
                            position: absolute;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;       
                        }
                    }
                    @media (max-width: $screen-sm-max){
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        right: unset;
                        bottom: unset;			
                        max-width: calc(100% - 3rem);
                        min-width: calc(100% - 3rem);
                        width: 100%;

                    }

                    &.in
                    {
                        height: auto;                      
                    }

                    .text
                    {
                        font-family: 'Montserrat-Medium';
                        font-weight: normal;
                        font-size: 1.9rem;
                        line-height: 2.7rem;
                        color: #009aa7;
                        margin-bottom: 2.5rem;
                    }
                    .form-control
                    {
                        font-size: 14px;
                        font-weight: 400;
                        color: $colorPrimary;
                        margin-bottom: 10px;
                    }
                    .legal
                    {
                        font-size: 1.1rem;
                        font-family: 'Montserrat-Regular';
                        font-weight: normal;
                        color: #009aa7;
                        text-decoration: none;
                        line-height: 1.5rem;
                        margin-top: 1.7rem;
                        margin-bottom: 3rem;
                    }

                    .wrap-btnDefault{
                        min-width: unset;
                        margin: 0 auto;
                        margin-right: 0;
                        

                        .btnDefault{
                            font-size: 1.5rem;
                            line-height: 1.7rem;
                            padding: 1.4rem 2.5rem;
                        }
                    }
                    
                }
                &:hover,
                &:focus,
                &.in
                {
                    .hoverbg
                    {
                        opacity: 1;
                        z-index: 3;
                    }
                }

                &.active
                {
                    opacity: 1;
                }
            }
        }
    }
    // PHOTO Full screen

    .mygallerybox, .mygallerybox_finished{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: -1;
        opacity: 0;

        -webkit-transition: all ease-in 0.3s;
        transition: all ease-in 0.3s;
    
        &.preopen,
        &.open{
            z-index: 1040;	
        }

        &.open {
            opacity: 1;
        }

        & .swiper-container{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        

        .wrap-img{
        
            max-width: 60%;
            max-height: 60%;
            position: absolute;
            top: 0;
            left:0;
            right:0;
            bottom: 0;
            margin: auto;

            .slide-img{
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            .capture{
                position: relative;        
                bottom: -1rem;
                color: $colorCorporative;
                font-family: 'Montserrat-Medium';
                font-size: 1.9rem;
                line-height: 2.7rem;
            }
            .aux{                
                height: auto;                
                margin: 0 auto;
                align-self: center;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
    

            @media (max-width: $md-width) {
                max-width: 100%;
                max-height: 100%;
                padding: 16rem 1.5rem;
                display: flex;
                align-items: center;

                .slide-img{
                    position: relative;
                    width: 100%;
                    height: auto;
                    max-height: 100%;
                    top: 0;                    
                    object-fit: contain;                   
                }

                .capture{      
                    line-height: 2.1rem;
                    bottom: unset;
                    font-size: 1.7rem;
                    position: initial;
                    padding-top: 1rem;
                }
            }
        }

        .btn-close {    
            position: absolute;
            top: 5rem;
            right: 5rem;
            padding: 0;
            background-color: transparent;          
            -webkit-transition: 0.3s ease-in all;
            transition: 0.3s ease-in all;
            z-index: 1040;
            width: 2.4rem;
            height: 2.4rem;
            opacity: .75;
            &:hover{
                background-color: transparent;
            }

            &::before{
                content: "";
                position: absolute;
                background-image: url('/images/close-x.svg');
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                //transform: rotate(45deg);
            }
            
            &:hover{
                opacity: 1;            
            }
            @media (max-width: $md-width) {
                top: 3rem;
                right: 3rem;
            }
        }

        .swiper-button-prev,
        .swiper-button-next, 
        .swiper-button-prev.fin,
        .swiper-button-next.fin {       
            width: 3.2rem;
            height: 3.2rem;      
            background-image: none;
        }
        .swiper-button-prev, .swiper-button-prev.fin{
            left: 1.5rem;          

            &:before{
                content: '';
                position: absolute;
                width: 3.2rem;
                height: 3.2rem;
                background-image: url('/images/right.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                transform: rotate(180deg);
            }
        }
        .swiper-button-next, .swiper-button-next.fin{
            right: 1.5rem;

            &:before{
                content: '';
                position: absolute;
                width: 3.2rem;
                height: 3.2rem;
                background-image: url('/images/right.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }  
 
    &.videos_old_2021{

        &.first{
            .section-title{
                padding-top: 5rem !important;              
            }        
            padding-bottom: 8rem;   
        }
        &:not(.first){       
            padding-top: 0;     
            padding-bottom: 8rem;
        }
        .block-photo .hoverbg{
            .title{
                background-color: #FFF;
                position: absolute;
                bottom: 0;
                width: 100%;
                font-family: 'Montserrat-Medium';
                font-size: 1.7rem;
                line-height: 2.5rem;
                margin: 0;
                color: $colorCorporative;
                padding: 0.5rem 1rem;
            }
        }
        .wrap-list-concursantes .concursante .block-photo .hoverbg .btn-action.js-videos-ampliar{
            position: absolute;
            top: 1rem;
            left: 1rem;
            background-color: #FFF;
            color: $colorCorporative;
            height: auto;
            padding: 0 0.5rem;
            padding-top: 0;           
            padding-bottom: 0.3rem;         
        }
        .wrap-winner{
            margin-bottom: 5rem;
            .title{
                font-family: 'Montserrat-SemiBold';
                font-size: 2.7rem;
                line-height: 3.1rem;
                letter-spacing: 0;
                font-weight: normal;
                color: $colorCorporative;
                margin-bottom: 1.5rem;
            }
            .title-name{
                font-family: 'Montserrat-Medium';
                font-size: 2.1rem;
                line-height: 2.7rem;
                letter-spacing: 0;
                font-weight: normal;
                color: #575252;
            }
            .title-fin{
                font-family: 'Montserrat-Medium';
                font-size: 1.9rem;
                line-height: 2.5rem;
                letter-spacing: 0;
                font-weight: normal;
                color: #575252;
            }
            .wrap-form{
                width: calc(40% + 3rem);
                padding-right: 3rem;
            }

            .photo{
                width: calc(60% - 3rem);   
                margin: 0;            
                height: 100%;
                border-radius: 0; 
            } 

        }
     

    }
    .contest_photo_finished{
        padding: 5rem 7rem;
        .title{
            width: 100%;
            font-weight: normal;
            font-family: "Montserrat-SemiBold";
            text-transform: uppercase;
            line-height: 3.3rem;
            font-size: 2.1rem;
            letter-spacing: 0;
            color: $colorCorporative;
            padding-bottom: 0;
            display: flex;           
            padding-bottom: 2rem;  
            margin: 0;
                       
        }
      
        .namePhoto, .textFinalists{
            width: 100%;
            font-weight: normal;
            font-family: "Montserrat-Regular";           
            line-height: 3.3rem;
            font-size: 2.1rem;
            letter-spacing: 0;
            color: $colorCorporative;           
            display: flex;            
            padding-bottom: 5rem;  
            padding-top: 1.5rem;
        }
        .textFinalists{
            padding-bottom: 7rem;
        }
        .textFinished{
            column-count: 2;          
            column-gap: 6rem;  
            &.text.generic, &.text.generic *{
                font-size: 1.5rem !important;
                line-height: 2.1rem !important;
                font-family: "Montserrat-Regular" !important;
                letter-spacing: 0 !important;

                p{
                    color: #000;
                    padding-bottom: 2.5rem;
                    &:last-of-type{
                        padding-bottom: 0;
                    }
                    &:empty{
                        display: none;
                    }
                }
                br{
                    display: none;
                }
                &:empty{
                    display: none;
                }
                b{
                    font-family: 'Montserrat-Bold' !important;
                }
                i{
                    font-family: 'Montserrat-Italic' !important;
                }
            }
        }
        
        .photo{           
            width: 60%;
            height: 100%;
            max-height: 75vh;
            border-radius: 0;
            margin: 0;
        }
    }

    .wrap-slider-finalists{
        position: relative;
        width: calc(100% + 3rem);    
        left: -1.5rem;

        .control {
            width: 3rem;
            height: 3rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
            color: transparent;
            border: none;
            font-size: unset;
            opacity: .75;
            transition: .2s all ease-in;
            z-index: 1;
            &:hover{
                opacity: 1;
            }
            &.swiper-button-disabled{
                transition: .2s all ease-in;
                opacity: 0;
            }
        
            &.control-prev{              
                width: 3.2rem;
                height: 3.2rem;
                left: 0rem;
            
                &:before{
                    content: "";
                    background-image: url('/images/right.svg');
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: 0;
                    left: 0;
                    transform: rotate(180deg);
                }
            
            }
            &.control-next{            
                width: 3.2rem;
                height: 3.2rem;
                right: 0rem;
                
                &:before{
                    content: "";
                    background-image: url('/images/right.svg');
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
    .pasadorPost.swiper-container{       
        position: relative;
        background-color: transparent; 
        min-height: unset;
        width: calc(100% - 3rem) !important;
        margin: 0 auto;

        .swiper-wrapper{
            .swiper-slide{
                padding-right: 1.5rem;
                width: calc(100% / 3);
                .wrap-item{
                    position: relative;
                    width: 100%;
                    padding-top: 64%;
                    .img-responsive{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                    }
                }
            }
        }
       
       
    }
}

@media (max-width: $md-width){
    .competition.competitionDetail_2021{
        padding-bottom: 7rem;
        .limited-wrap{        
            max-width: 100%;         
    
            .section-title{         
                .title{
                    font-size: 2.5rem;                  
                    line-height: 3.3rem;                   
                }
                .title-underline{
                    border-bottom: 2px solid;
                }
                
            }
          
            .form{
                width: 100%;
                display: block !important;
                overflow: visible;                     
    
                .input-box{
                    padding-right: 0;
                    margin-bottom: 2.4rem;
                    input{
                        height: 4.5rem;
                    }
                    .label{
                        left: 1rem !important;
                    }
                }
                .wrap-privacy{
                    width: 100%;                   
                }
    
                .input-box input + .label,                    
                .input-box textarea + .label, 
                .input-box select + .label,
                .select2 + .label{
                    position: absolute;
                    top: 0;
                    font-size: 1.3rem;
                    left: 1.7rem;
                    color: #575252;
                    background-color: #fff;
                    padding: 0 1.1rem;
                    pointer-events: none;
                    transform: translateY(-50%);
                    transform-origin: left;
                    white-space: nowrap;
                    margin: 0;
                    transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
                    font-family: 'Montserrat-Medium';
                    font-weight: normal;
                }
                .form-group{
                    position: relative;       
                }
                .privacy{
                    .acept{
                        position: absolute;
                    }
                    .labelContainer{
                        position: relative;
                    }
                    .textContainer{                              
                        font-family: 'Montserrat-Regular';
                        font-weight: normal;   
                        display: block;                           
                        
                        /*
                        .link{
                            color: $colorCorporative;
                            text-decoration: none;                            
                        }
                        */
                    }
                    /*
                    input +.labelContainer:after{
                        content: "";
                        position: absolute;
                        z-index: 1;
                        min-width: .8rem;
                        height: 0.4rem;
                        top: 2rem;
                        left: 0.2rem;
                        border: 0.2rem solid $colorCorporative;
                        border-top: 0;
                        border-right: 0;
                        transform: rotate(-45deg) scale(0);
                        transform-origin: left top;
                        transition: all 0.2s ease 0s;
                    }
                    input +.labelContainer:before{
                        content: "";
                        position: relative;
                        display: inline-block;
                        vertical-align: middle;
                        min-width: 1.2rem;
                        height: 1.2rem;
                        top: 0;
                        border: 0.1rem solid #575252;
                        border-radius: 0 !important;
                        background-color: transparent;
                        transition: background-color 0.2s linear 0.1s;
                        margin-top: 0.3rem;
                    }
                    input[type="checkbox"]:checked + .labelContainer:before {
                        background-color: transparent;
                        transition: background-color 0.2s linear 0s;
                    }
                    input[type="checkbox"]:checked + .labelContainer:after {
                        transform: translateY(-20%) rotate(-45deg) scale(1);
                        transition: all 0.2s ease 0.1s;
                    }
                    */
                }
            
                .wrap-rate-stars{
                    margin-bottom: 3rem;
                    .warranty-subtitle{
                        margin-bottom: 1rem;
                    }
                    .rate{
                        display: flex;                             
                        position: relative;
                        float: unset;
                        flex-direction: row-reverse;
                        justify-content: flex-end;
                        height: 3.5rem;                               
                        font-size: unset;
                        color: transparent;
                        &>input{
                            z-index: -1;
                            position: absolute;
                        }
                    }
                    .rate > label{
                        position: relative;
                        width: 3rem;
                        height: 3rem;
                        margin-left: .5rem;
                        color: transparent;
                    }
                    .rate:not(:checked) > label:before {
                        content: '';
                        background-image: url('/images/star-grey.svg');
                        width: 3rem;  
                        height: 3rem;                              
                        position: absolute;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;                               
                      
                    }
                    .rate > input:hover  ~ label:before {
                        content: '';
                        background-image: url('/images/star-orange.svg');
                        filter: brightness(.9);
                        width: 3rem;  
                        height: 3rem;
    
                        position: absolute;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;                              
                        
                    }
                    .rate > input:checked  ~ label:before{
                        content: '';
                        background-image: url('/images/star-orange.svg');
                        width: 3rem;  
                        height: 3rem;
    
                        position: absolute;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;                              
                        
                    }
                }
            }
            
        }
        .block{
            padding: 3rem;
            .content-first{           
                flex-direction: column;

                .title-form{
                    width: 100%; 
                    line-height: 2.7rem;
                    font-size: 2.1rem;  
                    min-height: 4.9rem;                      
                }
                .buttons{ 
                    .wrap-btnDefault{
                        margin-top: 6rem;       
                    }   
                }
            }
        }
        .wrap-form-competition{
            padding-top: 0;   
            padding-bottom: 7rem;     
            &.finished{
                padding-top: 3rem;
            }   
        }
        .wrap-btnBack{
            margin-top: 0;            
            .btnBack{
                padding-left: 3rem;
                &::before{
                    width: 2.5rem;
                    height: 2.5rem;                   
                }
            }
        }
    .contest_photo_finished{
        padding: 2rem 1.5rem;
        .title{
            width: 100%;
            font-weight: normal;
            font-family: "Montserrat-SemiBold";
            text-transform: uppercase;
            line-height: 2.7rem;
            font-size: 1.9rem;
            letter-spacing: 0;
            color: $colorCorporative;
            padding-bottom: 0;
            display: flex;           
            padding-bottom: 2rem;  
            margin: 0;
                       
        }
      
        .namePhoto, .textFinalists{
            width: 100%;
            font-weight: normal;
            font-family: "Montserrat-Regular";           
            line-height: 2.7rem;
            font-size: 1.9rem;
            letter-spacing: 0;
            color: $colorCorporative;           
            display: flex;            
            padding-bottom: 3rem;  
            padding-top: 1.5rem;
        }
        .textFinalists{
            padding-bottom: 3rem;
        }
        .textFinished{
            column-count: 1;          
            column-gap: 0;  
            &.text.generic, &.text.generic *{
                font-size: 1.5rem !important;
                line-height: 2.1rem !important;
                font-family: "Montserrat-Regular" !important;
                letter-spacing: 0 !important;

                p{
                    color: #000;
                    padding-bottom: 2.5rem;
                    &:last-of-type{
                        padding-bottom: 0;
                    }
                    &:empty{
                        display: none;
                    }
                }
                br{
                    display: none;
                }
                &:empty{
                    display: none;
                }
                b{
                    font-family: 'Montserrat-Bold' !important;
                }
                i{
                    font-family: 'Montserrat-Italic' !important;
                }
            }
        }
  
        .photo{
            height: auto;
            width: 100%;
            max-height: 50vh;
            border-radius: 0;
            margin: 0;

            .preview-photo{
                width: 100%;
                height: 100;
                object-fit: contain;
            }
        }
    }
        .wrap-dos-columns{
            flex-direction: column;
            .wrap-content-info{ 
                width: 100%;
                padding: 0;

                .textRules.text.generic, .generic.text *{
                    font-size: 1.5rem !important;
                    line-height: 2.1rem !important;
                    a{
                        color: $colorCorporative !important;
                    }
                }
                .more-info{
                    margin-bottom: 0;
                    font-size: 1.5rem;
                    font-weight: normal;
                    line-height: 2.1rem;
                    padding-top: 1.5rem;
                    a{
                        color: $colorCorporative;
                        font-size: 1.5rem;
                        line-height: 2.1rem;
                        padding: 0;
                        margin: 0;                
                        font-family: "Montserrat-Regular";
                        letter-spacing: 0;
                        font-weight: normal;
                        text-decoration: none;                    
                    }
                }
            }
            .part-form{
                width: 100%;
            }
        }
        .block .wrap-valoracion{ 
            flex-direction: column;
            margin-top: 4rem;      

            .block-stars{
                order: 1;
            }
            .block-input{
                order: 2;
            }
            .subtitle{
                margin-bottom: 2.1rem;
                font-size: 1.9rem;
                font-family: 'Montserrat-Medium';
                color: $colorCorporative;
                line-height: 2.1rem;
                letter-spacing: 0;
            }
            .wrap-rate-stars{
                padding-left: 0;
            }
        }
       
        .wrap-content-form{
            width: 100%;      
            display: block !important;
            max-height: 9999px;
            opacity: 1;
            transition: max-height 1s, opacity 0.4s 0.4s linear;
            padding-top: 3.9rem;
    
            &.hidden{
                display: none !important;
                height: auto;
                opacity: 0;
                max-height: 0;
                transition: max-height 0.5s, opacity 0.3s 0.1s linear;
            }
    
           .imgUpLoad{               
                position: relative;             
                max-height: 45vh;
                width: 100%;
                display: block;          
                object-fit: contain;
            }
    
            .uploadErr{
                color: #e3342f;
                font-size: 1.3rem;
                font-family: 'Montserrat-Regular';
                &.err{
                    padding-bottom: 2.5rem;
                }
            }

            form.form-paticipate{
                .form-header{
                    width: 100%;    
                }           
                .text1{
                    font-weight: normal;
                    font-family: 'Montserrat-Medium';
                    line-height: 2.1rem;
                    font-size: 1.7rem;    
                    letter-spacing: 0;
                    color: $colorCorporative;
                }
    
            }
        }
    
        .wrap-list-concursantes{
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + .6rem);
            padding-bottom: 0;
    
            .section-title{
                width: 100%;
                padding-right: .6rem;
            }
    
            .concursante{
                width: calc(100% / 2);
                padding-right: .6rem;
    
                .block-photo{
                    position: relative;
                    padding-top: 62%;
                    margin-bottom: .6rem;
                
                    &.in .hoverbg{
                        z-index: 103;
                    }                    
    
                    .photo{                       
             
                        & .close-modal{
                            position: absolute;
                            right: 0;
                            top: 0;
                            color: $colorWhite;
                            font-size: 60px;
                            display: none;
                        }
                        &.photo-modal{
                            position: fixed;
                            margin: auto;
                            z-index: 100;
                            top: 124px;
                            left: 20px;
                            right: 20px;
                            bottom: 60px;
                            height: 90%;
                            width: 90%;
                            border: 3px solid $colorWhite;                        
                            img
                            {
                                height: 100%!important;
                            }
    
                            & .close-modal
                            {
                                display: block;
                            }
                        }
    
                    }
                    .hoverbg{                       
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 100%;                       
                        transition: 0.3s ease-in all;
                        opacity: 1;
                        visibility: visible;
                        background-color: unset;
                        z-index: 2;


                        .title{
                            font-family: 'Montserrat-Medium';
                            font-size: 1.5rem;
                            line-height: 2.1rem;
                            padding: 0.5rem 1rem;
                        }
            
                        .btn-action
                        {
                            position: absolute;
                            top: 10px;
                            left: 10px;
                            background-color: $colorWhite;
                            color: $colorCorporative;
                            padding: 5px 5px 0 5px;
                            &.js-votar{
                                background-color: transparent;
                                color: #fff;
                                width: 3rem;
                                height: 3rem;
                                padding: 0;
                                .fs1{
                                    font-size: 2.5rem;
                                }
                            }
                            &.js-ampliar_2021{
                                width: 3rem;
                                height: 3rem;
                                bottom: 1rem;
                                right: 1rem;
                                top: unset;
                                left: unset;
                                .fs1{  
                                    font-size: 2rem;
                                }
                            }
                        }
                        .btn-action{
                            .fs1{                                              
                                font-size: 1.5rem;
                                vertical-align: bottom;                               
                            }
                            .textBtn{
                                font-size: 1.5rem;
                            }
                        } 
            
                        .js-votar
                        {
                            left: 1rem;
                            right: auto;
                            .fs1{
                                font-size: 1.5rem;
                                vertical-align: middle;
                            }
                        }
            
                    }
                 
                    .votar-box{       
                        .closeVotar{                     
                            width: 2.5rem;  
                            height: 2.5rem;
                            position: absolute;
                            top: 1.5rem;                    
                            right: 1.5rem;
                            cursor: pointer;
    
                            &::before{
                                content: '';
                                background-image: url('/images/close-concurso.svg');
                                width: 100%;  
                                height: 100%;
                                position: absolute;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;       
                            }
                        }
                       
                        .text
                        {
                            font-family: 'Montserrat-Medium';
                            font-weight: normal;
                            font-size: 1.9rem;
                            line-height: 2.7rem;
                            color: #009aa7;
                            margin-bottom: 2.5rem;
                        }
                        .form-control
                        {
                            font-size: 14px;
                            font-weight: 400;
                            color: $colorPrimary;
                            margin-bottom: 10px;
                        }
                        .legal
                        {
                            font-size: 1.1rem;
                            font-family: 'Montserrat-Regular';
                            font-weight: normal;
                            color: #009aa7;
                            text-decoration: none;
                            line-height: 1.5rem;
                            margin-top: 1.7rem;
                            margin-bottom: 3rem;
                        }
    
                        .wrap-btnDefault{
                            min-width: unset;
                            margin: 0 auto;
                            margin-right: 0;
                            .btnDefault{
                                font-size: 1.5rem;
                                line-height: 1.7rem;
                                padding: 1.4rem 2.5rem;
                            }
                        }
                       
                    }            
                }
            }
        }


        &.videos_old_2021{

            &.first{
                .section-title{
                    padding-top: 0rem !important;              
                }        
                padding-bottom: 5rem;   
            }
            &:not(.first){       
                padding-top: 0;     
                padding-bottom: 5rem;
            }
            .wrap-list-concursantes .concursante .block-photo .hoverbg .btn-action.js-videos-ampliar{      
                padding-top: 0.5rem;
            }
            .block-photo .hoverbg{
                .title{        
                    font-size: 1.5rem;
                    line-height: 2.1rem;                   
                }
            }
            .wrap-winner{
                margin-bottom: 4rem;
                flex-direction: column;
                padding: 2.5rem 1.5rem;

                .title{                   
                    font-size: 2.1rem;
                    line-height: 2.7rem;           
                }
                .title-name{                   
                    font-size: 1.7rem;
                    line-height: 2.5rem;
                }
                .title-fin{                  
                    font-size: 1.5rem;
                    line-height: 2.1rem;                    
                }
                .wrap-form{
                    width: 100%;
                    padding-right: 0;
                    padding-bottom: 2.5rem;
                }
                .photo{
                    width: 100%; 
                }

            }        

        }
    }
}
