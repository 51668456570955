.breadcrumb{
	display: flex;
	align-items: baseline;
	padding: 0;
	margin: 0;
	background-color: transparent !important;
	//padding-bottom: rem(10px);
	.crumb {
		display: flex;
    	align-items: baseline;
		.crumb-inner {
			text-transform: uppercase;
			font-weight: bold;
			font-size: rem(15px);
			color: $gray-dark;
			transition: opacity $t-fast linear;
			&:hover {
				opacity: 0.7;
			}

			h1, .h1{
				font-size: inherit;
				font-family: inherit;
				font-weight: inherit;
				margin: 0;
			}
		}
		&:after {
			content: '';
			display: none;
			margin-left: rem(15px);
			height: rem(10px);
			width: rem(5px);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			background-image: url('/css/assets/simple-arrow.svg');
		}
		&:first-child {
			.crumb-inner {
				font-size: rem(25px);
			}
		}
		&:last-child:not(:first-child) {
			.crumb-inner {
				opacity: 0.5;
				pointer-events: none;
			}
		}
		&:not(:last-child) {
			margin-right: rem(15px);
			&:after {
				display: block;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		padding-bottom: rem(5px);
	 	.crumb {
	 		display: none;
	 		&:last-child {
	 			display: block;
	 			.crumb-inner {
	 				opacity: 1;
	 				font-size: rem(25px);
	 			}
	 		}
	 	}
	}
}
.app-list_2021{
	.search-video-box{
		font-size: 1.5rem;
	}
	.module-list-fancy .module-list-header{
		border-bottom: none !important;
		padding-top: 0 !important;
	}
	.breadcrumb{		
		padding-bottom: 1rem;
		position: relative;
		&::before{
			content: '';
			width: 3.5rem;
			height: .4rem;
			position: absolute;
			bottom: -1rem;
			left: 0;
			background-color: #1197a5;
		}
		.crumb {		
			.crumb-inner {
				text-transform: unset;			
				padding-top: 5.5rem;
				color: #1197a5;
				font-family: 'Montserrat-SemiBold';
				font-weight: normal;
				font-size: 2.5rem;	
				
				h1, .h1{
					font-size: inherit;
					font-family: inherit;
					font-weight: inherit;
					margin: 0;
				}
				
			}
			&:after {			
				margin-left: 1.5rem;
				height: 1.8rem;
				width: 1.3rem;			
				background-image: url('/images/simple-arrow-blue.svg');
			}
			&:first-child {
				.crumb-inner {
					font-size: 3.5rem;
				}
			}
			&:last-child:not(:first-child) {
				.crumb-inner {
					opacity: 1;					
				}
			}
			&:not(:last-child) {
				margin-right: 1.5rem;			
			}
			&::before{
				content: none;
			}
		}

		@include media-breakpoint-down(sm) {
			padding-bottom: .5rem;
			.crumb {			
				&:last-child {					
					.crumb-inner {					
						font-size: 2.5rem;
					}
				}
			}
		}
	}
}