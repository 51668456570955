.wrap-module-list .module-list-container{
    .section-empresa.sofas{      
        padding-top: calc(7.3rem - 2.7rem);
        margin-top: 0;
        
        &.mvl{
            display: none;
        }
        &.section-text-only{
            .part-left{
                padding-right: 3.5rem;
            }
            .part-right{
                padding-left: 3.5rem;
            }
            
        }

        .wrap-content{
            .wrap-title{
                width: 50%;
                .title, .subtitle{
                    padding-bottom: 2.5rem;
                }
            }
        }
        .two-column, .list-items{          
            .column-left, .column-right{
                width: 50%;
            }
            .item{
                padding-bottom: 8.5rem;

                .item-img{
                    position: relative;     
                    padding-bottom: 2.9rem;
                    transform: translateX(0);     
                    &.last{
                        padding-top: 103% !important;

                    }  
                }
                .item-content{
                    padding-left: 2rem;
                    padding-top: 4rem;
                    position: relative;
                    .num{
                        font-size: 6.1rem;
                        color: rgba(17, 151, 165, 0.22);
                        font-family: 'Montserrat-SemiBold';
                        letter-spacing: 0;   
                        top: 0;
                        position: absolute;
                        left: 0;                     
                    }
                    .title{
                        font-size: 2.5rem;
                        text-transform: uppercase;
                        line-height: 3rem;
                        letter-spacing: 2.21px;
                        font-family: 'Montserrat-Medium';
                        color: $colorCorporative;
                        padding-bottom: 2.6rem;
                    }
                    .text{
                        padding-bottom: 2.6rem;

                    }           
               
                }

            }
            
            .column-left{          
                .item{  
                    .aux-img{
                        width: calc(100% + 10rem);
                    }    
                    .item-img{                      
                        width: 100%;     
                        padding-top: 70%;                        
                    }

                }

            }

            .column-right{
                padding-left: 6.6rem;              
                .item{
                    width: 100%;
                    .item-img{
                        width: 100%;
                        padding-top: 120%;
                        &.js-scrollMuve{
                            .foto-muve2{
                                transform: translateY(10rem);
                                transition: .7s all linear;
                            }                            
                        }
                    }
                    .item-content{
                        padding-left: 0;     
                        .num{
                            left: -2rem;
                        }         
                    }                    
                    &:nth-child(2){
                        padding-top: 22rem;
                    }
                }
            }
        }
    } 
}

.section-empresa.sofas{
    .aux-slider.ordinary{                       
        width: 100%;
        overflow: hidden;
        .wrap-slider{
            width: calc(100% + 3.5rem);
        }
        .aux-box{
            margin-bottom: 3rem;
        }
        .slider-ordinary{
            &.slick-slider{
                .slick-list{
                    padding: 0 !important;
                    .slick-slide{
                        .item{
                            position: relative;
                            padding-top: 48%;
                            .img{
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                            }
                        }                        
                    }
                }
            }
        }
    }

    .wrap-link{
        font-size: 1.5rem;
        line-height: 1.9rem;
        font-family: 'Montserrat-Medium';
        text-transform: uppercase;

        .link{
            position: relative;
            color: $colorCorporative;
            &::before{
                content: '';
                color: $colorCorporative;
                background-image: url('/images/arrow-blue.svg');
                width: 1.6rem;
                height: 100%;
                background-size: contain;
                background-position: center;
                position: absolute;
                background-repeat: no-repeat;
                right: 0;
                transform: translateX(3rem);
                transition: .3s transform linear;
            }                         
            &:hover{                                    
                &::before{
                    transform: translateX(4rem) scale(1.2);
                    transition: .3s transform linear;
                }
            }
        }
    }

}

@media (max-width: $md-width) {
    .wrap-module-list .module-list-container{
        .section-empresa.sofas{
            padding-top: 2.5rem;
            &:first-of-type{
                padding-top: 2.2rem;
            }  
            .wrap-content{
                flex-direction: column;
                .warp-content-text{
                    flex-direction: column;
                    .part-left, .part-right{
                        width: 100%;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
                .wrap-title{
                    width: 100%;
                    .subtitle{
                        font-size: 1.7rem;
                        line-height: 3rem;
                        letter-spacing: 1.5px;                       
                        padding-right: 0;
                        padding-bottom: 3.5rem;
                    }
                }               
            }
            
             &.mvl{
                display: flex;
                flex-direction: column;
                .two-column{
                    width: 100%;

                    .column-left{
                        width: 50%;
                        .item{
                            width: 100%;
                            .item-img{
                                width: calc(200%);
                                padding-top: 125%;
                            }
                        }

                    }
                    .column-right{
                        position: relative;
                        padding-left: 0;
                        width: 50%;
                        padding-right: 1.5rem;
                        margin-top: 22.1rem;
                        .item{
                            right: 7.5rem;
                            position: relative;
                            width: calc(150%);
                            .item-img{
                                width: 100%;
                                padding-top: 120%;
                            }
                        }
                    }
                  

                }
                .list-items{
                    .item{
                        padding-bottom: 10rem;
                        .item-img{  
                            position: relative;                    
                            padding-top: 103%;        
                            width: 100vw;
                            transform: translateX(-1.5rem);                                             
                        }
                        .item-content{
                            .title{
                                font-size: 2.1rem;
                                line-height: 3rem;
                                letter-spacing: 1.5px;
                            }
                            .text.generic{
                                font-size: 1.7rem;
                                line-height: 2.5rem;
                                letter-spacing: 0;
                            }
                        }
                    }
                }
            }  
            &.desk{
                display: none;            
            }  
        } 
    }

    .section-empresa.sofas{
        .aux-slider.ordinary{                       
            width: 100%;
            overflow: hidden;
            .wrap-slider{
                width: calc(100% + 1.5rem);
            }
            .aux-box{
                margin-bottom: 3rem;
            }
            .slider-ordinary{
                &.slick-slider{
                    .slick-list{
                        padding: 0 !important;
                        .slick-slide{
                            .item{
                                position: relative;
                                padding-top: 48%;
                                margin-right: 1.5rem;
                                .img{
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    left: 0;
                                }
                            }                            
                        }
                    }
                }
            }
        }    
    }
}