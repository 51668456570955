.topbar
{
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-left: 26px;
    padding-right: 38px;
    z-index: 5;

    @include media-breakpoint-up(md){
        padding-left: 32px;
        padding-right: 40px;
    }

    .alb-shadow
    {
        &, &:after, &:before
        {
            content: '';
            display: block;
            height: 80px;
            position: absolute;
            top: 0;
            z-index: -1;
        }

        &
        {
            background: url('/img/topbar/mob-middle.png') repeat-x transparent top center;
            right: 187px;
            left: 187px;
            pointer-events: none;
        }


        &:after
        {
            background: url('/img/topbar/mob-left.png') repeat-x transparent top left;
            width: 187px;
            left: -187px;
        }

        &:before
        {
            background: url('/img/topbar/mob-right.png') repeat-x transparent top right;
            width: 187px;
            right: -187px;
        }


        @include media-breakpoint-up(lg){
            &
            {
                background: url('/img/topbar/middle.png') repeat-x transparent top center;
                right: 640px;
                left: 640px;
            }


            &:after
            {
                background: url('/img/topbar/left.png') repeat-x transparent top left;
                width: 640px;
                left: -640px;
            }

            &:before
            {
                background: url('/img/topbar/right.png') repeat-x transparent top right;
                width: 640px;
                right: -640px;
            }
        }
    }

    .ham
    {
        cursor: pointer;
        margin-right: 32px;
        &+.hide-menu+.logo {
            height: unset;
        }
    }

    .logo
    {
        img {
            height: rem(35px);
        }
    }

    .menu.menu-horizontal
    {
        display: none;

        justify-content: flex-start;
        align-items: center;
        height: 100%;
        transition: padding $t-fast ease-in;


        @include media-breakpoint-up(lg){
            display: flex;
            padding-left: 1.6rem;
        }

        @include media-breakpoint-up(xl){
            padding-left: 10.125rem;
        }

        & > .item {
            padding-left: 20px;
            padding-right: 20px;
            line-height: normal;
            font-weight: bold;
            font-size: rem(11px);
            text-transform: uppercase;

            @media (min-width: 1280px) {
                font-size: rem(13px);
                padding-left: 24px;
                padding-right: 24px;
            }

            @media (min-width: 1500px) {
                font-size: rem(15px);
                padding-left: 28px;
                padding-right: 28px;
            }

            @media (min-width: 2000px) {
                font-size: rem(17px);
                padding-left: 32px;
                padding-right: 32px;
            }

            a
            {
                position: relative;
                color: $gray-dark;
            }

            &.is-active
            {
                a
                {
                    text-decoration: none;
                    color: $c-fama;

                    &:before
                    {
                        background-color: $c-fama;
                    }

                    .famaliving &
                    {
                        color: $c-famaliving;

                        &:before
                        {
                            background-color: $c-famaliving;
                        }
                    }
                    
                    h1{
                        font-size: inherit;
                        font-family: inherit;
                        font-weight: inherit;
                        margin: 0;
                    }
                }
            }


            a:before
            {
                content: '';
                position: absolute;
                height: 2px;
                background-color: $gray-dark;
                transform: scaleX(0);
                right: 0;
                bottom: -6px;
                left: 0;
                transition: transform $t-fast ease-in-out;
            }

            a:hover:before
            {
                transform: scaleX(1);
            }
        }

    }

    .btn-search
    {
        margin-left: 2rem;
        cursor: pointer;

        @include media-breakpoint-up(xl){
            margin-left: 3rem;
        }

        @include media-breakpoint-down(lg){
            margin-left: 0;
            .famaliving &
            {
                //margin-right: -1rem;
            }
        }
    }

    .menu-vertical
    {
        width: 264px;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $c-fama;
        overflow-y: auto;
        transform: translateX(-264px);

        .famaliving &
        {
            background-color: $c-famaliving;
        }


        .logo
         {
             padding: 1rem 2rem 1rem;
         }

        & > ul
        {
            list-style: none;
            margin-left: 0;
            padding-left: 0;

            .item a
            {
                display: block;
                color: white;
                padding-top: 1.2rem;
                padding-bottom: 1.2rem;
                padding-left: 2rem;
                padding-right: 2rem;
                background-color: transparent;


                font-size: $f-size-normal-body;
                font-weight: bold;

                transition: background-color $t-fast ease-in-out;
                text-transform: uppercase;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;


                &:hover
                {
                    background-color: rgba(black, 0.05);
                    text-decoration: none;
                }
            }
            .item.is-open
            {
                background-color: darken($gray, 20%);
                a{
                    color: $gray-dark;
                }
            }
            .item.top-separator
            {
                border-top: 1px dashed white;
            }

           li.item > ul
            {               
                list-style: none;
                padding: 0;
                background-color: $gray;

                > li.item a
                {

                    color: $gray-dark;
                    padding-top: 1.2rem;
                    padding-bottom: 1.2rem;
                    padding-left: 2rem;
                    padding-right: 2rem;
                    font-size: $f-size-small;
                    font-weight: 500;
                    display: flex;
                    align-items: center;

                    text-transform: capitalize;

                    &:before
                    {
                        content: '\27A4';
                        margin-right: 1.5rem;
                        font-size: rem(8px);
                        display: block;
                        transition: $t-fast transform ease-in-out;
                    }

                    &:hover
                    {
                        background-color: rgba(black, 0.05);
                        text-decoration: none;

                        &:before
                        {
                            transform: translateX(0.5rem);
                        }
                    }
                }
            }
        }
    }

    .ham
    {
        display: flex;
        align-items: center;
        height: 50px;

        div
        {
            position: relative;
        }

        div,
        div:before,
        div:after
        {
            height: 1px;
            background-color: $c-fama;

            .famaliving &
            {
                background-color: $c-famaliving;
            }

            position: relative;
            width: 25px;
            content: '';
            transition: all 0.3s ease-in-out;
        }

        div:before,
        div:after
        {
            position: absolute;
        }

        div:before{
            top: -6px;
        }
        div:after{
            bottom: -6px;
        }

        &:hover
        {
            div,
            div:before,
            div:after
            {
                background-color: $gray-dark;
            }
        }
    }

    .hide-menu
    {
        position: absolute;
        left: 38px;
        opacity: 0;
        z-index: -1;
        transition: 0.4s ease-in-out all;
        font-size: rem(9px);
        text-transform: uppercase;
        width: 50px;
        line-height: 1.5;
        color: $gray-dark;
    }

    .if-open-hidden
    {
        opacity: 1;
        transition: 0.4s ease-in-out all;
        h1{
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            margin: 0;
        }
    }

    transition: transform 0.3s ease-in-out;
    transform: translateX(0);

    &.is-open
    {
        transform: translateX(264px);

        .ham
        {
            div
            {
                &:before
                {
                    transform-origin: left center;
                    transform: rotate(-40deg) scaleX(0.5);
                    top: 0;
                }
                &:after
                {
                    transform-origin: left center;
                    transform: rotate(40deg) scaleX(0.5);
                    bottom: 0;
                }
                &,
                &:before,
                &:after
                {
                    background-color: $gray-dark;
                }
            }
        }
        .hide-menu
        {
            opacity: 1;
            z-index: 1;
            transition: 0.4s 0.8s ease-in-out all;
            cursor: pointer;
        }

        .if-open-hidden
        {
            opacity: 0;
        }
    }
    @include media-breakpoint-down(sm)
    {
         .row
        {
            margin: 0 -15px!important;
        }
    }
}
