.muro.muro_2021{
    .row.principal.principal_2021{
        padding-top: 10.2rem;
        display: block !important;
        .framebox{
            font-family: "Montserrat-Regular";
            line-height: 2.3rem !important;
            font-size: 1.5rem !important;
            h4{
                font-size: 1.5rem;
                line-height: 2.3rem;
                font-family: 'Montserrat-SemiBold';
            }
            tr{
                border-bottom: none;
            }
            td{
                background-image: none;
            }
            td > span {
                text-align: left;
                font-weight: normal;
                font-size: 1.5rem;
                line-height: 2.3rem;
                text-transform: none;
                color: #324b4e;
                margin-left: 0.2rem;
                strong{
                    font-family: 'Montserrat-Bold';
                }
            }
        }            
    }
    .pie{
        .pie-form{
            padding-bottom: 30rem;
        }
        .pie-form #myForm{
            .form-control {
                display: block;
                width: 100%;            
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1.2;
                color: #495057;               
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }
          
            .btn-add-photo{
                width: 3.4rem;
                height: 3.4rem;
                margin-top: 2rem;
                margin-left: 1.5rem;
            }
            .form-control{
                min-height: 3.4rem;
            }
            .btn-submit{
                min-height: 3.4rem;
            }
            textarea.form-control{
                min-height: 18rem;
            }
        }
        .pie-title{
            line-height: 1.2;
        }
        .pie-wrapp{
            max-width: 80rem;
            width: 100%;
            position: absolute;
            top: unset;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 1;
            bottom: 30rem;
        }
        .pie-deco{
            img{
                //padding-top: 35rem;
            }
        }
    }
//*************************************************************************movil*************************************************************************************//
    @media (max-width: $md-width) {
        .row.principal.principal_2021{
            padding-top: 7rem;           
        }
        .pie{
            .pie-wrapp{
                bottom: 26rem;
                .pie-form{
                    padding-bottom: 3rem;
                }
            }
        }
    }
    @media (min-width: 992px) and (max-width: 1199px){
        .pie{
            .pie-wrapp{
                bottom: 30rem;
                .pie-form{
                    padding-bottom: 3rem;
                }
            }
        }
    }
    @media screen and (max-width: 767px){
        .pie{
            padding-top: 0;
            padding-left: 15px;
            padding-right: 15px;

            .pie-wrapp{
                position: relative;
                top: auto;
                left: auto;
                max-width: 90%;
                margin: 0 auto 20px;
                padding: 0;
                bottom: 0;
            }
        }
    }
}



