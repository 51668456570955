// Fonts
//@import url('https://fonts.googleapis.com/css?family=Arvo:400,700|Montserrat:300,400,500,600,700&display=swap');
@import '../mixins/_units.scss';
// Variables
@import '../variables';

// Bootstrap & vendors
@import "../vendor";
@import "local-vendor";

// CORE FAMA
@import 'core/reset';
@import 'core/fonts';
@import 'core/mixings';
@import 'core/schema';
@import 'core/vars';
@import 'core/partials';

// components
@import "components/stars";
@import "components/topbar";
@import "components/videoDetail";
@import "components/videoList";
@import "components/productDetail_2021";
@import "components/loves";
@import "components/sidebar_2021";
@import "components/modalFinder_2021";
@import "components/module_2021";
@import "components/breadcrumb_2021";
@import "components/downloadImages_2021";
@import "components/listFancy_2021";
@import "components/hashtags_2021";
@import "components/sidebarCategories_2021";
@import "components/famalivingCatalogue_2021";

@import "valoraciones";

// GENERIC FAMA
@import 'generic/header';
@import 'generic/loader';
@import 'generic/default';
@import 'generic/common';
@import 'generic/buttons';
@import 'generic/footer';
@import 'generic/that';
@import 'generic/form';
@import 'generic/loginShops';

// MODULES FAMA 

// parts of home
@import 'modules/home';
@import 'modules/homeLayoutsCollage';
@import 'modules/homeSectionConcurso';
@import 'modules/homeSectionNonStop';
@import 'modules/homeSectionNews';
@import 'modules/homeSectionVideos';
@import 'modules/homeSectionGaranty';
@import 'modules/homeSectionEmpresa';
@import 'modules/homeSectionSimulator';
@import 'modules/homeSectionBlog';
@import 'modules/homeSectionInstagram';
@import 'modules/homeSectionLogos';

// parts of empresa
@import 'modules/empresaGeneric.scss';
@import 'modules/empresaSofas.scss';
@import 'modules/empresaSofasQuality.scss';
@import 'modules/empresaSofasDesign.scss';
@import 'modules/empresaSofasInternational.scss';
@import 'modules/empresaSofasIndustry.scss';
@import 'modules/empresaSofasMadeInSpain.scss';
@import 'modules/empresaSofasPhilosophy.scss';
@import 'modules/empresaSofasIgualidad.scss';
@import 'modules/empresaSofasEnviroment.scss';
@import 'modules/empresaSofasHumanTeam.scss';
@import 'modules/empresaSofasRsc.scss';
@import 'modules/empresaSofasRscVerticalBlocks.scss';
@import 'modules/empresaSofasRscCollage.scss';
@import 'modules/empresaSofasHistory.scss';
@import 'modules/empresaSofasHistoryTimeline.scss';
@import 'modules/empresaMedioambiente.scss';

// other parts
@import 'modules/map-shops.scss';
@import 'modules/pagesCorporates.scss';
@import 'modules/ourBrands.scss';
@import 'modules/famalovers.scss';
@import 'modules/instructions.scss';
@import 'modules/simulations.scss';
@import 'modules/contact.scss';
@import 'modules/warranties.scss';
@import 'modules/workWithUs.scss';
@import 'modules/curriculum_faqs.scss';
@import 'modules/contests_competitions.scss';
@import 'modules/contests_competitionsDetail.scss';
@import 'modules/contests_competitionsStories.scss';
@import 'modules/muro_2021.scss';
@import 'modules/showroom.scss';
@import 'modules/news';
@import 'modules/blog';
@import 'modules/blog_author';

/*
@font-face {
    font-family: 'slick';
    src: url('cssiw5/fonts/vendor/slick-carousel/slick/slick.woff');
}
*/

body{
    a, li, h1, h2, h3, h4, h5    {
        color: color(color-dark);
    }

    h1, h2, h3, h4, h5    {
        font-family: 'Montserrat-Bold';
    }

    a:hover{
        text-decoration: none;
    }
    overflow-x: hidden;

    &.is-open-modal    {
        overflow-y: hidden;
    }

    a, button {
        outline: none;
    }

    @include media-breakpoint-down(sm)    {
        font-size: $f-size-small;
        overflow-x: hidden;
        p {
            line-height: 1.54;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    * {
        -webkit-font-smoothing: antialiased;
    }

    p {
        padding: 0;
        margin: 0;
    }
}

.slick-slider, iframe {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

iframe {
    border: 0;
    background-color: #fff;
}

.bg-gray
{
    background-color: #f3f6f7;
}

.bg-orange
{
    background-color: $orange!important;
}

.c-fama{
    color: $colorCorporative;
}

body.famaliving .c-fama {
    color: $colorCorporative_famaliving;
}

.c-orange{
    color: $orange;
}

.c-blue{
    color: $blue;
}

.autoimg img{
    width: 100%;
    height: auto;
}

.button-dark{
    display: block;
    color: white;
    background-color: $gray-dark;
    padding: 13px 43px;
    transition: opacity $t-fast ease-in;
    text-transform: uppercase;
    text-align: center;
    border: none;
    font-size: 2rem;
    font-weight: bold;
    &:hover
    {
        opacity: 0.89;
        color: white;
    }
    @include media-breakpoint-down(md)
    {
        font-size: 1.5rem;
    }
}

.row {
    margin: 0 !important;
    flex-wrap: nowrap;
    @include media-breakpoint-down(sm)
    {
        flex-wrap: wrap;
    }
}

.container {
    max-width: unset !important;
}

.special-container{
    > .row
    {
        @include media-breakpoint-up(md){
            margin: 0;

            & >div:first-child
            {
                padding-right: 0!important;
            }
        }
    }
    @media screen and (min-width: 1281px)
    {
        .module .module-wrap
        {
            max-width: 859px;
        }
    }
}

.btn-control{
    z-index: 1;

    & .icon    {
        position: relative;
        display: inline-block;
    }
    & .icon:after {
        transition: transform $t-fast ease-in-out;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transform-origin: center;
        transform: scale(0);
        border-radius: 50%;
        display: block;
        background-color: rgba(white, 0.6);
        z-index: -1;
    }

    &:hover .icon:after
    {
        transform: scale(1);
    }
}

.client-photos-modal {
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    opacity: 0;
    visibility: hidden;
    transition: opacity $t-fast linear 0s, visibility 0s linear $t-fast;
    &.isVisible {
        opacity: 1;
        visibility: visible;
        transition: opacity $t-fast linear 0s, visibility 0s linear 0s;
    }
    .btn-control {
        position: absolute;
        top: rem(25px);
        right: rem(25px);
        cursor: pointer;
        &.prev {
            top: 50%;
            right: unset;
            left: rem(25px);
            transform: translateY(-50%);
        }
        &.next {
            top: 50%;
            right: rem(25px);
            transform: translateY(-50%);
        }
    }
    .slick-slider {
        position: absolute;
        width: calc(100% - ((46px  + 5rem) * 2));
        height: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .slick-list, .slick-track, .slick-slide, .slick-slide > div, .item {
            height: 100%;
        }
        .item {
            position: relative;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

.app-detail_2021 .client-photos-modal {
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    opacity: 0;
    visibility: hidden;
    transition: opacity $t-fast linear 0s, visibility 0s linear $t-fast;
    &.isVisible {
        opacity: 1;
        visibility: visible;
        transition: opacity $t-fast linear 0s, visibility 0s linear 0s;
    }
    .btn-control {
        position: absolute;
        top: rem(25px);
        right: rem(25px);
        cursor: pointer;
        &.prev {
            top: 50%;
            right: unset;
            left: rem(25px);
            transform: translateY(-50%);
        }
        &.next {
            top: 50%;
            right: rem(25px);
            transform: translateY(-50%);
        }
    }
    .slick-slider {
        position: absolute;
        width: calc(100% - ((46px  + #{rem(50px)}) * 2));
        height: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .slick-list, .slick-track, .slick-slide, .slick-slide > div, .item {
            height: 100%;
        }
        .item {
            position: relative;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

.slider2{
    width: 100%;
    height: 464px;
    margin-bottom: 4px;

    .item {
        width: 100%;
        height: 464px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

}
.slider2-thumb{
    width: 100%;
    height: 96px;

    .item {

        height: 96px;
        overflow: hidden;
        padding: 0 2px;

        img {
            width: 100%;
            height: 96px;
            object-fit: cover;
        }
    }
}

.slider3{
    height:285px;

    .item{
        width: 45%;
    }

    img {
        max-width: 100%;
        min-height:285px;
        padding: 0 7px;
        object-fit: cover;
        height: 285px;
    }
}

.deco-line{
    @include media-breakpoint-up(md){
        &-both,
        &-right,
        &-left
        {
            display: flex;
            align-items: center;

            &:before,
            &:after
            {
                height: 1px;
                border: 1px solid rgba($c-gray2, 0.25);
                flex: 1;
            }
        }
        &-both:before,
        &-left:before
        {
            content: '';
            display: block;
            margin-right: 21px;
        }

        &-both:after,
        &-right:after
        {
            content: '';
            display: block;
            margin-left: 21px;
        }
    }
}

.app {
    max-width: $special-container-max-width;
    margin: 0 auto;
    padding: 0 $lateral-space;
    padding-top: $topbar-height;
    &.app-detail_2021.hideControls {
        .topbar {
            transform: translateY(calc(-100% - 3.7rem)) scaleY(-1);
        }
        .control-fullscreen,
        .control-readmore {
            opacity: 0;
            transition: opacity $t-fast linear;
        }
        .header-box {
            transform: translateY(100%);
            transition: transform $t-fast $easeOutCubic 1s;
        }
        .header-detail .slick-dots {
            &:before {
                width: 0;
                transition: width $t-normal $easeInCubic 1s;
            }
            li {
                transform: translateX(100vw);
                transition: transform $t-normal $easeInCubic 0.8s;
                $delay: 0.05s;
                @for $i from 1 through 15 {
                    &:nth-last-child(#{$i}) {
                        transition: transform $t-normal $easeInCubic calc((#{$i} * #{$delay}));
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        padding: 0 $lateral-space-sm;
    }
    @media (pointer: coarse) {
        overflow-x: hidden;
    }
}
.app-list{
    padding-top: $topbar-height;

}

.app-list.app-list_2021{
    padding-top: 10.2rem;
    max-width: 1480px;
    padding-left: 5rem;
    padding-right: 5rem;

    @media (max-width: $md-width) {
        padding-top: 7.4rem !important;
        max-width: 100%;
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
}

.isHidden{
    display: none;
}

@include media-breakpoint-down(sm) {
    .app-detail_2021 .client-photos-modal .slider2-fullscreen.slick-slider{
        width: 100%;
        height: 100%;
    }
}


