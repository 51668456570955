.section-empresa.sofas.sofas-history{
    &.section-history-top{
        padding-bottom: 12.7rem;
        .wrap-content{
            .wrap-up{
                padding-bottom: 12rem;                
                .wrap-title{
                    width: 100%;
                    .subtitle{
                        width: 65%;
                    }
                }
                .part-left{
                    padding-right: 2.5rem;
                }
                .part-right{
                    padding-left: 2.5rem;
                    .wrap-img{
                        position: relative;
                        padding-top: 65%;
                        .img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }
            .wrap-center{
                .part-left{
                    padding-right: 2.5rem;
                    .wrap-img{
                        .img{
                            width: 95%;
                            object-fit: contain;
                        }
                    }
                }
                .part-right{
                    padding-left: 2.5rem;
                }
            }
        }
    }
    &.section-history-slider-logos{
        .wrap-content{
            .wrap-title{
                width: 30%;
                display: flex;
                position: relative;
                .title{
                    font-size: 3.5rem;
                    line-height: 4.5rem;
                    position: relative;
                    padding-right: 0;
                    z-index: 2;
                   
                }
                .gradient{
                    z-index: 1;
                    order: 2;
                    position: absolute;
                    background: linear-gradient(90deg, #e1f4f7, #e1f4f7 70%, rgba(255, 255, 255, 0) 95%, rgba(255, 255, 255, 0) 0);
                    background: -moz-linear-gradient(90deg, #e1f4f7, #e1f4f7 70%, rgba(255, 255, 255, 0) 95%, transrgba(255, 255, 255, 0) 0); /* FF3.6+ */
                    background: -webkit-gradient(linear, right, color-stop(0%,#e1f4f7), color-stop(70%,#e1f4f7), color-stop(95%,rgba(255, 255, 255, 0)), color-stop(100%,rgba(255, 255, 255, 0))); /* Chrome,Safari4+ */
                    background: -webkit-linear-gradient(90deg, #e1f4f7, #e1f4f7 70%, rgba(255, 255, 255, 0) 95%, rgba(255, 255, 255, 0) 0); /* Chrome10+,Safari5.1+ */
                    background: -o-linear-gradient(90deg, #e1f4f7, #e1f4f7 70%, rgba(255, 255, 255, 0) 95%, rgba(255, 255, 255, 0) 0); /* Opera 11.10+ */
                    background: -ms-linear-gradient(90deg, #e1f4f7, #e1f4f7 70%, rgba(255, 255, 255, 0) 95%, rgba(255, 255, 255, 0) 0); /* IE10+ */
                    width: 30%;
                    right: -6rem;
                    height: 100%;
                    bottom: 0;
                    
                }
            }
            .box-logos{
                width: 70%;
                
            }
            .wrap-logos{
                overflow: hidden;
                width: calc(100% + 5rem);
                padding-left: 5rem;
                .slider-logos{       
                    justify-content: space-between;

                    .item-logo{                       
                        width: auto;
                        height: auto;
                        margin-right: 6rem;  
                        padding-bottom: 5rem;
                        position: relative;
                        display: inline-block;            
                        
                        .img{
                            position: relative;
                            height: 7.7rem;
                            width: auto;
                            object-fit: contain;
                        }                
                    }
                      .year{
                        left: 50%;
                        transform: translateX(-50%);                
                        position: absolute;
                        font-size: 1.7rem;
                        text-align: center;
                        font-family: "Montserrat-Bold";
                        color: #575252;
                        letter-spacing: 5px;
                        bottom: 0;
                         
                    }
                }
            }
            .action-box{
                position: relative;
                width: 100%;
                padding-left: 5rem;
                .info{
                    text-align: center;               
                    margin-top: 1.5rem;
                    font-size: 1.5rem;
                }
            
                .action{                   
                    position: relative;
                    width: 100%;              
                    height: 3rem;
                    margin-top: 6rem;
                
                    .action-line{
                        width: 100%;
                        top: 50%;
                        height: 2px;
                        position: relative;      
                        background: linear-gradient(to right, $colorCorporative 33%, rgba(255,255,255,0) 0%);
                        background-position: bottom;
                        background-size: 30px 2px;
                        background-repeat: repeat-x;
                    }   
                
                    .container-drag{
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        .handler{
                            z-index: 10;
                            border-radius: 18.5px;
                            width: 10rem;
                            height: 3rem;
                            background-color: $colorCorporative;
                            box-shadow: 0 2px 10px 0 rgb(17 151 165 / 45%);    
                        }
                    }
                   
                        
                }
            }
        }
    }
}

//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {
    .section-empresa.sofas.sofas-history{
        &.section-history-top{
            padding-bottom: 7rem;
            .wrap-content{
                .generic.text, .generic.text p, .generic, .generic.text *{
                    color: #000 ;
                    font-size: 1.7rem !important;
                    line-height: 2.5rem !important;
                    font-family: 'Montserrat-Regular' !important;
                } 
                .wrap-up{
                    padding-bottom: 3.5rem;     
                    flex-direction: column;          
                    .wrap-title{
                        width: 100%;
                        .subtitle{
                            width: 100%;
                        }
                    }
                    .part-left{
                        width: 100%;
                        padding-right: 0;
                        margin-bottom: 3.5rem;
                    }
                    .part-right{
                        width: 100%;
                        padding-left: 0;
                        .wrap-img{
                            position: relative;
                            padding-top: 65%;
                            .img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                }
                .wrap-center{
                    flex-direction: column;
                    .part-left{
                        width: 100%;
                        padding-right: 0;
                        order: 2;

                        .wrap-img{
                            .img{
                                width: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                    .part-right{
                        width: 100%;
                        padding-left: 0;
                        order: 1;
                        margin-bottom: 3.5rem;
                    }
                }
            }
        }

        &.section-history-slider-logos{
            .wrap-content{
                .wrap-title{
                    width: 100%;
                    .gradient{
                        display: none;                        
                    }
                }
                .box-logos{
                    width: 100%;
                    
                }
                .wrap-logos{
                    overflow: hidden;
                    padding-left: 0;
                    transform: translateX(-1.5rem);
                    width: calc(100% + 3rem);

                    .slider-logos{                
                        padding-left: 1.5rem;
                        justify-content: space-between;

                        .item-logo{                     
                            width: auto;
                            height: auto;
                            margin-right: 3rem;  
                            padding-bottom: 5rem;
                            position: relative;
                            display: inline-block;            
                            
                            .img{
                                position: relative;
                                height: 7.7rem;
                                width: auto;
                                object-fit: contain;
                            }                
                        }
                          .year{
                            left: 50%;
                            transform: translateX(-50%);                
                            position: absolute;
                            font-size: 1.7rem;
                            text-align: center;
                            font-family: "Montserrat-Bold";
                            color: #575252;
                            letter-spacing: 5px;
                            bottom: 0;
                             
                        }
                    }
                }
                .action-box{
                    position: relative;
                    width: 100%;
                    padding-left: 0;

                    .info{
                        display: none;
                    }
                
                    .action{                   
                        position: relative;
                        width: 100%;              
                        height: 3rem;
                        margin-top: 6rem;
                    
                        .action-line{
                            width: 100%;
                            top: 50%;
                            height: 2px;
                            position: relative;     
                            background: linear-gradient(to right, $colorCorporative 33%, rgba(255,255,255,0) 0%);
                            background-position: bottom;
                            background-size: 21px 2px;
                            background-repeat: repeat-x;
                        }   
                    
                        .container-drag{
                            width: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            .handler{
                                z-index: 10;
                                border-radius: 18.5px;
                                width: 10rem;
                                height: 3rem;
                                background-color: $colorCorporative;
                                box-shadow: 0 2px 10px 0 rgb(17 151 165 / 45%);    
                            }
                        }  
                    }
                }
            }
        }
    }
}