#contact_2021 {
    padding: 0;
    padding-top: 15.9rem;

    .limited-wrap {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
    }

    .section-title {
        color: $colorCorporative;
        letter-spacing: 0;
        .title {
            font-size: 3.5rem;
            text-transform: none;
            font-family: "Montserrat-SemiBold";
            font-weight: normal;
            line-height: 4.3rem;
            text-align: left;
        }
        .title-underline {
            width: 3.5rem;
            border-bottom: 4px solid;
            background: $colorCorporative;
            margin-top: 0.5rem;
            margin-bottom: 4rem;
        }
        &.mvl {
            display: none;
        }
    }

    .special-contact {
        padding: 0;

        .part-up {
            .contact-info {
                background: none;
                padding: 0;
                .part-left {                  
                    width: 48%;
                    margin-right: 5%;
                }
                .part-right {                  
                    width: 50%;
                    padding-right: 9rem;                  
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    .section-title {
                        width: 100%;
                    }
                    .factoria {
                        width: 50%;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                    }
                    .wrap-btnDefault {
                        margin-top: 4rem;
                        min-width: unset;
                        align-self: flex-start;
                        width: fit-content !important;

                        .btnDefault {
                            line-height: 1.7rem;
                            font-size: 1.5rem;
                            min-width: unset;
                        }
                    }
                    .wrap-address {                      
                        width: 85%;
                        max-width: 22rem;
                        font-size: 1.7rem;
                        line-height: 2.3rem;
                        font-family: "Montserrat-Regular";
                        flex-grow: 1;

                        .title {
                            font-family: "Montserrat-SemiBold";
                            padding-bottom: 1.5rem;
                        }
                        .address {
                            padding-bottom: 3rem;
                        }
                    }
                    .info-contact {
                        font-size: 1.7rem;
                        line-height: 2.3rem;
                        font-family: "Montserrat-Regular";
                        a {
                            color: #000;
                            transition: 0.2s color ease;
                            &:hover {
                                color: $colorCorporative;
                                transition: 0.2s color ease;
                            }
                        }
                    }
                }
                .part-left {
                    .wrap-img {
                        .img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            min-height: 41.7rem;
                        }
                    }
                }
            }
        }
        .part-down {
            padding: 0 9rem;           
            margin-top: -2.5rem;

            .section-title {
                margin-top: -4rem;
                padding-bottom: 0.5rem;
            }
            .wrap-content {
                background-color: #fff;
                padding: 6.5rem;                
                padding-top: 11.5rem;
                margin-bottom: 8rem;
            }
            .form {
                width: calc(100% + 3.5rem);

                .input-box {
                    padding-right: 3.5rem;
                    margin-bottom: 3rem;
                }
                .wrap-privacy {
                    width: calc(100% - 3.5rem);
                    align-items: center;
                }

                .input-box input + .label,
                .input-box textarea + .label,
                .select2 + .label {
                    position: absolute;
                    top: 0;
                    font-size: 1.3rem;
                    left: 1.7rem;
                    color: #575252;
                    background-color: #fff;
                    padding: 0 1.1rem;
                    pointer-events: none;
                    transform: translateY(-50%);
                    transform-origin: left;
                    white-space: nowrap;
                    margin: 0;
                    transition: top 0.2s linear, left 0.2s linear,
                        transform 0.2s linear;
                }
                .form-group {
                    position: relative;
                }
                .privacy {
                    width: 60%;

                    .textContainer {
                        width: calc(100% - 3.2rem);
                        font-family: "Montserrat-Regular";
                        font-weight: normal;
                        display: block;

                        .link {
                            color: $colorCorporative;
                            text-decoration: none;                           
                            --border-color: #1197a5;
                            --border-width: 1px;
                            --bottom-distance: 0px;
                            display: inline;
                            background-image: linear-gradient(
                                var(--border-color),
                                var(--border-color)
                            );
                            background-size: 0 var(--border-width);
                            background-repeat: no-repeat;
                            background-position-y: bottom;
                            transition: background-size 0.3s;

                            &:hover {
                                background-size: 100% var(--border-width);
                            }
                        }
                    }
                    input + .labelContainer{
                        padding-top: 0;
                    }
                    /*
                    input + .labelContainer:after {
                        content: "";
                        position: absolute;
                        z-index: 1;
                        min-width: 1.6rem;
                        height: 0.8rem;
                        top: 2.3rem;
                        left: 0.3rem;
                        border: 0.2rem solid $colorCorporative;
                        border-top: 0;
                        border-right: 0;
                        transform: rotate(-45deg) scale(0);
                        transform-origin: left top;
                        transition: all 0.2s ease 0s;
                    }
                    input + .labelContainer:before {
                        content: "";
                        position: relative;
                        display: inline-block;
                        vertical-align: middle;
                        min-width: 2.2rem;
                        height: 2.2rem;
                        top: 0;
                        border: 0.1rem solid #575252;
                        border-radius: 0 !important;
                        background-color: transparent;
                        transition: background-color 0.2s linear 0.1s;
                    }
                    input[type="checkbox"]:checked + .labelContainer:before {
                        background-color: transparent;
                        transition: background-color 0.2s linear 0s;
                    }
                    */
                    input[type="checkbox"]:checked + .labelContainer:after {
                        transform: translateY(calc(-50% + .3rem)) rotate(-45deg) scale(1);
                        transition: all 0.2s ease 0.1s;
                    }
                    
                }
                .wrap-btnDefault {
                    min-width: unset;
                    .btnDefault {
                        font-size: 1.5rem;
                        line-height: 1.7rem;
                        padding: 1.4rem 6.6rem;
                    }
                }
            }
        }

        .wrap-departments {
            .departments {
                width: 100%;
                margin: 0 auto;                
                position: relative;

                .part-left,
                .part-right {
                    width: 50%;

                    .section-title {
                        color: #fff;

                        .title-underline {
                            width: 3.5rem;
                            border-bottom: 3px solid;
                            background: #fff;
                            margin-top: 2.5rem;
                            margin-bottom: 0;
                        }
                    }
                }
                .part-left {
                    background-color: $colorCorporative;                  
                    display: flex;                  
                    height: 54rem;
                    align-items: center;
                    justify-content: center;
                    position: sticky;                    
                    top: calc(100vh - 54rem);
                }
                .part-right {
                    padding: 6rem;
                    max-width: calc(50% - ((100% - 1280px) / 2));
                    max-height: 54rem;
                    overflow-y: auto;
                    position: relative;
                    &:after {
                        content: "";
                        width: 0.4rem;
                        height: 5rem;
                        background-color: $colorCorporative;
                        animation: MoveUpDown 2.5s linear infinite;
                        position: absolute;
                        right: 5rem;
                    }
    
                    @keyframes MoveUpDown {
                        0%,
                        100% {
                            top: 0;
                        }
                        50% {
                            top: 100px;
                        }
                    }
                    
                    &::-webkit-scrollbar {
                        -webkit-appearance: none;
                    }                   
                    &::-webkit-scrollbar-button:increment,
                    .contenedor::-webkit-scrollbar-button {
                        display: none;
                    }                   
                    &::-webkit-scrollbar-thumb {                        
                        border-radius: none !important;
                        border: none;
                    }
                    .department {
                        border-top: 1px solid $colorCorporative;

                        .nav.nav-department {
                            flex-direction: column;
                        }

                        &:last-of-type {
                            border-bottom: 1px solid $colorCorporative;
                        }
                        .item {
                            display: flex;
                            padding: 25px;
                            padding-left: 0;
                            cursor: pointer;

                            .num {
                                color: $colorCorporative;
                                font-size: 1.3rem;
                                font-family: "Montserrat-Regular";
                                height: 100%;
                            }
                            .name {
                                font-size: 2.5rem;
                                font-family: "Montserrat-SemiBold";
                                color: $colorCorporative;
                                padding-left: 1rem;
                                margin: 0;
                            }
                        }
                        .nav.nav-members {
                            flex-direction: column;
                            padding-bottom: 2rem;
                            padding-left: 28px;

                            .department-item {
                                height: 3rem;
                                line-height: 3rem;
                                font-size: 1.5rem;

                                a{
                                    color: $colorCorporative;
                                }

                                &:nth-child(2n) {
                                    background-color: transparent;
                                    transition: background-color 300ms ease-in;
                                }
                                &:nth-child(2n):hover {
                                    background-color: rgba(0, 165, 184, 0.1);
                                    transition: background-color 300ms ease-in;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $md-width) {
    #contact_2021 {
        padding: 0;
        padding-top: 9.2rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;

        .limited-wrap {
            max-width: 100%;
        }
        .section-title {
            color: $colorCorporative;
            letter-spacing: 0;
            .title {
                font-size: 2.5rem;
                line-height: 3.8rem;
            }
            .title-underline {
                width: 3.5rem;
                border-bottom: 2px solid;
                background: $colorCorporative;
                margin-top: 0.5rem;
                margin-bottom: 4rem;
            }
            &.mvl {
                display: block;
            }
            &.desc {
                display: none;
            }
        }

        .special-contact {
            padding: 0;

            .part-up {
                .contact-info {
                    flex-direction: column;
                    .part-left {
                        width: 100%;
                        margin-right: 0;

                        .wrap-img {
                            width: 100%;
                            transform: translateX(-1.5rem);
                            margin-bottom: 3rem;
                            .img {
                                min-height: unset;
                            }
                        }
                    }
                    .part-right {
                        width: 100%;
                        padding-right: 0;
                        flex-direction: column;
                        display: flex;
                        max-height: unset;
                        overflow-y: visible;
                        flex-direction: row;

                        .wrap-btnDefault {
                            margin-top: 4rem;
                            min-width: unset;
                            align-self: flex-start;

                            .btnDefault {
                                line-height: 1.7rem;
                                font-size: 1.5rem;
                                min-width: unset;
                            }
                        }
                        .factoria {
                            width: 100%;
                            margin-top: 3rem;
                            .wrap-address {
                                width: 95%;
                                max-width: unset;
                                font-size: 1.7rem;
                                line-height: 2.3rem;
                                font-family: "Montserrat-Regular";

                                .title {
                                    font-family: "Montserrat-SemiBold";
                                    padding-bottom: 1.5rem;
                                }
                                .address {
                                    padding-bottom: 3rem;
                                }
                            }
                            .info-contact {
                                font-size: 1.7rem;
                                line-height: 2.3rem;
                                font-family: "Montserrat-Regular";
                            }
                        }
                    }
                }
            }
            .part-down {
                padding: 0;
                margin-top: 3rem;
                .section-title {
                    margin-top: 0;
                    padding-bottom: 0.5rem;
                }
                .wrap-content {
                    padding: 1.5rem;
                    margin-bottom: 7.8rem;
                    padding-bottom: 8.4rem;
                }
                .form {
                    width: 100%;
                    display: block !important;
                    overflow: visible;

                    .input-box {
                        padding-right: 0;
                        margin-bottom: 2.4rem;
                        input {
                            height: 4.5rem;
                        }
                        .label {
                            left: 1rem !important;
                        }
                    }
                    .wrap-privacy {
                        width: 100%;
                    }

                    .input-box input + .label,
                    .input-box textarea + .label,
                    .select2 + .label {
                        position: absolute;
                        top: 0;
                        font-size: 1.3rem;
                        left: 1.7rem;
                        color: #575252;
                        background-color: #fff;
                        padding: 0 1.1rem;
                        pointer-events: none;
                        transform: translateY(-50%);
                        transform-origin: left;
                        white-space: nowrap;
                        margin: 0;
                        transition: top 0.2s linear, left 0.2s linear,
                            transform 0.2s linear;
                    }
                    .form-group {
                        position: relative;
                    }
                    .privacy {
                        width: 100%;
                        margin-top: 0;
                        
                        .acept {
                            position: absolute;
                        }
                        
                        .textContainer {
                            font-family: "Montserrat-Regular";
                            font-weight: normal;
                            display: block;

                            .link {
                                color: $colorCorporative;
                                text-decoration: none;                               
                            }
                        }
                      
                        /*
                        input + .labelContainer:after {
                            content: "";
                            position: absolute;
                            z-index: 1;
                            min-width: 0.8rem;
                            height: 0.4rem;
                            top: 2rem;
                            left: 0.2rem;
                            border: 0.2rem solid $colorCorporative;
                            border-top: 0;
                            border-right: 0;
                            transform: rotate(-45deg) scale(0);
                            transform-origin: left top;
                            transition: all 0.2s ease 0s;
                        }
                        input + .labelContainer:before {
                            content: "";
                            position: relative;
                            display: inline-block;
                            vertical-align: middle;
                            min-width: 1.2rem;
                            height: 1.2rem;
                            top: 0;
                            border: 0.1rem solid #575252;
                            border-radius: 0 !important;
                            background-color: transparent;
                            transition: background-color 0.2s linear 0.1s;
                            margin-top: 0.3rem;
                        }
                        input[type="checkbox"]:checked
                            + .labelContainer:before {
                            background-color: transparent;
                            transition: background-color 0.2s linear 0s;
                        }
                        input[type="checkbox"]:checked + .labelContainer:after {
                            transform: translateY(-20%) rotate(-45deg) scale(1);
                            transition: all 0.2s ease 0.1s;
                        }
                        */
                    }
                    .wrap-btnDefault {
                        min-width: unset;
                        margin: 0 auto;
                        margin-right: 0;

                        .btnDefault {
                            font-size: 1.5rem;
                            line-height: 1.7rem;
                            padding: 1.5rem 4.3rem;
                        }
                    }
                }
            }

            .wrap-departments {
                .departments {
                    width: 100%;
                    margin: 0 auto;
                    margin-bottom: 9.5rem;
                    flex-direction: column;
                    &:after {
                        content: none;
                    }

                    .part-left,
                    .part-right {
                        width: 100%;
                        max-width: 100%;

                        .section-title {
                            color: #fff;

                            .title-underline {
                                border-bottom: 2px solid;
                                margin-top: 1.5rem;
                            }
                        }
                    }
                    .part-left {
                        width: calc(100% + 3rem);
                        max-width: calc(100% + 3rem);
                        background-color: $colorCorporative;
                        transform: translateX(-1.5rem);
                        padding: 3.5rem;
                        position: relative;
                        top: 0;
                        height: 25rem;
                    }
                    .part-right {
                        padding: 0;
                        padding-top: 3.7rem;
                        max-height: unset;

                        .department {
                            border-top: 1px solid $colorCorporative;

                            &:last-of-type {
                                border-bottom: 1px solid $colorCorporative;
                            }
                            .item {
                                display: flex;
                                padding: 1.5rem;
                                padding-left: 0;

                                .num {
                                    font-size: 1.04rem;
                                }
                                .name {
                                    font-size: 1.9rem;
                                }
                            }
                        }
                        &:after{
                            content: none;
                        }
                    }
                }
            }
        }
    }
}
