.section-empresa.sofas.sofas-madeInSpain{
    &.section-two-columns-video{
        .wrap-content{
            .wrap-up{
                justify-content: space-between;
            }
            .wrap-title{
                width: 40%;
                .title{
                    padding-right: 0;
                }
                .subtitle{
                    width: 100%;
                }
            }
            .wrap-video{
                width: calc(55% + 5rem);
                padding-left: 5.5rem;
                margin-bottom: 4.5rem;
                position: relative;
                .videos-section-item{
                    position: relative;
                    width: calc(100% + 5rem);
                    height: auto;
                   
                    .video-wrapper{
                        .video-container{
                            position: absolute;
                            img{
                                position: relative;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                                         
                            }
                            iframe{
                                position: absolute;
                                width: 100%;
                                top: 0;
                                left: 0;
                                height: 100%;
                            }
                        }
                    }
                    .playPause.js-play:not(.isPlayd){
                        &::after{
                            content: '';
                            z-index: 1;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background-color: rgba(0,0,0,.5);                          
                        }
                    }
                    .playPause{ 
                        width: 100%;
                        height: 100%;                     
                        &::before{   
                            content: "";
                            z-index: 2;
                            width: 6.8rem;
                            height: 6.8rem;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                            opacity: 0.7;
                            top: 50%;
                            left: 50%;
                            transform: translateY(-50%) translateX(-50%) scale(1);                      
                            transition: 0.4s transform ease-in;
                        
                        }   
                        &:hover{
                            &::before{
                                transform: translateY(-50%) translateX(-50%) scale(1.2); 
                                transition: 0.4s transform ease-in;
                            }                      
                        }
                    
                    }
         
                }
            }
            .part-right{
                padding-left: 2.5rem;
            }
            .part-left{
                padding-right: 2.5rem;
            }
        }

    }
    .section-slider{
        .slider-madeInSpain{
            width: calc(100% + 5rem);
            margin-bottom: 2.9rem;
            margin-left: 0;                

            .slick-list{
                padding-left: 0 !important;
                padding-right: calc(100% - 34.6rem) !important;
                cursor: grab;                                
                    &.dragging, &.draggable:active:hover {
                        cursor: grabbing;
                    }
            }
            .slick-dots{
                position: relative;
                width: 9.5rem;
                li{
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    height: calc(2.5rem * 1.5);
                    align-items: center;
                    opacity: 0;
                    width: 100%;       
                    margin: 0;             
                    transition: .2s opacity ease;
                    
                    &.slick-active{
                        opacity: 1;
                        transition: .2s opacity ease;
                    }
                    .dot{
                        font-family: 'Montserrat-Bold';
                        font-size: 2.1rem;
                        line-height: 3rem;
                        letter-spacing: 1.85px;
                        color: $colorCorporative;
                    }
                }
            }
            .slick-slide{
                width: 34.6rem;
                max-width: 34.6rem;
                .item{
                    position: relative;
                    padding-top: 60%;
                    margin-right: 2rem;
                }
                .img{
                    width: 100%;                    
                    top: 0;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    object-fit: cover;
                }
            }
        }
    }
    
    /**
    *  Progress Bar
    */
    .wrap-progress{
        width: 100%;
        //margin: 0 auto;       
        max-width: 1260px;
        position: relative;
        height: calc(2.1rem * 1.5);
        margin-top: 0;
        display: flex;   
        padding-left: 10rem;
        justify-content: flex-end;
    }
    .progress {
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 0;
        overflow: hidden;
        align-self: center ;
        
        background-color:rgba($colorCorporative, .5);
        background-image: linear-gradient(to right, $colorCorporative, $colorCorporative);
        background-repeat: no-repeat;
        background-size: 0 100%;   
    
        transition: background-size .4s ease-in-out;
    }
    /**
    *  END Progress Bar
    */
        
}
@media (min-width: 1690px) {
.section-empresa.sofas.sofas-madeInSpain{
    &.section-two-columns-video{
        .section-slider{           
            .slider-madeInSpain{              
                margin-bottom: 2.9rem;
                //margin-left: calc((100% - 1260px) / 2);           
                }
            }  
        }
    } 
}
//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {
    .section-empresa.sofas.sofas-madeInSpain{
        &.section-two-columns-video{
            .wrap-content{
                .wrap-up{
                    justify-content: unset;
                    flex-direction: column;
                }
                .wrap-center{
                    flex-direction: column;
                    .part-left, .part-right{
                        width: 100%;
                    }
                }
                .wrap-title{
                    width: 100%;
                    .title{
                        padding-right: 0;
                    }
                    .subtitle{
                        width: 100%;
                    }
                }
                .wrap-video{
                    width: calc(100% + 3rem);
                    transform: translateX(-1.5rem);
                    padding-left: 0;
                    margin-bottom: 4.5rem;
                    position: relative;
                    .videos-section-item{
                        position: relative;
                        width: 100%;
                        .video-wrapper{
                            .video-container{
                                position: absolute;
                                img{
                                    width: 100%;
                                    object-fit: contain;
                                }
                                iframe{
                                    position: absolute;
                                    width: 100%;
                                    top: 0;
                                    left: 0;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
                .part-right{
                    padding-left: 0;
                }
                .part-left{
                    padding-right: 0;
                }
                .text.generic{
                    font-size: 1.7rem;
                    line-height: 2.5rem;
                    letter-spacing: 0;
                    color: #000;
                }
            }
    
        }
        .section-slider{
            .slider-madeInSpain{
                width: calc(100% + 3rem);
                transform: translateX(-1.5rem);   
                margin-bottom: 2.9rem;
                margin-left: 0;                
    
                .slick-list{
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
                .slick-dots{
                    position: relative;
                    width: 9.5rem;
                    left: 1.5rem;
                    li{
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: flex;
                        height: calc(2.5rem * 1.5);
                        align-items: center;
                        opacity: 0;
                        width: 100%;       
                        margin: 0;             
                        transition: .2s opacity ease;
                        
                        &.slick-active{
                            opacity: 1;
                            transition: .2s opacity ease;
                        }
                        .dot{
                            font-family: 'Montserrat-Bold';
                            font-size: 2.1rem;
                            line-height: 3rem;
                            letter-spacing: 1.85px;
                            color: $colorCorporative;
                        }
                    }
                }
                .slick-slide{
                    width: 29.4rem;
                    max-width: 29.4rem;
                    .item{
                        position: relative;
                        padding-top: 70%;
                        margin-right: 0; 
                        margin-left: 1.5rem;
                    }
                    .img{
                        width: 100%;                    
                        top: 0;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        object-fit: cover;
                    }
                }
            }
        }
    /**
    *  Progress Bar
    */
    .wrap-progress{
        width: 100%;
        margin: 0 auto;       
        max-width: 100%;
        position: relative;
        height: calc(2.1rem * 1.5);
        margin-top: 0;
        display: flex;   
        padding-left: 10rem;
        justify-content: flex-end;
    }
    .progress {
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 0;
        overflow: hidden;
        align-self: center ;
        
        background-color:rgba($colorCorporative, .5);
        background-image: linear-gradient(to right, $colorCorporative, $colorCorporative);
        background-repeat: no-repeat;
        background-size: 0 100%;   
    
        transition: background-size .4s ease-in-out;
    }
    /**
    *  END Progress Bar
    */
    }
}