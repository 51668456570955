body {
    overflow-x: hidden;
}

.hidden {
    display: none !important;
}
// MODULO TITLE, DESCRIPTION valoraciones
.wrap-description-headerRatings{
    margin-left: -5rem;
    width: calc(60% + 5rem);
    padding-bottom: 5rem;
    padding: 4rem 0rem;

    .section-title{
        width: 100%;
        
        .title{
            font-size: 3.5rem;
            text-transform: none;
            font-family: "Montserrat-SemiBold";
            font-weight: normal;
            line-height: 4.3rem;
            text-align: left;
            color: $colorCorporative;
        }
        .title-underline{
            width: 3.5rem;
            border-bottom: 4px solid;
            background: $colorCorporative;
            margin-top: 0.5rem;
            margin-bottom: 4rem;
        }
    }
    
    .description{
        font-size: 1.7rem;
        line-height: 2.5rem;
        color: #000;
        font-family: 'Montserrat-Regular';
        font-weight: normal;
        text-align: left;
    }

    @media (max-width: $md-width) {   
        width: 100%;
        padding-bottom: 0 !important;
        padding: 4rem 0rem;
        margin-left: 0;
      
        .section-title{    
            .title{
                font-size: 2.5rem;          
                line-height: 3.3rem;            
            }
            .title-underline{              
                border-bottom: 2px solid;
                margin-bottom: 3.4rem;
            }
        }
        .description{
            font-size: 1.3rem;
            line-height: 2rem;       
        }    
    }
} 

.headerRatings {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 4.5rem 0rem;
    z-index: 1;

    &.headerRatings_2021{
        padding: 0rem 0rem;
        font-size: 1.5rem;
        margin-left: -5rem;

    

        & > section:first-of-type {         
            padding: calc(1.5rem * 1.5)  calc(11.25rem * 1.5);        
            background-color: #fff;   
        }
        &> section:first-of-type > button {
            font-size: calc(1.5 * 1.3rem);
            color: $colorCorporative;
            font-weight: 600;
            background-color: transparent;
            border: none;
            outline: none;
            margin: 0 calc(1.5 * 2rem);
            cursor: pointer;
            padding: 0;
        }
        .starBtns > button{
            background-color: transparent;
            border: none;
            padding: 0;
            width: calc(1.5 * 2rem);
            height: calc(1.5 * 2.1rem);
            margin: calc(1.5 * 0.2rem);
            cursor: pointer;
            background-image: url(/css/assets/star.svg);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .ratings {
            display: flex;
            margin-top: 4.5rem;
        }
        
        .ratings > div:first-of-type {
            width: 35%;
            display: flex;
            flex-wrap: wrap;
            border-right: 1px solid #0099a726;
            align-self: flex-start;
        }
        
        .hiddenSEO {
            position: absolute;
            opacity: 0;
            width: 0;
        }
        
        .logoAndRatings {
            display: flex;
            padding: 1rem 2rem  1rem 1rem;
            margin-bottom: 2rem;
            width: 100%;
        }
        
        .logoAndRatings > img {
            align-self: flex-start;
            width: 45%;
        }
        
        .logoAndRatings > div {
            margin-left: 10%;
            width: 45%;
        }
        
        .logoAndRatings > div > div {
            display: flex;
        }
        
        .logoAndRatings > div > div > img {
            width: 20%;
        }
        
        .logoAndRatings > div > p {
            margin-block-start: 0;
            margin-block-end: 0;
            font-size: 1.3rem;
            color: #324b4e;
            margin-left: 0.2rem;
        }
        
        .logoAndRatings > div > div {
            margin-bottom: 0.3rem;
            margin-top: 0.3rem;
        }
        
        .ratingAmount {
            padding: 0 2rem;
            width: 100%;
        }
        
        .ratingAmount > table {
            width: 100%;
        }
        .th {
            text-align: left;
            font-weight: bold;
            font-size: 1.3rem;
            text-transform: uppercase;
            color: #324b4e;
            padding-bottom: 1.5rem;
            border-bottom: 1px solid #0099a726;
        }
        
        .ratingAmount > table > tbody > tr > th:first-of-type {
            width: 35%;
        }
        
        .ratingAmount > table > tr > th:last-of-type, .ratingAmount table > tr > td {
            width: 65%;
        }
        
        .xStars {
           width: 100%;
           text-align: left;
           font-size: 1.5rem;
           color: #3a80be;
           border: none;
           background-color: transparent;
           outline: none;
           padding: 2rem 1rem;
           margin: 0 !important;
           cursor: pointer;
           -webkit-appearance: none;
        }
        
        tr {
            border-bottom: 1px solid #0099a726;
        }
        
        td > span {
            text-align: left;
            font-weight: bold;
            font-size: 1.3rem;
            text-transform: uppercase;
            color: #324b4e;
            margin-left: 1rem;
        }
        
        td > span > span {
            font-weight: 400;
            text-transform: none;
            color: #ff8c41;
        }
        
        
        input[type="range"] {
            -webkit-appearance: none;
            outline: none;
            overflow: hidden;
            width: 40%;
            height: 5px;
            border-radius: 0px;
            background-color: #324b4e38;
            display: inline;
          }
        
          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            cursor: pointer;
            width: 0%;
            height: 5px;
            background: #ff8c41;
            box-shadow: -500px 0 0 500px #ff8c41;
          }
        
          .reviews {
              width: 75%;
              padding: 0 3.5rem;
          }
          .headerRatings .item {
            border-bottom: 1px solid #0099a726;
            padding-bottom: 3rem;
            padding-top: 3.5rem;
          }
        
          .rate-header {
            display: flex;
            margin-bottom: 1.25rem;
          }
        
          .rate-comment {
            font-size: 1.3rem;
            font-weight: 500;
            line-height: 1.54;
            color: #324b4e;
          }
        
          .photo {
            align-items: center;
            background-color: $colorCorporative;
            border-radius: 100%;
            color: #fff;
            display: flex;
            font-size: 1.5rem;
            font-weight: 300;
            height: 4rem;
            justify-content: center;
            margin-right: 3rem;
            min-width: 4rem;
            overflow: hidden;
            width: 4rem;
        
          }
        
          .name {
            font-weight: 500;
            margin-bottom: 1.5rem;
            color: #324b4e;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1;
            text-align: left;
          }
        
          .rate-order-verified {
            color: #3a80be;
            font-size: 1.3rem;
            font-weight: 700;
            margin-left: 2.5rem;
          }
        
          .stars {
              display: flex;
              margin-bottom: 1rem;
          }
        
          .stars > div {
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            height: 1.5rem;
            margin-right: 3px;
            opacity: 1;
            transform: translateX(0);
            width: 1.5rem;
            background-image: url(/css/assets/coloredStar.svg);
          }
        
          .date {
            font-size: 1.3rem;
            font-weight: 500;
            opacity: .5;
          }
          .filterMargin{
            display: flex;
            align-items: center;
              .th{
                  width: 40%;
                  border-bottom: none;
                  margin: 0;
                  padding: 0;
              }
                .productSelect {
                    -webkit-appearance: none;
                    background-color: #324b4e0c;
                    font-size: 1.5rem;
                    height: 4.5rem;
                    width: 60%;
                    padding: 0.5rem 1.5rem;
                    border: none;
                    border-radius: 0;
                    outline: none;
                    color:#324b4e;
                    margin-left: 1rem;            
                    background-image: url(/css/assets/triangle.svg);
                    background-size: 8px 12px;
                    background-repeat: no-repeat;
                    background-position: 90% center;
                }
            }
        
        
        
          .form {
              width: 100%;
              transition: height 1s;
          }
        
          .form > p {
            font-size: cacl(1.5 * 0.95rem);
            color: #324b4e;
            font-weight: 600;
            margin-top: cacl(1.5 * 4rem);
            margin-bottom: cacl(1.5 * 1.5rem);
          }

          .wrap-form-ratings_2021{
            padding: 3rem 10rem !important;
            //padding-bottom: 6rem !important;
          }
          .form.form-rating_2021{  
            position: relative;
            .tooltip{
                font-size: 1.5rem;
                .firstImgTooltip{
                    width: 70%;
                }
            }

            .customFileUpload{
                border: 1px solid #575252;
                height: 4.5rem;
                font-size: 1.5rem;
                font-family: 'Montserrat-Medium';
                font-weight: normal;
                margin: 0;
            }
            .or{
                position: absolute;
                left: 50%;
                transform: translate(-50%, 1rem);
            }
            p{
                font-size: 1.5rem;
                padding-bottom: 2rem;
            }
            .mobileRatingText{
                font-size: 1.5rem;
                padding-bottom: 2rem;
                line-height: 2.1rem;
                padding-right: 2.5rem;
            }
            .formStars{
                justify-content: flex-end;
                padding-bottom: 2rem;
            }

            .aux-wrap-form{
                width: calc(100% + 2.5rem);  
            }

            .input-box{
                position: relative;        
                padding-right: 2.5rem;
                padding-bottom: 2.5rem;
            }
            %item-box {
                display: block;
                input, textarea, select{         
                    & +.label {        
                        position: absolute;    
                        top: 0;
                        font-size: 1.5rem !important;
                        left: 1.5rem !important;
                        font-family: 'Montserrat-Medium' !important;
                        color: #575252 !important;
                        background-color: #fff !important;                      
                        padding: 0 1.1rem;
                        pointer-events: unset;
                        transform: translateY(-50%) !important;    
                        transform-origin: left;
                        white-space: nowrap;
                        transition: none;
                        font-weight: normal !important;
                        

                    }
                    &:valid,  &:focus {            
                        & +.label {                   
                            transform: translateY(-50%);                  
                        }
                    }
                    &:focus {
                        border: 1px solid #575252; 
                    }     
            
                }   
            
                input, select, textarea {
                    -webkit-appearance: none;
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 4.5rem;
                    border-radius: 0 !important;
                    border: 1px solid #575252;	
                    color: #575252;
                    font-family: 'Montserrat-Medium';
                    font-size: 1.5rem;
                    outline: 0;
                    padding: 0 1rem;
                    transition: all 0.2s linear;
                    text-decoration: none;
                    background-color: inherit;
            
                    &.error {
                        border-color: $red !important;
                    }	      	
            
                }
                select {
                    line-height: normal;
                    font-size: 1.7rem;
                }
                textarea {
                    padding: 1rem;
                    min-width: 100%;
                    max-width: 100%;
                    min-height: 15rem;
                }
                input[type=file] {
                    //display: none;
                    position: absolute;
                    display: block;
                    top: 1rem;
                    visibility: hidden;
                }
            }
        
        
            .input-box {
                @extend %item-box;
            }
            .privacy{
                position: relative;
                width: 100% !important;
                padding-left: 0;

                input[type="checkbox"]{
                    width: auto;
                    visibility: hidden;
                }
              /*
                .labelContainer{
                    top: 0;
                    width: 100%;
                    position: relative;
                    left: 0;
                    margin-left: 0;
                    display: flex;
                    flex-wrap: wrap;
                    padding-top: 1rem;
                    align-items: baseline;
            
                    .textContainer{
                        color: #575252;
                        font-size: 1.3rem;
                        width: calc(100% - 2.2rem);
                        font-family: 'Montserrat-Medium';
                        font-weight: normal;
            
                        .link{
                            text-decoration: underline;
                            color: $colorCorporative;
                            font-family: 'Montserrat-Medium';
                            font-weight: normal;
                        }
                    }
                }
                input+.labelContainer:before {
                    content: '';
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    min-width: 1.2rem;
                    height: 1.2rem;
                    top: 0;
                    border: 0.1rem solid $colorCorporative;
                    border-radius: 0 !important;
                    background-color: transparent;
                    transition: background-color 0.2s linear 0.1s;
                    
                }
                input.error +.labelContainer:before {
                    border: 0.1rem solid #e3342f;        
                }
                input+ .labelContainer:after {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    min-width: .8rem;
                    height: 0.4rem;
                    top: 1.7rem;
                    left: 0.2rem;
                    border: 0.2rem solid $colorCorporative;
                    border-top: 0;
                    border-right: 0;
                    transform: rotate(-45deg) scale(0);
                    transform-origin: left top;
                    transition: all 0.2s ease 0s;
                }
                input[type="checkbox"]:checked + .labelContainer:before {
                    background-color: transparent;
                    transition: background-color 0.2s linear 0s;
                }
                input[type="checkbox"]:checked + .labelContainer:after {
                    transform: translateY(-20%) rotate(-45deg) scale(1);
                    transition: all 0.2s ease 0.1s;
                }
                */
                &.error{
                    input + .labelContainer:before {
                        border: 0.2rem solid $err !important;
                    }
                }
                .err{
                    width: 100%;
                    font-style: normal;
                    color: $err;
                }
            }
            .buttons{
                width: 100%;
                display: flex;
                margin-top: 2rem;
                .wrap-btnDefault{
                    margin: 0 auto;
                    margin-right: 2.5rem;
                    min-width: unset;
                    .btnDefault{
                        padding: 1.4rem 6rem;
                        font-size: 1.5rem;
                    }
                }
            }
                       
        }
    }
}

.headerRatings > section, .headerRatings > header > h1 {
    width: 100%;
}

.headerRatings > section:first-of-type {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: rgba(0, 166, 184, 0.07);
    padding: 1.5rem 11.25rem;
    align-items: center;
}

.headerRatings > section:first-of-type > button {
    font-size: 1.3rem;
    color: $colorCorporative;
    font-weight: 600;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0 2rem;
    cursor: pointer;
    padding: 0.2rem 0;
}
.headerRatings > section:first-of-type > button:hover {
    border-bottom: 2px solid $colorCorporative;
}

.starBtns {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
}

.starBtns > button{
    background-color: transparent;
    border: none;
    padding: 0;
    width: 2rem;
    height: 2.1rem;
    margin: 0.2rem;
    cursor: pointer;
    background-image: url(/css/assets/star.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.s1:hover,
.s2:hover,
.s3:hover,
.s4:hover,
.s5:hover,
.s1:hover ~ .s2, .s1:hover ~ .s3, .s1:hover ~ .s4, .s1:hover ~ .s5,
.s2:hover ~ .s3, .s2:hover ~ .s4, .s2:hover ~ .s5,
.s3:hover ~ .s4, .s3:hover ~ .s5,
.s4:hover ~ .s5 {
    background-image: url(/css/assets/coloredStar.svg) !important;
}

.headerRatings > header > h1 {
    text-align: center;
    font-size: 2.3rem;
    color: $colorCorporative;
    font-weight: bold;
    margin-bottom: 4rem;
}

.ratings {
    display: flex;
    margin-top: 4.5rem;
}

.ratings > div:first-of-type {
    width: 35%;
    display: flex;
    flex-wrap: wrap;
    border-right: 1px solid #0099a726;
    align-self: flex-start;
}

.hiddenSEO {
    position: absolute;
    opacity: 0;
    width: 0;
}

.logoAndRatings {
    display: flex;
    padding: 0.8rem 1rem  0.7rem 1rem;
    margin-bottom: 2rem;
    width: 100%;
}

.logoAndRatings > img {
    align-self: flex-start;
    width: 45%;
}

.logoAndRatings > div {
    margin-left: 10%;
    width: 45%;
}

.logoAndRatings > div > div {
    display: flex;
}

.logoAndRatings > div > div > img {
    width: 20%;
}

.logoAndRatings > div > p {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 0.8rem;
    color: #324b4e;
    margin-left: 0.2rem;
}

.logoAndRatings > div > div {
    margin-bottom: 0.3rem;
    margin-top: 0.3rem;
}

.ratingAmount {
    padding: 0 1.2rem;
    width: 100%;
}

.ratingAmount > table {
    width: 100%;
}
.th {
    text-align: left;
    font-weight: bold;
    font-size: 0.7rem;
    text-transform: uppercase;
    color: #324b4e;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #0099a726;
}

.ratingAmount > table > tbody > tr > th:first-of-type {
    width: 35%;
}

.ratingAmount > table > tr > th:last-of-type, .ratingAmount table > tr > td {
    width: 65%;
}

.xStars {
   width: 100%;
   text-align: left;
   font-size: 0.8rem;
   color: #3a80be;
   border: none;
   background-color: transparent;
   outline: none;
   padding: 1.4rem 0.5rem;
   margin: 0 !important;
   cursor: pointer;
   -webkit-appearance: none;
}

tr {
    border-bottom: 1px solid #0099a726;
}

td > span {
    text-align: left;
    font-weight: bold;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: #324b4e;
    margin-left: 0.2rem;
}

td > span > span {
    font-weight: 400;
    text-transform: none;
    color: #ff8c41;
}


input[type="range"] {
    -webkit-appearance: none;
    outline: none;
    overflow: hidden;
    width: 40%;
    height: 5px;
    border-radius: 0px;
    background-color: #324b4e38;
    display: inline;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    width: 0%;
    height: 5px;
    background: #ff8c41;
    box-shadow: -500px 0 0 500px #ff8c41;
  }

  .reviews {
      width: 75%;
      padding: 0 3.5rem;
  }
  .headerRatings .item {
    border-bottom: 1px solid #0099a726;
    padding-bottom: 1.4375rem;
    padding-top: 1.875rem;
  }

  .rate-header {
    display: flex;
    margin-bottom: 1.25rem;
  }

  .rate-comment {
    font-size: .8125rem;
    font-weight: 500;
    line-height: 1.54;
    color: #324b4e;
  }

  .photo {
    align-items: center;
    background-color: $colorCorporative;
    border-radius: 100%;
    color: #fff;
    display: flex;
    font-size: 1.3125rem;
    font-weight: 300;
    height: 2.75rem;
    justify-content: center;
    margin-right: 1.5625rem;
    min-width: 2.75rem;
    overflow: hidden;
    width: 2.75rem;

  }

  .name {
    font-weight: 500;
    margin-bottom: .4375rem;
    color: #324b4e;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1;
    text-align: left;
  }

  .rate-order-verified {
    color: #3a80be;
    font-size: .6875rem;
    font-weight: 700;
    margin-left: 1.5625rem;
  }

  .stars {
      display: flex;
      margin-bottom: 1rem;
  }

  .stars > div {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 1rem;
    margin-right: 3px;
    opacity: 1;
    transform: translateX(0);
    width: 1.0625rem;
    background-image: url(/css/assets/coloredStar.svg);
  }

  .date {
    font-size: .8125rem;
    font-weight: 500;
    opacity: .5;
  }

  .productSelect {
      -webkit-appearance: none;
      background-color: #324b4e0c;
      padding: 0.5rem 1.5rem;
      border: none;
      border-radius: 0;
      outline: none;
      color:#324b4e;
      margin-left: 0.5rem;
      width: 14rem;
      background-image: url(/css/assets/triangle.svg);
      background-size: 8px 12px;
      background-repeat: no-repeat;
      background-position: 90% center;
  }

  .form {
      width: 100%;
      transition: height 1s;
  }

  .form > p {
    font-size: 0.95rem;
    color: #324b4e;
    font-weight: 600;
    margin-top: 4rem;
    margin-bottom: 1.5rem;
  }

  .form > div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  }

  .form > div > div:nth-of-type(1),  .form > div > div:nth-of-type(2),  .form > div > div:nth-of-type(3),  .form > div > div:nth-of-type(4), .form > div > div:nth-of-type(5), .form > div > div:nth-of-type(6) {
    width: 47%;
  }

.form > div > div:nth-of-type(8), .form > div > div:nth-of-type(11) {
    width: 100%;
  }

  .form > div > div:nth-of-type(9),  .form > div > div:nth-of-type(10) {
    width: 46%;
}

.ratingSelect {
    width: 47% !important;
}

.form > div > div:nth-of-type(11) > textarea {
    height: 10rem;
    resize: none;
}

  .labelRatingForm {
      width: 100%;
      display: block;
      font-size: 0.8rem;
      color: #324b4e86;
      margin-top: 1.5rem;
  }

  .form > div > div > input, .form > div > div > select, .form > div > div:nth-of-type(11) > textarea {
      width: 100%;
      padding: 1rem 0rem 1rem 1rem;
      font-size: 1rem;
      background-color: rgba(255, 255, 255, 0.7);
      border: none;
      outline: none;
      margin-bottom: 0.2rem;
      border: 1px solid rgba(255, 255, 255, 0.7);
  }

  .form > div > div > select {
      -webkit-appearance: none;
      background-image: url(/css/assets/triangle.svg);
      background-size: 8px 12px;
      background-repeat: no-repeat;
      background-position: 90% center;
  }

  .formStars {
      margin-top: 4rem;
      margin-bottom: 1.5rem;
  }

  .customFileUpload {
    width: 100%;
    padding: 1.2rem 0rem 1.2rem 0rem;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    outline: none;
    margin-bottom: 0.3rem;
    color: #324b4e;
    font-size: 0.8rem;
    text-transform: uppercase;
    text-align: center;
    align-self: center;
    margin-top: 2.9rem;
    border: 1px solid rgba(255, 255, 255, 0.7);
    background-size: cover;
  }

  input[type="file"] {
    // display: none;
    position: absolute;
    display: block;
    top: 1rem;
    visibility: hidden;
}

.form > div > div:nth-of-type(12) {
    padding-top: 1.2rem;
}
.privacyCheck input {
    width: 0.8rem !important;
}
.privacyCheck {
    margin-top: 0.5rem;
    display: flex;
    width: 100%;
}

.privacyCheck label {
    font-size: 0.8rem;
    color: #324b4e;
    margin-left: 0.5rem;
}

.sendBtn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.sendBtn input{
    background-color: $colorCorporative !important;
    color: white;
    width: 240px !important;
    cursor: pointer;
    transition: opacity 0.5s;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 0.8rem;
}

.sendBtn input:hover {
    opacity: 50%;
}

.headerRatings .tooltip, .mobileForm .tooltip {
    position: relative;
    display: inline-block;
    transition: color 0.3s;
    opacity: 1;
  }

.headerRatings .tooltip:hover, .mobileForm .tooltip:hover {
    color: $colorCorporative;
  }

.headerRatings .tooltip .tooltiptext, .mobileForm .tooltip .tooltiptext {
    visibility: hidden;
    width: 29rem;
    background-color: rgba(0, 0, 0, 0.863);
    color: #fff;
    text-align: center;
    padding: 2rem;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }

.headerRatings .tooltip .tooltiptext::after, .mobileForm .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    margin-left: -5px;
    border-width: 5px;
  }

.headerRatings .tooltip:hover .tooltiptext, .mobileForm .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .arrowTooltip {
      position: absolute;
      top: 57%;
      left: 39%;
  }

  .firstImgTooltip{
    margin: 10% 0% 10% 13%;
      max-height: 200px;
  }

  .secondImgTooltip {
      margin-right: 50%;
      max-height: 100px;
  }

  .thanks {
    text-align: center;
    display: none;
    background-color: rgba(0, 166, 184, 0.07);
    padding: 2rem 0rem;
    width: 100%;
  }

  .thanks > p:first-of-type{
    font-size: 1.4rem;
    font-weight: 600;
    color: $colorCorporative;
    margin-block-end: 0;
    margin-bottom: 0.8rem;
  }

  .thanks > p:last-of-type {
    font-size: 1.4rem;
    font-weight: 300;
  }

  .hiddenAccordeon {
    height: 0;
    overflow: hidden;
  }

  .rate-answer {
      padding-left: 10%;
      margin-top: 2rem;
  }

  .rate-answer > div:first-of-type {
      margin-bottom: 1rem;
      border-left: 2px solid #0099a79d;
      padding-left: 1rem;
  }

  .rate-answer > div:last-of-type {
    border-left: 2px solid #D9F2F5;
    padding-left: 1rem;
    width: 90%;
  }

  .rate-answer > div:first-of-type > img {
      width: 4rem;
      margin-bottom: 0.2rem;
  }

    .mobileRatingText {
        font-size: 1.5rem;
        line-height: 2.1rem;
        color: #324b4e;
        font-weight: 600;
        margin-bottom: 1rem;
        margin-top: 2rem;   
    }
    .moreInfoSpan {
        color: $colorCorporative;
        font-weight: normal;
        text-decoration: underline;
    }

    .mobileForm {
        display: none;
    }

    .markWrongField {
        border: 1px solid red;
    }

  @media (max-width: 991px) {
    .headerRatings {
        padding: 2rem 1.6rem;

        &.headerRatings_2021{
            padding: 4rem 0rem;
            margin-left: 0;

            .wrap-form-ratings_2021{
                padding: 3rem 1rem !important; 
                background-color: #fff;  
                & *{
                    padding-bottom: .5rem;
                    padding-top: .5rem;
                }
            }
           
            &> section:first-of-type > button {
                font-size: calc(1.5 * 1.3rem);
                color: $colorCorporative;
                font-weight: 600;
                background-color: transparent;
                border: none;
                outline: none;
                margin: 0 calc(1.5 * 2rem);
                cursor: pointer;
                padding: 0;
            }
            .starBtns > button{
                background-color: transparent;
                border: none;
                padding: 0;
                width: calc(1.5 * 2rem);
                height: calc(1.5 * 2.1rem);
                margin: calc(1.5 * 0.2rem);
                cursor: pointer;
                background-image: url(/css/assets/star.svg);
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }
     
            
            .ratings {
                display: flex;
                margin-top: 3rem;
            }
            
            .ratings > div:first-of-type {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                border-right: none;
                align-self: flex-start;
            }
            
            .hiddenSEO {
                position: absolute;
                opacity: 0;
                width: 0;
            }
            
            .logoAndRatings {
                display: flex;
                padding: 1rem 2rem  1rem 1rem;
                margin-bottom: 2rem;
                width: 100%;
            }
            
            .logoAndRatings > img {
                align-self: flex-start;
                width: 45%;
            }
            
            .logoAndRatings > div {
                margin-left: 10%;
                width: 45%;
            }
            
            .logoAndRatings > div > div {
                display: flex;
            }
            
            .logoAndRatings > div > div > img {
                width: 20%;
            }
            
            .logoAndRatings > div > p {
                margin-block-start: 0;
                margin-block-end: 0;
                font-size: 1.3rem;
                color: #324b4e;
                margin-left: 0.2rem;
            }
            
            .logoAndRatings > div > div {
                margin-bottom: 0.3rem;
                margin-top: 0.3rem;
            }
            
            .ratingAmount {
                padding: 0;
                width: 100%;
            }
            
            .ratingAmount > table {
                width: 100%;
            }
            .th {
                text-align: left;
                font-weight: bold;
                font-size: 1.3rem;
                text-transform: uppercase;
                color: #324b4e;
                padding-bottom: 1.5rem;
                border-bottom: 1px solid #0099a726;
            }
            
            .ratingAmount > table > tbody > tr > th:first-of-type {
                width: 35%;
            }
            
            .ratingAmount > table > tr > th:last-of-type, .ratingAmount table > tr > td {
                width: 65%;
            }
            
            .xStars {
               width: 100%;
               text-align: left;
               font-size: 1.5rem;
               color: #3a80be;
               border: none;
               background-color: transparent;
               outline: none;
               padding: 2rem 1rem;
               margin: 0 !important;
               cursor: pointer;
               -webkit-appearance: none;
            }
            
            tr {
                border-bottom: 1px solid #0099a726;
            }
            
            td > span {
                text-align: left;
                font-weight: bold;
                font-size: 1.3rem;
                text-transform: uppercase;
                color: #324b4e;
                margin-left: 1rem;
            }
            
            td > span > span {
                font-weight: 400;
                text-transform: none;
                color: #ff8c41;
            }
            
            
            input[type="range"] {
                -webkit-appearance: none;
                outline: none;
                overflow: hidden;
                width: 40%;
                height: 5px;
                border-radius: 0px;
                background-color: #324b4e38;
                display: inline;
              }
            
              input[type="range"]::-webkit-slider-thumb {
                -webkit-appearance: none;
                cursor: pointer;
                width: 0%;
                height: 5px;
                background: #ff8c41;
                box-shadow: -500px 0 0 500px #ff8c41;
              }
            
              .reviews {
                  width: 100%;
                  padding: 0 1.5rem;
              }
              .headerRatings .item {
                border-bottom: 1px solid #0099a726;
                padding-bottom: 3rem;
                padding-top: 3.5rem;
              }
            
              .rate-header {
                display: flex;
                margin-bottom: 1.25rem;
              }
            
              .rate-comment {
                font-size: 1.3rem;
                font-weight: 500;
                line-height: 1.54;
                color: #324b4e;
              }
            
              .photo {
                align-items: center;
                background-color: $colorCorporative;
                border-radius: 100%;
                color: #fff;
                display: flex;
                font-size: 1.5rem;
                font-weight: 300;
                height: 4rem;
                justify-content: center;
                margin-right: 3rem;
                min-width: 4rem;
                overflow: hidden;
                width: 4rem;
            
              }
            
              .name {
                font-weight: 500;
                margin-bottom: 1.5rem;
                color: #324b4e;
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1;
                text-align: left;
              }
            
              .rate-order-verified {
                color: #3a80be;
                font-size: 1.3rem;
                font-weight: 700;
                margin-left: 2.5rem;
              }
            
              .stars {
                  display: flex;
                  margin-bottom: 1rem;
              }
            
              .stars > div {
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: contain;
                height: 1.5rem;
                margin-right: 3px;
                opacity: 1;
                transform: translateX(0);
                width: 1.5rem;
                background-image: url(/css/assets/coloredStar.svg);
              }
            
              .date {
                font-size: 1.3rem;
                font-weight: 500;
                opacity: .5;
              }
              .filterMargin{
                display: flex;
                align-items: center;
                flex-direction: column;
                  .th{
                      width: 100%;                 
                  }
                    .productSelect {                  
                        height: 4.5rem;
                        width: 100%;
                        padding: 0.5rem 1.5rem;                
                        margin-left: 0;  
                    }
                }
            
            
            
              .form {
                  width: 100%;
                  transition: height 1s;
              }
            
              .form > p {
                font-size: cacl(1.5 * 0.95rem);
                color: #324b4e;
                font-weight: 600;
                margin-top: cacl(1.5 * 4rem);
                margin-bottom: cacl(1.5 * 1.5rem);
              }
        }
    }

    .headerRatings > section:first-of-type {
        padding: 2rem 1rem;
    }

    .headerRatings > section:first-of-type > button {
        font-size: 1.3rem;
        width: 100%;
        margin: 0.5rem 0rem;
        padding: 0.2rem 0;
    }

    .headerRatings > section:first-of-type > button:hover {
        border-bottom: none;
    }

    .headerRatings > header > h1 {
        font-size: 1.1rem;
        margin-bottom: 2rem;
    }

    .ratings {
        margin-top: 1.8rem;
        flex-wrap: wrap;
    }

    .ratings > div:first-of-type {
        width: 100%;
        border-right: none;
    }

    .hiddenSEO {
        position: absolute;
        opacity: 0;
    }

    .logoAndRatings {
        background-color: transparent;
        padding: 0;
        margin: 2rem auto 4rem auto;
        width: 100%;
        max-width: 350px;
    }

    .logoAndRatings > img {
        align-self: flex-start;
        width: 45%;
    }

    .logoAndRatings > div {
        margin-left: 10%;
        width: 45%;
    }

    .logoAndRatings > div > p {
        margin-block-start: 0;
        margin-block-end: 0;
        font-size: 0.8rem;
        color: #324b4e;
    }

    .logoAndRatings > div > div {
        margin-bottom: 0.3rem;
        margin-top: 0.3rem;
    }

    .ratingAmount {
        padding: 0rem;
        width: 100%;
    }

    .ratingAmount > table {
        width: 100%;
    }

    td {
        background-image: url(/css/assets/arrow-right.svg);
        background-repeat: no-repeat;
        background-position: 95% center !important;
        cursor: pointer;
    }

    .reviews {
          width: 100%;
          padding: 0;
    }

    .rate-order-verified {
        margin-left: 0;
        margin-top: 0.5rem;
        display: block;
        width: 100%;
    }

    .productSelect {
        padding: 0.8rem 1.5rem;
        margin: 2rem 0rem;
        width: 95%;
    }

    .form {
        display: none;
    }

    .thanks {
        text-align: center;
        display: none;
        padding: 1rem;
    }    

    .mobileForm {
        display: block;
        margin-top: 2rem;
        z-index: 3;
        background-color: rgba(0, 166, 184, 0.07);
        padding: 0;
        margin-bottom: 6rem;
    }

    .mobileForm > div {
        padding: 1.5rem;
    }

    .mobileForm > div > div {
        width: 90%;
    }

    .mobileForm > div > div > input, .mobileForm > div > div > select, .mobileForm > div > div:nth-of-type(11) > textarea {
        width: 100%;
        padding: 1rem 0rem 1rem 1rem;
        font-size: 1rem;
        background-color: rgba(255, 255, 255, 0.7);
        border: none;
        outline: none;
        margin-bottom: 1.5rem;
    }

    .mobileForm > div > div > select {
        -webkit-appearance: none;
        background-image: url(/css/assets/triangle.svg);
        background-size: 8px 12px;
        background-repeat: no-repeat;
        background-position: 90% center;
        width: 100%;
    }

    .mobileForm > div > div:nth-of-type(11) > textarea {
        height: 15rem;
        resize: none;
    }

    .mobileForm .tooltip .tooltiptext {
        left: -5rem;
        width: 20rem;
      }

      .mobileRatingMenu {
          display: flex;
          justify-content: space-between;
          height: 3.5rem;
          background-color: white;
          padding: 0 1.5rem 0 1.5rem;
          border-bottom: 1px solid #f3f6f7;
      }

      .mobileRatingMenu p {
          color: $colorCorporative;
          font-weight: 600;
          align-self: center;
          font-size: 1rem;
      }

      .mobileRatingMenu button {
          align-self: center;
          border: none;
          background-color: transparent;
          font-size: 1rem;

      }

      .ratingSelect {
        width: 90% !important;
    }

    .ratingSelect > label, .ratingSelect > select {
        margin-left: 0rem;
    }

    .formStars {
        justify-content: flex-end;
    }

    .hiddenMobile {
        display: none;
    }

    .customFileUpload {
        margin-left: 0;
        width: 100%;
        margin-bottom: 2rem;
        margin-top: -1rem;
    }

    // .mobileRatingText1 {
    //     margin: 1.5rem 1.5rem;
    // }

    .privacyCheck {
        margin-bottom: 0;
    }

    .sendBtn {
        margin-top: 3rem;
    }

    .filterMargin {
        margin-top: 3rem;
    }

    .arrowTooltip {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: rotate(-35deg);
    }

    .firstImgTooltip{
      margin: 10% 0% 10% 20%;
    }

    .secondImgTooltip {
        margin-right: 30%;
    }

  }
.comment-loader {
    text-align: center;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $colorCorporative;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

    @media (max-width: $md-width) {
        .headerRatings_2021.mobileForm{
            font-size: 1.5rem;

            .mobileRatingMenu{             
                transform: translate(-1.5rem, -1.5rem);
                width: calc(100% + 3rem);
                padding: 2.5rem 1.5rem;
                p{
                    font-size: 1.5rem;
                }
                button{
                    width: 1.5rem;
                    height: 1.5rem;
                    position: relative;
                    background-image: url('/images/cancel-copy.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;                    
                    left: unset;
                    right: 0;
                }
                .mobileRatingText.mobileRatingText1{
                    font-size: 1.3rem;
                }
            }
            .mobileRatingText{
                margin: 2.5rem 0;
                font-size: 1.5rem;
                line-height: 1.7rem;
                color: #324b4e;
                //font-weight: normal;
                margin-bottom: 1rem;
                margin-top: 2rem;
            }
            .form{
                display: block;
                width: 100%;  
                padding-right: 0;

                .aux-wrap-form{
                    width: 100%;
                    flex-direction: column;
                }          

                .input-box{
                    padding-right: 0 !important;
                }
                .customFileUpload{
                    position: relative;
                    top: 1rem;
                    height: 4.5rem;
                    opacity: 1;
                    border: 1px solid #575252;
                    background-color: #fff;                  
                    line-height: 1.5;
                    font-size: 1.3rem;
                    font-family: 'Montserrat-Medium';
                    font-weight: normal;
                    margin-top: 1rem;
                }
                .buttons{
                    .wrap-btnDefault{
                        min-width: unset;
                        margin: 0 auto;
                        margin-right: 0;
    
                        .btnDefault{
                            font-size: 1.5rem;
                            line-height: 1.7rem;
                            padding: 1.5rem 4.3rem
                        }
                    }
                }
              
                .privacy{
                    margin-bottom: 2rem;
                    padding: 0;
                    .labelContainer{
                        .textContainer{
                            font-size: 1.1rem;
                            line-height: 1.8rem;
                        }
                    }
                    /*
                    input+.labelContainer:before {				
                        top: .2rem;		
                    }
                    input+ .labelContainer:after {				
                        top: 1.9rem;			
                    }
                    */
                }
            }

            position: relative;

            .aux-wrap-form{
                width: 100%;  
            }

            .input-box{
                position: relative;        
                padding-right: 2.5rem;
                padding-bottom: 2rem;
            }
            .input-box {
                display: block;
                .label {        
                    position: relative;    
                    top: 0;
                    font-size: 1.3rem !important;
                    left: 1.5rem !important;
                    font-family: 'Montserrat-Medium' !important;
                    color: #575252 !important;
                    background-color: #ECF1F5!important;                      
                    padding: 0 1.1rem;
                    pointer-events: unset;
                    //transform: translateY(-50%) !important;    
                    transform-origin: left;
                    white-space: nowrap;
                    transition: none;
                    font-weight: normal !important;
                    z-index: 2;
                    left: 0 !important;
                    padding: 0;
                    background-color: transparent !important;                      
                    //transform: translateY(-2rem)! important;
                    transform: none;

                    .tooltip{
                        font-size: 1.5rem;

                        .tooltiptext{                            
                            width: 70vw;

                            .firstImgTooltip{
                                width: 80%;
                            }
                        }
                    }
                }
                input, textarea, select{         
                    & +.label {        
                        position: absolute;    
                        top: 0;
                        font-size: 1.3rem !important;
                        left: 1.5rem !important;
                        font-family: 'Montserrat-Medium' !important;
                        color: #575252 !important;
                        background-color: #ECF1F5!important;                      
                        padding: 0 1.1rem;
                        pointer-events: unset;
                        transform: translateY(-50%) !important;    
                        transform-origin: left;
                        white-space: nowrap;
                        transition: none;
                        font-weight: normal !important;
                        z-index: 2;
                        left: 0 !important;
                        padding: 0;
                        background-color: transparent !important;                      
                        transform: translateY(-2rem)! important;

                        // &::before{
                        //     content: '';
                        //     position: absolute;
                        //     background-color: rgba(0, 166, 184, 0.07);
                        //     width: 100%;
                        //     height: 100%;
                        //     z-index: 1;
                        //     top: 0;
                        //     left: 0;
                        // }
                    }
                    &:valid,  &:focus {            
                        & +.label {                   
                            transform: translateY(-50%);                  
                        }
                    }
                    &:focus {
                        border: 1px solid #575252; 
                    }     
            
                }   
                input[type=file] {
                    //display: none;
                    position: absolute;
                    display: block;
                    top: 1rem;
                    visibility: hidden;
                }
            
                input, select, textarea {
                    -webkit-appearance: none;
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 4.5rem;
                    border-radius: 0 !important;
                    border: 1px solid #575252;	
                    color: #575252;
                    font-family: 'Montserrat-Medium';
                    font-size: 1.5rem;
                    outline: 0;
                    padding: 0 1rem;
                    transition: all 0.2s linear;
                    text-decoration: none;
                    background-color: inherit;
                    margin-top: 0;

                    &.error {
                        border-color: $red !important;
                    }	      	
            
                }
                select {
                    line-height: normal;
                    font-size: 1.7rem;
                }
                textarea {
                    padding: 1rem;
                    min-width: 100%;
                    max-width: 100%;
                    min-height: 15rem;
                }
            }
        
        
            .privacy{
                position: relative;
                width: 100% !important;
                padding-left: 0;

                input[type="checkbox"]{
                    width: auto;
                    visibility: hidden;
                }
              /*
                .labelContainer{
                    top: 0;
                    width: 100%;
                    position: relative;
                    left: 0;
                    margin-left: 0;
                    display: flex;
                    flex-wrap: wrap;
                    padding-top: 1rem;
                    align-items: baseline;
            
                    .textContainer{
                        color: #575252;
                        font-size: 1.3rem;
                        width: calc(100% - 2.2rem);
                        font-family: 'Montserrat-Medium';
                        font-weight: normal;
            
                        .link{
                            text-decoration: underline;
                            color: $colorCorporative;
                            font-family: 'Montserrat-Medium';
                            font-weight: normal;
                        }
                    }
                }
                */
                /*
                input+.labelContainer:before {
                    content: '';
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    min-width: 1.2rem;
                    height: 1.2rem;
                    top: 0;
                    border: 0.1rem solid $colorCorporative;
                    border-radius: 0 !important;
                    background-color: transparent;
                    transition: background-color 0.2s linear 0.1s;
                    
                }
                input.error +.labelContainer:before {
                    border: 0.1rem solid #e3342f;        
                }
                input+ .labelContainer:after {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    min-width: .8rem;
                    height: 0.4rem;
                    top: 2rem;
                    left: 0.2rem;
                    border: 0.2rem solid $colorCorporative;
                    border-top: 0;
                    border-right: 0;
                    transform: rotate(-45deg) scale(0);
                    transform-origin: left top;
                    transition: all 0.2s ease 0s;
                }
                input[type="checkbox"]:checked + .labelContainer:before {
                    background-color: transparent;
                    transition: background-color 0.2s linear 0s;
                }
                input[type="checkbox"]:checked + .labelContainer:after {
                    transform: translateY(-20%) rotate(-45deg) scale(1);
                    transition: all 0.2s ease 0.1s;
                }
                &.error{
                    input+.labelContainer:before {
                        border: 0.2rem solid $err !important;
                    }
                }
                .err{
                    width: 100%;
                    font-style: normal;
                    color: $err;
                }
                */
            }
            .buttons{
                width: 100%;
                display: flex;
                margin-top: 2rem;
                .wrap-btnDefault{
                    margin: 0 auto;
                    margin-right: 2.5rem;
                    min-width: unset;
                    .btnDefault{
                        padding: 1.4rem 6rem;
                        font-size: 1.5rem;
                    }
                }
            }

        }
    }

    .headerRatings_2021 .thanks{
        background-color: #fff;
        padding: 4rem 0;

        & > p:first-of-type{
            font-size: 2.1rem;
        }
        & > p:last-of-type {
            font-size: 2.1rem;
        }
        @media (max-width: $md-width) {
            padding: 2rem 0;

            & > p:first-of-type{
                font-size: 1.5rem;
            }
            & > p:last-of-type {
                font-size: 1.5rem;
            }
        }
    } 
