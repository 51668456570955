
@media (min-width: 768px) {
    .productosFamaliving.footer .container {
        max-width:initial;
        width: 100%;
        padding-left: 66px;
        padding-right: 66px;
    }
}
.productosFamaliving.footer {
    background: #EEE;
    margin-top: 15px;
    color: #888;
    padding: 20px 0 0 0;
    z-index: 2;
}
.productosFamaliving.footer .row{
    flex-wrap: wrap;
}
.productosFamaliving.footer .visible-sm {
    display: none !important;
}
.productosFamaliving.footer .visible-xs {
    display: none !important;
}

.footer.footer_2021{
    margin-top: 0 !important;
    background-color: #fff !important;
    padding: 0 !important;
    overflow: hidden !important;
    .well{
        min-height: 20px;
        padding: 2rem;
        padding-left: 0;
        padding-right: 3rem;
        margin-bottom: 3rem;
        background-color: transparent;   
        border: none;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $colorCorporative;
    }
    .part-menus{
        padding-left: 3.3rem;       
        padding-right: 1.4rem;
        padding-top: 6.7rem;
        position: relative;
        z-index: 1;
        .h2.title{
            padding: .4rem 1rem;
            font-family: 'Montserrat-Bold';
            font-size: 1.5rem;
            text-transform: uppercase;
            line-height: 1.9rem;
            color: #1197a5;
            transition: color 200ms ease-out;
        
          
        }
        .link{
            &:hover{               
                .h2.title{
                    color: rgba(#3f6574, 0.8);              
                    transition: color 200ms ease-out;
                }
            }                
        }
        color: #00A5B8;
        .menu-item{
            padding-bottom: 4.4rem;
            padding-right: 2.7rem;
            color: #00A5B8;
            .list{
                .list-item{
                    color: inherit;
                    font-size: 1.5rem;
                    opacity: 1;
                    font-family: "Montserrat-Regular";
                    line-height: 1.9rem;
                    height: auto;
                    padding: .4rem 1rem;
                    border-bottom: 0.3px solid rgba(#3f6574, 0.3);
                    .link{
                        line-height: 100%;
                        color: inherit;
                        text-decoration: none;     
                        &:hover{
                            text-decoration: none; 
                        }               
                        
                    }
                    &:hover{
                        background-color: #f3f6f7;
                        transition: .3s all ease;                   
                        
                    }
                    .h2{                    
                        text-transform: uppercase;
                        padding: 0;
                    }

                    &.has-children{
                        padding: 0;
                        .title{
                            padding: .4rem 1rem;
                        }
                        .list.sublist{
                            margin-bottom: 4.2rem;
                            .list-item{
                                &:hover{                                  
                                    background-color: #f3f6f7;
                                    transition: .3s all ease;       
                                }  
                            }
                        
                        }
                    }
                }
                &.primary{
                    .list-item{
                        &:hover{
                            background-color: inherit;
                        }
                    }
                }
               
            
            }    
            &.second{        
                .list.primary{     
                    .list-item{
                        &:hover{                         
                            background-color: #f3f6f7;
                            transition: 0.3s all ease; 
                            .h2.title:hover{
                                color: inherit;
                            }       
                        }
                    }
                    .list-item.has-children{
                        &:hover{
                            background-color: #fff;
                            color: rgba(63, 101, 116, 0.8);
                            transition: color 200ms ease-out;  
                            .list.sublist{
                                .list-item{
                                    color: #1197a5;
                                }
                            }
                        }
                    }
                }
            }   
            
        }
    
        .part-up{
            margin-left: 1.6rem;
            .part-left{             
                width: calc(100% / 4);
                .menu-item{
                    width: 100%;
                }
            }
            .part-right{            
                width: calc(100% - (100% / 4));
                .list-menus{
                    width: 100%;
                    justify-content: space-between;
                
                    .menu-item{
                        padding-bottom: 5.5rem;
                        width: calc(100% / 3);
                    }
                }
                .wrap-newsletter{                 
                    width: calc(100% + 1.3rem);

                    .newsletter{
                        padding: 5rem;
                        padding-right: 0;
                        background-color: #f3f6f7;
                        .title{
                            line-height: 3.3rem;
                            padding-bottom: 2.9rem;
                            font-size: 2.9rem;
                            font-family: 'Montserrat-Bold';
                        }
                        .form{
                            width: 100%;
                            .input{
                                width: 50%;
                                margin-right: 1.1rem;
                            }                         
                        }
                        .newsletter-content{
                            margin-top: 3rem;

                            .part-left, .part-right{
                                position: relative;
                                width: 50%;
                            }
                            .wrap-btnDefault{
                                align-self: flex-start;
                                transform: translateX(0.2rem);
                                margin-left: 0;
                            }
                            .text{
                                width: 70%;
                                color: #575252;
                                font-size: 1.5rem;
                                line-height: 2.3rem;
                                font-family: 'Montserrat-Medium';
                                margin-bottom: 2.3rem;
                            }
                        }
                    }
                }
                .wrap-img{   
                    min-width: 44rem;                 
                    width: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;

                    .aux-wrap-img{                      
                        padding-top: 59%;
                        width: 100%;
                        position: relative;
                        .img{
                            right: 0;
                            position: absolute;
                            top: 0;
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    &.mvl{
                        display: none;
                    }
                }
             
                .aux-item{
                    position: relative;
                    height: 100%;
                }
            }
        }
        .part-down{
            background-color: #f3f6f7;
            width: calc(100% / 4 + 1.2rem);

            .list-menus{
                position: relative;

                .menu-item{
                    padding-left: 1.6rem;
                    padding-bottom: 3rem;
                    padding-top: 5.5rem;

                    .list.primary{
                        border-top: 0.3px solid rgba(#3f6574, 0.3);

                        .list-item{
                            &:hover{
                                background-color: rgba(17, 151, 165, 0.16);
                                transition: .3s all ease;
                                .h2.title{
                                    color: inherit;
                                }
                            }
                        }
                    }
                   
                }
            }
            &.mvl{
                display: none;
            }
         
        }

    }
  
    .wrap-btnDefault{       
        margin-left: 1.1rem;          
        align-self: flex-start;
        margin-top: 0.2rem;          
        .btnDefault{    
            letter-spacing: 3px;
            line-height: 1.7rem;
            font-size: 1.5rem;}
    }
    .part-info{
        width: 100%;
        position: relative;
        padding-top: 5rem;
        padding-right: 3.3rem;
        padding-left: 3.3rem;
        padding-bottom: 2.9rem;
        background-color: #fff;
        z-index: 2;

        .wrap-redes{
            width: 100%;
            padding-left: 1.6rem;
            padding-bottom: 2.9rem;
            border-bottom: 1px solid #cfdae1;
            align-items: center;
            justify-content: space-between;

            .redes{
                justify-content: space-between;           
                align-items: center;
                height: 4.2rem;

                .logo{
                    height: 100%;
                    img{
                        height: 100%;
                    }
                }
                .red-item{
                    font-size: 1.7rem;
                    line-height: 2.1rem;
                    font-family: 'Montserrat-Bold';
                    color: #575252;   
                    a{
                        color: #575252; 
                    }  
                    a:hover{
                        color: #1197a5;
                        transition: .3s all ease;
                    }                    
                }
                &>div{
                    margin-right: 3.2rem;
                }
            }
            #languages{              
                position: relative;
                margin: 0;
                padding: 0;
                height: 2.7rem;
                a, .a{
                    font-size: 1.7rem;
                    color: #575252;
                }
                .lang-current a,  
                .lang-current .a{
                    font-family: 'Montserrat-Bold';
                    color: #1197a5;
                    border-bottom: 5px solid #1197a5;
                }
                .lang-item{
                    margin-right: 4.2rem;
                    a, .a{
                        font-family: 'Montserrat-Medium';
                    }
                    &:last-of-type{
                        margin-right: 0;
                    }
                }
            }
        }
        .info-pages{
            padding-top: 2.9rem;
            justify-content: space-between;
            color: #575252;
            font-size: 1.5rem;
            line-height: 1.9rem;
            font-family: 'Montserrat-Regular';
            .page{
                margin-left: 3.2rem;          
                a{   
                    color: #575252;            
                    text-decoration: none;
                    transition: .3s all ease;
                }
                a:hover{
                    color: #575252;
                    opacity: 1;
                    text-decoration: underline;
                    transition: .3s all ease;
                }
            
            }
        }
    }
    .input-box .well{
        min-height: 20px;
        padding: 2rem;
        padding-left: 0;
        margin-bottom: 3rem;
        background-color: transparent;      
        border: none;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $colorCorporative;
    }

}

@media (min-width: calc(#{$md-width} + 1px)) and (max-width: 1200px) {
    .footer.footer_2021{        
        .part-info{
            .wrap-redes{
                flex-direction: column;
                .languages{
                    justify-content: center;
                }            
            }
        }
    }

}

@media (min-width: calc(#{$md-width} + 1px)) and (max-width: calc(#{$lg-width} + 1px)) {
    .footer.footer_2021{        
        .part-menus .part-up{
            .part-right{
                .wrap-img{   
                    min-width: unset;      
                    padding-left: 1rem;           
                    width: 100%;
                    .aux-wrap-img{                
                        padding-top: 33rem;
                    }
                }
            }
        }
    }
}

@media (max-width: $md-width) {
    .footer.footer_2021{
        padding: 0;
        .part-menus{
            padding-right: 1.5rem;
            padding-left: 1.5rem;
            padding-top: 1.8rem;
            .h2.title{
                padding: .4rem 1rem;
                font-family: 'Montserrat-Bold';
                font-size: 1.5rem;
                text-transform: uppercase;
                line-height: 1.9rem;
                color: #1197a5;
                transition: color 200ms ease-out;
                &:hover{
                    color: rgba(#3f6574, 0.8);              
                    transition: color 200ms ease-out;
                }
            }
            color: #00A5B8;
            .menu-item{
                padding-bottom: 4.4rem;
                padding-right: 0;
                color: #00A5B8;

                .list{
                    .list-item{
                        color: inherit;
                        font-size: 1.5rem;
                        opacity: 1;
                        font-family: "Montserrat-Regular";
                        line-height: 1.9rem;
                        height: auto;
                        padding: .4rem 1rem;
                        border-bottom: 0.3px solid rgba(#3f6574, 0.3);
                        .link{
                            line-height: 100%;
                            color: inherit;
                            text-decoration: none;     
                            &:hover{
                                text-decoration: none; 
                            }               
                            
                        }
                        &:hover{
                            background-color: #f3f6f7;
                            transition: .2s all ease;                   
                            
                        }
                        .h2{                    
                            text-transform: uppercase;
                            padding: 0;
                        }
    
                        &.has-children{
                            padding: 0;
                            .title{
                                padding: .4rem 1rem;
                            }
                            .list.sublist{
                                margin-bottom: 4.2rem;
                                .list-item{
                                    &:hover{                                  
                                        background-color: #f3f6f7;
                                        transition: .2s all ease;       
                                    }  
                                }
                            
                            }
                        }
                    }
                    &.primary{
                        .list-item{
                            &:hover{
                                background-color: inherit;
                            }
                        }
                    }
                   
                
                }
                
            }
            .part-up{
                margin-left: 0;
                flex-direction: column;
            
                .part-left{             
                    width: 100%;
                    .menu-item{
                        width: 100%;
                        padding-right: 1.5rem;
                        padding-left: 1.5rem;
                    }
                }
                .part-right{            
                    width: 100%;
                    .list-menus{
                        flex-direction: column;
                        width: 100%;
                        justify-content: space-between;
                        .menu-item{
                            padding-bottom: 5.5rem;
                            width: 100%;
                            padding-right: 1.5rem;
                            padding-left: 1.5rem;
                        }
                    }
                    .wrap-newsletter{                 
                        width: calc(100% + 1.5rem);
    
                        .newsletter{
                            padding: 0;
                            background-color: #fff;
                            width: 100%;
                            display: flex;
                            flex-direction: column;

                            .newsletter-form{
                                background-color: #f3f6f7;
                                padding-left: 1.5rem;
                                padding-right: 3.3rem;
                                width: 100%;
                                padding-bottom: 13.9rem;
                                padding-top: 3.3rem;
                            }
                            .title{
                                line-height: 3.3rem;
                                padding-bottom: 2.9rem;
                                font-size: 2.9rem;
                                font-family: 'Montserrat-Bold';
                            }
                            .form{
                                width: 100%;
                                margin-bottom: 3rem;
                                .input{
                                    width: 50%;
                                    margin-right: 1.1rem;
                                }                         
                            }
                            .newsletter-content{
                                flex-direction: column;
                                margin-top: 3rem;
                                background-color: #f3f6f7;
                                position: relative;
                                left: -1.5rem;
                                padding: 3rem;

                                .part-left, .part-right{
                                    position: relative;
                                    width: 100%;
                                }
                                .wrap-btnDefault{
                                    min-width: auto;
                                    align-self: flex-start;
                                    transform: translateX(0.2rem);
                                    margin-left: 0 !important;  
                                    .btnDefault{
                                        font-size: 1.7rem;
                                        padding: 1.4rem;
                                    }                                  
                                }
                                .text{
                                    width: 100%;
                                    color: #575252;
                                    font-size: 1.5rem;
                                    line-height: 2.3rem;
                                    font-family: 'Montserrat-Medium';
                                    margin-bottom: 2.3rem;
                                }
                            }
                        }
                    }
                    .wrap-img{   
                        min-width: 44rem;                 
                        width: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
    
                        .aux-wrap-img{                      
                            padding-top: 59%;
                            width: 100%;
                            position: relative;
                            .img{
                                right: 0;
                                position: absolute;
                                top: 0;
                                object-fit: cover;
                                width: 100%;
                                height: 100%;
                            }
                        }
                        &.mvl{
                            position: relative;
                            display: block;
                            width: calc(100% - 4.2rem);
                            min-width: calc(100% - 4.2rem);
                            align-self: flex-end;
                            margin-top: -15%;
                        }
                        &.desk{
                            display: none;
                        }
                    }
                 
                    .aux-item{
                        position: relative;
                        height: 100%;
                    }
                }
    
            }
            .part-down{
                background-color: #f3f6f7;
                width: calc(100% + 1.5rem);
                margin-bottom: 2.4rem;
                .list-menus{
                   
                    .menu-item{
                        padding-left: 1.6rem;
                        padding-bottom: 3rem;
                        padding-top: 3rem;
    
                        .list.primary{
                            border-top: 0.3px solid rgba(#3f6574, 0.3);
    
                            .list-item{
                                &:hover{
                                    background-color: rgba(17, 151, 165, 0.16);
                                }
                                .title{
                                    font-family: 'Montserrat-SemiBold';
                                }
                            }
                        }
                       
                    }
                }
                &.mvl{
                    display: block;
                    width: calc(100% + 1.5rem);
                    padding-right: 1.5rem;
                    .list-menus{                   
                        .menu-item{
                            padding-bottom: 3rem;
                        }
                    }                
                }
                &.desk{
                    display: none;
                }
            }
    
        }
        .wrap-btnDefault{         
            margin-left: 0;  
            min-width: auto;                
            .btnDefault{    
                letter-spacing: 3px;
                line-height: 1.7rem;
                font-size: 1.5rem;}
        }
        .part-info{
            width: 100%;
            position: relative;       
            padding-right: 3.3rem;
            padding-left: 3.3rem;
            padding-bottom: 2.9rem;
            background-color: #fff;
            padding-top: 5.4rem;
            z-index: 2;
    
            .wrap-redes{
                width: 100%;
                padding-left: 1.6rem;
                padding-bottom: 2.9rem;
                border-bottom: 1px solid #cfdae1;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                padding-left: 0;
                padding-bottom: 1.5rem;
    
                .redes{
                    justify-content: space-between;         
                    align-items: center;
                    height: auto;
                    flex-wrap: wrap;
                    order: 2;
    
                    .logo{
                        width: 100%;
                        height: 100%;
                        text-align: center;                    
                        padding-top: 3rem;
                        padding-bottom: 3rem;
                        margin-right: 0;
                        img{
                            height: 100%;
                        }
                    }
                    .red-item{
                        width: calc(100% / 3);
                        font-size: 1.7rem;
                        line-height: 2.1rem;
                        font-family: 'Montserrat-Bold';
                        color: #575252;
                        margin-bottom: 2.1rem;
                        margin-right: 0;
                      
                        &:nth-child(2){
                            text-align: left;
                        }
                        &:nth-child(3){
                            text-align: center;
                        }
                        &:nth-child(4){
                            text-align: right;
                        }
                        &:nth-child(5){
                            text-align: left;
                        }
                        &:nth-child(6){
                            text-align: center;
                        }
                        &:nth-child(7){
                            text-align: right;
                        }
                        
                    }         
                }
                #languages{   
                    width: 100%;
                    position: relative;
                    margin: 0;
                    padding: 0;                   
                    order: 1;

                    display: flex;
                    border-bottom: 1px solid #cfdae1;
                    border-top: 1px solid #cfdae1;                    
                    height: 4.8rem;
                    align-items: center;
                    a, .a{
                        font-size: 1.7rem;
                        color: #575252;
                    }
                    .lang-current a,
                    .lang-current .a{
                        font-family: 'Montserrat-Bold' !important;
                        color: #1197a5;
                        border-bottom: 5px solid #1197a5;
                    }
                    .lang-item{
                        margin-right: 4.2rem;
                        a, .a{
                            font-family: 'Montserrat-Medium';
                        }
                        &:last-of-type{
                            margin-right: 0;
                        }
                    }
                    .list-languages{
                        margin: 0 auto;
                    }
                }
            }
            .info-pages{
                padding-top: 2.9rem;
                justify-content: space-between;
                color: #575252;
                font-size: 1.5rem;
                line-height: 1.9rem;
                font-family: 'Montserrat-Regular';
                flex-direction: column;
                text-align: center;
                .info{
                    order: 2;
                    border-top: 1px solid #cfdae1;   
                    padding-top: 3rem;
                    display: flex;
                    flex-direction: column;
                    line-height: 2.3rem;
                
                }
                .page{
                    margin-left: 0;
                    padding-bottom: 2.1rem;
                }
                .pages{
                    order: 1;
                    flex-direction: column;
                }
            }
        }
    
    }
}

.wrap-logosEU{
    display: flex;
    position: absolute;
    width: 150%;
    height: 100%;
    right: -150%;
    top: 0;
    padding-left: 2.5rem;  
    padding-top: 2.5rem;
    z-index: 9999;

    a{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: left;
        }
    }

    &.mvl{
        display: none;
    }
    @media (min-width: 1200px) {
        .esp{
            width: 100%;
        }
        .eu{
            width: 100%;

            img{
                height: 130%;
            }
        }
    }

    @media (min-width: 1600px) {
        .esp{
            width: 100%;
        }
        .eu{
            width: 150%;
            img{
                height: 130%;
            }
        }
    }

    @media (max-width: $md-width) {
        &.mvl{
            width: 100%;         
            display: flex;
            position: relative;
            top: unset;
            right: unset;
            flex-direction: column;
            padding-left: 0;
            padding-top: 0;

            .eu{
               overflow: hidden;
               position: relative;

                img{
                    width: calc(100% + 5rem);
                    margin-left: -2.5rem;
                }
            }
            
        }
    }  
}