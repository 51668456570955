.section-empresa.sofas.sofas-quality{
    &.section-two-columns-quality{
        .text{
            color: #000 !important;
        }
        .wrap-content{          
            .part-up{
                .wrap-title{
                    .title{
                        padding-right: 0;
                    }
                    .subtitle{
                        width: 100%;
                    }
                }
                .list-logos{
                    margin-bottom: 3rem;                    
                    align-self: flex-end;
                    max-height: 17.8rem;
                    width: 50%;
                    justify-content: center;
                    justify-content: center;
                    .logo{
                        &:nth-child(1){
                            align-items: center;
                            display: flex;
                            padding-right: 11%;
                        }
                        &:nth-child(2){
                            max-width: 13.6rem;
                            display: flex;
                            align-items: center;
                        }
                    }
                }
               

            }
           
            .warp-content-text{
                .part-left{
                    padding-right: 3.2rem;
                }
                .part-right{
                    padding-left: 3.2rem;
                }
            }
            .wrap-img{
                width: 100%;
                position: relative;
                padding-top: 34%;
                margin-top: 4.4rem;
            }
        }

    }
    &.section-two-columns-elements{
        .wrap-content{
            .wrap-content-items{
                .wrap-items{
                    flex-wrap: wrap;
                    width: calc(100% + 6.6rem);
                    .item{                        
                        width: 50%;
                        padding-right: 6.6rem;
                        padding-bottom: 5.8rem;
                        .title{
                            font-size: 2.1rem;
                            line-height: 2.5rem;
                            font-family: 'Montserrat-Bold';
                            margin-bottom: 2rem;
                            color: $colorCorporative;
                        }
                        .text.generic{
                            color: #000;
                        }
                    }
                }
            }
        }
    }

    &.section-logo-garanty{
        .wrap-content{
            .wrap-garanty{
                position: relative;
                max-width: 69rem;
                margin: 0 auto;
                background-color: rgba(17, 151, 165, 0.14);
                padding: 4.7rem;
                padding-right: calc(23.4rem / 2);
                .wrap-title{
                    width: 100%;
                    .title{
                        font-size: 2.1rem;
                        padding-right: 0;
                        letter-spacing: 0;
                        line-height: 2.5rem;
                        width: 100%;
                        color: #000;
                    }
                }
                .wrap-content-text{
                    .text{
                        font-size: 1.5rem;
                        line-height: 2.3rem;
                        padding-bottom: 3.5rem;
                        color: #000;
                    }
                    .wrap-btnDefault{
                        align-self: flex-start;
                    }
                }
            }
      
        }
   

        .marka{
            background-image: url('/images/img_garanty/star-garanty.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            position: absolute;
            width: 23.4rem;
            height: 23.4rem;
            right: 0;
            top: 0;
            transform: translateX(50%) translateY(-45%);

            &::before{
                content: '';
                background-image: url('/images/img_garanty/check-white.svg');
                background-repeat: no-repeat;
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                background-position: center;
                transform: scale(1.4);
            }
            &::after{
                content: "";
                background-image: url('/images/img_garanty/words-circle.svg');
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                background-position: center;
                background-repeat: no-repeat;              
                animation: spins_sofas 16s linear infinite;
                @-moz-keyframes spins_sofas {
                    100% { -moz-transform: rotate(360deg) }
                }
                @-webkit-keyframes spins_sofas {
                    100% { -webkit-transform: rotate(360deg)}
                }
                @keyframes spins_sofas {
                    from {
                        -webkit-transform: scale(1.4) rotate(0deg);
                        transform: scale(1.4) rotate(0deg);
                    }
                    to {
                        -webkit-transform: scale(1.4) rotate(360deg);
                        transform: scale(1.4) rotate(360deg);
                    }
                }
            }

        }
    }

}

//**********************************************tablet**************************************/

@media (min-width: calc(#{$md-width} + 1px)) and (max-width: 1500px) {

    .section-empresa.sofas.sofas-quality.section-logo-garanty .wrap-content .wrap-garanty{
       max-width: 75%;  
            
       padding-right: calc(15rem / 2);
        .marka{            
            width: 15rem;
            height: 15rem;                

            &::before{         
                transform: scale(0.8);
            }
            &::after{              
                
                @-moz-keyframes spins_sofas {
                    100% { -moz-transform: rotate(360deg) scale(0.9) }
                }
                @-webkit-keyframes spins_sofas {
                    100% { -webkit-transform: rotate(360deg) scale(0.9)}
                }
                @keyframes spins_sofas {
                    from {
                        -webkit-transform: scale(0.9) rotate(0deg);
                        transform: scale(0.9) rotate(0deg);
                    }
                    to {
                        -webkit-transform: scale(0.9) rotate(360deg);
                        transform: scale(0.9) rotate(360deg);
                    }
                }
            }
        }
    }
}
//**********************************************MOVIL**************************************/

@media (max-width: $md-width) {

    .section-empresa.sofas.sofas-quality{
        &.section-two-columns-quality{
            .wrap-content{
                .part-up{
                    flex-direction: column;
                    .wrap-title{
                        .title{
                            padding-right: 0;
                        }
                        .subtitle{
                            width: 100%;
                        }
                    }
                    .list-logos{
                        margin-bottom: 3rem;                    
                        align-self: unset;
                        max-height: 17.8rem;                       
                        width: 100%;
                        justify-content: flex-start;                    
                    }  
                }            
                .warp-content-text{
                    .part-left{
                        padding-right: 0;
                        padding-bottom: 2.5rem;
                    }
                    .part-right{
                        padding-left: 0;
                    }
                }
                .wrap-img{
                    width: calc(100% + 3rem);
                    transform: translateX(-1.5rem);
                    position: relative;
                    padding-top: 60%;
                    margin-top: 8rem;
                }
            }

        }
        &.section-two-columns-elements{
            padding-top: 3.5rem;
            .wrap-content{
                .wrap-content-items{
                    .wrap-items{                    
                        width: 100%;
                        .item{                        
                            width: 100%;
                            padding-right: 0;
                            padding-bottom: 5rem;                    
                          
                            .title{
                                padding-bottom: 2rem;
                            }
                        }
                    }
                }
            }
        }

        &.section-logo-garanty{
            padding-top: 6.7rem;

            .wrap-content{
                .wrap-garanty{              
                    max-width: 100%;
                    width: 100%;                 
                    padding: 2.5rem;
                    padding-right: 2.5rem;
                    padding-top: 7.1rem;
                    padding-bottom: 4.5rem;

                    .wrap-title{
                        width: 100%;              
                    }
                    .wrap-content-text{
                        .text{
                            padding-bottom: 3.5rem;
                        }
                        .wrap-btnDefault{
                            width: 100% !important;
                            align-self: center;
                            .btnDefault{
                                font-size: 1.9rem;
                                line-height: 1.7rem;
                                padding: 2rem 1.4rem;
                            }
                        }
                    }
                }        
            }   

            .marka{            
                width: 13.4rem;
                height: 13.4rem;      
                transform: translateX(-2.5rem) translateY(-60%);

                &::before{         
                    transform: scale(0.8);
                }
                &::after{              
                    
                    @-moz-keyframes spins_sofas {
                        100% { -moz-transform: rotate(360deg) scale(0.8) }
                    }
                    @-webkit-keyframes spins_sofas {
                        100% { -webkit-transform: rotate(360deg) scale(0.8)}
                    }
                    @keyframes spins_sofas {
                        from {
                            -webkit-transform: scale(0.8) rotate(0deg);
                            transform: scale(0.8) rotate(0deg);
                        }
                        to {
                            -webkit-transform: scale(0.8) rotate(360deg);
                            transform: scale(0.8) rotate(360deg);
                        }
                    }
                }
            }
        }
    }
}