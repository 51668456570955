.section-empresa.sofas.sofas-rscCollage{
    &.section-rsc-collage{
        padding-top: 0;
        .wrap-content{
            .warp-collage{
                width: calc(100% + 1rem);
                margin-top: 16.6rem;
                .wrap-row{
                    display: flex;
                    width: 100%;
                    position: relative;
                    margin-bottom: 1rem;
                    .wrap-aux{
                        position: relative;
                        width: 50%;
                        height: 17vh;
                        margin-right: 1rem;
                        &:first-of-type, &:last-of-type{
                            width: 25%;
                        }
                    }
                    .wrap-img{
                        width: 100%;
                        height: 100%;
                        position: relative; 
                        .img{
                            height: 100%;                            
                            object-fit: cover;
                            width: 100%;
                            top: 0;
                            left: 0;                           
                        }              
                    }
                    &.row2{
                        .wrap-aux{
                            position: relative;
                            width: calc(100% / 3);
                        }
                        &:first-of-type, &:last-of-type{
                            width: calc(100% / 3);
                        }
                    }
                }
            }
        }
        .warp-collage-one{
            padding-top: 16rem;
            .img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;              
            }
        }
    }
}

//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {
    .section-empresa.sofas.sofas-rscCollage{
        &.section-rsc-collage{
            padding-top: 0;
            .wrap-content{
                .warp-collage-one{
                    padding-top: 4.5rem;
                }
                .warp-collage{
                    width: calc(100% + .5rem);
                    margin-top: 4.5rem;
                    .wrap-row{
                        display: flex;
                        width: 100%;
                        position: relative;
                        margin-bottom: .5rem;
                        .wrap-aux{
                            position: relative;
                            width: 50%;
                            height: 10vh;
                            margin-right: .5rem;
                            &:first-of-type, &:last-of-type{
                                width: 25%;
                            }
                        }
                        .wrap-img{
                            width: 100%;
                            height: 100%;
                            position: relative;                        
                              
                            .img{
                                height: 100%;                            
                                object-fit: cover;
                                width: 100%;
                                top: 0;
                                left: 0;                               
                            }              
                        }
                        &.row2{
                            .wrap-aux{
                                position: relative;
                                width: calc(100% / 3);
                            }
                            &:first-of-type, &:last-of-type{
                                width: calc(100% / 3);
                            }
                        }
                    }
                }
            }
        }
    }
}