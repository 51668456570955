.module-list-container{
    .container{
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-bottom: 0 !important;
    }
}
.section-empresa.sofas.sofas-industry{
    &.section-two-paragraphs-images-middle{
      
        .wrap-content{
            .wrap-text{
                .generic.text{
                    color: #000;
                    font-size: 1.7rem;
                    line-height: 2.5rem;
                }
            }
            .part-up{
                width: 100%;
                display: flex;
                flex-direction: column;
                .wrap-content1, .wrap-content2, .wrap-content3{
                    width: calc(100% - (100% / 3));
                }
                .wrap-content2, .wrap-content3{
                    align-self: flex-end;
                }
                .wrap-content1{
                    padding-bottom: 4rem;
                }
                .wrap-content2{
                    padding-bottom: 6rem;
                }
                .wrap-content3{
                    padding-bottom: 0;
                }
            }

            .part-down{
                .wrap-content-down{
                    width: calc(100% + 4rem);                   
                    .item{
                        width: calc(100% / 3);
                        padding-bottom: 2.5rem;
                        padding-right: 4rem;
                    }
                    .subtitle{
                        line-height: 2.5rem !important;
                        font-size: 1.7rem;
                        font-family: 'Montserrat-Bold' !important;
                        text-transform: unset;
                        letter-spacing: 0;
                    }                  
                    .wrap-aux-content{
                        position: relative;
                        width: 100%;   
                        flex-wrap: wrap;          
                      
                        .wrap-img{                                
                            justify-content: center;
                            display: flex;
                            width: calc(((100% / 3) * 2));
                            .img{
                                width: 50%;
                                display: block;
                            }                            
                        }
                         
                    
                    }                                         
                }              
            }           
            
        }
    }
    &.cms{
        padding-top: 8rem;
    }
}

//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {
    .section-empresa.sofas.sofas-industry{
        &.section-two-paragraphs-images-middle{     
            .wrap-content{
                .wrap-text{
                    .generic.text{
                        color: #000;
                        font-size: 1.7rem;
                        line-height: 2.5rem;
                    }
                }
                .generic.text, .generic.text p{
                    color: #000;
                    font-size: 1.7rem;
                    line-height: 2.5rem;
                }
                .part-up{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    .wrap-content1, .wrap-content2, .wrap-content3{
                        width: 100%;
                    }
                    .wrap-content2, .wrap-content3{
                        align-self: unset;
                    }
                    .wrap-content1{
                        padding-bottom: 4rem;
                    }
                    .wrap-content2{
                        padding-bottom: 6rem;
                    }
                    .wrap-content3{
                        padding-bottom: 0;
                    }
                }
    
                .part-down{
                    padding-top: 6rem;
                    .title{
                        font-size: 3.3rem;
                    }
                 
                    .wrap-content-down{
                        flex-direction: column;
                        width: 100%;
                        padding-top: 0;
                        .subtitle{
                            line-height: 2.5rem;
                            font-size: 1.7rem;
                            font-family: 'Montserrat-Bold';
                            text-transform: unset;
                            letter-spacing: 0;
                        }                    
                   
                        .wrap-aux-content{
                            position: relative;
                            width: 100%;      

                            .wrap-img{
                                order: 8;
                                width: 100%;
                                .img{
                                    width: 100%;
                                    display: block;
                                }
                            }                                           
                         
                        }  
                     
                        .item{
                            width: 100%;
                            padding-right: 0;
                            padding-bottom: 3.5rem;
                            &.item1{
                                order: 1;
                            }
                            &.item4{
                                order: 2;
                            }
                            &.item2{
                                order: 3;
                            }
                            &.item3{
                                order: 5;
                            }                          
                            &.item5{
                                order: 4;
                            }
                            &.item6{
                                order: 6;
                            }
                            &.item7{
                                order: 7;
                            }


                        }                                         
                    }                
                }           
                
            }
        }
        &.cms{
            padding-top: 0 !important;
        }
    }
}



