.bg-gray.bg-gray-catalogue{
	background-color: #ECF1F5 !important;
}

.app-list_2021{
	width: 100%;

	/**** START OLD ****/
	.module-list-fancy {
		.module-menu-sm {
			display: none;
		}
		.module-list-header {
			display: flex;
			width: 100%;
			//align-items: flex-end;
			align-items: center;
			justify-content: flex-end;
			border-bottom: 1px solid $c-gray2;
			padding-bottom: rem(5px);
			padding-top: rem(55px);
			padding-left: rem(10px);
			padding-right: rem(10px);
			margin-bottom: rem(45px);
			.breadcrumb {
				flex-grow: 1;
			}
			.button-container {
				white-space: nowrap;
				.button-grid,
				.button-list {
					width: rem(34px);
					height: rem(34px);
					border-radius: 3px;
					cursor: pointer;
					outline: none;
					border: 0;
					position: relative;
					background-color: transparent;
					transition: all $t-fast linear;
					&:after {
						content: '';
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						width: rem(14px);
						height: rem(14px);
						background-repeat: no-repeat;
						background-position: center;
						background-size: contain;
					}
					&:hover {
						background-color: #fff;
					}
					&.isActive {
						background-color: #fff;
						pointer-events: none;
					}
				}
				.button-grid {
					&:after {
						background-image: url('/css/assets/grid.svg');
					}
					&.isActive {
						&:after {
							background-image: url('/css/assets/fl-grid-active.svg');
						}
					}
				}
				.button-list {
					margin-left: rem(5px);
					&:after {
						background-image: url('/css/assets/list.svg');
					}
					&.isActive {
						&:after {
							background-image: url('/css/assets/fl-list-active.svg');
						}
					}
				}
			}
		}
		.module-list-wrapper {
			display: flex;
			flex-wrap: nowrap;
			//padding-left: rem(20px);
			padding-left: 0;
			width: calc(100% + #{rem(17px)});
			position: relative;
	
			.ballon-list {
				position: absolute;
				z-index: -1;
				height: rem(445px);
				width: rem(445px);
				background-color: #fff;
				border-radius: 100%;
				top: rem(100px);
				left: rem(-165px);
				opacity: 0;
				transition: opacity $t-normal linear, transform $t-fast $easeOutCubic;
			}
			.module-list-lateral {
				width: rem(250px);
				min-width: rem(250px);
				padding-bottom: rem(50px);
				.module-list-lateral-inner {
					position: sticky;
					top: calc(#{$topbar-height} + #{rem(45px)});
				}
				.module-lateral-extra {
					.module-lateral-title {
						padding-top: rem(100px);
						font-weight: normal;
						font-family: 'Montserrat-Bold';
						font-size: rem(25px);
						color: $colorCorporative;
						line-height: 1.22;
						position: relative;
						padding-bottom: rem(20px);
						&:before {
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							width: rem(80px);
							height: 5px;
							background-color: $colorCorporative;
						}
					}
					.module-lateral-text {
						padding-top: rem(25px);
						font-size: rem(13px);
						line-height: 1.77;
						color: $gray-dark;
					}
					img {
						max-width: rem(200px);
						margin-top: rem(45px);
					}
				}
			}
			.module-list-container {
				padding-left: rem(45px);
				width: calc(100% - #{rem(250px)});
				min-width: calc(100% - #{rem(250px)});
				flex-grow: 1;
				.module-list-header {
					display: none;
				}
				.categories-list,
				.products-list {
					 display: flex;
					flex-wrap: wrap;
					position: relative;
					padding-bottom: rem(130px);
				}
				.categories-list {
					.categories-item {
						position: relative;
						height: rem(335px);
						width: calc(100% / 3 - #{rem(17px)});
						margin-right: rem(17px);
						margin-bottom: rem(17px);
						&.double {
							width: calc((100% / 3) * 2 - #{rem(17px)});
						}
						&:hover {
							.categories-item-image {
								img {
									transform: scale(1.1) translate(-45%, -45%);
								}
							}
						}
						.categories-item-image {
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: calc(100% - #{rem(100px)});
							overflow: hidden;
							img {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								object-position: center;
								object-fit: cover;
								width: 100%;
								height: 100%;
								transform-origin: center;
								transition: transform 1s $easeOutCubic;
							}
						}
						.categories-item-header {
							position: relative;
							z-index: 1;
							height: rem(100px);
							border-bottom: 5px solid $c-gray2;
							padding: rem(20px);
							background-color: #fff;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							.top-section {
								display: flex;						
								align-items: baseline;
								.categories-item-name {
									flex-grow: 1;
									text-overflow: ellipsis;
									padding-right: rem(5px);
									font-weight: 500;
									font-size: rem(21px);
									color: $gray-dark;
									line-height: 1.05;
									max-height: rem(50px);
									overflow: hidden;
									-webkit-line-clamp: 2;
									display: -webkit-box;
									-webkit-box-orient: vertical;
								}
								.categories-item-like {
									font-family: 'Arvo';
									font-size: rem(15px);
									color: $pink;
									position: relative;
									display: flex;
									align-items: center;
									line-height: rem(23px);
									&:before {
										content: '';
										display: inline-block;
										width: rem(17px);
										height: rem(15px);
										margin-right: rem(7px);
										background-repeat: no-repeat;
										background-size: contain;
										background-position: center;
										background-image: url('/css/assets/heart-pink.svg');
									}
								}
							}
				
						}
						.hashtag-list {
							position: absolute;
							z-index: 1;
							width: 100%;
							top: rem(80px);
							transform: translateY(-100%);
							padding: 0 rem(20px);
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							opacity: 0.5;
							.hashtag-item {
								font-size: rem(11px);
								color: $gray-dark;
								font-weight: 500;
								margin-right: rem(20px);
								cursor: pointer;
								text-decoration: underline;
								&:hover {
									text-decoration: none;
								}
							}
						}
					}
				}
				.products-list {
					.products-item {
						position: relative;
						overflow: hidden;
						>a {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							opacity: 0;
							transition: opacity $t-fast linear;
						}
						.products-item-image {
							opacity: 0;
							img {
								transform: translate(-50%, -50%) scale(0.9);
							}
						}
						.products-item-special {
							bottom: rem(-17px);
						}
						.products-item-header {
							transform: translateY(100%);
							border-top: 0;
							width: 0;
							&:after {
								opacity: 0;
								transform: translateX(rem(-30px));
							}
							.top-section {
								.products-item-info {
									.products-item-name {
										opacity: 0;
										transform: translateY(#{rem(30px)});
									}
									.products-item-rating {
										>* {
											opacity: 0;
											transform: translateX(#{rem(15px)});
										}
									}
								}
								.products-item-like {
									opacity: 0;
									transform: translateY(#{rem(-30px)});
								}
							}
							.bottom-section {
								opacity: 0;
								transform: translateY(#{rem(30px)});
							}
						}
					}
					&.list-mode {
						width: calc(100% + #{rem(60px)});
						.products-item {
							>a {
								transition: opacity $t-fast linear calc(1.5s + 0s);
							}
							.products-item-header {
								transition: width $t-normal $easeOutCubic calc(1.5s + 0.2s), background-color $t-fast linear 0s;
								&:after {
									transition: opacity $t-fast linear calc(1.5s + 0.8s), transform $t-normal $easeOutCubic calc(1.5s + 0.8s);
								}
								.top-section {
									.products-item-info {
										.products-item-name {
											transition: opacity $t-fast linear calc(1.5s + 0.6s), transform $t-normal $easeOutCubic calc(1.5s + 0.6s);
										}
									}
									.products-item-like {
										transition: opacity $t-fast linear calc(1.5s + 0.8s), transform $t-normal $easeOutCubic calc(1.5s + 0.8s);
									}
								}
								.bottom-section {
									transition: opacity $t-fast linear calc(1.5s + 0.7s), transform $t-normal $easeOutCubic calc(1.5s + 0.7s);
								}
							}
							$delay: 0.05s;
							@for $i from 1 through 30 {
								&.order-#{$i} {
									>a {
										transition: opacity $t-fast linear calc(0s + (#{$i} * #{$delay}));
									}
									.products-item-header {
										transition: width $t-normal $easeOutCubic calc(0.2s + (#{$i} * #{$delay})), background-color $t-fast linear 0s;
										&:after {
											transition: opacity $t-fast linear calc(0.8s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.8s + (#{$i} * #{$delay}));
										}
										.top-section {
											.products-item-info {
												.products-item-name {
													transition: opacity $t-fast linear calc(0.6s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.6s + (#{$i} * #{$delay}));
												}
											}
											.products-item-like {
												transition: opacity $t-fast linear calc(0.8s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.8s + (#{$i} * #{$delay}));
											}
										}
										.bottom-section {
											transition: opacity $t-fast linear calc(0.7s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.7s + (#{$i} * #{$delay}));
										}
									}
								}
							}
						}
						.products-item {
							position: relative;
							height: rem(47px);
							margin-bottom: rem(17px);
							width: calc(50% - #{rem(60px)});
							margin-right: rem(60px);
							&.image-loaded {
								>a {
									opacity: 1;
									&:hover {
										.products-item-header {
											background-color: #f4f4f4;
										}
									}
								}
								.products-item-image {
									position: absolute;
									bottom: 0;
									left: 0;
									width: rem(47px);
									height: rem(47px);
									opacity: 1;
									img {
										object-position: center;
										object-fit: cover;
										width: 100%;
										height: 100%;
										transform: none;
									}
								}
								.products-item-special {
									display: none;
								}
								.products-item-header {
									position: absolute;
									top: 0;
									left: rem(47px);
									z-index: 1;
									height: 100%;
									background-color: #fff;
									display: flex;
									flex-direction: column;
									justify-content: space-between;
									padding: rem(6px) 0;
									width: calc(100% - #{rem(47px)});
									opacity: 1;
									transform: translateY(0);
									&:after {
										display: none;
										content: '';
										position: absolute;
										right: rem(10px);
										top: 50%;
										width: rem(7px);
										height: rem(12px);
										transform: translateY(-50%);
										background-repeat: no-repeat;
										background-size: contain;
										background-position: center;
										background-image: url('/css/assets/arrow-simple-grey.svg');
										opacity: 1;
										transform: translate(0, -50%);
									}
									.top-section {
										white-space: nowrap;
										display: flex;
										padding: 0 rem(10px);
										.products-item-info {
											flex-grow: 1;
											white-space: nowrap;
											display: flex;
											align-items: center;
											padding-right: rem(40px);
											.products-item-name {
												font-weight: 500;
												color: $gray-dark;
												font-size: rem(15px);
												opacity: 1;
												transform: translateY(0);
											}
											.products-item-rating {
												display: none;
											}
										}
										.products-item-like {
											position: absolute;
											z-index: 1;
											top: 50%;
											right: 0;
											height: 100%;
											margin-right: rem(10px);
											font-family: 'Arvo';
											font-size: rem(15px);
											color: $pink;
											display: flex;
											align-items: center;
											line-height: rem(23px);
											opacity: 1;
											transform: translate(0, -50%);
											&.favourite {
												pointer-events: none;
												&:before {
													background-image: url('/css/assets/heart-pink.svg');
												}
											}
											&:before {
												content: '';
												pointer-events: none;
												display: inline-block;
												width: rem(17px);
												height: rem(15px);
												margin-right: rem(7px);
												background-repeat: no-repeat;
												background-size: contain;
												background-position: center;
												background-image: url('/css/assets/heart.png');
											}
											&:hover
											{
												&:before {
													animation: beat .25s infinite alternate;
												}
											}
										}
									}
									.bottom-section {
										padding: 0 rem(10px);
										font-family: 'Arvo';
										font-size: rem(12px);
										color: $gray-dark;
										line-height: 1.3;
										opacity: 0.5;
										white-space: nowrap;
										overflow: hidden;
										text-overflow: ellipsis;
										padding-right: rem(80px);
										opacity: 1;
										transform: translateY(0);
									}
								}
							}
						}
					}
					&.grid-mode {
						.products-item {
							$delay: 0.05s;
							@for $i from 1 through 30 {
								&:nth-child(#{$i}) {
									.products-item-special {
										transition: bottom $t-normal $easeOutCubic calc(0.9s + (#{$i} * #{$delay}));
									}
									.products-item-header {
										transition: transform $t-normal $easeOutCubic calc(0.2s + (#{$i} * #{$delay})), border $t-fast $easeOutCubic calc(0.8s + (#{$i} * #{$delay}));
										.top-section {
											.products-item-info {
												.products-item-name {
													transition: opacity $t-fast linear calc(0.6s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.6s + (#{$i} * #{$delay}));
													.products-item-rating {
														>* {
															&:nth-child(1) { transition: opacity $t-fast linear calc(1s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(1s + (#{$i} * #{$delay})); }
															&:nth-child(2) { transition: opacity $t-fast linear calc(1.05s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(1.05s + (#{$i} * #{$delay})); }
															&:nth-child(3) { transition: opacity $t-fast linear calc(1.1s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(1.1s + (#{$i} * #{$delay})); }
															&:nth-child(4) { transition: opacity $t-fast linear calc(1.15s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(1.15s + (#{$i} * #{$delay})); }
															&:nth-child(5) { transition: opacity $t-fast linear calc(1.2s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(1.2s + (#{$i} * #{$delay})); }
														}
													}
												}
											}
											.products-item-like {
												transition: opacity $t-fast linear calc(0.8s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.8s + (#{$i} * #{$delay}));
											}
										}
										.bottom-section {
											transition: opacity $t-fast linear calc(0.7s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.7s + (#{$i} * #{$delay}));
										}
									}
								}
							}
						}
						.products-item {
							position: relative;
							height: rem(335px);
							width: calc(100% / 2 - #{rem(17px)});
							margin-right: rem(17px);
							margin-bottom: rem(17px);
							order: unset !important;
							&.double {
								width: calc(100% - #{rem(17px)});
								.products-item-header {
									width: calc(50% - (#{rem(17px)} / 2));
								}
							}
							&.is-new {
								.products-item-special {
									display: flex;
								}
							}
							&.image-loaded {
								>a {
									opacity: 1;
									&:hover {
										.products-item-image {
											img {
												transform: scale(1.1) translate(-45%, -45%);
											}
										}
									}
								}
								.products-item-image {
									position: absolute;
									bottom: 0;
									left: 0;
									width: 100%;
									height: 100%;
									overflow: hidden;
									opacity: 1;
									img {
										position: absolute;
										top: 50%;
										left: 50%;
										transform: translate(-50%, -50%);
										object-position: center;
										object-fit: cover;
										width: 100%;
										height: 100%;
										transform-origin: center;
										transition: transform 1s $easeOutCubic;
									}
								}
								.products-item-special {
									position: absolute;
									z-index: 1;
									left: rem(10px);
									height: rem(17px);
									padding: 0 rem(6px);
									align-items: center;
									background-color: $c-gray2;
									text-transform: uppercase;
									font-size: rem(11px);
									font-weight: bold;
									color: $gray-dark;
									bottom: rem(100px);
								}
								.products-item-header {
									position: absolute;
									bottom: 0;
									left: 0;
									z-index: 2;
									width: 100%;
									height: rem(100px);
									border-top: 5px solid $c-gray2;
									background-color: #fff;
									display: flex;
									flex-direction: column;
									padding: rem(17px) rem(10px) rem(12px) rem(10px);
									transform: translateY(0);
									&:before {
										content: '';
										position: absolute;
										width: 100%;
									}
									.top-section {
										white-space: nowrap;
										display: flex;
										.products-item-info {
											flex-grow: 1;
											white-space: nowrap;
											display: flex;
											flex-wrap: wrap;
											align-items: center;
											overflow: hidden;
											height: rem(21px);
											.products-item-name {
												font-weight: 500;
												color: $gray-dark;
												font-size: rem(21px);
												overflow: hidden;
												text-overflow: ellipsis;
												line-height: 1;
												opacity: 1;
												transform: translateY(0);
											}
											.products-item-rating {
												display: flex;
												margin-left: rem(20px);
												>* {
													width: rem(17px);
													height: rem(16px);
													margin-right: 3px;
													background-repeat: no-repeat;
													background-position: center;
													background-size: contain;
													opacity: 1;
													transform: translateX(0);
												}
												&.r00 {
													display: none;
													>*:nth-child(1) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(2) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(3) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
												}
												&.r05 {
													>*:nth-child(1) { background-image: url('/css/assets/star-half.svg'); }
													>*:nth-child(2) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(3) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
												}
												&.r10 {
													>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(2) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(3) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
												}
												&.r15 {
													>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(2) { background-image: url('/css/assets/star-half.svg'); }
													>*:nth-child(3) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
												}
												&.r20 {
													>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(3) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
												}
												&.r25 {
													>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(3) { background-image: url('/css/assets/star-half.svg'); }
													>*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
												}
												&.r30 {
													>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(3) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
													>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
												}
												&.r35 {
													>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(3) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(4) { background-image: url('/css/assets/star-half.svg'); }
													>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
												}
												&.r40 {
													>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(3) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(4) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
												}
												&.r45 {
													>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(3) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(4) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(5) { background-image: url('/css/assets/star-half.svg'); }
												}
												&.r50 {
													>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(3) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(4) { background-image: url('/css/assets/star-active.svg'); }
													>*:nth-child(5) { background-image: url('/css/assets/star-active.svg'); }
												}
											}
										}
										.products-item-like {
											font-family: 'Arvo';
											font-size: rem(15px);
											color: $pink;
											position: relative;
											display: flex;
											align-items: center;
											line-height: rem(23px);
											opacity: 1;
											transform: translateY(0);
											&.favourite {
												pointer-events: none;
												&:before {
													background-image: url('/css/assets/heart-pink.svg');
												}
											}
											&:before {
												content: '';
												display: inline-block;
												width: rem(17px);
												height: rem(15px);
												margin-right: rem(7px);
												background-repeat: no-repeat;
												background-size: contain;
												background-position: center;
												background-image: url('/css/assets/heart.png');
											}
											&:hover
											{
												&:before {
													animation: beat .25s infinite alternate;
												}
											}
										}
									}
									.bottom-section {
										margin-top: rem(12px);
										font-family: 'Arvo';
										font-size: rem(13px);
										color: $gray-dark;
										line-height: 1.3;
										line-clamp: 2;
										-webkit-line-clamp: 2;
										-webkit-box-orient: vertical;
										display: -webkit-box;
										text-overflow: ellipsis;
										max-height: rem(34px);
										overflow: hidden;
										opacity: 1;
										transform: translateY(0);
									}
								}
							}
						}
					}
				}
			}
		}
		.catalogos-wrapper {
			width: calc(100% - 1.7rem);
			margin-left: 0;
			
			.catalogos-title {
				font-family: 'Arvo';
				font-weight: bold;
				font-size: rem(35px);
				color: $colorCorporative;
				display: flex;
				align-items: center;
				&:after {
					content: '';
					flex-grow: 1;
					height: 1px;
					background-color: $c-gray2;
					margin-left: rem(15px);
				}
			}
			.catalogos-slider {
				padding: rem(40px) 0;
				&.isVisible.isLoaded {
					.slick-slide {
						.catalogo-item {
							opacity: 1;
							transform: translateX(0);
							transition: opacity $t-fast linear 0.6s, transform $t-normal $easeOutCubic 0.6s;
						}
						&:nth-child(1) .catalogo-item { transition: opacity $t-fast linear 0s, transform $t-normal $easeOutCubic 0s; }
						&:nth-child(2) .catalogo-item { transition: opacity $t-fast linear 0.1s, transform $t-normal $easeOutCubic 0.1s; }
						&:nth-child(3) .catalogo-item { transition: opacity $t-fast linear 0.2s, transform $t-normal $easeOutCubic 0.2s; }
						&:nth-child(4) .catalogo-item { transition: opacity $t-fast linear 0.3s, transform $t-normal $easeOutCubic 0.3s; }
						&:nth-child(5) .catalogo-item { transition: opacity $t-fast linear 0.4s, transform $t-normal $easeOutCubic 0.4s; }
						&:nth-child(6) .catalogo-item { transition: opacity $t-fast linear 0.5s, transform $t-normal $easeOutCubic 0.5s; }
					}
				}
				.slick-track {
					display: flex;
					justify-content: space-between;
					min-width: 100%;
					.slick-slide {
						width: auto !important;
					}
				}
				.slick-next, .slick-prev {
					width: rem(30px);
					height: rem(30px);
					transition: all $t-fast linear;
					&.slick-disabled {
						opacity: 0;
					}
					&:before {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: rem(12px);
						height: rem(12px);
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center;
					}
				}
				.slick-prev:before {
					background-image: url('/css/assets/slider2-left.svg');
				}
				.slick-next:before {
					background-image: url('/css/assets/slider2-right.svg');
				}
				.catalogo-item {
					position: relative;
					display: block;
					cursor: pointer;
					min-width: rem(150px);
					width: rem(150px);
					height: rem(150px);
					margin: rem(30px) 0;
					overflow: visible;
					outline: 0;
					opacity: 0;
					transform: translateX(5rem);
					&:before {
						content: '';
						position: absolute;
						z-index: -1;
						background-color: #fff;
						border-radius: 100%;
						width: 80%;
						height: 80%;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						transition: transform $t-fast $easeOutCubic;
					}
					&:hover {
						&:before {
							transform: translate(-50%, -50%) scale(1.2);
							transition: transform $t-normal $easeOutCubic;
						}
					}
					.catalogo-image {
						position: absolute;
						left: 50%;
						top: 40%;
						width: 100%;
						height: 70%;
						transform: translate(-50%, -50%);
						object-position: center;
						object-fit: contain;
					}
					.catalogo-name {
						position: absolute;
						bottom: calc(10% + #{rem(5px)});
						font-size: rem(13px);
						font-weight: bold;
						text-transform: uppercase;
						color: $gray-dark;
						width: 100%;
						left: 50%;
						transform: translateX(-50%);
						text-align: center;
					}
					.catalogo-extension {
						position: absolute;
						top: 90%;
						font-size: rem(13px);
						font-weight: 500;
						text-transform: uppercase;
						display: flex;
						align-items: center;
						justify-content: center;
						color: $gray-dark;
						width: 100%;
						text-align: center;
						&:before {
							content: '';
							width: rem(15px);
							height: rem(15px);
							margin-right: rem(5px);
							background-repeat: no-repeat;
							background-size: contain;
							background-position: center;
							background-image: url('/css/assets/down-arrow.svg');
						}
					}
				}
			}
		}
		@include media-breakpoint-down(sm) {
			.module-menu-sm {
				display: block;
				position: relative;
				width: 100vw;
				left: 50%;
				transform: translateX(-50%);
				margin-bottom: 1rem;
				&:before {
					content: '';
					position: absolute;
					z-index: -1;
					top: rem(51px);
					left: 0;
					width: 100%;
					height: 2px;
					background-color: $c-gray2;
				}

			}
			.module-list-header {
				border: none;
				margin: 0;
				padding: 0;
				& > *:not(.search-video-box){
					display: none;
				}
				&:not(.show-search-video-box){
					display: none;
				}
			}
			 .module-list-wrapper {
				 flex-direction: column-reverse;
				 overflow: hidden;
				 margin-left: calc(#{$lateral-space-sm} * -1) !important;
				width: calc(100% + (#{$lateral-space-sm} * 2));
				padding-left: $lateral-space-sm;
				padding-right: $lateral-space-sm;
				 &:before {
					 display: none;
				 }
				.module-list-lateral {
					width: 100%;
					min-width: 100%;
					.sidebar-categories {
						display: none;
					}
					.module-lateral-extra {
						margin-top: rem(60px);
						position: relative;
						border-top: 0;
						padding: 0 rem(20px);
						&:before, &:after {
							content: '';
							position: absolute;
							top: 0;
						}
						&:before {
							left: 0;
							height: 1px;
							width: calc(100% - #{rem(56px)} - #{$lateral-space-sm});
							background-color: $c-gray2;
						}
						&:after {
							right: $lateral-space-sm;
							transform: translateY(-50%);
							width: rem(56px);
							height: rem(70px);
							background-repeat: no-repeat;
							background-size: contain;
							background-position: left;
							background-image: url('/css/assets/quote.svg');
						}
						.module-lateral-title {
							padding-top: rem(45px);
							line-height: 1.12;
						}
					}
				}
				.module-list-container {
					width: 100%;
					min-width: 100%;
					padding-left: 0;
					display: flex;
					flex-direction: column;
					.hashtags {
						order: 1;
						margin-top: rem(25px);
					}
					.module-list-header {
						display: flex;
						order: 2;
						padding-top: rem(25px);
						margin-bottom: rem(20px);
					}
					.categories-list,
					.products-list {
						padding-bottom: 0;
					}
					.categories-list {
						width: calc(100% + #{$lateral-space-sm});
						.categories-item {
							position: relative;				
							height: 60vw;
							max-height: rem(380px);
							width: calc(100% - #{$lateral-space-sm}) !important;
							margin-right: $lateral-space-sm;
							margin-bottom: $lateral-space-sm;
							.hashtag-list {
								display: block;
								bottom: rem(10px);
								top: unset;
								transform: unset;
								padding: 0 rem(10px);
								.hashtag-item {
									margin-right: rem(15px);
								}
							}
							.categories-item-image {
								bottom: unset;
								top: 0;
								height: calc(100% - #{rem(60px)});
							}
							.categories-item-header {
								position: absolute;
								bottom: 0;
								width: 100%;
								border-bottom: 0;
								height: rem(60px);
								padding: rem(10px);
								.top-section {
									flex-direction: row;
									.categories-item-name {
										font-size: rem(15px);
									}
									.categories-item-like {
										font-size: rem(13px);
									}
								}
							}
						}
					}
					.products-list {
						order: 3;
						width: calc(100% + #{$lateral-space-sm}) !important;
						&.list-mode {
							.products-item{
								margin-right: $lateral-space-sm;
								margin-bottom: $lateral-space-sm;
								width: 100%;
								&:nth-child(odd) {
									margin-right: $lateral-space-sm;
								}
								&.image-loaded {
									.products-item-special {
										display: none;
									}
									.products-item-header {
										&:after {
											display: block;
										}
										.top-section {
											.products-item-info {
	
											}
											.products-item-like {
												display: none;
											}
										}
									}
								}
							}
						}
						&.grid-mode {
							.products-item {
								height: rem(235px);
								margin-bottom: $lateral-space-sm;
								margin-right: $lateral-space-sm;
								width: calc(100% / 2 - #{$lateral-space-sm});
								&.double {
									width: calc(100% - #{$lateral-space-sm});
									.products-item-header {
										width: 100%;
									}
								}
								&.image-loaded {
									.products-item-image {
										position: absolute;
										bottom: 0;
										left: 0;
										width: 100%;
										height: 100%;
										opacity: 1;
										img {
											object-position: center;
											object-fit: cover;
											width: 100%;
											height: 100%;
										}
									}
									.products-item-special {
										font-size: rem(9px);
										bottom: rem(60px);
									}
									.products-item-header {
										height: rem(60px);
										padding: rem(12px) rem(10px);
										.top-section {
											white-space: nowrap;
											display: flex;
											height: 100%;
											.products-item-info {
												flex-direction: column;
												align-items: flex-start;
												justify-content: space-between;
												.products-item-name {
													font-size: rem(15px);
													margin-right: 0;
													width: 100%;
													line-height: 1.15;
												}
												.products-item-rating {
													display: flex;
													margin-left: 0;
													position: absolute;
													left: rem(10px);
													bottom: rem(10px);
													>* {
														width: rem(13px);
														height: rem(12px);
													}
												}
											}
											.products-item-like {
												position: absolute;
												right: rem(10px);
												bottom: rem(10px);
												font-size: rem(13px);
												align-items: flex-end;
												line-height: 1;
												&:before {
													content: '';
													display: inline-block;
													width: rem(15px);
													height: rem(13px);
												}
											}
										}
										.bottom-section {
											display: none;
										}
									}
								}
							}
						}
					}
				}
			}
			.catalogos-wrapper {
				width: 100%;
				margin-top: 1rem;
				.catalogos-title {
					position: relative;
					font-size: rem(25px);
					&:after {
						position: absolute;
						margin-left: 0;
						bottom: rem(-10px);
						width: 100%;
					}
					&:before {
						content: '';
						position: absolute;
						height: rem(30px);
						width: 1px;
						background-color: $c-gray2;
						right: rem(30px);
					}
				}
				.catalogos-slider {
					.slick-next, .slick-prev {
						top: rem(-15px);
					}
					.slick-prev {
						left: unset;
						right: rem(30px);
					}
					.slick-next {
						right: 0;
					}
					.slick-list {
						width: 100vw;
						overflow: hidden;
						margin-left: rem(-10px);
						padding-left: rem(10px);
					}
					.catalogo-item {
						min-width: 40vw;
						width: 40vw;
						height: 40vw;
						.catalogo-name,
						.catalogo-extension {
							font-size: rem(11px);
						}
					}
				}
			}
		}
	}
/**** END OLD ****/


// CATALOGUE
	&.catalogue_2021{
		max-width: 1480px !important;

		.module-list-fancy {
			padding-top: 4rem;

			.module-list-wrapper .module-list-container .products-list.grid-mode{
				grid-auto-rows: 35rem;
				grid-gap: 2rem;
				-ms-grid-columns: auto 2rem auto;
				-ms-grid-rows: 35rem 1.5rem 35rem 1.5rem 35rem;			
				grid-template-columns: repeat(4, auto);
				grid-template-rows: repeat(4, 35rem);

				.products-item{
					width: auto;
					height: auto;
					min-height: 35rem;
					margin: 0;
					position: relative;

					.bg-img{
						position: absolute;
						top: 0;
						width: 100%;
						height: 100%;
						background-size: cover;
						z-index: 1;
						background-repeat: no-repeat;
						overflow: hidden;

						&:hover{
							&::after{
								transform: translateY(-50%) translateX(-50%) scale(1.1);
								transition: 0.4s transform ease-in;
							}
						}

						&::after{
							content: '';
							background-image: url(/images/btn-play.svg);
							top: 50%;
							left: 50%;
							position: absolute;
							width: 6.8rem;
							height: 6.8rem;
							background-position: center;
							background-repeat: no-repeat;
							background-size: contain;
							opacity: 0.6;
							transform: translateY(-50%) translateX(-50%) scale(1);
							transition: 0.4s transform ease-in;
							
						}
						&::before{
							content: "";
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
							background-color: rgba(0, 0, 0, 0.5);
						}
					}

					&.no-image.image-loaded .products-item-header .bottom-section{					
						max-height: none!important;
						display: block!important;				
						font-size: 1.5rem;
						text-align: center;
					}
				}
			}
			.module-list-wrapper{
				padding-left: 0;
				width: 100%;
				.module-list-container{
					padding-left: 0;
    				padding-right: 0;
				}
			}
			.pattern-video-item{
				&.is-video{
					position: fixed;
					z-index: -1;
					display: none;
					width: 100%;
					height: 100vh;
					top: 0;
					left: 0;
					min-height: unset;
	
					&::before{
						content: "";
						position: absolute;
						width: 0;
						height: 0;
						top: 0;
						left: 0;
						background-color: rgba(0, 0, 0, 0.5);
					}
	
					iframe{
						background-color: #fff;
						border: 0;
						width: 0% !important;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
					.close{
						position: absolute;
						width: 0;
						height: 4rem;
						top: 3rem;
						right: 3rem;
						background-image: url('/images/close-white.svg');
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						z-index: 2000;
	
					}
	
					&.is-open{
						position: fixed;
						z-index: 1000;
						display: flex;
	
						&::before{
							width: 100%;
							height: 100%;
						}
	
						iframe{
							width: 80% !important;
							background-color: transparent;
						}
						.close{
							width: 4rem;
						}
					}
				}
			}
			
		}

		@media screen and (max-width: $sm-width){
			.module-list-fancy{
				padding-top: 0;
				
				.module-list-wrapper{
					// padding-left: 1.5rem;
    				// padding-right: 1.5rem;
					padding-left: 0rem;
					padding-right: 0rem;
					width: 100% !important;
					transform: translateX(0);

					&.outTransform{
						transform: unset !important;
					}

					.module-list-container .products-list.grid-mode{
						width: 100% !important;
						display: -ms-grid;
						display: grid;
						grid-gap: 1.5rem;
						-ms-grid-columns: auto 1.5rem auto;
						grid-template-columns: repeat(2, auto);
						-ms-grid-rows: 30rem 1.5rem 30rem 1.5rem 30rem;
						grid-template-rows: repeat(3, 30rem);
						padding: 2rem 0 2rem;
						
						.products-item{
							width: auto;
							height: auto;
							min-height: 100%;
							margin: 0;

						
						}
					}
				} 
				.module-list-wrapper{
				
					.module-list-container{
						padding-left: 0;
						padding-right: 0;
					}
				}
				.pattern-video-item.is-video{
					&.is-open{
						iframe{
							width: 100% !important;
							aspect-ratio: 16/9;
							height: auto;
						}
					}
				}
			}
		}
		
	}
//END CATALOGUE

.module-list-fancy {
	.module-menu-sm {
		display: none;
	}
	.module-list-header {
		display: flex;
        width: 100%;
		align-items: flex-end;
        justify-content: flex-end;
		border-bottom: 1px solid $c-gray2;
		padding-bottom: .5rem;
		padding-top: 5.5rem;
		padding-left: 1rem;
		padding-right: 1rem;
		margin-bottom: 4.5rem;
		.breadcrumb {
			flex-grow: 1;
		}
		.button-container {
			white-space: nowrap;
			.button-grid,
			.button-list {
				width: 3.4rem;
				height: 3.4rem;
				border-radius: 3px;
				cursor: pointer;
				outline: none;
				border: 0;
				position: relative;
				background-color: transparent;
				transition: all $t-fast linear;
				&:after {
					content: '';
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 1.4rem;
					height: 1.4rem;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
				}
				&:hover {
					background-color: #fff;
				}
				&.isActive {
					background-color: #fff;
					pointer-events: none;
				}
			}
			.button-grid {
				&:after {
					background-image: url('/css/assets/grid.svg');
				}
				&.isActive {
					&:after {
						background-image: url('/css/assets/grid-active.svg');
					}
				}
			}
			.button-list {
				margin-left: .5rem;
				&:after {
					background-image: url('/css/assets/list.svg');
				}
				&.isActive {
					&:after {
						background-image: url('/css/assets/list-active.svg');
					}
				}
			}
		}
	}
	.module-list-wrapper {
		display: flex;
		flex-wrap: nowrap;
		padding-left: 2rem;
		width: calc(100% + 1.7rem);
		position: relative;
		.ballon-list {
			position: absolute;
			z-index: -1;
			height: 44.5rem;
			width: 44.5rem;
			background-color: #fff;
			border-radius: 100%;
			top: 10rem;
			left: -16.5rem;
			opacity: 0;
			transition: opacity $t-normal linear, transform $t-fast $easeOutCubic;
		}
		.module-list-lateral {
			width: 25rem;
			min-width: 25rem;
			padding-bottom: 5rem;
			.module-list-lateral-inner {
				position: sticky;
				top: calc(#{$topbar-height} + 10rem);
				.sidebar-categories{
					
					position: -moz-sticky;
					position: -o-sticky;
					position: -ms-sticky;
					position: sticky;
					position: -webkit-sticky;
					
					top: 11.8rem;
					padding-top: calc(7.3rem - 2.7rem);

				}
			}
			.module-lateral-extra {
				.module-lateral-title {
					padding-top: 10rem;
					font-weight: normal;
					font-family: 'Montserrat-Bold';
					font-size: 2.5rem;
					color: $colorCorporative;
					line-height: 1.22;
					position: relative;
					padding-bottom: 2rem;
					&:before {
						content: '';
						position: absolute;
						bottom: 0;
						left: 0;
						width: 8rem;
						height: 5px;
						background-color: $colorCorporative;
					}
				}
				.module-lateral-text {
					padding-top: 2.5rem;
					font-size: 1.3rem;
					line-height: 1.77;
					color: $gray-dark;
				}
				img {
					max-width: 20rem;
					margin-top: 4.5rem;
				}
			}
		}
		.module-list-container {
			padding-left: 4.5rem;
			width: calc(100% - 25rem);
			min-width: calc(100% - 25rem);
			flex-grow: 1;
			.module-list-header {
				display: none;
			}
			.categories-list,
			.products-list {
			 	display: flex;
				flex-wrap: wrap;
				position: relative;
				padding-bottom: 13rem;
			}
			.categories-list {
				.categories-item {
					position: relative;
					height: 33.5rem;
					width: calc(100% / 3 - 1.7rem);
					margin-right: 1.7rem;
					margin-bottom: 1.7rem;
					&.double {
						width: calc((100% / 3) * 2 - 1.7rem);
					}
					&:hover {
						.categories-item-image {
							img {
								transform: scale(1.1) translate(-45%, -45%);
							}
						}
					}
					.categories-item-image {
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: calc(100% - 10rem);
						overflow: hidden;
						img {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							object-position: center;
							object-fit: cover;
							width: 100%;
							height: 100%;
							transform-origin: center;
							transition: transform 1s $easeOutCubic;
						}
					}
					.categories-item-header {
						position: relative;
						z-index: 1;
						height: 10rem;
						border-bottom: 5px solid $c-gray2;
						padding: 2rem;
						background-color: #fff;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						.top-section {
							display: flex;
							//white-space: nowrap;
							align-items: baseline;
							.categories-item-name {
								flex-grow: 1;
								text-overflow: ellipsis;
								padding-right: .5rem;
								font-weight: 500;
								font-size: 2.1rem;
								color: $gray-dark;
								line-height: 1.05;
								max-height: 5rem;
								overflow: hidden;
							    -webkit-line-clamp: 2;
							    display: -webkit-box;
							    -webkit-box-orient: vertical;
							}
							.categories-item-like {
								font-family: 'Arvo';
								font-size: 1.5rem;
								color: $pink;
								position: relative;
								display: flex;
    							align-items: center;
    							line-height: 2.3rem;
								&:before {
									content: '';
									display: inline-block;
									width: 1.7rem;
									height: 1.5rem;
									margin-right: .7rem;
									background-repeat: no-repeat;
									background-size: contain;
									background-position: center;
									background-image: url('/css/assets/heart-pink.svg');
								}
							}
						}
					
					}
					.hashtag-list {
						position: absolute;
						z-index: 1;
						width: 100%;
						top: 8rem;
						transform: translateY(-100%);
						padding: 0 2rem;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						opacity: 0.5;
						.hashtag-item {
							font-size: 1.1rem;
							color: $gray-dark;
							font-weight: 500;
							margin-right: 2rem;
							cursor: pointer;
							text-decoration: underline;
							&:hover {
								text-decoration: none;
							}
						}
					}
				}
			}
			.products-list {
				.products-item {
					position: relative;
					overflow: hidden;
					>a {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						opacity: 0;
						transition: opacity $t-fast linear;
					}
					.products-item-image {
						opacity: 0;
						img {
							transform: translate(-50%, -50%) scale(0.9);
						}
					}
					.products-item-special {
						bottom: 1.7rem;
					}
					.products-item-header {
						transform: translateY(100%);
						border-top: 0;
						width: 0;
						&:after {
							opacity: 0;
							transform: translateX(-3rem);
						}
						.top-section {
							.products-item-info {
								.products-item-name {
									opacity: 0;
									transform: translateY(3rem);
								}
								.products-item-rating {
									>* {
										opacity: 0;
										transform: translateX(1.5rem);
									}
								}
							}
							.products-item-like {
								opacity: 0;
								transform: translateY(-3rem);
							}
						}
						.bottom-section {
							opacity: 0;
							transform: translateY(3rem);
						}
					}
				}
				&.list-mode {
					width: calc(100% + 6rem);
					.products-item {
						>a {
							transition: opacity $t-fast linear calc(1.5s + 0s);
						}
						.products-item-header {
							transition: width $t-normal $easeOutCubic calc(1.5s + 0.2s), background-color $t-fast linear 0s;
							&:after {
								transition: opacity $t-fast linear calc(1.5s + 0.8s), transform $t-normal $easeOutCubic calc(1.5s + 0.8s);
							}
							.top-section {
								.products-item-info {
									.products-item-name {
										transition: opacity $t-fast linear calc(1.5s + 0.6s), transform $t-normal $easeOutCubic calc(1.5s + 0.6s);
									}
								}
								.products-item-like {
									transition: opacity $t-fast linear calc(1.5s + 0.8s), transform $t-normal $easeOutCubic calc(1.5s + 0.8s);
								}
							}
							.bottom-section {
								transition: opacity $t-fast linear calc(1.5s + 0.7s), transform $t-normal $easeOutCubic calc(1.5s + 0.7s);
							}
						}
						$delay: 0.05s;
						@for $i from 1 through 30 {
							&.order-#{$i} {
								>a {
									transition: opacity $t-fast linear calc(0s + (#{$i} * #{$delay}));
								}
								.products-item-header {
									transition: width $t-normal $easeOutCubic calc(0.2s + (#{$i} * #{$delay})), background-color $t-fast linear 0s;
									&:after {
										transition: opacity $t-fast linear calc(0.8s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.8s + (#{$i} * #{$delay}));
									}
									.top-section {
										.products-item-info {
											.products-item-name {
												transition: opacity $t-fast linear calc(0.6s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.6s + (#{$i} * #{$delay}));
											}
										}
										.products-item-like {
											transition: opacity $t-fast linear calc(0.8s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.8s + (#{$i} * #{$delay}));
										}
									}
									.bottom-section {
										transition: opacity $t-fast linear calc(0.7s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.7s + (#{$i} * #{$delay}));
									}
								}
							}
						}
					}
					.products-item {
						position: relative;
						height: 4.7rem;
						margin-bottom: 1.7rem;
						width: calc(50% - 6rem);
						margin-right:6rem;
						&.image-loaded {
							>a {
								opacity: 1;
								&:hover {
									.products-item-header {
										background-color: #f4f4f4;
									}
								}
							}
							.products-item-image {
								position: absolute;
								bottom: 0;
								left: 0;
								width: 4.7rem;
								height:4.7rem;
								opacity: 1;
								img {
									object-position: center;
									object-fit: cover;
									width: 100%;
									height: 100%;
									transform: none;
								}
							}
							.products-item-special {
								display: none;
							}
							.products-item-header {
								position: absolute;
								top: 0;
								left: 4.7rem;
								z-index: 1;
								height: 100%;
								background-color: #fff;
								display: flex;
								flex-direction: column;
								justify-content: space-between;
								padding: .6rem 0;
								width: calc(100% - 4.7rem);
								opacity: 1;
								transform: translateY(0);
								&:after {
									display: none;
									content: '';
									position: absolute;
									right: rem(10px);
									top: 50%;
									width: .7rem;
									height: 1.2rem;
									transform: translateY(-50%);
									background-repeat: no-repeat;
									background-size: contain;
									background-position: center;
									background-image: url('/css/assets/arrow-simple-grey.svg');
									opacity: 1;
									transform: translate(0, -50%);
								}
								.top-section {
									white-space: nowrap;
									display: flex;
									padding: 0 1rem;
									.products-item-info {
										flex-grow: 1;
										white-space: nowrap;
										display: flex;
										align-items: center;
										padding-right: 4rem;
										.products-item-name {
											font-weight: 500;
											color: $gray-dark;
											font-size: 1.5rem;
											opacity: 1;
											transform: translateY(0);
										}
										.products-item-rating {
											display: none;
										}
									}
									.products-item-like {
										position: absolute;
										z-index: 1;
										top: 50%;
										right: 0;
										height: 100%;
										margin-right: 1rem;
										font-family: 'Arvo';
										font-size: 1.5rem;
										color: $pink;
										display: flex;
		    							align-items: center;
		    							line-height: 2.3rem;
		    							opacity: 1;
										transform: translate(0, -50%);
										&.favourite {
											pointer-events: none;
											&:before {
												background-image: url('/css/assets/heart-pink.svg');
											}
										}
										&:before {
											content: '';
											pointer-events: none;
											display: inline-block;
											width: 1.7rem;
											height: 1.5rem;
											margin-right: .7rem;
											background-repeat: no-repeat;
											background-size: contain;
											background-position: center;
											background-image: url('/css/assets/heart.png');
										}
										&:hover
								        {
								        	&:before {
								            	animation: beat .25s infinite alternate;
								        	}
								        }
									}
								}
								.bottom-section {
									padding: 0 1rem;
									font-family: 'Arvo';
									font-size: 1.2rem;
									color: $gray-dark;
									line-height: 1.3;
									opacity: 0.5;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									padding-right: 8rem;
									opacity: 1;
									transform: translateY(0);
								}
							}
						}
					}
				}
				&.grid-mode {
					.products-item {
						$delay: 0.05s;
						@for $i from 1 through 30 {
							&:nth-child(#{$i}) {
								.products-item-special {
									transition: bottom $t-normal $easeOutCubic calc(0.9s + (#{$i} * #{$delay}));
								}
								.products-item-header {
									transition: transform $t-normal $easeOutCubic calc(0.2s + (#{$i} * #{$delay})), border $t-fast $easeOutCubic calc(0.8s + (#{$i} * #{$delay}));
									.top-section {
										.products-item-info {
											.products-item-name {
												transition: opacity $t-fast linear calc(0.6s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.6s + (#{$i} * #{$delay}));
												.products-item-rating {
													>* {
														&:nth-child(1) { transition: opacity $t-fast linear calc(1s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(1s + (#{$i} * #{$delay})); }
														&:nth-child(2) { transition: opacity $t-fast linear calc(1.05s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(1.05s + (#{$i} * #{$delay})); }
														&:nth-child(3) { transition: opacity $t-fast linear calc(1.1s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(1.1s + (#{$i} * #{$delay})); }
														&:nth-child(4) { transition: opacity $t-fast linear calc(1.15s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(1.15s + (#{$i} * #{$delay})); }
														&:nth-child(5) { transition: opacity $t-fast linear calc(1.2s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(1.2s + (#{$i} * #{$delay})); }
													}
												}
											}
										}
										.products-item-like {
											transition: opacity $t-fast linear calc(0.8s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.8s + (#{$i} * #{$delay}));
										}
									}
									.bottom-section {
										transition: opacity $t-fast linear calc(0.7s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.7s + (#{$i} * #{$delay}));
									}
								}
							}
						}
					}
					.products-item {
						position: relative;
						height: 33.5rem;
						width: calc(100% / 2 - 1.7rem);
						margin-right: 1.7rem;
						margin-bottom: 1.7rem;
						order: unset !important;
						&.double {
							width: calc(100% - 1.7rem);
							.products-item-header {
								width: calc(50% - (1.7rem / 2));
							}
						}
						&.is-new {
							.products-item-special {
								display: flex;
							}
						}
						&.image-loaded {
							>a {
								opacity: 1;
								&:hover {
									.products-item-image {
										img {
											transform: scale(1.1) translate(-45%, -45%);
										}
									}
								}
							}
							.products-item-image {
								position: absolute;
								bottom: 0;
								left: 0;
								width: 100%;
								height: 100%;
								overflow: hidden;
								opacity: 1;
								img {
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									object-position: center;
									object-fit: cover;
									width: 100%;
									height: 100%;
									transform-origin: center;
									transition: transform 1s $easeOutCubic;
								}
							}
							.products-item-special {
								position: absolute;
								z-index: 1;
								left: 1rem;
								height: 1.7rem;
								padding: 0 .6rem;
								align-items: center;
								background-color: $c-gray2;
								text-transform: uppercase;
								font-size: 1.1rem;
								font-weight: bold;
								color: $gray-dark;
								bottom: 10rem;
							}
							.products-item-header {
								position: absolute;
								bottom: 0;
								left: 0;
								z-index: 2;
								width: 100%;
								height: 10rem;
								border-top: 5px solid $c-gray2;
								background-color: #fff;
								display: flex;
								flex-direction: column;
								padding: 1.7rem 1rem 1.2rem 1rem;
								transform: translateY(0);
								&:before {
									content: '';
									position: absolute;
									width: 100%;
								}
								.top-section {
									white-space: nowrap;
									display: flex;
									.products-item-info {
										flex-grow: 1;
										white-space: nowrap;
										display: flex;
										flex-wrap: wrap;
										align-items: center;
										overflow: hidden;
										height: 2.1rem;
										.products-item-name {
											font-weight: 500;
											color: $gray-dark;
											font-size: 2.1rem;
											overflow: hidden;
											text-overflow: ellipsis;
											line-height: 1;
											opacity: 1;
											transform: translateY(0);
										}
										.products-item-rating {
											display: flex;
											margin-left: 2rem;
											>* {
												width: 1.7rem;
												height: 1.6rem;
												margin-right: 3px;
												background-repeat: no-repeat;
												background-position: center;
												background-size: contain;
												opacity: 1;
												transform: translateX(0);
											}
											&.r00 {
												display: none;
												>*:nth-child(1) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(2) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(3) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
											}
											&.r05 {
												>*:nth-child(1) { background-image: url('/css/assets/star-half.svg'); }
												>*:nth-child(2) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(3) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
											}
											&.r10 {
												>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(2) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(3) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
											}
											&.r15 {
												>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(2) { background-image: url('/css/assets/star-half.svg'); }
												>*:nth-child(3) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
											}
											&.r20 {
												>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(3) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
											}
											&.r25 {
												>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(3) { background-image: url('/css/assets/star-half.svg'); }
												>*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
											}
											&.r30 {
												>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(3) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
												>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
											}
											&.r35 {
												>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(3) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(4) { background-image: url('/css/assets/star-half.svg'); }
												>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
											}
											&.r40 {
												>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(3) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(4) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
											}
											&.r45 {
												>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(3) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(4) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(5) { background-image: url('/css/assets/star-half.svg'); }
											}
											&.r50 {
												>*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(3) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(4) { background-image: url('/css/assets/star-active.svg'); }
												>*:nth-child(5) { background-image: url('/css/assets/star-active.svg'); }
											}
										}
									}
									.products-item-like {
										font-family: 'Arvo';
										font-size: 1.5rem;
										color: $pink;
										position: relative;
										display: flex;
		    							align-items: center;
		    							line-height: 2.3rem;
		    							opacity: 1;
		    							transform: translateY(0);
		    							&.favourite {
		    								pointer-events: none;
											&:before {
												background-image: url('/css/assets/heart-pink.svg');
											}
										}
										&:before {
											content: '';
											display: inline-block;
											width: 1.7rem;
											height: 1.5rem;
											margin-right: .7rem;
											background-repeat: no-repeat;
											background-size: contain;
											background-position: center;
											background-image: url('/css/assets/heart.png');
										}
										&:hover
								        {
								        	&:before {
								            	animation: beat .25s infinite alternate;
								        	}
								        }
									}
								}
								.bottom-section {
									margin-top: 1.2rem;
									font-family: 'Arvo';
									font-size: 1.3rem;
									color: $gray-dark;
									line-height: 1.3;
									line-clamp: 2;
									-webkit-line-clamp: 2;
									-webkit-box-orient: vertical;
									display: -webkit-box;
									text-overflow: ellipsis;
									max-height: 3.4rem;
									overflow: hidden;
									opacity: 1;
									transform: translateY(0);
								}
							}
						}
					}
				}
			}
		}
	}
	.catalogos-wrapper {
		width: 100%;
		.catalogos-title {
			font-family: 'Arvo';
			font-weight: bold;
			font-size: 3.5rem;
			color: $colorCorporative;
			display: flex;
			align-items: center;
			&:after {
				content: '';
				flex-grow: 1;
				height: 1px;
				background-color: $c-gray2;
				margin-left: 1.5rem;
			}
		}
		.catalogos-slider {
			padding: 4rem 0;
			&.isVisible.isLoaded {
				.slick-slide {
					.catalogo-item {
						opacity: 1;
						transform: translateX(0);
						transition: opacity $t-fast linear 0.6s, transform $t-normal $easeOutCubic 0.6s;
					}
					&:nth-child(1) .catalogo-item { transition: opacity $t-fast linear 0s, transform $t-normal $easeOutCubic 0s; }
					&:nth-child(2) .catalogo-item { transition: opacity $t-fast linear 0.1s, transform $t-normal $easeOutCubic 0.1s; }
					&:nth-child(3) .catalogo-item { transition: opacity $t-fast linear 0.2s, transform $t-normal $easeOutCubic 0.2s; }
					&:nth-child(4) .catalogo-item { transition: opacity $t-fast linear 0.3s, transform $t-normal $easeOutCubic 0.3s; }
					&:nth-child(5) .catalogo-item { transition: opacity $t-fast linear 0.4s, transform $t-normal $easeOutCubic 0.4s; }
					&:nth-child(6) .catalogo-item { transition: opacity $t-fast linear 0.5s, transform $t-normal $easeOutCubic 0.5s; }
				}
			}
			.slick-track {
				display: flex;
				justify-content: space-between;
				min-width: 100%;
				.slick-slide {
					width: auto !important;
				}
			}
			.slick-next, .slick-prev {
				width: 3rem;
				height: 3rem;
				transition: all $t-fast linear;
				&.slick-disabled {
					opacity: 0;
				}
				&:before {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 1.2rem;
					height: 1.2rem;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
				}
			}
			.slick-prev:before {
				background-image: url('/css/assets/slider2-left.svg');
			}
			.slick-next:before {
				background-image: url('/css/assets/slider2-right.svg');
			}
			.catalogo-item {
				position: relative;
				display: block;
				cursor: pointer;
				min-width: 15rem;
				width: 15rem;
				height: 15rem;
				margin: 3rem 0;
				overflow: visible;
				outline: 0;
				opacity: 0;
				transform: translateX(5rem);
				&:before {
					content: '';
					position: absolute;
					z-index: -1;
					background-color: #fff;
					border-radius: 100%;
					width: 80%;
					height: 80%;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					transition: transform $t-fast $easeOutCubic;
				}
				&:hover {
					&:before {
						transform: translate(-50%, -50%) scale(1.2);
						transition: transform $t-normal $easeOutCubic;
					}
				}
				.catalogo-image {
					position: absolute;
					left: 50%;
					top: 40%;
					width: 100%;
					height: 70%;
					transform: translate(-50%, -50%);
					object-position: center;
					object-fit: contain;
				}
				.catalogo-name {
					position: absolute;
					bottom: calc(10% + .5rem);
					font-size: 1.3rem;
					font-weight: bold;
					text-transform: uppercase;
					color: $gray-dark;
					width: 100%;
					left: 50%;
					transform: translateX(-50%);
					text-align: center;
				}
				.catalogo-extension {
					position: absolute;
					top: 90%;
					font-size: 1.3rem;
					font-weight: 500;
					text-transform: uppercase;
					display: flex;
					align-items: center;
					justify-content: center;
					color: $gray-dark;
					width: 100%;
					text-align: center;
					&:before {
						content: '';
						width: 1.5rem;
						height: 1.5rem;
						margin-right: 0.5rem;
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center;
						background-image: url('/css/assets/down-arrow.svg');
					}
				}
			}
		}
	}
	@media (min-width: 769px) and (max-width: 991px){
		.module-list-wrapper {
			transform: translateX(0) !important;
			padding-right: 0 !important;

			&.outTransform{
				transform: unset !important;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.module-menu-sm {
			display: block;
			position: relative;
			width: 100vw;
			left: 50%;
			transform: translateX(-50%);
			margin-bottom: 1rem;
			&:before {
				content: '';
				position: absolute;
				z-index: -1;
				top: 5.1rem;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: $c-gray2;
			}
		}
		.module-list-header {
            border: none;
            margin: 0;
            padding: 0;
            & > *:not(.search-video-box){
                display: none;
            }
            &:not(.show-search-video-box){
                display: none;
            }
		}
	 	.module-list-wrapper {
	 		flex-direction: column-reverse;
	 		overflow: hidden;	
			margin-left: 0 !important;
			width: calc(100% + (1.5rem * 2));		
			transform: translateX(-1.5rem);
			padding-left: 1.5rem;
			padding-right: 1.5rem;

			.videos-wrapper{
				padding: 0 1.5rem;
			}
			
	 		&:before {
	 			display: none;
	 		}
			.module-list-lateral {
				width: 100%;
				min-width: 100%;
				.sidebar-categories {
					display: none;
				}
				.module-lateral-extra {
					margin-top: 6rem;
					position: relative;
					border-top: 0;
					padding: 0 2rem;
					&:before, &:after {
						content: '';
						position: absolute;
						top: 0;
					}
					&:before {
						left: 0;
						height: 1px;
						width: calc(100% - 5.6rem - #{$lateral-space-sm});
						background-color: $c-gray2;
					}
					&:after {
						right: $lateral-space-sm;
						transform: translateY(-50%);
						width: 5.6rem;
						height: 7rem;
						background-repeat: no-repeat;
						background-size: contain;
						background-position: left;
						background-image: url('/css/assets/quote.svg');
					}
					.module-lateral-title {
						padding-top: 4.5rem;
						line-height: 1.12;
					}
				}
			}
			.module-list-container {
				width: 100%;
				min-width: 100%;
				padding-left: 0;
				display: flex;
				flex-direction: column;
				.hashtags {
					order: 1;
					margin-top: 2.5rem;
				}
				.module-list-header {
					display: flex;
					order: 2;
					padding-top: 2.5rem;
					margin-bottom: 2rem;
				}
				.categories-list,
				.products-list {
					padding-bottom: 0;
				}
				.categories-list {
					width: calc(100% + #{$lateral-space-sm});
					.categories-item {
						position: relative;
						//height: rem(235px);
						height: 60vw;
						max-height: 38rem;
						width: calc(100% - #{$lateral-space-sm}) !important;
						margin-right: $lateral-space-sm;
						margin-bottom: $lateral-space-sm;
						.hashtag-list {
							display: block;
							bottom: 1rem;
							top: unset;
							transform: unset;
							padding: 0 1rem;
							.hashtag-item {
								margin-right: 1.5rem;
							}
						}
						.categories-item-image {
							bottom: unset;
							top: 0;
							height: calc(100% - 6rem);
						}
						.categories-item-header {
							position: absolute;
							bottom: 0;
							width: 100%;
							border-bottom: 0;
							height: 6rem;
							padding: 1rem;
							.top-section {
								flex-direction: row;
								.categories-item-name {
									font-size: 1.5rem;
								}
								.categories-item-like {
									font-size: 1.3rem;
								}
							}
						}
					}
				}
				.products-list {
					order: 3;
					width: calc(100% + #{$lateral-space-sm}) !important;
					&.list-mode {
						.products-item{
							margin-right: $lateral-space-sm;
							margin-bottom: $lateral-space-sm;
							width: 100%;
							&:nth-child(odd) {
								margin-right: $lateral-space-sm;
							}
							&.image-loaded {
								.products-item-special {
									display: none;
								}
								.products-item-header {
									&:after {
										display: block;
									}
									.top-section {
										.products-item-like {
											display: none;
										}
									}
								}
							}
						}
					}
					&.grid-mode {
						.products-item {
							height: 23.5rem;
							margin-bottom: $lateral-space-sm;
							margin-right: $lateral-space-sm;
							width: calc(100% / 2 - #{$lateral-space-sm});
							&.double {
								width: calc(100% - #{$lateral-space-sm});
								.products-item-header {
									width: 100%;
								}
							}
							&.image-loaded {
								.products-item-image {
									position: absolute;
									bottom: 0;
									left: 0;
									width: 100%;
									height: 100%;
									opacity: 1;
									img {
										object-position: center;
										object-fit: cover;
										width: 100%;
										height: 100%;
									}
								}
								.products-item-special {
									font-size: .9rem;
									bottom: 6rem;
								}
								.products-item-header {
									height: 6rem;
									padding: 1.2rem 1rem;
									.top-section {
										white-space: nowrap;
										display: flex;
										height: 100%;
										.products-item-info {
											flex-direction: column;
											align-items: flex-start;
											justify-content: space-between;
											.products-item-name {
												font-size: 1.5rem;
												margin-right: 0;
												width: 100%;
    											line-height: 1.15;
											}
											.products-item-rating {
												display: flex;
												margin-left: 0;
												position: absolute;
												left: 1rem;
												bottom: 1rem;
												>* {
													width: 1.3rem;
													height: 1.3rem;
												}
											}
										}
										.products-item-like {
											position: absolute;
											right: 1rem;
											bottom: 1rem;
											font-size: 1.3rem;
											align-items: flex-end;
											line-height: 1;
											&:before {
												content: '';
												display: inline-block;
												width: 1.5rem;
												height: 1.3rem;
											}
										}
									}
									.bottom-section {
										display: none;
									}
								}
							}
						}
					}
				}
			}
		}
		.catalogos-wrapper {
			margin-top: 1rem;
			.catalogos-title {
				position: relative;
				font-size: 2.5rem;
				&:after {
					position: absolute;
					margin-left: 0;
					bottom: -1rem;
					width: 100%;
				}
				&:before {
					content: '';
					position: absolute;
					height: 3rem;
					width: 1px;
					background-color: $c-gray2;
					right:3rem;
				}
			}
			.catalogos-slider {
				.slick-next, .slick-prev {
					top: -1.5rem;
				}
				.slick-prev {
					left: unset;
					right: 3rem;
				}
				.slick-next {
					right: 0;
				}
				.slick-list {
					width: 100vw;
					overflow: hidden;
					margin-left: -1rem;
					padding-left: 1rem;
				}
				.catalogo-item {
					min-width: 40vw;
					width: 40vw;
					height: 40vw;
					.catalogo-name,
					.catalogo-extension {
						font-size: 1.1rem;
					}
				}
			}
		}
	}
}
	@media (max-width: $md-width) {
		.sidebar-categories{
			top: 0 !important;
			padding-top: 0 !important;
		}
	}
}





