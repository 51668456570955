.section-empresa.sofas.sofas-iguality{
    &.section-three-paragraph-three-img{
        .wrap-content{
            .wrap-up-first{
                position: relative;
                width: 100%;
                .wrap-title{
                    width: 100%;
                    .title{
                        padding-right: 0;     
                        position: relative;
                        padding-bottom: 6rem;                      
                    }
                }
            
                .wrap-img{
                    position: absolute;
                    width: calc(50% - 5rem);
                    margin: 0 auto;
                    right: 0;
                    margin-top: -2rem;
                    .img{
                        margin: 0 auto;
                        width: 26.9rem;
                        height: 24.2rem;
                        object-fit: contain;
                    }
                }
            }
            .part-left, .part-right{
                width: 50%;
            }
            .part-right{
                padding-left: 6rem;
            }
            .part-up{
                width: 100%;
                margin-bottom: 6rem;
                .part-left{
                    .wrap-img{
                        width: 100%;
                        .img{
                            width: 100%;
                        }
                    }
                }
                .part-right{
                    align-items: flex-end;
                    display: flex;
                    position: relative;
                    .wrap-text{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;                    
                    }               
                }
            }
            .part-center{
                margin-bottom: 6rem;
            }
            .part-down{
                .part-right{
                    align-items: flex-end;
                    display: flex;
                }
            }

        }
    }
}

//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {
    .section-empresa.sofas.sofas-iguality{
        &.section-three-paragraph-three-img{
            .wrap-content{
                .generic.text, .generic.text p, .generic{
                    color: inherit;
                    font-size: 1.7rem;
                    line-height: 2.5rem;
                }
                .wrap-up-first{
                    position: relative;
                    width: 100%;
                    .wrap-title{
                        width: 100%;
                        .title{
                            width: 70%;
                            padding-right: 0;     
                            position: relative;
                            padding-bottom: 6rem;                         
                        }
                    }
                
                    .wrap-img{         
                        right: 2.5rem;
                        bottom: 2.5rem;
                        .img{                        
                            width: 14rem;
                            height: 12.6rem;                           
                        }
                    }
                }
                .part-left, .part-right{
                    width: 100%;
                }
                .part-right{
                    padding-left: 0;
                }
                .part-up{
                    width: 100%;
                    margin-bottom: 2.5rem;
                    margin-top: .5rem;
                    flex-direction: column;
                    .part-left{
                        .wrap-img{
                            width: 100%;
                            margin-bottom: 3rem;
                            .img{
                                width: 100%;
                            }
                        }
                    }
                    .part-right{
                        align-items: flex-end;
                        display: flex;
                        position: relative;
                        .wrap-text{
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;                    
                        }               
                    }
                }
                .part-center{
                    flex-direction: column;
                    margin-bottom: 3rem;
                    .wrap-img{
                        margin-top: 3rem;
                    }
                }
                .part-down{
                    flex-direction: column;
                    .part-right{
                        order: 1;
                        align-items: flex-end;
                        display: flex;
                    }
                    .part-left{
                        order: 2;
                        .wrap-img{
                            margin-top: 3rem;
                        }
                    }
                }
    
            }
        }
    }
}