@font-face {
	font-family: 'Roboto';
	src: url('/fonts/Roboto-Regular.ttf');
}

@font-face {
	font-family: 'Arvo';
	src: url('/fonts/Arvo.ttf');
}

@font-face {
	font-family: 'Gotham';
	src: url('/fonts/gotham-light.ttf');	
}


// FAMA NEW 2021
@font-face {
	font-family: 'Montserrat-Regular';
	src: url('/fonts/Montserrat-Regular.ttf');	
}

@font-face {
	font-family: 'Montserrat-Italic';
	src: url('/fonts/Montserrat-Italic.ttf');	
}

@font-face {
	font-family: 'Montserrat-Medium';
	src: url('/fonts/Montserrat-Medium.ttf');	
}

@font-face {
	font-family: 'Montserrat-SemiBold';
	src: url('/fonts/Montserrat-SemiBold.ttf');	
}

@font-face {
	font-family: 'Montserrat-Bold';
	src: url('/fonts/Montserrat-Bold.ttf');	
}

@font-face {
	font-family: 'Montserrat-ExtraBold';
	src: url('/fonts/Montserrat-ExtraBold.ttf');
	
}
