$screen-rt-min: 2560px;

html{
   -webkit-font-smoothing: antialiased !important;
}
body{
	background: #fff;
}
body  .container-wrap{
    max-width: 1280px;
    margin: 0 auto;	
}
body > .container{
    margin: 0;
    padding: 0;
}
body.in-popup{
	overflow: hidden;
}
.bg-white{
	background-color: $colorWhite!important;
}
.container{
	overflow: hidden;
}

a:focus {
	outline: none;
	text-decoration: none;
}
h1,h2,h3,h4,h5
{
	font-family: $fontStack1;
}

.autoscroll{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-y: auto;
}
.content{	
	min-height: 100px;
	overflow: hidden;
	padding: 30px;
}
.col-nopadding{
	padding-left: 0!important;
	padding-right: 0!important;
}

@media (max-width: $screen-sm-max){
	.col-nopadding-sm{
		padding-left: 0!important;
		padding-right: 0!important;
	}
}


.img{
	width:100%;
}

.section-title{
	margin-top: 0;
	text-transform: uppercase;
	a {
		color: #333;
	}
}

.text {
	font-family: $fontStack1;
	font-size: 14px;
	color: #333;
	font-weight: normal;
}

@media (min-width: 768px) {
	.container{		
		max-width: initial;
		width: 100%;	
		margin-bottom: 30px;
		padding-left:$containerLateralMargin;
		padding-right:$containerLateralMargin;
	}
}

html a{
	color: $colorLinkNormal;
}

html a:hover{
	color:$colorLinkHover;
	text-decoration:none;
}

a.linkimg{

	& img	{
		filter: saturate(0);
		transition: filter  0.3s ease-in;
	}

	&:hover img	{
		filter: saturate(100) invert(100);
	}
}

.btn.btn-lg{
	font-size: $defaultFontSize;
}

.section-title{
	font-size: $titleSize;
	font-family: $titleFont;
}

//Sistema de grid
.grid-sizer,
.grid-item {width: 25%;}
.grid-item--width2 {width: 50%;}
.grid-item--width3 {width: 75%;}
.grid-item--width4 {width: 100%;}

@media (max-width: 767px){
	.grid-item {width: 100%}
}

@media (max-width: 1024px) and (min-width:768px){
	.grid-item {width:50%;}
}

.grid-push{
	margin-left: 25%;
}
.grid-push--width2 {margin-left: 50%;}
.grid-push--width3 {margin-left: 75%;}
.grid-push--width4 {margin-left: 100%;}

.font-black {color:black !important;}

.text-box{
	font-size: $defaultFontSize;
	font-family: $fontStack1;
	float: right;
	border-radius: 3px;
	line-height: 120%;
	margin-top: 6px;

	.block &:hover	{
		color: #333;
	}
}
.text-box--gray{
	@extend .text-box;

    background: #CCCCCC;
    color: white;
    right: 10px;
	padding: 1px 6px 1px 6px;

    .block &{
    	color: $colorWhite;
    }
}

.text-box--white{
	@extend .text-box;
    background:white;
	color: red;
	right: 30px;
	padding: 2px 3px 2px 3px;
}

.padding0{
	padding:0px !important;
}

.paddingImg {padding:3px;}

.heart{
	font-size:20px;
	font-weight:normal;
}

.heart--red {color:red;}
.heart--white {color:white;}
.heart--pink {color: $pink;}

.likes-text{
	font-size: $defaultFontSize;
	font-family: $fontStack1;
	font-weight: 500;
	margin-left: 1rem;
    display: flex;

	.likes-count{
		padding-right: .5rem;
	}
	.home &	{
		position: relative;
		top: -2px;
	}
	& a i{
		transition: 0.4s transform cubic-bezier(0.000, 1.650, 1.000, -0.600);
		transform: scale(1);
		vertical-align: middle;
	}
	& a:hover:active i{
		transform: scale(1.4);
	}

	@media (min-width: $screen-md-min) {
		& a:hover i{
			transform: scale(1.4);
		}
	}

}

.likes-text--white {color:white;}

.likes {
	vertical-align: middle;
	font-family: $fontStack1;
	font-weight: 500;
	& a i{
		transition: 0.4s transform cubic-bezier(0.000, 1.650, 1.000, -0.600);
		transform: scale(1);
		vertical-align: middle;
	}
	& a:hover i{
		transform: scale(1.4);
	}
}
.like
{
	& a i{
		transition: 0.4s transform cubic-bezier(0.000, 1.650, 1.000, -0.600);
		transform: scale(1);
		vertical-align: middle;
	}
	& a:hover i{
		transform: scale(1.4);
	}
}

.dashedline--gray {border: 1px dashed #CCCCCC;}
.dashedline--white {border: 1px dashed white;}
.dashedline--black {border: 1px dashed #333;}

.line--white {border: 1px solid white;}
.line--gray {border: 1px solid #CCCCCC;}

.share{
	padding-left: 20px;  
    font-size: 18px;
}

.share--white {color:white;}

.text-black {color:black !important;}


/** GENERIC BLOCKS Item **/

.block a{
	color:$colorLinkNormal;

	text-decoration:none;
	transition: color 0.4s ease-out;
}

.block a:hover{
	color:$colorLinkHover;
	text-decoration:none;
}

.block{
	padding: 5px;
	margin: 0 0 25px;
	overflow: hidden;
	@media (min-width: $screen-md-min){
		border-left: 1px solid #CCC;
		box-shadow: 2px 0 0 #CCC, 0 2px 0 #CCC;
	}

    padding: 5px;
	position: relative;
	border-radius: 5px;
	background-color: #FFF;
	height: 494px - (( 12px - 1px - 5px) * 2);

	.desc	{
		padding: 5px 10px 15px;
		font-size: 14px;
   		font-family: $fontStack1;
	}

	&.block--white{
		background-color: #FFF;
	}
	&.block--red{
		background-color: #ED0000;
		color: $colorWhite;

		& .likes-text,
		& .share
		{
			color: $colorWhite;
		}

		& .text-box--white
		{
			color: #ED0000;
		}

	}

	&.block--orange	{

		background-color: #FF5C26;
		color: $colorWhite;

		& .item-concursos-title
		{
			color: #333;
		}

		& .likes-text,
		& .share
		{
			color: $colorWhite;
		}

		& .text-box--white
		{
			color: #FF5C26;
		}
	}

	.text-only-resize{
		display: none;
	}

}

.block .title{
	text-align: left;
}

.block .down{
	position: absolute;
	bottom: 0px;
	right: 0px;
	padding:0px;
	height: 51px;

	.down-wrapper
	{
		padding: 10px;
		font-weight: 500;
	}
}

/* Block photo */

.block .photo,
.products-item .photo{
	padding: 0;
	overflow: hidden;
	background: $backgroundTextNormal;
	display: block;
	max-height: 285px;

	img	{
		padding: 0;
		opacity: 1;
		transition: opacity 0.4s ease-out, transform 0.2s ease-out;
		width: 100%;
		@include object-fit(cover);
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility:    hidden;
		backface-visibility:     hidden;		
	}

	&:hover img	{
		opacity: 0.8;
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		backface-visibility:    hidden;		
	}
}

.block.newnew .photo{
	min-height: 250px;
	img	{
		height: 250px;
	}
}

.block.resized{
	.photo	{
		position: relative;
		min-height: 285px;

		img	{
			width: 100%;
			height: 285px;
		}
	}

	.text-only-resize{
		display: block;
	}

	.subtitlel,
	.item-news-subtitle	{
		margin-bottom: 0;
		padding-bottom: 15px;
	}

	&.newnew .down {
		background-color: $backgroundTextNormal;

		box-shadow: 0 -5px 15px $backgroundTextNormal;
	}
}

.item-concursos.block{
	padding-bottom: 40px;

	.photo	{
		padding-bottom: 10px;
		max-height: none;
		height: auto;
		min-height: 0;
		background-color: transparent;
		max-height: none;

		img
		{
			margin-bottom: 15px;
			height: 100%;
		}
	}
}


.block .photo.photo-natural{
	padding: 0;
	overflow: hidden;
	background: transparent;
	width: 100%;
	transition: transform 0.3s ease-out;
	text-align: center;

	img	{
		padding: 0;
		width: auto;
		height: auto;
		display: inline-block;
		margin-top: 60px;
    	margin-bottom: 40px;
	}

	&:hover img	{
		opacity: 1;
		transform: scale(1.15);
	}
	&:after{
		content: "";
		display: inline-block;
		height: 100%;
		display: none;
	}
}



.photo img{
	width: 100%;
}

/* Block Small - 1 col */
.block.small{
	& .photo{
		min-height: 304px;
	}
}

/* Block Big - 3 col */
.block.big{
	.photo{
		min-height: 100%;
		max-height: 480px;
		height: 480px;

		@media (max-width: $screen-md-max) AND (min-width: $screen-md-min){
			&.col-md-12
			{
				margin-bottom: 15px;
			}
		}
	}
}
.block.big .text{
	padding: 15px 10px 30px 22px;
	min-height: 100%;
}

.block.big .title{
	font-size: 35px;
	font-family: 'Arvo';
	margin-top: 5px;
    padding: 0px 2px;

    @media (max-width: 1300px){
    	font-size: 30px;
    }
}

.block.big .subtitle{
	font-size: 18px;
	font-family: $fontStack1;
	color:#84C652;
	 @media (max-width: 1300px){
    	font-size: 15px;
    }
}

.block.big .desc{
	font-size: $defaultFontSize;
	font-family: $fontStack1;
	padding-bottom: 15px;
}
.form-block{
	input:not([type=checkbox]):not([type=radio]),
	select,
	textarea.form-control
	{
		border-radius: 0;
	}
	input:not([type=checkbox]):not([type=radio]),
	select
	{
		height: 50px;
	}
	.input-group-addon{
		min-width: 48px;
		font-size: 15px;
		border-radius: 0;
		color: #9C9EA7;
	}
	textarea.form-control{
		max-width: 100%;
		min-width: 100%;
	}
}

.checkbox{
	input,
	label{
		vertical-align: middle;
		color: #999;
	}
	.label	{
		padding-left: 10px;
	}
	input{
		height: 0;
		width: 0;
	}

	input:before{
		content: "";
		height: 16px;
		width: 16px;
		border: #999 1px solid;
		float: left;
		display: inline-block;
		vertical-align: middle;
		margin-top: -3px;
		font-family: FontAwesome;
		background-color: #FFF;
	}
	input:checked:before{
		content: "\f00c"
	}
}

.autoheight{
	height: auto!important;
}

.icon{
	&.icon-circle
	{
		text-align: center;
		border: 2px solid #999;
		color: #999;
		border-radius: 100%;
		height: 35px;
		width: 35px;
		float: left;
		padding: 3px;
		margin-right: 10px;
	}

	&:hover{
		color: #333;
	}

	&.facebook:hover,
	a:hover &.facebook{	
		color: $colorFacebook!important;
		border-color: $colorFacebook!important;
	}

	&.twitter:hover,
	a:hover &.twitter{		
		color: $colorTwitter!important;
		border-color: $colorTwitter!important;
	}

	&.youtube:hover,
	a:hover &.youtube{
		color: $colorYoutube!important;
		border-color: $colorYoutube!important;
	}

	&.pinterest:hover,
	a:hover &.pinterest{
		color: $colorPinterest!important;
		border-color: $colorPinterest!important;
	}

	&.instagram:hover,
	a:hover &.instagram{
		color: $colorYoutube!important;
		border-color: $colorYoutube!important;
	}

	&.rss:hover,
	a:hover &.rss{
		color: $colorRSS!important;
		border-color: $colorRSS!important;
	}
}
.btn{
	font-family: $fontStack1;
	font-weight: 500;
	border-radius: 0;
}
.btn.btn-gray{
	background: #AAA;
	color: $colorWhite;
	border-radius: 0;
	transition: all 0.3s ease-in;
	font-family: $fontStack3;
	font-weight: 500;

	&:hover	{
		background-color: $colorCorporative;		
	}
}
/******/

/** Mobile Version **/
.title-mobile{
	background-color: $colorCorporative;	
	color: $colorWhite;
	font-size: 20px;
	padding-left: 20px;
	height: 35px;
	line-height: 35px;
	display: block;
	margin-bottom: 10px;

	&:hover	{
		color: $colorWhite;
		background-color: lighten($colorCorporative, 5%);

		body.famaliving &
		{
			background-color: lighten($colorCorporative2, 5%);
		}
	}

	h1, .h1
	{	
		line-height: 35px;
		margin: 0;
		font-size: inherit;	
		font-weight: inherit;		
		font-family: "Montserrat", Arial, sans-serif;	
	}
	.icon{
		margin-right: 18px;
		line-height: 35px;
		height: 35px;
	}
}

/*********** SISTEMA DE FOTOS **************/
.photo-valign{
	width: 87px;
	height: 87px;
	border-radius: 100%;
	overflow: hidden;
	display: block;
	position: relative;

	.photo-wrap	{
		text-align: center;
		width: 10000px;
		height: 10000px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -5000px;
		margin-top: -5000px;
	}
	.photo-wrap:before{
		content: "";
	    height: 100%;
	    width: 1px;
	    display: inline-block;
	    vertical-align: middle;
		margin-right: -4px;
	}

	img	{	
		vertical-align: middle;
		display: inline-block;
	}
}
/*********** END SISTEMA DE FOTOS **************/



// Use vars bootstrap
@media (max-width: $screen-xs-max)
{
	body{
		background-color: $colorWhite;
		overflow-x: hidden;
	}

	body > .container{	
		padding-top: 60px;
	}

	body.home{
		overflow: auto!important;
	}



	.block	{
		margin: 0;
		border-left: none;
		border-right: none;
		border-bottom: none;
		border-radius: 0;
		padding-top: 12px;
		margin-bottom: 30px;
		border: 0;
		box-shadow: 0;

		&:first-child{
			border-top: none;
		}

		.down{
			padding: 0px;
    		height: auto;    		
    		bottom: 6px;
    		right: 12px;
		}

		.photo{
			&.photo-natural
			{
				min-height: initial;
				img
				{
					height: 46px;
					margin-top: 20px;
					margin-bottom: 10px;
				}
			}
		}

		&.big{
			.photo{
				//height: 250px;
				height: auto;
			}
		}
	}
}

@media (min-width: $screen-md-min){

}
@media (min-width: $screen-rt-min)
{
	.block
	{
		&.big
		{
			.photo
			{
				height: 700px;
				max-height: 100%;
				img
				{
					height: 700px;
				}
			}
		}

		&.resized,
		&.newnew,
		&
		{
			.photo
			{
				height: 400px;
				max-height: 100%;
				img
				{
					height: 400px;
				}
			}
		}

		&.resized.big
		{
			.photo
			{
				height: 800px;
				max-height: 100%;
				img
				{
					height: 800px;
				}
			}
		}
	}
}

#imgmodal
{
	background-color: $colorWhite;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	position: fixed;
    z-index: 3000;

	img
	{
		width: 100%;
		height: 100%;

		@include object-fit(cover);
	}

	.btn-close
	{
		color: #333;
		font-size: 30px;
		position: absolute;
		top: 50px;
		right: 50px;
		padding: 10px;
		background-color: rgba($colorPrimary, 0.75);
		cursor: pointer;
		-webkit-transition: 0.3s ease-in all;
		transition: 0.3s ease-in all;

		&:hover
		{
			background-color: rgba($colorPrimary, 1);
		}

		///FAMALIVING color
		body.famaliving &
		{
			background-color: rgba($colorCorporative2, 0.75);

			&:hover
			{
				background-color: rgba($colorCorporative2, 1);
			}
		}
	}
}


#storelist
{
	background-color: $backgroundBody;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	position: fixed;
    z-index: 3000;
	padding-top: 30px;
	overflow-y: scroll;

	img
	{
		width: 100%;
		height: 100%;
		@include object-fit(cover);
	}

	.btn-close
	{
		color: #333;
		font-size: 30px;
		position: absolute;
		top: 20px;
		right: 20px;
		padding: 10px;
		background-color: rgba($colorPrimary, 0.75);
		cursor: pointer;
		-webkit-transition: 0.3s ease-in all;
		transition: 0.3s ease-in all;

		&:hover
		{
			background-color: rgba($colorPrimary, 1);
		}

		///FAMALIVING color
		body.famaliving &
		{
			background-color: rgba($colorCorporative2, 0.75);

			&:hover
			{
				background-color: rgba($colorCorporative2, 1);
			}
		}
	}

	.products-hashtags
	{
		height: 230px;
		position: relative;
		background-color: transparent;
		.list-group
		{
			margin-bottom: 0;
		}
		.list-group-item
		{
			background-color: transparent;
			color: $colorPrimary;
			font-size: 14px;
			border: 0 none;

			&.active
			{
				background-color: $colorPrimary;
				color: $colorWhite;
			}
			&:hover{
				text-decoration: none;
				color: #555;
				background-color: #f5f5f5;
			}
		}

		&.in-2-cols
		{
			.list-group-item
			{
				width: 50%;
				border-radius: 0px;

				&:nth-child(2n + 1)
				{
					float: left;
					clear: left;
				}
				&:nth-child(2n)
				{
					float: right;
					clear: right;
				}
			}
		}
	}
	.continent-container
	{
		background: $colorCorporative;
		padding: 0px;
		height: 230px;
		overflow: hidden;
	}
	.products-item-list{
		li a.active{
			background: rgba(255,255,255,0.2);
		}
	}
	.pais-title{
		float: left;
		font-size: 18px;
		margin-left: 12px;
	}

	.products-item{
		.product-text{
			.desc{
				padding-top: 35px;
				padding-left: 0px;
				margin-left: 10px;
			}
		}
	}
	.section-title{
		color: $colorCorporative2;
	}
	.products-list-col{
		text-transform: capitalize!important;
		ul{
			li{
				a{font-size: 18px !important;}
			}
		}
	}
}


#st-6 {
	font-family: "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif;;
	direction: ltr;
	display: block;
	opacity: 1;
	text-align: left;
	z-index: 94034;
}
#st-6.st-animated {
	-moz-transition: opacity 0.2s ease-in;
	-ms-transition: opacity 0.2s ease-in;
	-o-transition: opacity 0.2s ease-in;
	-webkit-transition: opacity 0.2s ease-in;
	transition: opacity 0.2s ease-in;
}
#st-6.st-hidden {
	opacity: 0;
}
#st-6 .st-btn {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-moz-transition: top 0.2s ease-in;
	-ms-transition: top 0.2s ease-in;
	-o-transition: top 0.2s ease-in;
	-webkit-transition: top 0.2s ease-in;
	transition: top 0.2s ease-in;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	cursor: pointer;
	display: inline-block;
	font-size: 18px;
	height: 32px;
	line-height: 36px;
	padding: 0 8px;
	position: relative;
	text-align: center;
	top: 0;
	vertical-align: top;
	white-space: nowrap;
}
#st-6 .st-btn:last-child {
	margin-right: 0;
}
#st-6 .st-btn > svg {
	height: 16px;
	width: 16px;
	position: relative;
	top: 8px;
	vertical-align: top;
}
#st-6 .st-btn > img {
	height: 16px;
	width: 16px;
	position: relative;
	top: 8px;
	vertical-align: top;
}
#st-6 .st-btn > span {
	-moz-transition: all 0.2s ease-in;
	-ms-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	-webkit-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
	color: #fff;
	display: inline-block;
	font-weight: 500;
	letter-spacing: 0.5px;
	min-width: 60px;
	opacity: 1;
	padding: 0 6px;
	position: relative;
	vertical-align: top;
}
#st-6.st-has-labels .st-btn {
	min-width: 120px;
}
#st-6.st-has-labels .st-btn.st-remove-label {
	min-width: 50px;
}
#st-6.st-has-labels .st-btn.st-remove-label > span {
	display: none;
}
#st-6.st-has-labels .st-btn.st-hide-label > span {
	display: none;
}
#st-6 .st-total {
	color: #555;
	display: inline-block;
	font-weight: 500;
	line-height: 12px;
	margin-right: 0;
	max-width: 80px;
	padding: 4px 8px;
	text-align: center;
}
#st-6 .st-total.st-hidden {
	display: none;
}
#st-6 .st-total > span {
	font-size: 16px;
	line-height: 17px;
	display: block;
	padding: 0;
}
#st-6 .st-total > span.st-shares {
	font-size: 9px;
	line-height: 9px;
}
#st-6.st-justified {
	display: flex;
	text-align: center;
}
#st-6.st-justified .st-btn {
	-moz-flex: 1;
	-ms-flex: 1;
	-webkit-flex: 1;
	flex: 1;
}#st-6 .st-btn:hover {
	 opacity: .8;
	 top: -4px;
 }#st-6 .st-btn[data-network='facebook'] {
	  background-color: #3B5998;
  }
#st-6 .st-btn[data-network='twitter'] {
	background-color: #55acee;
}
#st-6 .st-btn[data-network='email'] {
	background-color: #7d7d7d;
}
.share-btn-container{
	display: none;
	width: 128px;
	position: absolute;
	top: 2px;
	left: 110px;
	padding: 8px;
	background-color: #fff;
	border-radius: 5px;
}

.spinner
{
	&.spinner-back
	{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: -1;

		.spinner-icon
		{
			display: block;
			margin: 50vh auto;
			color: $colorPrimary;
			//@extend %famalivingTextColor;
			text-align: center;

		}
	}
}

#popup_cookies {
	position: fixed;
	z-index: 1000;
	bottom: 0;
	margin: 0px auto;
	left: 0px;
	right: 0px;
	text-align: center;
	display: none;
	.buttons
	{
		padding-bottom: 15px;
	}
	.buttons a
	{
		padding: 10px;

		-webkit-transition: color 0.3s ease-in;
		transition: color 0.3s ease-in;
		margin: 0 5px;
	}

	@media screen AND (max-width: $screen-sm-max)
	{
		.content
		{
			font-size: 12px;
			padding: 15px 15px 10px;
		}
		.buttons
		{
			padding-bottom: 10px;
		}
		.buttons a
		{
			padding: 4px 10px;
			font-size: 12px;
		}


	}

	@media screen AND (min-width: $screen-md-min)
	{
		min-height: 125px;
	}

	background-color: #cacaca;
}

#popup_cookies.mostrar {
	display: block;
}

.scrollbox
{
	&.scrollbox-conditions
	{
		margin-top: 20px;
		min-height: 50px;
		max-height: 120px;
		border: 1px solid #ccc;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 15px;
		color: #999;

		font-size: 12px;

	}
}
