.container.home-garantia{
    width: 100%;
    max-width: 1280px;
    padding: 0 5rem;
    background-color: #fff;
    padding-top: 20.2rem;
    padding-bottom: 21%;

    .wrap-garantia{
        max-width: 1280px;
        margin: 0 auto;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;

        .garantia-content{
            width: 60%;
            min-width: 54rem;
            padding-bottom: 8.5rem;
            padding-top: 4.8rem;
            background-color: #f3f6f7;
            padding-left: 9.5rem;
            padding-right: 9.5rem;
            position: relative;
            align-self: auto;
            .section-title, .section-subtitle{
                text-align: start;
                margin: 0;
            }
            .section-subtitle{
                color: $colorCorporative;
                -webkit-text-stroke: 0;
            }
            .section-title{
                margin: 0;
            }
            .line-separation{
                justify-content: flex-start;
            }
           .garantia-text{
                max-width: 41.1rem;
                font-size: 1.5rem;
                width: 100%;
                padding-bottom: 3.3rem;
                line-height: 2.3rem;
                .subtitle{
                    font-family: 'Montserrat-SemiBold';
                    padding-bottom: 2.5rem;
                    line-height: 2.2rem;
                }
           }
           .wrap-btnDefault{
                align-self: flex-start;
           }
           .marka{
                background-image: url('/images/img_garanty/star-garanty.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                position: absolute;
                width: 23.4rem;
                height: 23.4rem;
                right: 0;
                top: 0;
                transform: translateX(62%) translateY(-24%);

                &::before{
                    content: '';
                    background-image: url('/images/img_garanty/check-white.svg');
                    background-repeat: no-repeat;
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    background-position: center;
                    transform: scale(1.4);
                }
                &::after{
                    content: "";
                    background-image: url('/images/img_garanty/words-circle.svg');
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    animation: spins 16s linear infinite;
                    @-moz-keyframes spins {
                        100% { -moz-transform: rotate(360deg) }
                    }
                    @-webkit-keyframes spins {
                        100% { -webkit-transform: rotate(360deg)}
                    }
                    @keyframes spins {
                        from {
                            -webkit-transform: scale(1.4) rotate(0deg);
                            transform: scale(1.4) rotate(0deg);
                        }
                        to {
                            -webkit-transform: scale(1.4) rotate(360deg);
                            transform: scale(1.4) rotate(360deg);
                        }
                    }
                }

            }
        }
        .garantia-wrap-img{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            right: 0;
            bottom: 0;
            position: absolute;

            .img{
                width: 50%;
                height: calc(50vw * 0.56);
                max-height: 33rem;
                transform: translateY(50%);
                background-color: $colorCorporative;
                position: relative;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    &.aux{
                        height: 100%;
                        visibility: hidden;
                    }
                }

            }
        }
    }

}
@media (max-width: $md-width) {
    .container.home-garantia{
        width: 100%;
        max-width: 1280px;
        padding: 0 1.5rem;
        padding-top: 15.4rem;
        padding-bottom: 21%;
        .wrap-garantia{
            .garantia-content{
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                .garantia-text{
                    padding-left: 0;
                }
                .wrap-btnDefault{
                    align-self: flex-start;
                    transform: translateX(0);
                    .btnDefault{
                        padding: 1.4rem 3rem;
                    }
                }
                .marka{
                    width: 16.8rem;
                    height: 16.8rem;
                    transform: translateX(1rem) translateY(-40%);
                    &::before{
                        transform: scale(1.1);
                    }
                    &::after{
                        animation: spins1 16s linear infinite;
                        @-moz-keyframes spins1 {
                            100% { -moz-transform: rotate(360deg) scale(1.1)}
                        }
                        @-webkit-keyframes spins1 {
                            100% { -webkit-transform: rotate(360deg) scale(1.1)}
                        }
                        @keyframes spins1 {
                            from {
                                -webkit-transform: scale(1.1) rotate(0deg);
                                transform: scale(1.1) rotate(0deg);
                            }
                            to {
                                -webkit-transform: scale(1.1) rotate(360deg);
                                transform: scale(1.1) rotate(360deg);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $sm-width) {
    .container.home-garantia{
        width: 100%;
        max-width: 1280px;
        padding: 0 1.5rem;
        padding-top: 15.4rem;
        padding-bottom: 58vw;

        .wrap-garantia{
            max-width: 1280px;
            margin: 0 auto;
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;

            .garantia-content{
                width: 100%;
                min-width: 100%;
                padding-bottom: 8.5rem;
                padding-top: 12.4rem;
                background-color: #f3f6f7;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                padding-top: 12.4rem;
                padding-bottom: 25vh;
                .section-subtitle{
                    letter-spacing: 4.25px;
                    line-height: normal;
                    font-size: 1.7rem;
                    padding-bottom: 5.2rem;
                }
                .section-title{
                    font-size: 6rem;
                    line-height: 9rem;
                }
                .line-separation{
                    display: none;
                }
               .garantia-text{
                    max-width: 30.5rem;
                    width: 100%;
                    padding-bottom: 3.3rem;

                    .subtitle{
                        padding-bottom: 2.5rem;
                        line-height: 2.2rem;
                        width: 80%;
                    }
                }
            }
            .garantia-wrap-img{
                .img{
                    width: 100%;
                    position: relative;
                    transform: translateY(70%) translateX(1.5rem);
                    padding-top: 64%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}
