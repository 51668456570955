.wrapper-page{
    position: relative;
    padding-right: 5rem;
    padding-left: 5rem;
    .header{
        width: calc(100% + 10rem);
        transform: translateX(-5rem);
        position: relative;

        .title{
            position: absolute;
            bottom: 9.1rem;
            text-align: center;
            font-family: 'Montserrat-Bold';
            width: 100%;
            font-size: 3.5rem;
            color: #fff;
            text-transform: uppercase;
            letter-spacing: 0;
        }

        .wrap-img{
            width: 100%;
            height: 33rem;    
            .img{
                width: 100%;
                height: 100%;    
                object-fit: cover;
            }            
        }
    }
    .wrap-content{       
        padding-bottom: 8rem;
        padding-top: 5rem;
        max-width: 967px;
        margin: 0 auto;
        .description{
            font-family: 'Montserrat-Regular';
                      
            >p{
                margin-bottom: 2.5rem;
            }

            p{
                font-size: 1.7rem;
                line-height: 2.3rem;
                color: #000;
                letter-spacing: 0;
                font-weight: normal;
                text-transform: none;                      
            }
            h2,  h3{
                font-size: 2.1rem;
                font-family: 'Montserrat-Bold';
                color: $colorCorporative;
                font-weight: normal;
                line-height: 2.9rem;
                margin: 0;   
                & +*{                    
                    padding-top: 2.5rem;
                }         
                
            }
            h3{          
                color: #000;         
            }
            *:empty, br{
                display: none !important;
            }
            a{
                color: $colorCorporative;
                            
                --border-color: $colorCorporative;
                --border-width: 1px;
                --bottom-distance: 0px; /* you can increase this */    
               
                display: inline;
                background-image: linear-gradient(var(--border-color), var(--border-color));
                background-size: 0% var(--border-width);
                background-repeat: no-repeat;
                background-position-y: bottom;
                transition: background-size 0.5s;    

                &:hover{                                  
                    background-size: 100% var(--border-width);  
                }
            }       
        }
    }
}

@media (max-width: $md-width) {
    .wrapper-page{     
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        .header{
            width: calc(100% + 3rem);
            transform: translateX(-1.5rem);
            
            .title{
                position: absolute;
                bottom: 7.5rem;         
                font-size: 2.5rem;               
            }
    
            .wrap-img{          
                height: 23.5rem;               
            }
        }
        .wrap-content{       
            padding-bottom: 8rem;
            padding-top: 3.3rem;
            max-width: 100%;   

            .description{
                >p{
                    margin-bottom: 2rem;
                }    
                p{
                    font-size: 1.7rem;
                    line-height: 2.3rem;
                    color: #000;
                    letter-spacing: 0;
                    font-weight: normal;
                    text-transform: none;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                h2, h3{    
                    line-height: 3rem; 
                    padding-top: 4rem;
                                  
                    & +*{
                        padding-top: 2.5rem;
                    }
                } 
                h2:first-of-type{
                    padding-top: 0;                    
                }
            }
        }
    }
}