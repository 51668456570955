.container-collage{
    display: none;
    align-self: center;   
    transform: scale(1.15);
    position: absolute;
    right: 5rem;
   
    .collage{
        .mr-3-5{
            margin-right: .35rem;
        }
        .ml-3-5{
            margin-left: .35rem;
        }
        .mb-7{
            margin-bottom: .7rem;
        }     
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;  
            position: relative;
                           
        }
        .img-wrap{
            position: relative;
            &:before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(17, 151, 165, 0.4);
                opacity: 0;
                z-index: 1;  
                transition: opacity .3s;                      
            } 
            &:hover{  
                .item-name{
                    opacity: 1;
                    transition: opacity .3s;
                }   
                &:before{
                    opacity: 1; 
                    transition: opacity .3s;                                               
                }          
            }      
        }
        .linkImg{
            .item-name{
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%);
                opacity: 0;
                color: #fff;
                font-size: 2.5rem;
                font-family: 'Montserrat-SemiBold';
                transition: opacity .3s;
                z-index: 1;
            }            
        }        
        .layout1{            
            .line-horizont-1{     
                justify-content: center;
                align-items: flex-end;
                .img1{
                    width: 17.7rem;
                    height: 20.8rem;
                }
                .img2{
                    width: 24.8rem;
                    height: 17.7rem;
                } 
            }
            .line-vert-2{
                flex-direction: column;
                align-items: flex-end;
                .img3{
                    width: 28.1rem;
                    height: 17.7rem;
                }
                .img5{
                    width: 26.9rem;
                    height: 16.8rem;   
                }             
            }
            .line-vert-3{
                .img4{                
                    width: 22.1rem;
                    height: 19.1rem;         
                }
                .img6{
                    width: 20.6rem;
                    height: 12.9rem;
                }
            }

        }
        .layout2{            
            .line-horizont-1{     
                justify-content: center;
                align-items: flex-end;
                .img1{
                    width: 23.5rem;
                    height: 20.8rem;
                }
                .img2{
                    width: 26.8rem;
                    height: 14.6rem;
                } 
            }
            .line-vert-2{
                flex-direction: column;
                align-items: flex-end;
                .img3{
                    width: 28.1rem;
                    height: 16rem;
                }
                .img5{
                    width: 21.5rem;
                    height: 16.8rem;   
                }             
            }
            .line-vert-3{
                .img4{                
                    width: 18rem;
                    height: 12.9rem;         
                }
                .img6{
                    width: 21.97rem;
                    height: 18rem;
                }
            }

        }
        .layout3{            
            .line-horizont-1{     
                justify-content: center;
                align-items: flex-end;
                .img1{
                    width: 28.1rem;
                    height: 17.6rem;
                }
                .img2{
                    width: 22.1rem;
                    height: 28.7rem;
                } 
            }
            .line-vert-2{
                flex-direction: column;
                align-items: flex-end;
                .img3{
                    width: 20.1rem;
                    height: 12.6rem;
                }                        
            } 
            .line-vert-3{
                .img4{                
                    width: 29.6rem;
                    height: 15.5rem;         
                }          
            }     
        }
        .layout4{            
            .line-horizont-1{     
                justify-content: center;
                align-items: flex-end;
                .img1{
                    width: 33.8rem;
                    height: 19rem;
                }            
            }
            .line-vert-2{
                flex-direction: column;
                align-items: flex-end;
                .img3{
                    width: 22.8rem;
                    height: 35.1rem;
                }                      
            }
            .line-vert-3{
                .img4{                
                    width: 25.9rem;
                    height: 19rem;         
                }
                .img6{
                    width: 19.4rem;
                    height: 12.8rem;
                }
            }
        }  
    }
    opacity: 0;
    transition: .3s .2s opacity ease ;
    &.isActive{
        opacity: 1;
        transition: .3s .2s opacity ease ;
        display: block;      
    }
}

@media only screen and (max-width: $md-width){
    .container-collage{
        display: none !important;
    }    
}