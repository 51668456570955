.container.home-simulations{
    background-color: #f3f6f7;
    padding-left: 5rem;
    padding-right: 5rem;

    .section-simulations-wrap{     
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 19.2rem;
        padding-bottom: 19.2rem;
        .wrap-text.mvl{
            display: none;
        } 
    }

    .slider-home-simulations-list{
        width: 100%;
     
        &>.slick-list{            
            padding: 0 !important;
            .wrap-slider-simulation-item{              
                .wrap-text{
                    padding: 6.2rem;
                    padding-top: 0;

                    .section-title, .section-subtitle{
                        text-align: left;
                        text-transform: capitalize;
                        margin: 0;
                    }
                    .section-title{
                        font-size: 6rem;
                        margin: 0;

                    }
                    .line-separation{
                        justify-content: flex-start;
                    }

                    .section-subtitle{
                        text-transform: uppercase;                        
                    }                       
                   
                    &.desk{
                        margin-left: 0;                   
                    } 
                    &.sm-mvl{
                        display: none;
                    }           
                }
                .slider-simulation-item, .wrap-text{
                    width: 50%;
                }                

            }
        }
        &>.slick-next{
            opacity: 1;
            right: 0;
            width: 4rem;
            height: 4rem;
            &:before{
                content: '';
                background-image: url('/images/right.svg');
                width: 4rem;
                height: 4rem;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                top: 0;
                left: 0;
            }

        }
        &>.slick-prev{
            opacity: 1;
            left: 0;
            width: 4rem;
            height: 4rem;
            &:before{
                content: '';
                background-image: url('/images/left.svg');
                width: 4rem;
                height: 4rem;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                top: 0;
                left: 0;
            }

        }
    }
  
    .slider-type-simulation.slider{
        .slick-list{
            padding: 0 !important;
            .slick-slide{
                .item{
                    margin-right: 0;
                    position: relative;
                    padding-top: 60%;
                    .img{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;                        
                    }
                }
            }
        }
    }
      .slider-type-simulation.slider .slick-dots {
        bottom: 0;
        height: 5rem;
      }
      
      .slider-type-simulation.slider .slick-dots li {
        width: 5rem;
        height: 5rem;
        opacity: .8;
        button{
            border: 2px solid #fff;
        };
       
      }
      
      .slider-type-simulation.slider .slick-dots li.slick-active {
        opacity: 1;
        button{
            border: 3px solid #fff;
        }
        
      }
      
      .slider-type-simulation.slider .slick-dots li.slick-active button,
      .slider-type-simulation.slider .slick-dots li:hover button {
        opacity: 1;
        transform: scale(1,1);
        
      }
      
      .slider-type-simulation.slider .slick-dots li button {
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: auto;
        padding: 0;
        transition: all .5s ease;
        transform: scale(.75,.75);
      }
      
      .slider-type-simulation.slider .slick-dots li button img {
        display: block;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
      
      .slider-type-simulation.slider .slick-dots li button:before {
        display: block;
        position: relative;
        content: '';
        width: 100%;
        padding-top: 100%;
        height: auto;
      }
}


@media (max-width: $md-width) {   
    .container.home-simulations{
        padding-top: 0;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        .section-simulations-wrap{
       
            padding-top: 6.5rem;
            padding-bottom: 9.2rem;
            .slick-arrow{
                transform: translateY(-1000%);
            }
            .wrap-text.mvl{
                display: flex;
                flex-direction: column;
                padding-bottom: 4rem;
                .line-separation{
                    justify-content: center;
                }
                .section-title{
                    text-align: center;
                    font-size: 5rem;
                    margin: 0 auto;
                }
            }  
        }


        .slider-home-simulations-list{          
            max-width: 100%;           
            &>.slick-list{  
                .wrap-slider-simulation-item{
                    flex-direction: column;
                    .slider-simulation-item, .wrap-text{
                        width: 100%;
                    }
                    .wrap-text{
                        padding: 0;    
                        padding-bottom: 5.2rem;  
                        display: flex;
                        flex-direction: column;
                        .wrap-info{
                            font-size: 1.7rem;
                            line-height: 2.4rem;
                            color: #575252;
                            text-align: center;
                        } 
                       
                        &.desk{                           
                            display: none; 
                        }    
                        &.sm-mvl{
                            display: block;
                            .section-subtitle{
                                text-align: center;
                            }
                        }                        
                    }    

                }
            }
            &>.slick-next{
                opacity: 1;
                right: 0;
                width: 8rem;
                height: calc(((100vw - 30px) / 100) * 60);
                transform: translate(0, 0);                
                top: 0;

                &::before{
                    content: '';
                    transform: translateY(-50%);             
                    background-image: url(/images/right.svg);
                    width: 4rem;
                    height: 4rem;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: 50%;
                    left: unset;
                    right: 1.5rem;
                } 
            }
            &>.slick-prev{
                opacity: 1;
                left: 0;           
                width: 8rem;
                height: calc(((100vw - 30px) / 100) * 60);  
                transform: translate(0, 0);
                top: 0;
                
                &:before{
                    content: '';
                    background-image: url('/images/left.svg');
                    width: 4rem;
                    height: 4rem;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: 50%;
                    transform: translateY(-50%); 
                    left: 1.5rem;                  
                }
            }
        }
    
        .slider-type-simulation.slider{
            .slick-list{
                padding: 0 !important;
                .slick-slide{
                    .item{
                        margin-right: 0;
                        position: relative;
                        padding-top: 60%;
                        .img{
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
        .slider-type-simulation.slider .slick-dots {
            bottom: 0;
            height: 3.5rem;
        }
        
        .slider-type-simulation.slider .slick-dots LI {
            width: 3.5rem;
            height: 3.5rem;
            opacity: .8;
            BUTTON{
                border: 2px solid #fff;
            };        
        }
        
        .slider-type-simulation.slider .slick-dots LI.slick-active {
            opacity: 1;
            button{
                border: 3px solid #fff;
            }            
        }
        
        .slider-type-simulation.slider .slick-dots LI.slick-active BUTTON,
        .slider-type-simulation.slider .slick-dots LI:hover BUTTON {
            opacity: 1;
            transform: scale(1,1);            
        }
        
        .slider-type-simulation.slider .slick-dots LI BUTTON {
            display: block;
            overflow: hidden;
            position: relative;
            width: 100%;
            height: auto;
            padding: 0;
            transition: all .5s ease;
            transform: scale(.75,.75);
        }
        
        .slider-type-simulation.slider .slick-dots LI BUTTON IMG {
            display: block;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        
        .slider-type-simulation.slider .slick-dots LI BUTTON:before {
            display: block;
            position: relative;
            content: '';
            width: 100%;
            padding-top: 100%;
            height: auto;
        }
    }
}