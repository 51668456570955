/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null){
    -moz-object-fit: $fit;
    -webkit-object-fit: $fit;
    -o-object-fit: $fit;
       object-fit: $fit;
    @if $position { 
      -moz-object-position: $position;
      -webkit-object-position: $position;
        -o-object-position: $position;
           object-position: $position;
  
      // Font-family use by plugin OFI (Object Fit Images)
      // https://github.com/bfred-it/object-fit-images/tree/master
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    } 
    @else {
        font-family: 'object-fit: #{$fit}';
    }
}