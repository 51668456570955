.container.home-instagram{
    padding-top: 0;
    padding: 0;
    .wrap-instagram-list{
        margin: 0 auto;
        width: 100%;    
    }
    .instagram-list{
        .instagram-item{
            width: calc(100% / 4);
            overflow: hidden;

            .inistagram-img{
                position: relative;
                padding-top: 100%;

                .wrap-content{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                    color: #fff;

                    .instaVideo{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }

                    .instagram-media{                       
                        color: #fff;                          
                    }

                    .img{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                        filter: blur(0);                    
                        transition: .3s .2s filter ease;
                    }
                    .instagram-info{                        
                        width: 100%;
                        height: auto;
                        top: 50%;
                        left: 0;
                        position: absolute;
                        color: #fff;
                        padding: 3.1rem;                       
                        z-index: 2;
                        opacity: 0;
                        transform: translateY(-50%);
                        transition: .2s opacity ease;

                        .text{
                            color: #fff;
                            text-align: center;                          
                            font-size: 1.5rem;
                            a{
                                color: #fff;
                            }
                        }
                       
                    }
                    &:before{
                        content: "";
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: rgba(16, 64, 70, 0.6);
                        opacity: 0;
                        z-index: 1;
                        transition: .3s .2s opacity ease;
                    }
                    &.esVideo:after{
                        content: "";
                        width: 10%;
                        height: 10%;
                        max-width: 2.5rem;
                        max-height: 2.5rem;
                        position: absolute;
                        top: 1rem;
                        right: 1rem;
                        background-image: url('/images/icon-videoInsta.svg');
                        background-repeat: no-repeat;
                        background-size: cover;
                        opacity: 0;
                        z-index: 2;
                        transition: .3s .2s opacity ease;                        
                    }                
                }
               
            }
            &:hover{
                .wrap-content{
                    &:before, &.esVideo:after{
                        opacity: 1;
                        transition: .3s opacity ease;
                    }
                  
                    .instagram-info{
                        opacity: 1;
                        transition: .3s .2s opacity ease;
                    }
                    .img{
                        filter: blur(5px);                    
                        transition: .3s filter ease;
                    }
                }
               
            }
            &.lg-insta{          

                display: none;
                @media (min-width: $extra-width) {
                    display: block;
                }
            }
            @media (min-width: $extra-width) {
                width: calc(100% / 6);
            }
        }     
    }  
}
@media (max-width: $md-width) {
    .container.home-instagram{
        padding-top: 0;
        padding: 0;

        .instagram-list{       
            .instagram-item{
                width: calc(100% / 4);

                .inistagram-img{
                    position: relative;
                    padding-top: 100%;

                    .wrap-content{
                        .instagram-info{
                            display: none !important;
                        }
                        .instagram-media{
                            &::before{
                                content: "";
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                background-color: rgba(16, 64, 70, 0.6);
                                opacity: 0;
                                z-index: 1;
                                transition: .3s .2s opacity ease;
                            }
                        }
                        &::before{
                            content: none;
                        }
                    }
                    &:hover{
                        .wrap-content{
                            &::before{
                                content: none;
                            }
                            .instagram-media{
                                &::before{
                                    opacity: 1;
                                    transition: .3s opacity ease;
                                }
                            }
                        }
                    }                 
                }             
            }
        } 
    }
}   

@media (max-width: $sm-width) {
    .container.home-instagram{
        .instagram-list{
            flex-wrap: wrap;
            .instagram-item{
                width: calc(100% / 2);
            }
        }       
    }
}   