.form, #popup_cookies_config_box{
    .input-box{
        position: relative;        
    }
%item-box {
	display: block;
    input, textarea{         
        & +.label {        
            position: absolute;    
            top: 0;
            font-size: 1.9rem;
            left: 2.1rem;  
            color: #575252;
            background-color: #f3f6f7;
            padding: 0 1.1rem;
            pointer-events: none;
            transform: translateY(50%);
            transform-origin: left;
            white-space: nowrap;
            transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
        }
        &:valid,  &:focus {            
            & +.label {                   
                transform: translateY(-50%) scale(0.8);                   
            }
        }
        &:focus {
            border: 1px solid #575252; 
        }     

    }   

	input, select, textarea {
		-webkit-appearance: none;
		display: block;
		position: relative;
		width: 100%;
		height: 4.5rem;
		border-radius: 0 !important;
		border: 1px solid #575252;	
		color: #575252;
		font-family: 'Montserrat-Medium';
		font-size: 1.4rem;
		outline: 0;
		padding: 0 1rem;
		transition: all 0.2s linear;
		text-decoration: none;

		&.error {
			border-color: $red !important;
		}	      	

	}
	select {
		line-height: normal;
	}
	textarea {
		padding: 1rem;
		min-width: 100%;
		max-width: 100%;
		min-height: 15rem;
	}
}


.input-box {
	@extend %item-box;
}
.privacy, .check_cookies{
	position: relative;
    input[type="checkbox"]{
        width: auto;
        visibility: hidden;
		position: absolute;
    }
    .labelContainer{
        top: 0;
        width: 100%;
        //position: absolute;
		position: relative;
        left: 0;
        margin-left: 0;
        display: flex;
        flex-wrap: wrap;
        padding-top: 1rem;
		align-items: center;

        .textContainer{
			color: #575252;
            font-size: 1.3rem;
			width: calc(100% - 3.2rem);
			font-family: 'Montserrat-Medium';
    		font-weight: normal;
			/*
            .link{
                text-decoration: underline;
                color: #1197a5;
				font-family: 'Montserrat-Medium';
				font-weight: normal;
            }*/
			.link{
				font-family: 'Montserrat-Medium';
				font-weight: normal;
				color: $colorCorporative;
				text-decoration: none;
				margin-left: .5rem;
				--border-color: #1197a5;
				--border-width: 1px;
				--bottom-distance: 0px;
				display: inline;
				background-image: linear-gradient(var(--border-color),var(--border-color));
				background-size: 0 var(--border-width);
				background-repeat: no-repeat;
				background-position-y: bottom;
				transition: background-size .3s;  

				&:hover{
					background-size: 100% var(--border-width);
				}
			}
        }
    }
    input + .labelContainer:before {
        content: '';
        position: relative;
        display: inline-block;
        vertical-align: middle;
        min-width: 1.7rem;
        height: 1.7rem;
        top: 0;
        border: 0.1rem solid $colorCorporative;
        border-radius: 0 !important;
        background-color: transparent;
        transition: background-color 0.2s linear 0.1s;
		
    }
	input.error + .labelContainer:before {
        border: 0.2rem solid #e3342f;        
    }
    input + .labelContainer:after {
        content: '';
        position: absolute;
        z-index: 1;
        min-width: 1.1rem;
        height: 0.6rem;
        top: 50%;
        left: 0.3rem;
        border: 0.2rem solid $colorCorporative;
        border-top: 0;
        border-right: 0;
        transform: rotate(-45deg) scale(0);
        transform-origin: left top;
        transition: all 0.2s ease 0s;
    }
    input[type="checkbox"]:checked + .labelContainer:before {
        background-color: transparent;
        transition: background-color 0.2s linear 0s;
    }
    input[type="checkbox"]:checked + .labelContainer:after {
        transform: translateY(calc(50% + .3rem)) rotate(-45deg) scale(1);
        transition: all 0.2s ease 0.1s;
    }
    &.error{
        input+.labelContainer:before {
            border: 0.2rem solid $err !important;
        }
    }
    .err{
        width: 100%;
        font-style: normal;
        color: $err;
    }
}
/*
.check_cookies{
    input[type="checkbox"]{
        width: auto;
        visibility: hidden;
    }
    position: relative;
    .labelContainer{
        top: 0;
        width: 100%;        
        left: 0;
        margin-left: 0;
        display: flex;
        flex-wrap: wrap;
        padding-top: 0;
		margin-bottom: .5rem;
		position: relative;

        .textContainer{
			color: #575252;
            font-size: 1.3rem;
			width: calc(100% - 2.2rem);
			font-family: 'Montserrat-Medium';
    		font-weight: normal;      
        }
    }
    input+.labelContainer:before {
        content: '';
        position: relative;
        display: inline-block;
        vertical-align: middle;
        min-width: 1.7rem;
        height: 1.7rem;
        top: 0;
        border: 0.2rem solid #1197a5;
        border-radius: 0 !important;
        background-color: transparent;
        transition: background-color 0.2s linear 0.1s;
		
    }
	
    input+ .labelContainer:after {
        content: '';
        position: absolute;
        z-index: 1;
        min-width: 1rem;
        height: 0.4rem;
        top: .8rem;
        left: 0.2rem;
        border: 0.1rem solid #fff;
        border-top: 0;
        border-right: 0;
        transform: rotate(-45deg) scale(0);
        transform-origin: left top;
        transition: all 0.2s ease 0s;
    }
    input[type="checkbox"]:checked + .labelContainer:before {
        background-color: #1197a5;
        transition: background-color 0.2s linear 0s;
    }
    input[type="checkbox"]:checked + .labelContainer:after {
        transform: translateY(-20%) rotate(-45deg) scale(1);
        transition: all 0.2s ease 0.1s;
    }
 
    
}
*/

.textarea-box {
	@extend %item-box;
}

/*
.checkboxRadio-box
{
	&.squared {
		input
		{
			display: none;
			&[type="radio"]
			{
				&+.inputContainer
				{
					&:before
					{
						border-radius: 0;
					}
					&:after
					{
						border-radius: 0;
					}
				}
			}
		}
	}
	input
	{
		display: none;
		&[type="radio"]
		{
			&+.inputContainer
			{
				&:before
				{
					content: '';
					position: absolute;
					width: 1.4rem;
					height: 1.4rem;
					border-radius: 100%;
					border: 1px solid #888;
					background-color: #fff;
					transition: border-color 0.2s linear;
				}
				&:after
				{
					content: '';
					position: absolute;
					width: 0rem;
					height: 0rem;
					top: 0.5rem;
					left: 0.8rem;
					border-radius: 100%;
					background-color: $red;
					transition: all 0.2s $easeOutCubic, background-color 0.2s linear;
				}
				&:hover
				{
					&:before
					{
						border-color: #ccc;
					}
					&:after
					{
						background-color: lighten($red, 10%);
					}
				}
			}
			&:checked
			{
				&+.inputContainer:after
				{
					top: 0.3rem;
					left: 0.3rem;
					width: 1rem;
					height: 1rem;
				}
			}
		}
		&[type="checkbox"]
		{
			&+.inputContainer
			{
				&:before
				{
					content: '';
					border-radius: 0;
					position: absolute;
					width: 1.4rem;
					height: 1.4rem;
					top: 0.2rem;
					border: 1px solid #888;
					background-color: transparent;
					transition: border-color 0.2s linear;
				}
				&:after
				{
					content: '';
					position: absolute;
					width: 0.8rem;
					height: 0.3rem;
					top: 0.6rem;
					left: 0.3rem;
					border: 2px solid $red;
					border-top: 0;
					border-right: 0;
					transform: rotate(-45deg) scale(0);
					transition: all 0.2s $easeOutCubic;
				}
				&:hover
				{
					&:before
					{
						border-color: #ccc;
					}
					&:after
					{
						border-color: lighten($red, 10%);
					}
				}
			}
			&:checked
			{
				&+.inputContainer:after
				{
					transform: rotate(-45deg) scale(1);
				}
			}
		}
		&:disabled {
			&+.inputContainer {
				cursor: default;
				pointer-events: none;
				opacity: 0.5;
			}
		}
	}
	.inputContainer
	{
		cursor: pointer;
		position: relative;
		display: inline-block;
		.textPlain
		{
			display: inline-block;
			padding-left: 2.4rem;
			padding-top: 0.2rem;
			font-size: 1rem;
			font-family: 'Montserrat-Bold';
			color: $black;
			transition: all 0.2s linear;
			letter-spacing: 0.9px;
			line-height: 1.2;
		}
		.textNormal {
			display: inline-block;
			padding-left: 2.4rem;
			font-size: 1.4rem;
			font-family: 'Montserrat-Medium';
			color: $black;
			transition: all 0.2s linear;
			line-height: normal;
		}
		.highlight {
			color: $red;
			font-size: 1rem;
			letter-spacing: 0;
		}
		.subtextNormal {
			display: block;
			padding-left: 2.4rem;
			font-size: 1rem;
			line-height: 1.2;
			color: #797979;
		}
		a {
			color: $red;
			transition: all 0.2s linear;
			&:hover {
				color: lighten($red, 10%);
			}
		}
	}
	&.isHidden{
		display: none;
		transition: all .2s ease;
	}
}
*/
/*
	.checkboxRadio-box{
		input{
			display: none;
		}
		.inputContainer{
			cursor: pointer;
			position: relative;
			display: inline-block;
			.textSmall{
				display: inline-block;
				padding-left: 2.4rem;
				font-size: 1rem;
				font-family: "Montserrat-Medium";
				color: #fff;
				transition: all .2s linear;
				line-height: normal;
				a{
					line-height: 2.8;
					color: #fff;
				}
			}
		}
		input[type="checkbox"]+.inputContainer:before {
			content: "";
			border-radius: 0;
			position: absolute;
			width: 1.4rem;
			height: 1.4rem;
			top: 0.7rem;        
			background-color: $white;
			transition: border-color .2s linear;
		}
		input[type="checkbox"]+.inputContainer:after {
			content: "";
			position: absolute;
			width: 0.8rem;
			height: 0.3rem;
			top: 1.1rem;
			left: 0.3rem;
			border: 2px solid #e2231b;
			border-top: 0;
			border-right: 0;
			transform: rotate(-45deg) scale(0);
			transition: all .2s cubic-bezier(.215,.61,.355,1);
		}
		input[type="checkbox"]:checked+.inputContainer:after {
			transform: rotate(-45deg) scale(1);
		}
		
	}
*/
	.err{
		color: $red;
		font-size: 14px;
		margin: 0;
		position: relative;
		font-family: 'Montserrat-Medium';
		padding-top: 0.5rem;
	}

}
.footer_2021 .well{
	min-height: 20px;
    padding: 2rem;
	padding-left: 0;
	padding-right: 3rem;
    margin-bottom: 3rem;
    background-color: transparent;  
	border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
	color: $colorCorporative;;
}
@media (max-width: $md-width) {
	.form{
		.privacy{
			margin-bottom: 2rem;
			.labelContainer{
				.textContainer{
					font-size: 1.1rem;
					line-height: 1.8rem;
				}
			}
			/*
			input+.labelContainer:before {				
				top: .2rem;		
			}
			input+ .labelContainer:after {				
				top: 1.9rem;			
			}
			*/
		}
	}	
}