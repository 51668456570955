.page-brands {   
    .up, .down{
        padding-right: 5rem;
        padding-left: 5rem;
        padding-top: 18.3rem;
        background-color: #f3f6f7;
    }
    .up{
        padding-bottom: 6rem;        

        .part-left{
            width: 53%;
            margin-right: 5.5rem;    
            margin-left: 3.4rem;
            
        }
        .part-right{
            width: calc(100% - 53% - 5.5rem - 3.4rem);
           
        }
    }
    .down{
        padding-top: 0;
        background-color: #e8f1f3;
        padding-top: 11.9rem;

        .wrap-brands{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .part-line{
                justify-content: space-between;
                padding-bottom: 6.5rem;
            }
            .part-columns{
                justify-content: space-between;
                .part-left, .part-right{
                    width: 46.5%;
                    .item-brand{
                        width: 100%;
                        padding-bottom: 12rem;
                    }
                }
                .part-left{
                    flex-direction: column;                  
                }
            }

            .item-brand{
                width: 46.5%;

                .title{
                    font-size: 1.9rem;
                    line-height: 2.6rem;
                    font-family: 'Montserrat-Bold';
                    color: $colorCorporative;
                    margin-bottom: 1.5rem;
                }

                .wrap-img{
                    margin-bottom: 1.5rem;
                    padding-top: 43%;
                    position: relative;

                    .img{
                        position: absolute;
                        top: 0;
                        left: 0;
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            &.mvl{
                display: none;
            }
        }
    }
    position: relative;    
    
    .limited-wrap{   
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        .section-title{
            width: 100%;
            margin-left: 3.4rem;

            .title{                    
                font-size: 3.5rem;
                text-transform: none;
                font-family: 'Montserrat-SemiBold';
                font-weight: normal;
                line-height: 4.3rem;
                text-align: left;
                margin: unset;
            }
            .title-underline{
                width: 3.5rem;
                border-bottom: 4px solid;
                background: $colorCorporative;
                margin-top: 0.5rem;
                margin-bottom: 5.4rem;
            }
        }
       
    
    }
    .wrap-link{
        font-size: 1.5rem;
        line-height: 1.9rem;
        font-family: "Montserrat-Medium";
        text-transform: uppercase;
        margin-top: 2.5rem;
        .link{
            position: relative;
            color: $colorCorporative;
            &::before{   
                content: "";
                color: $colorCorporative;
                background-image: url(/images/arrow-blue.svg);
                width: 1.6rem;
                height: 100%;
                background-size: contain;
                background-position: center;
                position: absolute;
                background-repeat: no-repeat;
                right: 0;
                transform: translateX(3rem);
                transition: 0.3s transform linear;
            }
            &:hover{
                &:before {
                    transform: translateX(4rem) scale(1.2);
                    transition: 0.3s transform linear;
                }
            }
        }
    }
    .generic.text, .generic.text p, .generic.text *{
        color: #575252 !important;
    }

}


@media (max-width: $md-width) {
    .page-brands {   
        .up, .down{
            padding-right: 1.5rem;
            padding-left: 1.5rem;
            padding-top: 9.8rem;  
        }
        .up{
            padding-bottom: 5.6rem;                 
            .part-up{
                flex-direction: column;     
            }
            .part-left{
                width: 100%;
                margin-right: 0;    
                margin-left: 0;
                
            }
            .part-right{
                width: 100%;               
            }
        }
        .down{
            padding-top: 0;
            background-color: #e8f1f3;
            padding-top: 5.6rem;
    
            .wrap-brands{
                display: flex;
                flex-direction: column;                
                justify-content: unset; 

                .item-brand{
                    width: 100%;
                    padding-bottom: 11rem;
    
                    .title{
                        font-size: 1.9rem;
                        line-height: 2.6rem;
                        font-family: 'Montserrat-Bold';
                        color: $colorCorporative;
                        margin-bottom: 1.5rem;
                    }
    
                    .wrap-img{
                        margin-bottom: 3.5rem;
                    }
                }
                &.mvl{
                    display: block;
                }
                &.desc{
                    display: none;
                }
            }
        }
        position: relative;    
        
        .limited-wrap{    
            .section-title{              
                margin-left: 0;
                .title{                    
                    font-size: 2.5rem;                  
                    line-height: 3.2rem;                  
                }
                .title-underline{
                    width: 3.5rem;
                    border-bottom: 2px solid;            
                    margin-bottom: 2.4rem;
                }
            }          
        
        }
        .generic.text, .generic.text p, .generic, .generic.text *{
            color: #575252;
            font-size: 1.5rem;
            line-height: 2.3rem;
            font-family: 'Montserrat-Regular';
            letter-spacing: 0;
        }
    
    }
}