.container-content.ganadores_2021{

    .generic.text, .generic.text *{
        color: #000;
        font-size: 1.5rem !important;
        line-height: 2.1rem !important;
        font-family: "Montserrat-Regular" !important;
        letter-spacing: 0 !important;
    }
    .limited-wrap{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        padding: 0;

        .section-title{   
            padding-top: 5rem;      
            .title{
                font-size: 3.5rem;
                text-transform: none;
                font-family: "Montserrat-SemiBold";
                font-weight: normal;
                line-height: 4.3rem;
                text-align: left;
            }
            .title-underline{
                width: 3.5rem;
                border-bottom: 4px solid;
                background: $colorCorporative;
                margin-top: 0.5rem;
                margin-bottom: 4rem;
        
            }            
        }        
    }
    .first-content{
        .wrap-content-winners{
            display: flex;
            flex-wrap: wrap;
            padding-left: 3rem;

            .part-left{
                width: 30%;
                position: relative;
                padding-bottom: 3rem;

                .title{
                    font-family: 'Montserrat-SemiBold';
                    font-size: 2.7rem;
                    line-height: 3.1rem;
                    letter-spacing: 0;
                    font-weight: normal;
                    color: $colorCorporative;
                    margin-bottom: 1.5rem;
                }
                .text{
                    font-family: 'Montserrat-Regular';
                    font-size: 1.5rem;
                    line-height: 2.1rem;
                    letter-spacing: 0;
                    font-weight: normal;
                    color: #575252;
                }
            }
            .part-right{
                width: 70%;
                padding: 0 3rem;
                position: relative;

                .premiogrande{                  
                    .superbox-title{
                        font-size: 2.5rem;
                        font-family: 'Montserrat-Regular';
                        color: $colorCorporative;
                        line-height: 3.3rem;
                        letter-spacing: 0;
                        padding-bottom: 1.5rem;
                        display: flex;
                        align-items: center;

                        .icon{
                            height: 2.5rem;
                            padding-right: 1rem;
                            object-fit: contain;
                        }
                    }             
                }
                .premio_2021{
                    padding-left: 0;
                    padding-right: 3rem;
                }
            }
            .finalistas_2021.premiados_2021{
                width: 100%;
                position: relative;
            }            
        }
    }
    .list-finalists{
        padding-bottom: 7rem;
        padding-left: 6rem;

        .title{
            font-family: 'Montserrat-SemiBold';
            font-size: 2.7rem;
            line-height: 3.1rem;
            letter-spacing: 0;
            font-weight: normal;
            color: $colorCorporative;
            margin-bottom: 1.5rem;
        }
        .finalistas_2021{
            .finalist_2021{
                padding-left: 0;
                padding-right: 3rem;
            }
        }
        .group:nth-child(odd){           
            .finalist_2021{
                &:nth-child(odd){
                    width: 45%;
                }
                &:nth-child(even){
                    width: 55%;
                }
            }            
        
        }
        .group:nth-child(even){         
            .finalist_2021{
                &:nth-child(even){
                    width: 45%;
                }
                &:nth-child(odd){
                    width: 55%;
                }
            }          
        }
    }
    .group{
        .premio_2021{
            width: 50%;
            padding-right: 3rem;
        }   
    }

    .premio-title{
        padding-bottom: 1.5rem;
    }
    .superauthor{
        display: flex;
        justify-content: space-between;
        padding-top: 1.5rem;
        padding-bottom: 3rem;

        .author-name{
            color: $colorCorporative;
            font-size: 1.7rem;
            font-family: 'Montserrat-Medium';
            line-height: 2.5rem;
            letter-spacing: 0;
        }
        .sharemicro{
            padding-right: 1.2rem;
        }
        .likes-count{
            font-family: 'Montserrat-Medium';
            font-size: 1.7rem;
            color: #575252;
            padding-left: .5rem;
        }
    }
}



@media (max-width: $md-width) {
    .container-content.ganadores_2021{
        .limited-wrap{
            width: 100%;
            max-width: 100%;
            margin: 0 auto;
            padding: 0;
    
            .section-title{   
                padding-top: 0;      
                .title{
                    font-size: 2.5rem;                   
                    line-height: 3.3rem;                    
                }
                .title-underline{                   
                    border-bottom: 2px solid;  
                }            
            }        
        }
        .first-content{
            padding: 3rem 1.5rem;
            .wrap-content-winners{
                flex-direction: column;
                padding-left: 0;
                padding: 0;

                .part-left{
                    width: 100%;
                    position: relative;
                    padding-bottom: 3rem;
    
                    .title{
                        font-family: 'Montserrat-SemiBold';
                        font-size: 2.7rem;
                        line-height: 3.1rem;
                        letter-spacing: 0;
                        font-weight: normal;
                        color: $colorCorporative;
                        margin-bottom: 1.5rem;
                    }
                    .text{
                        font-family: 'Montserrat-Regular';
                        font-size: 1.5rem;
                        line-height: 2.1rem;
                        letter-spacing: 0;
                        font-weight: normal;
                        color: #575252;
                    }
                }
                .part-right{
                    width: 100%;
                    padding: 0;
                    
                    .premiogrande{                  
                        .superbox-title{
                            font-size: 1.9rem;                   
                            line-height: 2.5rem;        
                            .icon{
                                height: 1.9rem;                             
                            }
                        }             
                    }
                    .premio_2021{
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
                .finalistas_2021.premiados_2021{
                    width: 100%;
                    position: relative;
                    .group{
                        flex-direction: column;
                    }
                }            
            }
        }
        .list-finalists{       
            padding: 3rem 0 5rem 0;
    
            .title{                
                font-size: 2.1rem;
                line-height: 2.7rem;         
            }
            .finalistas_2021{
                .finalist_2021{
                    padding-left: 0;
                    padding-right: 0;
                }
            }
            .group:nth-child(odd){    
                flex-direction: column;       
                .finalist_2021{
                    &:nth-child(odd){
                        width: 100%;
                    }
                    &:nth-child(even){
                        width: 100%;
                    }
                }            
            
            }
            .group:nth-child(even){     
                flex-direction: column;      
                .finalist_2021{
                    &:nth-child(even){
                        width: 100%;
                    }
                    &:nth-child(odd){
                        width: 100%;
                    }
                }          
            }
        }
        .group{
            .premio_2021{
                width: 100%;
                padding-right: 0;
            }   
        }
    
        .premio-title{
            padding-bottom: 1.5rem;
        }
        .superauthor{
            display: flex;
            justify-content: space-between;
            padding-top: 1.5rem;
            padding-bottom: 3rem;
    
            .author-name{
                color: $colorCorporative;
                font-size: 1.7rem;
                font-family: 'Montserrat-Medium';
                line-height: 2.5rem;
                letter-spacing: 0;
            }
            .sharemicro{
                padding-right: 1.2rem;
            }
            .likes-count{
                font-family: 'Montserrat-Medium';
                font-size: 1.7rem;
                color: #575252;
                padding-left: .5rem;
                padding-right: .5rem;
            }
        }
    }
}